import { Column, Columns, Title } from "components/trunx";
import React, { ReactElement } from "react";

import PzText from "../../components/text/PzText";
export default function UploadSuccessfullPage(): ReactElement {
    return (
        <Columns isAlignItemsCenter isJustifyContentCenter>
            <Column isNarrow style={{ minWidth: "400px" }}>
                <Title hasTextCentered is3 mb4 pb3>
                    Gratulerer!
                </Title>
                <PzText hasTextCentered>
                    <p>Du har levert!</p>
                    <p>Se oversikt for å sjekke status på innsendingen.</p>
                </PzText>
            </Column>
        </Columns>
    );
}
