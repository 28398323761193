import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useQuery, UseQueryResult } from "react-query";
import {
    Book,
    BookBokbasen,
    BookFileAudioBook,
    BookGroupNames,
    BookPublizm,
    BookStatus,
    BookType,
    FirestoreCollection,
} from "shared";

import { sortAudioBookByFilename } from "../../../../common/BookUtils";
import { isEmpty } from "../../../../components/utils/ObjectUtils";
import { firestore, functions, functionsEuropeNorth } from "../../../../firebase";
import { AudioBookChapter } from "../../types";
import { FirestoreApi, mapDocResult, mapQueryResult, QueryReturnType, useFirestoreDocument2 } from "../queriesCommon";
import { StorageQueries } from "../storageQueries";
import { BookKeys } from "./bookkeys";

export const BookQueries = {
    loadAudibookSample: (bookId: string) => {
        return useQuery({
            queryKey: BookKeys.sampleAudiobook(bookId),
            queryFn: () => StorageQueries.loadStorageUrl("books/" + bookId + "/sample/audiobookSample.mp3"),
        });
    },
    loadAudiobook: (bookId: string, downloadable?: boolean, enabled?: boolean): UseQueryResult<AudioBookChapter[]> => {
        const bookData = BookQueries.bookById(bookId, false, enabled);
        const book = bookData.data;
        return useQuery({
            queryKey: BookKeys.audiobook(bookId),
            queryFn: () => {
                if (!book) {
                    return [];
                }
                if (book?.source == "bokbasen") {
                    return BookFileQueries.loadBokbasenAudiobook(book, bookId, downloadable);
                }
                return BookFileQueries.loadPublizmAudiobook(book, bookId);
            },
            enabled: Boolean(book) && Boolean(enabled),
            suspense: false,
        });
    },

    getAllFeatured: () => BookQueries.booksByGroupname(BookGroupNames.AKTUELT),
    booksByGroupname: (groupName: string): QueryReturnType<Book[]> => {
        return useQuery({
            queryKey: BookKeys.booksByGroupName(groupName),
            queryFn: () =>
                getDocs(
                    query(collection(firestore, FirestoreCollection.BOOK), where("groups", "array-contains", groupName))
                ),
            select: mapQueryResult(),
        });
    },
    booksBySubject: (subject: string): QueryReturnType<Book[]> => {
        return useQuery({
            queryKey: BookKeys.booksBySubject(subject),
            queryFn: () =>
                getDocs(
                    query(
                        collection(firestore, FirestoreCollection.BOOK),
                        where("allSubjects", "array-contains", subject)
                    )
                ),
            select: mapQueryResult<Book>(),
        });
    },
    bookById: (bookId?: string, subscribe?: boolean, enabled?: boolean): UseQueryResult<Book | null> => {
        const queryBookId = bookId ?? "";
        if (subscribe) {
            return useFirestoreDocument2(
                BookKeys.bookById(queryBookId, true),
                doc(collection(firestore, FirestoreCollection.BOOK), queryBookId),
                true,
                {
                    select: mapDocResult<Book>(),
                    suspense: false,
                    enabled: Boolean(bookId) && Boolean(enabled),
                }
            ) as UseQueryResult<Book | null>;
        }
        return useQuery({
            queryKey: BookKeys.bookById(queryBookId),
            queryFn: () => FirestoreApi.getSingleDoc(FirestoreCollection.BOOK, queryBookId),
            select: mapDocResult<Book>(),
            enabled: Boolean(bookId),
        });
    },
    allBooksBySource: (source: string) => {
        return useQuery({
            queryKey: BookKeys.booksBySource(source),
            queryFn: () =>
                getDocs(query(collection(firestore, FirestoreCollection.BOOK), where("source", "==", source))),
            select: mapQueryResult<Book>(),

            keepPreviousData: true,
        });
    },
    isBookAvailabeForPurchase: (bookId: string, type: BookType) => {
        const { data: bookById } = BookQueries.bookById(bookId, false, true);

        return useQuery({
            queryKey: ["isBookAvailabeForPurchase", bookId, type],
            queryFn: async () => {
                const book = await getBook(bookById!, type);
                return (
                    book &&
                    book.status == BookStatus.PUBLISHED &&
                    (book.source == "publizm" || book.inventoryDetails?.published == true)
                );
            },
            enabled: true,
        });
    },
};

async function getBook(book: Book, type: BookType): Promise<Book | null> {
    if (book.bookType == type || book.source == "publizm") {
        return Promise.resolve(book);
    }
    const bookId = book.relatedBooks?.find((relatedBook) => relatedBook.type == type)?.bookId;
    if (bookId == null) return Promise.resolve(null);
    return (await FirestoreApi.getSingleDoc(FirestoreCollection.BOOK, bookId!)).data() as Book;
}
const BookFileQueries = {
    loadBokbasenAudiobook: async (
        book: BookBokbasen,
        bookId: string,
        downloadable?: boolean
    ): Promise<AudioBookChapter[]> => {
        const audioBookUrl = await BookFileQueries.loadBookUrl(bookId, null, BookType.AUDIO, downloadable);
        return [
            {
                src: audioBookUrl,
                title: book.title,
                index: 0,
                durationSeconds: (book.bookFile as BookFileAudioBook)?.durationSeconds,
            } as AudioBookChapter,
        ];
    },
    loadPublizmAudiobook: async (book: BookPublizm, bookId: string): Promise<AudioBookChapter[]> => {
        const files = book?.audiobook?.files;
        const chaptersMap = files?.sort(sortAudioBookByFilename).map(async (file, index) => {
            const filepath = file.filePath ?? `books/${bookId}/audiobook/${file.fileName}`;
            return {
                src: await BookFileQueries.loadBookUrl(bookId, filepath, book?.audiobook?.type ?? BookType.AUDIO),
                title: file.title,
                index,
                durationSeconds: file.durationSeconds,
            } as AudioBookChapter;
        });

        if (!chaptersMap) {
            return [];
        }
        return await Promise.all(chaptersMap);
    },
    loadBookUrl: async (bookId: string, filePath: string | null, type: BookType, downloadable?: boolean) => {
        if (type == BookType.PODCAST) {
            return StorageQueries.loadStorageUrl(filePath!);
        }
        const createBookLinkFn = httpsCallable(functionsEuropeNorth, "createBookLink");
        const data = await createBookLinkFn({ bookId, path: filePath, type, downloadable });
        if (filePath == null) {
            return data.data;
        }
        const tempFilePath = data.data as string;
        return StorageQueries.loadStorageUrl(tempFilePath);
    },
};

async function queryByTitleAndAuthor(title: string, authorName?: string) {
    const queries = [where("title", "==", title)];
    if (authorName) queries.push(where("author.name", "==", "authorName"));
    const result = await getDocs(query(collection(firestore, FirestoreCollection.BOOK), ...queries));
    if (result.empty) {
        return isEmpty(authorName) ? queryByUrlTitle(title) : queryByTitleAndAuthor(title);
    }
    return result;
}
async function queryByUrlTitle(title: string) {
    const queries = [where("urlTitle", "==", title)];
    return getDocs(query(collection(firestore, FirestoreCollection.BOOK), ...queries));
}
