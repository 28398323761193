import "./PzBookCard.css";

import { faBookOpen, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppPath } from "common/AppPath";
import DOMPurify from "dompurify";
import { BookVersionDetails } from "pages/customer/bookdetails/BookDetailsContext";
import { BookPrice, BookPriceType, BookType, UrlUtils } from "shared";

import { isPriceDiscounted } from "../../common/BookUtils";
import TerningkastIkon from "./TerningkastIcon";
interface PzBookCardProps extends BookVersionDetails {
    coverPath?: string;
    prices: BookPrice[];
    size?: "small" | "medium" | "large";
}

export default function PzBookCard({
    coverPath,
    title,
    titleHighlighted,
    authorHighlighted,
    bookReviews,
    collectionNamesHighlighted,
    author,
    prices,
    id,
    size = "large",
}: PzBookCardProps) {
    const coverId = `cover_${id}`;

    return (
        <a
            href={UrlUtils.encodeBookUrl(id!, title ?? "", author ?? "", AppPath.BOOK_DETAILS)}
            aria-label="Bokdetaljer"
            key={id}
        >
            <div className="bookcard-container">
                <div className="bookcard-img-container mb-2 pl-2">
                    <img id={coverId} src={coverPath} className={`bookcard-img ${size}`} alt={title} />
                    {<TerningkastIkon bookReviews={bookReviews} />}
                </div>
                <div className="bookcard-info ">
                    <h5 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(titleHighlighted ?? "") }} />
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(authorHighlighted ?? "") }} />
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(collectionNamesHighlighted ?? "") }} />
                    <DisplayPrices prices={prices} />
                </div>
            </div>
        </a>
    );
}

interface DisplayPricesProps {
    prices: BookPrice[];
}

function DisplayPrices({ prices }: DisplayPricesProps) {
    return (
        <>
            {prices?.map((price, index) => {
                if (price.type === BookPriceType.BUNDLE) {
                    return;
                }
                return (
                    <DisplayPrice
                        isEbook={price.type === BookPriceType.EBOOK}
                        price={price}
                        key={`${price.bookId}-${index}`}
                        bookId={price.bookId!}
                        type={price.type == BookPriceType.EBOOK ? BookType.EBOOK : BookType.AUDIO}
                    />
                );
            })}
        </>
    );
}

interface DisplayPriceProps {
    isEbook: boolean;
    price: BookPrice;
    bookId: string;
    type: BookType;
}

function DisplayPrice({ isEbook, price, bookId, type }: DisplayPriceProps) {
    // const isBookAvailabeForPurchase = BookQueries.isBookAvailabeForPurchase(bookId, type);
    function renderPrice() {
        if (isPriceDiscounted(price)) {
            return (
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <p style={{ fontVariantNumeric: "tabular-nums" }}>
                        <s>{price.originalPrice}</s>
                    </p>
                    <p style={{ fontVariantNumeric: "tabular-nums", display: "flex", flexDirection: "row" }}>
                        <p style={{ color: "var(--ocean-600)" }}>{price.value}</p> ,-
                    </p>
                </div>
            );
        }

        return (
            <div>
                <p style={{ fontVariantNumeric: "tabular-nums" }}>{price.value} ,-</p>
            </div>
        );
    }
    // if (!isBookAvailabeForPurchase.data) {
    //     return <></>;
    // }
    return (
        <div className="display-price">
            <FontAwesomeIcon
                icon={isEbook ? faBookOpen : faHeadphones}
                style={{ color: "var(--persimon)", minWidth: 18 }}
                className="mr-2"
            />
            {renderPrice()}
        </div>
    );
}
