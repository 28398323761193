import LoadingIndicator from "components/LoadingIndicator";
import { Column } from "components/trunx";
import React, { useEffect, useState } from "react";
import { CustomerBookData } from "services/firestore/types";
import { UserService } from "services/firestore/UserService";

import BookcaseDisplayer from "./BookcaseDisplayer";

export default function BookcasePage() {
    const [loading, setLoading] = useState<boolean>(true);
    const [bookcase, setBookcase] = useState<CustomerBookData[]>([]);

    useEffect(() => {
        loadBookcase();
    }, []);

    async function loadBookcase() {
        new UserService.CustomerBook()
            .getAll()
            .then(setBookcase)
            .finally(() => setLoading(false));
        setLoading(false);
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    if (bookcase.length === 0) {
        return (
            <Column pl6 ml4>
                Du har ingen bøker i bokhyllen
            </Column>
        );
    }

    return <BookcaseDisplayer cards={bookcase} />;
}
