import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import React, { ReactElement } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { BookSource, BookType, UrlUtils } from "shared";

import { AppPath } from "../../../../../common/AppPath";
import PzButton from "../../../../../components/form/PzButton";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import PzModal from "../../../../../components/modal/PzModal";
import PzTable from "../../../../../components/table/PzTable";
import { Modal } from "../../../../../components/trunx";
import useBookApi from "../../../../../services/firestore/hooks/useBookApi";
import useUserApi from "../../../../../services/firestore/hooks/useUserApi";
import VippsService from "../../../../../services/VippsService";

export interface TransactionsTableData {
    id: string;
    bookId: string;
    userId: string;
    bookType: BookType;
    source: BookSource;
    originalPrice?: number;
    price?: number;
    isDuplicate?: boolean;
    transactionStatus?: "CAPTURED" | "REFUNDED" | "AUTHORIZED" | "CANCELLED";
    vippsReference?: string;
    timestamp: string;
}
interface TableProps {
    data: TransactionsTableData[];
}
export default function TransactionsTable({ data }: TableProps): ReactElement {
    const columns: ColumnData[] = [
        {
            label: "Tittel",
        },
        {
            label: "Bruker",
        },
        {
            label: "Format",
        },
        {
            label: "Betalt",
        },
        {
            label: "Tidspunkt",
            sortByProperty: "timestamp",
        },
        {
            label: "",
        },
        {
            label: "Status",
        },
    ];

    function createRow(transaction: TransactionsTableData): RowData {
        return {
            components: [
                {
                    content: (
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <BookTitle bookId={transaction.bookId} />
                        </React.Suspense>
                    ),
                    width: "20%",
                },
                {
                    content: (
                        <React.Suspense fallback={<LoadingIndicator />}>
                            <UserName userId={transaction.userId} />
                        </React.Suspense>
                    ),
                    width: "5%",
                },
                {
                    content: transaction.bookType,
                    width: "5%",
                },
                {
                    content: transaction.price + ",-",
                    width: "5%",
                },
                {
                    content: transaction.timestamp,
                    width: "10%",
                },
                {
                    content: <OpenBook bookId={transaction.bookId} />,
                    width: "2%",
                },
                {
                    content: <CancelPayment transaction={transaction} />,
                    width: "5%",
                },
            ],
        };
    }

    return (
        <PzTable
            createRow={createRow}
            data={data}
            enableExternalDataUpdate
            columns={columns}
            rowKeyGenerator={(data) => data.id}
            defaultSortIndex={1}
        />
    );
}

interface BookTitleProps {
    bookId: string;
}
function BookTitle({ bookId }: BookTitleProps) {
    const { data: book } = useBookApi().bookById(bookId);

    return <div>{book?.title}</div>;
}

interface BookTitleProps {
    bookId: string;
}
function OpenBook({ bookId }: BookTitleProps) {
    const navigate = useNavigate();
    const { data: book } = useBookApi().bookById(bookId);
    const previewPath = UrlUtils.encodeBookUrl(
        book?.id ?? "",
        book?.title ?? "",
        book?.author?.name ?? "",
        AppPath.BOOK_DETAILS,
        true
    );
    return <PzButton onClick={() => navigate(previewPath)}>Åpne</PzButton>;
}

interface BookTitleProps {
    bookId: string;
}
function CancelPayment({ transaction }: { transaction: TransactionsTableData }) {
    const [showModal, setShowModal] = React.useState(false);

    const cancelPaymentFn = useMutation({
        mutationFn: (transaction: TransactionsTableData) => {
            return new VippsService().cancelPayment(transaction.userId, transaction.id);
        },
    });

    function renderButton() {
        return (
            <>
                <PzButton onClick={() => setShowModal(true)}>Kanseller betaling {transaction.isDuplicate ? "(duplikat betaling)" : ""}</PzButton>
                <PzModal isActive={showModal && !cancelPaymentFn.isSuccess} onClose={() => setShowModal(false)}>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Kanseller betaling</Modal.Card.Title>
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <div>Betaling er bekreftet av bruker men har ikke blitt endelig utført</div>
                        <br />
                        {transaction.isDuplicate ? <div>
                            Bruker har kjøpt samme bok før.<br /> Dette er duplikat kjøp av samme bok som kan skyldes at bruker har forsøket å kjøpe boken igjen før innlogging.
                        </div> :
                            <div>
                                Boken er et Bokbasen bok. På grunn av en feil så har ikke transaksjonen blitt bekreftet av
                                Bokbasen og bruker har derfor ikke fått tilgang til boken. <br /> Transaksjonen kan derfor
                                kanselleres og bruker vil da få pengene tilbake.
                            </div>}
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <PzButton
                            isPrimary
                            isLoading={cancelPaymentFn.isLoading}
                            onClick={() => cancelPaymentFn.mutate(transaction)}
                        >
                            Kanseller betaling
                        </PzButton>
                    </Modal.Card.Foot>
                </PzModal>
            </>
        );
    }
    console.log(transaction.bookId, transaction.transactionStatus);
    if (transaction.transactionStatus == "CANCELLED") {
        return <div>Betaling kansellert</div>;
    } else if (transaction.transactionStatus == "CAPTURED") {
        return <div>Betaling utført</div>;
    } else if (transaction.source != "bokbasen" || transaction.transactionStatus == undefined) {
        return <></>;
    }

    if (cancelPaymentFn.isSuccess) {
        return <div>Betaling kansellert</div>;
    }

    return renderButton();
}

interface UserNameProps {
    userId: string;
}
function UserName({ userId }: UserNameProps) {
    const { data: user } = useUserApi().getUser(userId);

    return <div>{user?.firstname + " " + user?.surname}</div>;
}
