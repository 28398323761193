import { ReactElement } from "react";
import { Agreement, agreementDisplayValue } from "shared";

import AgreementContent from "../pagetemplate/AgreementContent";
import TextModal from "./TextModal";

interface AgreementModalProps {
    agreement?: Agreement;
    onClose: () => void;
    isActive: boolean;
}

export default function AgreementModal({ isActive, agreement, onClose }: AgreementModalProps): ReactElement {
    const title = `${agreementDisplayValue(agreement?.agreementType)}`;

    if (!isActive) {
        return <></>;
    }
    return (
        <TextModal
            isActive={isActive}
            title={title}
            content={<AgreementContent content={agreement?.agreementContent ?? ""} />}
            onClose={onClose}
        />
    );
}
