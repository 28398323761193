import "../common/CardDisplayer.css";
import "./CatalogueCardDisplayer.css";

import { Column } from "components/trunx";
import React, { useEffect, useState } from "react";
import { Configure, useInstantSearch, usePagination } from "react-instantsearch";
import { BookSource, BookType, isEmpty } from "shared";

import SearchField from "../../../components/algolia/SearchField";
import LoadingIndicator from "../../../components/LoadingIndicator";
import StoryblokWrapper from "../../../components/storyblok/StoryblokWrapper";
import { PzTitle } from "../../../components/text/PzTitle";
import { BooksIndex, HighlightResult } from "../../../services/algolia/types";
import { getCoverpath } from "../../../services/firestore/hooks/useBookApi";
import { AnalyticsApi } from "../../../services/firestore/queries/queriesCommon";
import GroupedBooksSlideshow from "../../storyblok/GroupedBooksSlideshow";
import { GroupItems } from "../../storyblok/types";
import BookCardGrid, { CatalogueCardProps } from "./BookCardGrid";
import BookFilters from "./BookFilters";
import { CatalogueFilterKeys, useCatalougeFilter } from "./CatalogueFilterContext";
import Paginate from "./Paginate";

interface CatalogueCardDisplayerProps {
    defaultPageSize?: number;
    type?: BookType;
}
export default function CatalogueCardDisplayerAlgolia({ defaultPageSize, type }: CatalogueCardDisplayerProps) {
    const { results, status } = useInstantSearch();
    const { nbPages, refine } = usePagination();
    const {
        currentPage,
        activeFilter,
        searchPattern,
        setCurrentPage,
        hasActiveFilter,
        setSearchPattern,
        getFilterValueByKey,
        getNextLink,
        getPrevLink,
    } = useCatalougeFilter();

    //Pagination related
    const [pageSize, setPageSize] = useState<number>(defaultPageSize ?? 12);

    useEffect(() => {
        refine(currentPage);
    }, [currentPage]);

    //Utility method for mapping from BookData to CataloguecardProps
    function mapBookDataToCatalogueDisplayer(books: BooksIndex[]): CatalogueCardProps[] {
        return (
            books
                ?.map((book) => ({
                    coverPath: getCoverpath({
                        bookId: book.objectID,
                        bookType: book.bookType,
                        source: book.source as BookSource,
                        size: "small",
                        updatedDate: book.updatedDate,
                        coverPath: book.source == "publizm" ? book["ebook.coverPath"] : book.coverPath,
                    }),
                    title: book["title"],
                    source: book.source,
                    bookReviews: book.bookReviews,
                    author: book["author.name"],
                    titleHighlighted: (book._highlightResult["title"] as HighlightResult)?.value,
                    authorHighlighted: (book._highlightResult["author.name"] as HighlightResult)?.value,
                    collectionNames: book.collectionDetails?.map((c) =>
                        `${c.title} ${c.partNumber ? "#" + c.partNumber : ""}`.trim()
                    ),
                    collectionNamesHighlighted: book.collectionDetails
                        ?.map((c) => `${c.title} ${c.partNumber ? "#" + c.partNumber : ""}`.trim())
                        .join(","),
                    prices: book.prices,
                    id: book.objectID,
                }))
                .sort((a, b) => {
                    if (a.collectionNames && a.collectionNames[0] && b.collectionNames && b.collectionNames[0]) {
                        return a.collectionNames[0].localeCompare(b.collectionNames[0]);
                    }
                    return a.title.localeCompare(b.title);
                }) ?? []
        );
    }

    function onPrev() {
        setCurrentPage((page) => Math.max(0, page - 1));
    }

    function onNext() {
        setCurrentPage((page) => Math.min(nbPages - 1, page + 1));
    }

    function onSearchChange(value: string) {
        AnalyticsApi.logSearch(value);
        setSearchPattern(value);
        setCurrentPage(0);
    }

    function onPagesizeChange(pageSize: number) {
        setPageSize(pageSize);
        setCurrentPage(0);
    }

    function createAlgoliaFilterQuery() {
        let filter = `status: PUBLISHED AND mainBook: true AND source: "publizm"`;
        if (type) {
            filter += ` AND bookType:"${type}"`;
        }
        if (type != BookType.PODCAST) {
            filter += ` AND NOT bookType:"${BookType.PODCAST}"`;
        }
        if (activeFilter.length > 0) {
            filter += ` AND tags:'${activeFilter[0]}'`;
        }

        const subject = getFilterValueByKey(CatalogueFilterKeys.SUBJECT_KEY);
        if (subject) {
            filter += ` AND allSubjects:"${subject}" OR tags:"${subject}"`;
        }

        const group = getFilterValueByKey(CatalogueFilterKeys.GROUP);
        if (group) {
            filter += ` AND groups:"${group}"`;
        }

        const collection = getFilterValueByKey(CatalogueFilterKeys.COLLECTION);
        if (collection) {
            filter += ` AND collectionDetails.title:"${collection}"`;
        }
        return filter;
    }

    const loading = status == "loading";
    const showGroups = isEmpty(searchPattern) && !hasActiveFilter() && currentPage == 0 && type != BookType.PODCAST;
    return (
        <Column isFlex isFlexDirectionColumn isAlignItemsFlexStart className="catalogue-card-displayer">
            <Configure hitsPerPage={pageSize} filters={createAlgoliaFilterQuery()} />
            <Column isFlex isFlexDirectionColumn isAlignItemsFlexStart className="catalogue-filter">
                <SearchField onChange={onSearchChange} initialValue={searchPattern} />
                <BookFilters />
            </Column>
            {showGroups && <StoryblokBookGroups />}
            {loading ? (
                <LoadingIndicator size="XL" />
            ) : (
                <React.Suspense fallback={<LoadingIndicator size="XL" />}>
                    {showGroups && <PzTitle isSize3>Katalog</PzTitle>}
                    <BookCardGrid bookCardDetails={mapBookDataToCatalogueDisplayer(results.hits)} loading={loading} />
                </React.Suspense>
            )}
            <Column isFlex isFlexDirectionColumn isAlignSelfFlexStart>
                <Paginate
                    pageSize={pageSize}
                    currentPage={currentPage + 1}
                    nPages={nbPages}
                    setPageSize={onPagesizeChange}
                    onPrev={onPrev}
                    onNext={onNext}
                    nextLink={getNextLink()}
                    prevLink={getPrevLink()}
                />
            </Column>
        </Column>
    );
}

function StoryblokBookGroups() {
    return (
        <StoryblokWrapper slug="catalogue">
            {(value: GroupItems) => (
                <>
                    {value?.content?.groups.map((props) => (
                        <div style={{ width: "100%", paddingBottom: "10px" }} key={props._uid}>
                            <GroupedBooksSlideshow
                                groupName={props.groupName}
                                title={props.title}
                                description={props.description}
                                showSeeAllButton={false}
                            ></GroupedBooksSlideshow>
                        </div>
                    ))}
                </>
            )}
        </StoryblokWrapper>
    );
}
