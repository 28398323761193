import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { ReactElement } from "react";

import PzIconButton from "../form/PzIconButton";

interface CollapsibleExpandButtonProps {
    isOpen?: boolean;
    onClick?: (e) => void;
}
export default function CollapsibleExpandButton({ isOpen, onClick }: CollapsibleExpandButtonProps): ReactElement {
    return (
        <PzIconButton className="collapsible" icon={isOpen ? faChevronUp : faChevronDown} onClick={onClick} isSmall />
    );
}
