import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { ReactElement } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { Agreement, AgreementType, FirestoreCollection, UserRole } from "shared";

import PzFormInputField from "../../../components/form/PzFormInputField";
import AgreementModal from "../../../components/modal/AgreementModal";
import { firestore } from "../../../firebase";
import { mapQueryResult } from "../../../services/firestore/queries/queriesCommon";

interface AgreementCheckboxProps {
    agreementType: AgreementType;
    role: UserRole;
    name: string;
    requiredMessage: string;
    agreementDescription: string;
}
export default function AgreementCheckbox({
    agreementType,
    role,
    name,
    requiredMessage,
    agreementDescription,
}: AgreementCheckboxProps): ReactElement {
    const { register, errors } = useFormContext();
    const [showAgreementModal, setShowAgreementModal] = useState<boolean>(false);

    const { data: agreementData } = useQuery({
        queryKey: ["agreement", agreementType, role],
        queryFn: () =>
            getDocs(
                query(
                    collection(firestore, FirestoreCollection.LEGAL_TERMS),
                    where("agreementType", "==", agreementType),
                    where("roles", "array-contains", role),
                    orderBy("date", "desc")
                )
            ),
        select: mapQueryResult<Agreement>(),
    });
    const openUserInfoModal = () => setShowAgreementModal(true);
    const closeUserInfoModal = () => setShowAgreementModal(false);
    return (
        <>
            <AgreementModal isActive={showAgreementModal} agreement={agreementData?.[0]} onClose={closeUserInfoModal} />
            <PzFormInputField
                id={name}
                name={name}
                type="checkbox"
                formError={errors[name]}
                inputRef={register({ required: requiredMessage })}
            >
                <label htmlFor={name} className="ml-3">
                    Jeg aksepterer{" "}
                    <a role="button" onClick={openUserInfoModal}>
                        {agreementDescription}
                    </a>
                </label>
            </PzFormInputField>
        </>
    );
}
