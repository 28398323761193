import "../../common/Card.css";

import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PzIconButton from "components/form/PzIconButton";
import LoadingIndicator from "components/LoadingIndicator";
import PzModal from "components/modal/PzModal";
import { Modal } from "components/trunx";
import { useState } from "react";

import useBookApi from "../../../../services/firestore/hooks/useBookApi";
import { AnalyticsApi } from "../../../../services/firestore/queries/queriesCommon";

interface DownloadEbookProps {
    bookId: string;
}

export default function DownloadEbook({ bookId }: DownloadEbookProps) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { data: ebook, isLoading } = useBookApi().loadEbook(bookId, showModal);

    const closeModal = () => {
        setShowModal(false);
    };

    function renderBody() {
        return (
            <div className="epub-download">
                <p>Last ned ebok i .epub format ved å trykke på lenken</p>
                <br />
                <div className="is-center">
                    <a
                        href={ebook?.url}
                        download
                        onClick={() => {
                            AnalyticsApi.logEvent("download_ebook", { bookId });
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowCircleDown} />
                        <span>Last ned</span>
                    </a>
                    <a className="ml-4" onClick={closeModal}>
                        Avbryt
                    </a>
                </div>
            </div>
        );
    }
    return (
        <span>
            <PzIconButton iconPlacement="left" isSmall icon={faArrowCircleDown} onClick={() => setShowModal(true)}>
                <span style={{ textDecoration: "underline" }}>Last ned</span>
            </PzIconButton>
            <PzModal isActive={showModal} onClose={() => setShowModal(false)}>
                <Modal.Card.Body style={{ justifyContent: "center" }}>
                    {isLoading ? (
                        <div>
                            <LoadingIndicator />
                            <p>Forbereder nedlasting</p>
                        </div>
                    ) : (
                        renderBody()
                    )}
                </Modal.Card.Body>
            </PzModal>
        </span>
    );
}
