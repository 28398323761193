import { Help, Input, Label } from "components/trunx";
import { ReactElement, Ref } from "react";

type PzInputFieldType = "text" | "number" | "password" | "email" | "checkbox" | "tel";
type ErrorType = string | ReactElement;
type InputProps = typeof Input.prototype.props;
type LabelProps = typeof Label.prototype.props;
export interface PzInputFieldProps extends InputProps {
    name: string;
    type?: PzInputFieldType;
    id?: string;
    label?: string;
    labelProps?: LabelProps;
    placeholder?: string;
    inputRef?: Ref<HTMLInputElement>;
    error?: ErrorType;
}

function PzInputField(props: PzInputFieldProps) {
    const {
        id,
        name,
        className,
        type = "text",
        label,
        labelProps,
        placeholder,
        inputRef,
        error,
        children,
        ...otherProps
    } = props;
    return (
        <>
            {label && (
                <Label {...labelProps} htmlFor={name}>
                    {label}
                </Label>
            )}
            <input
                {...otherProps}
                id={id}
                name={name ?? id}
                className={`${type !== "checkbox" ? "input" : ""} ${errorStyle(error)} ${className}`}
                type={type}
                placeholder={placeholder}
                ref={inputRef ?? null}
            />
            {children}
            <ErrorMessage error={error} />
        </>
    );
}

function ErrorMessage({ error }: { error?: ErrorType }) {
    return <>{error && <Help isDanger>{error}</Help>}</>;
}

function errorStyle(error?: ErrorType) {
    return error ? "is-danger" : "";
}

export default PzInputField;
