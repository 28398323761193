import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Column } from "components/trunx";
import { PropsWithChildren, ReactElement } from "react";

export default function IconText({
    children,
    ...fontAwesomeProps
}: PropsWithChildren<FontAwesomeIconProps>): ReactElement {
    return (
        <Column pb0>
            <FontAwesomeIcon className="mr-4" {...fontAwesomeProps} />
            {children}
        </Column>
    );
}
