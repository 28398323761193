import { Column } from "components/trunx";
import { formatDate } from "components/utils/DateUtils";
import { useNavigate } from "react-router-dom";
import { AuthorProfile, languageDisplayValue } from "shared";

import { AppPath } from "../../../../../common/AppPath";
import PzTable from "../../../../../components/table/PzTable";
import { RowData } from "../../../../../components/table/TableBody";
import { ColumnData } from "../../../../../components/table/TableHead";
import { PzTitle } from "../../../../../components/text/PzTitle";
import useAuthorsApi from "../../../../../services/firestore/hooks/useAuthorsApi";
import { BookAuthorProfileFormProps } from "./BookAuthorAdminPage";

export interface AuthorTableCollapsibleProps {
    authorId: string;
}

export default function AuthorTableCollapsible({ authorId }: AuthorTableCollapsibleProps) {
    const navigate = useNavigate();

    const { data, isFetching } = useAuthorsApi().authorProfilesById(authorId);

    const columns: ColumnData[] = [
        {
            label: "Navn",
            sortByProperty: "authorProfile.displayName",
        },
        {
            label: "Sist oppdatert",
            sortByProperty: "authorProfileInfo.lastChanged",
        },
        {
            label: "Språk",
            sortByProperty: "authorProfileInfo.language",
        },
    ];

    function editProfile(authorProfile: AuthorProfile) {
        const formData: BookAuthorProfileFormProps = {
            source: authorProfile?.source,
            documentId: authorProfile.id!,
            displayName: authorProfile.displayName,
            name: authorProfile.name,
            lastChanged: authorProfile.lastChanged?.toDate(),
            description: authorProfile.description,
            language: authorProfile.language,
            profilePath: authorProfile.profilePicturePath,
        };
        navigate(AppPath.ADMIN_CREATE_AUTHOR_PROFILE, {
            state: {
                authorId: authorId,
                formValues: undefined,
                authorProfile: formData,
            },
        });
    }
    function createRow(authorProfile: AuthorProfile): RowData {
        return {
            components: [
                {
                    content: <a onClick={() => editProfile(authorProfile)}>{authorProfile.displayName}</a>,
                    width: "15%",
                },
                {
                    content: formatDate(authorProfile.lastChanged ?? new Date()) ?? "-",
                    width: "15%",
                },

                {
                    content: languageDisplayValue(authorProfile.language),
                    width: "60%",
                },
            ],
        };
    }

    if (data?.length === 0) {
        return <Column>Ingen profiler</Column>;
    }
    return (
        <Column style={{ margin: "0 auto" }} p6>
            <PzTitle isSize5>Forfatterprofiler</PzTitle>
            <PzTable
                enablePagination={false}
                isNarrow
                enableExternalDataUpdate
                loading={isFetching}
                isShadowless
                columns={columns}
                data={data}
                createRow={createRow}
            ></PzTable>
        </Column>
    );
}
