import "../common/CardDisplayer.css";
import "./CatalogueCardDisplayer.css";

import LoadingIndicator from "components/LoadingIndicator";
import { Column } from "components/trunx";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { BookService } from "services/firestore/BookService";
import { Query } from "services/firestore/FirestoreService";
import { BookData } from "services/firestore/types";
import { authorDisplayValue, BookType, getBookPrices, getIfNotempty } from "shared";

import useUrlQueries from "../../../common/hooks/useQuery";
import SearchField from "../../../components/algolia/SearchField";
import { getQueryUrl, updateWindowQueryParameterByQueries } from "../../../components/utils/UrlUtils";
import BookCardGrid, { CatalogueCardProps } from "./BookCardGrid";
import BookFilters from "./BookFilters";
import { CatalogueFilterKeys, useCatalougeFilter } from "./CatalogueFilterContext";
import Paginate from "./Paginate";

const CURSOR_AFTER_KEY = "cursor";
interface CatalogueCardDisplayerProps {
    defaultPageSize?: number;
    type?: BookType;
}
export default function CatalogueCardDisplayer({ defaultPageSize, type }: CatalogueCardDisplayerProps) {
    const query = useUrlQueries();
    const hasLoadedInitialBooks = useRef(false);
    const [displayBooks, setDisplayBooks] = useState<CatalogueCardProps[]>([]);
    const [fetchedBooks, setFetchedBooks] = useState<BookData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [getNewPage, setGetNewPage] = useState<boolean>(false);
    const { activeFilter, searchPattern, setActiveFilter, setSearchPattern } = useCatalougeFilter();
    //Pagination related
    const [cursorFirst, setCursorFirst] = useState<any>(query.get(CURSOR_AFTER_KEY) ?? null);
    const [cursorLast, setCursorLast] = useState<any>();
    const [orderByField, setOrderByField] = useState<string>("updatedDate");
    const [pageSize, setPageSize] = useState<number>(defaultPageSize ?? 80);

    //update when adding or removing filters or changing pageSize
    useEffect(() => {
        hasLoadedInitialBooks.current && loadBooksStartAfter(orderByField, null, pageSize); //uses a string of 1 to "reset" cursorLast to start. setCursorLast does not work as it is async
    }, [activeFilter, pageSize, searchPattern]);

    useEffect(() => {
        // new BookService().getAll().then(setFetchedBooks);
        loadBooksStartAt(orderByField, cursorFirst, pageSize).finally(() => (hasLoadedInitialBooks.current = true));
    }, []);

    //Display fetched books
    useEffect(() => {
        setDisplayBooks(mapBookDataToCatalogueDisplayer(fetchedBooks));
    }, [fetchedBooks]);

    useEffect(() => {
        updateWindowQueryParameterByQueries(
            {
                [CURSOR_AFTER_KEY]: cursorFirst,
                [CatalogueFilterKeys.SEARCH_PATTERN_KEY]: searchPattern,
                [CatalogueFilterKeys.FILTER_KEY]: activeFilter.join(","),
            },
            true
        );
    }, []);

    useEffect(() => {
        updateWindowQueryParameterByQueries({
            [CURSOR_AFTER_KEY]: cursorFirst,
            [CatalogueFilterKeys.SEARCH_PATTERN_KEY]: searchPattern,
            [CatalogueFilterKeys.FILTER_KEY]: activeFilter.join(","),
        });
    }, [cursorFirst, searchPattern, activeFilter]);

    function getSearchFieldQuery() {
        const searchFieldQueries: Query[] = [{ fieldPath: "status", opStr: "==", value: "PUBLISHED" }];

        if (type) {
            searchFieldQueries.push({
                fieldPath: "bookType",
                opStr: "==",
                value: type,
            });
        }
        if (searchPattern !== "") {
            searchFieldQueries.push({
                fieldPath: "searchField",
                opStr: "array-contains-any",
                value: searchPattern.split(" "),
            });
        }

        return searchFieldQueries;
    }

    function getTagQuery() {
        const tagQueries: Query[] = [{ fieldPath: "status", opStr: "==", value: "PUBLISHED" }];
        activeFilter.forEach((filter) => {
            tagQueries.push({ fieldPath: "tags", opStr: "array-contains", value: filter });
        });
        return tagQueries;
    }

    async function loadBooksEndBefore(orderByField: string, endAt: any, limit: number, newPage = false) {
        setLoading(true);
        setDisplayBooks([]);
        let booksQuery2;

        const booksQuery1 = await new BookService()
            .getPaginatedEndBefore(orderByField, endAt, limit, getSearchFieldQuery())
            .then((books) => processBookSearchResult(books, newPage))
            .catch((err) => console.log(err));

        if (activeFilter.length > 0) {
            booksQuery2 = await new BookService()
                .getPaginatedEndBefore(orderByField, endAt, limit, getTagQuery())
                .then((books) => processTagQueryResult(books, booksQuery1 || []))
                .catch((err) => console.log(err));
        }
        setFetchedBooks(booksQuery2 !== undefined ? booksQuery2 : booksQuery1);
        setLoading(false);
        getNewPage && setGetNewPage(false);
    }

    async function loadBooksStartAt(orderByField: string, startAt: any, limit: number, newPage = false) {
        setLoading(true);
        setDisplayBooks([]);
        let booksQuery2;

        const booksQuery1 = await new BookService()
            .getPaginatedStartAt(orderByField, startAt, limit, getSearchFieldQuery())
            .then((books) => processBookSearchResult(books, newPage))
            .catch((err) => console.log(err));

        if (activeFilter.length > 0) {
            booksQuery2 = await new BookService()
                .getPaginatedStartAt(orderByField, startAt, limit, getTagQuery())
                .then((books) => processTagQueryResult(books, booksQuery1 || []))
                .catch((err) => console.log(err));
        }
        setFetchedBooks(booksQuery2 !== undefined ? booksQuery2 : booksQuery1);
        setLoading(false);
        getNewPage && setGetNewPage(false);
    }

    async function loadBooksStartAfter(orderByField: string, startAfter: any, limit: number, newPage = false) {
        setLoading(true);
        setDisplayBooks([]);
        let booksQuery2;

        const booksQuery1 = await new BookService()
            .getPaginatedStartAfter(orderByField, startAfter, limit, getSearchFieldQuery())
            .then((books) => processBookSearchResult(books, newPage))
            .catch((err) => console.log(err));

        if (activeFilter.length > 0) {
            booksQuery2 = await new BookService()
                .getPaginatedStartAfter(orderByField, startAfter, limit, getTagQuery())
                .then((books) => processTagQueryResult(books, booksQuery1 || []))
                .catch((err) => console.log(err));
        }
        setFetchedBooks(booksQuery2 !== undefined ? booksQuery2 : booksQuery1);
        setLoading(false);
        getNewPage && setGetNewPage(false);
    }

    function processBookSearchResult(books: BookData[], newPage: boolean) {
        if (books.length != 0) {
            const bookIntersection = newPage
                ? books
                : intersectBooks(fetchedBooks, filterSearchFieldQueryResult(books));
            setCursorFirst(bookIntersection[0].book.title);
            setCursorLast(bookIntersection[bookIntersection.length - 1].book.title);

            return bookIntersection;
        } else {
            setCursorLast(null);
            setCursorFirst(null);
            return [];
        }
    }

    function processTagQueryResult(books: BookData[], searchFieldQueryResult: BookData[]) {
        let bookIntersection: BookData[];

        if (books.length !== 0 && searchFieldQueryResult) {
            bookIntersection = intersectBooks(searchFieldQueryResult, books);
            return bookIntersection;
        } else if (books.length !== 0 && !searchFieldQueryResult) {
            bookIntersection = books;
            setCursorFirst(bookIntersection[0].book.title);
            setCursorLast(bookIntersection[bookIntersection.length - 1].book.title);

            return bookIntersection;
        } else if (books.length == 0 && !searchFieldQueryResult) {
            setCursorLast(null);
            setCursorFirst(null);

            return [];
        } else {
            return [];
        }
    }

    /**
     * Query will inherently be too general. This method cross-validates searchField-values
     * with searchPattern to make sure that books not containing all keywords from searchPattern
     * aren't returned from search.
     * @param bookData book-documents from query
     * @returns An array of books all matching the searchPattern
     */
    function filterSearchFieldQueryResult(bookData: BookData[]): BookData[] {
        return searchPattern !== ""
            ? bookData.filter((book) => {
                let shouldReturn = true;
                searchPattern.split(" ").forEach((searchPatternField) => {
                    if (book.book.searchField.indexOf(searchPatternField) === -1) shouldReturn = false;
                });
                if (shouldReturn) return book;
            })
            : bookData;
    }

    /**
     * Joins two query results based on the documentId
     * @param previousResult array of results from previous query
     * @param newResult array of results from new query
     * @returns an intersection of the elements of both arrays
     */
    function intersectBooks(previousResult: BookData[], newResult: BookData[]) {
        if (activeFilter.length == 0 || searchPattern == "") return newResult;

        const bookIds: string[] = previousResult.map((bookData) => bookData.documentId);
        const allBooks: BookData[] = [];
        newResult.forEach((bookData) => bookIds.indexOf(bookData.documentId) !== -1 && allBooks.push(bookData));

        return allBooks;
    }

    //Utility method for mapping from BookData to CataloguecardProps
    function mapBookDataToCatalogueDisplayer(books: BookData[]): CatalogueCardProps[] {
        const convertedBooks: CatalogueCardProps[] = [];
        books
            ?.filter((b) => (b.book.source == "bokbasen" && b.book.mainBook) || b.book.source !== "bokbasen")
            ?.forEach((book) => {
                convertedBooks.push({
                    coverPath: getIfNotempty(book.book.ebook?.coverPath) ?? book.book?.coverPath,
                    title: book.book.title,
                    source: book.book.source,
                    titleHighlighted: book.book.title,
                    author: authorDisplayValue(book.book.author),
                    authorHighlighted: authorDisplayValue(book.book.author),
                    collectionNames: book.book.collectionDetails?.map((c) =>
                        `${c.title} ${c.partNumber ? "#" + c.partNumber : ""}`.trim()
                    ),
                    collectionNamesHighlighted: book.book.collectionDetails
                        ?.map((c) => `${c.title} ${c.partNumber ? "#" + c.partNumber : ""}`.trim())
                        .join(","),
                    prices: getBookPrices(book.book),
                    bookReviews: book.book.bookReviews ?? [],
                    id: book.documentId,
                });
            });
        return convertedBooks;
    }

    //Filter functionality
    function toggleTag(tagName): void {
        if (isTagSelected(tagName)) {
            removeTag(tagName);
        } else {
            addTag(tagName);
        }
    }
    function addTag(tag: string): void {
        setActiveFilter([tag]);
    }

    function isTagSelected(tagName): boolean {
        return activeFilter.find((tag) => tagName == tag) != undefined;
    }

    function removeTag(tagToRemove: string): void {
        setActiveFilter(activeFilter.filter((tags) => tags != tagToRemove));
    }

    function getNextLink() {
        return getQueryUrl({
            [CURSOR_AFTER_KEY]: cursorLast,
            [CatalogueFilterKeys.SEARCH_PATTERN_KEY]: searchPattern,
            [CatalogueFilterKeys.FILTER_KEY]: activeFilter.join(","),
        });
    }

    function getPrevLink() {
        return getQueryUrl({
            [CURSOR_AFTER_KEY]: cursorFirst,
            [CatalogueFilterKeys.SEARCH_PATTERN_KEY]: searchPattern,
            [CatalogueFilterKeys.FILTER_KEY]: activeFilter.join(","),
        });
    }

    function onPrev() {
        loadBooksEndBefore(orderByField, cursorFirst, pageSize, true);
    }

    function onNext() {
        loadBooksStartAfter(orderByField, cursorLast, pageSize, true);
    }

    return (
        <Column isFlex isFlexDirectionColumn isAlignItemsFlexStart className="catalogue-card-displayer p-0">
            <Column isFlex isFlexDirectionColumn isAlignItemsFlexStart className="catalogue-filter">
                <SearchField initialValue={searchPattern} onChange={setSearchPattern} />
                <BookFilters />
            </Column>

            {loading && <LoadingIndicator size="XL" />}
            <React.Suspense fallback={<LoadingIndicator size="XL" />}>
                <BookCardGrid bookCardDetails={displayBooks} loading={loading} />
            </React.Suspense>

            <Column isFlex isFlexDirectionColumn isAlignSelfFlexStart className="pagination-container">
                <Paginate
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    onPrev={onPrev}
                    onNext={onNext}
                    nextLink={getNextLink()}
                    prevLink={getPrevLink()}
                />
            </Column>
        </Column>
    );
}
