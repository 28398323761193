import { doc, getDoc } from "firebase/firestore";
import { useQuery, UseQueryResult } from "react-query";
import { FirestoreCollection, UserInfo } from "shared";

import { firestore } from "../../../firebase";
import { mapDocResult } from "../queries/queriesCommon";

export const USER_API_KEYS = {
    getUser: (userId?: string) => ["user", userId, "date"],
};
export default function useUserApi() {
    const getUser = (userId?: string): UseQueryResult<UserInfo | null> => {
        return useQuery({
            queryKey: USER_API_KEYS.getUser(userId),
            queryFn: async () => {
                console.debug("Fetching userinfo for userId", userId);
                try {
                    //@ts-ignore
                    return getDoc(doc(firestore, FirestoreCollection.USERS, userId));
                } catch (e) {
                    console.error("Error while fetching userinfo", e);
                    throw e;
                }
            },
            select: (data) => {
                console.debug("Got userinfo for userId", userId, "with data", data?.data());
                const userInfo = mapDocResult<UserInfo>()(data);
                if (!userInfo) return null;
                const name = userInfo.firstname + " " + userInfo.surname;
                return {
                    ...userInfo,
                    name,
                    fullname: userInfo?.fullname ?? name,
                };
            },
            enabled: Boolean(userId),
            suspense: true,
        });
    };

    return {
        getUser,
    };
}
