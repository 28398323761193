import "./AuthorProfileCatalogueDisplayer.css";

import LoadingIndicator from "components/LoadingIndicator";
import { Column, Content } from "components/trunx";
import React, { useEffect, useState } from "react";
import { Configure, useInstantSearch, usePagination } from "react-instantsearch";

import SearchField from "../../../../components/algolia/SearchField";
import { HighlightResult, IAuthorProfilesAlgolia } from "../../../../services/algolia/types";
import useFeatureToggle from "../../../../services/firestore/hooks/useFeatureToggle";
import { useCatalougeFilter } from "../CatalogueFilterContext";
import Paginate from "../Paginate";
import AuthorProfileCatalogueCard, { AuthorProfileCatalogueCardProps } from "./AuthorProfileCatalogueCard";

export default function AuthorProfileCardDisplayerAlgolia() {
    const isBokbasenEnabled = useFeatureToggle().isBokbasenEnabled();
    const { results, status } = useInstantSearch();
    const { nbPages, refine } = usePagination();
    const [pageSize, setPageSize] = useState<number>(12);
    const { currentPage, searchPattern, setCurrentPage, setSearchPattern, getNextLink, getPrevLink } =
        useCatalougeFilter();

    useEffect(() => {
        refine(currentPage);
    }, [currentPage]);

    //Utility method for mapping from BookData to CataloguecardProps
    function mapBookDataToCatalogueDisplayer(
        authorProfiles: IAuthorProfilesAlgolia[]
    ): AuthorProfileCatalogueCardProps[] {
        return (
            authorProfiles?.map((authorProfile) => ({
                id: authorProfile.authorId,
                profileId: authorProfile.objectID,
                displayName: authorProfile.displayName,
                displayNameHighlighted: (authorProfile._highlightResult["displayName"] as HighlightResult)?.value,
                description: authorProfile.description ?? "",
                descriptionHighlighted: (authorProfile._highlightResult["description"] as HighlightResult)?.value ?? "",
                profileImagePath: authorProfile.profilePicturePath,
                updatedDate: authorProfile.lastChanged,
            })) ?? []
        );
    }

    function onPrev() {
        setCurrentPage((page) => Math.max(0, page - 1));
    }

    function onNext() {
        setCurrentPage((page) => Math.min(nbPages - 1, page + 1));
    }

    function onSearchChange(value: string) {
        setSearchPattern(value);
        setCurrentPage(0);
    }

    function onPagesizeChange(pageSize: number) {
        setPageSize(pageSize);
        setCurrentPage(0);
    }
    function createAlgoliaFilterQuery() {
        return `language: NB AND source:"publizm"`;
    }

    const loading = status == "loading";
    const displayAuthorProfiles = mapBookDataToCatalogueDisplayer(results.hits) ?? [];
    return (
        <div className="catalogue-card-displayer author-card-displayer">
            <Configure hitsPerPage={pageSize} filters={createAlgoliaFilterQuery()} />
            <Column isFlex isFlexDirectionColumn isAlignItemsFlexStart className="catalogue-filter">
                <SearchField
                    label="Søk etter din favorittforfatter"
                    onChange={onSearchChange}
                    initialValue={searchPattern}
                />
            </Column>

            {loading && <LoadingIndicator size="XL" />}

            <div className="cards-container author-profile-cards-container">
                {displayAuthorProfiles.length === 0 && !loading ? <Content>Ingen treff på ditt søk</Content> : null}
                {displayAuthorProfiles.map((authorProfile) => {
                    return <AuthorProfileCatalogueCard {...authorProfile} key={authorProfile.id} />;
                })}
            </div>
            <Column isFlex isFlexDirectionColumn isAlignSelfFlexStart>
                <Paginate
                    label="Forfattere per side"
                    pageSize={pageSize}
                    currentPage={currentPage + 1}
                    nPages={nbPages}
                    setPageSize={onPagesizeChange}
                    onPrev={onPrev}
                    onNext={onNext}
                    nextLink={getNextLink()}
                    prevLink={getPrevLink()}
                />
            </Column>
        </div>
    );
}
