import CollapsibleExpandButton from "components/dashboard/CollapsibleExpandButton";
import PzButton from "components/form/PzButton";
import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import { formatDate } from "components/utils/DateUtils";
import { useNavigate } from "react-router-dom";
import { AuthorSource } from "shared";

import { AppPath } from "../../../../../common/AppPath";
import PzAlgoliaTable from "../../../../../components/table/PzAlgoliaTable";
import AuthorTableCollapsible from "./AuthorTableCollapsible";

export interface AuthorTableData {
    firstName?: string;
    lastName?: string;
    bornInCountry?: string;
    bornInYear?: string;
    roles: string[];
    fullName?: string;
    contactInfo?: string;
    lastChanged?: Date;
    id: string;
}
export interface AuthorTableProps {
    authorTableData: AuthorTableData[];
    algolia?: boolean;
    source: AuthorSource;
}

export default function AuthorTable({ authorTableData, algolia, source }: AuthorTableProps) {
    const navigate = useNavigate();

    function editAuthor(authorId: string) {
        navigate(AppPath.ADMIN_CREATE_AUTHOR, {
            state: {
                authorId: authorId,
            },
        });
    }
    function createRowAuthor(
        authorData: AuthorTableData,
        toggleCollapsible?: () => void,
        isOpenCollapsible?: boolean
    ): RowData {
        return {
            collapsibleComponent: <AuthorTableCollapsible authorId={authorData.id!} />,
            components: [
                {
                    content: (
                        <a onClick={() => editAuthor(authorData.id)}>
                            {authorData.firstName + " " + authorData.lastName}
                        </a>
                    ),
                    width: "25%",
                },
                {
                    content: formatDate(authorData.lastChanged) ?? "-",
                    width: "25%",
                },
                {
                    content: source == "bokbasen" ? authorData.bornInYear ?? "" : "skip",
                    width: "20%",
                },
                {
                    content: source == "bokbasen" ? authorData.bornInCountry ?? "" : "skip",
                    width: "20%",
                },
                {
                    content: authorData.roles.join(",") ?? "",
                    width: "20%",
                },
                {
                    content: authorData.contactInfo ?? "",
                    width: "20%",
                },
                {
                    content: <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />,
                    width: "1%",
                    className: "is-chevron-cell",
                },
            ].filter((r) => r.content !== "skip"),
        };
    }

    const columnsAuthor: ColumnData[] = [
        {
            label: "Profil",
            sortByProperty: !algolia ? "firstName" : undefined,
        },
        {
            label: "Endret",
            sortByProperty: !algolia ? "lastChanged" : undefined,
        },
        {
            label: source == "bokbasen" ? "Fødselsår" : "skip",
            sortByProperty: !algolia ? "bornInYear" : undefined,
        },
        {
            label: source == "bokbasen" ? "Fødselsland" : "skip",
            sortByProperty: !algolia ? "bornInCountry" : undefined,
        },
        {
            label: "Roller",
            sortByProperty: !algolia ? "roles" : undefined,
        },
        {
            label: "Kontaktinfo",
            sortByProperty: !algolia ? "contactInfo" : undefined,
        },
        {
            label: "",
        },
    ].filter((c) => c.label !== "skip");

    function getSearchDataAuthor(author: AuthorTableData): string {
        const lastChanged = formatDate(author.lastChanged) ?? "";
        return (author.firstName ?? "") + (author.lastName ?? "") + lastChanged + author.contactInfo;
    }

    if (!algolia) {
        return (
            <PzTable
                createRow={createRowAuthor}
                searchData={getSearchDataAuthor}
                data={authorTableData}
                columns={columnsAuthor}
                rowKeyGenerator={(data) => data.id}
                defaultSortIndex={1}
                tableAddOn={
                    source == "publizm" ? (
                        <PzButton isPrimary hasTextWeightSemibold onClick={() => navigate(AppPath.ADMIN_CREATE_AUTHOR)}>
                            Registrer ny +
                        </PzButton>
                    ) : undefined
                }
            />
        );
    }
    return (
        <PzAlgoliaTable
            createRow={createRowAuthor}
            data={authorTableData}
            columns={columnsAuthor}
            rowKeyGenerator={(data) => data.id}
            defaultSortIndex={1}
            tableAddOn={
                source == "publizm" ? (
                    <PzButton isPrimary hasTextWeightSemibold onClick={() => navigate(AppPath.ADMIN_CREATE_AUTHOR)}>
                        Registrer ny +
                    </PzButton>
                ) : undefined
            }
        />
    );
}
