import { ProductPurchaseMetadata } from "components/transaction/VippsInitiatePaymentButton";

import LoginForm from "./LoginForm";
import RedirectAfterLogin from "./RedirectAfterLogin";

interface LocationState {
    from: {
        pathname: string;
        search?: string;
    };
    productPurchaseMetadata: ProductPurchaseMetadata;
}

export default function LoginPanel() {
    return (
        <>
            <LoginForm />
            <RedirectAfterLogin />
        </>
    );
}
