import { Timestamp } from "firebase-admin/firestore";

import { UserRole } from ".";
import { FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";

export enum AgreementType {
    PRIVACY = "privacy",
    USER_TERMS = "userterms",
    CONFIDENTIALITY = "confidentiality",
    SALES_CONDITIONS = "salesconditions",
}

export type Agreement = AgreementBase;
export const AgreementLatestVersion = 1;

type AllAgreement = AgreementBase;

export interface AgreementBase extends PzFirestoreBase {
    date: Timestamp;
    roles: UserRole[];
    agreementType: AgreementType;
    agreementContent: string;
}

export interface UserAgreement {
    agreementType: AgreementType;
    documentId: string;
    approvedDate: Timestamp;
}
export function agreementDisplayValue(agreementType?: AgreementType): string {
    switch (agreementType) {
        case AgreementType.USER_TERMS:
            return "Brukervilkår";
        case AgreementType.PRIVACY:
            return "Personvern";
        case AgreementType.CONFIDENTIALITY:
            return "Taushetsplikt";
        case AgreementType.SALES_CONDITIONS:
            return "Kjøpsvilkår";
        default:
            return "Ukjent vilkår";
    }
}

export class AgreementVersionMapper extends FirestoreVersionMapper<Agreement, AllAgreement> {
    public latestVersion = AgreementLatestVersion;
    public mapToLatest(agreement: AllAgreement): Agreement {
        if (agreement.version === AgreementLatestVersion) {
            return agreement;
        }

        switch (agreement.version) {
            case undefined:
                return {
                    ...agreement,
                    version: AgreementLatestVersion,
                };
            default:
                throw new Error(`Mapper for agreement version ${agreement["version"]} not implemented`);
        }
    }
}
