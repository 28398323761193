import { useEffect, useState } from "react";
import { UserRole } from "shared";

import PzButton from "../../../components/form/PzButton";
import PzSelect2 from "../../../components/PzSelect2";
import PzSelectDropdown, { OptionProps } from "../../../components/PzSelectDropdown";
import { useAuth } from "../../../contexts/AuthContext";
import { UserService } from "../../../services/firestore/UserService";
import { useRoyaltyContext } from "./RoyaltyDashboardContext";
import SelectYearDropdown from "./SelectYearDropdown";
export default function RoyaltyFilter() {
    const { generateReport, loadReport, viewMonth } = useRoyaltyContext();
    const [selectedMonth, setSelectedMonth] = useState<number>(viewMonth);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [selectedUser, setSelectedUser] = useState<string>();
    const { activeRole } = useAuth();

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", gap: "5px", width: "100%" }}>
            <SelectYearDropdown onChange={setSelectedYear} />
            <SelectMonthDropdown
                onChange={(month) => {
                    loadReport(month, selectedYear, selectedUser);
                    setSelectedMonth(month);
                }}
            />
            {activeRole == UserRole.ADMIN && (
                <UserSelect
                    onChange={(user) => {
                        loadReport(selectedMonth, selectedYear, user);
                        setSelectedUser(user);
                    }}
                />
            )}
            <PzButton onClick={() => generateReport(selectedMonth, selectedYear, selectedUser)}>
                Generer ny rapport
            </PzButton>
        </div>
    );
}

function SelectMonthDropdown({ onChange }: { onChange: (month: number) => void }) {
    const { viewMonth } = useRoyaltyContext();
    function getMonthOptions(): OptionProps<number>[] {
        return [...Array(12).keys()].map((monthNumber) => {
            const date = new Date();
            date.setMonth(monthNumber);
            const label = date.toLocaleString("no-NB", {
                month: "long",
            });
            const firstLetter = label.charAt(0);
            const firstLetterCap = firstLetter.toUpperCase();
            const remainingLetters = label.slice(1);
            const capitalizedWord = firstLetterCap + remainingLetters;
            return {
                label: capitalizedWord,
                value: monthNumber + 1,
                isDefault: viewMonth == monthNumber + 1,
            };
        });
    }

    return (
        <PzSelectDropdown
            style={{ width: "150px" }}
            title="Velg måned"
            options={getMonthOptions()}
            onChanged={onChange}
        />
    );
}

const defaultValue = { label: "Velg en bruker", value: null, isDefault: true };
function UserSelect({ onChange }: { onChange: (userId?: string) => void }) {
    const { userInfo, activeRole } = useAuth();
    const [selectedUser, setSelectedUser] = useState(userInfo?.id);
    const [userOptions, setUserOptions] = useState<OptionProps<string | null>[]>([]);
    useEffect(() => {
        new UserService().getAllUsers().then((users) => {
            const options: OptionProps<string | null>[] = [
                defaultValue,
                ...users.map((user) => ({
                    label: user.name,
                    value: user.id,
                })),
            ];

            setUserOptions(options);
        });
    }, []);

    if (activeRole !== UserRole.ADMIN) {
        return null;
    }

    return (
        <PzSelect2
            title="Velg bruker"
            style={{ width: "250px" }}
            searchable
            options={userOptions}
            //@ts-ignore
            onChanged={(option) => {
                onChange(option?.value ?? undefined);
            }}
            selectedOption={userOptions.find((v) => v.value == selectedUser)}
        ></PzSelect2>
    );
}
