import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, getDocs, query } from "firebase/firestore";
import { useQuery } from "react-query";
import { BookGroup, FirestoreCollection } from "shared";

import { firestore } from "../../../../firebase";
import { mapQueryResult } from "../queriesCommon";
import { BookGroupKeys } from "./bookkeys";

export const BookGroupQueries = {
    allBookGroups: () => {
        return useQuery({
            queryKey: BookGroupKeys.all(),
            queryFn: () => getDocs(collection(firestore, FirestoreCollection.BOOK_GROUP)),
            select: mapQueryResult<BookGroup>(),
        });
    },
    allSubscribe: () => {
        return useFirestoreQuery(
            BookGroupKeys.all(true),
            query(collection(firestore, FirestoreCollection.BOOK_GROUP)),
            {
                subscribe: true,
                includeMetadataChanges: true,
            },
            {
                select: mapQueryResult<BookGroup>(),
                suspense: false,
            }
        );
    },
};
