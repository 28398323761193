export function removeNullOrUndefinedKeys<T>(obj?: T) {
    if (!obj) {
        return;
    }

    for (const propName in obj) {
        const object = obj[propName];

        if (
            object === null ||
            object === undefined ||
            (typeof object === "number" && isNaN(Number(object))) ||
            (typeof object === "string" && object === "")
        ) {
            delete obj[propName];
        }
    }
    return obj;
}

export function removeKeys<T>(obj: T, removeKeys: string[]) {
    removeKeys.forEach((key) => {
        delete obj[key];
    });
    return obj;
}

export function objectEmpty(obj: any) {
    return obj && Object.keys(obj).length === 0;
}

export function isEmpty(value?: string): value is string {
    return !value || value.trim().length === 0;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

type fn = (...vals: any) => void;
export function debounce(fn: fn, time = 1000): fn {
    let timeoutId;
    function wrapper(...args) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            timeoutId = null;
            fn(...args);
        }, time);
    }
    return wrapper;
}
