import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PzTag from "../../../components/PzTag";
import { CatalogueFilterKeys, useCatalougeFilter } from "./CatalogueFilterContext";

export default function BookFilters() {
    const { getFilterValueByKey, removeFilterValueByKey } = useCatalougeFilter();

    function renderFilterContent(value: string) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    alignItems: "center",
                }}
            >
                {value}
                <FontAwesomeIcon icon={faMinus} color="black" />
            </div>
        );
    }
    return (
        <div className="catalogue-filter-tags">
            {getFilterValueByKey(CatalogueFilterKeys.COLLECTION) && (
                <PzTag onClick={() => removeFilterValueByKey(CatalogueFilterKeys.COLLECTION)}>
                    {renderFilterContent(`Serie: ${getFilterValueByKey(CatalogueFilterKeys.COLLECTION)}`)}
                </PzTag>
            )}
            {getFilterValueByKey(CatalogueFilterKeys.GROUP) && (
                <PzTag onClick={() => removeFilterValueByKey(CatalogueFilterKeys.GROUP)}>
                    {renderFilterContent(`${getFilterValueByKey(CatalogueFilterKeys.GROUP)}`)}
                </PzTag>
            )}
            {getFilterValueByKey(CatalogueFilterKeys.SUBJECT_KEY) && (
                <PzTag onClick={() => removeFilterValueByKey(CatalogueFilterKeys.SUBJECT_KEY)}>
                    {renderFilterContent(`Emne: ${getFilterValueByKey(CatalogueFilterKeys.SUBJECT_KEY)}`)}
                </PzTag>
            )}
        </div>
    );
}
