import "./PageNotFound.css";

import PzButton from "components/form/PzButton";
import PzText from "components/text/PzText";
import { Title } from "components/trunx";
import { useNavigate } from "react-router-dom";

import { AppPath } from "../../common/AppPath";
import PageTemplate from "../../components/pagetemplate/PageTemplate";

interface PageNotFoundProps {
    description?: string;
}

export function PageNotFoundPage(props: PageNotFoundProps) {
    return (
        <PageTemplate>
            <PageNotFound {...props} />
        </PageTemplate>
    );
}

export function PageNotFound({ description }: PageNotFoundProps) {
    const navigate = useNavigate();

    function renderDescription() {
        return (
            <PzText hasTextCentered mb5>
                {description}
            </PzText>
        );
    }
    return (
        <div className="align-center">
            <Title is2 isAlignSelfCenter>
                Siden du forsøker å nå finnes ikke!
            </Title>
            {description && renderDescription()}
            <PzButton
                isPrimary
                isSize5
                style={{ margin: "0 auto", display: "block" }}
                onClick={() => navigate(AppPath.ROOT)}
            >
                Gå til hovedsiden
            </PzButton>
        </div>
    );
}
