import { UserInfoData, WrittenWorkData } from "./../../../services/firestore/types";
export default class SortEditor {
    static sort(writtenWorkData: WrittenWorkData) {
        return (editor1: UserInfoData, editor2: UserInfoData): number => {
            if (editor1.userInfo.tags && editor2.userInfo.tags) {
                const editor1NumberOfMatch = this.getNumberOfMatchingTags(editor1, writtenWorkData);
                const editor2NumberOfMatch = this.getNumberOfMatchingTags(editor2, writtenWorkData);
                const editor1AssignedTasks = this.getEditorAssigmentsRatio(editor1);
                const editor2AssignedTasks = this.getEditorAssigmentsRatio(editor2);
                const editor1Result = editor1NumberOfMatch * editor1AssignedTasks;
                const editor2Result = editor2NumberOfMatch * editor2AssignedTasks;
                return editor2Result > editor1Result ? -1 : 1;
            }
            return 0;
        };
    }

    static getEditorAssigmentsRatio(editor: UserInfoData): number {
        if (!editor.userInfo.assignments) {
            return 0;
        }
        const current = editor.userInfo.assignments ?? 0;
        return current;
    }

    static getNumberOfMatchingTags(editor: UserInfoData, writtenWorkData: WrittenWorkData): number {
        if (!editor.userInfo.tags) {
            return 0;
        }
        const writtenWorkTags: string[] = writtenWorkData.writtenWork.tags ?? [];
        return editor.userInfo.tags?.filter((tag) => this.hasMatchingTag(writtenWorkTags, tag)).length;
    }

    static hasMatchingTag(writtenWorkTags: string[], editorTag: string): boolean {
        return writtenWorkTags.some((tag) => tag.toUpperCase() === editorTag.toUpperCase());
    }
}
