import { faBook, faCheck, faFileUpload, faTag, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column, Columns, Label } from "components/trunx";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { UserRole } from "shared";

import { RenderWhen } from "../../components/auth/UserAuth";
import CheckSvg from "../../components/form/CheckSvg";
import StepIndicator from "../../components/form/StepIndicator";
import LoadingIndicator from "../../components/LoadingIndicator";
import PageTemplate from "../../components/pagetemplate/PageTemplate";
import PzInfoMessage from "../../components/PzInfoMessage";
import { getWrittenWorkEventFromUrl } from "../../components/utils/UrlUtils";
import { FormSteps, UploadProvider, useUpload } from "../../contexts/UploadContext";
import { PageNotFoundPage } from "../staticpages/PageNotFound";
import TagFilePage from ".//TagFilePage";
import UploadFilePage from "./UploadFilePage";
import UploadPageButtons from "./UploadPageButtons";
import UploadPitchPage from "./UploadPitchPage";
import UploadSuccessfullPage from "./UploadSuccessfullPage";

export default function UploadPage(): ReactElement {
    const location = useLocation();

    try {
        return (
            <PageTemplate>
                <RenderWhen userIs={UserRole.EDITOR}>
                    <PzInfoMessage
                        title={"Ikke tilgang"}
                        message="Du er logget inn med rollen 'Redaktør'. Brukere som er logget inn med denne rollen kan ikke levere verk."
                    />
                </RenderWhen>
                <RenderWhen userIsNot={UserRole.EDITOR}>
                    <UploadProvider writtenWorkEvent={getWrittenWorkEventFromUrl(location.search)}>
                        <UploadPageContent />
                    </UploadProvider>
                </RenderWhen>
            </PageTemplate>
        );
    } catch (e: any) {
        return <PageNotFoundPage description={e.message} />;
    }
}

function UploadPageContent(): ReactElement | null {
    const { currentStep, updateStep, highestVisitedStep, uploadState: uploadState } = useUpload();

    function renderForm() {
        switch (currentStep) {
            case FormSteps.UPLOAD_FILE:
                return <UploadFilePage />;
            case FormSteps.TAG_FILE:
                return <TagFilePage />;
            case FormSteps.PITCH:
                return <UploadPitchPage />;
            case FormSteps.FINISHED:
                return <UploadSuccessfullPage />;
            default:
                return null;
        }
    }

    function renderLoadingIndicator() {
        if (uploadState === "STALE" || currentStep === FormSteps.FINISHED) {
            return null;
        }

        switch (uploadState) {
            case "UPLOADING":
                return (
                    <Label>
                        <LoadingIndicator style={{ marginRight: "8px", verticalAlign: "middle", display: "inline" }} />
                        Lagrer
                    </Label>
                );
            case "UPLOADED":
                return (
                    <Label>
                        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginRight: "5px" }} />
                        Lagret
                    </Label>
                );
            case "ERROR":
                return (
                    <Label>
                        <FontAwesomeIcon icon={faTimes} color="red" style={{ marginRight: "5px" }} />
                        Det skjedde en feil ved opplasting av data
                    </Label>
                );
            default:
                return null;
        }
    }

    const isFinished = currentStep === FormSteps.FINISHED;
    return (
        <Columns isAlignItemsCenter isJustifyContentCenter isFlexDirectionColumn>
            <Column isFullMobile is10Desktop is6Widescreen isFullTablet>
                <StepIndicator
                    steps={[
                        {
                            label: "Dokument",
                            stepMarker: <FontAwesomeIcon icon={faFileUpload} />,
                            disabled: isFinished,
                        },
                        {
                            label: "Sjanger",
                            stepMarker: <FontAwesomeIcon icon={faTag} />,
                            disabled: highestVisitedStep < FormSteps.TAG_FILE || isFinished,
                        },
                        {
                            label: "Pitch",
                            stepMarker: <FontAwesomeIcon icon={faBook} />,
                            disabled: highestVisitedStep < FormSteps.PITCH || isFinished,
                        },
                        {
                            label: "Ferdig",
                            stepMarker: <CheckSvg />,
                            disabled: true,
                        },
                    ]}
                    activeStep={currentStep - 1}
                    onChange={(step) => updateStep(step + 1)}
                />
                {renderForm()}
                <Column hasTextCentered pt3 className={"is-vcentered"}>
                    {renderLoadingIndicator()}
                </Column>
            </Column>
            <UploadPageButtons />
        </Columns>
    );
}
