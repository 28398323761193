import "./PlaySampleButton.css";

import { faBookOpen, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppPath } from "common/AppPath";
import { useNavigate } from "react-router-dom";
import { Book, BookType, hasSample } from "shared";

import PzButton from "../../../../components/form/PzButton";

interface PlaySampleButtonProps {
    bookId: string;
    book: Book;
    isFeatured?: boolean;
}
export function PlaySampleButton({ bookId, book, isFeatured = false }: PlaySampleButtonProps) {
    const navigate = useNavigate();

    const sampleExists = hasSample(book);

    function getButtonLabel() {
        if (book.source == "bokbasen" && book.inventoryId) {
            return book.bookType == BookType.EBOOK ? "Les utdrag" : "Lytt til utdrag";
        }

        return "Lytt til utdrag";
    }

    function onButtonClick() {
        if (book.source == "bokbasen" && book.bookType == BookType.EBOOK) {
            navigate(AppPath.EBOOK, { state: { bookId, title: book?.title, viewSample: true } });
        } else {
            navigate(`${AppPath.AUDIOBOOK_SAMPLE}?id=${bookId}`);
        }
    }

    if (!sampleExists) {
        return null;
    }

    return (
        <PzButton
            className={isFeatured ? "play-sample-button is-featured" : "play-sample-button"}
            onClick={(e) => {
                onButtonClick();
                e.stopPropagation();
            }}
        >
            <FontAwesomeIcon
                size="sm"
                icon={book.source == "bokbasen" && book.bookType == BookType.EBOOK ? faBookOpen : faHeadphones}
            />
            {getButtonLabel()}
        </PzButton>
    );
}
