import { Column, Columns } from "components/trunx";
import { ReactElement } from "react";
import { UserInfo } from "shared";

import { PzTitle } from "../../components/text/PzTitle";
import DeleteUserMessage from "./DeleteUserMessage";
import UpdatePassword from "./UpdatePassword";
import UpdateUserInfo from "./UpdateUserInfo";

interface AuthorEditProfileProps {
    userInfo?: UserInfo;
}

export default function AuthorEditProfile({ userInfo }: AuthorEditProfileProps): ReactElement {
    return (
        <Columns pl0 ml0 pr0 mr0 isFlexGrow1 isFlex isFlexDirectionColumn isAlignItemsCenter>
            <Column id={"updateAuthorProfile"} isFlexGrow1>
                <PzTitle>Oppdater Forfatterprofil</PzTitle>
                <UpdateUserInfo userInfo={userInfo} />
                <UpdatePassword />
                <DeleteUserMessage />
            </Column>
        </Columns>
    );
}
