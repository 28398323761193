import PzText from "components/text/PzText";
import { PzTitle } from "components/text/PzTitle";
import { Container } from "components/trunx";
import { Assessment, WrittenWork } from "shared";

interface AssessmentMessageFormatProps {
    assessment: Assessment;
    writtenWork?: WrittenWork;
}

export default function AssessmentMessageFormat({ assessment, writtenWork }: AssessmentMessageFormatProps) {
    return (
        <Container isPaddingLess>
            <PzText style={{ whiteSpace: "pre-wrap" }} mb6 containerProps={{ p0: true }}>
                {assessment.introduction}
            </PzText>

            <AssessmentParagraph title="Tema" text={assessment.theme} />
            <AssessmentParagraph title="Motiv" text={assessment.motive} />
            <AssessmentParagraph title="Sjanger" text={assessment.genre} />
            <AssessmentParagraph title="Karakterer" text={assessment.characters} />
            <AssessmentParagraph title="Struktur" text={assessment.structure} />
            <AssessmentParagraph title="Språk" text={assessment.language} />
            <AssessmentParagraph title="Konklusjon" text={assessment.conclusion} />

            <PzText style={{ whiteSpace: "pre-wrap" }} mt6 mb3 containerProps={{ p0: true }}>
                {assessment.ending}
            </PzText>
        </Container>
    );
}

function AssessmentParagraph(props) {
    if (!props.text) {
        return null;
    }
    return (
        <PzText mb6 containerProps={{ p0: true }}>
            <PzTitle mb3 is5>
                {props.title}
            </PzTitle>
            {props.text}
        </PzText>
    );
}
