import React, { PropsWithChildren, ReactElement, useContext, useEffect, useMemo, useState } from "react";
import {
    Book,
    BookPrice,
    BookPriceType,
    BookReview,
    BookType,
    getBookCoverPath,
    getBookIdByType,
    getBookPrices,
    UserRole,
} from "shared";

import { hasBookTypeAnyFiles } from "../../../common/BookUtils";
import { useAuth } from "../../../contexts/AuthContext";
import { BookService } from "../../../services/firestore/BookService";
import useBookApi from "../../../services/firestore/hooks/useBookApi";
import { CustomerBookData } from "../../../services/firestore/types";
import { UserService } from "../../../services/firestore/UserService";
import { BookMetadataMapper } from "./meta/BookMetadataMapper";

export interface BookAccessDetails {
    hasEbookAccess: boolean;
    hasAudiobookAccess: boolean;
    hasBundleAccess: boolean;
}

export interface BookVersionDetails {
    title?: string;
    titleHighlighted?: string;
    author?: string;
    source?: "publizm" | "bokbasen";
    authorHighlighted?: string;
    collectionNames?: string[];
    collectionNamesHighlighted?: string;
    bookReviews?: BookReview[];
    format?: BookType;
    bookId?: string;
    price?: number;
    coverPath?: string;
    id?: string;
    updatedDate?: Date;
}

interface BookDetailsContextProps {
    customerBook: CustomerBookData | null;
    toogleBookFavorite: () => void;
    bookId: string;
    ebookId?: string;
    audioBookId?: string;
    book: Book;
    type: BookType;
    versionDetails: BookVersionDetails[];
    bundlePrice?: number;
    price?: number;
    hasAccess: BookAccessDetails;
    relatedBooks: Book[];
}

const BookDetailsContext = React.createContext<BookDetailsContextProps>({} as BookDetailsContextProps);

export function useBookDetails(): BookDetailsContextProps {
    return useContext(BookDetailsContext);
}

export function getPriceValueByType(book: Book, type: BookPriceType): number | undefined {
    return getPriceByType(book, type)?.value;
}

export function getPriceByType(book: Book, type: BookPriceType): BookPrice | undefined {
    return getBookPrices(book)?.find((p) => p.type === type);
}

interface BookDetailsProviderProps {
    bookId: string;
    book: Book;
    type: BookType;
}
export default function BookDetailsProvider(props: PropsWithChildren<BookDetailsProviderProps>): ReactElement {
    const [customerBook, setCustomerBook] = useState<CustomerBookData | null>(null);
    const { hasRole, currentUser } = useAuth();
    const [relatedBooks, setRelatedBooks] = useState<Book[]>([]);

    const [hasAccess, setHasAccess] = useState<BookAccessDetails>({
        hasEbookAccess: false,
        hasAudiobookAccess: false,
        hasBundleAccess: false,
    });
    useBookApi().loadEbook(props.bookId, hasAccess.hasEbookAccess || hasAccess.hasBundleAccess);
    const { bookId, children, book, type } = props;

    const bookVersionDetails: BookVersionDetails[] = useMemo(
        () => [getBookVersionDetails(BookType.EBOOK), getBookVersionDetails(BookType.AUDIO)],
        [book]
    );

    useEffect(() => {
        if (props.book && currentUser != null) {
            getUserAccess();
        }
    }, [props.book]);
    useEffect(() => {
        loadCustomerBook();
    }, []);
    useEffect(() => {
        findAndFetchRelatedBooks();
    }, []);

    async function findAndFetchRelatedBooks() {
        if (book.source == "bokbasen") {
            const bookRelatedBooks = book.relatedBooks ?? [];
            const books = await Promise.all(bookRelatedBooks.map((b) => new BookService().getById(b.bookId)));
            const booksFiltered = books.map((b) => b?.book).filter((b) => b != null);
            //@ts-ignore
            setRelatedBooks([book, ...booksFiltered]);
        } else {
            setRelatedBooks([book]);
        }
    }

    async function getUserAccess() {
        if (currentUser == null) return
        const bookId = props.bookId;
        const book = props.book;
        const transactionService = new UserService.Transaction();
        transactionService.getBookAccess(book, bookId).then((bookAccess: BookAccessDetails) => {
            if (book.source != "bokbasen" && hasRole(UserRole.ADMIN)) {
                setHasAccess({
                    hasEbookAccess: hasBookTypeAnyFiles(BookType.EBOOK, book),
                    hasAudiobookAccess: hasBookTypeAnyFiles(BookType.AUDIO, book),
                    hasBundleAccess: false,
                });
            } else {
                setHasAccess(bookAccess);
            }
        });
    }

    async function loadCustomerBook() {
        if (currentUser == null) return
        await new UserService.CustomerBook().getByBookIdAndType(bookId, type).then(setCustomerBook);
    }

    async function toogleBookFavorite() {
        await new UserService.CustomerBook().toogleBookAsFavorite(bookId, book, type);
        await loadCustomerBook();
    }

    function getBookVersionDetails(type: BookType): BookVersionDetails {
        switch (type) {
            case BookType.BUNDLE:
            case BookType.EBOOK:
                return {
                    coverPath: getBookCoverPath(book, BookType.EBOOK),
                    title: book?.title,
                    author: book.author?.name,
                    source: book.source,
                    format: BookType.EBOOK,
                    price: getPriceValueByType(book, BookPriceType.EBOOK),
                    bookId: getBookIdByType(book, bookId, BookType.EBOOK),
                    updatedDate: book.updatedDate?.toDate(),
                };
            case BookType.PODCAST:
            case BookType.AUDIO:
                return {
                    title: book?.title,
                    coverPath: getBookCoverPath(book, BookType.AUDIO),
                    author: book.author?.name,
                    format: BookType.AUDIO,
                    source: book.source,
                    price: getPriceValueByType(book, BookPriceType.AUDIOBOOK),
                    bookId: getBookIdByType(book, bookId, BookType.AUDIO),
                    updatedDate: book.updatedDate?.toDate(),
                };
        }
    }

    const values: BookDetailsContextProps = {
        book,
        customerBook,
        relatedBooks,
        hasAccess,
        bookId,
        audioBookId: BookMetadataMapper.getBookWithWithType({ book, documentId: bookId }, BookType.AUDIO),
        ebookId: BookMetadataMapper.getBookWithWithType({ book, documentId: bookId }, BookType.EBOOK),
        toogleBookFavorite,
        type,
        versionDetails: bookVersionDetails,
        bundlePrice: getPriceValueByType(book, BookPriceType.BUNDLE),
        price: getPriceValueByType(book, type == BookType.EBOOK ? BookPriceType.EBOOK : BookPriceType.AUDIOBOOK),
    };

    return <BookDetailsContext.Provider value={values}>{children}</BookDetailsContext.Provider>;
}
