import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import { ReactElement } from "react";
import { LicenseeBokbasen } from "shared";

import { LicenseeData } from "../../../../../services/firestore/types";

interface TableProps {
    licenseeDataList: LicenseeData[];
}
export default function BokbasenLicenseeAdministrationTable({ licenseeDataList }: TableProps): ReactElement {
    const columns: ColumnData[] = [
        {
            label: "Navn",
            sortByProperty: "licensee.name",
        },
        {
            label: "Inntekt",
            sortByProperty: "saleInfo.income",
        },
        {
            label: "Antall bøker solgt",
            sortByProperty: "saleInfo.numberOfSales",
        },
        {
            label: "Rabatt",
            sortByProperty: "licensee.discount",
        },
    ];

    function createRow(licensee: LicenseeData): RowData {
        const licenseeDetails = licensee.licensee as LicenseeBokbasen;
        const income = licensee.saleInfo?.income;
        const discount = licenseeDetails.discount ?? 0;
        return {
            components: [
                {
                    content: licensee.licensee.name ?? ".",
                    width: "25%",
                },
                {
                    //TODO: Remove conditional chaining. Added as a quick-fix to phantom documents in emulator
                    content: income ? income.toFixed(2) + ",-" : "-",
                    width: "15%",
                },
                {
                    content: licensee.saleInfo?.numberOfSales?.toString() ?? "-",
                    width: "15%",
                },
                {
                    content: discount?.toString() + "%",
                    width: "15%",
                },
            ],
        };
    }

    function getSearchData(data: LicenseeData): string {
        const licensee = data.licensee;
        return licensee.name + licensee.email;
    }

    return (
        <>
            <PzTable
                createRow={createRow}
                searchData={getSearchData}
                data={licenseeDataList}
                columns={columns}
                rowKeyGenerator={(data) => data.documentId}
                defaultSortIndex={1}
                enablePagination
            />
        </>
    );
}
