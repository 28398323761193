import { Book, UrlUtils } from "shared";

import { AppPath } from "../../../../common/AppPath";
import PzButton from "../../../../components/form/PzButton";
import PzText from "../../../../components/text/PzText";
import { PzTitle } from "../../../../components/text/PzTitle";
import LoadingIndicator from "../../../../components/LoadingIndicator";

interface ReceiptPageProps {
    book: Book;
    loggingIn?: boolean;
}

export default function ReceiptPage({ book, loggingIn }: ReceiptPageProps) {
    const authorName = book.author?.name;
    const title = book.title;
    return (
        <div
            style={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                maxWidth: "600px",
                margin: "0 auto",
                gap: "2rem",
            }}
        >
            <PzTitle isSize1 hasTextCentered>
                Takk for handelen!
            </PzTitle>
            <PzText
                isSize5
                style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem" }}
            >
                Dette er kvittering på ditt kjøp av
                <div>
                    <PzTitle is2>
                        {loggingIn ? <div>{title}</div> : <a href={UrlUtils.encodeBookUrlForBook(book, AppPath.BOOK_DETAILS)}>{title}</a>}
                    </PzTitle>
                    <div>av</div>
                    <div style={{ fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}>{authorName}</div>
                </div>
                <div>
                    Du kan gå direkte til boken du har kjøpt ved å klikke på tittelen over. Du finner også boken sammen
                    med andre bøker du har kjøpt under
                </div>
            </PzText>
            {loggingIn ? <LoadingIndicator label="Logger deg inn. Vennligst vent" />
                : <a className="ignore-hover" href={`${UrlUtils.createUrl(AppPath.CATALOGUE)}/my-books`}>
                    <PzButton isPrimary>Mine Bøker</PzButton>
                </a>}
        </div>
    );
}
