import React from "react";

import { bulmaClassName } from "./classNames";
import { ErrorBoundaryProps } from "./ErrorBoundary";
import { HelpersProps, SizeProps } from "./modifiers";
import { renderElement } from "./renderElement";

export interface TabsProps extends React.HTMLAttributes<HTMLElement>, ErrorBoundaryProps, HelpersProps, SizeProps {
    isBoxed?: boolean;
    isCentered?: boolean;
    isFullwidth?: boolean;
    isRight?: boolean;
    isToggle?: boolean;
    isToggleRounded?: boolean;
}

export class Tabs extends React.Component<TabsProps> {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = { hasError: false };

    render(): React.ReactNode {
        const {
            fallbackUI,
            isBoxed,
            isCentered,
            isFullwidth,
            isLarge,
            isMedium,
            isRight,
            isSmall,
            isToggle,
            isToggleRounded,
            ...props
        } = this.props;

        if (this.state.hasError) return fallbackUI;

        return renderElement("nav", props, bulmaClassName.tabs, {
            isBoxed,
            isCentered,
            isFullwidth,
            isLarge,
            isMedium,
            isRight,
            isSmall,
            isToggle,
            isToggleRounded,
        });
    }
}
