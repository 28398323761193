import { AgreementDashboardMenu } from "components/dashboard/Menu/DashboardUserMenu";
import PzButton from "components/form/PzButton";
import AgreementModal from "components/modal/AgreementModal";
import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import { formatDate } from "components/utils/DateUtils";
import { ReactElement, useEffect, useState } from "react";
import { AgreementData } from "services/firestore/types";
import { Agreement, agreementDisplayValue, userRoleDisplayValue } from "shared";

interface AgreementTableProps {
    agreementDataList: AgreementData[];
    updateAgreementData: () => void;
    setCurrentAgreementData: (agreementData: AgreementData | null) => void;
    updateSelectedMenu: (selectedMenu: AgreementDashboardMenu) => void;
}
export default function AgreementTable({
    agreementDataList,
    setCurrentAgreementData,
    updateSelectedMenu,
}: AgreementTableProps): ReactElement {
    const [renderAgreementDataList, setRenderAgreementDataList] = useState<AgreementData[]>(agreementDataList);
    const [showAgreementModal, setShowAgreementModal] = useState<boolean>(false);
    const [agreement, setAgreement] = useState<Agreement>();
    const closeAgreementModal = () => setShowAgreementModal(false);
    const openAgreementModal = (agreement: Agreement) => {
        setAgreement(agreement);
        setShowAgreementModal(true);
    };

    useEffect(() => {
        setRenderAgreementDataList(agreementDataList);
    }, [agreementDataList]);

    const columns: ColumnData[] = [
        {
            label: "Avtaletype",
            sortByProperty: "agreement.agreementType",
        },
        {
            label: "Roller",
            sortByProperty: "agreement.roles",
        },
        {
            label: "Dato opprettet",
            sortByProperty: "agreement.date",
        },

        {
            label: "Se avtale",
        },
        {
            label: "Endre avtale",
        },
    ];

    function createRow(agreementData: AgreementData): RowData {
        const roles: string = agreementData.agreement.roles.map(userRoleDisplayValue).join(", ") ?? "-";

        function updateAgreement(agreementData: AgreementData) {
            setCurrentAgreementData(agreementData);
            updateSelectedMenu(AgreementDashboardMenu.NEW_AGREEMENT);
        }

        return {
            components: [
                {
                    content: agreementDisplayValue(agreementData.agreement.agreementType) ?? "",
                    width: "28%",
                },
                {
                    content: roles,
                    width: "28%",
                },
                {
                    content: formatDate(agreementData.agreement.date) ?? "-",
                    width: "28%",
                },
                {
                    content: <PzButton onClick={() => openAgreementModal(agreementData.agreement)}>Åpne</PzButton>,
                    width: "8%",
                },
                {
                    content: (
                        <PzButton isOutlined isDanger onClick={() => updateAgreement(agreementData)}>
                            Endre
                        </PzButton>
                    ),
                    width: "8%",
                },
            ],
        };
    }

    return (
        <>
            <PzTable
                createRow={createRow}
                data={renderAgreementDataList}
                columns={columns}
                rowKeyGenerator={(data) => data.documentId}
                defaultSortIndex={2}
            />
            <AgreementModal isActive={showAgreementModal} agreement={agreement} onClose={closeAgreementModal} />
        </>
    );
}
