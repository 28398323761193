import { RenderWhen } from "components/auth/UserAuth";
import { Container } from "components/trunx";
import { useAssessment } from "contexts/AssessmentContext";
import { ReactElement } from "react";
import { UserRole } from "shared";

import PzButton from "../../components/form/PzButton";
import AssessmentInput from "./AssesmentInput";

interface AssessmentEditorProps {
    readOnly?: boolean;
}

export default function AssessmentEditor({ readOnly }: AssessmentEditorProps): ReactElement {
    const { updateAssessmentFormData, assessment } = useAssessment();

    return (
        <ul>
            <RenderWhen userIs={UserRole.ADMIN}>
                <AssessmentInput
                    className={"mb-1"}
                    readOnly={readOnly}
                    title="Introduksjon"
                    id="introduction"
                    description="Dette er starten av brevet til forfatteren av verket. Innledende hilsen legges til automatisk."
                    defaultValue={assessment?.introduction || ""}
                    onChangeValue={(value) => updateAssessmentFormData({ introduction: value })}
                />
                <Container mb6 isFlex isJustifyContentRight>
                    <PzButton
                        isInvisible={readOnly}
                        onClick={() => updateAssessmentFormData({ introduction: undefined })}
                        label={"Bruk standard introduksjon"}
                    />
                </Container>
            </RenderWhen>
            <AssessmentInput
                readOnly={readOnly}
                title="Sjanger"
                id="genre"
                description="Til hvilken grad forfatteren forståelse for novellen som sjanger, hvordan kommer det til syne?"
                defaultValue={assessment?.genre || ""}
                onChangeValue={(value) => updateAssessmentFormData({ genre: value })}
            />
            <AssessmentInput
                readOnly={readOnly}
                title="Tema"
                id="theme"
                description="Får forfatteren fram underliggende budskap?"
                defaultValue={assessment?.theme || ""}
                onChangeValue={(value) => updateAssessmentFormData({ theme: value })}
            />
            <AssessmentInput
                readOnly={readOnly}
                title="Motiv"
                id="motive"
                description="Til hvilken grad finner forfatteren spennende indre/ytre hendelser?"
                defaultValue={assessment?.motive || ""}
                onChangeValue={(value) => updateAssessmentFormData({ motive: value })}
            />
            <AssessmentInput
                readOnly={readOnly}
                title="Karakterer"
                id="characters"
                description="Er karakterende tydelige? Er de troverdige? Endrer de seg underveis?"
                defaultValue={assessment?.characters || ""}
                onChangeValue={(value) => updateAssessmentFormData({ characters: value })}
            />
            <AssessmentInput
                readOnly={readOnly}
                title="Struktur"
                id="structure"
                description="Til hvilken grad holder forfatteren seg til saken? Tar historien overraskende vendinger?"
                defaultValue={assessment?.structure || ""}
                onChangeValue={(value) => updateAssessmentFormData({ structure: value })}
            />
            <AssessmentInput
                readOnly={readOnly}
                title="Språk"
                id="language"
                description="Er språket effektivt? Blir det skjemmet av klisjeer? Hvordan vil du beskrive det?"
                defaultValue={assessment?.language || ""}
                onChangeValue={(value) => updateAssessmentFormData({ language: value })}
            />
            <AssessmentInput
                readOnly={readOnly}
                title="Konklusjon"
                id="conclusion"
                description="En setning om styrker og svakheter"
                defaultValue={assessment?.conclusion || ""}
                onChangeValue={(value) => updateAssessmentFormData({ conclusion: value })}
            />
            <RenderWhen userIs={UserRole.ADMIN}>
                <AssessmentInput
                    className={"mb-1"}
                    readOnly={readOnly}
                    title="Avslutning"
                    id="ending"
                    rows={10}
                    description="Dette er avslutningen av vurderingen. Avsluttende hilsen legges til automatisk."
                    defaultValue={assessment?.ending || ""}
                    onChangeValue={(value) => updateAssessmentFormData({ ending: value })}
                />
                <Container isFlex isJustifyContentRight>
                    <PzButton
                        isInvisible={readOnly}
                        onClick={() => updateAssessmentFormData({ ending: undefined })}
                        label="Bruk standard avslutning"
                    />
                </Container>
            </RenderWhen>
        </ul>
    );
}
