export function getFileUUIDFromFilePath<T extends string>(filePath?: T): string {
    if (!filePath) {
        return filePath as T extends string ? string : undefined;
    }
    const filePathSplit = filePath.split("/") as string[];
    if (filePathSplit.length === 1) {
        return filePath as string;
    }
    return filePathSplit.reverse()[0] as T extends string ? string : undefined;
}
