import PzButton from "components/form/PzButton";
import AgreementModal from "components/modal/AgreementModal";
import { Column, Container, Subtitle, Title } from "components/trunx";
import { useEffect, useState } from "react";
import { AgreementHelpers, AgreementService } from "services/firestore/AgreementService";
import { AgreementData } from "services/firestore/types";
import { AgreementType, UserInfo, UserRole } from "shared";

import { PzTitle } from "../../components/text/PzTitle";
import DeleteUserMessage from "./DeleteUserMessage";
import UpdatePassword from "./UpdatePassword";
import UpdateUserInfo from "./UpdateUserInfo";

interface CustomerEditProfileProps {
    userInfo?: UserInfo;
}

export default function CustomerEditProfile({ userInfo }: CustomerEditProfileProps) {
    const [showPrivacyAgreementModal, setShowPrivacyAgreementModal] = useState<boolean>(false);
    const [showUserAgreementModal, setShowUserAgreementModal] = useState<boolean>(false);
    const [latestUserAgreements, setLatestUserAgreements] = useState<AgreementData[]>([]);

    useEffect(() => {
        new AgreementService().getLatestRoleAgreements(UserRole.CUSTOMER).then(setLatestUserAgreements);
    }, []);

    return (
        <Container p4 isFlex isFlexDirectionRow isJustifyContentCenter>
            <Column isFullMobile is8Tablet is6Desktop isFlex isFlexDirectionColumn isAlignItemsStretch>
                <Title hasTextBlack hasTextWeightBold isSize1>
                    Din profil
                </Title>
                <Column pl0 ml0 pr0 mr0 isFlexGrow1>
                    <PzTitle>Oppdater brukerprofil</PzTitle>
                    <UpdateUserInfo userInfo={userInfo} />
                    <UpdatePassword />
                    <DeleteUserMessage />
                </Column>

                <Column mt5 isFlex isFlexDirectionColumn style={{ backgroundColor: "white" }}>
                    <Subtitle>Vilkår</Subtitle>
                    <Column isFlex isFlexDirectionRow isJustifyContentSpaceBetween pl0>
                        <PzButton isInverted hasTextLink onClick={() => setShowPrivacyAgreementModal(true)}>
                            Personvernregler
                        </PzButton>
                        <PzButton isInverted hasTextLink onClick={() => setShowUserAgreementModal(true)}>
                            Brukervilkår
                        </PzButton>
                    </Column>
                </Column>
            </Column>
            <AgreementModal
                agreement={
                    AgreementHelpers.getAgreementByTypeAndRole(latestUserAgreements, AgreementType.PRIVACY)?.agreement
                }
                isActive={showPrivacyAgreementModal}
                onClose={() => setShowPrivacyAgreementModal(false)}
            />
            <AgreementModal
                agreement={
                    AgreementHelpers.getAgreementByTypeAndRole(latestUserAgreements, AgreementType.USER_TERMS)
                        ?.agreement
                }
                isActive={showUserAgreementModal}
                onClose={() => setShowUserAgreementModal(false)}
            />
        </Container>
    );
}
