import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Book, BookBokbasen } from "shared";

import PzButton from "../../../../components/form/PzButton";

interface BlaIBokaButtonProps {
    book?: Book;
}

export default function BlaIBokaButton({ book }: BlaIBokaButtonProps) {
    if (!book || (book.source == "bokbasen" && !book.blaIBokaLink)) {
        return null;
    }
    function onButtonClick() {
        window.open((book as BookBokbasen).blaIBokaLink, "_blank");
    }

    return (
        <PzButton
            className={"play-sample-button"}
            onClick={(e) => {
                onButtonClick();
                e.stopPropagation();
            }}
        >
            <FontAwesomeIcon size="sm" icon={faExternalLinkAlt} />
            Bla i boka
        </PzButton>
    );
}
