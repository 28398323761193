import { CSSProperties } from "react";

import CoverImage from "./CoverImage";

interface BookCoverImageProps {
    coverPath?: string;
    isSmall?: boolean;
    style?: CSSProperties;
}
export default function BookCoverImage({ coverPath, isSmall, style }: BookCoverImageProps) {
    return (
        <div style={style ?? { minHeight: isSmall ? "180px" : "500px", maxWidth: isSmall ? "200px" : "350px" }}>
            <CoverImage coverPath={coverPath} />
        </div>
    );
}
