import { BookAuthor, BookSource, BookStatus } from "shared";
import { BookType } from "shared/lib/types/BookTypes";

import { OptionProps } from "../../../../../components/PzSelectDropdown";
import { BookLicenseeRole } from "./../../../../../../shared/types/BookTypes";
import { FirebaseUploadFile } from "./../../../../../services/storage/types";

export enum SubmitType {
    PUBLISH,
    SAVE,
}

export const bookStateOptions: OptionProps[] = [
    {
        value: BookStatus.DRAFT,
        label: "Kladd",
    },
    {
        value: BookStatus.PUBLISHED,
        label: "Publisert",
    },
];
export const languageOptions: OptionProps[] = [
    {
        value: "NB",
        label: "Norsk (Bokmål)",
    },
    {
        value: "EN",
        label: "Engelsk",
    },
    {
        value: "NN",
        label: "Norsk (Nynorsk)",
    },
];

export interface LicenceeFormValues {
    licenseeId: string;
    name: string;
    percentage: number;
    role: BookLicenseeRole;
    removed?: boolean;
}
export interface LicenceeCostFormValues {
    licenseeId: string;
    advance?: number;
    currentAdvance: number;
    removed?: boolean;
    deletable?: boolean;
}
export interface AudioBookFileFormValues {
    id?: string;
    title: string;
    durationSeconds: number;
    file: Partial<FirebaseUploadFile>;
    deleted?: boolean;
}

export interface AudioBookFormValues {
    narrator: string;
    translator: string;
    sounddesigner: string;
    contentType: {
        audiobook: boolean;
        radiodrama: boolean;
    };
    producer: string;
    price?: number;
    isbn: number;
    type: BookType;
    licensees?: LicenceeFormValues[];
    licenseeCosts?: LicenceeCostFormValues[];
    sample?: Partial<AudioBookFileFormValues>;
    files: Partial<AudioBookFileFormValues>[];
    cover: Partial<FirebaseUploadFile>;
    productionCost?: number;
}

export interface EbookFormValues {
    file: Partial<FirebaseUploadFile>;
    sample?: Partial<FirebaseUploadFile>;
    kindle?: Partial<FirebaseUploadFile>;
    price?: number;
    isbn?: number;
    translator?: string;
    pages?: number;
    licensees?: LicenceeFormValues[];
    licenseeCosts?: LicenceeCostFormValues[];
    samplePages?: number;
    cover: Partial<FirebaseUploadFile>;
    productionCost?: number;
}

export interface BookReview {
    rating: number;
    content: string;
}
export interface BookAdminFormValues {
    source: BookSource;
    mainBook: boolean;
    ebook: EbookFormValues;
    audiobook: AudioBookFormValues;
    bundlePrice: number;
    summary: string;
    tags: string[];
    reviews: string; //deprecated
    bookReview: BookReview;
    reviewsReaders: string; // deprecated
    title: string;
    author: BookAuthor;
    language: string;
    publishedYear: string;
    publisher: string;
    publisherLocation: string;
    state: BookStatus;
}

export function parseAudioFile(file: FirebaseUploadFile, onFileParse: (file: AudioBookFileFormValues) => void) {
    new AudioContext().decodeAudioData(file.data.slice(0) as ArrayBuffer, (audioFile) => {
        const duration = audioFile.duration;
        const audioBookFile: AudioBookFileFormValues = {
            file: file,
            durationSeconds: duration,
            title: "",
        };
        onFileParse(audioBookFile);
    });
}
