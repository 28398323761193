import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { faBook, faHeadphones, faPodcast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface EbookAudioIconsProps {
    showAudio: boolean;
    showEbook: boolean;
    showKindle: boolean;
    showPodcast: boolean;
}
export default function ShowBookTypeIcons({ showAudio, showEbook, showKindle, showPodcast }: EbookAudioIconsProps) {
    if (!showAudio && !showEbook && !showPodcast) {
        return <div>-</div>;
    }

    return (
        <span>
            {showEbook && (
                <span title="Ebok" style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon icon={faBook} color="black" />
                </span>
            )}
            {showAudio && (
                <span title="Lydbok" style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon icon={faHeadphones} color="black" />
                </span>
            )}
            {showPodcast && (
                <span title="Podcast" style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon icon={faPodcast} color="black" />
                </span>
            )}
            {showKindle && <FontAwesomeIcon title="Kindle" icon={faAmazon} color="black" />}
        </span>
    );
}
