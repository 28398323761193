import { getStateColor, renderLabel, StateOptionType } from "components/dashboard/DashboardState";
import { ReactElement, useEffect, useState } from "react";
import { getWrittenWorkStateDisplayValue, WrittenWorkState } from "shared";

import PzSelect2 from "../../../components/PzSelect2";
import { WrittenWorkData } from "../../../services/firestore/types";
import { WrittenWorkHelper, WrittenWorkService } from "../../../services/firestore/WrittenWorkService";

interface StateDropdownProps {
    writtenWorkData: WrittenWorkData;
    onStateChange: () => void;
}

function getStateOption(writtenWorkState: WrittenWorkState) {
    return {
        value: writtenWorkState,
        label: renderLabel(getWrittenWorkStateDisplayValue(writtenWorkState), getStateColor(writtenWorkState), true),
    };
}

function getAllStateOptions(): StateOptionType[] {
    return Object.values(WrittenWorkState).map((writtenWorkState: WrittenWorkState) =>
        getStateOption(writtenWorkState)
    );
}

export default function StateDropdown(props: StateDropdownProps): ReactElement {
    const [selectedOption, setSelectedOption] = useState<StateOptionType>(
        getStateOption(props.writtenWorkData.writtenWork.state)
    );

    useEffect(() => {
        setSelectedOption(getStateOption(props.writtenWorkData.writtenWork.state));
    }, [props.writtenWorkData.writtenWork.state]);

    function onOptionSelected(selected: StateOptionType | null) {
        if (!selected?.value) {
            return;
        }

        new WrittenWorkService()
            .changeWrittenWorkState(props.writtenWorkData, selected?.value)
            .then(props.onStateChange);
    }

    const renderOptions = getAllStateOptions().filter((option) =>
        WrittenWorkHelper.isAllowedStateChange(props.writtenWorkData.writtenWork, option.value)
    );

    return (
        <PzSelect2
            selectedOption={selectedOption}
            options={renderOptions}
            onChanged={onOptionSelected}
            controlStyle={{ width: "150px" }}
        />
    );
}
