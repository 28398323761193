import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { AppPath } from "../../../common/AppPath";
import { useAuth } from "../../../contexts/AuthContext";
import { AnalyticsApi } from "../../../services/firestore/queries/queriesCommon";
import userMenu, { MenuItem } from "./UserMenu";

export default function PzMenu() {
    const { activeRole } = useAuth();
    const menuItems = useMemo(() => userMenu(activeRole).filter((meny) => meny.enabled), [activeRole]);

    return (
        <>
            {menuItems.map((item) => {
                if (item.isMultiMenu === false) {
                    const isActiveClass = item.isCurrentLocation ? "is-active" : "";
                    return (
                        <a
                            key={`key-${item.label}`}
                            className={`navbar-item link ${isActiveClass}`}
                            href={item.location}
                            onClick={() => {
                                AnalyticsApi.logEvent("header_menu_tab_selected", { tab: item.label });
                            }}
                        >
                            {item.label}
                        </a>
                    );
                }
                return <MenuDropdown key={`key-${item.label}`} menuItem={item} />;
            })}
        </>
    );
}

interface MenuDropdownProps {
    menuItem: MenuItem;
}
function MenuDropdown({ menuItem }: MenuDropdownProps) {
    const location = useLocation();
    return (
        <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">{menuItem.label}</a>
            <div className="navbar-dropdown">
                {(menuItem.target as MenuItem[])
                    .filter((meny) => meny.enabled)
                    .map((item: MenuItem) => {
                        const isActiveClass = location.pathname === item.target ? "is-active" : "";
                        return (
                            <a
                                key={`key-${item.label}`}
                                className={`navbar-item is-hoverable ${isActiveClass}`}
                                href={item.target as AppPath}
                            >
                                {item.label}
                            </a>
                        );
                    })}
            </div>
        </div>
    );
}
