import {
    deleteObject,
    getDownloadURL,
    getMetadata,
    listAll,
    ref as storageRef,
    StorageReference,
    updateMetadata,
    uploadBytes,
} from "firebase/storage";
import { FirebaseError } from "firebase-admin";
import { v4 as uuidv4 } from "uuid";

import { auth, storage } from "../../firebase";
import {
    FilePath,
    FirebaseFileMetadata,
    FirebaseStorageErrorTypes,
    FirebaseStorageFolder,
    FirebaseStorageMetadata,
    FirebaseUploadFile,
} from "./types";

interface FileOptions {
    cache: boolean;
    cacheOptions?: {
        maxAge?: number;
    };
}
export class FirebaseStorage {
    userId?: string;
    folder: FirebaseStorageFolder = FirebaseStorageFolder.AUTHOR_FILES;
    storageRef: StorageReference;
    constructor(folder?: FirebaseStorageFolder, userId?: string, ref?: StorageReference) {
        this.userId = userId;
        this.folder = folder ?? FirebaseStorageFolder.AUTHOR_FILES;
        this.storageRef = ref ?? storageRef(storage);
    }

    getUserId(): string {
        if (this.userId) {
            return this.userId;
        }
        const firebaseUser = auth.currentUser;
        if (firebaseUser) {
            return firebaseUser.uid;
        }
        throw new Error("User not found. Make the user is logged in before doing this operation");
    }

    withUserId(userId: string): FirebaseStorage {
        return new FirebaseStorage(this.folder, userId);
    }

    withFolder(folder: FirebaseStorageFolder): FirebaseStorage {
        return new FirebaseStorage(folder, this.userId);
    }

    withPath(path?: string) {
        if (path) {
            return new FirebaseStorage(this.folder, this.userId, storageRef(this.storageRef, path));
        }
        return this;
    }

    getFolderRef = (): StorageReference => {
        let ref = storageRef(this.storageRef, this.folder);
        if (this.folder === FirebaseStorageFolder.AUTHOR_FILES) {
            ref = storageRef(ref, this.getUserId());
        }
        return ref;
    };
    getFileRef = (filePath: FilePath): StorageReference => storageRef(this.storageRef, filePath);

    async getUserFileList(): Promise<FirebaseFileMetadata[]> {
        return listAll(this.getFolderRef())
            .then((fileList) =>
                Promise.all(
                    fileList.items.map(async (file: StorageReference) => {
                        const metadata = await getMetadata(file);
                        return this.mapFile(metadata);
                    })
                )
            )
            .catch(handleError);
    }

    getFileUrl(filePath: FilePath): Promise<string> {
        return getDownloadURL(this.getFileRef(filePath));
    }

    async getFileMetadata(filePath: FilePath): Promise<FirebaseFileMetadata> {
        return await getMetadata(this.getFileRef(filePath)).then(this.mapFile);
    }

    async updateMetadata(filePath?: string, additionalMetadata?: FirebaseStorageMetadata) {
        const metadata = {
            customMetadata: {
                ...additionalMetadata,
            },
        };

        const fileRef = storageRef(this.storageRef, filePath);

        return updateMetadata(fileRef, metadata)
            .then(() => fileRef.fullPath)
            .catch((e) => console.log(e));
    }
    async updateFile(
        file: FirebaseUploadFile,
        filePath?: string,
        options?: FileOptions,
        additionalMetadata?: FirebaseStorageMetadata
    ): Promise<FilePath> {
        const metadata = {
            contentType: file.type,
            contentDisposition: `inline; filename=${file.name}`,
            customMetadata: {
                name: file.name,
                ...additionalMetadata,
            },
        };
        if (options?.cache) {
            metadata["cacheControl"] = `public, max-age=${options.cacheOptions?.maxAge ?? "604800"}`;
        }
        let fileRef;
        if (filePath) {
            fileRef = storageRef(this.storageRef, filePath);
        } else {
            fileRef = storageRef(this.getFolderRef(), uuidv4());
        }
        return uploadBytes(fileRef, file.data, metadata)
            .then(() => fileRef.fullPath)
            .catch(handleError);
    }

    async deleteUserFile(fileUUID: string): Promise<void> {
        deleteObject(storageRef(this.getFolderRef(), fileUUID))
            .then(() => fileUUID)
            .catch(handleError);
    }

    async deleteFile(filePath?: FilePath): Promise<void> {
        if (!filePath) {
            return;
        }
        deleteObject(storageRef(this.getFolderRef(), filePath))
            .then(() => filePath)
            .catch(handleError);
    }

    async mapFile(metadata: any): Promise<FirebaseFileMetadata> {
        const fileUrl = await new FirebaseStorage().getFileUrl(metadata.fullPath);
        return {
            id: metadata.name,
            name: metadata.customMetadata.name,
            filePath: metadata.fullPath,
            uploadDate: metadata.timeCreated,
            type: metadata.contentType,
            size: metadata.size,
            fileUrl,
        };
    }
}

function handleError(e: FirebaseError): never {
    if (e.code === FirebaseStorageErrorTypes.UNAUTHORIZED || e.code === FirebaseStorageErrorTypes.UNAUTHENTICATED) {
        throw new Error("Ingen tilgang");
    }

    throw e;
}
