import UpdateUserInfo from "components/auth/UpdateUserInfo";
import PzButton from "components/form/PzButton";
import { PzTitle } from "components/text/PzTitle";
import { Column, Container, Control, Field } from "components/trunx";
import { removeKeys, removeNullOrUndefinedKeys } from "components/utils/ObjectUtils";
import { FirebaseAuthErrorCode, useAuth } from "contexts/AuthContext";
import { InteresseProfilButton } from "pages/useradmin/signup/editor/InteresseProfilButton";
import { InteresseProfilSelect } from "pages/useradmin/signup/editor/InteresseProfilSelect";
import { SignupEditorCompanyInfo } from "pages/useradmin/signup/editor/SignupEditorCompanyInfo";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UserService } from "services/firestore/UserService";
import { CreateUserRequest, UserInfo } from "shared";

import DeleteUserMessage from "./DeleteUserMessage";
import UpdatePassword from "./UpdatePassword";

interface EditorEditProfileProps {
    userInfo?: UserInfo;
}

export default function EditorEditProfile({ userInfo }: EditorEditProfileProps) {
    useEffect(() => {
        methods.register("tags");
    }, []);

    const methods = useForm<UserInfo>({
        mode: "onBlur",
        defaultValues: {
            companyName: userInfo?.companyName,
            firstname: userInfo?.firstname,
            surname: userInfo?.surname,
            email: userInfo?.email,
            telephoneNumber: userInfo?.telephoneNumber,
            tags: userInfo?.tags,
            companyId: userInfo?.companyId,
            accountnumber: userInfo?.accountnumber,
        },
    });

    const { handleSubmit } = methods;
    const [formError, setFormError] = useState("");
    const [loading, setLoading] = useState(false);
    const { currentUser, updateEmail } = useAuth();
    const [showSelectIntresseProfil, setShowSelectInteresseProfil] = useState(false);

    function hideTagsSelectPage() {
        setShowSelectInteresseProfil(false);
    }

    function saveTagsAndCloseTagsSelectPage(tags: string[]) {
        methods.setValue("tags", tags);
        hideTagsSelectPage();
    }
    const selectedTags: string[] = methods.getValues("tags") ?? userInfo?.tags ?? [];

    const onSubmit = (data) => handleSubmitSignUp(data);

    async function handleSubmitSignUp(data) {
        setFormError("");
        setLoading(true);
        await updateEmail(data.email)
            .then(() => updateUserInfo(data))
            .catch((err) => {
                if (
                    err.code === FirebaseAuthErrorCode.EMAIL_EXISTS ||
                    err.code === FirebaseAuthErrorCode.EMAIL_IN_USE
                ) {
                    setFormError("Eposten er allerede i bruk");
                } else {
                    setFormError("Det skjedde en feil. Vennligst prøv på nytt");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function updateUserInfo(data) {
        const persistData: CreateUserRequest = {
            ...data,
            companyName: methods.getValues("companyName"),
            tags: methods.getValues("tags") ?? [],
        };
        const uploadData = removeNullOrUndefinedKeys(removeKeys(persistData, ["passwordConfirm", "password"]));

        currentUser &&
            uploadData &&
            new UserService()
                .updateUserInfo(uploadData, currentUser?.uid)
                .then(() => {
                    setFormError("");
                })
                .catch(() => {
                    setFormError("Oppdater feilet, prøv igjen");
                })
                .finally(() => {
                    setLoading(false);
                });
    }

    return (
        <Container>
            <Column id={"editor-edit-profile"} isFlexGrow1 m0 p2>
                <PzTitle>Oppdater profil</PzTitle>
                {formError && <div className="notification is-danger"> {formError} </div>}
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <UpdateUserInfo
                            telephoneNumberRequired={false}
                            defaultTelephoneNumber={userInfo?.telephoneNumber}
                            registerKindleEmail
                        />
                        <Column isHidden={!showSelectIntresseProfil} m0>
                            <InteresseProfilSelect
                                isUpdate={true}
                                initialTags={selectedTags}
                                onSubmit={saveTagsAndCloseTagsSelectPage}
                                onCancel={hideTagsSelectPage}
                            />
                        </Column>
                        <Column isHidden={showSelectIntresseProfil} p0>
                            <InteresseProfilButton
                                isUpdate={true}
                                onClick={() => setShowSelectInteresseProfil(true)}
                                tags={selectedTags}
                            />
                            <SignupEditorCompanyInfo />
                        </Column>
                        <Field isGrouped className="mt-5">
                            <Control>
                                <PzButton
                                    type="submit"
                                    isPrimary
                                    disabled={loading}
                                    isHidden={showSelectIntresseProfil}
                                    isLoading={loading}
                                >
                                    Oppdater profil
                                </PzButton>
                            </Control>
                        </Field>
                    </form>
                </FormProvider>

                {!showSelectIntresseProfil && <UpdatePassword />}
                <DeleteUserMessage />
            </Column>
        </Container>
    );
}
