import { CSSProperties } from "react";
import { BookReview } from "shared";

import TerningkastIkon from "../../../components/pz/TerningkastIcon";

interface BookCoverImageProps {
    coverPath?: string;
    isSmall?: boolean;
    style?: CSSProperties;
    bookReviews?: BookReview[];
}
export default function CoverImage({ coverPath, bookReviews }: BookCoverImageProps) {
    return (
        <>
            {coverPath && (
                <figure className="image" style={{ position: "relative" }}>
                    <img src={coverPath} alt="Cover image" />
                    <TerningkastIkon bookReviews={bookReviews} top="-13%" right="-17%" />
                </figure>
            )}
        </>
    );
}
