import { faChevronDown, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column, Content, Dropdown } from "components/trunx";
import { useState } from "react";

export interface PaginateProps {
    pageSize: number;
    label?: string;
    currentPage?: number;
    nPages?: number;
    nbHits?: number;
    totalHits?: number;
    setPageSize: (number) => void;
    onPrev: () => void;
    onNext: () => void;

    nextLink?: string;
    prevLink?: string;
}

const pageOptions = [12, 24, 48];

export default function Paginate({
    currentPage,
    nbHits,
    label,
    nPages,
    nextLink,
    prevLink,
    pageSize,
    setPageSize,
    onPrev,
    onNext,
}: PaginateProps) {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    return (
        <Column
            isFlex
            isFlexDirectionRow
            isAlignItemsCenter
            isFlexWrapWrap
            isJustifyContentFlexStart
            className="pagination"
        >
            <Content mb0 pb0>
                {label ? label : "Bøker per side:"}
            </Content>
            <Dropdown isClickable ml3 mr3 m0 p0 isActive={showDropdown}>
                <Dropdown.Trigger>
                    <Column isFlex isFlexDirectionRow isAlignItemsCenter onClick={() => setShowDropdown(!showDropdown)}>
                        <Content mb0 pb0 mr2>
                            {pageSize}
                        </Content>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </Column>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                    <Dropdown.Content>
                        {pageOptions.map((pageOption) => (
                            <Dropdown.Item
                                key={pageOption}
                                onClick={() => {
                                    setPageSize(pageOption);
                                    setShowDropdown(false);
                                }}
                            >
                                {pageOption}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Content>
                </Dropdown.Menu>
            </Dropdown>
            {currentPage ? (
                <div>
                    <div>
                        Viser side {currentPage} av {nPages}
                    </div>
                </div>
            ) : null}
            <div className="pagination-step-icons">
                <Content pb0 mb0 pr2 pl2 mr4 onClick={onPrev} isClickable>
                    <a href={`${window.location.origin}${prevLink}`} style={{ pointerEvents: "none" }}>
                        <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
                    </a>
                </Content>
                <Content pb0 pr2 pl2 mb0 onClick={onNext} isClickable>
                    <a href={`${window.location.origin}${nextLink}`} style={{ pointerEvents: "none" }}>
                        <FontAwesomeIcon icon={faChevronRight} size={"lg"} />
                    </a>
                </Content>
            </div>
        </Column>
    );
}
