import {
    arrayRemove,
    arrayUnion,
    CollectionReference,
    deleteDoc,
    deleteField,
    getDocs,
    increment,
    onSnapshot,
    orderBy,
    query,
    QueryFieldFilterConstraint,
    QuerySnapshot,
    setDoc,
    startAfter,
    startAt,
    updateDoc,
    where,
    WhereFilterOp,
} from "firebase/firestore";
import { FilePath, FirestoreCollection, removeNullOrUndefinedKeys } from "shared";
import { FirestoreVersionMapper } from "shared/types/PzFirestoreBaseTypes";
import { v4 as uuidv4 } from "uuid";

import { getFileUUIDFromFilePath } from "../../components/utils/FirestoreFilePathUtils";
import { auth } from "../../firebase";
import { FirestoreApi } from "./queries/queriesCommon";
import { DocumentData, OrderType } from "./types";

export interface Query {
    fieldPath: string;
    opStr: WhereFilterOp;
    value: any;
}

interface Order {
    attribute: string;
    order: OrderType;
}

export class FirestoreService<T> {
    protected collectionRef: CollectionReference;
    //@ts-ignore
    protected versioner: FirestoreVersionMapper<T, any>;
    protected collectionPath: FirestoreCollection;
    //@ts-ignore
    constructor(collectionPath: FirestoreCollection | string, versioner: FirestoreVersionMapper<T, any>) {
        this.collectionRef = FirestoreApi.getCollectionRef(collectionPath as FirestoreCollection);
        this.versioner = versioner;
        this.collectionPath = collectionPath as FirestoreCollection;
    }

    protected getUserId(): string {
        const firebaseUser = auth.currentUser;
        if (firebaseUser) {
            return firebaseUser.uid;
        }
        return "";
    }

    protected getDocumentFromCache(documentId: string): Promise<DocumentData<T> | null> {
        return this.getDocument(documentId, true);
    }

    protected getDocument(documentId: string, fromCache?: boolean): Promise<DocumentData<T> | null> {
        return FirestoreApi.getSingleDoc(this.collectionPath as FirestoreCollection, documentId, fromCache)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return { data: this.versioner.mapToLatest(snapshot.data() as T), id: snapshot.id };
                }
                return null;
            })
            .catch((err) => {
                console.log(
                    `Error occured while fetching from collection ${this.collectionRef.id} and document ${documentId}`,
                    err
                );
                return null;
            });
    }
    protected multiQuery(queries: Query[]): Promise<DocumentData<T>[]> {
        const whereQueries = queries.map((query) => where(query.fieldPath, query.opStr, query.value));
        return getDocs(FirestoreApi.getQueryFor(this.collectionPath, ...whereQueries))
            .then((s) => this.mapQueryResult(s))
            .catch((err) => {
                console.log(
                    `Error occured while fetching from collection ${this.collectionRef.id} with queries ${queries}`,
                    err
                );
                return [] as DocumentData<T>[];
            });
    }

    protected getAllSnapshot(onUpdate: (data: DocumentData<T>[]) => void, source?: "publizm" | "bokbasen"): () => void {
        const whereQueries: QueryFieldFilterConstraint[] = [];
        if (source == "bokbasen") {
            whereQueries.push(where("source", "==", "bokbasen"));
        } else if (source == "publizm") {
            whereQueries.push(where("source", "==", "publizm"));
        }
        return onSnapshot(
            query(FirestoreApi.getCollectionRef(this.collectionPath as FirestoreCollection), ...whereQueries),
            { includeMetadataChanges: true },
            async (s) => {
                if (!s.metadata.hasPendingWrites) {
                    onUpdate(this.mapQueryResult(s));
                }
            }
        );
    }

    protected query(
        fieldPath: string,
        opStr: WhereFilterOp,
        value: any,
        fromCache?: boolean
    ): Promise<DocumentData<T>[]> {
        const queryFn = FirestoreApi.getQueryFor(this.collectionPath, where(fieldPath, opStr, value));

        return FirestoreApi.getDocsForQuery(queryFn, fromCache)
            .then((s) => this.mapQueryResult(s))
            .catch((err) => {
                console.log(
                    `Error occured while fetching from collection ${this.collectionRef.id} with query ${fieldPath} ${opStr} ${value}`,
                    err
                );
                return [] as DocumentData<T>[];
            });
    }

    protected all(): Promise<DocumentData<T>[]> {
        return getDocs(FirestoreApi.getCollectionRef(this.collectionPath as FirestoreCollection))
            .then((s) => this.mapQueryResult(s))
            .catch((err) => {
                console.log(`Error occured while fetching from collection ${this.collectionRef.id}`, err);
                return [] as DocumentData<T>[];
            });
    }

    protected paginatedQueryStartAt(
        orderByField: string,
        startAfterConstraint: any,
        limit: number,
        queries: Query[]
    ): Promise<DocumentData<T>[]> {
        const whereQueries = queries.map((query) => where(query.fieldPath, query.opStr, query.value));
        return getDocs(
            FirestoreApi.orderByLimitQuery(
                FirestoreApi.getQueryFor(this.collectionPath, ...whereQueries),
                orderByField,
                limit,
                startAt(startAfterConstraint)
            )
        )
            .then((s) => this.mapQueryResult(s))
            .catch((err) => {
                console.log(`Error occured while fetching from collection ${this.collectionRef.id}`, err);
                return [] as DocumentData<T>[];
            });
    }

    protected paginatedQueryStartAfter(
        orderByField: string,
        startAfterConstraint: any,
        limit: number,
        queries: Query[]
    ): Promise<DocumentData<T>[]> {
        const whereQueries = queries.map((query) => where(query.fieldPath, query.opStr, query.value));
        return getDocs(
            FirestoreApi.orderByLimitQuery(
                FirestoreApi.getQueryFor(this.collectionPath, ...whereQueries),
                orderByField,
                limit,
                startAfter(startAfterConstraint)
            )
        )
            .then((s) => this.mapQueryResult(s))
            .catch((err) => {
                console.log(`Error occured while fetching from collection ${this.collectionRef.id}`, err);
                return [] as DocumentData<T>[];
            });
    }

    protected paginatedQueryEndBefore(
        orderByField: string,
        endBeforeConstraint: any,
        limit: number,
        queries: Query[]
    ): Promise<DocumentData<T>[]> {
        const whereQueries = queries.map((query) => where(query.fieldPath, query.opStr, query.value));
        return getDocs(
            FirestoreApi.orderByLimitQueryDesc(
                FirestoreApi.getQueryFor(this.collectionPath, ...whereQueries),
                orderByField,
                limit,
                startAfter(endBeforeConstraint)
            )
        )
            .then((s) => {
                const data = this.mapQueryResult(s);
                return data.reverse();
            })
            .catch((err) => {
                console.log(`Error occured while fetching from collection ${this.collectionRef.id}`, err);
                return [] as DocumentData<T>[];
            });
    }

    protected save(data: Partial<T>, documentId?: string): Promise<FilePath> {
        const firestoreDocumentId = documentId ?? uuidv4();
        const document = FirestoreApi.getSingleDocRef(this.collectionPath, firestoreDocumentId);
        const versionedData = this.versioner.addVersion(removeNullOrUndefinedKeys(data));
        //@ts-ignore
        return setDoc(FirestoreApi.getSingleDocRef(this.collectionPath, firestoreDocumentId), versionedData)
            .then(() => getFileUUIDFromFilePath(document.path))
            .catch((err) => {
                console.log(`Error saving collection ${this.collectionRef.id} with documentid ${documentId}`, err);
                throw err;
            });
    }

    protected update(data: Partial<T>, documentId?: string): Promise<FilePath> {
        const firestoreDocumentId = documentId ?? uuidv4();
        const document = FirestoreApi.getSingleDocRef(this.collectionPath, firestoreDocumentId);
        const versionedData = this.versioner.addVersion(removeNullOrUndefinedKeys(data));
        const result = documentId
            ? FirestoreApi.mutate(this.collectionPath, versionedData, firestoreDocumentId)
            : FirestoreApi.add(this.collectionPath, versionedData, firestoreDocumentId);
        return result
            .then(() => getFileUUIDFromFilePath(document.path))
            .catch((err) => {
                console.log(
                    `Error uploading to collection ${this.collectionRef.id} with documentid ${documentId}`,
                    err
                );
                throw err;
            });
    }

    protected multiOrder(orders: Order[]): Promise<DocumentData<T>[]> {
        const orderByQueries = orders.map((query) => orderBy(query.attribute, query.order));
        return getDocs(FirestoreApi.getQueryFor(this.collectionPath, ...orderByQueries))
            .then((s) => this.mapQueryResult(s))
            .catch((err) => {
                console.log(
                    `Error occured while fetching from collection ${this.collectionRef.id} with orders ${orders}`,
                    err
                );
                return [] as DocumentData<T>[];
            });
    }

    protected multiWhereOrder(queries: Query[], orders: Order[]): Promise<DocumentData<T>[]> {
        const whereQueries = queries.map((query) => where(query.fieldPath, query.opStr, query.value));
        const orderByQueries = orders.map((query) => orderBy(query.attribute, query.order));
        return getDocs(FirestoreApi.getQueryFor(this.collectionPath, ...orderByQueries, ...whereQueries))
            .then((s) => this.mapQueryResult(s))
            .catch((err) => {
                console.log(
                    `Error occured while fetching from collection ${this.collectionRef.id} with queries ${queries}`,
                    err
                );
                return [] as DocumentData<T>[];
            });
    }

    protected deleteField(field: string, documentId: string): Promise<FilePath> {
        const document = FirestoreApi.getSingleDocRef(this.collectionPath, documentId);
        return updateDoc(document, { [field]: deleteField() })
            .then(() => document.path)
            .catch((err) => {
                console.log(
                    `Error uploading to collection ${this.collectionRef.id} with documentid ${documentId}`,
                    err
                );
                throw err;
            });
    }

    protected removeFromArray(documentId: string, field: string, value: any[]): Promise<FilePath> {
        const document = FirestoreApi.getSingleDocRef(this.collectionPath, documentId);
        return updateDoc(document, { [field]: arrayRemove(...value) })
            .then(() => document.path)
            .catch((err) => {
                console.log(
                    `Error uploading to collection ${this.collectionRef.id} with documentid ${documentId}`,
                    err
                );
                throw err;
            });
    }
    protected appendOrUpdateArray(documentId: string, field: string, value: any[]): Promise<FilePath> {
        const document = FirestoreApi.getSingleDocRef(this.collectionPath, documentId);
        return updateDoc(document, { [field]: arrayUnion(...value) })
            .then(() => document.path)
            .catch((err) => {
                console.log(
                    `Error uploading to collection ${this.collectionRef.id} with documentid ${documentId}`,
                    err
                );
                throw err;
            });
    }

    protected increment(field: string, documentId: string, incrementBy?: number): Promise<FilePath> {
        const document = FirestoreApi.getSingleDocRef(this.collectionPath, documentId);
        return updateDoc(document, { [field]: increment(incrementBy ?? 1) })
            .then(() => document.path)
            .catch((err) => {
                console.log(
                    `Error uploading to collection ${this.collectionRef.id} with documentid ${documentId}`,
                    err
                );
                throw err;
            });
    }

    protected deleteDoc(documentId: string): Promise<boolean> {
        const document = FirestoreApi.getSingleDocRef(this.collectionPath, documentId);

        return deleteDoc(document)
            .then(() => true)
            .catch((err) => {
                false;
                console.log(
                    `Error deleting from collection ${this.collectionRef.id}, document documentid ${documentId}`,
                    err
                );
                throw err;
            });
    }

    protected mapQueryResult(snapshot: QuerySnapshot): DocumentData<T>[] {
        if (!snapshot.empty) {
            return snapshot.docs.map((doc) => ({ data: this.versioner.mapToLatest(doc.data() as T), id: doc.id }));
        }
        return [] as DocumentData<T>[];
    }
}
