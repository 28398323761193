import LoadingIndicator from "components/LoadingIndicator";
import { Column, Columns } from "components/trunx";
import { useDashboard } from "contexts/DashboardContext";
import React, { ReactElement, useEffect, useState } from "react";

import { AdminRoyaltyMenu } from "../../../components/dashboard/Menu/DashboardUserMenu";
import { useAuth } from "../../../contexts/AuthContext";
import { RoyaltyService } from "../../../services/firestore/RoyaltyService";
import { RoyaltyData } from "../../../services/firestore/types";
import { RoyaltyDashboardContext } from "./RoyaltyDashboardContext";
import RoyaltyReportButton from "./RoyaltyReportButton";
import RoyaltyTable from "./RoyaltyTable";

export default function RoyaltyDashboard(): ReactElement {
    const { selectedMenu } = useDashboard();
    const { userInfo } = useAuth();
    const [royaltyDataList, setRoyaltyDataList] = useState<RoyaltyData[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [viewMonth, setViewMonth] = useState(new Date().getMonth() + 1);
    const [viewYear, setViewYear] = useState(new Date().getFullYear());
    const [viewUser, setViewUser] = useState(userInfo?.id);

    useEffect(() => {
        setLoading(true);
        loadInitialData(viewMonth, viewYear, true, viewUser);
    }, []);

    function renderContent() {
        if (loading) {
            return <LoadingIndicator />;
        }

        if (selectedMenu == AdminRoyaltyMenu.YEARLY_REPORT) {
            return <RoyaltyReportButton />;
        }
        return <RoyaltyTable key={selectedMenu} data={royaltyDataList} />;
    }

    function loadInitialData(month: number, year: number, generateNewReport?: boolean, userId?: string) {
        setViewMonth(month);
        setViewYear(year);
        setViewUser(userId);
        setRoyaltyDataList(undefined);
        new RoyaltyService()
            .getRoyaltyiesForUserByMonthAndYear(month, year, generateNewReport, userId)
            .then(setRoyaltyDataList)
            .finally(() => setLoading(false));
    }

    return (
        <RoyaltyDashboardContext.Provider
            value={{
                viewMonth,
                viewUser,
                generateReport: (month: number, year: number, userId?: string) =>
                    loadInitialData(month, year, true, userId),
                loadReport: (month: number, year: number, userId?: string) =>
                    loadInitialData(month, year, false, userId),
            }}
        >
            <Columns isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                    {renderContent()}
                </Column>
            </Columns>
        </RoyaltyDashboardContext.Provider>
    );
}
