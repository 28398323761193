import { Column } from "components/trunx";
import React from "react";
import { ReactElement } from "react";

export default function DeleteUserMessage(): ReactElement {
    return (
        <Column pl0 ml0 pt5 mt5 mb0 pb0>
            Ønsker du å slette din bruker? Send email til{" "}
            <a href={"mailto: kontakt@publizm.com"}>kontakt@publizm.com</a>
        </Column>
    );
}
