import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column, Columns, Subtitle, Tag, Title } from "components/trunx";
import React, { ReactElement } from "react";

interface InteresseProfilButtonProps {
    onClick: () => void;
    tags: string[];
    isUpdate?: boolean;
}
export function InteresseProfilButton({ onClick, tags, isUpdate }: InteresseProfilButtonProps): ReactElement {
    return (
        <Columns>
            <Column ml0 isFlex isFlexDirectionColumn isJustifyContentSpaceAround>
                <Column p0 m0 isMarginLess isFlex isFlexDirectionRow isJustifyContentFlexStart style={{ gap: "10px" }}>
                    <Title isSpaced m0 is4 isMarginLess style={{ verticalAlign: "middle" }}>
                        {isUpdate ? "Oppdater interesseprofil" : "Lag en interesseprofil:"}
                    </Title>
                    <a href="#" onClick={onClick}>
                        <FontAwesomeIcon size={"2x"} icon={faArrowCircleRight} color="green" />
                    </a>
                </Column>
                <Column m0 p0 pt2>
                    <Subtitle is6>
                        {isUpdate
                            ? "Oppdater listen over sjangere du er interessert i, for å få en bedre match på dine oppdrag."
                            : " Lag en liste over sjangere du er interessert i, for å få bedre matcher på dine oppdrag."}
                    </Subtitle>
                </Column>
                <Column m0 pl0 isFlex isJustifyContentFlexStart isFlexWrapWrap style={{ gap: "5px" }}>
                    {tags?.map((tag, index) => (
                        <Tag isNormal isSuccess isMedium hasTextBlack key={String(index)}>
                            {tag}
                        </Tag>
                    ))}
                </Column>
            </Column>
        </Columns>
    );
}
