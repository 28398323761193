import { ReactElement } from "react";

import UserIcon from "../../assets/svg/user-icon.svg?react";

export default function DashboardUserIcon(): ReactElement {
    return (
        <div className="dashboard-user-icon">
            <UserIcon />
        </div>
    );
}
