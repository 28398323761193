import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control, Field, Input } from "components/trunx";
import React, { ReactElement, useRef, useState } from "react";

import { debounce } from "../utils/ObjectUtils";

export interface SearchProps {
    onChange: (value: string) => void;
    defaultValue?: string;
}

export function SearchField(props: SearchProps): ReactElement {
    const [input, setInput] = useState(props.defaultValue || "");

    const inputUpdated = useRef(
        debounce((value: string) => {
            props.onChange(value);
        }, 100)
    );

    function updateInput(value: string) {
        setInput(value);
        inputUpdated.current(value);
    }

    return (
        <Field hasAddons>
            <Control>
                <FontAwesomeIcon
                    icon={faSearch}
                    style={{ position: "absolute", zIndex: 10, top: "12px", left: "10px" }}
                />
                <Input
                    name="search"
                    isRounded
                    type="text"
                    defaultValue={props.defaultValue}
                    onChange={(e) => updateInput(e.target.value)}
                    value={input}
                    style={{ paddingLeft: "34px" }}
                />
            </Control>
        </Field>
    );
}
