import React from "react";

import LoadingIndicator from "../../../../../components/LoadingIndicator";
import useBookApi from "../../../../../services/firestore/hooks/useBookApi";

interface IBookSaleNumbersProps {
    bookId: string;
}
export default function BookSaleNumbers(props: IBookSaleNumbersProps) {
    return (
        <React.Suspense fallback={<LoadingIndicator />}>
            <BookSaleNumbersContent {...props} />
        </React.Suspense>
    );
}

function BookSaleNumbersContent({ bookId }: IBookSaleNumbersProps) {
    const { data: bookEconomics } = useBookApi().bookEconomics(bookId);

    return <div>{bookEconomics?.totalSales ?? "-"}</div>;
}
