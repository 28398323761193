import { signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
    AgreementType,
    CreateUserRequest,
    CreateUserVippsRequest,
    LoginWithVippsRequest,
    LoginWithVippsResponse,
    UserRole,
    VippsLoginUserInfo,
} from "shared";

import VippsLoginButton from "../../../../assets/svg/VippsLoginButton";
import VippsRegisterButton from "../../../../assets/svg/VippsRegisterButton";
import UpdateUserInfoForm from "../../../../components/auth/UpdateUserInfo";
import PzButton from "../../../../components/form/PzButton";
import PzFormInputField from "../../../../components/form/PzFormInputField";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import PzModal from "../../../../components/modal/PzModal";
import { PzTitle } from "../../../../components/text/PzTitle";
import { Column, Container, Modal } from "../../../../components/trunx";
import { FirebaseAuthError, FirebaseAuthErrorCode, useAuth } from "../../../../contexts/AuthContext";
import app, { auth, functionsEuropeNorth } from "../../../../firebase";
import { AnalyticsApi } from "../../../../services/firestore/queries/queriesCommon";
import RedirectAfterLogin from "../../login/RedirectAfterLogin";
import AgreementCheckbox from "../AgreementCheckbox";

interface SigninWithVippsProps {
    redirectPath: "LOGIN_PAGE" | "PURCHASE_PAGE";
    onLogin?: () => void;
    registrer?: boolean;
}
export default function SigninWithVipps({ redirectPath, onLogin, registrer }: SigninWithVippsProps) {
    const loginWithVippsFn = httpsCallable<LoginWithVippsRequest, LoginWithVippsResponse>(
        functionsEuropeNorth,
        "loginWithVipps"
    );
    const [searchParams, _] = useSearchParams();
    const code = searchParams.get("code"); // Is callback from vipps login
    const redirectUrl = window.location.origin + getPathName();
    const { data: userInfoResponse, isLoading } = useQuery({
        queryKey: "userInfo",
        queryFn: async (): Promise<LoginWithVippsResponse> => {
            const userInfoResponse = await loginWithVippsFn({ code: code, redirectUrl });
            return userInfoResponse.data!;
        },
        enabled: !!code,
    });

    function getPathName() {
        if (redirectPath === "LOGIN_PAGE") return "/login/vipps";
        if (redirectPath === "PURCHASE_PAGE") return "/purchase";
        return "/login";
    }
    async function redirectToVippsLogin() {
        const response = await loginWithVippsFn({ redirectUrl });
        window.open(response.data.redirectUrl, "_self");
    }

    useEffect(() => {
        if (userInfoResponse?.existingUser && userInfoResponse.customToken) {
            signInWithCustomToken(auth, userInfoResponse.customToken).then(() => {
                AnalyticsApi.logEvent("user_logged_in_with_vipps");
                onLogin?.();
            });
        }
    }, [userInfoResponse]);

    if (isLoading) return <LoadingIndicator />;

    if (userInfoResponse?.existingUser && redirectPath != "PURCHASE_PAGE") {
        return <RedirectAfterLogin />;
    }

    if (userInfoResponse?.userInfo && !userInfoResponse?.existingUser) {
        return <SignupWithVippsForm userInfo={userInfoResponse.userInfo} />;
    }

    return (
        <div onClick={redirectToVippsLogin} style={{ cursor: "pointer", marginLeft: "-7px" }}>
            {registrer ? <VippsRegisterButton /> : <VippsLoginButton />}
        </div>
    );
}

export interface SignUpWithVippsProps extends CreateUserRequest {
    acceptUserAgreements: boolean;
    acceptContentAgreements: boolean;
}
function SignupWithVippsForm({ userInfo }: { userInfo: VippsLoginUserInfo }) {
    const createUserVippsFn = httpsCallable<CreateUserVippsRequest, string>(functionsEuropeNorth, "createUserVipps");
    const [error, setError] = useState<"email_already_exists" | "unknown">();
    const [connectToExistingUser, setConnectToExistingUser] = useState(false);
    const [latestSubmittedData, setLatestSubmittedData] = useState<CreateUserRequest>();
    const { mutate: createUserWithVipps, isLoading } = useMutation({
        mutationFn: createUserVippsFn,
    });
    const methods = useForm<CreateUserRequest>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: {
            firstname: userInfo.given_name,
            surname: userInfo.family_name,
            email: userInfo.email,
            telephoneNumber: `+${userInfo.phone_number}`,
        },
    });

    async function onSubmit(data: CreateUserRequest) {
        setLatestSubmittedData(data);
        createUserWithVipps(
            {
                ...data,
                createUserType: UserRole.CUSTOMER,
                sub: userInfo.sub,
            },
            {
                onSuccess: (token) => {
                    signInWithCustomToken(auth, token.data);
                },
                onError: (error: any) => {
                    if (error?.code === "functions/already-exists") {
                        setError("email_already_exists");
                    } else {
                        setError("unknown");
                    }
                },
            }
        );
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <UpdateUserInfoForm defaultTelephoneNumber={userInfo.phone_number} registerKindleEmail={false} />
                    <AgreementCheckbox
                        agreementType={AgreementType.USER_TERMS}
                        role={UserRole.CUSTOMER}
                        name="acceptContentAgreements"
                        requiredMessage="Du må akseptere brukervilkårene"
                        agreementDescription="Publizms brukervilkår"
                    />
                    <AgreementCheckbox
                        agreementType={AgreementType.PRIVACY}
                        role={UserRole.CUSTOMER}
                        name="acceptUserAgreements"
                        requiredMessage="Du må akseptere vilkårene for personvern"
                        agreementDescription="vilkårene for personvern"
                    />
                    <PzButton isPrimary isLoading={isLoading} type="submit" value="Registrer" />
                    {error === "email_already_exists" && (
                        <div className="notification is-warning" style={{ marginTop: "15px" }}>
                            <div>
                                Det er allerede registrert en bruker med samme epost adresse. <br /> Ønsker du å koble
                                eksisterende bruker til Vipps innlogging?
                            </div>
                            <PzButton mt2 ml0 onClick={() => setConnectToExistingUser(true)}>
                                Koble til
                            </PzButton>
                        </div>
                    )}
                </form>
            </FormProvider>
            {connectToExistingUser && (
                <PzModal isActive>
                    <PzTitle is4>Logg inn</PzTitle>
                    <Modal.Card.Body>
                        <Container>
                            <SimpleLogin
                                email={latestSubmittedData?.email}
                                onLogin={() => onSubmit(latestSubmittedData!)}
                            />
                        </Container>
                    </Modal.Card.Body>
                </PzModal>
            )}
            <RedirectAfterLogin />
        </>
    );
}

interface LoginFormValues {
    email: string;
    password: string;
}
function SimpleLogin({ email, onLogin }: { email; onLogin: () => void }) {
    const { register, handleSubmit, errors, setError } = useForm<LoginFormValues>({
        defaultValues: {
            email,
        },
    });
    const { login } = useAuth();
    const [loading, setLoading] = useState(false);

    async function onSubmit(data: LoginFormValues) {
        login(data.email, data.password)
            .catch((error: FirebaseAuthError) => {
                if (error.code === FirebaseAuthErrorCode.USER_NOT_FOUND) {
                    setError("email", {
                        message:
                            "Ingen bruker registrert med denne eposten. Skriv på nytt eller registrer deg ny bruker.",
                        shouldFocus: true,
                    });
                } else {
                    setError("password", {
                        message: "Feil passord. Vennligst skriv ditt passord på nytt eller endre passord.",
                        shouldFocus: true,
                    });
                }
            })
            .finally(() => {
                setLoading(false);
                onLogin();
            });
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <PzFormInputField
                name="email"
                label="Epost"
                type="email"
                autoComplete="email"
                formError={errors.email}
                inputRef={register({ required: "Du må oppgi brukernavn" })}
            />
            <PzFormInputField
                name="password"
                label="Passord"
                type="password"
                autoComplete="current-password"
                formError={errors.password}
                inputRef={register({ required: "Du må oppgi passord" })}
            />
            <div>Etter innlogging vil din bruker bli koblet til din Vipps bruker.</div>
            <Column isFull pr0 pl0 isFlex isFlexDirectionRow style={{ gap: "10px" }}>
                <PzButton isFullwidth isPrimary disabled={loading} value="Logg in" type="submit" />
            </Column>
        </form>
    );
}
