import "./PublizmLogo.css";

import publizmLogo from "assets/svg/publizm-only-logo.svg";

import { AppPath } from "../common/AppPath";
import { useAuth } from "../contexts/AuthContext";

interface PublizmLogoProps {
    isLink: boolean;
    width?: number;
    height?: number;
}

export default function PublizmLogo(props: PublizmLogoProps) {
    const { isLink, width = 50, height = 50 } = props;

    const renderLogo = () => (
        <img
            className="publizm-logo"
            alt="publizmLogo"
            src={publizmLogo}
            style={{ width: `${width}px`, height: `${height}px` }}
        />
    );
    if (isLink) {
        const { currentUser } = useAuth();
        const link = currentUser ? AppPath.DASHBOARD : AppPath.ROOT;
        return <a href={link}>{renderLogo()}</a>;
    } else {
        return (
            <div
                className="publizm-logo-container"
                style={{
                    width: `${width * 2}px`,
                    height: `${height * 2}px`,
                }}
            >
                {renderLogo()}
            </div>
        );
    }
}
