import "./PzCarousel.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Li, Tabs, Ul } from "components/trunx";
import { ReactElement, useState } from "react";
//@ts-ignore TODO: FIxme
import SwipeableViews from "react-swipeable-views";

import { Container } from "./trunx/Container";
interface PzCarouselProps {
    items: ReactElement[];
}
export default function PzCarousel({ items }: PzCarouselProps): ReactElement {
    const [showItemIndex, setShowItemIndex] = useState<number>(0);

    return (
        <Container>
            <Tabs isCentered isToggle>
                <Ul>
                    {items.map((_, index) => {
                        const isActive = showItemIndex === index;
                        return (
                            <Li
                                className="carousel_button"
                                onClick={() => setShowItemIndex(index)}
                                isActive={showItemIndex === index}
                                key={index}
                            >
                                <FontAwesomeIcon
                                    className={`${isActive ? "color--primary" : ""} mr-2`}
                                    icon={[isActive ? "fas" : "far", "circle"]}
                                />
                            </Li>
                        );
                    })}
                </Ul>
            </Tabs>
            {/** @ts-ignore */}
            <SwipeableViews
                style={{ overflow: "hidden" }}
                ignoreNativeScroll
                enableMouseEvents
                resistance
                index={showItemIndex}
                onSwitching={(index) => {
                    setShowItemIndex(index);
                }}
            >
                {items.map((item) => (
                    <div className="swipeable-item">
                        <Container p0 m0 isFlex isJustifyContentCenter isFlexWrapNowrap>
                            {item}
                        </Container>
                    </div>
                ))}
            </SwipeableViews>
        </Container>
    );
}
