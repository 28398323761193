import { arrayUnion, Timestamp } from "firebase/firestore";
import { FilePath } from "services/storage/types";
import {
    Agreement,
    AgreementType,
    AgreementVersionMapper,
    createNewUserAgreement,
    FirestoreCollection,
    UserAgreement,
    UserInfo,
    UserRole,
} from "shared";

import { notEmpty } from "../../components/utils/ObjectUtils";
import { FirestoreService } from "./FirestoreService";
import { AgreementData, DocumentData, OrderType, UserInfoData } from "./types";
import { UserService } from "./UserService";

export class AgreementService extends FirestoreService<Agreement> {
    constructor() {
        super(FirestoreCollection.LEGAL_TERMS, new AgreementVersionMapper());
    }

    getAgreementByDocId(documentId: string): Promise<AgreementData | null> {
        return this.query("documentId", "==", documentId).then((docData) => {
            if (docData.length === 0) {
                return null;
            }
            return {
                agreement: docData[0].data,
                documentId: docData[0].id,
            };
        });
    }

    async getAllAgreements(): Promise<AgreementData[]> {
        return this.all().then((agreementDataList) => agreementDataList.map(this.mapAgreementData));
    }

    async getLatestRoleAgreements(role?: UserRole): Promise<AgreementData[]> {
        const agreementKeys = Object.values(AgreementType);
        if (!role) {
            return [];
        }
        const agreementPromises = agreementKeys.map(async (agreementKey) => {
            return await this.getLatestAgreement(agreementKey as AgreementType, role);
        });
        return (await Promise.all(agreementPromises)).filter(notEmpty);
    }

    async getLatestAgreementsAllRoles(): Promise<AgreementData[]> {
        const userRoles = Object.values(UserRole);

        const agreements: AgreementData[] = [];

        const agreementPromises = userRoles.map(async (userRole) => {
            return agreements.concat(await this.getLatestRoleAgreements(userRole as UserRole));
        });

        await Promise.all(agreementPromises);

        return agreements;
    }

    async getUserNewAgreements(userInfo: Partial<UserInfo>, activeRole?: UserRole): Promise<AgreementData[]> {
        const roleAgreements: AgreementData[] = await this.getLatestRoleAgreements(activeRole);
        const userAgreements: UserAgreement[] = userInfo?.agreements || [];
        console.log("getUserNewAgreements", roleAgreements, userAgreements, activeRole);
        return this.getUserNotApprovedAgreements(roleAgreements, userAgreements);
    }

    getUserNotApprovedAgreements(agreements: AgreementData[], userAgreements: UserAgreement[]): AgreementData[] {
        return agreements.filter(
            (agreement) => !userAgreements.some((userAgreement) => userAgreement.documentId == agreement.documentId)
        );
    }

    async getLatestAgreement(agreementType: AgreementType, role: UserRole): Promise<AgreementData | null> {
        return this.multiWhereOrder(
            [
                { fieldPath: "agreementType", opStr: "==", value: agreementType },
                { fieldPath: "roles", opStr: "array-contains", value: role },
            ],
            [{ attribute: "date", order: OrderType.DESC }]
        ).then((data) => {
            if (data.length === 0) {
                return null;
            }
            return this.mapAgreementData(data[0]);
        });
    }

    async updateUserAcceptAgreement(userInfo: Partial<UserInfoData>, agreementData: AgreementData): Promise<void> {
        if (!userInfo.id) {
            return;
        }

        const agreement: Agreement = agreementData.agreement;
        const newUserAgreement: UserAgreement = createNewUserAgreement(
            agreement,
            agreementData.documentId,
            Timestamp.now()
        );
        new UserService()
            // @ts-ignore
            .updateUserInfo({ agreements: arrayUnion(newUserAgreement) }, userInfo.id)
            .catch(console.log);
    }

    createNewAgreement(data: Agreement): Promise<FilePath> {
        if (data.date != null) {
            return this.update(data);
        }
        return this.update({ ...data, date: Timestamp.now() });
    }

    mapAgreementData(data: DocumentData<Agreement>): AgreementData {
        return {
            agreement: data.data,
            documentId: data.id,
        };
    }
}

export type AgreementRoleMap = Map<UserRole, AgreementData[]>;
export class AgreementHelpers {
    static getAllNewestAgreements(agreements: AgreementData[]): AgreementRoleMap {
        const agreementByRole: AgreementRoleMap = new Map();
        const agreementsSortedByDate = agreements.sort(
            (a, b) => b.agreement.date.toMillis() - a.agreement.date.toMillis()
        );

        const addAgreementToMap = (role: UserRole, agreement?: AgreementData): void => {
            if (agreement) {
                agreementByRole.has(role)
                    ? agreementByRole.get(role)?.push(agreement)
                    : agreementByRole.set(role, [agreement]);
            }
        };

        const agreementKeys = Object.values(AgreementType);
        const userRoles = Object.values(UserRole);

        agreementKeys.forEach((agreementKey) => {
            userRoles.forEach((role) => {
                const agreement = agreementsSortedByDate.find((agreement) => {
                    return (
                        agreement.agreement.agreementType === agreementKey && agreement.agreement.roles.includes(role)
                    );
                });
                addAgreementToMap(role, agreement);
            });
        });
        return agreementByRole;
    }

    static hasUserApprovedLatestAgreements(agreements: AgreementData[], userAgreements: UserAgreement[]): boolean {
        return agreements.every((agreement) =>
            userAgreements.some((userAgreement) => userAgreement.documentId === agreement.documentId)
        );
    }
    static getAgreementByTypeAndRole(
        agreements: AgreementData[],
        agreementType: AgreementType
    ): AgreementData | undefined {
        return agreements.find((agreement) => agreement.agreement.agreementType === agreementType);
    }
}
