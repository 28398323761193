import "./CardDisplayer.css";

import { PzTitle } from "components/text/PzTitle";
import { Column } from "components/trunx";

interface CardDisplayerProps {
    title?: string;
    children: any;
}

export default function CardDisplayer({ children, title }: CardDisplayerProps) {
    return (
        <Column p5 isFlex isFlexDirectionColumn isAlignItemsCenter className="catalogue-card-displayer">
            <PzTitle pl2 isAlignSelfFlexStart>
                {title}
            </PzTitle>
            <div className="cards-container catalogue-cards-container">{children}</div>
        </Column>
    );
}
