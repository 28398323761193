import "./VippsPaymentButtonWithLegal.css";

import AgreementModal from "components/modal/AgreementModal";
import { useEffect, useState } from "react";
import { AgreementHelpers, AgreementService } from "services/firestore/AgreementService";
import { AgreementData } from "services/firestore/types";
import { AgreementType, UserRole } from "shared";

import VippsInitiatePaymentButtton, { VippsInitiatePaymentButtonProps } from "./VippsInitiatePaymentButton";

/*
const agreement = `Publizm AS – org. nummer 926 714 929 – eier og drifter denne plattformen. Informasjon og åndsverk er eid av selskapet, innholdsleverandør og/eller partner, og kan ikke kopieres eller brukes uten skriftlig tillatelse.
Disse vilkårene utgjør avtalen du som medlem inngår med Publizm. Medlemskap tilbys til personer over 18 år eller yngre som har tillatelse av sine foresatte. Ved å registrere deg og ta i bruk tjenesten godtar du disse vilkårene. 

<h4>Avtalen</h4>
Avtalen består av disse salgsbetingelsene, opplysninger gitt i bestillingsløsningen og eventuelt særskilt avtalte vilkår. Ved eventuell motstrid mellom opplysningene, går det som særskilt er avtalt mellom partene foran, så fremt det ikke strider mot ufravikelig lovgivning.

<h4>Partene</h4>
Selger er Publizm, og betegnes i det følgende som selger/selgeren.
Kjøper er den forbrukeren som foretar bestillingen, og betegnes i det følgende som kjøper/kjøperen.

<h4>Medlemskap og pris</h4>
Som medlem (abonnent) hos oss i Publizm har du tilgang til alt innhold på vår plattform så lenge ditt medlemskap er aktivt. Vi tilbyr månedlig medlemskap som fornyes automatisk hver måned til det sies opp, uten bindingstid. 
Enkeltverk som e-bok og lydbok selges til stykkpris, der medlemmer tilbys en lavere pris enn ikke-medlemmer. Alle priser vises i norske kroner inkludert mva. Den oppgitte prisen for varen og tjenester er den totale prisen kjøper skal betale. Denne prisen inkluderer alle avgifter og tilleggskostnader.
Eventuelle kostander for datatrafikk i forbindelse med bruk av våre tjenester er for brukers egen regning. 

<h4>Betaling</h4>
Medlemskap betales forskuddsvis hver måned. Andre kjøp betales før tjenesten kan lastes ned eller tas i bruk. Betaling skjer ved trekk fra registrert bankkort eller Vipps.

<h4>Leveranse</h4>
Du kan umiddelbart laste ned eller streame innhold du har betalt for.

<h4>Angrerett</h4>
Avtalen er bindende for begge parter når kjøperen har sendt sin bestilling til selgeren. Du har rett til å melde deg ut når som helst, uten gebyr. Medlemskap løper ut måneden og refunderes ikke for inneværende (påbegynte) måned. Det er 14 dagers angrerett på førstegangs etablering av medlemskap. 
E-bøker/nedlastbare lydbøker – særskilte vilkår
Etter at du har aktivert boken opphører angreretten på e-bok/nedlastbar lydbok. Om det skulle være tekniske problemer eller andre vesentlige mangler med varen blir kjøpesummen kreditert i sin helhet. Reklamasjon sendes på e-post til kontakt@publizm.no Klager rettes til selger innen rimelig tid.

<h4>Personvern, Sikkerhet Og Personopplysninger</h4>
I forbindelse med bestillingen, godtar du at vi lagrer og bruker informasjonen din for å oppfylle våre forpliktelser overfor deg. Vi dokumenterer all kommunikasjon vi har med deg via e-post. Vi deler ikke din personlige informasjon til tredjepart.

<h4>Cookies</h4>
Publizm bruker cookies, tekstfiler som sendes fra et nettsted til datamaskinen hvor den lagres i minnet (session cookies) eller som tekstfiler (tekstbaserte informasjonskapsler). 
Cookies brukes til å lagre dine bestillinger og kjøp. Hvis din nettleser ikke aksepterer cookies, vil du ikke være i stand til å bruke Publizm. Men det kan enkelt endre ved å gå på innstillinger til nettleseren din. 

<h4>Force Majeure</h4>
Force majeure er særlige omstendigheter utenfor menneskelig kontroll, som vi ikke kan avverge. Eksempler er krig, opprør, blokade, naturkatastrofe, streik og lock-out, importrestriksjoner, hyperinflasjon, ran og innbrudd. Slike forhold – som hindrer Publizm i å gjennomføre sine operasjoner – frigjør selskapet fra sine forpliktelser. 

<h4>Endring av kontrakt</h4>
Kontrakten kan ikke endres uten skriftlig samtykke fra begge parter.

<h4>Tvister og lover</h4>
Norsk lov gjelder for alle kjøp gjort under nevnte vilkår. Partene skal forsøke å løse eventuelle tvister i minnelighet. Partene angir Oslo Tingrett som verneting.`;
*/

export default function VippsPaymentButtonWithLegal({
    initiatePayment,
    productPurchaseMetadata,
    setError,
}: VippsInitiatePaymentButtonProps): JSX.Element {
    const [displaySalesConditionsModal, setDisplaySalesConditionsModal] = useState<boolean>(false);
    const [latestUserAgreements, setLatestUserAgreements] = useState<AgreementData[]>([]);

    useEffect(() => {
        new AgreementService().getLatestRoleAgreements(UserRole.CUSTOMER).then(setLatestUserAgreements);
    }, []);

    function optionallyRenderSalesConditionModal() {
        return (
            <AgreementModal
                agreement={
                    AgreementHelpers.getAgreementByTypeAndRole(latestUserAgreements, AgreementType.SALES_CONDITIONS)
                        ?.agreement
                }
                isActive={displaySalesConditionsModal}
                onClose={() => setDisplaySalesConditionsModal(false)}
            />
        );
    }

    return (
        <div id="vipps-payment-section">
            <VippsInitiatePaymentButtton
                initiatePayment={initiatePayment}
                productPurchaseMetadata={productPurchaseMetadata}
                setError={setError}
            />
            <p className="agreement-link" onClick={() => setDisplaySalesConditionsModal(true)}>
                Ved å fullføre kjøpet aksepterer jeg kjøpsvilkårene
            </p>
            {optionallyRenderSalesConditionModal()}
        </div>
    );
}
