import "./PageTemplate.css";

import PzFooter from "components/pz/pzFooter";
import { Section } from "components/trunx";
import React, { HTMLAttributes, PropsWithChildren, useEffect } from "react";

import { AnalyticsApi } from "../../services/firestore/queries/queriesCommon";
import LoadingIndicator from "../LoadingIndicator";
import PageHeader2 from "./PageHeader2";
import TermsAndConditions from "./TermsAndConditions";

export interface PageTemplateProps extends HTMLAttributes<HTMLDivElement> {
    /* Enclose children i a <Section>-tag if true (default) */
    renderSection?: boolean;
    marginLessTop?: boolean;
}

function PageTemplate(props: PropsWithChildren<PageTemplateProps>) {
    const { renderSection = true, marginLessTop = false, children, ...otherProps } = props;
    useEffect(() => {
        AnalyticsApi.logPageView(window.location.pathname?.substring(1));
    }, []);
    return (
        <div>
            <div {...otherProps} id="page-container">
                <PageHeader2 />
                <TermsAndConditions>
                    {renderSection ? (
                        <Section id="page-content" className="mb-6">
                            <React.Suspense fallback={<LoadingIndicator />}>{children}</React.Suspense>
                        </Section>
                    ) : (
                        <div className={(!marginLessTop ? "mt-6" : "") + " mb-6"}>{children}</div>
                    )}
                </TermsAndConditions>
                <PzFooter />
            </div>
        </div>
    );
}

export default PageTemplate;
