import "./PageHeader.css";

import LoginButton from "components/LoginButton";
import UserLogo from "components/pagetemplate/UserLogo";
import PublizmLogo from "components/PublizmLogo";
import PzDivider from "components/pz/PzDivider";
import { useAuth } from "contexts/AuthContext";
import { ReactElement, useEffect, useState } from "react";
import PixelService from "services/PixelService";

import PzMenu from "./menu/PzMenu";

export default function PageHeader2(): ReactElement {
    const { currentUser, userInfo, activeRole } = useAuth();
    const [isBurgerMenuActive, setIsBurgerMenuActive] = useState<boolean>(false);
    const toggleBurgerMenu = () => setIsBurgerMenuActive((prevState) => !prevState);
    const pixelService = new PixelService();

    useEffect(() => {
        let advancedMatching;

        if (currentUser && userInfo) {
            advancedMatching = {
                em: currentUser.email,
                fn: userInfo?.firstname,
                ln: userInfo?.surname,
                ph: userInfo?.telephoneNumber?.replace("+", "").replace(" ", ""),
            };
        }

        pixelService.initializePageTracking({ autoConfig: true, debug: true }, advancedMatching);

        return function unsubscribe() {
            pixelService.revokeTrackingConsent();
        };
    }, []);
    const renderUserButton = () =>
        !currentUser ? <LoginButton /> : <UserLogo name={userInfo?.name ?? ""} role={activeRole} />;
    const isActiveClass = isBurgerMenuActive ? "is-active" : "";
    return (
        <>
            <nav className="navbar is-transparent" id="mainMenu">
                <div className="navbar-brand p-1">
                    <div id="navbar-logo" className="navbar-item">
                        <PublizmLogo isLink />
                    </div>
                    <div className="user-button is-hidden-desktop is-flex">{renderUserButton()}</div>
                    <a
                        aria-expanded={isBurgerMenuActive ? true : false}
                        className={"navbar-burger is-align-self-center is-size-2 " + isActiveClass}
                        aria-label="menu"
                        role="button"
                        onClick={toggleBurgerMenu}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div className={"navbar-menu " + isActiveClass} style={{ flexGrow: 15 }}>
                    <div className="navbar-start">
                        <PzMenu />
                    </div>
                </div>
                <div className="user-button is-flex is-clickable is-hidden-mobile is-hidden-tablet-only">
                    {renderUserButton()}
                </div>
            </nav>
            <PzDivider fullWidth />
        </>
    );
}
