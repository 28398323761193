import "./PzTag.css";

import { PropsWithChildren } from "react";
interface PzTagProps {
    variant?: "normal" | "danger" | "tertiary";
    size?: "small" | "medium" | "large";
    onClick?: () => void;
}
export default function PzTag({
    children,
    variant = "normal",
    size = "medium",
    onClick,
}: PropsWithChildren<PzTagProps>) {
    const clickable = onClick != undefined;
    return (
        <span onClick={onClick} className={`pz-tag ${size} ${clickable ? "clickable" : ""} ${variant}`}>
            {children}
        </span>
    );
}
