import "./BookUpload.css";

import { ReactElement } from "react";

import useUrlQueries from "../../../../../common/hooks/useQuery";
import PageTemplate from "../../../../../components/pagetemplate/PageTemplate";
import BookAdmin from "./BookAdmin";

export default function BookAdminPage(): ReactElement {
    const query = useUrlQueries();
    return (
        <PageTemplate marginLessTop>
            <BookAdmin bookId={query.get("id") === null ? undefined : query.get("id")!} />
        </PageTemplate>
    );
}
