import React, { PropsWithChildren, ReactElement, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { DashboardMenu, DashboardMenuType } from "../components/dashboard/Menu/DashboardUserMenu";

interface DashboardContextProps {
    selectedMenu?: DashboardMenuType;
    searchPattern?: string;
    updateSearchPattern: (value?: string) => void;
    updateSelectedMenu: (selectedMenu: string | DashboardMenuType) => void;
}

const DashboardContext = React.createContext<DashboardContextProps>({} as DashboardContextProps);

export function useDashboard(): DashboardContextProps {
    return useContext(DashboardContext);
}

export function DashboardProvider({ children }: PropsWithChildren<unknown>): ReactElement {
    const [selectedMenu, setSelectedMenu] = useState<DashboardMenuType>();
    const [searchPattern, setSearchPattern] = useState<string>();
    const [queryParams, setQueryParams] = useSearchParams();

    function updateSelectedMenu(selectedMenu: string | DashboardMenuType) {
        const selectedMenuEnum = Object.values(DashboardMenu).find((val) => val === selectedMenu);
        setSelectedMenu(selectedMenuEnum);
    }

    useEffect(() => {
        setQueryParams((prev) => {
            if (selectedMenu) {
                prev.set("tab", selectedMenu);
            } else {
                prev.delete("tab");
            }
            return prev;
        });
    }, [selectedMenu]);

    return (
        <DashboardContext.Provider
            value={{
                searchPattern,
                updateSearchPattern: setSearchPattern,
                selectedMenu: (queryParams.get("tab") as DashboardMenuType) ?? selectedMenu,
                updateSelectedMenu,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
}
