import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { ReactElement, useEffect, useState } from "react";
import { getWrittenWorkEventDisplayValue } from "shared";

import CollapsibleExpandButton from "../../../components/dashboard/CollapsibleExpandButton";
import DashboardWrittenWorkState from "../../../components/dashboard/DashboardWrittenWorkState";
import OpenFileLink from "../../../components/dashboard/OpenFileLink";
import WrittenWorksPanelCollapsible from "../../../components/dashboard/WrittenWorkCollapsbile";
import { ColumnData } from "../../../components/table/TableHead";
import { formatDate } from "../../../components/utils/DateUtils";
import { WrittenWorkData } from "../../../services/firestore/types";

interface WrittenWorksTableProps {
    writtenWorkDataList: WrittenWorkData[];
    updateWrittenWorks: () => void;
}
export default function EditorDashboardTable({ writtenWorkDataList }: WrittenWorksTableProps): ReactElement {
    const [renderWrittenWorkDataList, setRenderWrittenWorkDataList] = useState<WrittenWorkData[]>(writtenWorkDataList);

    useEffect(() => {
        setRenderWrittenWorkDataList(writtenWorkDataList);
    }, [writtenWorkDataList]);

    const columns: ColumnData[] = [
        {
            label: "Tittel",
            sortByProperty: "writtenWork.title",
        },
        {
            label: "Dato levert",
            sortByProperty: "writtenWork.uploadDate",
        },
        {
            label: "Status",
            sortByProperty: "writtenWork.state",
        },
        {
            label: "Event",
            sortByProperty: "writtenWork.writtenWorkEventId",
        },
        {
            label: "Forfatter",
            sortByProperty: "writtenWork.authorName",
        },

        {
            label: "Fillenke",
        },
        {
            label: "",
        },
    ];

    function createRow(
        writtenWorkData: WrittenWorkData,
        toggleCollapsible?: () => void,
        isOpenCollapsible?: boolean
    ): RowData {
        return {
            collapsibleComponent: <WrittenWorksPanelCollapsible writtenWorkData={writtenWorkData} />,
            components: [
                {
                    content: writtenWorkData.writtenWork.title ?? "",
                    width: "30%",
                },
                {
                    content: formatDate(writtenWorkData.writtenWork.uploadDate) ?? "-",
                    width: "2%",
                },
                {
                    content: <DashboardWrittenWorkState writtenWorkData={writtenWorkData} />,
                    width: "2%",
                },
                {
                    content: getWrittenWorkEventDisplayValue(writtenWorkData.writtenWork.writtenWorkEventId),
                    width: "17%",
                },
                {
                    content: writtenWorkData.writtenWork.authorName ?? "-",
                    width: "15%",
                },

                {
                    content: <OpenFileLink isLink filePath={writtenWorkData.writtenWork.filePath} />,
                    width: "2%",
                },
                {
                    content: <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />,
                    width: "1%",
                },
            ],
        };
    }

    return (
        <PzTable
            createRow={createRow}
            data={renderWrittenWorkDataList}
            columns={columns}
            rowKeyGenerator={(data) => data.documentId}
            defaultSortIndex={1}
        />
    );
}
