import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { ReactElement } from "react";

import IconText from "./text/IconText";
import PzText from "./text/PzText";
import { PzTitle } from "./text/PzTitle";

interface PzInfoMessageProps {
    icon?: IconProp;
    title?: string;
    message: string;
}

export default function PzInfoMessage(props: PzInfoMessageProps): ReactElement {
    const { icon = "exclamation-circle", title, message } = props;
    return (
        <>
            <PzTitle hasTextCentered is3>
                <IconText icon={icon}>{title}</IconText>
            </PzTitle>
            <PzText hasTextCentered>{message}</PzText>
        </>
    );
}
