import { AppPath } from "common/AppPath";
import { UserRole } from "shared";

export enum AuthorDashboardMenu {
    MY_WORK = "Mine verk",
    FEEDBACK = "Vurderte verk",
    ROYALTY = "Royalty",
}

export enum EditorDashboardMenu {
    ALL = "Alle",
    NEW = "Nye",
    UNDER_ASSESSMENT = "Vurderes",
    ASSESSED = "Vurderte",
    ARCHIVE = "Arkiv",
}

export enum AdminDashboardMenu {
    ALL = "Alle",
    DELIVERED = "Leverte",
    UNDER_ASSESSMENT = "Vurderes",
    ASSESSED = "Vurderte",
    ARCHIVE = "Arkiv",
    TESTDATA = "Testdata",
}

export enum UserAdminDashboardMenu {
    ALL = "Alle",
    AUTHORS = "Forfattere",
    EDITORS = "Redaktører",
    ADMINS = "Administrator",
}

export enum AgreementDashboardMenu {
    ALL = "Alle",
    NEWEST = "Nyeste",
    USER_TERMS = "Brukervilkår",
    PRIVACY = "Personvern",
    CONFIDENTIALITY = "Taushetsplikt",
    NEW_AGREEMENT = "Lag ny avtale",
}

export enum AdministrationDashboardMenu {
    PUBLIZM = "Publizm",
    BOKBASEN = "Bokbasen",
}

export enum AuthorAdminDashboardMenu {
    ADMIN_AUTHORS = "Publizm",
    ADMIN_AUTHORS_BOKBASEN = "Bokbasen",
}

export enum AdminListLicenseeMenu {
    LICENSEES = "Publizm",
    LICENSEES_BOKBASEN = "Bokbasen",
}

export enum AdminRoyaltyMenu {
    ROYALTY_REPORTS = "Royalty rapport",
    YEARLY_REPORT = "Årlig inntekter/utbetalinger",
}

export const DashboardMenu = {
    ...AuthorDashboardMenu,
    ...EditorDashboardMenu,
    ...AdminDashboardMenu,
    ...UserAdminDashboardMenu,
    ...AgreementDashboardMenu,
    ...AdministrationDashboardMenu,
    ...AuthorAdminDashboardMenu,
    ...AdminRoyaltyMenu,
    ...AdminListLicenseeMenu,
};
export type DashboardMenuType =
    | AuthorDashboardMenu
    | EditorDashboardMenu
    | AdminDashboardMenu
    | UserAdminDashboardMenu
    | AgreementDashboardMenu
    | AuthorAdminDashboardMenu
    | AdministrationDashboardMenu
    | AdminRoyaltyMenu
    | AdminListLicenseeMenu;

const dashboardUserMenu = (role?: UserRole, location?: AppPath): string[] => {
    switch (role) {
        case UserRole.AUTHOR:
            return Object.values(AuthorDashboardMenu);
        case UserRole.ADMIN:
            if (location === AppPath.USERADMIN) {
                return Object.values(UserAdminDashboardMenu);
            }
            if (location === AppPath.MANAGE_AGREEMENTS) {
                return Object.values(AgreementDashboardMenu);
            }
            if (location === AppPath.CATALOG_ADMIN) {
                return Object.values(AdministrationDashboardMenu);
            }
            if (location === AppPath.ADMIN_AUTHORS) {
                return Object.values(AuthorAdminDashboardMenu);
            }
            if (location === AppPath.ADMIN_LICENSEE_DASHBOARD) {
                return Object.values(AdminListLicenseeMenu);
            }
            if (location === AppPath.ROYALTY_DASHBOARD) {
                return Object.values(AdminRoyaltyMenu);
            }
            if (location === AppPath.ADMIN_TRANSACTIONS) {
                return Object.values(AdministrationDashboardMenu);
            }
            return Object.values(AdminDashboardMenu);
        case UserRole.EDITOR:
            return Object.values(EditorDashboardMenu);
        default:
            return Object.values(AuthorDashboardMenu);
    }
};

export default dashboardUserMenu;
