import "./SearchField.css";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "components/trunx";
import { useEffect, useMemo } from "react";
import { useSearchBox } from "react-instantsearch";

import { debounce } from "../utils/ObjectUtils";

interface SearchFieldProps {
    label?: string;
    initialValue?: string;
    onChange?: (value: string) => void;
}
export default function SearchField({ initialValue, onChange, label }: SearchFieldProps) {
    const { query, refine } = useSearchBox();
    const debouncedSetSearchPattern = useMemo(
        () =>
            debounce((e) => {
                const searchValue = e.target.value.toLowerCase().trim();
                onChange?.(searchValue);
                refine(searchValue);
            }, 350),
        []
    );

    useEffect(() => {
        initialValue && refine(initialValue);
    }, []);
    return (
        <div className="search-container">
            <Input
                defaultValue={initialValue}
                isRounded
                onChange={debouncedSetSearchPattern}
                placeholder={label ?? "Hva er din greie?"}
                style={{ minHeight: 56, width: 480 }}
                className="text-xl search-input"
            />
            <FontAwesomeIcon icon={faSearch} style={{ position: "relative", left: "-2rem" }} />
        </div>
    );
}
