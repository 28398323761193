import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faGavel, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { Column, Container, Content } from "components/trunx";
import { judgementComparator } from "components/utils/JudgementComparator";
import { ReactElement } from "react";
import {
    AssessmentMetadata,
    getWrittenWorkEventDisplayValue,
    getWrittenWorkStateDisplayValue,
    WrittenWorkEvent,
    WrittenWorkState,
} from "shared";

import CollapsibleExpandButton from "../../../components/dashboard/CollapsibleExpandButton";
import { STATE_COLOR } from "../../../components/dashboard/DashboardState";
import OpenFileLink from "../../../components/dashboard/OpenFileLink";
import ShowUserInfoModalLink from "../../../components/dashboard/ShowUserInfoModalLink";
import WrittenWorksPanelCollapsible from "../../../components/dashboard/WrittenWorkCollapsbile";
import PzTable from "../../../components/table/PzTable";
import { RowData } from "../../../components/table/TableBody";
import { ColumnData } from "../../../components/table/TableHead";
import PzText from "../../../components/text/PzText";
import { PzTitle } from "../../../components/text/PzTitle";
import { formatDate } from "../../../components/utils/DateUtils";
import { UserInfoData, WrittenWorkData } from "../../../services/firestore/types";
import { WrittenWorkService } from "../../../services/firestore/WrittenWorkService";
import EditorList from "./EditorList";
import StateDropdown from "./StateDropdown";

interface AdminDashboardTableProps {
    writtenWorkDataList: WrittenWorkData[];
    editors: UserInfoData[];
    updateWrittenWorks: () => void;
}
export default function AdminDashboardTable({
    editors,
    writtenWorkDataList,
    updateWrittenWorks,
}: AdminDashboardTableProps): ReactElement {
    function onAssign(writtenWorkData: WrittenWorkData, editor: UserInfoData) {
        new WrittenWorkService()
            .assignWrittenWork(writtenWorkData.documentId, editor)
            .catch((error) => console.log(error))
            .finally(updateWrittenWorks);
    }

    const columns: ColumnData[] = [
        {
            label: "Tittel",
            sortByProperty: "writtenWork.title",
        },
        {
            label: "Dato levert",
            sortByProperty: "writtenWork.uploadDate",
        },
        {
            label: "Forfatter",
            sortByProperty: "writtenWork.authorName",
        },
        {
            label: "Status",
            sortByProperty: "writtenWork.state",
            tooltip: StatusTooltipContent,
        },
        {
            label: "Redaktør",
            sortByProperty: "writtenWork.assignedToEditor.name",
        },
        {
            label: "Event",
            sortByProperty: "writtenWork.writtenWorkEventId",
        },
        {
            label: "Sist aktivitet",
            sortByProperty: "writtenWork.assessmentMetadata.lastOpened",
            tooltip: SistAktivtetTooltipContent,
        },
        {
            label: JudgementColumnHeader,
            customComparator: judgementComparator,
        },
        {
            label: "Fillenke",
        },
        {
            label: "",
        },
    ];

    function renderWrittenWorkEvent(writtenWorkEventId?: string) {
        const event = writtenWorkEventId ? WrittenWorkEvent.get(writtenWorkEventId) : undefined;

        if (event) {
            return (
                <Tippy
                    className="pz-tooltip"
                    content={event.name}
                    maxWidth={1000}
                    animation="scale-subtle"
                    inertia
                    interactive
                    hideOnClick="toggle"
                    theme="light-border"
                >
                    <div>{event.id}</div>
                </Tippy>
            );
        } else {
            return "-";
        }
    }

    function renderLastActivityDate(assessmentMetadata?: AssessmentMetadata) {
        if (assessmentMetadata) {
            return formatDate(assessmentMetadata.lastOpened) + ` (${assessmentMetadata.openedQuantity ?? 1})`;
        } else {
            return "-";
        }
    }

    function renderJudgement(assessmentMetadata?: AssessmentMetadata) {
        return <FontAwesomeIcon icon={getJudgementIcon(assessmentMetadata)} />;
    }

    function getJudgementIcon(assessmentMetadata?: AssessmentMetadata) {
        switch (assessmentMetadata?.judgement) {
            case true:
                return faThumbsUp;
            case false:
                return faThumbsDown;
            default:
                return faQuestion;
        }
    }

    function createRow(
        writtenWorkData: WrittenWorkData,
        toggleCollapsible?: () => void,
        isOpenCollapsible?: boolean
    ): RowData {
        return {
            collapsibleComponent: (
                <WrittenWorksPanelCollapsible
                    writtenWorkData={writtenWorkData}
                    editorListComponent={
                        writtenWorkData.writtenWork.state === WrittenWorkState.DELIVERED ? (
                            <EditorList editors={editors} onAssign={onAssign} writtenWorkData={writtenWorkData} />
                        ) : undefined
                    }
                />
            ),
            components: [
                {
                    content: writtenWorkData.writtenWork.title ?? "",
                    width: "25%",
                },
                {
                    content: formatDate(writtenWorkData.writtenWork.uploadDate) ?? "-",
                    width: "5%",
                },
                {
                    content: (
                        <ShowUserInfoModalLink
                            userMetadata={{
                                userId: writtenWorkData.writtenWork.authorId,
                                name: writtenWorkData.writtenWork.authorName ?? "-",
                                email: "",
                            }}
                        />
                    ),
                    width: "20%",
                },
                {
                    content: (
                        <StateDropdown
                            key={writtenWorkData.documentId}
                            writtenWorkData={writtenWorkData}
                            onStateChange={updateWrittenWorks}
                        />
                    ),
                    width: "5%",
                },
                {
                    content: <ShowUserInfoModalLink userMetadata={writtenWorkData.writtenWork.assignedToEditor} />,
                    width: "20%",
                },
                {
                    content: renderWrittenWorkEvent(writtenWorkData.writtenWork.writtenWorkEventId),
                    width: "6%",
                },
                {
                    content: renderLastActivityDate(writtenWorkData.writtenWork.assessmentMetadata),
                    width: "14%",
                },
                {
                    content: renderJudgement(writtenWorkData.writtenWork.assessmentMetadata),
                    width: "5%",
                },
                {
                    content: <OpenFileLink isLink filePath={writtenWorkData.writtenWork.filePath} />,
                    width: "4%",
                },
                {
                    content: <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />,
                    width: "1%",
                    className: "is-chevron-cell",
                },
            ],
        };
    }

    function getSearchData(data: WrittenWorkData): string {
        const writtenWork = data.writtenWork;
        const judgement = writtenWork.assessmentMetadata?.judgement;
        return (
            (writtenWork.authorName ?? "") +
            (writtenWork.title ?? "") +
            writtenWork.pitch +
            writtenWork.tags?.join("") +
            formatDate(writtenWork.uploadDate) +
            getWrittenWorkStateDisplayValue(writtenWork.state) +
            getWrittenWorkEventDisplayValue(writtenWork.writtenWorkEventId) +
            writtenWork.writtenWorkEventId +
            writtenWork.assignedToEditor?.name +
            writtenWork.assignedToEditor?.email +
            writtenWork.assignedToEditor?.telephoneNumber +
            (judgement === undefined ? "A-" : judgement ? "A1" : "A0") +
            writtenWork.assessmentMetadata?.lastOpened
        );
    }

    return (
        <PzTable
            createRow={createRow}
            searchData={getSearchData}
            data={writtenWorkDataList}
            columns={columns}
            rowKeyGenerator={(data) => data.documentId}
            defaultSortIndex={1}
        />
    );
}

const JudgementColumnHeader = <FontAwesomeIcon icon={faGavel} />;

export const SistAktivtetTooltipContent = (
    <Container>
        <PzTitle pb0 mb2 hasTextCentered isSize4 style={{ borderBottom: "0.1px solid #1514141f" }}>
            Sist aktivitet
        </PzTitle>
        <Column isSize6>
            <div>Denne kolonnen viser sist aktivitet på vurderingen av verket.</div>
            <div>Sist aktivitet bli bare oppdatert når redaktør besøker vurderingssiden.</div>
            <div>Hvis det ikke finnes noen vurdering vil raden være tom.</div>
            <br />
            <div>Sist aktivitet vises på følgende format: </div>
            <Content mt2 hasTextGrey>
                {"Dato vurdering ble sist åpnet av redaktør (antall ganger vurdering er åpnet)"}
            </Content>
            <div>Eksempel:</div>
            <Content hasTextGrey pb0 mb2>
                08/06/2021 (5)
            </Content>
            <div>
                Her ble verket sist åpnet <strong>08/06/2021</strong> og totalt <strong>5</strong> ganger.
            </div>
        </Column>
    </Container>
);

export const StatusTooltipContent = (
    <Container>
        <PzTitle pb0 mb2 hasTextCentered isSize4 style={{ borderBottom: "0.1px solid #1514141f" }}>
            Status
        </PzTitle>
        <PzText isSize6 p0 m0 mb4 containerProps={{ m0: true, p0: true }}>
            Her kan du endre status på verket
        </PzText>
        <Container pt2 isSize6>
            <PzTitle mb1 pb0 hasTextWeightMedium isSize5>
                Forklaring av de ulike fargekodene:
            </PzTitle>
            <Column>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                    <FontAwesomeIcon
                        style={{ marginTop: "3px" }}
                        size="xs"
                        icon={["fas", "circle"]}
                        color={STATE_COLOR.WAITING_FOR_RESPONS}
                    />
                    <div>Venter respons fra bruker</div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                    <FontAwesomeIcon
                        style={{ marginTop: "3px" }}
                        size="xs"
                        icon={["fas", "circle"]}
                        color={STATE_COLOR.NEEDS_ACTION}
                    />
                    <div>Krever handling av admin</div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                    <FontAwesomeIcon
                        style={{ marginTop: "3px" }}
                        size="xs"
                        icon={["fas", "circle"]}
                        color={STATE_COLOR.DONE_REFUSED}
                    />
                    <div>Verket er ikke antatt</div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                    <FontAwesomeIcon
                        style={{ marginTop: "3px" }}
                        size="xs"
                        icon={["fas", "circle"]}
                        color={STATE_COLOR.DONE_ACCEPTED}
                    />
                    <div>Verket er akseptert</div>
                </div>
            </Column>
        </Container>
        <Container pt2 isSize6>
            <PzTitle mb1 pb0 hasTextWeightMedium isSize5>
                Endring av status:
            </PzTitle>
            <Column>
                <div>Det er noe regler for hvordan du endrer på Status</div>
                <div>Det skilles på om verket har tildelt en redaktør eller ikke</div>
                <div>Følgende statuser har tildelt redaktør:</div>
                <Content pb0 mb1>
                    <ul>
                        <li>{getWrittenWorkStateDisplayValue(WrittenWorkState.ASSIGNED)}</li>
                        <li>{getWrittenWorkStateDisplayValue(WrittenWorkState.UNDER_ASSESSMENT)}</li>
                        <li>{getWrittenWorkStateDisplayValue(WrittenWorkState.ASSESSED)}</li>
                        <li>{getWrittenWorkStateDisplayValue(WrittenWorkState.ACCEPTED)}</li>
                        <li>{getWrittenWorkStateDisplayValue(WrittenWorkState.REFUSED)}</li>
                    </ul>
                </Content>
                <div>Følgende statuser har IKKE tildelt redaktør:</div>
                <Content pt0 mt0>
                    <ul>
                        <li>{getWrittenWorkStateDisplayValue(WrittenWorkState.DRAFT)}</li>
                        <li>{getWrittenWorkStateDisplayValue(WrittenWorkState.DELIVERED)}</li>
                    </ul>
                </Content>
                <div>Hvis et verk har tildelt redaktør kan du endre status til alle mulige statuser.</div>
                <div>
                    Hvis du endrer til en status som ikke har tildelt redaktør vil tildelt redaktør bli fjernet fra
                    verket og redaktør vil ikke lenger få tilgang til verket.
                </div>
                <br />
                <div>
                    Hvis et verk ikke har tildelt redaktør kan du bare endre til status som ikke har tildelt redaktør
                    eller til <strong>{getWrittenWorkStateDisplayValue(WrittenWorkState.REFUSED)}</strong>.
                </div>
                <br />
                <div>For å kunne endre til status som har tildelt redaktør må du først tildele redaktør manuelt.</div>
                <div>Endring av status vil bare endre på status og om verket har tildelt redaktør eller ikke.</div>
                <br />
                <strong>Vurderingen blir ikke slettet ved endring av status</strong>
            </Column>
        </Container>
    </Container>
);
