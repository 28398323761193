import PzSwiper from "./PzSwiper";

interface IPzItemsSwiperProps {
    slides?: JSX.Element[];
}
export default function PzItemsSwiper({ slides }: IPzItemsSwiperProps) {
    return (
        <PzSwiper
            autoplay
            pagination
            slides={slides ?? []}
            breakpoints={{
                // when window width is >= 320px
                320: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                // when window width is >= 640px
                850: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                // when window width is >= 640px
                1000: {
                    slidesPerView: 6,
                    spaceBetween: 40,
                },
            }}
        />
    );
}
