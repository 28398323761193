import { Column, Columns, Container } from "components/trunx";
import { UserRole } from "shared";

import DashboardHorizontalMenu from "../../components/dashboard/Menu/DashboardHorizontalMenu";
import PageTemplate from "../../components/pagetemplate/PageTemplate";
import { useAuth } from "../../contexts/AuthContext";
import { DashboardProvider } from "../../contexts/DashboardContext";
import AdminDashboardContainer from "./admin/AdminDashboardContainer";
import AuthorDashboard from "./author/AuthorDashboard";
import CustomerDashboard from "./customer/CustomerDashboard";
import EditorDashboard from "./editor/EditorDashboard";

export default function UserDashboard() {
    const { activeRole } = useAuth();
    function getUserDashboard() {
        switch (activeRole) {
            case UserRole.ADMIN:
                return <AdminDashboardContainer />;
            case UserRole.AUTHOR:
                return <AuthorDashboard />;
            case UserRole.EDITOR:
                return <EditorDashboard />;
            case UserRole.CUSTOMER:
                return <CustomerDashboard />;
            default:
                return <AuthorDashboard />;
        }
    }

    return (
        <PageTemplate marginLessTop>
            <DashboardProvider>
                <Container>
                    <DashboardHorizontalMenu />
                    <Columns pt5>
                        <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                            {getUserDashboard()}
                        </Column>
                    </Columns>
                </Container>
            </DashboardProvider>
        </PageTemplate>
    );
}
