import { Container, Control, Radio, Title } from "components/trunx";
import { useAssessment } from "contexts/AssessmentContext";
import { useAuth } from "contexts/AuthContext";
import { ReactElement } from "react";
import { UserRole } from "shared";

interface AssessmentJudgementProps {
    readOnly?: boolean;
}

export default function AssessmentJudgement({ readOnly }: AssessmentJudgementProps): ReactElement {
    const { assessment, updateAssessmentFormData } = useAssessment();
    const { userInfo, activeRole } = useAuth();

    return (
        <Container ml0 mb6 pb6 mt6 pl0 pr0 mr0 style={{ maxWidth: "1400px" }}>
            <Title is4 pb0 mb0 mb5 style={{ fontWeight: 600 }}>
                Dommen
            </Title>
            <Control>
                <Radio
                    disabled={activeRole == UserRole.AUTHOR || readOnly}
                    checked={assessment.judgement}
                    name="judgement"
                    value="Videreutvikle"
                    onChange={() => updateAssessmentFormData({ judgement: true })}
                >
                    <span style={{ marginRight: "5px" }}> Godkjenn</span>
                </Radio>
                <Radio
                    disabled={activeRole == UserRole.AUTHOR || readOnly}
                    checked={assessment.judgement != undefined && !assessment.judgement}
                    name="judgement"
                    value="Ikke anta"
                    onChange={() => updateAssessmentFormData({ judgement: false })}
                >
                    <span style={{ marginRight: "5px" }}> Ikke antatt</span>
                </Radio>
            </Control>
        </Container>
    );
}
