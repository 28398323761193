import "./LoadingIndicator.css";

import { CSSProperties, ReactElement } from "react";

type Size = "S" | "M" | "L" | "XL";
interface LoadingIndicatorProps extends CSSProperties {
    label?: string;
    size?: Size;
    style?: CSSProperties;
}
export default function LoadingIndicator({ size = "S", style, label }: LoadingIndicatorProps): ReactElement {
    const getSizeStyle = () => {
        switch (size) {
            case "S":
                return {
                    width: "20px",
                    height: "20px",
                };
            case "M":
                return {
                    width: "30px",
                    height: "30px",
                };
            case "L":
                return {
                    width: "50px",
                    height: "50px",
                };
            case "XL":
                return {
                    width: "80px",
                    height: "80px",
                };
        }
    };
    return (
        <div className="loader-wrapper is-active" style={{ ...style, display: "flex", flexDirection: "column" }}>
            <div className="loader is-loading" style={getSizeStyle()}></div>
            {label && <p>{label}</p>}
        </div>
    );
}
