import { BookService } from "services/firestore/BookService";
import { BookData } from "services/firestore/types";
import { Book, BookFile, BookFileAudioBook, BookType } from "shared";
export class BookMetadataMapper {
    static hasCollection(book: Book) {
        return book.collectionDetails && book.collectionDetails?.length > 0;
    }

    static getFileFormat(book: Book) {
        if (book.source == "bokbasen") {
            return book.inventoryDetails?.format;
        }
    }

    static getCollections(book: Book): string[] {
        if (!this.hasCollection(book)) return [];

        return book.collectionDetails!.filter((c) => c.bookId == undefined || c.bookId == book.id).map((c) => c.title);
    }

    static renderCollection(book: Book) {
        if (!this.hasCollection(book)) return;

        return book
            .collectionDetails!.filter((c) => c.bookId == undefined || c.bookId == book.id)
            .map((c) => `${c.title} (#${c.partNumber ?? 1})`)
            .join(", ");
    }

    static getAudiobookDuration(book: Book) {
        if (book.source == "bokbasen") {
            if (book.bookFile.type == BookType.AUDIO && book.bookFile?.durationSeconds) {
                return this.formatDuration(book.bookFile?.durationSeconds);
            }
            return "";
        }
        let totalDurationInSeconds = 0;
        if (book.audiobook) {
            book.audiobook?.files?.forEach((file) => {
                totalDurationInSeconds += file.durationSeconds;
            });
            return this.formatDuration(totalDurationInSeconds);
        }
        return "";
    }

    static formatDuration(seconds: number) {
        if (seconds <= 3600) {
            return String(Math.floor(seconds / 60)) + " min";
        }
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return String(hours) + " t " + String(minutes) + " min";
    }
    static getEBookPages(book: Book) {
        if (book.source == "bokbasen" && book.bookFile.type == BookType.EBOOK) {
            const pages = book.bookFile.pages;
            return pages > 0 ? pages : undefined;
        }
    }
    static getBookTechincalProtection(book: Book) {
        if (book.source == "bokbasen") {
            const hasProtection =
                book.technicalProtection?.toUpperCase() != "NONE" && book.inventoryDetails?.drm != "NON_DRM";
            return hasProtection ? `Vannmerket (${book.inventoryDetails?.drm})` : null;
        }
    }

    static isAudioBook(bookFil?: BookFile): bookFil is BookFileAudioBook {
        return bookFil?.type == BookType.AUDIO || bookFil?.type == BookType.PODCAST;
    }

    static async getBookWithType(book: Book, type: BookType): Promise<Book | undefined> {
        if (book.source == "bokbasen") {
            if (book.bookType == type) return book;
            const relatedBookId = book.relatedBooks.find((a) => a.type == type)?.bookId;

            const bookData = await new BookService().getById(relatedBookId!);
            return bookData?.book;
        }

        return book;
    }

    static getBookWithWithType(bookData: BookData, type: BookType) {
        if (bookData.book.source == "bokbasen") {
            if (bookData.book.bookType == type) return bookData.documentId;
            return bookData.book.relatedBooks?.find((a) => a.type == type)?.bookId;
        }

        return bookData.documentId;
    }
}
