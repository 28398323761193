import "./pzFooter.css";

import { faFacebook, faInstagram, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import greenTree from "assets/svg/green-tree.svg";
import { SocialMedia } from "common/SocialMedia";

export default function PzFooter() {
    return (
        <footer className="footer-container">
            <div className="columns footer-inner">
                <div className="column is-4">
                    <p>Kontakt</p>
                    <a href="mailto:kontakt@publizm.no" id="contact-link">
                        kontakt@publizm.no
                    </a>
                </div>
                <div className="column is-4">
                    <p>Følg oss</p>
                    <div className="social-media-icon-container">
                        <a
                            href={SocialMedia.FACEBOOK}
                            className="social-media-icon"
                            target="_blank"
                            aria-label="Facebook"
                        >
                            <FontAwesomeIcon size="2x" icon={faFacebook} />
                        </a>
                        <a
                            href={SocialMedia.INSTAGRAM}
                            className="social-media-icon"
                            target="_blank"
                            aria-label="Instagram"
                        >
                            <FontAwesomeIcon size="2x" icon={faInstagram} />
                        </a>
                        <a
                            href={SocialMedia.YOUTUBE}
                            className="social-media-icon"
                            target="_blank"
                            aria-label="Youtube"
                        >
                            <FontAwesomeIcon size="2x" icon={faYoutube} />
                        </a>
                        <a href={SocialMedia.TIKTOK} className="social-media-icon" target="_blank" aria-label="Tik Tok">
                            <FontAwesomeIcon size="2x" icon={faTiktok} />
                        </a>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="green-container">
                        <p className="font-bold">Publizm sparer trær!</p>
                        <img id="tree" src={greenTree} alt="Green tree" />
                    </div>
                </div>
            </div>
        </footer>
    );
}
