export const BookKeys = {
    all: ["books"] as const,
    booksByProfileId: (profileid: string) => [...BookKeys.all, "profileid", profileid] as const,
    booksByTitleAndAuthor: (title: string, author?: string) =>
        [...BookKeys.all, "title", title, author ? "author" + author : ""] as const,
    bookById: (id: string, subscribe?: boolean) => ["book", "id", id, subscribe ? "subscribe" : ""] as const,
    booksByGroupName: (groupName: string) => [...BookKeys.all, "groupName", groupName] as const,
    booksBySubject: (subject: string) => [...BookKeys.all, "subject", subject] as const,
    booksBySource: (source: string) => [...BookKeys.all, "source", source] as const,
    audiobook: (bookId: string) => [...BookKeys.all, "audiobook", bookId] as const,
    sampleAudiobook: (bookId: string) => [...BookKeys.audiobook(bookId), "sample"] as const,
};

export const BookGroupKeys = {
    all: (subscribe?: boolean) => ["bookgroups", subscribe ? "subscribe" : ""] as const,
};
