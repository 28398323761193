import { AppPath } from "common/AppPath";
import { RenderWhen } from "components/auth/UserAuth";
import PzButton from "components/form/PzButton";
import FeedbackModal from "components/modal/FeedbackModal";
import { Container } from "components/trunx";
import { useAssessment } from "contexts/AssessmentContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserRole, WrittenWorkEvent, WrittenWorkState } from "shared";

interface AssessmentButtonProps {
    viewButton?: boolean;
    eventId?: string;
}

export default function AssessmentButtonsPanel({ viewButton, eventId }: AssessmentButtonProps) {
    const { uploadAssessmentData, uploadState, assessment, updateWrittenWorkState } = useAssessment();
    const [showEditorConfirmSubmissionModal, setShowEditorConfirmSubmissionModal] = useState<boolean>(false);
    const [showAdminApproveFeedbackModal, setShowAdminApproveFeedbackModal] = useState<boolean>(false);

    const openEditorConfirmSubmissionModal = () => setShowEditorConfirmSubmissionModal(true);
    const openAdminConfirmAuthorFeedbackModal = () => setShowAdminApproveFeedbackModal(true);
    const closeModal = () => {
        setShowEditorConfirmSubmissionModal(false);
        setShowAdminApproveFeedbackModal(false);
    };
    const navigate = useNavigate();

    function routeToDashboard() {
        if (uploadState === "UPLOADED" || uploadState === "STALE") {
            navigate(AppPath.DASHBOARD);
        }
    }

    async function editorSaveAssessment() {
        await uploadAssessmentData(false);
        await updateWrittenWorkState(WrittenWorkState.UNDER_ASSESSMENT);
    }

    async function edtitorSubmitAssessment(): Promise<void> {
        const uploadStatus: boolean = await uploadAssessmentData(true);
        if (uploadStatus) {
            openEditorConfirmSubmissionModal();
        } else {
            await updateWrittenWorkState(WrittenWorkState.UNDER_ASSESSMENT);
        }
    }

    function adminSaveAssessment() {
        uploadAssessmentData(false);
    }

    async function adminSubmitAssessment() {
        const uploadStatus = await uploadAssessmentData(true);

        if (uploadStatus) {
            openAdminConfirmAuthorFeedbackModal();
        }
    }

    return (
        <>
            <Container ml0 pl0 pr0 mr0 mb2 pb2 mt6 hasTextCentered>
                {viewButton ? (
                    <>
                        <RenderWhen userIs={UserRole.EDITOR}>
                            {<SumbitAssessmentButton text="Send inn vurdering" onClick={edtitorSubmitAssessment} />}
                            <SaveAssessmentButton onClick={editorSaveAssessment} />
                        </RenderWhen>
                        <RenderWhen userIs={UserRole.ADMIN}>
                            {
                                <SumbitAssessmentButton
                                    text={
                                        eventId && WrittenWorkEvent.get(eventId)?.isActive()
                                            ? "Send tilbake til forfatter" + " (" + findEndDateOfEvent(eventId) + ")"
                                            : "Send tilbake til forfatter"
                                    }
                                    onClick={adminSubmitAssessment}
                                    disabled={eventId && WrittenWorkEvent.get(eventId)?.isActive()}
                                />
                            }
                            {<SaveAssessmentButton onClick={adminSaveAssessment} />}
                        </RenderWhen>
                    </>
                ) : null}
            </Container>
            <FeedbackModal
                content="Bekreft levering av din vurdering av verket. Du kan ikke endre denne vurderingen etter at den er levert. Før din vurdering sendes til forfatteren vil din vurdering kvalitetssikres av en sjefsredaktør"
                isActive={showEditorConfirmSubmissionModal}
                onAcceptText="Bekreft innsendelse"
                onCloseText="Avbryt"
                onClose={async () => {
                    closeModal();
                }}
                onAccept={async () => {
                    await updateWrittenWorkState(WrittenWorkState.ASSESSED);
                    closeModal();
                    routeToDashboard();
                }}
            />
            <FeedbackModal
                content="Bekreft at du vil sende vurderingen tilbake til forfatter. Vurderingen kan ikke endres etter at den ser sendt. Forfatteren vil motta en e-post med link til vurderingen. Selve vurderingen sendes ikke på epost men må leses av forfatteren på publizm.no"
                isActive={showAdminApproveFeedbackModal}
                onAcceptText="Bekreft - Send tilbake til forfatter"
                onCloseText="Avbryt"
                onClose={async () => {
                    closeModal();
                }}
                onAccept={async () => {
                    const endState = assessment.judgement ? WrittenWorkState.ACCEPTED : WrittenWorkState.REFUSED;
                    await updateWrittenWorkState(endState);
                    closeModal();
                    routeToDashboard();
                }}
            />
        </>
    );
}

function SumbitAssessmentButton(props) {
    return (
        <PzButton mb5 mr2 isPrimary onClick={props.onClick} disabled={props.disabled}>
            {props.text}
        </PzButton>
    );
}

function findEndDateOfEvent(eventId: string): string | undefined {
    return WrittenWorkEvent.get(eventId)?.activeTo.toLocaleDateString();
}

function SaveAssessmentButton(props) {
    return (
        <PzButton ml2 mb5 isSecondary onClick={props.onClick}>
            Lagre
        </PzButton>
    );
}
