import "./LicenseeDashboard.css";

import DashboardHorizontalMenu from "components/dashboard/Menu/DashboardHorizontalMenu";
import LoadingIndicator from "components/LoadingIndicator";
import PageTemplate from "components/pagetemplate/PageTemplate";
import { Column, Columns, Container } from "components/trunx";
import { DashboardProvider, useDashboard } from "contexts/DashboardContext";
import { ReactElement, useEffect, useRef, useState } from "react";
import { LicenseeService } from "services/firestore/LicenseeService";
import { LicenseeData, LicenseePaymentInfoData, LicenseeSaleInfoData } from "services/firestore/types";

import { AdminListLicenseeMenu } from "../../../../../components/dashboard/Menu/DashboardUserMenu";
import BokbasenLicenseeAdministrationTable from "./BokbasenLicenseeAdministrationTable";
import LicenseeAdministrationTable from "./LicenseeAdministrationTable";
export default function LicenseeDashboard(): ReactElement {
    return (
        <>
            <PageTemplate marginLessTop>
                <DashboardProvider>
                    <Container>
                        <DashboardContent />
                    </Container>
                </DashboardProvider>
            </PageTemplate>
        </>
    );
}

function DashboardContent() {
    const { selectedMenu } = useDashboard();
    const [licenseePaymentInfo, setLicenseePaymentInfo] = useState<LicenseePaymentInfoData[]>([]);
    const [licenseeSaleInfo, setLicenseeSaleInfo] = useState<LicenseeSaleInfoData[]>([]);
    const [licenseeSaleInfoBokbasen, setLicenseeSaleInfoBokbasen] = useState<LicenseeSaleInfoData[]>([]);
    const [licenseeDataList, setLicenseeDataList] = useState<LicenseeData[]>([]);
    const [licenseeTableData, setLicenseeTableData] = useState<LicenseeData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const unsubscribe = useRef<() => void>();
    useEffect(() => {
        const source = selectedMenu == AdminListLicenseeMenu.LICENSEES_BOKBASEN ? "bokbasen" : "publizm";
        const saleInfoData = source == "bokbasen" ? licenseeSaleInfoBokbasen : licenseeSaleInfo;
        setLicenseeTableData(
            licenseeDataList
                .filter((data) => {
                    if (data.documentId == "publizm") return true;
                    return source == "publizm" ? data.licensee.source != "bokbasen" : data.licensee.source == source;
                })
                .map((data) => ({
                    ...data,
                    saleInfo: saleInfoData.find((ec) => ec.licenseeId == data.documentId)?.saleInfo,
                    paymentInfo: licenseePaymentInfo.find((ec) => ec.licenseeId == data.documentId)?.paymentInfo,
                }))
        );
    }, [selectedMenu, licenseeDataList, licenseeSaleInfo, licenseePaymentInfo]);
    useEffect(() => {
        setLoading(true);
        fetchData();
        return () => unsubscribe.current?.();
    }, []);

    function renderTable() {
        return <LicenseeAdministrationTable key={"publizm"} licenseeDataList={licenseeTableData} />;
    }

    function renderTableBokbasen() {
        return <BokbasenLicenseeAdministrationTable key={"bokbasen"} licenseeDataList={licenseeTableData} />;
    }

    function renderContent() {
        if (loading) {
            return <LoadingIndicator />;
        }

        switch (selectedMenu) {
            case AdminListLicenseeMenu.LICENSEES:
                return renderTable();
            case AdminListLicenseeMenu.LICENSEES_BOKBASEN:
                return renderTableBokbasen();
            default:
                return renderTable();
        }
    }

    function fetchData() {
        setLoading(true);
        new LicenseeService.LicenseePaymentInfoService("none")
            .getAllSaleInfo("publizm", new Date().getFullYear())
            .then(setLicenseeSaleInfo);
        new LicenseeService.LicenseePaymentInfoService("none")
            .getAllSaleInfo("bokbasen", new Date().getFullYear())
            .then(setLicenseeSaleInfoBokbasen);
        new LicenseeService.LicenseePaymentInfoService("none").getAllPaymentInfo().then(setLicenseePaymentInfo);
        new LicenseeService()
            .getAll()
            .then(setLicenseeDataList)
            .finally(() => setLoading(false));
    }

    return (
        <>
            <DashboardHorizontalMenu />
            <Columns pt5 className="licenseeDashboard">
                <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                    {renderContent()}
                </Column>
            </Columns>
        </>
    );
}
