import { WrittenWorkData } from "services/firestore/types";
import { AssessmentMetadata } from "shared";

export function judgementComparator(aValue: WrittenWorkData, bValue: WrittenWorkData) {
    const a_string: string = getJudgmentString(aValue.writtenWork.assessmentMetadata);
    const b_string: string = getJudgmentString(bValue.writtenWork.assessmentMetadata);
    return b_string.localeCompare(a_string);
}

function getJudgmentString(assessmentMetadata?: AssessmentMetadata) {
    switch (assessmentMetadata?.judgement) {
        case true:
            return "A-accepted";
        case false:
            return "C-refused";
        default:
            return "B-not_assessed";
    }
}
