import "./AdminCreateAuthorProfilePage.css";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PageTemplate from "components/pagetemplate/PageTemplate";
import { Column, Columns, Container, Subtitle, Title } from "components/trunx";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Language, languageDisplayValue } from "shared";
import { v4 as uuidv4 } from "uuid";

import { AppPath } from "../../../../../common/AppPath";
import PzButton from "../../../../../components/form/PzButton";
import PzFormInputField from "../../../../../components/form/PzFormInputField";
import PzIconButton from "../../../../../components/form/PzIconButton";
import PzTextArea from "../../../../../components/form/PzTextArea";
import PzSelectDropdown, { OptionProps } from "../../../../../components/PzSelectDropdown";
import { PzTitle } from "../../../../../components/text/PzTitle";
import PzFileUpload from "../../../../../components/uploadfile/PzFileUpload";
import { FileUtils } from "../../../../../components/utils/FileUtils";
import { getStorageAuthorProfilepictureUrl } from "../../../../../services/firestore/hooks/useBookApi";
import { FirebaseUploadFile } from "../../../../../services/storage/types";
import { BookAuthorAdminFormStateProps, BookAuthorProfileFormProps } from "./BookAuthorAdminPage";

export const languageOptions: OptionProps[] = Object.values(Language).map((language) => {
    return {
        value: language,
        label: languageDisplayValue(language),
        isDefault: language === Language.NB,
    };
});

export default function BookAuthorProfilePage() {
    const { state } = useLocation();

    return (
        <PageTemplate marginLessTop>
            <AuthorProfileAdmin state={state} />
        </PageTemplate>
    );
}

interface AuthorProfileAdminProps {
    state: BookAuthorAdminFormStateProps;
}
function AuthorProfileAdmin({ state }: AuthorProfileAdminProps) {
    const navigate = useNavigate();
    function onSubmit(values?: BookAuthorProfileFormProps) {
        const updatedValues = { ...state.authorProfile, ...values };
        navigate(AppPath.ADMIN_CREATE_AUTHOR, {
            state: {
                authorId: state.authorId,
                formValues: state.formValues,
                updatedAuthorProfile: { uniqueId: uuidv4(), ...updatedValues },
            },
        });
    }

    function onGoBack() {
        navigate(AppPath.ADMIN_CREATE_AUTHOR, { state });
    }
    return (
        <Column>
            <AuthorProfileAdminForm initialFormValues={state.authorProfile} onSubmit={onSubmit} onGoBack={onGoBack} />
        </Column>
    );
}
interface AuthorProfileAdminFormProps {
    initialFormValues: BookAuthorProfileFormProps;
    onSubmit: (values: BookAuthorProfileFormProps) => void;
    onGoBack: () => void;
}
function AuthorProfileAdminForm({ initialFormValues, onSubmit, onGoBack }: AuthorProfileAdminFormProps) {
    const methods = useForm<BookAuthorProfileFormProps>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: initialFormValues,
    });
    const { register, handleSubmit } = methods;
    return (
        <Columns p6>
            <Column is2Desktop isFlex isFlexDirectionColumn>
                <PzIconButton isSmall onClick={onGoBack} icon={faArrowLeft} />
            </Column>
            <Column>
                <FormProvider {...methods}>
                    <form className="authoradmin">
                        <Title mb5>Forfatterprofil</Title>
                        <Columns>
                            <Column>
                                <PzFormInputField
                                    label="Forfatternavn/Psuedonym"
                                    name="displayName"
                                    inputRef={register()}
                                />
                                <PzFormInputField label="Navn" name="name" inputRef={register()} />
                                <PzSelectDropdown
                                    name="language"
                                    ml0
                                    pl0
                                    selectRef={register()}
                                    title="Språk"
                                    options={languageOptions}
                                ></PzSelectDropdown>
                                <Subtitle pt2 mt6 pb1 mb0 hasTextWeightBold>
                                    Om forfatteren
                                </Subtitle>
                                <p>Denne teksten skal beskrive forfatterskapet</p>
                                <PzTextArea name="description" textAreaRef={register()} />
                            </Column>
                            <Column>
                                <AuthorProfilePicture />
                            </Column>
                        </Columns>

                        <Column>
                            <PzButton onClick={handleSubmit(onSubmit)} isPrimary>
                                Oppdater
                            </PzButton>
                        </Column>
                    </form>
                </FormProvider>
            </Column>
        </Columns>
    );
}

function AuthorProfilePicture() {
    const { register, setValue } = useFormContext<BookAuthorProfileFormProps>();
    const authorPicture = useWatch<FirebaseUploadFile>({ name: "profilePicture" });
    const profilePath = useWatch<string>({ name: "profilePath" });
    const [authorPicturePath, setAuthorPicturePath] = useState<string>();
    useEffect(() => {
        register({ name: "profilePicture" });
    }, []);

    useEffect(() => {
        loadCoverPath();
    }, [authorPicture]);

    function addFile(file: FirebaseUploadFile) {
        setValue("profilePicture", file);
    }

    function loadCoverPath() {
        if (authorPicture && authorPicture.data) {
            const coverPath = FileUtils.dataToFileUrl(authorPicture.data, authorPicture.type);
            setAuthorPicturePath(coverPath);
        } else if (profilePath) {
            setAuthorPicturePath(getStorageAuthorProfilepictureUrl(profilePath, new Date()));
        }
    }

    return (
        <Column pl0 ml0 isFlex isFlexDirectionRow>
            <Container isFlex isFlexDirectionColumn>
                <Column p0 m0>
                    <PzTitle isSize6 pb0 mb2 hasTextWeightBold>
                        Forsidebilde
                    </PzTitle>
                    <div className="coverimage">
                        {authorPicturePath && (
                            <figure className="image">
                                <img src={authorPicturePath} alt="Cover image" />
                            </figure>
                        )}
                    </div>
                    <PzFileUpload accept="image" p0 m0 pt4 onFileAdded={addFile}>
                        Last opp
                    </PzFileUpload>
                </Column>
            </Container>
        </Column>
    );
}
