import React, { useState } from 'react';
import PzModal from './modal/PzModal';
import PzButton from './form/PzButton';
import { Modal, Level, Container, Column, Help } from './trunx';
import NewsLetterInput from './pagetemplate/NewsLetterInput';
import { PzTitle } from './text/PzTitle';
import PzIconButton from './form/PzIconButton';
import { faBook } from '@fortawesome/free-solid-svg-icons';

const NewsletterModal: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div style={{ alignSelf: "center" }}>
            <PzIconButton isPrimary iconPlacement="left" icon={faBook} onClick={handleOpenModal}>Få nyhetene her</PzIconButton>

            {showModal && (
                <PzModal isActive onClose={handleCloseModal}>
                    <Modal.Card.Body pl0 pr0 mr0 ml0 >
                        <PzTitle hasTextCentered style={{ fontWeight: 500 }} is4 pb0 mb0>
                            <strong>Meld deg på vårt nyhetsbrev.</strong>
                        </PzTitle>
                        <PzTitle hasTextCentered style={{ fontWeight: 50 }} hasTextWeightNormal is6 pt0 mt0>
                            <div>Få invitasjoner og nyheter rett i postkassen</div>
                        </PzTitle>
                        <Column p0 m0 style={{ maxWidth: "500px" }}>
                            <NewsLetterInput onSubmit={() => setIsSubmitted(true)} isButtonPrimary ignorePadding />
                        </Column>
                        <PzButton isSecondary onClick={handleCloseModal}>{isSubmitted ? "Lukk" : "Avbryt"}</PzButton>
                    </Modal.Card.Body>
                </PzModal>
            )}
        </div>
    );
};

export default NewsletterModal;
