import "./BookDetailsPage.css";

import { Column, Container, Content } from "components/trunx";
import { useAuth } from "contexts/AuthContext";
import { onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VippsService from "services/VippsService";
import { Book, BookType, VippsPayment, VippsPaymentState } from "shared";

import useUrlQueries from "../../../common/hooks/useQuery";
import LoadingIndicator from "../../../components/LoadingIndicator";
import PageTemplate from "../../../components/pagetemplate/PageTemplate";
import useBookApi from "../../../services/firestore/hooks/useBookApi";
import { AnalyticsApi } from "../../../services/firestore/queries/queriesCommon";
import BookDetails from "./BookDetails";
import BookDetailsProvider from "./BookDetailsContext";
import ReceiptPage from "./purchase/ReceiptPage";

type UnsubscribeFn = () => void;
interface BookDetailsContainerProps {
    onClose?: () => void;
}

function getViewedBookType(query, book?: Book | null) {
    const typeFromQuery = query.get("type");
    if (typeFromQuery) return typeFromQuery == "audio" ? BookType.AUDIO : BookType.EBOOK;
    return book?.bookType == BookType.PODCAST ? BookType.PODCAST : BookType.EBOOK;
}

export default function BookPurchaseDetailsPage(props: BookDetailsContainerProps) {
    return (
        <React.Suspense
            fallback={
                <Column pt6 isFlex isFlexDirectionColumn isJustifyContentCenter isAlignItemsCenter isFlexGrow1>
                    <LoadingIndicator size="XL" />
                    <Content>Laster bok</Content>
                </Column>
            }
        >
            <PageTemplate>
                <Container>
                    <BookPurchaseDetailsPageContainer {...props} />
                </Container>
            </PageTemplate>
        </React.Suspense>
    );
}
function BookPurchaseDetailsPageContainer({ onClose }: BookDetailsContainerProps) {
    const [transactionStatus, setTransactionStatus] = useState<VippsPaymentState>();
    const [snapshotUnsubscribeFn, setSnapshotUnsubscribeFn] = useState<UnsubscribeFn>();

    const query = useUrlQueries();
    const { currentUser, hasRole, loginWithOrderId } = useAuth();
    const { bookUrl } = useParams<{ bookUrl: string }>();
    const orderId = query.get("orderid");
    const bookId = query.get("id");

    const { data: book, isLoading, isError } = useBookApi().bookByUrl(bookUrl, bookId);
    const bookType = getViewedBookType(query, book);

    useEffect(() => {
        // if (orderId) {
        //     subscribeToTransactionSnapshot(orderId)
        //         .then((fn) => {
        //             setSnapshotUnsubscribeFn(() => fn);
        //         })
        //         .catch(console.error);
        // }
        // if (orderId && !currentUser) {
        //     loginWithOrderId(orderId)
        // }

        if (orderId && !currentUser) {
            loginWithOrderId(orderId).then((userId) => {
                if (!userId) {
                    setTransactionStatus(VippsPaymentState.ABORTED);
                    return
                };
                subscribeToTransactionSnapshot(orderId, userId)
                    .then((fn) => {
                        setSnapshotUnsubscribeFn(() => fn);
                    })
                    .catch(console.error);
            })
        } else if (orderId && currentUser) {
            subscribeToTransactionSnapshot(orderId, currentUser.uid)
                .then((fn) => {
                    setSnapshotUnsubscribeFn(() => fn);
                })
                .catch(console.error);
        }
    }, []);

    useEffect(() => {
        if (transactionStatus && transactionStatus == VippsPaymentState.CAPTURE) {
            snapshotUnsubscribeFn?.();
        }
    }, [transactionStatus]);

    useEffect(() => {
        if (book) {
            AnalyticsApi.logEvent("show_book_details", { title: book.title, bookId: book.id });
            AnalyticsApi.logViewItem(book.id ?? "ukjent", book.title, book.bookType);
        }
    }, []);

    async function subscribeToTransactionSnapshot(orderId: string, userId: string): Promise<UnsubscribeFn> {
        return onSnapshot(
            new VippsService().getVippsPaymentInfoDocumentRefByOrderIdAndUserIdQuery(orderId, userId),
            { includeMetadataChanges: true },
            (snap) => {
                if (snap.empty) return;
                const data = snap.docs[0].data() as VippsPayment;
                if (data.paymentState !== transactionStatus) {
                    setTransactionStatus(data.paymentState);
                }
                if (data.paymentState == VippsPaymentState.AUTHORIZED) {
                    const amount = data.transactionInfo?.amount ?? 0;
                    AnalyticsApi.logPurchase(
                        amount / 100,
                        book?.id,
                        book?.title,
                        data?.transactionInfo?.transactionId,
                        book?.bookType ?? bookType
                    );
                }
            }
        );
    }

    if (isError || (!isLoading && !book)) {
        return <Container>{"Fant ikke bok med tittel " + bookUrl}</Container>;
    }

    console.log("transactionStatus", transactionStatus, "orderId", orderId, "isLoading", isLoading, "book", book);
    if (!book || isLoading || (orderId != undefined && transactionStatus == VippsPaymentState.CREATED)) {
        return <LoadingIndicator />;
    }

    if (orderId && !currentUser && !transactionStatus) {
        return <div>
            <LoadingIndicator label="Logger deg inn. Vennligst vent" />
        </div>
    }
    if (orderId && transactionStatus === VippsPaymentState.AUTHORIZED || transactionStatus == VippsPaymentState.CAPTURED) {
        return <ReceiptPage book={book} loggingIn={orderId != null && currentUser == null} />;
    }
    return (
        <BookDetailsProvider bookId={book.id!} book={book} type={bookType}>
            <BookDetails onClose={onClose} />
        </BookDetailsProvider>
    );
}
