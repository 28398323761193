import { arrayRemove, arrayUnion, deleteDoc, getDocs, Timestamp, updateDoc, where } from "firebase/firestore";
import { useMutation } from "react-query";
import { Book, BookGroup, FirestoreCollection } from "shared";

import { FirestoreApi } from "../queriesCommon";

export const BookMutations = {
    updateBook: () => {
        return useMutation({
            mutationFn: async ({ data, id }: { data: Partial<Book>; id?: string }) => {
                return FirestoreApi.mutate(FirestoreCollection.BOOK, { ...data, updatedDate: Timestamp.now() }, id);
            },
        });
    },
    addBookGroup: () => {
        return useMutation({
            mutationFn: async ({ groupName, id }: { groupName: string; id?: string }) => {
                return FirestoreApi.mutate(FirestoreCollection.BOOK, { groups: arrayUnion(groupName) }, id);
            },
        });
    },
    removeBookGroup: () => {
        return useMutation({
            mutationFn: async ({ groupName, id }: { groupName: string; id?: string }) => {
                return FirestoreApi.mutate(FirestoreCollection.BOOK, { groups: arrayRemove(groupName) }, id);
            },
        });
    },
};

export const BookGroupMutations = {
    createOrUpdatBookGroup: () => {
        return useMutation({
            mutationFn: async ({ data, id }: { data: Partial<BookGroup>; id?: string }) => {
                return FirestoreApi.mutate(FirestoreCollection.BOOK_GROUP, data, id);
            },
        });
    },
    deleteGroup: () => {
        return useMutation({
            mutationFn: async ({ id, groupName }: { id: string; groupName: string }) => {
                const ref = FirestoreApi.getSingleDocRef(FirestoreCollection.BOOK_GROUP, id);
                await deleteDoc(ref);

                const books = await getDocs(
                    FirestoreApi.getQueryFor(FirestoreCollection.BOOK, where("groups", "array-contains", groupName))
                );

                for (const book of books.docs) {
                    await updateDoc(book.ref, {
                        groups: arrayRemove(groupName),
                    });
                }
            },
        });
    },
};
