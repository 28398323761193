import "./PzFaq.css";

import { Column, Container, Section } from "components/trunx";
import { useState } from "react";
import { UnmountClosed } from "react-collapse";
import { ReactElement } from "react";

import CollapsibleExpandButton from "./dashboard/CollapsibleExpandButton";
import PzText from "./text/PzText";
import { PzTitle } from "./text/PzTitle";
interface PzFaqProps {
    items: PzFaqItemProps[];
}

interface PzFaqItemProps {
    question: string;
    answer: string;
}
export default function PzFaq({ items }: PzFaqProps): ReactElement {
    return (
        <Section hasBackgroundGreyDark>
            <PzTitle hasTextWhite hasTextWeightBold isSize1 style={{ marginTop: "-35px", marginBottom: "-10px" }}>
                FAQ
                <>
                    {items.map((item, index) => (
                        <PzFaqItem {...item} key={index} />
                    ))}
                </>
            </PzTitle>
        </Section>
    );
}

function PzFaqItem({ question, answer }: PzFaqItemProps): ReactElement {
    const [answerOpened, setAnswerOpened] = useState<boolean>(false);
    return (
        <Container pt5 className="pzfaq-item">
            <Column
                p0
                m0
                isFlex
                isFlexDirectionRow
                isClickable
                onClick={() => setAnswerOpened((prevValue) => !prevValue)}
            >
                <PzText isSize5 hasTextWeightLight pl0 ml0 containerProps={{ p0: true, m0: true }}>
                    {question}
                </PzText>
                <CollapsibleExpandButton isOpen={answerOpened} />
            </Column>

            <UnmountClosed isOpened={answerOpened}>
                <Column isSize6 hasTextWeightLight pt2 pb2 p0 m0 mb1 style={{ lineHeight: "1.5" }}>
                    {answer}
                </Column>
            </UnmountClosed>
        </Container>
    );
}
