import { ref } from "firebase/storage";
import { getDownloadURL } from "firebase/storage";

import { storage } from "../../../firebase";

export const StorageQueries = {
    loadStorageUrl: (filePath: string): Promise<string> => {
        const fileRef = ref(storage, filePath);
        return getDownloadURL(fileRef);
    },
};
