import { TrunxProps, trunxPropsToClassnamesObject } from "./classNames";

export interface BackgroundColorHelpersProps {
    hasBackgroundBlack?: boolean;
    hasBackgroundBlackBis?: boolean;
    hasBackgroundBlackTer?: boolean;
    hasBackgroundDanger?: boolean;
    hasBackgroundDangerDark?: boolean;
    hasBackgroundDangerLight?: boolean;
    hasBackgroundDark?: boolean;
    hasBackgroundGrey?: boolean;
    hasBackgroundGreyDark?: boolean;
    hasBackgroundGreyDarker?: boolean;
    hasBackgroundGreyLight?: boolean;
    hasBackgroundGreyLighter?: boolean;
    hasBackgroundLight?: boolean;
    hasBackgroundLink?: boolean;
    hasBackgroundLinkDark?: boolean;
    hasBackgroundLinkLight?: boolean;
    hasBackgroundInfo?: boolean;
    hasBackgroundInfoDark?: boolean;
    hasBackgroundInfoLight?: boolean;
    hasBackgroundPrimary?: boolean;
    hasBackgroundPrimaryDark?: boolean;
    hasBackgroundPrimaryLight?: boolean;
    hasBackgroundSuccess?: boolean;
    hasBackgroundSuccessDark?: boolean;
    hasBackgroundSuccessLight?: boolean;
    hasBackgroundWarning?: boolean;
    hasBackgroundWarningDark?: boolean;
    hasBackgroundWarningLight?: boolean;
    hasBackgroundWhite?: boolean;
    hasBackgroundWhiteBis?: boolean;
    hasBackgroundWhiteTer?: boolean;
}

export interface FlexboxHelpers {
    isAlignContentBaseline?: boolean;
    isAlignContentCenter?: boolean;
    isAlignContentEnd?: boolean;
    isAlignContentFlexEnd?: boolean;
    isAlignContentFlexStart?: boolean;
    isAlignContentSpaceAround?: boolean;
    isAlignContentSpaceBetween?: boolean;
    isAlignContentSpaceEvenly?: boolean;
    isAlignContentStart?: boolean;
    isAlignContentStretch?: boolean;
    isAlignItemsBaseline?: boolean;
    isAlignItemsCenter?: boolean;
    isAlignItemsEnd?: boolean;
    isAlignItemsFlexEnd?: boolean;
    isAlignItemsFlexStart?: boolean;
    isAlignItemsSelfEnd?: boolean;
    isAlignItemsSelfStart?: boolean;
    isAlignItemsSpaceAround?: boolean;
    isAlignItemsSpaceBetween?: boolean;
    isAlignItemsSpaceEvenly?: boolean;
    isAlignItemsStart?: boolean;
    isAlignItemsStretch?: boolean;
    isAlignSelfAuto?: boolean;
    isAlignSelfBaseline?: boolean;
    isAlignSelfCenter?: boolean;
    isAlignSelfFlexEnd?: boolean;
    isAlignSelfFlexStart?: boolean;
    isAlignSelfStretch?: boolean;
    isFlexDirectionColumn?: boolean;
    isFlexDirectionColumnReverse?: boolean;
    isFlexDirectionRow?: boolean;
    isFlexDirectionRowReverse?: boolean;
    isFlexGrow0?: boolean;
    isFlexGrow1?: boolean;
    isFlexGrow2?: boolean;
    isFlexGrow3?: boolean;
    isFlexGrow4?: boolean;
    isFlexGrow5?: boolean;
    isFlexShrink0?: boolean;
    isFlexShrink1?: boolean;
    isFlexShrink2?: boolean;
    isFlexShrink3?: boolean;
    isFlexShrink4?: boolean;
    isFlexShrink5?: boolean;
    isFlexWrapNowrap?: boolean;
    isFlexWrapWrap?: boolean;
    isFlexWrapWrapReverse?: boolean;
    isJustifyContentCenter?: boolean;
    isJustifyContentEnd?: boolean;
    isJustifyContentFlexEnd?: boolean;
    isJustifyContentFlexStart?: boolean;
    isJustifyContentLeft?: boolean;
    isJustifyContentRight?: boolean;
    isJustifyContentSpaceAround?: boolean;
    isJustifyContentSpaceBetween?: boolean;
    isJustifyContentSpaceEvenly?: boolean;
    isJustifyContentStart?: boolean;
}

export interface FontFamilyHelpersProps {
    isFamilySansSerif?: boolean;
    isFamilyMonospace?: boolean;
    isFamilyPrimary?: boolean;
    isFamilySecondary?: boolean;
    isFamilycode?: boolean;
}

export interface OtherHelpersProps {
    isClearfix?: boolean;
    isClickable?: boolean;
    isClipped?: boolean;
    isOverlay?: boolean;
    isPulledLeft?: boolean;
    isPulledRight?: boolean;
    isRadiusLess?: boolean;
    isRelative?: boolean;
    isShadowLess?: boolean;
    isUnselectable?: boolean;
}

export interface MainColorsProps {
    isDanger?: boolean;
    isInfo?: boolean;
    isLink?: boolean;
    isPrimary?: boolean;
    isSuccess?: boolean;
    isWarning?: boolean;
}

export interface ShadeColorsProps {
    isBlack?: boolean;
    isDark?: boolean;
    isLight?: boolean;
    isWhite?: boolean;
}

export interface SizeProps {
    isLarge?: boolean;
    isMedium?: boolean;
    isSmall?: boolean;
}

export interface SpacingHelpersProps {
    isMarginLess?: boolean;
    isPaddingLess?: boolean;
    m0?: boolean;
    m1?: boolean;
    m2?: boolean;
    m3?: boolean;
    m4?: boolean;
    m5?: boolean;
    m6?: boolean;
    mAuto?: boolean;
    mtAuto?: boolean;
    mrAuto?: boolean;
    mbAuto?: boolean;
    mlAuto?: boolean;
    mxAuto?: boolean;
    myAuto?: boolean;
    mt0?: boolean;
    mt1?: boolean;
    mt2?: boolean;
    mt3?: boolean;
    mt4?: boolean;
    mt5?: boolean;
    mt6?: boolean;
    mr0?: boolean;
    mr1?: boolean;
    mr2?: boolean;
    mr3?: boolean;
    mr4?: boolean;
    mr5?: boolean;
    mr6?: boolean;
    mb0?: boolean;
    mb1?: boolean;
    mb2?: boolean;
    mb3?: boolean;
    mb4?: boolean;
    mb5?: boolean;
    mb6?: boolean;
    ml0?: boolean;
    ml1?: boolean;
    ml2?: boolean;
    ml3?: boolean;
    ml4?: boolean;
    ml5?: boolean;
    ml6?: boolean;
    mx0?: boolean;
    mx1?: boolean;
    mx2?: boolean;
    mx3?: boolean;
    mx4?: boolean;
    mx5?: boolean;
    mx6?: boolean;
    my0?: boolean;
    my1?: boolean;
    my2?: boolean;
    my3?: boolean;
    my4?: boolean;
    my5?: boolean;
    my6?: boolean;
    p0?: boolean;
    p1?: boolean;
    p2?: boolean;
    p3?: boolean;
    p4?: boolean;
    p5?: boolean;
    p6?: boolean;
    pAuto?: boolean;
    ptAuto?: boolean;
    prAuto?: boolean;
    pbAuto?: boolean;
    plAuto?: boolean;
    pxAuto?: boolean;
    pyAuto?: boolean;
    pt0?: boolean;
    pt1?: boolean;
    pt2?: boolean;
    pt3?: boolean;
    pt4?: boolean;
    pt5?: boolean;
    pt6?: boolean;
    pr0?: boolean;
    pr1?: boolean;
    pr2?: boolean;
    pr3?: boolean;
    pr4?: boolean;
    pr5?: boolean;
    pr6?: boolean;
    pb0?: boolean;
    pb1?: boolean;
    pb2?: boolean;
    pb3?: boolean;
    pb4?: boolean;
    pb5?: boolean;
    pb6?: boolean;
    pl0?: boolean;
    pl1?: boolean;
    pl2?: boolean;
    pl3?: boolean;
    pl4?: boolean;
    pl5?: boolean;
    pl6?: boolean;
    px0?: boolean;
    px1?: boolean;
    px2?: boolean;
    px3?: boolean;
    px4?: boolean;
    px5?: boolean;
    px6?: boolean;
    py0?: boolean;
    py1?: boolean;
    py2?: boolean;
    py3?: boolean;
    py4?: boolean;
    py5?: boolean;
    py6?: boolean;
}

export interface TextColorHelpersProps {
    hasTextBlack?: boolean;
    hasTextBlackBis?: boolean;
    hasTextBlackTer?: boolean;
    hasTextDanger?: boolean;
    hasTextDark?: boolean;
    hasTextGrey?: boolean;
    hasTextGreyDark?: boolean;
    hasTextGreyDarker?: boolean;
    hasTextGreyLight?: boolean;
    hasTextGreyLighter?: boolean;
    hasTextLight?: boolean;
    hasTextLink?: boolean;
    hasTextInfo?: boolean;
    hasTextPrimary?: boolean;
    hasTextSuccess?: boolean;
    hasTextWarning?: boolean;
    hasTextWhite?: boolean;
    hasTextWhiteBis?: boolean;
    hasTextWhiteTer?: boolean;
}

export interface TextTransformationHelpersProps {
    isCapitalized?: boolean;
    isItalic?: boolean;
    isLowercase?: boolean;
    isUnderlined?: boolean;
    isUppercase?: boolean;
}

export interface TextWeightHelpersProps {
    hasTextWeightLight?: boolean;
    hasTextWeightNormal?: boolean;
    hasTextWeightMedium?: boolean;
    hasTextWeightSemibold?: boolean;
    hasTextWeightBold?: boolean;
}

export interface TypographyAlignementHelpersProps {
    hasTextCentered?: boolean;
    hasTextCenteredDesktop?: boolean;
    hasTextCenteredDesktopOnly?: boolean;
    hasTextCenteredFullhd?: boolean;
    hasTextCenteredMobile?: boolean;
    hasTextCenteredTablet?: boolean;
    hasTextCenteredTabletOnly?: boolean;
    hasTextCenteredTouch?: boolean;
    hasTextCenteredWidescreen?: boolean;
    hasTextCenteredWidescreenOnly?: boolean;
    hasTextJustified?: boolean;
    hasTextJustifiedDesktop?: boolean;
    hasTextJustifiedDesktopOnly?: boolean;
    hasTextJustifiedFullhd?: boolean;
    hasTextJustifiedMobile?: boolean;
    hasTextJustifiedTablet?: boolean;
    hasTextJustifiedTabletOnly?: boolean;
    hasTextJustifiedTouch?: boolean;
    hasTextJustifiedWidescreen?: boolean;
    hasTextJustifiedWidescreenOnly?: boolean;
    hasTextLeft?: boolean;
    hasTextLeftDesktop?: boolean;
    hasTextLeftDesktopOnly?: boolean;
    hasTextLeftFullhd?: boolean;
    hasTextLeftMobile?: boolean;
    hasTextLeftTablet?: boolean;
    hasTextLeftTabletOnly?: boolean;
    hasTextLeftTouch?: boolean;
    hasTextLeftWidescreen?: boolean;
    hasTextLeftWidescreenOnly?: boolean;
    hasTextRight?: boolean;
    hasTextRightDesktop?: boolean;
    hasTextRightDesktopOnly?: boolean;
    hasTextRightFullhd?: boolean;
    hasTextRightMobile?: boolean;
    hasTextRightTablet?: boolean;
    hasTextRightTabletOnly?: boolean;
    hasTextRightTouch?: boolean;
    hasTextRightWidescreen?: boolean;
    hasTextRightWidescreenOnly?: boolean;
}

export interface TypographySizeHelpers {
    isSize1?: boolean;
    isSize1Mobile?: boolean;
    isSize1Touch?: boolean;
    isSize1Tablet?: boolean;
    isSize1Desktop?: boolean;
    isSize1Widescreen?: boolean;
    isSize1Fullhd?: boolean;
    isSize2?: boolean;
    isSize2Mobile?: boolean;
    isSize2Touch?: boolean;
    isSize2Tablet?: boolean;
    isSize2Desktop?: boolean;
    isSize2Widescreen?: boolean;
    isSize2Fullhd?: boolean;
    isSize3?: boolean;
    isSize3Mobile?: boolean;
    isSize3Touch?: boolean;
    isSize3Tablet?: boolean;
    isSize3Desktop?: boolean;
    isSize3Widescreen?: boolean;
    isSize3Fullhd?: boolean;
    isSize4?: boolean;
    isSize4Mobile?: boolean;
    isSize4Touch?: boolean;
    isSize4Tablet?: boolean;
    isSize4Desktop?: boolean;
    isSize4Widescreen?: boolean;
    isSize4Fullhd?: boolean;
    isSize5?: boolean;
    isSize5Mobile?: boolean;
    isSize5Touch?: boolean;
    isSize5Tablet?: boolean;
    isSize5Desktop?: boolean;
    isSize5Widescreen?: boolean;
    isSize5Fullhd?: boolean;
    isSize6?: boolean;
    isSize6Mobile?: boolean;
    isSize6Touch?: boolean;
    isSize6Tablet?: boolean;
    isSize6Desktop?: boolean;
    isSize6Widescreen?: boolean;
    isSize6Fullhd?: boolean;
    isSize7?: boolean;
    isSize7Mobile?: boolean;
    isSize7Touch?: boolean;
    isSize7Tablet?: boolean;
    isSize7Desktop?: boolean;
    isSize7Widescreen?: boolean;
    isSize7Fullhd?: boolean;
}

export interface VisibilityHelpersProps {
    isBlock?: boolean;
    isBlockDesktop?: boolean;
    isBlockDesktopOnly?: boolean;
    isBlockFullhd?: boolean;
    isBlockMobile?: boolean;
    isBlockTablet?: boolean;
    isBlockTabletOnly?: boolean;
    isBlockTouch?: boolean;
    isBlockWidescreen?: boolean;
    isBlockWidescreenOnly?: boolean;
    isFlex?: boolean;
    isFlexDesktop?: boolean;
    isFlexDesktopOnly?: boolean;
    isFlexFullhd?: boolean;
    isFlexMobile?: boolean;
    isFlexTablet?: boolean;
    isFlexTabletOnly?: boolean;
    isFlexTouch?: boolean;
    isFlexWidescreen?: boolean;
    isFlexWidescreenOnly?: boolean;
    isHidden?: boolean;
    isHiddenDesktop?: boolean;
    isHiddenDesktopOnly?: boolean;
    isHiddenFullhd?: boolean;
    isHiddenMobile?: boolean;
    isHiddenTablet?: boolean;
    isHiddenTabletOnly?: boolean;
    isHiddenTouch?: boolean;
    isHiddenWidescreen?: boolean;
    isHiddenWidescreenOnly?: boolean;
    isInline?: boolean;
    isInlineBlock?: boolean;
    isInlineBlockDesktop?: boolean;
    isInlineBlockDesktopOnly?: boolean;
    isInlineBlockFullhd?: boolean;
    isInlineBlockMobile?: boolean;
    isInlineBlockTablet?: boolean;
    isInlineBlockTabletOnly?: boolean;
    isInlineBlockTouch?: boolean;
    isInlineBlockWidescreen?: boolean;
    isInlineBlockWidescreenOnly?: boolean;
    isInlineDesktop?: boolean;
    isInlineDesktopOnly?: boolean;
    isInlineFlex?: boolean;
    isInlineFlexDesktop?: boolean;
    isInlineFlexDesktopOnly?: boolean;
    isInlineFlexFullhd?: boolean;
    isInlineFlexMobile?: boolean;
    isInlineFlexTablet?: boolean;
    isInlineFlexTabletOnly?: boolean;
    isInlineFlexTouch?: boolean;
    isInlineFlexWidescreen?: boolean;
    isInlineFlexWidescreenOnly?: boolean;
    isInlineFullhd?: boolean;
    isInlineMobile?: boolean;
    isInlineTablet?: boolean;
    isInlineTabletOnly?: boolean;
    isInlineTouch?: boolean;
    isInlineWidescreen?: boolean;
    isInlineWidescreenOnly?: boolean;
    isInvisible?: boolean;
    isSrOnly?: boolean;
}

export interface HelpersProps
    extends BackgroundColorHelpersProps,
        FlexboxHelpers,
        FontFamilyHelpersProps,
        OtherHelpersProps,
        SpacingHelpersProps,
        SizeProps,
        TextColorHelpersProps,
        TextTransformationHelpersProps,
        TextWeightHelpersProps,
        TypographyAlignementHelpersProps,
        TypographySizeHelpers,
        VisibilityHelpersProps {}

export interface ModifiersProps extends HelpersProps, MainColorsProps, ShadeColorsProps, SizeProps {}

export interface ExtractModifiersPropsArg extends ModifiersProps {
    [prop: string]: any;
}

export function modifierPropsToClassnamesObject(props?: ModifiersProps) {
    return trunxPropsToClassnamesObject(props as TrunxProps);
}

export function extractModifiersProps({
    // BackgroundColorHelpersProps
    hasBackgroundBlack,
    hasBackgroundBlackBis,
    hasBackgroundBlackTer,
    hasBackgroundDanger,
    hasBackgroundDark,
    hasBackgroundDangerDark,
    hasBackgroundDangerLight,
    hasBackgroundGrey,
    hasBackgroundGreyDark,
    hasBackgroundGreyDarker,
    hasBackgroundGreyLight,
    hasBackgroundGreyLighter,
    hasBackgroundLight,
    hasBackgroundLink,
    hasBackgroundLinkDark,
    hasBackgroundLinkLight,
    hasBackgroundInfo,
    hasBackgroundInfoDark,
    hasBackgroundInfoLight,
    hasBackgroundPrimary,
    hasBackgroundPrimaryDark,
    hasBackgroundPrimaryLight,
    hasBackgroundSuccess,
    hasBackgroundSuccessDark,
    hasBackgroundSuccessLight,
    hasBackgroundWarning,
    hasBackgroundWarningDark,
    hasBackgroundWarningLight,
    hasBackgroundWhite,
    hasBackgroundWhiteBis,
    hasBackgroundWhiteTer,
    // FlexboxHelpers
    isAlignContentBaseline,
    isAlignContentCenter,
    isAlignContentEnd,
    isAlignContentFlexEnd,
    isAlignContentFlexStart,
    isAlignContentSpaceAround,
    isAlignContentSpaceBetween,
    isAlignContentSpaceEvenly,
    isAlignContentStart,
    isAlignContentStretch,
    isAlignItemsBaseline,
    isAlignItemsCenter,
    isAlignItemsEnd,
    isAlignItemsFlexEnd,
    isAlignItemsFlexStart,
    isAlignItemsSelfEnd,
    isAlignItemsSelfStart,
    isAlignItemsSpaceAround,
    isAlignItemsSpaceBetween,
    isAlignItemsSpaceEvenly,
    isAlignItemsStart,
    isAlignItemsStretch,
    isAlignSelfAuto,
    isAlignSelfBaseline,
    isAlignSelfCenter,
    isAlignSelfFlexEnd,
    isAlignSelfFlexStart,
    isAlignSelfStretch,
    isFlexDirectionColumn,
    isFlexDirectionColumnReverse,
    isFlexDirectionRow,
    isFlexDirectionRowReverse,
    isFlexGrow0,
    isFlexGrow1,
    isFlexGrow2,
    isFlexGrow3,
    isFlexGrow4,
    isFlexGrow5,
    isFlexShrink0,
    isFlexShrink1,
    isFlexShrink2,
    isFlexShrink3,
    isFlexShrink4,
    isFlexShrink5,
    isFlexWrapNowrap,
    isFlexWrapWrap,
    isFlexWrapWrapReverse,
    isJustifyContentCenter,
    isJustifyContentEnd,
    isJustifyContentFlexEnd,
    isJustifyContentFlexStart,
    isJustifyContentLeft,
    isJustifyContentRight,
    isJustifyContentSpaceAround,
    isJustifyContentSpaceBetween,
    isJustifyContentSpaceEvenly,
    isJustifyContentStart,
    // FontFamilyHelpersProps
    isFamilySansSerif,
    isFamilyMonospace,
    isFamilyPrimary,
    isFamilySecondary,
    isFamilycode,
    // OtherHelpersProps
    isClearfix,
    isClickable,
    isClipped,
    isOverlay,
    isPulledLeft,
    isPulledRight,
    isRadiusLess,
    isRelative,
    isShadowLess,
    isUnselectable,
    // MainColorsHelperProps
    isDanger,
    isInfo,
    isLink,
    isPrimary,
    isSuccess,
    isWarning,
    // ShadeColorsHelperProps
    isBlack,
    isDark,
    isLight,
    isWhite,
    // SizeProps
    isLarge,
    isMedium,
    isSmall,
    // SpacingHelpersProps
    isMarginLess,
    isPaddingLess,
    m0,
    m1,
    m2,
    m3,
    m4,
    m5,
    m6,
    mAuto,
    mtAuto,
    mrAuto,
    mbAuto,
    mlAuto,
    mxAuto,
    myAuto,
    mt0,
    mt1,
    mt2,
    mt3,
    mt4,
    mt5,
    mt6,
    mr0,
    mr1,
    mr2,
    mr3,
    mr4,
    mr5,
    mr6,
    mb0,
    mb1,
    mb2,
    mb3,
    mb4,
    mb5,
    mb6,
    ml0,
    ml1,
    ml2,
    ml3,
    ml4,
    ml5,
    ml6,
    mx0,
    mx1,
    mx2,
    mx3,
    mx4,
    mx5,
    mx6,
    my0,
    my1,
    my2,
    my3,
    my4,
    my5,
    my6,
    p0,
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    pAuto,
    ptAuto,
    prAuto,
    pbAuto,
    plAuto,
    pxAuto,
    pyAuto,
    pt0,
    pt1,
    pt2,
    pt3,
    pt4,
    pt5,
    pt6,
    pr0,
    pr1,
    pr2,
    pr3,
    pr4,
    pr5,
    pr6,
    pb0,
    pb1,
    pb2,
    pb3,
    pb4,
    pb5,
    pb6,
    pl0,
    pl1,
    pl2,
    pl3,
    pl4,
    pl5,
    pl6,
    px0,
    px1,
    px2,
    px3,
    px4,
    px5,
    px6,
    py0,
    py1,
    py2,
    py3,
    py4,
    py5,
    py6,
    // TextColorHelpersProps
    hasTextBlack,
    hasTextBlackBis,
    hasTextBlackTer,
    hasTextDanger,
    hasTextDark,
    hasTextGrey,
    hasTextGreyDark,
    hasTextGreyDarker,
    hasTextGreyLight,
    hasTextGreyLighter,
    hasTextLight,
    hasTextLink,
    hasTextInfo,
    hasTextPrimary,
    hasTextSuccess,
    hasTextWarning,
    hasTextWhite,
    hasTextWhiteBis,
    hasTextWhiteTer,
    // TextTransformationHelpersProps
    isCapitalized,
    isItalic,
    isLowercase,
    isUnderlined,
    isUppercase,
    // TextWeightHelpersProps
    hasTextWeightLight,
    hasTextWeightNormal,
    hasTextWeightMedium,
    hasTextWeightSemibold,
    hasTextWeightBold,
    // TypographyAlignementHelpersProps
    hasTextCentered,
    hasTextCenteredDesktop,
    hasTextCenteredDesktopOnly,
    hasTextCenteredFullhd,
    hasTextCenteredMobile,
    hasTextCenteredTablet,
    hasTextCenteredTabletOnly,
    hasTextCenteredTouch,
    hasTextCenteredWidescreen,
    hasTextCenteredWidescreenOnly,
    hasTextJustified,
    hasTextJustifiedDesktop,
    hasTextJustifiedDesktopOnly,
    hasTextJustifiedFullhd,
    hasTextJustifiedMobile,
    hasTextJustifiedTablet,
    hasTextJustifiedTabletOnly,
    hasTextJustifiedTouch,
    hasTextJustifiedWidescreen,
    hasTextJustifiedWidescreenOnly,
    hasTextLeft,
    hasTextLeftDesktop,
    hasTextLeftDesktopOnly,
    hasTextLeftFullhd,
    hasTextLeftMobile,
    hasTextLeftTablet,
    hasTextLeftTabletOnly,
    hasTextLeftTouch,
    hasTextLeftWidescreen,
    hasTextLeftWidescreenOnly,
    hasTextRight,
    hasTextRightDesktop,
    hasTextRightDesktopOnly,
    hasTextRightFullhd,
    hasTextRightMobile,
    hasTextRightTablet,
    hasTextRightTabletOnly,
    hasTextRightTouch,
    hasTextRightWidescreen,
    hasTextRightWidescreenOnly,
    // TypographySizeHelpers
    isSize1,
    isSize1Mobile,
    isSize1Touch,
    isSize1Tablet,
    isSize1Desktop,
    isSize1Widescreen,
    isSize1Fullhd,
    isSize2,
    isSize2Mobile,
    isSize2Touch,
    isSize2Tablet,
    isSize2Desktop,
    isSize2Widescreen,
    isSize2Fullhd,
    isSize3,
    isSize3Mobile,
    isSize3Touch,
    isSize3Tablet,
    isSize3Desktop,
    isSize3Widescreen,
    isSize3Fullhd,
    isSize4,
    isSize4Mobile,
    isSize4Touch,
    isSize4Tablet,
    isSize4Desktop,
    isSize4Widescreen,
    isSize4Fullhd,
    isSize5,
    isSize5Mobile,
    isSize5Touch,
    isSize5Tablet,
    isSize5Desktop,
    isSize5Widescreen,
    isSize5Fullhd,
    isSize6,
    isSize6Mobile,
    isSize6Touch,
    isSize6Tablet,
    isSize6Desktop,
    isSize6Widescreen,
    isSize6Fullhd,
    isSize7,
    isSize7Mobile,
    isSize7Touch,
    isSize7Tablet,
    isSize7Desktop,
    isSize7Widescreen,
    isSize7Fullhd,
    // VisibilityHelpersProps
    isBlock,
    isBlockDesktop,
    isBlockDesktopOnly,
    isBlockFullhd,
    isBlockMobile,
    isBlockTablet,
    isBlockTabletOnly,
    isBlockTouch,
    isBlockWidescreen,
    isBlockWidescreenOnly,
    isFlex,
    isFlexDesktop,
    isFlexDesktopOnly,
    isFlexFullhd,
    isFlexMobile,
    isFlexTablet,
    isFlexTabletOnly,
    isFlexTouch,
    isFlexWidescreen,
    isFlexWidescreenOnly,
    isHidden,
    isHiddenDesktop,
    isHiddenDesktopOnly,
    isHiddenFullhd,
    isHiddenMobile,
    isHiddenTablet,
    isHiddenTabletOnly,
    isHiddenTouch,
    isHiddenWidescreen,
    isHiddenWidescreenOnly,
    isInline,
    isInlineBlock,
    isInlineBlockDesktop,
    isInlineBlockDesktopOnly,
    isInlineBlockFullhd,
    isInlineBlockMobile,
    isInlineBlockTablet,
    isInlineBlockTabletOnly,
    isInlineBlockTouch,
    isInlineBlockWidescreen,
    isInlineBlockWidescreenOnly,
    isInlineDesktop,
    isInlineDesktopOnly,
    isInlineFlex,
    isInlineFlexDesktop,
    isInlineFlexDesktopOnly,
    isInlineFlexFullhd,
    isInlineFlexMobile,
    isInlineFlexTablet,
    isInlineFlexTabletOnly,
    isInlineFlexTouch,
    isInlineFlexWidescreen,
    isInlineFlexWidescreenOnly,
    isInlineFullhd,
    isInlineMobile,
    isInlineTablet,
    isInlineTabletOnly,
    isInlineTouch,
    isInlineWidescreen,
    isInlineWidescreenOnly,
    isInvisible,
    isSrOnly,
    ...props
}: ExtractModifiersPropsArg) {
    return [
        {
            // BackgroundColorHelpersProps
            hasBackgroundBlack,
            hasBackgroundBlackBis,
            hasBackgroundBlackTer,
            hasBackgroundDanger,
            hasBackgroundDark,
            hasBackgroundDangerDark,
            hasBackgroundDangerLight,
            hasBackgroundGrey,
            hasBackgroundGreyDark,
            hasBackgroundGreyDarker,
            hasBackgroundGreyLight,
            hasBackgroundGreyLighter,
            hasBackgroundLight,
            hasBackgroundLink,
            hasBackgroundLinkDark,
            hasBackgroundLinkLight,
            hasBackgroundInfo,
            hasBackgroundInfoDark,
            hasBackgroundInfoLight,
            hasBackgroundPrimary,
            hasBackgroundPrimaryDark,
            hasBackgroundPrimaryLight,
            hasBackgroundSuccess,
            hasBackgroundSuccessDark,
            hasBackgroundSuccessLight,
            hasBackgroundWarning,
            hasBackgroundWarningDark,
            hasBackgroundWarningLight,
            hasBackgroundWhite,
            hasBackgroundWhiteBis,
            hasBackgroundWhiteTer,
            // FlexboxHelpers
            isAlignContentBaseline,
            isAlignContentCenter,
            isAlignContentEnd,
            isAlignContentFlexEnd,
            isAlignContentFlexStart,
            isAlignContentSpaceAround,
            isAlignContentSpaceBetween,
            isAlignContentSpaceEvenly,
            isAlignContentStart,
            isAlignContentStretch,
            isAlignItemsBaseline,
            isAlignItemsCenter,
            isAlignItemsEnd,
            isAlignItemsFlexEnd,
            isAlignItemsFlexStart,
            isAlignItemsSelfEnd,
            isAlignItemsSelfStart,
            isAlignItemsSpaceAround,
            isAlignItemsSpaceBetween,
            isAlignItemsSpaceEvenly,
            isAlignItemsStart,
            isAlignItemsStretch,
            isAlignSelfAuto,
            isAlignSelfBaseline,
            isAlignSelfCenter,
            isAlignSelfFlexEnd,
            isAlignSelfFlexStart,
            isAlignSelfStretch,
            isFlexDirectionColumn,
            isFlexDirectionColumnReverse,
            isFlexDirectionRow,
            isFlexDirectionRowReverse,
            isFlexGrow0,
            isFlexGrow1,
            isFlexGrow2,
            isFlexGrow3,
            isFlexGrow4,
            isFlexGrow5,
            isFlexShrink0,
            isFlexShrink1,
            isFlexShrink2,
            isFlexShrink3,
            isFlexShrink4,
            isFlexShrink5,
            isFlexWrapNowrap,
            isFlexWrapWrap,
            isFlexWrapWrapReverse,
            isJustifyContentCenter,
            isJustifyContentEnd,
            isJustifyContentFlexEnd,
            isJustifyContentFlexStart,
            isJustifyContentLeft,
            isJustifyContentRight,
            isJustifyContentSpaceAround,
            isJustifyContentSpaceBetween,
            isJustifyContentSpaceEvenly,
            isJustifyContentStart,
            // FontFamilyHelpersProps
            isFamilySansSerif,
            isFamilyMonospace,
            isFamilyPrimary,
            isFamilySecondary,
            isFamilycode,
            // OtherHelpersProps
            isClearfix,
            isClickable,
            isClipped,
            isOverlay,
            isPulledLeft,
            isPulledRight,
            isRadiusLess,
            isRelative,
            isShadowLess,
            isUnselectable,
            // MainColorsHelperProps
            isDanger,
            isInfo,
            isLink,
            isPrimary,
            isSuccess,
            isWarning,
            // ShadeColorsHelperProps
            isBlack,
            isDark,
            isLight,
            isWhite,
            // SizeProps
            isLarge,
            isMedium,
            isSmall,
            // SpacingHelpersProps
            isMarginLess,
            isPaddingLess,
            m0,
            m1,
            m2,
            m3,
            m4,
            m5,
            m6,
            mAuto,
            mtAuto,
            mrAuto,
            mbAuto,
            mlAuto,
            mxAuto,
            myAuto,
            mt0,
            mt1,
            mt2,
            mt3,
            mt4,
            mt5,
            mt6,
            mr0,
            mr1,
            mr2,
            mr3,
            mr4,
            mr5,
            mr6,
            mb0,
            mb1,
            mb2,
            mb3,
            mb4,
            mb5,
            mb6,
            ml0,
            ml1,
            ml2,
            ml3,
            ml4,
            ml5,
            ml6,
            mx0,
            mx1,
            mx2,
            mx3,
            mx4,
            mx5,
            mx6,
            my0,
            my1,
            my2,
            my3,
            my4,
            my5,
            my6,
            p0,
            p1,
            p2,
            p3,
            p4,
            p5,
            p6,
            pAuto,
            ptAuto,
            prAuto,
            pbAuto,
            plAuto,
            pxAuto,
            pyAuto,
            pt0,
            pt1,
            pt2,
            pt3,
            pt4,
            pt5,
            pt6,
            pr0,
            pr1,
            pr2,
            pr3,
            pr4,
            pr5,
            pr6,
            pb0,
            pb1,
            pb2,
            pb3,
            pb4,
            pb5,
            pb6,
            pl0,
            pl1,
            pl2,
            pl3,
            pl4,
            pl5,
            pl6,
            px0,
            px1,
            px2,
            px3,
            px4,
            px5,
            px6,
            py0,
            py1,
            py2,
            py3,
            py4,
            py5,
            py6,
            // TextColorHelpersProps
            hasTextBlack,
            hasTextBlackBis,
            hasTextBlackTer,
            hasTextDanger,
            hasTextDark,
            hasTextGrey,
            hasTextGreyDark,
            hasTextGreyDarker,
            hasTextGreyLight,
            hasTextGreyLighter,
            hasTextLight,
            hasTextLink,
            hasTextInfo,
            hasTextPrimary,
            hasTextSuccess,
            hasTextWarning,
            hasTextWhite,
            hasTextWhiteBis,
            hasTextWhiteTer,
            // TextTransformationHelpersProps
            isCapitalized,
            isItalic,
            isLowercase,
            isUnderlined,
            isUppercase,
            // TextWeightHelpersProps
            hasTextWeightLight,
            hasTextWeightNormal,
            hasTextWeightMedium,
            hasTextWeightSemibold,
            hasTextWeightBold,
            // TypographyAlignementHelpersProps
            hasTextCentered,
            hasTextCenteredDesktop,
            hasTextCenteredDesktopOnly,
            hasTextCenteredFullhd,
            hasTextCenteredMobile,
            hasTextCenteredTablet,
            hasTextCenteredTabletOnly,
            hasTextCenteredTouch,
            hasTextCenteredWidescreen,
            hasTextCenteredWidescreenOnly,
            hasTextJustified,
            hasTextJustifiedDesktop,
            hasTextJustifiedDesktopOnly,
            hasTextJustifiedFullhd,
            hasTextJustifiedMobile,
            hasTextJustifiedTablet,
            hasTextJustifiedTabletOnly,
            hasTextJustifiedTouch,
            hasTextJustifiedWidescreen,
            hasTextJustifiedWidescreenOnly,
            hasTextLeft,
            hasTextLeftDesktop,
            hasTextLeftDesktopOnly,
            hasTextLeftFullhd,
            hasTextLeftMobile,
            hasTextLeftTablet,
            hasTextLeftTabletOnly,
            hasTextLeftTouch,
            hasTextLeftWidescreen,
            hasTextLeftWidescreenOnly,
            hasTextRight,
            hasTextRightDesktop,
            hasTextRightDesktopOnly,
            hasTextRightFullhd,
            hasTextRightMobile,
            hasTextRightTablet,
            hasTextRightTabletOnly,
            hasTextRightTouch,
            hasTextRightWidescreen,
            hasTextRightWidescreenOnly,
            // TypographySizeHelpers
            isSize1,
            isSize1Mobile,
            isSize1Touch,
            isSize1Tablet,
            isSize1Desktop,
            isSize1Widescreen,
            isSize1Fullhd,
            isSize2,
            isSize2Mobile,
            isSize2Touch,
            isSize2Tablet,
            isSize2Desktop,
            isSize2Widescreen,
            isSize2Fullhd,
            isSize3,
            isSize3Mobile,
            isSize3Touch,
            isSize3Tablet,
            isSize3Desktop,
            isSize3Widescreen,
            isSize3Fullhd,
            isSize4,
            isSize4Mobile,
            isSize4Touch,
            isSize4Tablet,
            isSize4Desktop,
            isSize4Widescreen,
            isSize4Fullhd,
            isSize5,
            isSize5Mobile,
            isSize5Touch,
            isSize5Tablet,
            isSize5Desktop,
            isSize5Widescreen,
            isSize5Fullhd,
            isSize6,
            isSize6Mobile,
            isSize6Touch,
            isSize6Tablet,
            isSize6Desktop,
            isSize6Widescreen,
            isSize6Fullhd,
            isSize7,
            isSize7Mobile,
            isSize7Touch,
            isSize7Tablet,
            isSize7Desktop,
            isSize7Widescreen,
            isSize7Fullhd,
            // VisibilityHelpersProps
            isBlock,
            isBlockDesktop,
            isBlockDesktopOnly,
            isBlockFullhd,
            isBlockMobile,
            isBlockTablet,
            isBlockTabletOnly,
            isBlockTouch,
            isBlockWidescreen,
            isBlockWidescreenOnly,
            isFlex,
            isFlexDesktop,
            isFlexDesktopOnly,
            isFlexFullhd,
            isFlexMobile,
            isFlexTablet,
            isFlexTabletOnly,
            isFlexTouch,
            isFlexWidescreen,
            isFlexWidescreenOnly,
            isHidden,
            isHiddenDesktop,
            isHiddenDesktopOnly,
            isHiddenFullhd,
            isHiddenMobile,
            isHiddenTablet,
            isHiddenTabletOnly,
            isHiddenTouch,
            isHiddenWidescreen,
            isHiddenWidescreenOnly,
            isInline,
            isInlineBlock,
            isInlineBlockDesktop,
            isInlineBlockDesktopOnly,
            isInlineBlockFullhd,
            isInlineBlockMobile,
            isInlineBlockTablet,
            isInlineBlockTabletOnly,
            isInlineBlockTouch,
            isInlineBlockWidescreen,
            isInlineBlockWidescreenOnly,
            isInlineDesktop,
            isInlineDesktopOnly,
            isInlineFlex,
            isInlineFlexDesktop,
            isInlineFlexDesktopOnly,
            isInlineFlexFullhd,
            isInlineFlexMobile,
            isInlineFlexTablet,
            isInlineFlexTabletOnly,
            isInlineFlexTouch,
            isInlineFlexWidescreen,
            isInlineFlexWidescreenOnly,
            isInlineFullhd,
            isInlineMobile,
            isInlineTablet,
            isInlineTabletOnly,
            isInlineTouch,
            isInlineWidescreen,
            isInlineWidescreenOnly,
            isInvisible,
            isSrOnly,
        },
        props,
    ];
}
