import "./AuthorDashboard.css";

import LoadingIndicator from "components/LoadingIndicator";
import { useDashboard } from "contexts/DashboardContext";
import { useEffect, useState } from "react";
import { WrittenWorkData } from "services/firestore/types";
import { WrittenWorkService } from "services/firestore/WrittenWorkService";
import { WrittenWorkState } from "shared";

import { AuthorDashboardMenu } from "../../../components/dashboard/Menu/DashboardUserMenu";
import { useAuth } from "../../../contexts/AuthContext";
import RoyaltyDashboard from "../royalty/RoyaltyDashboard";
import AuthorDashboardTable from "./AuthorDashboardTable";

export default function AuthorDashboard() {
    const [loading, setLoading] = useState<boolean>(true);
    const [writtenWorkDataList, setWrittenWorkDataList] = useState<WrittenWorkData[]>([]);
    const { currentUser } = useAuth();
    const [error, setError] = useState<Error>();
    const { selectedMenu } = useDashboard();

    function getWrittenWorksDataListByType(state: WrittenWorkState[]) {
        return writtenWorkDataList.filter((data) => state.includes(data.writtenWork.state));
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    async function loadInitialData() {
        if (currentUser) {
            await new WrittenWorkService().getAllLoggedInUserData().then(setWrittenWorkDataList).catch(setError);
        }
        setLoading(false);
    }

    function renderContent() {
        if (loading || !selectedMenu) {
            return <LoadingIndicator />;
        }

        function renderTable(writtenWorkDataList: WrittenWorkData[]) {
            return (
                <AuthorDashboardTable
                    key={selectedMenu}
                    writtenWorkDataList={writtenWorkDataList}
                    updateWrittenWorks={loadInitialData}
                />
            );
        }

        switch (selectedMenu) {
            case AuthorDashboardMenu.MY_WORK:
                return renderTable(writtenWorkDataList);
            case AuthorDashboardMenu.FEEDBACK:
                return renderTable(
                    getWrittenWorksDataListByType([WrittenWorkState.REFUSED, WrittenWorkState.ACCEPTED])
                );
            case AuthorDashboardMenu.ROYALTY:
                return <RoyaltyDashboard />;
            default:
                return renderTable(writtenWorkDataList);
        }
    }

    return renderContent();
}
