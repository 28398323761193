import classnames from "classnames";
import React from "react";

import { bulmaClassName } from "./classNames";
import { ErrorBoundaryProps } from "./ErrorBoundary";
import { extractModifiersProps, HelpersProps, modifierPropsToClassnamesObject } from "./modifiers";

export interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement>, ErrorBoundaryProps, HelpersProps {}

export class Radio extends React.Component<RadioProps> {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = { hasError: false };

    render(): React.ReactNode {
        const [modifiersProps, { children, className, fallbackUI, ...props }] = extractModifiersProps(this.props);

        if (this.state.hasError) return fallbackUI;

        return (
            <label
                className={classnames(bulmaClassName.radio, className, modifierPropsToClassnamesObject(modifiersProps))}
            >
                <input {...props} type="radio" />

                {children}
            </label>
        );
    }
}
