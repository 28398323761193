import { UPLOAD_STATE } from "common/types";
import { Timestamp } from "firebase/firestore";
import React, { PropsWithChildren, ReactElement, useContext, useEffect, useState } from "react";
import { WrittenWorkData } from "services/firestore/types";
import { WrittenWorkService } from "services/firestore/WrittenWorkService";
import Logger from "services/logging/LoggerService";
import { Assessment, AssessmentFeedback, UserRole, WrittenWork, WrittenWorkState } from "shared";

import { useAuth } from "./AuthContext";

interface AssessmentContextProps {
    assessment: Assessment;
    writtenWork: WrittenWork | undefined;
    uploadAssessmentData: (validate: boolean) => Promise<boolean>;
    updateAssessmentFormData: (data: Partial<Assessment>) => void;
    updateWrittenWorkState: (state: WrittenWorkState) => Promise<void>;
    error: boolean;
    errorMsg: string;
    uploadState: UPLOAD_STATE;
}

const AssessmentContext = React.createContext<AssessmentContextProps>({} as AssessmentContextProps);

export function useAssessment(): AssessmentContextProps {
    return useContext(AssessmentContext);
}

interface AssessmentProviderProps {
    writtenWorkData: WrittenWorkData;
}

export function AssessmentProvider({
    children,
    writtenWorkData,
}: PropsWithChildren<AssessmentProviderProps>): ReactElement {
    const [assessment, setAssessment] = useState<Partial<Assessment>>({
        lastOpened: Timestamp.now(),
    });

    const { userHasActiveRole } = useAuth();
    const [assessmentDocumentId, setAssessmentDocumentId] = useState<string>("");
    const [writtenWorkDocumentId, setWrittenWorkDocumentId] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [uploadState, setUploadState] = useState<UPLOAD_STATE>("STALE");
    const [writtenWork, setWrittenWork] = useState<WrittenWork>();

    useEffect(() => {
        if (writtenWorkData) {
            setWrittenWorkDocumentId(writtenWorkData.documentId);
            setWrittenWork(writtenWorkData.writtenWork);
            loadAssessment(writtenWorkData.documentId);
        }
    }, []);

    useEffect(() => {
        intiliazeAdminAssessment();
    }, [assessment]);

    useEffect(() => {
        if (assessmentDocumentId && userHasActiveRole(UserRole.EDITOR)) {
            updateLastOpened();
            incrementOpened();
        }
    }, [assessmentDocumentId]);

    function updateAssessmentFormData(data: Partial<Assessment>): void {
        setAssessment((prevState) => ({
            ...prevState,
            ...data,
        }));
    }

    async function updateLastOpened() {
        uploadAssessmentService({ lastOpened: Timestamp.now() }, writtenWorkDocumentId, assessmentDocumentId)
            .catch()
            .catch((error) => Logger.error(error));
    }

    async function incrementOpened() {
        if (writtenWorkDocumentId && assessmentDocumentId) {
            await new WrittenWorkService.Assessment(writtenWorkDocumentId).incrementOpenedQuantity(
                assessmentDocumentId
            );
        }
    }

    function intiliazeAdminAssessment() {
        if (userHasActiveRole(UserRole.ADMIN)) {
            if (!assessment.introduction) {
                updateAssessmentFormData({ introduction: getAssessmentIntroduction(writtenWorkData) });
            }
            if (!assessment.ending) {
                updateAssessmentFormData({ ending: getAssessmentEnding(writtenWorkData, assessment) });
            }
        }
    }

    async function loadAssessment(documentId: string): Promise<boolean> {
        if (!documentId) {
            return false;
        }

        return await new WrittenWorkService.Assessment(documentId)
            .getFirst()
            .then((assessmentData) => {
                if (assessmentData != null) {
                    setAssessment(assessmentData.assessment);
                    setAssessmentDocumentId(assessmentData.documentId);
                }
                return true;
            })
            .catch(() => false);
    }

    async function loadWrittenWork(documentId: string): Promise<boolean> {
        if (!documentId) {
            return false;
        }

        return await new WrittenWorkService()
            .getWrittenWorkDataByDocId(documentId)
            .then((writtenWorkData) => {
                if (writtenWorkData != null) {
                    setWrittenWork(writtenWorkData.writtenWork);
                    setWrittenWorkDocumentId(writtenWorkData.documentId);
                }
                return true;
            })
            .catch(() => false);
    }

    async function uploadAssessmentData(validate: boolean): Promise<boolean> {
        setError(false);
        setUploadState("UPLOADING");
        await uploadAssessmentService(assessment, writtenWorkDocumentId, assessmentDocumentId)
            .then(() => {
                loadAssessment(writtenWorkDocumentId).then(() => {
                    writtenWork && updateWrittenWorkState(writtenWork?.state);
                    setUploadState("UPLOADED");
                });
            })
            .catch(() => {
                setUploadState("ERROR");
                return false;
            });

        await loadWrittenWork(writtenWorkDocumentId);

        if (validate && !isValidForSubmission(assessment)) {
            setError(true);
            setErrorMsg("Du må fylle inn minst konklusjon og godkjenne/avvise");
            return false;
        } else {
            return true;
        }
    }

    async function updateWrittenWorkState(state: WrittenWorkState): Promise<void> {
        setUploadState("UPLOADING");
        await new WrittenWorkService()
            .updateWrittenWork({ state: state }, writtenWorkDocumentId)
            .then(() => setUploadState("UPLOADED"));
    }

    async function uploadAssessmentService(
        assessmentContent: Partial<Assessment>,
        writtenWorkId: string,
        assessmentId: string
    ): Promise<void> {
        setError(false);
        setUploadState("UPLOADING");
        await new WrittenWorkService.Assessment(writtenWorkId)
            .updateAssessment(assessmentContent, assessmentId)
            .then(() => {
                setUploadState("UPLOADED");
            })
            .catch(() => {
                setUploadState("ERROR");
                setError(true);
                setErrorMsg("Feil ved opplastning");
            })
            .finally(() => {
                assessmentContent.lastOpened && setUploadState("STALE");
            });
    }

    return (
        <AssessmentContext.Provider
            value={{
                error,
                uploadState,
                errorMsg,
                assessment,
                writtenWork,
                uploadAssessmentData,
                updateWrittenWorkState,
                updateAssessmentFormData,
            }}
        >
            {children}
        </AssessmentContext.Provider>
    );
}

function isValidForSubmission(assessment: Assessment): boolean {
    // Minimum submission requirement: conclusion and assessment
    if (assessment.conclusion == null || assessment.conclusion?.length == 0) {
        return false;
    } else if (assessment.judgement == null) {
        return false;
    } else {
        return true;
    }
}

function getAssessmentIntroduction(writtenWork: WrittenWorkData): string {
    const authorName = writtenWork.writtenWork.authorName || "Forfatter";
    const eventSpesificIntro = writtenWork.writtenWorkEvent?.assessmentFeedback?.introduction;

    return (eventSpesificIntro || DEFAULT_ASSESSMENT_FEEDBACK.introduction).replaceAll("${authorName}", authorName);
}

function getAssessmentEnding(writtenWork: WrittenWorkData, assessment: Assessment) {
    const resolveFeedbackEnding = (judgement: boolean, assessmentFeedback?: AssessmentFeedback) =>
        judgement ? assessmentFeedback?.endingAccepted : assessmentFeedback?.endingRefused;

    const judgement = assessment?.judgement || false;
    const eventSpesificAssessmentFeedback = writtenWork.writtenWorkEvent?.assessmentFeedback;
    const eventSpesificEnding = resolveFeedbackEnding(judgement, eventSpesificAssessmentFeedback);
    const defaultEnding = resolveFeedbackEnding(judgement, DEFAULT_ASSESSMENT_FEEDBACK);

    return eventSpesificEnding || defaultEnding;
}

const DEFAULT_ASSESSMENT_FEEDBACK: AssessmentFeedback = {
    introduction:
        "Kjære ${authorName} \n\nTusen takk for at vi fikk gleden av å lese ditt manus. En redaktør med interesse for verkets sjangere har nå vurdert ditt verk og har kommet med følgende tilbakemelding:",
    endingAccepted:
        "Publizm ønsker å bidra i videreutviklingen av ditt verk, med mål om publisering. Du vil snart få tildelt en redaktør i egen melding. Vi ser frem til et godt samarbeid!\n\n\n Mvh \n Publizm v/Redaksjonen",
    endingRefused:
        "Med dette må vi dessverre meddele deg at vi ikke kan ta på oss videreutvikling av ditt manus. Håper at det ikke ødelegger din lyst til å fortsette med dette, eller med et annet prosjekt. Vi ønsker deg lykke til videre :-) \n\n\n Mvh \n Publizm v/Redaksjonen",
};
