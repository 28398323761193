import { UPLOAD_STATE } from "common/types";
import { Timestamp } from "firebase/firestore";
import React, { PropsWithChildren, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { removeNullOrUndefinedKeys, WrittenWork, WrittenWorkEvent, WrittenWorkState } from "shared";

import LoadingIndicator from "../components/LoadingIndicator";
import { getFileUUIDFromFilePath } from "../components/utils/FirestoreFilePathUtils";
import { objectEmpty } from "../components/utils/ObjectUtils";
import { WrittenWorkService } from "../services/firestore/WrittenWorkService";
import Logger from "../services/logging/LoggerService";
import { FirebaseStorage } from "../services/storage/StorageService";
import { FirebaseStorageFolder, FirebaseUploadFile } from "../services/storage/types";
import { useAuth } from "./AuthContext";

export enum FormSteps {
    UPLOAD_FILE = 1,
    TAG_FILE = 2,
    PITCH = 3,
    FINISHED = 4,
}

interface UploadContextProps {
    writtenWorkFormData: Partial<WrittenWork>;
    file?: FirebaseUploadFile;
    updateFile: (file?: FirebaseUploadFile) => void;
    currentStep: FormSteps;
    uploadState: UPLOAD_STATE;
    highestVisitedStep: FormSteps;
    updateWrittenWorkFormData: (userFormData: Partial<WrittenWork>) => void;
    next: () => void;
    finish: () => void;
    prev: () => void;
    updateStep: (step: number) => void;
    maxNumberOfFiles: number;
    errors?: FormErrors<WrittenWork>;
}

const UploadContext = React.createContext<UploadContextProps>({} as UploadContextProps);

export function useUpload(): UploadContextProps {
    return useContext(UploadContext);
}

type FormErrors<T> = {
    [key in keyof T]?: string;
};

interface UploadProviderProps {
    writtenWorkEvent?: WrittenWorkEvent;
}
export function UploadProvider({ children, writtenWorkEvent }: PropsWithChildren<UploadProviderProps>): ReactElement {
    const maxNumberOfFiles = useRef<number>(1);
    const { currentUser } = useAuth();
    const [loadingInitialData, setLoadingInitialData] = useState(true);
    const [uploadState, setUploadState] = useState<UPLOAD_STATE>("STALE");
    const [file, setFile] = useState<FirebaseUploadFile>();
    const [highestVisitedStep, setHighestVisitedStep] = useState<FormSteps>(1);
    const [currentStep, setCurrentStep] = useState<FormSteps>(1);
    const [errors, setErrors] = useState<FormErrors<WrittenWork>>();
    const [writtenWorkFormData, setWrittenWorkFormData] = useState<Partial<WrittenWork>>({
        tags: [],
        state: WrittenWorkState.DRAFT,
        writtenWorkEventId: writtenWorkEvent?.id,
        uploadDate: Timestamp.now(),
    });

    const writtenWorkDocumentId = useRef<string>();
    const hasWrittenWorkFormDataChanged = useRef<boolean>(false);

    useEffect(() => {
        loadInitialData();
    }, []);

    useEffect(() => {
        hasWrittenWorkFormDataChanged.current = true;
    }, [writtenWorkFormData, file]);

    useEffect(() => {
        if (shouldSaveDraft()) {
            uploadWrittenWorkFormData(WrittenWorkState.DRAFT);
        }
    }, [currentStep, writtenWorkFormData]);

    function updateWrittenWorkFormData(data: Partial<WrittenWork>) {
        setWrittenWorkFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
    }

    function shouldSaveDraft() {
        return (
            file &&
            hasWrittenWorkFormDataChanged.current &&
            currentStep !== FormSteps.FINISHED &&
            (highestVisitedStep > FormSteps.UPLOAD_FILE || uploadState === "UPLOADED")
        );
    }
    async function loadInitialData() {
        setLoadingInitialData(true);
        new WrittenWorkService()
            .getFirstLoggedinUserDataWithState(WrittenWorkState.DRAFT)
            .then(async (writtenWorkData) => {
                if (writtenWorkData) {
                    const writtenWork = writtenWorkData.writtenWork;
                    writtenWorkDocumentId.current = writtenWorkData.documentId;
                    updateWrittenWorkFormData(writtenWork);
                    await loadFile(writtenWork.filePath);
                    setUploadState("UPLOADED");
                }
            })
            .catch((err) => Logger.error("Det skjedde en feil ved lasting av initiell data", err))
            .finally(() => setLoadingInitialData(false));
    }

    async function loadFile(filePath?: string) {
        if (filePath) {
            const fileMetadata = await new FirebaseStorage(FirebaseStorageFolder.AUTHOR_FILES).getFileMetadata(
                filePath
            );
            setFile({
                filePath: fileMetadata.filePath,
                name: fileMetadata.name,
                type: fileMetadata.type,
                fileUrl: fileMetadata.fileUrl,
            } as FirebaseUploadFile);
        }
    }

    async function uploadWrittenWorkFormData(state: WrittenWorkState) {
        setUploadState("UPLOADING");
        try {
            const filePath = await uploadFile();
            await uploadWrittenWork(state, filePath);
            setUploadState("UPLOADED");
            hasWrittenWorkFormDataChanged.current = false;
            return true;
        } catch (error) {
            // @ts-ignore
            Logger.error("Det skjedde en feil ved opplastning", error);
            setUploadState("ERROR");
        }
        return false;
    }

    async function uploadWrittenWork(state: WrittenWorkState, filePath?: string) {
        const data: Partial<WrittenWork> = {
            ...writtenWorkFormData,
            authorId: currentUser?.uid ?? "",
            filePath,
            state,
        };
        await new WrittenWorkService()
            .updateWrittenWork(data, writtenWorkDocumentId.current)
            .then((writtenWorkPath) => {
                writtenWorkDocumentId.current = getFileUUIDFromFilePath(writtenWorkPath);
                writtenWorkFormData.filePath = filePath;
            });
    }

    async function uploadFile() {
        if (file && file.data) {
            return new FirebaseStorage(FirebaseStorageFolder.AUTHOR_FILES).updateFile(
                file,
                writtenWorkFormData.filePath
            );
        }

        return writtenWorkFormData.filePath;
    }

    function updateFile(updatedFile?: FirebaseUploadFile) {
        setFile(updatedFile);
    }

    function next() {
        if (validateForm()) {
            const step: number = currentStep;
            const nextStep: number = step >= 3 ? 4 : step + 1;
            setHighestVisitedStep((prevValue) => (nextStep > prevValue ? nextStep : prevValue));
            setCurrentStep(nextStep);
        }
    }

    function prev() {
        let step = currentStep;
        step = step <= 1 ? 1 : step - 1;
        setCurrentStep(step);
    }

    function finish() {
        if (validateForm()) {
            uploadWrittenWorkFormData(WrittenWorkState.DELIVERED).then((result) => result && next());
        }
    }

    function updateStep(updatedStep: number) {
        setCurrentStep(updatedStep);
    }

    function validateForm() {
        let errors: FormErrors<WrittenWork> = {};
        switch (currentStep) {
            case FormSteps.UPLOAD_FILE:
                errors = {
                    authorName: !writtenWorkFormData.authorName ? "Du må oppgi forfatternavn" : undefined,
                    title: !writtenWorkFormData.title ? "Du må oppgi tittel på verket" : undefined,
                    filePath: !file ? "Du må laste opp din tekst for å gå videre" : undefined,
                };
                break;
            case FormSteps.TAG_FILE:
                if (writtenWorkFormData.tags && writtenWorkFormData.tags.length < 1) {
                    errors = {
                        tags: "Du må velge minst en tag",
                    };
                }
                break;
            case FormSteps.PITCH:
                if (!writtenWorkFormData.pitch || writtenWorkFormData.pitch.trim().length <= 0) {
                    errors = {
                        pitch: "Pitchen er tom",
                    };
                }

                if (writtenWorkFormData.pitch && writtenWorkFormData.pitch.trim().length >= 500) {
                    errors = {
                        pitch: "Pitchen kan maks inneholde 500 tegn",
                    };
                }
                break;
        }

        if (!objectEmpty(removeNullOrUndefinedKeys(errors))) {
            setErrors(errors);
            return false;
        }

        setErrors({});
        return true;
    }

    const value = {
        file,
        uploadState,
        uploadFile,
        next,
        prev,
        finish,
        errors,
        currentStep,
        highestVisitedStep,
        updateFile,
        updateWrittenWorkFormData,
        writtenWorkFormData,
        updateStep,
        maxNumberOfFiles: maxNumberOfFiles.current,
    };

    return (
        <UploadContext.Provider value={value}>
            {loadingInitialData ? <LoadingIndicator size="L" /> : children}
        </UploadContext.Provider>
    );
}
