import { useQuery } from "react-query";
import { FeatureToggles, FirestoreCollection, UserRole } from "shared";

import { useAuth } from "../../../contexts/AuthContext";
import { FirestoreApi, mapDocResult } from "../queries/queriesCommon";

interface UseFeatureToggleProps {
    viewPodcastTab: () => boolean;
    isBokbasenEnabled: () => boolean;
}

const FeatureToggleKeys = {
    featureToggle: "featureToggle",
    isBokbasenEnabled: () => [FeatureToggleKeys.featureToggle, "isBokbasenEnabled"],
};
export default function useFeatureToggle(): UseFeatureToggleProps {
    function isBokbasenEnabled(): boolean {
        const features = fetchFeatureToggles();
        const isAdmin = useAuth().userHasActiveRole(UserRole.ADMIN);
        const result = (features?.view_bokbasen_admin == true && isAdmin) || features?.view_bokbasen_all == true;
        console.log(features, "isAdmin", isAdmin, "isBokbasenEnabled", result);
        return result;
    }

    function viewPodcastTab(): boolean {
        const features = fetchFeatureToggles();
        return features?.view_podcast_tab == true;
    }

    function fetchFeatureToggles(): FeatureToggles | null | undefined {
        const result = useQuery({
            queryKey: FeatureToggleKeys.featureToggle,
            queryFn: () => FirestoreApi.getSingleDoc(FirestoreCollection.FEATURES, "toggles"),
            select: mapDocResult<FeatureToggles>(),
        });
        return result.data;
    }

    return {
        isBokbasenEnabled,
        viewPodcastTab,
    };
}
