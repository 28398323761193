import "../SignUpPanel.css";

import audiobook from "assets/audiobook.png";
import { Button, Column, Columns, Control, Field } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CreateUserRequest, UserRole } from "shared";

import { AppPath } from "../../../../common/AppPath";
import { PzTitle } from "../../../../components/text/PzTitle";
import { removeKeys } from "../../../../components/utils/ObjectUtils";
import { FirebaseAuthErrorCode, useAuth } from "../../../../contexts/AuthContext";
import { AgreementService } from "../../../../services/firestore/AgreementService";
import { AgreementData } from "../../../../services/firestore/types";
import SignupService from "../../../../services/SignupService";
import SignupUserInfoForm from "../SignupUserInfoForm";
import { AcceptanceCriteria } from "./AcceptanceCriteria";
import { InteresseProfilButton } from "./InteresseProfilButton";
import { InteresseProfilSelect } from "./InteresseProfilSelect";
import { SignupEditorCompanyInfo } from "./SignupEditorCompanyInfo";

export interface SignUpProps extends CreateUserRequest {
    passwordConfirm: string;
    acceptAgreements: boolean;
    acceptConfidentiality: boolean;
}

export default function SignUpEditorPanel(): ReactElement {
    const methods = useForm<SignUpProps>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: {
            acceptConfidentiality: false,
            acceptAgreements: false,
        },
    });

    const navigate = useNavigate();
    const { signup } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSelectIntresseProfil, setShowSelectInteresseProfil] = useState(false);
    const [latestEditorAgreements, setLatestEditorAgreements] = useState<AgreementData[]>([]);
    const { handleSubmit, getValues, register, setValue } = methods;

    useEffect(() => {
        register("tags");
    }, []);

    useEffect(() => {
        new AgreementService().getLatestRoleAgreements(UserRole.EDITOR).then(setLatestEditorAgreements);
    }, []);

    const onSubmit = (data) => handleSubmitSignUp(data);

    async function handleSubmitSignUp(data) {
        setError("");
        const persistData: CreateUserRequest = {
            ...data,
            companyName: getValues("companyName"),
            tags: getValues("tags") ?? [],
            createUserType: UserRole.EDITOR,
        };

        const signupData: CreateUserRequest = removeKeys(persistData, [
            "passwordConfirm",
            "acceptAgreements",
            "acceptConfidentiality",
        ]);

        setLoading(true);

        await signup(signupData)
            .then(() => navigate(AppPath.ROOT))
            .catch((err) => {
                if (err?.code === FirebaseAuthErrorCode.EMAIL_EXISTS) {
                    setError("Epost er allerede i bruk");
                } else {
                    setError("Det skjedde en feil. Vennligst prøv på nytt");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function validateEmail(email: string) {
        const isInvitedAsEditor = await new SignupService().isInvitedAsEditor(email);
        if (!isInvitedAsEditor) {
            return "Du kan ikke registrere deg som redaktør med denne epostadressen";
        }

        return true;
    }

    function hideTagsSelectPage() {
        setShowSelectInteresseProfil(false);
    }

    function saveTagsAndCloseTagsSelectPage(tags: string[]) {
        setValue("tags", tags);
        hideTagsSelectPage();
    }
    const selectedTags: string[] = getValues("tags") ?? [];
    return (
        <Columns className={"is-vcentered align-content-center signup-form"}>
            <Column className={"is-hidden-tablet-only is-hidden-mobile"}>
                <img id="signup-editor-logo" alt="Audiobook image" src={audiobook} />
            </Column>
            <Column id={"signup"} isFlexGrow1 m0 p2>
                <PzTitle>Registrering redaktør</PzTitle>
                {error && <div className="notification is-danger"> {error} </div>}
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Column isHidden={!showSelectIntresseProfil} m0 p0>
                            <InteresseProfilSelect
                                initialTags={selectedTags}
                                onSubmit={saveTagsAndCloseTagsSelectPage}
                                onCancel={hideTagsSelectPage}
                            />
                        </Column>
                        <Column isHidden={showSelectIntresseProfil}>
                            <SignupUserInfoForm validators={{ email: validateEmail }} telephoneNumberRequired={true} />
                            <InteresseProfilButton
                                onClick={() => setShowSelectInteresseProfil(true)}
                                tags={selectedTags}
                            />
                            <SignupEditorCompanyInfo />
                            <AcceptanceCriteria agreements={latestEditorAgreements} />
                            <Field isGrouped className="mt-5">
                                <Control>
                                    <Button
                                        isPrimary
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={loading}
                                        isLoading={loading}
                                    >
                                        Registrer
                                    </Button>
                                </Control>
                            </Field>
                        </Column>
                    </form>
                </FormProvider>
            </Column>
        </Columns>
    );
}
