import { UserRole } from "./UserTypes";

// Collection must have path in odd numbers
export enum FirestoreCollection {
    INVITES = "invites",
    EMAIL = "email",
    BOKBASEN_IMPORT_INFO = "bokbasen_import_info",
    BOKBASEN_INVENTORY_INFO = "bokbasen_inventory_info",
    WRITTEN_WORK = "writtenwork",
    BOOK_ACCESS = "bookAccess",
    USERS = "users",
    BOKBASEN_METADATA = "bokbasen_metadata",
    BOOK = "book",
    BATCH_STATE = "batch_state",
    BOOK_READER_STATE = "book_reader_state",
    BOOK_ECONOMICS = "book_economics",
    CUSTOMER_BOOK = "customerbooks",
    AUTHOR = "author",
    FEATURES = "features",
    BOOK_GROUP = "book_group",
    AUTHOR_PROFILE = "author_profile",
    PUBLISHER = "publisher",
    LEGAL_TERMS = "legalterms",
    NEWSLETTER_SUBSCRIBE = "newsletter_subscribe",
    PAYMENT = "payment",
    CATALOGUE = "catalogue",
    LICENSEE = "licensee",
    ROYALTIES = "royalties",
    ROYALTY_REPORT = "royalty_report",
    LICENSEE_PAYMENT_INFO = "payment_info",
    LICENSEE_SALE_INFO = "sale_info",
    SSR_METADATA = "SSR_METADATA",
    TRANSACTIONS = "transactions",
}

export interface FirestoreInvites {
    email: string;
    roles: UserRole[];
}
