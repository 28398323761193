import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useQuery, UseQueryResult } from "react-query";
import {
    Book,
    BookBokbasen,
    BookEconomics,
    BookFileAudioBook,
    BookGroupNames,
    BookPublizm,
    BookSource,
    BookType,
    dateToYYYYMMDDstring,
    DownloadBookLinkRequest,
    FirestoreCollection,
    getIfNotempty,
    isEmpty,
    UrlUtils,
} from "shared";

import { sortAudioBookByFilename } from "../../../common/BookUtils";
import { firestore, functions, functionsEuropeNorth, isDevMode } from "../../../firebase";
import {
    FirestoreApi,
    mapDocResult,
    mapFirstQueryResult,
    mapQueryResult,
    QueryReturnType,
    useFirestoreDocument2,
} from "../queries/queriesCommon";
import { StorageQueries } from "../queries/storageQueries";
import { AudioBookChapter } from "../types";

const FIREBASE_API_URL = isDevMode ? "http://localhost:7080/v0/b" : "https://firebasestorage.googleapis.com/v0/b";
export const getStorageUrl = () => `${FIREBASE_API_URL}/${import.meta.env.VITE_FIREBASE_storageBucket}/o`;
export const getStorageBookAssetUrl = (bookId: string, coverName: string, source?: BookSource) =>
    `${getStorageUrl()}/books%2F${bookId}%2Fassets%2F${coverName}${source != "publizm" ? ".jpeg" : ""}?alt=media`;
export const getStorageAuthorProfilepictureUrl = (path: string, updatedDate?: Date) =>
    `${getStorageUrl()}/${path?.replaceAll("/", "%2F")}?alt=media` +
    (updatedDate ? `&updated=${dateToYYYYMMDDstring(updatedDate)}` : "");
export const BookKeys = {
    all: ["books"] as const,
    booksByProfileId: (profileid: string) => [...BookKeys.all, "profileid", profileid] as const,
    booksByAuthorId: (authorId: string) => [...BookKeys.all, "authorId", authorId] as const,
    booksByTitleAndAuthor: (title: string, author?: string) =>
        [...BookKeys.all, "title", title, author ? "author" + author : ""] as const,
    bookById: (id: string, subscribe?: boolean) => ["book", "id", id, subscribe ? "subscribe" : ""] as const,
    loadEbook: (id?: string) => ["book", "id", id, "ebook"] as const,
    booksByGroupName: (groupName: string) => [...BookKeys.all, "groupName", groupName] as const,
    booksBySubject: (subject: string) => [...BookKeys.all, "subject", subject] as const,
    bookEconomics: (bookId: string) => [...BookKeys.all, "bookEconomics", bookId] as const,
    booksBySource: (source: string) => [...BookKeys.all, "source", source] as const,
    audiobook: (bookId: string) => [...BookKeys.all, "audiobook", bookId] as const,
    sampleAudiobook: (bookId: string) => [...BookKeys.audiobook(bookId), "sample"] as const,
};

export const BookGroupKeys = {
    all: (subscribe?: boolean) => ["bookgroups", subscribe ? "subscribe" : ""] as const,
};

export default function useBookApi() {
    const loadEbook = (bookId: string, enabled = true) => {
        const bookVariant = bookById(bookId);
        const ebook = bookVariant?.data?.ebook ?? bookVariant?.data?.bookFile;
        return useQuery({
            suspense: false,
            enabled: enabled && Boolean(ebook),
            queryKey: BookKeys.loadEbook(bookId),
            queryFn: async () => {
                if (!ebook || ebook.type !== BookType.EBOOK) {
                    return;
                }

                if (bookVariant?.data?.source == "bokbasen") {
                    const ebookUrl = await loadBookUrl(bookId, null, BookType.EBOOK);
                    return {
                        url: ebookUrl,
                        title: ebook.name!,
                        pages: ebook.pages,
                    };
                }

                const ebookUrl = await loadBookUrl(bookId, ebook.filePath, BookType.EBOOK);
                return {
                    url: ebookUrl,
                    title: ebook.name!,
                    pages: ebook.pages,
                };
            },
        });
    };

    const loadBookUrl = async (
        bookId: string,
        filePath: string | null,
        type: BookType,
        downloadable?: boolean
    ): Promise<string> => {
        return await BookFileQueries.loadBookUrl(bookId, filePath!, type);
    };
    const loadCoverUrl = (
        bookId?: string,
        _coverPath?: string,
        useSuspense?: boolean
    ): UseQueryResult<string | undefined> => {
        const bookData = bookById(bookId);
        const book = bookData.data!;
        const coverPath = getIfNotempty(_coverPath) ?? getIfNotempty(book?.audiobook?.coverPath) ?? book?.coverPath;
        return useQuery({
            queryKey: ["coverUrl", coverPath],
            queryFn: () => {
                if (!coverPath) return;
                return getCoverpath({
                    bookId: book.id!,
                    bookType: book.bookType!,
                    source: book.source!,
                    size: "original",
                    coverPath: bookToCoverpath(book),
                    updatedDate: book.updatedDate?.toDate(),
                });
            },
            suspense: useSuspense == undefined ? true : useSuspense,
        });
    };
    const loadAudibookSample = (bookId: string) => {
        return useQuery({
            queryKey: BookKeys.sampleAudiobook(bookId),
            queryFn: () => StorageQueries.loadStorageUrl("books/" + bookId + "/sample/audiobookSample.mp3"),
        });
    };
    const loadAudiobook = (
        bookId: string,
        downloadable?: boolean,
        enabled?: boolean
    ): UseQueryResult<AudioBookChapter[]> => {
        const bookData = bookById(bookId, false, enabled);
        const book = bookData.data;
        return useQuery({
            queryKey: BookKeys.audiobook(bookId),
            queryFn: () => {
                if (!book) {
                    return [];
                }
                if (book?.source == "bokbasen") {
                    return BookFileQueries.loadBokbasenAudiobook(book, bookId, downloadable);
                }
                return BookFileQueries.loadPublizmAudiobook(book, bookId);
            },
            enabled: Boolean(book) && Boolean(enabled),
        });
    };
    const bookByUrl = (bookUrl?: string, bookId?: string | null) => {
        const urlBookId = UrlUtils.getBookRefFromUrl(bookUrl);
        console.debug(`Fetching book with id ${bookId ?? urlBookId}`);
        if (bookId || urlBookId) {
            return bookById(bookId ?? urlBookId);
        }
        const { title, author } = UrlUtils.decodeBookUrlLegacy2(bookUrl!);
        return bookByAuthorAndTitle(title, author);
    };
    const bookByAuthorAndTitle = (title: string, authorName?: string) => {
        return useQuery({
            queryKey: BookKeys.booksByTitleAndAuthor(title, authorName),
            queryFn: () => queryByTitleAndAuthor(title, authorName),
            select: mapFirstQueryResult<Book>(),
            suspense: false,
        });
    };
    const getAllByAuthorProfileId = (authorProfileId?: string) => {
        return useQuery({
            queryKey: BookKeys.booksByProfileId(authorProfileId ?? ""),
            queryFn: () =>
                getDocs(
                    query(
                        collection(firestore, FirestoreCollection.BOOK),
                        where("author.profileId", "==", authorProfileId)
                    )
                ),
            select: mapQueryResult<Book>(),
            suspense: false,
            enabled: Boolean(authorProfileId),
        });
    };
    const getAllByAuthorId = (authorId?: string) => {
        return useQuery({
            queryKey: BookKeys.booksByAuthorId(authorId ?? ""),
            queryFn: async () => {
                const result = await getDocs(
                    query(
                        collection(firestore, FirestoreCollection.BOOK),
                        where("authorIds", "array-contains", authorId),
                        where("mainBook", "==", true)
                    )
                );
                if (result.empty) {
                    return getDocs(
                        query(collection(firestore, FirestoreCollection.BOOK), where("author.id", "==", authorId))
                    );
                }
                return result;
            },
            select: mapQueryResult<Book>(),
            suspense: false,
            enabled: Boolean(authorId),
        });
    };
    const getAllFeatured = () => booksByGroupname(BookGroupNames.AKTUELT);
    function booksByGroupname(groupName: string): QueryReturnType<Book[]> {
        return useQuery({
            queryKey: BookKeys.booksByGroupName(groupName),
            queryFn: () =>
                getDocs(
                    query(collection(firestore, FirestoreCollection.BOOK), where("groups", "array-contains", groupName))
                ),
            select: mapQueryResult(),
        });
    }
    function booksBySubject(subject: string): QueryReturnType<Book[]> {
        return useQuery({
            queryKey: BookKeys.booksBySubject(subject),
            queryFn: () =>
                getDocs(
                    query(
                        collection(firestore, FirestoreCollection.BOOK),
                        where("allSubjects", "array-contains", subject)
                    )
                ),
            select: mapQueryResult<Book>(),
        });
    }
    function bookEconomics(bookId: string): UseQueryResult<BookEconomics | null> {
        return useQuery({
            queryKey: BookKeys.bookEconomics(bookId),
            queryFn: () =>
                getDoc(
                    doc(
                        collection(firestore, FirestoreCollection.BOOK),
                        `${bookId}/${FirestoreCollection.BOOK_ECONOMICS}/${FirestoreCollection.BOOK_ECONOMICS}`
                    )
                ),
            select: mapDocResult<BookEconomics>(),
            enabled: Boolean(bookId),
        });
    }
    function bookById(bookId?: string, subscribe?: boolean, enabled?: boolean): UseQueryResult<Book | null> {
        const queryBookId = bookId ?? "";
        if (subscribe) {
            return useFirestoreDocument2(
                BookKeys.bookById(queryBookId, true),
                doc(collection(firestore, FirestoreCollection.BOOK), queryBookId),
                true,
                {
                    select: mapDocResult<Book>(),
                    suspense: false,
                    enabled: Boolean(bookId) && Boolean(enabled),
                }
            ) as UseQueryResult<Book | null>;
        }
        return useQuery({
            queryKey: BookKeys.bookById(queryBookId),
            queryFn: () => FirestoreApi.getSingleDoc(FirestoreCollection.BOOK, queryBookId),
            select: mapDocResult<Book>(),
            enabled: Boolean(bookId),
        });
    }
    function allBooksBySource(source: string) {
        return useQuery({
            queryKey: BookKeys.booksBySource(source),
            queryFn: () =>
                getDocs(query(collection(firestore, FirestoreCollection.BOOK), where("source", "==", source))),
            select: mapQueryResult<Book>(),

            keepPreviousData: true,
        });
    }

    return {
        getAllFeatured,
        loadCoverUrl,
        loadEbook,
        bookByUrl,
        getAllByAuthorId,
        getAllByAuthorProfileId,
        bookEconomics,
        bookById,
    };
}

const BookFileQueries = {
    loadBokbasenAudiobook: async (
        book: BookBokbasen,
        bookId: string,
        downloadable?: boolean
    ): Promise<AudioBookChapter[]> => {
        const audioBookUrl = await BookFileQueries.loadBookUrl(bookId, null, BookType.AUDIO, downloadable);
        return [
            {
                src: audioBookUrl,
                title: book.title,
                index: 0,
                durationSeconds: (book.bookFile as BookFileAudioBook)?.durationSeconds,
            } as AudioBookChapter,
        ];
    },
    loadPublizmAudiobook: async (book: BookPublizm, bookId: string): Promise<AudioBookChapter[]> => {
        const files = book?.audiobook?.files;
        const chaptersMap = files?.sort(sortAudioBookByFilename).map(async (file, index) => {
            return {
                src: await BookFileQueries.loadBookUrl(bookId, file.filePath!, BookType.AUDIO),
                title: file.title,
                index,
                durationSeconds: file.durationSeconds,
            } as AudioBookChapter;
        });

        if (!chaptersMap) {
            return [];
        }
        return await Promise.all(chaptersMap);
    },
    loadBookUrl: async (bookId: string, filePath: string | null, type: BookType, downloadable?: boolean) => {
        const createBookLinkFn = httpsCallable<DownloadBookLinkRequest, string>(functionsEuropeNorth, "createBookLink");
        const data = await createBookLinkFn({ bookId, path: filePath, type, downloadable });
        const tempFilePath = data.data as string;
        return StorageQueries.loadStorageUrl(tempFilePath);
    },
};

async function queryByTitleAndAuthor(title: string, authorName?: string) {
    const queries = [where("title", "==", title)];
    if (authorName) queries.push(where("author.name", "==", "authorName"));
    const result = await getDocs(query(collection(firestore, FirestoreCollection.BOOK), ...queries));
    if (result.empty) {
        return isEmpty(authorName) ? queryByUrlTitle(title) : queryByTitleAndAuthor(title);
    }
    return result;
}
async function queryByUrlTitle(title: string) {
    const queries = [where("urlTitle", "==", title)];
    return getDocs(query(collection(firestore, FirestoreCollection.BOOK), ...queries));
}

type CoverPathProps = {
    bookId: string;
    bookType: BookType;
    source: BookSource;
    updatedDate?: Date;
    coverPath?: string;
    size: "small" | "medium" | "original";
};

export function bookToCoverpath(book: Book): string {
    if (book.source == "bokbasen") {
        return book.coverPath;
    }
    return book.ebook?.coverPath ?? book.audiobook?.coverPath ?? book.coverPath;
}
export function getCoverpath({ source, bookType, bookId, size, coverPath, updatedDate }: CoverPathProps): string {
    // 250x250,250x374,700x700,700x1048
    let coverUrl;
    // if (coverPath) {
    //     coverUrl = `${getStorageUrl()}/${coverPath?.replaceAll("/", "%2F")}?alt=media`;
    // }

    if (source == "bokbasen") {
        coverUrl =
            bookType == BookType.AUDIO
                ? getStorageBookAssetUrl(
                      bookId,
                      size == "original" ? "cover" : size == "small" ? "cover_250x250" : "cover_700x700"
                  )
                : getStorageBookAssetUrl(
                      bookId,
                      size == "original" ? "cover" : size == "small" ? "cover_250x374" : "cover_700x1048"
                  );
    } else {
        coverUrl =
            bookType == BookType.AUDIO || bookType == BookType.PODCAST
                ? getStorageBookAssetUrl(
                      bookId,
                      size == "original"
                          ? "audiobookcover"
                          : size == "small"
                          ? "audiobookcover_250x374"
                          : "audiobookcover_700x700",
                      source
                  )
                : getStorageBookAssetUrl(
                      bookId,
                      size == "original"
                          ? "ebookcover"
                          : size == "small"
                          ? "ebookcover_250x374"
                          : "ebookcover_700x1048",
                      source
                  );
    }
    return coverUrl + (updatedDate ? `&updated=${dateToYYYYMMDDstring(updatedDate)}` : "");
}
