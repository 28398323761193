import { ReactElement, useState } from "react";
import { agreementDisplayValue } from "shared";

import AgreementModal from "../../../../components/modal/AgreementModal";
import { AgreementData } from "../../../../services/firestore/types";

interface ViewAgreementButtonProps {
    agreementData?: AgreementData;
}
export default function ViewAgreementButton({ agreementData }: ViewAgreementButtonProps): ReactElement {
    const [showAgreementModal, setShowAgreementModal] = useState<boolean>(false);
    const openUserInfoModal = () => setShowAgreementModal(true);
    const closeUserInfoModal = () => setShowAgreementModal(false);
    return (
        <>
            {agreementData ? (
                <>
                    <AgreementModal
                        isActive={showAgreementModal}
                        agreement={agreementData.agreement}
                        onClose={closeUserInfoModal}
                    />
                    {<a onClick={openUserInfoModal}>{agreementDisplayValue(agreementData.agreement.agreementType)}</a>}
                </>
            ) : (
                "Ukjent"
            )}
        </>
    );
}
