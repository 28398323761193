import PzButton from "components/form/PzButton";
import PzInputField from "components/form/PzInputField";
import { Column, Columns, Help, Section } from "components/trunx";
import React, { useState } from "react";
import { subscribeToNewsletter, validateEmailAddress } from "services/EmailService";

export default function NewsLetterInput({ onSubmit, isButtonPrimary = false, ignorePadding = false }: { onSubmit?: () => void, isButtonPrimary?: boolean, ignorePadding?: boolean }) {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    async function onClickNewsLetter(evt) {
        evt.preventDefault();
        setError("");
        setMessage("");
        if (!validateEmailAddress(email)) {
            setError("Du må oppgi en gyldig epost-adresse");
            return;
        }
        setLoading(true);
        await subscribeToNewsletter({ email });
        setMessage("Du har nå meldt deg inn for Publizms nyhetsbrev");
        setLoading(false);
        onSubmit?.()
    }

    return (
        <>
            <form onSubmit={onClickNewsLetter}>
                <Section isJustifyContentCenter isFlex pt0 pb5>
                    <Column is6={!ignorePadding}>
                        <Columns>
                            <Column style={{ maxWidth: "900px" }}>
                                <PzInputField
                                    mb4
                                    placeholder="Epost-adresse"
                                    name="newsletterEmail"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {error && <Help isDanger>{error}</Help>}
                                {message && <Help isSuccess>{message}</Help>}
                            </Column>
                            <Column is5>
                                <PzButton
                                    onClick={onClickNewsLetter}
                                    isPrimary={isButtonPrimary}
                                    isSecondary={!isButtonPrimary}
                                    style={{ borderColor: "orange" }}
                                    isFullwidth
                                    isLoading={loading}
                                    disabled={loading}
                                >
                                    Jeg er med
                                </PzButton>
                            </Column>
                        </Columns>
                        <Column isFlexWrapWrap isNarrow is12={!ignorePadding} mt0 pt0 >
                            Ved å trykke “JEG ER MED” godkjenner jeg at Publizm lagrer min e-postadresse for å sende meg
                            sine nyheter.
                        </Column>
                    </Column>
                </Section>
            </form>
        </>
    );
}
