import { ReactElement } from "react";

import LoadingIndicator from "../../../../../../components/LoadingIndicator";
import PageTemplate from "../../../../../../components/pagetemplate/PageTemplate";
import { Column, Columns, Container } from "../../../../../../components/trunx";
import { DashboardProvider } from "../../../../../../contexts/DashboardContext";
import { BookGroupQueries } from "../../../../../../services/firestore/queries/book/bookgroupQueries";
import BookGroupTable from "./BookGroupTable";

export default function BookGroupAdminDashboard(): ReactElement {
    return (
        <PageTemplate marginLessTop>
            <DashboardProvider>
                <Container>
                    <DashboardContent />
                </Container>
            </DashboardProvider>
        </PageTemplate>
    );
}

function DashboardContent() {
    const { data, isLoading } = BookGroupQueries.allSubscribe();

    function renderContent() {
        if (isLoading || data == undefined) {
            return <LoadingIndicator />;
        }

        return <BookGroupTable bookGroupList={data} />;
    }

    return (
        <Columns pt5>
            <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                {renderContent()}
            </Column>
        </Columns>
    );
}
