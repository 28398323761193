import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { AppPath } from "common/AppPath";
import { RenderWhen } from "components/auth/UserAuth";
import PzIconButton from "components/form/PzIconButton";
import PzText from "components/text/PzText";
import { Column, Columns, Title } from "components/trunx";
import { ReactElement } from "react";
import { useNavigate } from "react-router";
import { UserRole } from "shared";

export default function Header(): ReactElement {
    return (
        <>
            <RenderWhen userIs={UserRole.AUTHOR}>
                {RenderHeader(
                    "Vurdering av verk",
                    "Under finner du vurderingen av verket ditt. Gjerne ta kontakt med sjefsredaktør om du lurer på noe knyttet til vurderingen."
                )}
            </RenderWhen>
            <RenderWhen userIs={UserRole.EDITOR}>
                {RenderHeader("Vurdering av verk", "Du kan lagre eller sende inn vurderingen nederst på denne siden.")}
            </RenderWhen>

            <RenderWhen userIs={UserRole.ADMIN}>{RenderHeader("Vurdering av verk")}</RenderWhen>
        </>
    );
}

function RenderHeader(title: string, content?: string) {
    const navigate = useNavigate();

    return (
        <Columns>
            <Column isOneFifthDesktop isHiddenMobile isJustifyContentCenter isHiddenTouch isAlignSelfFlexStart isFlex>
                <PzIconButton iconPlacement="left" icon={faArrowCircleLeft} onClick={() => navigate(AppPath.DASHBOARD)}>
                    til oversikt
                </PzIconButton>
            </Column>

            <Column isThreeFifthsDesktop is12 pl0 pr0 mr0 mb3 pb4 hasTextCentered isFlex isAlignSelfFlexStart>
                <Column>
                    <Title pb0 mb0 style={{ fontWeight: 400, fontSize: "4rem", marginLeft: "-0.07em" }}>
                        {title}
                    </Title>
                    {content && <PzText containerProps={{ mt5: true }}>{content}</PzText>}
                </Column>
            </Column>
        </Columns>
    );
}
