import GiveBookAccessButton from "components/dashboard/GiveBookAccessButton";
import { Card, Column, Container } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { UserAgreement, UserRole, userRoleDisplayValue } from "shared";

import CollapsibleExpandButton from "../../../../components/dashboard/CollapsibleExpandButton";
import DeleteUserButton from "../../../../components/dashboard/DeleteUserButton";
import { ContactInfo } from "../../../../components/modal/UserInfoModal";
import PzCollapsible from "../../../../components/PzCollapsible";
import PzTable from "../../../../components/table/PzTable";
import { RowData } from "../../../../components/table/TableBody";
import { ColumnData } from "../../../../components/table/TableHead";
import { formatDate } from "../../../../components/utils/DateUtils";
import { notEmpty } from "../../../../components/utils/ObjectUtils";
import { AgreementHelpers, AgreementRoleMap } from "../../../../services/firestore/AgreementService";
import { AgreementData, UserInfoData } from "../../../../services/firestore/types";
import UserApprovedLabel from "./UserAgreementLabel";
import UserAgreementTable from "./UserAgreementTable";
import UserRoleOverview from "./UserRoleOverview";
interface UserAdminDashboardTableProps {
    userInfoDataList: UserInfoData[];
    agreementRoleMap: AgreementRoleMap;
    allAgreements: AgreementData[];
    reloadUsers: () => void;
}

export interface UserTableData {
    userId: string;
    name: string;
    email: string;
    telephoneNumber: string;
    createdDate?: Date;
    lastSignInTime?: Date;
    hasApprovedLatestAgreements: boolean;
    latestUserAgreements: AgreementData[];
    userAgreements: UserAgreement[];
    activeRoleDisplayValue: string;
    activeRole?: UserRole;
    roles: UserRole[];
}
export default function UserAdminDashboardTable({
    userInfoDataList,
    agreementRoleMap,
    allAgreements,
    reloadUsers,
}: UserAdminDashboardTableProps): ReactElement {
    const [userTableData, setUserTableData] = useState<UserTableData[]>([]);

    useEffect(() => {
        setUserTableData(mapUserTableData());
    }, [userInfoDataList]);

    function mapUserTableData(): UserTableData[] {
        return userInfoDataList.map((userData) => {
            const userRoles = userData.roles ?? [];
            const latestUserAgreements = userRoles.flatMap((role) => agreementRoleMap.get(role)).filter(notEmpty);
            const hasApprovedLatestAgreements = AgreementHelpers.hasUserApprovedLatestAgreements(
                latestUserAgreements,
                userData.userInfo.agreements ?? []
            );
            return {
                userId: userData.id,
                name: userData.userInfo?.surname + ", " + userData?.userInfo.firstname,
                email: userData.userInfo.email,
                telephoneNumber: userData.userInfo.telephoneNumber ?? "",
                userAgreements: userData.userInfo.agreements ?? [],
                hasApprovedLatestAgreements,
                createdDate: userData.userInfo.createdDate?.toDate(),
                lastSignInTime: userData.userInfo.lastSignInTime?.toDate(),
                // Used for sorting
                activeRoleDisplayValue: userRoleDisplayValue(userData.activeRole) ?? "-",
                activeRole: userData.activeRole,
                roles: userData.roles ?? [],
                latestUserAgreements,
            };
        });
    }
    const columns: ColumnData[] = [
        {
            label: "Navn",
            sortByProperty: "name",
        },
        {
            label: "Registrert",
            sortByProperty: "createdDate",
        },
        {
            label: "Hovedrolle",
            sortByProperty: "activeRoleDisplayValue",
        },
        {
            label: "Brukervilkår",
            sortByProperty: "hasApprovedLatestAgreements",
        },
        {
            label: "Sist aktiv",
            sortByProperty: "lastSignInTime",
        },
        {
            label: "",
        },
    ];
    function createRow(
        userTableData: UserTableData,
        toggleCollapsible?: () => void,
        isOpenCollapsible?: boolean
    ): RowData {
        return {
            collapsibleComponent: (
                <UserAdminDashboardTableCollapsbile
                    userTableData={userTableData}
                    allAgreements={allAgreements}
                    latestUserAgreements={userTableData.latestUserAgreements}
                    reloadUsers={reloadUsers}
                />
            ),
            components: [
                {
                    content: userTableData.name,
                },
                {
                    content: formatDate(userTableData.createdDate) ?? "-",
                },
                {
                    content: userTableData.activeRoleDisplayValue,
                },
                {
                    content: (
                        <UserApprovedLabel hasApprovedLatestAgreements={userTableData.hasApprovedLatestAgreements} />
                    ),
                },
                {
                    content: formatDate(userTableData.lastSignInTime) ?? "-",
                },
                {
                    content: <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />,
                    width: "1%",
                },
            ],
        };
    }
    function getSearchData(data: UserTableData) {
        return data.name + userRoleDisplayValue(data.activeRole) + data.email + data.telephoneNumber;
    }
    return (
        <PzTable
            createRow={createRow}
            data={userTableData}
            columns={columns}
            rowKeyGenerator={(data) => data.userId}
            searchData={getSearchData}
            defaultSortIndex={1}
        />
    );
}

interface UserAdminDashboardTableCollapsbileProps {
    userTableData: UserTableData;
    latestUserAgreements: AgreementData[];
    allAgreements: AgreementData[];
    reloadUsers: () => void;
}

function UserAdminDashboardTableCollapsbile({
    userTableData,
    latestUserAgreements,
    allAgreements,
    reloadUsers,
}: UserAdminDashboardTableCollapsbileProps) {
    return (
        <>
            <Card style={{ height: "auto" }} p0 m0 isFlex isFlexDirectionRow isJustifyContentSpaceBetween>
                <Column isFlex isFlexDirectionColumn>
                    <Column isFlexDesktop isFlexTablet isFlexWidescreen isFlexDirectionRow>
                        <Container>
                            <ContactInfo
                                user={{
                                    name: userTableData.name,
                                    email: userTableData.email,
                                    telephoneNumber: userTableData.telephoneNumber,
                                }}
                            />
                            <Container mt3>
                                <GiveBookAccessButton userUid={userTableData.userId} />
                                <DeleteUserButton
                                    userUid={userTableData.userId}
                                    userName={userTableData.name}
                                    onSubmit={reloadUsers}
                                />
                            </Container>
                        </Container>

                        <UserRoleOverview userTableData={userTableData} onUserUpdated={reloadUsers} />
                    </Column>
                </Column>
            </Card>
            <PzCollapsible title={"Brukervilkår"}>
                <Column>
                    <UserAgreementTable
                        userTableData={userTableData}
                        latestUserAgreements={latestUserAgreements}
                        allAgreements={allAgreements}
                    />
                </Column>
            </PzCollapsible>
        </>
    );
}
