import "./AuthorProfileDetailsPage.css";

import PageTemplate from "components/pagetemplate/PageTemplate";
import PzBookCard from "components/pz/PzBookCard";
import PzDivider from "components/pz/PzDivider";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { AuthorProfile, Book, getBookCoverPath } from "shared";

import LoadingIndicator from "../../../components/LoadingIndicator";
import PzItemsSwiper from "../../../components/swiper/PzItemsSwiper";
import useAuthorsApi from "../../../services/firestore/hooks/useAuthorsApi";
import useBookApi, { getCoverpath } from "../../../services/firestore/hooks/useBookApi";
import AuthorImage from "../common/AuthorImage";
import AuthorProfileDetailsProvider, { useAuthorProfileDetails } from "./AuthorProfileContext";

export default function AuthorProfileDetailsPage() {
    const { authorProfileName: authorProfileNameUrl } = useParams<{ authorProfileName: string }>();
    const { data: authorProfile } = useAuthorsApi().authorProfileByUrl(authorProfileNameUrl || "");
    const { data: booksByAuthorProfile } = useBookApi().getAllByAuthorId(authorProfile?.authorId);

    return (
        <PageTemplate>
            <AuthorProfileDetailsProvider
                authorProfileId={authorProfile?.id ? authorProfile.id : ""}
                authorProfile={authorProfile ?? ({} as AuthorProfile)}
                booksByAuthorProfile={booksByAuthorProfile ?? []}
            >
                <AuthorDetailsContainer />
            </AuthorProfileDetailsProvider>
        </PageTemplate>
    );
}

function AuthorDetailsContainer() {
    return (
        <div className="author-details-container ">
            <DisplayAuthorInfo />
            <DisplayBooksByAuthor />
        </div>
    );
}

function DisplayAuthorInfo() {
    const { authorProfile } = useAuthorProfileDetails();

    return (
        <div className="display-author-info-container">
            {authorProfile && (
                <AuthorImage
                    coverPath={authorProfile.profilePicturePath}
                    lastChanged={authorProfile.lastChanged?.toDate()}
                />
            )}
            {authorProfile && (
                <div className="display-author-info-text mt-4">
                    <h2>{authorProfile.displayName}</h2>
                    <p className="text-lg" style={{ textAlign: "left" }}>
                        <ReactMarkdown
                            // @ts-ignore
                            rehypePlugins={[rehypeRaw]}
                            children={authorProfile.description}
                        />
                    </p>
                </div>
            )}
        </div>
    );
}

function DisplayBooksByAuthor() {
    const { booksByAuthorProfile } = useAuthorProfileDetails();

    function renderBook(book: Book) {
        return (
            <React.Suspense fallback={<LoadingIndicator />}>
                <div style={{ width: "100%" }} key={book.id}>
                    <PzBookCard
                        author={book.author.name}
                        authorHighlighted={book.author.name}
                        prices={book.prices}
                        coverPath={getCoverpath({
                            source: book.source!,
                            bookId: book.id!,
                            bookType: book.bookType!,
                            size: "small",
                            updatedDate: book.updatedDate?.toDate(),
                            coverPath: book.coverPath,
                        })}
                        title={book.title}
                        titleHighlighted={book.title}
                        id={book.id}
                        key={book.id}
                        size="small"
                    />
                </div>
            </React.Suspense>
        );
    }
    return (
        <div className="display-books-by-author-profile-carousel">
            {booksByAuthorProfile.length > 0 && (
                <>
                    <h3 className="mb-0">Bøker</h3>
                    <PzDivider />
                    <PzItemsSwiper slides={booksByAuthorProfile.map(renderBook)} />
                </>
            )}
        </div>
    );
}
