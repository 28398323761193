import PzModal from "components/modal/PzModal";
import { Content, Modal } from "components/trunx";
import { ReactElement } from "react";

import PzButton from "../form/PzButton";
import AgreementContent from "../pagetemplate/AgreementContent";

interface AgreementModalProps {
    onAccept: () => void;
    onClose: () => void;
    isActive: boolean;
    content: string;
    onAcceptText: string;
    onCloseText?: string;
}

export default function AgreementModal({
    isActive,
    onAccept,
    onAcceptText,
    onCloseText,
    onClose,
    content,
}: AgreementModalProps): ReactElement {
    function renderModal() {
        return (
            <>
                <Modal.Card.Body style={{ maxWidth: "35rem", height: "100%", overflow: "hidden" }}>
                    <Content style={{ overflowY: "auto", maxHeight: "30rem", height: "max-content" }}>
                        <AgreementContent content={content ?? ""} />
                    </Content>
                </Modal.Card.Body>
                <Modal.Card.Foot>
                    <PzButton isPrimary onClick={onAccept}>
                        {onAcceptText}
                    </PzButton>
                    <PzButton isSecondary onClick={onClose}>
                        {onCloseText ?? "Avbryt"}
                    </PzButton>
                </Modal.Card.Foot>
            </>
        );
    }
    if (!isActive) {
        return <></>;
    }

    return (
        <PzModal contentStyle={{ maxHeight: "50rem" }} isActive={isActive} onClose={onClose}>
            {renderModal()}
        </PzModal>
    );
}
