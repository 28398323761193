import "./SmocDialogPage.css";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { AppPath } from "common/AppPath";
import PzIconButton from "components/form/PzIconButton";
import PageTemplate from "components/pagetemplate/PageTemplate";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function SmocDialogPage() {
    const navigate = useNavigate();
    const [queryParameters, _] = useSearchParams();
    const domainName = queryParameters.get("smoc_domain_name");
    const key = queryParameters.get("smoc_key");

    return (
        <PageTemplate>
            <PzIconButton
                isSmall
                iconPlacement="left"
                icon={faArrowLeft}
                onClick={() => navigate(-1)}
                style={{ color: "var(--persimon)" }}
            >
                Tilbake
            </PzIconButton>

            {(!key || !domainName) && (
                <p className="text-lg" style={{ textAlign: "center" }}>
                    Vi klarte ikke å åpne riktig dialog. Vennligst prøv igjen senere, eller ta kontakt med oss.
                </p>
            )}

            {key && domainName && (
                <iframe
                    id="smoc_iframe"
                    name="code"
                    src={`${AppPath.SMOC_BASE_URL}/${domainName}/${key}?iframe_mode=true`}
                    className="smoc"
                    scrolling="no"
                ></iframe>
            )}
        </PageTemplate>
    );
}
