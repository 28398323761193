import { Timestamp } from "firebase/firestore";
import React, { ReactElement } from "react";
import { AgreementType } from "shared";

import PzTable from "../../../../components/table/PzTable";
import { RowData } from "../../../../components/table/TableBody";
import { ColumnData } from "../../../../components/table/TableHead";
import { formatDate } from "../../../../components/utils/DateUtils";
import { sortByDate } from "../../../../components/utils/SortUtils";
import { AgreementData, OrderType } from "../../../../services/firestore/types";
import { UserTableData } from "./UserAdminDashboardTable";
import ViewAgreementButton from "./ViewAgreementButton";
interface UserAgreementTableProps {
    userTableData: UserTableData;
    latestUserAgreements: AgreementData[];
    allAgreements: AgreementData[];
}

interface AgreementTableData {
    agreementType: AgreementType;
    agreementContent: string;
    createdDate?: Timestamp;
    approvedDate?: Timestamp;
    documentId: string;
    agreementData: AgreementData;
}

export default function UserAgreementTable({
    latestUserAgreements,
    allAgreements,
    userTableData,
}: UserAgreementTableProps): ReactElement {
    const getAgreementData = (agreementId: string) =>
        allAgreements.find((agreement) => agreement.documentId === agreementId);

    const agreementTableData = () => {
        const userApprovedAgreements = userTableData.userAgreements;
        const notApprovedAgreements = latestUserAgreements.filter(
            (agreement) =>
                !userApprovedAgreements.some((userAgreement) => userAgreement.documentId === agreement.documentId)
        );
        const userAgreementsTableData: AgreementTableData[] = userApprovedAgreements.map((userAgreement) => {
            const agreementData = getAgreementData(userAgreement.documentId);
            return {
                agreementType: userAgreement.agreementType,
                documentId: userAgreement.documentId,
                approvedDate: userAgreement.approvedDate,
                agreementContent: agreementData?.agreement.agreementContent,
                createdDate: agreementData?.agreement.date,
                agreementData: agreementData,
            } as AgreementTableData;
        });

        const notApprovedAgreementsTableData: AgreementTableData[] = notApprovedAgreements.map((agreementData) => {
            return {
                agreementType: agreementData.agreement.agreementType,
                documentId: agreementData.documentId,
                createdDate: agreementData.agreement.date,
                agreementContent: agreementData?.agreement.agreementContent,
                agreementData: agreementData,
            } as AgreementTableData;
        });

        return [...userAgreementsTableData, ...notApprovedAgreementsTableData].sort((a, b) =>
            sortByDate(a.createdDate, b.createdDate, OrderType.DESC)
        );
    };

    const columns: ColumnData[] = [
        {
            label: "Opprettet",
        },
        {
            label: "Godkjent",
        },
        {
            label: "Brukervilkår",
        },
    ];
    function createRow(agreementTableData: AgreementTableData): RowData {
        return {
            components: [
                {
                    content: formatDate(agreementTableData.createdDate) ?? "",
                },
                {
                    content: formatDate(agreementTableData.approvedDate) ?? "",
                },
                {
                    content: <ViewAgreementButton agreementData={agreementTableData.agreementData} />,
                },
            ],
        };
    }

    return (
        <PzTable
            isFullwidth
            isShadowless
            isNarrow
            createRow={createRow}
            data={agreementTableData()}
            columns={columns}
            rowKeyGenerator={(data) => data.documentId}
            defaultSortIndex={1}
        />
    );
}
