import { Timestamp } from "firebase-admin/firestore";

import { filterUniqueByKey, removeNullOrUndefinedKeys } from "../utils";
import { Currency, Language } from "./CommonTypes";
import { DataSource, FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";

export const PUBLIZM_EBOOK_COVER_FILENAME = "ebookcover";
export const PUBLIZM_AUDIOBOOK_COVER_FILENAME = "audiobookcover";
export enum ContributorRoleCode {
    ByAuthor,
    With,
    ScreenplayBy,
    LibrettoBy,
    LyricsBy,
    ByComposer,
    ByArtist,
    ByPhotographer,
    CreatedBy,
    FromAnIdeaBy,
    DesignedBy,
    IllustratedBy,
    PhotographsBy,
    TextBy,
    PrefaceBy,
    PrologueBy,
    SummaryBy,
    SupplementBy,
    AfterwordBy,
    NotesBy,
    CommentariesBy,
    EpilogueBy,
    ForewordBy,
    IntroductionBy,
    FootnotesBy,
    MemoirBy,
    ExperimentsBy,
    IntroductionAndNotesBy,
    SoftwareWrittenBy,
    BookAndLyricsBy,
    ContributionsBy,
    AppendixBy,
    IndexBy,
    DrawingsBy,
    CoverDesignOrArtworkBy,
    PreliminaryWorkBy,
    OriginalAuthor,
    MapsBy,
    InkedOrColoredBy,
    PaperEngineeringBy,
    ContinuedBy,
    Interviewer,
    Interviewee,
    ComicScriptBy,
    Inker,
    Colorist,
    Letterer,
    ResearchBy,
    OtherPrimaryCreator,
    EditedBy,
    RevisedBy,
    RetoldBy,
    AbridgedBy,
    AdaptedBy,
    TranslatedBy,
    AsToldBy,
    TranslatedWithCommentaryBy,
    SeriesEditedBy,
    EditedAndTranslatedBy,
    EditorInChief,
    GuestEditor,
    VolumeEditor,
    EditorialBoardMember,
    EditorialCoordinationBy,
    ManagingEditor,
    FoundedBy,
    PreparedForPublicationBy,
    AssociateEditor,
    ConsultantEditor,
    GeneralEditor,
    DramatizedBy,
    GeneralRapporteur,
    LiteraryEditor,
    ArrangedBy,
    TechnicalEditor,
    ThesisAdvisorOrSupervisor,
    ThesisExaminer,
    ScientificEditor,
    HistoricalAdvisor,
    OriginalEditor,
    TranslationRevisedBy,
    OtherAdaptationBy,
    CompiledBy,
    SelectedBy,
    NonTextMaterialSelectedBy,
    CuratedBy,
    OtherCompilationBy,
    Producer,
    Director,
    Conductor,
    Choreographer,
    OtherDirectionBy,
    Actor,
    Dancer,
    Narrator,
    Commentator,
    VocalSoloist,
    InstrumentalSoloist,
    ReadBy,
    PerformedByMusicalGroup,
    Speaker,
    Presenter,
    PerformedBy,
    FilmedPhotographedBy,
    Editor,
    OtherRecordingBy,
    AssistedBy,
    HonoredDedicatedTo,
    EnactingJurisdiction,
    PeerReviewed,
    VariousRoles,
    Other,
}

export enum BookGroupNames {
    AKTUELT = "AKTUELT",
}
export interface DownloadBookLinkRequest {
    bookId: string;
    path?: string | null;
    type: BookType;
    downloadable?: boolean;
}

export interface BookAccess {
    id?: string;
    bookId: string;
    userId: string;
    tempBookPath: string;
    bookPath: string;
    downloadable?: boolean;
    bookType: BookType;
    validTo: Timestamp;
    accessId: string;
    createdDate: Timestamp;
}

export type AuthorType = ContributorRoleCode;

export enum NameType {
    UNSPECIFIED = "UNSPECIFIED",
    PSEUDONYM = "PSEUDONYM",
    AUTHORITY_CONTROLLED_NAME = "AUTHORITY_CONTROLLED_NAME",
    EARLIER_NAME = "EARLIER_NAME",
    REAL_NAME = "REAL_NAME",
    TRANSLATED_FORM_OF_PRIMARY_NAME = "TRANSLATED_FORM_OF_PRIMARY_NAME",
    LATER_NAME = "LATER_NAME",
    FICTIONAL_NAME = "FICTIONAL_NAME",
}
export enum ContributorDateRole {
    DATE_OF_BIRTH = "50",
    DATE_OF_DEATH = "51",
    FLOURISHED_AROUND = "56",
}

export class BookVersionMapper extends FirestoreVersionMapper<Book, AllBooks> {
    public latestVersion = BookLatestVersion;
    public mapToLatest(book: AllBooks): Book {
        if (book.version === BookLatestVersion) {
            return book;
        }

        switch (book.version) {
            case 1:
                return {
                    ...book,
                    source: "publizm",
                    version: BookLatestVersion,
                };
            case undefined:
                return {
                    ...book,
                    version: BookLatestVersion,
                };
            default:
                throw new Error(`Mapper for book version ${book["version"]} not implemented`);
        }
    }
}

export type BookSource = DataSource;
export type Book = BookPublizm | BookBokbasen;
export const BookLatestVersion = 2;

type AllBooks = Book;
export interface BookPublizm extends BookBase {
    source: "publizm";
}

export interface BookCover {
    coverLink: string;
    coverType: "original" | "small" | "large";
    lastUpdated?: string;
}

export interface BookInventoryDetails {
    format: string;
    drm?: string;
    inventoryId?: string;
    isbn?: number;
    published: boolean;
    sampleAvailable: boolean;
    hasAccess: boolean;
    createdDate: Date;
    updatedDate: Date;
    publisherId: string;
    version: number;
    firstpublished: string;
    title: string;
    author: string;
    name: string;
}

export interface BookCollectionDetails {
    title: string;
    partNumber?: number;
    collectionId: string;
    sourceName: string;
    type: BookCollectionType;
    bookId?: string; // Sometimes ebok and audiobook can be in different collections, but still be related
}

export enum BookCollectionType {
    SERIES = "SERIES",
    OTHER_AFFILIATION = "OTHER_AFFILIATION",
    UNSPECIFIED = "UNSPECIFIED",
}

export interface RelatedBookBundle extends RelatedBook {
    type: BookType.BUNDLE;
    bookIds: string[];
    title: string;
    language: Language;
    price: number;

    mainBook: false;
    publishedYear: never;
    coverpath: never;
    inventoryId: never;
    bookId: never;
    isbn: never;
}

export interface RelatedBook {
    bookId: string;
    isbn: string;
    title: string;
    coverpath: string;
    mainBook: boolean;
    publishedYear: string;
    language: Language;
    type: BookType;
    price: number;
    dateOfFirstPublication: string;
    originalPrice: number;
    inventoryId?: string;
}

export interface BundleInfo {
    bookIds: string[];
    price: number;
}

export interface BookBokbasen extends BookBase {
    source: "bokbasen";
    bookFile: BookFileAudioBook | BookFileEbook;
    edition: number;
    audience: string;
    bokbasenId: string;
    bokbasenReferanse: string;
    inventoryId?: string;
    inventoryDetails?: BookInventoryDetails;
    relatedBooks: RelatedBook[]; // Is only listed when mainBook=true
    mainBookRef?: string; // Is only listed when mainBook=false
    relationId?: string; // All books with same content but different variants will have the same relationId (ex one book with ebook and audiobook variant)
    replacedById?: string;
    replacesId?: string;
    blaIBokaLink?: string;
    productComposition?: string;
    isbn: string;
    originalLanguage: string;
    publishingDetails: BookPublishingDetails;
    authors: BookAuthor[];
    subjectDetails: BookSubject[];
    salesRestrictions: SalesRestrictionDetails[];
    salesRight?: SalesRight;
    prize?: BookPrize;
    notes?: string;
    annotation?: string;
    themas?: string[];
    themaDetails?: BookSubject[];
    tableOfContents?: string;
    dateOfFirstPublication?: Timestamp;
    publisherId?: string;
    // Temp metadata after bokbasen import. These will be removed after import is finished
    coverDetails: BookCover[];
    bokbasenAuthors: AuthorBokbasen[];
    bundleDetails: BundleInfo[];
    inventoryStatusSynced?: boolean;
}

export interface AuthorImported extends AuthorBokbasen {
    sequenceNumber: number;
    role: string;
}

export interface BookReview {
    content: string; //
    rating?: string;
    ratingLimit?: string;
    ratingType?: string; // Om det er terningkast eller annen type vurderingsmåte
    author: string;
    source: string; // Kilde som anmeldelse er hentet fra (feks VG, en person, forfatter osv)
}
export interface BookImprint {
    name: string;
    id: string;
}

export interface BookPublishingDetails {
    imprints: BookImprint[];
    publisher: BookPublisher;
    productComposition?: string;
    publicationDate: string;
    dateOfFirstPublication: string;
    dateOfFirstPublicationInOriginalLanguage?: string;
    publicAnnouncementDate?: string;
    lastReprintDate: string;
    lastReissueDate?: string;
}
export interface BookPublisher {
    role?: string;
    name: string;
    bokbasenId: string;
    glnId: string;
    website: string;
}
export interface SalesRight {
    type: string;
    countries: string[];
}
export interface SalesRestrictionDetails {
    territory: string;
    value?: string;
    type: string;
    note?: string;
    outlet?: {
        id: number;
        idType: string;
        idName: string;
        name: string;
    };
}
export enum SalesRightType {
    NO_SUBSCRIPTION = "NO_SUBSCRIPTION",
    NO_RESTRICTION = "NO_RESTRICTION",
    ONLINE_RETAIL_ONLY = "ONLINE_RETAIL_ONLY",
    UNSPECIFIED = "UNSPECIFIED",
    RETAILER_EXCLUSIVE = "RETAILER_EXCLUSIVE",
    RETAILER_EXCLUDED = "RETAILER_EXCLUDED",
    UNKOWN = "UNKOWN",
    NO_ONLINE_SALE = "NO_ONLINE_SALE",
}
export type AuthorWebsiteRole = "homepage" | "twitter" | "instagram" | "facebook";
export interface AuthorWebsite {
    bokbasenRole?: string;
    role?: AuthorWebsiteRole;
    description: string;
    link: string;
}

export interface BookSubject {
    name: string;
    kode?: string;
    type: string;
}

export interface BookAuthor {
    id: string;
    profileId: string;
    bokbasenId?: string;
    sequenceNumber?: number;
    name: string;
    description: string;
    profilePicturePath: string;
    role: string;
    websites?: AuthorWebsite[];
}
export interface BookBase extends PzFirestoreBase {
    source: BookSource;
    bookType: BookType;
    mainBook: boolean; // Is main book when the book has related books
    collectionIds?: string[];
    collectionNames?: string[];
    collectionDetails?: BookCollectionDetails[];
    groups?: string[];
    title: string;
    mainTitle?: string;
    audience?: string;
    originalTitle?: string;
    subtitle?: string;
    urlTitle?: string;
    summary: string;
    shortSummary?: string;
    reviews?: string;
    reviewsReaders?: string;
    language: Language;
    tags: string[];
    subjects: string[];
    allSubjects: string[];
    litteraryType?: string;
    featured?: boolean;
    author: BookAuthor;
    authorIds?: string[];
    coverPath: string;
    coverLastUpdated: string;
    technicalProtection?: string;
    publisher: string;
    publishedYear: string;
    publisherLocation?: string;
    createdDate: Timestamp;
    updatedDate: Timestamp;
    lastUpdated: Timestamp;
    audiobook?: BookFileAudioBook; // Deprecated
    ebook?: BookFileEbook; // Deprecated
    bookFile?: BookFileAudioBook | BookFileEbook;
    prices: BookPrice[];
    price: BookPrice;
    licensees: BookLicensee[];
    licenseeIds: string[];
    status: BookStatus;
    searchField: string[];
    bookReviews: BookReview[];
}

export const isEbook = (file: Partial<BookFile>): file is BookFileEbook => file.type === BookType.EBOOK;
export const isAudioBook = (file: Partial<BookFile>): file is BookFileAudioBook =>
    file.type === BookType.AUDIO || file.type === BookType.PODCAST;

export enum BookStatus {
    DRAFT = "DRAFT",
    INACTIVE = "INACTIVE",
    PUBLISHED = "PUBLISHED",
    NOT_PUBLISHED = "NOT_PUBLISHED", // Missing inventory data or not published by publisher
    HIDDEN = "HIDDEN",
    DELETED = "DELETED",
    MISSING_INVENTORY_DETAILS = "MISSING_INVENTORY_DETAILS",
}
export const BOOK_TAGS = [
    "Bekjennelse",
    "Drama",
    "Fantasy",
    "Komedie",
    "Oppvekst",
    "Cli-fi",
    "Tragedie",
    "Faksjon",
    "Eventyr",
    "Sci-fi",
    "Skitten realisme",
    "Krim",
    "Ungdom",
    "Romantikk",
    "Spenning",
    "Dystopi",
    "Technothriller",
    "Erotikk",
    "Psykologisk drama",
    "Utopi",
    "Grøsser",
    "Feelgood",
    "Lyrikk",
    "Mysterier",
    "Familie",
    "Brev",
    "Historie",
    "Politikk",
    "Dagbok",
    "Sakprosa",
    "Barn",
];

export const MINIMUM_BOOK_TAGS = [
    "Bekjennelse",
    "Drama",
    "Oppvekst",
    "Eventyr",
    "Sci-fi",
    "Krim",
    "Spenning",
    "Erotikk",
    "Psykologisk drama",
    "Grøsser",
    "Feelgood",
    "Familie",
    "Historie",
    "Politikk",
    "Barn",
];

export enum BookType {
    EBOOK = "EBOOK",
    AUDIO = "AUDIO",
    PODCAST = "PODCAST",
    BUNDLE = "BUNDLE",
}

export enum EbookFormat {
    EPUB = "EPUB",
    MOBI = "MOBI",
}
export enum AudioBookFormat {
    MP3 = "MP3",
    WAVE = "WAVE",
}

export interface BookPrice {
    bookId?: string;
    value: number;
    originalPrice?: number;
    type: BookPriceType;
    isFree?: boolean;
    unpricedType?: string;
    priceType?: string;
    qualifier?: string;
    currency: Currency;
    tax?: BookPriceTax;
}

export interface BookPriceTax {
    type: "standard" | "zero";
    percent: string;
    taxableAmount: string;
}

export function hasSample(book: Book) {
    if (book.source == "bokbasen") {
        if (book.bookType == BookType.AUDIO) {
            return book.bookFile?.sample?.filePath != undefined;
        }
        return book.inventoryId && book.inventoryDetails?.sampleAvailable;
    }

    return book.audiobook?.sample?.fileName || book.audiobook?.sample?.link;
}
export function getBookPriceByType(book: Book, type: BookPriceType): number {
    if (book.source == "bokbasen") {
        return getBookPrices(book).find((b) => b.type === type)?.value ?? 0;
    }
    return book.prices.find((b) => b.type === type)?.value ?? 0;
}

export enum BookPriceType {
    EBOOK = "EBOOK",
    AUDIOBOOK = "AUDIOBOOK",
    BUNDLE = "BUNDLE",
}

export enum BookLicenseeRole {
    AUTHOR = "AUTHOR",
    EDITOR = "EDITOR",
    TRANSLATOR = "TRANSLATOR",
    COMPOSER = "COMPOSER",
    SOUND_DESIGNER = "SOUND_DESIGNER",
    WRITER = "WRITER",
    AGENCY = "AGENCY",
    PUBLISHER = "PUBLISHER",
    BOOKSTORE = "BOOKSTORE",
}

export function convertBookPriceTypeToBookType(bookType: BookPriceType): BookType {
    switch (bookType) {
        case BookPriceType.EBOOK:
            return BookType.EBOOK;
        case BookPriceType.AUDIOBOOK:
            return BookType.AUDIO;
        case BookPriceType.BUNDLE:
            return BookType.BUNDLE;
    }
}
export function bookTypeDisplayValue(type: BookType) {
    switch (type) {
        case BookType.EBOOK:
            return "E-bok";
        case BookType.AUDIO:
            return "Lydbok";
        case BookType.BUNDLE:
            return "Bundle";
        default:
            return "";
    }
}

export function bookLicenseeDisplayValue(role: BookLicenseeRole): string {
    switch (role) {
        case BookLicenseeRole.AUTHOR:
            return "Forfatter";
        case BookLicenseeRole.EDITOR:
            return "Redaktør";
        case BookLicenseeRole.TRANSLATOR:
            return "Oversetter";
        case BookLicenseeRole.COMPOSER:
            return "Komponist";
        case BookLicenseeRole.SOUND_DESIGNER:
            return "Lyddesigner";
        case BookLicenseeRole.WRITER:
            return "Skribent";
        case BookLicenseeRole.AGENCY:
            return "Byrå";
        default:
            return "";
    }
}

export interface BookPrize {
    name: string;
    year: number;
}
export interface BookLicenseeBase {
    source: DataSource;
    licenseeId: string;
    name: string;
    percentage: number;
    role: BookLicenseeRole;
    website?: string;
    bokbasenId?: string;
    glnId?: string;
}

export interface BookLicenseeBokbasen extends BookLicenseeBase {
    source: "bokbasen";
    bokbasenId: string;
    glnId: string;
}

export interface BookLicenseePublizm extends BookLicenseeBase {
    source: "publizm";
}

export type BookLicensee = BookLicenseePublizm | BookLicenseeBokbasen;
export interface BookFile {
    name?: string;
    coverPath?: string;
    isbn?: number;
    type: BookType;
    translator?: string;
    licensees?: BookLicensee[];
    // Used for easy querying by licenseeId
    licenseeIds?: string[];
}

export interface EbookLicense {
    name: string;
    humanReadable: boolean;
    link: string;
}
export interface BookFileEbook extends BookFile {
    type: BookType.EBOOK;
    license?: EbookLicense;
    filePath: string;
    illustrated?: boolean;
    format: EbookFormat;
    pages: number;
    edition?: number;
    samplePages?: number;
    fileSizeInBytes?: number;
    translator?: string;
    sample?: {
        link?: string;
        name?: string;
        filePath?: string;
    };
    kindle?: {
        name: string;
        filePath: string;
    };
}

export interface AudioBookFile {
    filePath?: string;
    durationSeconds: number;
    fileName: string;
    title: string;
    order?: number;
    link?: string;
    lastUpdated?: string;
    sample?: boolean;
}

export enum AudioBookContentType {
    AUDIOBOOK = "AUDIOBOOK",
    RADIODRAMA = "RADIODRAMA",
}
export interface BookFileAudioBook extends BookFile {
    type: BookType.AUDIO | BookType.PODCAST;
    format: AudioBookFormat;
    contentType: AudioBookContentType[];
    narrator: string;
    sounddesigner: string;
    producer: string;
    audiofileId?: string;
    durationSeconds?: number;
    sample?: AudioBookFile;
    sampleLink?: string;
    files: AudioBookFile[];
}

// Subcollection under Book
export type BookEconomics = BookEconomicsBase;
export const BookEconomicsLatestVersion = 1;

type AllBookEconomics = BookEconomicsBase;

export class BookEconomicsVersionMapper extends FirestoreVersionMapper<BookEconomics, AllBookEconomics> {
    public latestVersion = BookEconomicsLatestVersion;
    public mapToLatest(bookEconomics: AllBookEconomics): BookEconomics {
        if (bookEconomics.version === BookEconomicsLatestVersion) {
            return bookEconomics;
        }

        switch (bookEconomics.version) {
            case undefined:
                return {
                    ...bookEconomics,
                    version: BookEconomicsLatestVersion,
                };
            default:
                throw new Error(`Mapper for bookEconomics version ${bookEconomics["version"]} not implemented`);
        }
    }
}

export interface BookEconomicsProduct {
    type: BookType;
    productionCost: number;
    earnings: number;
}

export interface BookEconomicsLicensee {
    licenseeId: string;
    bookType: BookType;
    advancePaid?: number; //forskudd
    earnings: number;
    advanceSettled?: number;
}

export interface BookEconomicsBase extends PzFirestoreBase {
    products: BookEconomicsProduct[];
    licensees: BookEconomicsLicensee[];
    licenseeIds: string[];
    totalEarnings: number; // Inkluderer bare salg av indviduel bok selv om det er solgt som bundle (salgspris deles på 50% hvis bundle har to bøker)
    totalEarningsIncludingBundle?: number; // Inkluderer inntekt fra alle bøker når salget gjelder bundle
    totalSales: number;
}

export function authorDisplayValue(author: BookAuthor) {
    if (!author) return "Ukjent navn";
    const role = roleDisplayValue.get(author.role);
    return role ? `${author.name} (${role})` : author.name;
}

export function getBookReviews(book: Book) {
    if (book.source == "publizm") {
        return book.reviews;
    }

    return book.bookReviews;
}

export function getBookCoverPath(book: Book, type: BookType): string | undefined {
    if (book.source == "bokbasen") {
        return book.bookType == type
            ? book.coverPath
            : book.relatedBooks?.find((t) => t.type == type)?.coverpath ?? book.coverPath;
    }

    return type == BookType.EBOOK ? book.ebook?.coverPath : book.audiobook?.coverPath;
}

export function getBookIdByType(book: Book, bookId: string, type: BookType): string | undefined {
    if (book.source == "bokbasen") {
        return book.bookType == type ? bookId : book.relatedBooks?.find((b) => b.type == type)?.bookId;
    }

    return bookId;
}

export function createBookPrice(price: number, originalPrice: number, bookType: BookType, bookId: string): BookPrice {
    return {
        bookId,
        value: price,
        originalPrice: originalPrice ?? -1,
        currency: Currency.NOK,
        type: bookType == BookType.AUDIO ? BookPriceType.AUDIOBOOK : BookPriceType.EBOOK,
    };
}

export function getYearOfFirstPublication(book: BookBokbasen, bookId: string): string | undefined {
    if (book.bokbasenReferanse == bookId) {
        return book.publishingDetails?.dateOfFirstPublication;
    }

    return book.relatedBooks?.find((b) => b.bookId == bookId)?.publishedYear;
}
export function isPriceEditable(book: BookBokbasen, bookId: string): boolean {
    const publicationYear = getYearOfFirstPublication(book, bookId);

    return publicationYear == new Date().getFullYear().toString();
}
export function getAllPrices(relatedBooks: RelatedBook[], currentBook: BookBokbasen, unique?: boolean) {
    const existingBundlePrices = (currentBook.prices ?? []).filter((b) => b.type == BookPriceType.BUNDLE);
    const allPrices = [
        ...existingBundlePrices,
        createBookPrice(
            currentBook.price?.value,
            currentBook.price?.originalPrice ?? -1,
            currentBook.bookType,
            currentBook.bokbasenReferanse
        ),
    ];

    relatedBooks?.forEach((relatedBook) => {
        const pricesIncludesBook = allPrices.some((price) => price.bookId === relatedBook.bookId);
        if (relatedBook && !pricesIncludesBook) {
            const relatedBookPrice = createBookPrice(
                relatedBook.price,
                relatedBook.originalPrice,
                relatedBook.type,
                relatedBook.bookId
            );
            allPrices.push(relatedBookPrice);
        }
    });
    const pricesSorted = allPrices
        .filter((b) => b.bookId || b.type == BookPriceType.BUNDLE)
        .filter(
            (b) =>
                b.type == BookPriceType.BUNDLE ||
                relatedBooks == undefined ||
                relatedBooks?.some((r) => r.bookId == b.bookId)
        )
        .sort((a, b) => a.type?.localeCompare(b.type));

    if (unique) {
        return filterUniqueByKey(pricesSorted, "type");
    }
    return pricesSorted;
}
export function getBookPrices(book: Book) {
    if (book.source == "bokbasen") {
        return book.prices ? book.prices : getAllPrices(book.relatedBooks, book, true);
    }

    return book.prices;
}

export function mapToRelatedBook(book: BookBokbasen, bookId: string): RelatedBook {
    return removeNullOrUndefinedKeys({
        bookId: bookId,
        isbn: book.isbn,
        mainBook: book.mainBook,
        title: book.title,
        coverpath: book.coverPath,
        publishedYear: book.publishedYear,
        type: book.bookType,
        language: book.language,
        inventoryId: book.inventoryId,
        originalPrice: book.price?.originalPrice ?? -1,
        price: book.price?.value ?? -1,
        dateOfFirstPublication: book.publishingDetails?.dateOfFirstPublication,
    });
}

const roleDisplayValue = new Map<string, string>([
    [ContributorRoleCode[ContributorRoleCode.ByAuthor], ""],
    [ContributorRoleCode[ContributorRoleCode.ReadBy], "Oppleser"],
    [ContributorRoleCode[ContributorRoleCode.TranslatedBy], "Oversetter"],
]);

export type Author = AuthorBokbasen | AuthorPublizm;
export const AuthorLatestVersion = 1;

type AllAuthor = Author;

export class AuthorVersionMapper extends FirestoreVersionMapper<Author, AllAuthor> {
    public latestVersion = AuthorLatestVersion;
    public mapToLatest(author: AllAuthor): Author {
        if (author.version === AuthorLatestVersion) {
            return author;
        }

        switch (author.version) {
            case undefined:
                return {
                    ...author,
                    version: AuthorLatestVersion,
                };
            default:
                throw new Error(`Mapper for author version ${author["version"]} not implemented`);
        }
    }
}
export type AuthorSource = "publizm" | "bokbasen";

export enum AuthorRole {
    ByAuthor = "ByAuthor",
    With = "With",
    ScreenplayBy = "ScreenplayBy",
    LibrettoBy = "LibrettoBy",
    LyricsBy = "LyricsBy",
    ByComposer = "ByComposer",
    ByArtist = "ByArtist",
    ByPhotographer = "ByPhotographer",
    CreatedBy = "CreatedBy",
    FromAnIdeaBy = "FromAnIdeaBy",
    DesignedBy = "DesignedBy",
    IllustratedBy = "IllustratedBy",
    PhotographsBy = "PhotographsBy",
    TextBy = "TextBy",
    PrefaceBy = "PrefaceBy",
    PrologueBy = "PrologueBy",
    SummaryBy = "SummaryBy",
    SupplementBy = "SupplementBy",
    AfterwordBy = "AfterwordBy",
    NotesBy = "NotesBy",
    CommentariesBy = "CommentariesBy",
    EpilogueBy = "EpilogueBy",
    ForewordBy = "ForewordBy",
    IntroductionBy = "IntroductionBy",
    FootnotesBy = "FootnotesBy",
    MemoirBy = "MemoirBy",
    ExperimentsBy = "ExperimentsBy",
    IntroductionAndNotesBy = "IntroductionAndNotesBy",
    SoftwareWrittenBy = "SoftwareWrittenBy",
    BookAndLyricsBy = "BookAndLyricsBy",
    ContributionsBy = "ContributionsBy",
    AppendixBy = "AppendixBy",
    IndexBy = "IndexBy",
    DrawingsBy = "DrawingsBy",
    CoverDesignOrArtworkBy = "CoverDesignOrArtworkBy",
    PreliminaryWorkBy = "PreliminaryWorkBy",
    OriginalAuthor = "OriginalAuthor",
    MapsBy = "MapsBy",
    InkedOrColoredBy = "InkedOrColoredBy",
    PaperEngineeringBy = "PaperEngineeringBy",
    ContinuedBy = "ContinuedBy",
    Interviewer = "Interviewer",
    Interviewee = "Interviewee",
    ComicScriptBy = "ComicScriptBy",
    Inker = "Inker",
    Colorist = "Colorist",
    Letterer = "Letterer",
    ResearchBy = "ResearchBy",
    OtherPrimaryCreator = "OtherPrimaryCreator",
    EditedBy = "EditedBy",
    RevisedBy = "RevisedBy",
    RetoldBy = "RetoldBy",
    AbridgedBy = "AbridgedBy",
    AdaptedBy = "AdaptedBy",
    TranslatedBy = "TranslatedBy",
    AsToldBy = "AsToldBy",
    TranslatedWithCommentaryBy = "TranslatedWithCommentaryBy",
    SeriesEditedBy = "SeriesEditedBy",
    EditedAndTranslatedBy = "EditedAndTranslatedBy",
    EditorInChief = "EditorInChief",
    GuestEditor = "GuestEditor",
    VolumeEditor = "VolumeEditor",
    EditorialBoardMember = "EditorialBoardMember",
    EditorialCoordinationBy = "EditorialCoordinationBy",
    ManagingEditor = "ManagingEditor",
    FoundedBy = "FoundedBy",
    PreparedForPublicationBy = "PreparedForPublicationBy",
    AssociateEditor = "AssociateEditor",
    ConsultantEditor = "ConsultantEditor",
    GeneralEditor = "GeneralEditor",
    DramatizedBy = "DramatizedBy",
    GeneralRapporteur = "GeneralRapporteur",
    LiteraryEditor = "LiteraryEditor",
    ArrangedBy = "ArrangedBy",
    TechnicalEditor = "TechnicalEditor",
    ThesisAdvisorOrSupervisor = "ThesisAdvisorOrSupervisor",
    ThesisExaminer = "ThesisExaminer",
    ScientificEditor = "ScientificEditor",
    HistoricalAdvisor = "HistoricalAdvisor",
    OriginalEditor = "OriginalEditor",
    TranslationRevisedBy = "TranslationRevisedBy",
    OtherAdaptationBy = "OtherAdaptationBy",
    CompiledBy = "CompiledBy",
    SelectedBy = "SelectedBy",
    NonTextMaterialSelectedBy = "NonTextMaterialSelectedBy",
    CuratedBy = "CuratedBy",
    OtherCompilationBy = "OtherCompilationBy",
    Producer = "Producer",
    Director = "Director",
    Conductor = "Conductor",
    Choreographer = "Choreographer",
    OtherDirectionBy = "OtherDirectionBy",
    Actor = "Actor",
    Dancer = "Dancer",
    Narrator = "Narrator",
    Commentator = "Commentator",
    VocalSoloist = "VocalSoloist",
    InstrumentalSoloist = "InstrumentalSoloist",
    ReadBy = "ReadBy",
    PerformedByMusicalGroup = "PerformedByMusicalGroup",
    Speaker = "Speaker",
    Presenter = "Presenter",
    PerformedBy = "PerformedBy",
    FilmedPhotographedBy = "FilmedPhotographedBy",
    Editor = "Editor",
    OtherRecordingBy = "OtherRecordingBy",
    AssistedBy = "AssistedBy",
    HonoredDedicatedTo = "HonoredDedicatedTo",
    EnactingJurisdiction = "EnactingJurisdiction",
    PeerReviewed = "PeerReviewed",
    VariousRoles = "VariousRoles",
    Other = "Other",
}
export interface AuthorBokbasen extends AuthorBase {
    source: "bokbasen";
    bokbasenId: string;
    nameType?: string;
    sequenceNumber?: number;
    websites: AuthorWebsite[];
}

export interface AuthorPublizm extends AuthorBase {
    source: "publizm";
}
export interface AuthorBase extends PzFirestoreBase {
    source: AuthorSource;
    nasjonalAuthId?: string;
    isniId?: string;
    roles?: AuthorRole[];
    fullName?: string;
    dateOfBirth?: string;
    dateOfDeath?: string;
    bornInCountry?: string;
    bornInYear?: string;
    firstName: string;
    lastName: string;
    lastChanged?: Timestamp;
    contactInfo?: string;
}

export type AuthorProfile = AuthorProfileBase;
export const AuthorProfileLatestVersion = 1;

type AllAuthorProfile = AuthorProfileBase;

export class AuthorProfileVersionMapper extends FirestoreVersionMapper<AuthorProfile, AllAuthorProfile> {
    public latestVersion = AuthorProfileLatestVersion;
    public mapToLatest(authorProfile: AllAuthorProfile): AuthorProfile {
        if (authorProfile.version === AuthorProfileLatestVersion) {
            return authorProfile;
        }

        switch (authorProfile.version) {
            case undefined:
                return {
                    ...authorProfile,
                    version: AuthorProfileLatestVersion,
                };
            default:
                throw new Error(`Mapper for authorprofile version ${authorProfile["version"]} not implemented`);
        }
    }
}
export enum AuthorProfileStatus {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED",
    HIDDEN = "HIDDEN",
}
export interface AuthorProfileBase extends PzFirestoreBase {
    source: AuthorSource;
    authorId: string;
    displayName: string;
    name: string;
    lastChanged: Timestamp;
    description: string;
    descriptionOld?: string;
    profilePictureSource?: string;
    profilePicturePath?: string;
    language: Language;
    roles?: AuthorRole[];
}

export interface BookGroup extends PzFirestoreBase {
    name: string;
    description?: string;
    createdDate: Timestamp;
    lastChanged: Timestamp;
}
