type FetchMethod = "GET" | "POST" | "PUT";

function mapBody(response: Response) {
    try {
        return response.json();
    } catch {
        return response.text();
    }
}

export interface ApiResponse {
    status: number;
    data?: any;
}

function doFetch(url: string, method: FetchMethod, body?: BodyInit): Promise<ApiResponse> {
    return fetch(url, {
        method,
        body,
    })
        .then((response) => {
            if (response.ok) {
                return { status: 200, data: mapBody(response) };
            }
            return { status: response.status };
        })
        .catch((err) => {
            console.error(`Something went wrong when calling ${url} with method ${method}`, err);
            throw err;
        });
}

export const DefaultRestService = {
    get: (url: string) => doFetch(url, "GET"),
    post: (url: string, body: BodyInit) => doFetch(url, "POST", body),
    put: (url: string, body: BodyInit) => doFetch(url, "PUT", body),
};
