import { Column, Container } from "components/trunx";
import React from "react";

import DashboardHorizontalMenu from "../../../components/dashboard/Menu/DashboardHorizontalMenu";
import PageTemplate from "../../../components/pagetemplate/PageTemplate";
import { DashboardProvider } from "../../../contexts/DashboardContext";
import RoyaltyDashboard from "./RoyaltyDashboard";

export default function RoyaltyDashboardPage() {
    return (
        <PageTemplate marginLessTop>
            <DashboardProvider>
                <Container>
                    <DashboardHorizontalMenu />
                    <Column pt5>
                        <RoyaltyDashboard />
                    </Column>
                </Container>
            </DashboardProvider>
        </PageTemplate>
    );
}
