import "../forgottenpassword/useradminform.css";

import { Column, Columns } from "components/trunx";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { AppPath } from "../../../common/AppPath";
import PzButton from "../../../components/form/PzButton";
import PzFormInputField from "../../../components/form/PzFormInputField";
import { PzTitle } from "../../../components/text/PzTitle";
import { FirebaseAuthError, FirebaseAuthErrorCode, useAuth } from "../../../contexts/AuthContext";
import { AnalyticsApi } from "../../../services/firestore/queries/queriesCommon";
import SigninWithVipps from "../signup/vipps/SigninWithVipps";

const LoginType = { EMAIL: "EMAIL", GOOGLE: "GOOGLE" };

interface LoginFormValues {
    email: string;
    password: string;
}

export default function LoginForm() {
    const { register, handleSubmit, errors, setError } = useForm<LoginFormValues>();
    const { login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    async function onSubmit(data: LoginFormValues) {
        login(data.email, data.password)
            .catch((error: FirebaseAuthError) => {
                if (error.code === FirebaseAuthErrorCode.USER_NOT_FOUND) {
                    setError("email", {
                        message:
                            "Ingen bruker registrert med denne eposten. Skriv på nytt eller registrer deg ny bruker.",
                        shouldFocus: true,
                    });
                } else {
                    setError("password", {
                        message: "Feil passord. Vennligst skriv ditt passord på nytt eller endre passord.",
                        shouldFocus: true,
                    });
                }
            })
            .finally(() => {
                AnalyticsApi.logEvent("user_logged_in_with_username_password");
                setLoading(false);
            });
    }

    return (
        <Columns isAlignItemsCenter isJustifyContentCenter>
            <Column isNarrow className="useradmin-form">
                <PzTitle>Logg inn</PzTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <PzFormInputField
                        name="email"
                        label="Epost"
                        type="email"
                        autoComplete="email"
                        formError={errors.email}
                        inputRef={register({ required: "Du må oppgi brukernavn" })}
                    />
                    <PzFormInputField
                        name="password"
                        label="Passord"
                        type="password"
                        autoComplete="current-password"
                        formError={errors.password}
                        inputRef={register({ required: "Du må oppgi passord" })}
                    />
                    <Column hasTextRight isPaddingLess>
                        <Link to="/forgot-password">Glemt passord?</Link>
                    </Column>
                    <Column hasTextCentered pl0 ml0 pt0 mt0>
                        <Column
                            isFull
                            pr0
                            pl0
                            isFlex
                            isFlexWrapWrap
                            isFlexDirectionRow
                            style={{ gap: "10px" }}
                            isJustifyContentCenter
                        >
                            <PzButton
                                style={{ width: "199px", marginLeft: "-5px" }}
                                isPrimary
                                id={LoginType.EMAIL}
                                disabled={loading}
                                value="Logg in"
                                type="submit"
                            />
                            <SigninWithVipps redirectPath="LOGIN_PAGE" />
                        </Column>
                    </Column>
                </form>
                <Column hasTextCentered pl0 ml0>
                    Trenger du en konto?
                    <Column
                        isFull
                        pr0
                        pl0
                        isFlex
                        isFlexDirectionRow
                        isFlexWrapWrap
                        isJustifyContentCenter
                        style={{ gap: "10px" }}
                    >
                        <PzButton
                            id={LoginType.EMAIL}
                            style={{ width: "199px", marginLeft: "-5px" }}
                            disabled={loading}
                            onClick={() => navigate(AppPath.SIGNUP_CUSTOMER)}
                            value="Logg in"
                        >
                            Registrer deg med epost
                        </PzButton>
                        <SigninWithVipps redirectPath="LOGIN_PAGE" registrer />
                    </Column>
                </Column>
            </Column>
        </Columns>
    );
}
