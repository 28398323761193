import "tippy.js/animations/scale-subtle.css"; // optional
import "tippy.js/animations/scale.css"; // optional
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light-border.css"; // optional
import "./PzTooltip.css";

import { faInfoCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { ReactElement } from "react";

interface PzTooltipProps {
    icon?: IconDefinition;
    content: ReactElement;
    zeroPadding?: boolean;
    size?: FontAwesomeIconSizes;
    placement?: PzTooltipPlacement;
}

export enum FontAwesomeIconSizes {
    XS = "xs",
    SM = "sm",
    LG = "lg",
    TIMES_2 = "2x",
    TIMES_3 = "3x",
    TIMES_5 = "5x",
    TIMES_7 = "7x",
    TIMES_10 = "10x",
}

export enum PzTooltipPlacement {
    BOTTOM = "bottom",
    TOP = "top",
}

export default function PzTooltip({
    size,
    zeroPadding,
    content,
    placement = PzTooltipPlacement.TOP,
    icon = faInfoCircle,
}: PzTooltipProps): ReactElement {
    return (
        <Tippy
            className={zeroPadding || false ? "pz-tooltip-zero-padding" : "pz-tooltip"}
            content={content}
            maxWidth={1000}
            placement={placement}
            animation="scale-subtle"
            inertia
            interactive
            hideOnClick="toggle"
            theme="light-border"
        >
            <div className="pz-tooltip-icon">
                <FontAwesomeIcon icon={icon} size={size} />
            </div>
        </Tippy>
    );
}
