import { Book, BookAuthor } from "../types";

const bokbasenPrefix = "BB";
const publizmPrefix = "PB";
interface BookUrlProps {
    title: string;
    author: string;
}
export class UrlUtils {
    private static makeStringUrlCompatible(value: string): string {
        return value
            ?.replace(/[øØ]/g, "o")
            ?.replace(/[åÅ]/g, "aa")
            ?.replace(/[æÆ]/g, "ae")
            ?.replace(/[^a-zA-Z0-9 ]/g, "");
    }

    private static encodeUrl(...values: string[]) {
        const valuesWithoutSpecialCharacters = Array.from(values).map((v) => this.makeStringUrlCompatible(v));
        return encodeURIComponent(valuesWithoutSpecialCharacters.join("_")).replace(" ", "_").replace(/%20/g, "-");
    }

    private static decodeUrl(url: string) {
        return decodeURIComponent(url).replace(" ", "_").replace(/-/g, " ").split("_");
    }

    // BOOK URL ENCODER AND DECODER
    static encodeBookUrl(bookId: string, title: string, authorName: string, path?: string, onlyPath?: boolean): string {
        const bookUrl = this.encodeUrl(title, authorName);
        const bookIdEncoded = this.getBookIdEncoded(bookId);
        return `${onlyPath ? "" : window.location.origin}${path}/${bookUrl}_${bookIdEncoded}`;
    }

    private static getBookIdEncoded(bookId?: string): string {
        if (!bookId) return "";
        if (bookId?.startsWith("no.bokbasen")) {
            return bookId.replace("no.bokbasen.", bokbasenPrefix);
        }
        return `${publizmPrefix}${bookId}`;
    }
    private static getBookIdDecoded(bookIdFromUrl: string): string | undefined {
        const bookUrlLowercase = bookIdFromUrl.toLowerCase();
        if (bookUrlLowercase.startsWith(publizmPrefix.toLowerCase())) {
            return bookUrlLowercase.replace(publizmPrefix.toLowerCase(), "");
        } else if (bookUrlLowercase.startsWith(bokbasenPrefix.toLowerCase())) {
            return `no.bokbasen.${bookUrlLowercase.replace(bokbasenPrefix.toLowerCase(), "")}`;
        }
    }
    static getBookRefFromUrl(url?: string): string | undefined {
        if (!url) return;
        const urlSplit = url.split("_");
        if (urlSplit.length > 0) {
            const lastValue = urlSplit[urlSplit.length - 1];
            return this.getBookIdDecoded(lastValue);
        }
    }
    static encodeBookUrlForBook(book: Book, path: string, onlyPath?: boolean): string {
        return this.encodeBookUrl(book.id!, book?.title, book?.author?.name ?? "", path, onlyPath);
    }

    // AUTHOR URL ENCODER AND DECODER

    static getAuthorRefFromUrl(url?: string): string | undefined {
        if (!url) return;
        const urlSplit = url.split("_");
        if (urlSplit.length > 0) {
            const lastValue = urlSplit[urlSplit.length - 1];
            return this.getAuthorIdDecoded(lastValue);
        }
    }
    private static getAuthorIdEncoded(authorId?: string): string {
        if (!authorId) return "";
        if (authorId?.startsWith("bokbasen")) {
            return authorId.replace("bokbasen_", bokbasenPrefix);
        }
        return `${publizmPrefix}${authorId}`;
    }
    private static getAuthorIdDecoded(authorIdFromUrl: string): string | undefined {
        const urlLowercase = authorIdFromUrl.toLowerCase();
        if (urlLowercase.startsWith(publizmPrefix.toLowerCase())) {
            return urlLowercase.replace(publizmPrefix.toLowerCase(), "");
        } else if (urlLowercase.startsWith(bokbasenPrefix.toLowerCase())) {
            return `bokbasen_${urlLowercase.replace(bokbasenPrefix.toLowerCase(), "")}`;
        }
    }
    static encodeAuthorForAuthor(author: BookAuthor, path: string, onlyPath?: boolean) {
        const authorProfileName = author?.name;
        return this.encodeAuthorUrl(author?.id, authorProfileName, path, onlyPath);
    }
    static encodeAuthorUrl(authorId: string, authorProfileName: string, path: string, onlyPath?: boolean) {
        const authorUrl = this.encodeUrl(authorProfileName);
        return `${onlyPath ? "" : window.location.origin}${path}/${authorUrl}_${this.getAuthorIdEncoded(authorId)}`;
    }

    static getUrlParameterAfterPath(url: string, path: string) {
        let _url = url;
        if (path && url.includes(path)) {
            const _path = this.removeBeginningAndTrailingSlash(path);
            _url = url.substring(url.indexOf(_path) + _path.length + 1, url.length);
            _url = this.removeQueryParameters(_url);
            _url = this.removeBeginningAndTrailingSlash(_url);
        }
        return _url;
    }

    static encodeAuthorUrlLegacy(authorProfileName: string, path: string, onlyPath?: boolean) {
        const bookUrl = this.encodeUrl(authorProfileName);
        return `${onlyPath ? "" : window.location.origin}${path}/${bookUrl}`;
    }
    static decodeAuthorUrlLegacy(url: string) {
        const decodedAuthorUrl = this.decodeUrlLegacy(url);
        return decodedAuthorUrl[0];
    }

    static createUrl(path: string) {
        return `${window.location.origin}${path}`;
    }

    // --- LEGACY BOOK URL ENCODER AND DECODER
    static encodeBookUrlLegacy(title: string, authorName: string, path?: string, onlyPath?: boolean): string {
        const bookUrl = this.encodeUrl(title, authorName);
        return `${onlyPath ? "" : window.location.origin}${path}/${bookUrl}`;
    }
    static decodeBookUrlLegacy(url: string): BookUrlProps {
        const decodedSplit = this.decodeUrlLegacy(url);
        return { title: decodedSplit[0], author: decodedSplit.length > 1 ? decodedSplit[1] : "" };
    }
    static decodeBookUrlLegacy2(url: string): BookUrlProps {
        let decodedSplit = this.decodeUrlLegacy(url);
        if (decodedSplit.length == 1 && url.includes("~")) {
            decodedSplit = this.decodeUrlLegacy2(url);
        }
        return { title: decodedSplit[0], author: decodedSplit.length > 1 ? decodedSplit[1] : "" };
    }
    private static encodeUrlLegacy(...values: string[]) {
        return encodeURIComponent(values.join("~")).replace(/%2B/g, "§").replace(/-/g, "+").replace(/%20/g, "-");
    }

    private static decodeUrlLegacy(url: string) {
        return decodeURIComponent(url).replace(/-/g, " ").replace(/[+]/g, "-").replace(/[§]/g, "+").split("_");
    }

    private static decodeUrlLegacy2(url: string) {
        return decodeURIComponent(url).replace(/-/g, " ").replace(/[+]/g, "-").replace(/[§]/g, "+").split("~");
    }

    private static removeQueryParameters(path: string) {
        return path.substring(0, path.indexOf("?") > -1 ? path.indexOf("?") : path.length);
    }
    private static removeBeginningAndTrailingSlash(path: string) {
        let _path = path;
        _path = _path.substring(_path.startsWith("/") ? 1 : 0, _path.length);
        _path = _path.substring(0, _path.endsWith("/") ? _path.indexOf("/") : _path.length);
        return _path;
    }
    static isValidHttpUrl(urlString: string): boolean {
        try {
            const url = new URL(urlString);
            return url.protocol === "http:" || url.protocol === "https:";
        } catch (_) {
            return false;
        }
    }
}

function shortenString(value: string): string {
    return btoa(value);
}

function recoverShortenedString(value: string): string {
    return atob(value);
}
