import PzButton from "components/form/PzButton";
import PzModal from "components/modal/PzModal";
import { SearchField } from "components/search/SearchField";
import { Column, Container, Help, Level, Modal } from "components/trunx";
import { useState } from "react";
import { BookService } from "services/firestore/BookService";
import { Query } from "services/firestore/FirestoreService";
import { BookData } from "services/firestore/types";

import GiveBookAccessTable from "./GiveBookAccessTable";

interface GiveBookAccessButtonProps {
    userUid: string;
}

export default function GiveBookAccessButton({ userUid }: GiveBookAccessButtonProps): JSX.Element {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [giveBookAccessModalOpen, setGiveBookAccessModalOpen] = useState<boolean>(false);
    const [searchPattern, setSearchPattern] = useState<string>("");
    const [fetchedBooks, setFetchedBooks] = useState<BookData[]>();

    const onClose = () => {
        setErrorMessage(undefined);
        setGiveBookAccessModalOpen(false);
        setFetchedBooks([]);
    };
    const onOpen = () => setGiveBookAccessModalOpen(true);

    const onChange = (input: string) => setSearchPattern(input.toLowerCase().trim());

    const loadBooks = () => {
        const query: Query = {
            fieldPath: "searchField",
            opStr: "array-contains-any",
            value: searchPattern.split(" "),
        };

        new BookService()
            .getFilteredBooks([query])
            .then(setFetchedBooks)
            .catch((err) => setErrorMessage(err));
    };

    return (
        <>
            <PzModal isActive={giveBookAccessModalOpen} onClose={onClose}>
                <Modal.Card.Head>
                    <Level>
                        <Level.Left>
                            <Level.Item>
                                <Modal.Card.Title>Gi lesetilgang til bok</Modal.Card.Title>
                            </Level.Item>
                        </Level.Left>
                    </Level>
                </Modal.Card.Head>
                <Modal.Card.Body style={{ width: "850px" }}>
                    <Container isFlex isFlexDirectionColumn>
                        <Column isFlex isAlignSelfFlexEnd isFlexDirectionRow>
                            <SearchField onChange={onChange} />
                            <PzButton ml1 onClick={loadBooks}>
                                Søk
                            </PzButton>
                        </Column>
                        <GiveBookAccessTable books={fetchedBooks ?? []} userId={userUid} />
                        {errorMessage && (
                            <Column>
                                <Help isDanger>{errorMessage}</Help>
                            </Column>
                        )}
                        <Column>
                            <PzButton isNormal onClick={onClose}>
                                Lukk
                            </PzButton>
                        </Column>
                    </Container>
                </Modal.Card.Body>
            </PzModal>
            <PzButton mr2 onClick={onOpen}>
                Gi lesetilgang til bok
            </PzButton>
        </>
    );
}
