import { RenderWhen } from "components/auth/UserAuth";
import { Container, Subtitle, Textarea, Title } from "components/trunx";
import { useAuth } from "contexts/AuthContext";
import { HTMLProps, ReactElement } from "react";
import { UserRole } from "shared";

export interface AssessmentInputProps extends HTMLProps<Container> {
    title: string;
    id: string;
    description: string;
    defaultValue: string;
    readOnly?: boolean;
    subDescription?: string;
    onChangeValue: (value: string) => void;
    rows?: number;
}

export default function AssessmentInput(props: AssessmentInputProps): ReactElement {
    const {
        className = "mb-6",
        title,
        description,
        subDescription,
        id,
        defaultValue,
        onChangeValue,
        readOnly = false,
        rows = 5,
    } = props;

    const { userInfo, activeRole } = useAuth();

    if (activeRole == UserRole.AUTHOR && !defaultValue) {
        return <></>;
    }

    return (
        <Container className={className} pb4>
            <Title pb2 is4 style={{ fontWeight: 600 }}>
                {title}
            </Title>
            <RenderWhen userIsNot={UserRole.AUTHOR}>
                <Subtitle is5>{description}</Subtitle>
                {subDescription && <Subtitle is5>{subDescription}</Subtitle>}
            </RenderWhen>
            <Textarea
                readOnly={readOnly}
                name={id}
                rows={rows}
                defaultValue={defaultValue.replaceAll("\\n", "\n")}
                spellCheck={false}
                onBlur={(e) => {
                    onChangeValue(e.target.value.replaceAll("\n", "\\n"));
                }}
            />
        </Container>
    );
}
