import React, { PropsWithChildren, ReactElement, useContext } from "react";
import { AuthorProfile, Book } from "shared";

interface AuthorProfileDetailsContextProps {
    authorProfileId: string;
    authorProfile: AuthorProfile;
    booksByAuthorProfile: Book[];
}

const AuthorProfileDetailsContext = React.createContext<AuthorProfileDetailsContextProps>(
    {} as AuthorProfileDetailsContextProps
);

export function useAuthorProfileDetails(): AuthorProfileDetailsContextProps {
    return useContext(AuthorProfileDetailsContext);
}

interface AuthorProfileDetailsProviderProps {
    authorProfile: AuthorProfile;
    authorProfileId: string;
    booksByAuthorProfile: Book[];
}
export default function AuthorProfileDetailsProvider(
    props: PropsWithChildren<AuthorProfileDetailsProviderProps>
): ReactElement {
    const { authorProfile, authorProfileId, booksByAuthorProfile, children } = props;

    const values: AuthorProfileDetailsContextProps = {
        authorProfileId,
        authorProfile,
        booksByAuthorProfile,
    };

    return <AuthorProfileDetailsContext.Provider value={values}>{children}</AuthorProfileDetailsContext.Provider>;
}
