import "./PzDataList.css";

import { PropsWithChildren, ReactElement } from "react";
interface PzDataListProps {
    columns?: number;
    rows?: number;
}
export default function PzDataList(props: PropsWithChildren<PzDataListProps>): ReactElement {
    const { children } = props;
    return (
        <ul
            className="pz-data-list"
            style={{
                gridTemplateColumns: `repeat(${props.columns ?? 1}, 1fr)`,
                gridTemplateRows: `repeat(${props.rows ?? 10}, 1fr)`,
            }}
        >
            {children}
        </ul>
    );
}
