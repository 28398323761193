import "./CustomerPage.css";

import { Container, Level, Modal } from "components/trunx";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BookType } from "shared";

import { AppPath } from "../../common/AppPath";
import PzMenuTabs from "../../components/pagetemplate/menu/PzMenuTabs";
import PageTemplate from "../../components/pagetemplate/PageTemplate";
import { useAuth } from "../../contexts/AuthContext";
import useFeatureToggle from "../../services/firestore/hooks/useFeatureToggle";
import { AnalyticsApi } from "../../services/firestore/queries/queriesCommon";
import BookcasePage from "./bookcase/BookcasePage";
import AuthorProfileCataloguePage from "./catalogue/catalogueauthorprofile/AuthorProfileCataloguePage";
import CataloguePage from "./catalogue/CataloguePage";
import PzButton from "../../components/form/PzButton";
import PzModal from "../../components/modal/PzModal";
import PzIconButton from "../../components/form/PzIconButton";
import { faBook } from "@fortawesome/free-solid-svg-icons";

export interface CatalogueOption {
    name: string;
    content: ReactNode;
    requiresLogin?: boolean;
}

enum CustomerPageTabOptions {
    MY_BOOKS = "Mine bøker",
    ALL_BOOKS = "Alle bøker",
    PODCASTS = "Podcaster",
    AUTHORS = "Forfattere",
}

export default function CustomerPage() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { tab } = useParams<{ tab: string }>();

    const VERTICAL_BREAKPOINT = 500;
    const isLoggedIn = currentUser != null;
    const options = getOptions();
    const viewPodcastTab = useFeatureToggle().viewPodcastTab();
    const renderOptions = options
        .filter((o) => (isLoggedIn && o.requiresLogin == true) || o.requiresLogin !== true)
        .filter(
            (o) =>
                (viewPodcastTab && o.name == CustomerPageTabOptions.PODCASTS) ||
                o.name !== CustomerPageTabOptions.PODCASTS
        );
    const [selectedOption, setSelectedOption] = useState<string>(getDefaultOption());

    function getDefaultOption() {
        const allbooks = renderOptions.find((o) => o.name === CustomerPageTabOptions.ALL_BOOKS)?.name;
        return allbooks ?? renderOptions[0].name;
    }

    function onOptionSelected(name: string) {
        setSelectedOption(name);
        AnalyticsApi.logEvent("catalogue_tab_selected", { tab: name });
        switch (name) {
            case CustomerPageTabOptions.MY_BOOKS:
                navigate(`${AppPath.CATALOGUE}/my-books`);
                break;
            case CustomerPageTabOptions.ALL_BOOKS:
                navigate(`${AppPath.CATALOGUE}/all-books`);
                break;
            case CustomerPageTabOptions.PODCASTS:
                navigate(`${AppPath.CATALOGUE}/podcasts`);
                break;
            case CustomerPageTabOptions.AUTHORS:
                navigate(`${AppPath.CATALOGUE}/authors`);
                break;
        }
    }
    const renderContent = renderOptions.find((o) => o.name === selectedOption);

    useEffect(() => {
        if (currentUser && tab === "my-books") {
            setSelectedOption(CustomerPageTabOptions.MY_BOOKS);
        }
        if (tab === "all-books") {
            setSelectedOption(CustomerPageTabOptions.ALL_BOOKS);
        }
        if (tab === "podcasts") {
            setSelectedOption(CustomerPageTabOptions.PODCASTS);
        }
        if (tab === "authors") {
            setSelectedOption(CustomerPageTabOptions.AUTHORS);
        }
    }, []);

    return (
        <PageTemplate>
            <div style={{ width: "100%", display: "flex", margin: "0 auto", justifyContent: "center", marginTop: "-10px", marginBottom: "20px" }}>
                <ProduktvelgerButton />
            </div>
            <Container>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <PzMenuTabs
                        options={renderOptions.map((o) => o.name)}
                        selectedOption={selectedOption}
                        onOptionSelected={onOptionSelected}
                        vertical={window.innerWidth <= VERTICAL_BREAKPOINT ? true : false}
                    />
                </div>
                {renderContent?.content}
            </Container>
        </PageTemplate>
    );
}

function ProduktvelgerButton() {
    const [modalOpen, setModalOpen] = useState(false);

    const showModal = () => setModalOpen(true)
    const closeModal = () => setModalOpen(false)

    return <>
        <PzIconButton isPrimary iconPlacement="left" icon={faBook} onClick={showModal}>Finn din ultimate vårbok!</PzIconButton>
        <PzModal isActive={modalOpen} onClose={closeModal}>

            <Modal.Card.Head>
                <Level>
                    <Level.Left>
                        <Level.Item>
                            <Modal.Card.Title>Vi hjelper deg</Modal.Card.Title>
                        </Level.Item>
                    </Level.Left>
                </Level>
            </Modal.Card.Head>
            <Modal.Card.Body pl0 pr0 mr0 ml0 style={{ width: "400px", maxWidth: "85vw", height: "800px", maxHeight: "60vh" }} >
                <div style={{ height: "90%" }}>
                    <iframe width="100%" id="smoc_iframe" height="100%" name="code" frameBorder="no" allowTransparency src="https://go.smoc.ai/publizm/operator_382_channel_y0yugu3u_copy_1/operator_382_channel_y0yugu3u_bot_copy_1/new?iframe_mode=true"
                        seamless={true} style={{ border: "none", "overflow": "hidden" }} loading="lazy"></iframe>

                </div>
                <PzButton isSecondary onClick={closeModal}>{"Lukk"}</PzButton>
            </Modal.Card.Body>
        </PzModal>
    </>

}
function getOptions() {
    return [
        {
            name: CustomerPageTabOptions.MY_BOOKS,
            content: <BookcasePage />,
            requiresLogin: true,
        },
        {
            name: CustomerPageTabOptions.ALL_BOOKS,
            content: <CataloguePage />,
        },
        {
            name: CustomerPageTabOptions.PODCASTS,
            content: <CataloguePage type={BookType.PODCAST} />,
        },
        {
            name: CustomerPageTabOptions.AUTHORS,
            content: <AuthorProfileCataloguePage />,
        },
    ] as CatalogueOption[];
}
