import { User } from "firebase/auth";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserRole } from "shared";

import { AppPath } from "../../../common/AppPath";
import { FirebaseUser, useAuth } from "../../../contexts/AuthContext";
const CUSTOMER_HOME_PAGE_URL = AppPath.CATALOGUE;
const USER_HOME_PAGE_URL = AppPath.DASHBOARD;
const ADMIN_HOME_PAGE_URL = AppPath.ROOT;

const REDIRECT_TO_LOCAL_STORAGE_KEY = "redirect_to";
export default function RedirectAfterLogin() {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser } = useAuth();

    function saveLocationToLocalStorage() {
        if (location.state) {
            localStorage.setItem(REDIRECT_TO_LOCAL_STORAGE_KEY, JSON.stringify(location.state));
        }
    }

    function resetLocationFromLocalStorage() {
        localStorage.removeItem(REDIRECT_TO_LOCAL_STORAGE_KEY);
    }
    function getLocationFromLocalStorage() {
        const location = localStorage.getItem(REDIRECT_TO_LOCAL_STORAGE_KEY);
        return location ? JSON.parse(location) : null;
    }

    useEffect(() => {
        if (currentUser === null) {
            saveLocationToLocalStorage();
            return;
        }
        const locationState = getLocationFromLocalStorage() ?? location.state;
        resetLocationFromLocalStorage();
        if (locationState) {
            console.log(
                JSON.stringify(locationState),
                `${locationState.from.pathname}${locationState.from.search ?? ""}`,
                window.location.pathname
            );
            redirectToOriginalPage(
                currentUser,
                `${locationState.from.pathname}${locationState.from.search ?? ""}`,
                locationState.productPurchaseMetadata
            );
        } else {
            redirectToUserHomepage(currentUser);
        }
    }, [currentUser]);

    function redirectToUserHomepage(user: FirebaseUser | null) {
        if (user === null) {
            return;
        }

        user.getIdTokenResult().then(redirectUserBasedOnRole);
    }

    function redirectToOriginalPage(user: User | null, pathname: string, pageState?: any) {
        if (user === null) {
            return;
        }

        if (window.location.pathname === pathname) {
            return;
        }
        navigate(pathname, {
            state: pageState,
            replace: true,
        });
    }

    function redirectUserBasedOnRole(token: any) {
        const claims = token.claims;
        const roles = claims["roles"] ?? [];
        if (roles.includes(UserRole.ADMIN)) {
            navigate(ADMIN_HOME_PAGE_URL);
            return;
        } else if (roles.includes(UserRole.CUSTOMER)) {
            navigate(CUSTOMER_HOME_PAGE_URL);
            return;
        }
        navigate(USER_HOME_PAGE_URL);
    }

    return <></>;
}
