import React, { ReactElement, useEffect, useRef, useState } from "react";

import { FirebaseUploadFile } from "../../services/storage/types";
import { DropZone } from "./DropZone";
import SingleFileUpload from "./SingleFileUpload";

const removeFileWithSameTitle = (name: string) => (file: FirebaseUploadFile) => file.name !== name;

type UploadType = "DROP_ZONE" | "SINGLE_FILE";
interface UploadFileProps {
    onFileListUpdated: (files: FirebaseUploadFile[]) => void;
    maxNumberOfFiles?: number;
    initialFileList?: FirebaseUploadFile[];
    type?: UploadType;
}

export default function UploadFile({
    initialFileList = [],
    onFileListUpdated,
    maxNumberOfFiles = 1,
    type = "DROP_ZONE",
}: UploadFileProps): ReactElement {
    const [files, setFiles] = useState<FirebaseUploadFile[]>(initialFileList);
    const didMountRef = useRef<boolean>(false);

    useEffect(() => {
        if (didMountRef.current) {
            onFileListUpdated(files);
        }
        didMountRef.current = true;
    }, [files]);

    function onFileAdded(newFile: FirebaseUploadFile) {
        setFiles((prevFilesState) => {
            const filteredPrevFiles = prevFilesState
                .slice(0, maxNumberOfFiles - 1)
                .filter(removeFileWithSameTitle(newFile.name));
            return [...filteredPrevFiles, newFile];
        });
    }

    function onDeleteFile(file: FirebaseUploadFile) {
        setFiles((prevFilesState) => prevFilesState.filter(removeFileWithSameTitle(file.name)));
    }

    return (
        <div style={{ display: "flex", flexDirection: type === "DROP_ZONE" ? "column" : "row" }}>
            {type === "DROP_ZONE" ? (
                <DropZone onFileAdded={onFileAdded} files={files} onDeleteFile={onDeleteFile} />
            ) : (
                <SingleFileUpload onFileAdded={onFileAdded} files={files} onDeleteFile={onDeleteFile} />
            )}
        </div>
    );
}
