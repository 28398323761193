import "./polyfill";
import "./styles/css/publizm.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { SentryInit } from "services/logging/SentryInit";

import App from "./components/App";
import LoadingIndicator from "./components/LoadingIndicator";
SentryInit();

// const updateSW = registerSW({
//     onNeedRefresh() {
//         if (confirm("New content available. Reload?")) {
//             updateSW(true);
//         }
//     },
// });

const root = createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <React.Suspense fallback={<LoadingIndicator />}>
            <App />
        </React.Suspense>
    </React.StrictMode>
);
