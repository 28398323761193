import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from "react";

import useUrlQueries from "../../../common/hooks/useQuery";
import { getQueryUrl, updateWindowQueryParameterByQueries } from "../../../components/utils/UrlUtils";

export enum CatalogueFilterKeys {
    PAGE_KEY = "page",
    SEARCH_PATTERN_KEY = "search",
    FILTER_KEY = "filter",
    SUBJECT_KEY = "subject",
    GROUP = "group",
    COLLECTION = "collection",
}

export interface ICatalogueFilter {
    key: CatalogueFilterKeys;
    value: string;
}

interface CatalogueFilterContextProps {
    currentPage: number;
    searchPattern: string;
    activeFilter: string[];
    hasActiveFilter: () => boolean;
    getPrevLink: () => string;
    getNextLink: () => string;
    getFilterValueByKey: (key: CatalogueFilterKeys) => string | undefined;
    removeFilterValueByKey: (key: CatalogueFilterKeys) => void;
    setActiveFilter: Dispatch<SetStateAction<string[]>>;
    setSearchPattern: Dispatch<SetStateAction<string>>;
    setCurrentPage: Dispatch<SetStateAction<number>>;
}
const CatalogueFilterContext = React.createContext<CatalogueFilterContextProps>({} as CatalogueFilterContextProps);

export function useCatalougeFilter(): CatalogueFilterContextProps {
    return useContext(CatalogueFilterContext);
}

export default function CatalougeFilterProvider({ children }: PropsWithChildren<unknown>) {
    const query = useUrlQueries();
    const [activeFilter, setActiveFilter] = useState<string[]>(
        query.get(CatalogueFilterKeys.FILTER_KEY)?.split(",") ?? []
    );
    const [searchPattern, setSearchPattern] = useState<string>(query.get(CatalogueFilterKeys.SEARCH_PATTERN_KEY) ?? "");
    const [currentPage, setCurrentPage] = useState(
        query.get(CatalogueFilterKeys.PAGE_KEY) ? parseInt(query.get(CatalogueFilterKeys.PAGE_KEY)!, 0) : 0
    );

    const [filters, setFilters] = useState<ICatalogueFilter[]>([]);

    function createCatalogueFilter(key: string, value?: string | null) {
        if (value) {
            return {
                key,
                value,
            };
        }
    }
    useEffect(() => {
        setFilters(
            [
                createCatalogueFilter(CatalogueFilterKeys.SUBJECT_KEY, query.get(CatalogueFilterKeys.SUBJECT_KEY)),
                createCatalogueFilter(CatalogueFilterKeys.GROUP, query.get(CatalogueFilterKeys.GROUP)),
                createCatalogueFilter(CatalogueFilterKeys.COLLECTION, query.get(CatalogueFilterKeys.COLLECTION)),
            ].filter((a) => a) as ICatalogueFilter[]
        );
    }, []);
    useEffect(() => {
        updateWindowQueryParameterByQueries(
            {
                [CatalogueFilterKeys.PAGE_KEY]: currentPage.toFixed(0),
                [CatalogueFilterKeys.SEARCH_PATTERN_KEY]: searchPattern,
                [CatalogueFilterKeys.FILTER_KEY]: activeFilter.join(","),
            },
            true
        );
    }, []);

    useEffect(() => {
        updateWindowQueryParameterByQueries({
            [CatalogueFilterKeys.PAGE_KEY]: currentPage.toFixed(0),
            [CatalogueFilterKeys.SEARCH_PATTERN_KEY]: searchPattern,
            [CatalogueFilterKeys.FILTER_KEY]: activeFilter.join(","),
        });
    }, [currentPage, searchPattern, activeFilter]);

    function getNextLink() {
        return getQueryUrl({
            [CatalogueFilterKeys.PAGE_KEY]: currentPage.toFixed(0),
            [CatalogueFilterKeys.SEARCH_PATTERN_KEY]: searchPattern,
            [CatalogueFilterKeys.FILTER_KEY]: activeFilter.join(","),
        });
    }

    function getPrevLink() {
        return getQueryUrl({
            [CatalogueFilterKeys.PAGE_KEY]: currentPage.toFixed(0),
            [CatalogueFilterKeys.SEARCH_PATTERN_KEY]: searchPattern,
            [CatalogueFilterKeys.FILTER_KEY]: activeFilter.join(","),
        });
    }
    function getFilterValueByKey(key: CatalogueFilterKeys) {
        return filters.find((v) => v.key == key)?.value;
    }

    function hasActiveFilter() {
        return filters.length > 0;
    }

    function removeFilterValueByKey(key: CatalogueFilterKeys) {
        setFilters((tempFilters) => tempFilters.filter((v) => v.key != key));
        updateWindowQueryParameterByQueries({ [key]: null });
    }
    return (
        <CatalogueFilterContext.Provider
            value={{
                getFilterValueByKey,
                removeFilterValueByKey,
                searchPattern,
                activeFilter,
                setSearchPattern,
                setActiveFilter,
                hasActiveFilter,
                setCurrentPage,
                getNextLink,
                getPrevLink,
                currentPage,
            }}
        >
            {children}
        </CatalogueFilterContext.Provider>
    );
}
