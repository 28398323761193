import React from "react";

import LoadingIndicator from "../../../../../components/LoadingIndicator";
import useBookApi from "../../../../../services/firestore/hooks/useBookApi";

interface IBookSaleNumbersProps {
    bookId: string;
}

export default function BookEarningNumbers(props: IBookSaleNumbersProps) {
    return (
        <React.Suspense fallback={<LoadingIndicator />}>
            <BookEarningsNumbersContent {...props} />
        </React.Suspense>
    );
}

function BookEarningsNumbersContent({ bookId }: IBookSaleNumbersProps) {
    const { data: bookEconomics } = useBookApi().bookEconomics(bookId);

    return <div>{bookEconomics?.totalEarnings ?? 0},-</div>;
}
