import { ReactElement, useEffect, useState } from "react";
import { UserRole, userRoleDisplayValue } from "shared";

import { UserAdminDashboardMenu } from "../../../../components/dashboard/Menu/DashboardUserMenu";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { filterBySearchValue } from "../../../../components/utils/SearchUtils";
import { useAuth } from "../../../../contexts/AuthContext";
import { useDashboard } from "../../../../contexts/DashboardContext";
import { AgreementHelpers, AgreementRoleMap, AgreementService } from "../../../../services/firestore/AgreementService";
import { AgreementData, UserInfoData } from "../../../../services/firestore/types";
import { UserService } from "../../../../services/firestore/UserService";
import UserAdminDashboardTable from "./UserAdminDashboardTable";

export default function UserAdminDashboard(): ReactElement {
    const [userInfoDataList, setUserInfoDataList] = useState<UserInfoData[]>([]);
    const [agreementRoleMap, setAgreementRoleMap] = useState<AgreementRoleMap>(new Map());
    const [allAgreements, setAllAgreements] = useState<AgreementData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { activeRole } = useAuth();
    const { selectedMenu, searchPattern, updateSearchPattern } = useDashboard();

    useEffect(() => {
        loadInitialData();
    }, []);

    function filterBySearch(data: UserInfoData) {
        const userInfo = data.userInfo;
        const searchData =
            userInfo.name + userRoleDisplayValue(data.activeRole) + userInfo.email + userInfo.telephoneNumber;

        return filterBySearchValue(searchPattern, searchData);
    }

    async function loadInitialData() {
        await loadUserData();
        await new AgreementService()
            .getAllAgreements()
            .then((allAgreements) => {
                setAllAgreements(allAgreements);
                return allAgreements;
            })
            .then(AgreementHelpers.getAllNewestAgreements)
            .then(setAgreementRoleMap);
        setLoading(false);
    }

    function loadUserData() {
        return new UserService().getAllUsers().then(setUserInfoDataList);
    }

    function getUsersByRoles(roles: UserRole[] = []) {
        return userInfoDataList
            .filter(filterBySearch)
            .filter((user) => (roles.length === 0 ? true : roles.some((role) => user.roles?.includes(role))));
    }

    function renderContent() {
        if (loading) {
            return <LoadingIndicator />;
        }

        const viewedUserInfoDataList = getUsersByRoles();

        function renderTable(userInfoDataList: UserInfoData[]) {
            return (
                <UserAdminDashboardTable
                    userInfoDataList={userInfoDataList}
                    agreementRoleMap={agreementRoleMap}
                    reloadUsers={loadUserData}
                    allAgreements={allAgreements}
                />
            );
        }

        switch (selectedMenu) {
            case UserAdminDashboardMenu.ALL:
                return renderTable(viewedUserInfoDataList);
            case UserAdminDashboardMenu.AUTHORS:
                return renderTable(getUsersByRoles([UserRole.AUTHOR]));
            case UserAdminDashboardMenu.EDITORS:
                return renderTable(getUsersByRoles([UserRole.EDITOR]));
            case UserAdminDashboardMenu.ADMINS: {
                return renderTable(getUsersByRoles([UserRole.ADMIN]));
            }
            default:
                return renderTable(viewedUserInfoDataList);
        }
    }

    if (activeRole !== UserRole.ADMIN) {
        return <div>Ingen tilgang</div>;
    }

    return renderContent();
}
