import { Column } from "components/trunx";
import React, { useEffect, useMemo } from "react";
import { ReactElement } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import PzSelect2 from "../../../../../components/PzSelect2";
import { LicenseeData } from "../../../../../services/firestore/types";
import { useBookAdminContext } from "./BookAdminContext";
import { BookAdminFormValues } from "./BookAdminTypes";
export interface LicenseeOption {
    label: string;
    value: LicenseeData;
}

interface LicenseeSelectorProps {
    name: string;
    index: number;
    noDuplicates?: boolean;
}
export default function LicenseeSelector({ name, index, noDuplicates }: LicenseeSelectorProps): ReactElement {
    const { register, setValue } = useFormContext<BookAdminFormValues>();
    const licenseeId = useWatch({ name: `${name}[${index}].licenseeId` });
    const selectedLicensees = useWatch({ name: `${name}` });
    const { licensees } = useBookAdminContext();
    const licenseeOptions: LicenseeOption[] = useMemo(
        () =>
            licensees
                .filter((l) => l.documentId !== "publizm")
                .filter(
                    (l) =>
                        noDuplicates != true ||
                        // @ts-ignore
                        !selectedLicensees?.some((s) => s.licenseeId === l.documentId && s.licenseeId !== licenseeId)
                )
                .map((licensee) => ({
                    label: licensee.licensee.name,
                    value: licensee,
                })),
        [licensees, selectedLicensees]
    );
    const initialValue = licenseeOptions.find((licensee) => licensee.value.documentId === licenseeId) ?? null;
    useEffect(() => {
        register(`${name}[${index}].licenseeId`);
        register(`${name}[${index}].name`);
        setValue(`${name}[${index}].licenseeId`, licenseeId);
        setValue(`${name}[${index}].name`, initialValue?.value.licensee.name);
    }, []);

    function onChanged(selected: LicenseeOption | null) {
        if (selected == null) {
            return;
        }
        setValue(`${name}[${index}].licenseeId`, selected.value.documentId);
        setValue(`${name}[${index}].name`, selected.value.licensee.name);
    }
    return (
        <Column is8 pt0 mt0>
            <PzSelect2
                title="Rettighetshaver"
                onChanged={onChanged}
                options={licenseeOptions}
                selectedOption={initialValue}
                searchable
            ></PzSelect2>
        </Column>
    );
}
