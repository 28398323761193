import { PropsWithChildren } from "react";
import { UserRole } from "shared";

import { useAuth } from "../../contexts/AuthContext";

export const isAuthenticated = () => !!useAuth().currentUser;
export const hasRole = (role: UserRole) => isAuthenticated() && useAuth()?.activeRole === role;

export enum AuthStatus {
    "AUTHENTICATED",
    "NOAUTH",
}

export type AuthPredicate = UserRole | AuthStatus;

const AuthPredicateMap = new Map<AuthPredicate, () => boolean>([
    [AuthStatus.NOAUTH, () => !isAuthenticated()],
    [AuthStatus.AUTHENTICATED, () => isAuthenticated()],
    [UserRole.AUTHOR, () => hasRole(UserRole.AUTHOR)],
    [UserRole.EDITOR, () => hasRole(UserRole.EDITOR)],
    [UserRole.ADMIN, () => hasRole(UserRole.ADMIN)],
]);

export interface AuthProps {
    userIs?: AuthPredicate;
    userIsNot?: AuthPredicate;
}

export function RenderWhen(props: PropsWithChildren<AuthProps>) {
    const isPredicate = AuthPredicateMap.get(props.userIs as AuthPredicate) ?? (() => false);
    const isNotPredicate = AuthPredicateMap.get(props.userIsNot as AuthPredicate) ?? (() => true);
    if (isPredicate() || !isNotPredicate()) {
        return <>{props.children}</>;
    } else {
        return <></>;
    }
}
