import PageTemplate from "../../../../components/pagetemplate/PageTemplate";
import PublizmLogo from "../../../../components/PublizmLogo";
import { PzTitle } from "../../../../components/text/PzTitle";
import { Column, Columns, Container } from "../../../../components/trunx";
import SigninWithVipps from "./SigninWithVipps";

export default function SigninWithVippsPage() {
    return (
        <PageTemplate>
            <Container m0 p0>
                <Columns className={"is-vcentered align-content-center signup-form"}>
                    <Column isFlex isJustifyContentCenter className={"is-hidden-tablet-only is-hidden-mobile"}>
                        <PublizmLogo isLink={false} width={125} height={169} />
                    </Column>
                    <Column id={"signup"} isFlexGrow1 m0 p2>
                        <Column mb0 pb0 pl0 ml0>
                            <PzTitle pl0 ml0>
                                {"Registrering"}
                            </PzTitle>
                        </Column>
                        <SigninWithVipps redirectPath="LOGIN_PAGE" />
                    </Column>
                </Columns>
            </Container>
        </PageTemplate>
    );
}
