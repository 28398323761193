import "./TagFileButton.css";

import { Button } from "components/trunx";

interface TagFileButtonProps {
    onClick: () => void;
    tagName: string;
    selected: boolean;
    width?: string;
}
export default function TagFileButton(props: TagFileButtonProps) {
    return (
        <Button
            onClick={(e) => {
                e.preventDefault();
                props.onClick();
            }}
            className={`${props.selected ? "tag-selected" : ""} tag-file-button text-sm`}
            isFocused={false}
            style={props.width ? { width: props.width } : {}}
        >
            {props.tagName}
        </Button>
    );
}
