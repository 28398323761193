import { Timestamp } from "firebase/firestore";
import React from "react";
import { useForm } from "react-hook-form";
import { BookGroup, removeNullOrUndefinedKeys } from "shared";

import PzButton from "../../../../../../components/form/PzButton";
import PzFormInputField from "../../../../../../components/form/PzFormInputField";
import PzTextArea from "../../../../../../components/form/PzTextArea";
import PzModal from "../../../../../../components/modal/PzModal";
import { PzTitle } from "../../../../../../components/text/PzTitle";
import { Content, Modal } from "../../../../../../components/trunx";
import { BookGroupMutations } from "../../../../../../services/firestore/queries/book/bookMutations";

interface IBookGroupModalProps {
    initialData?: BookGroup;
    id?: string;
    open: boolean;
    onClose: () => void;
}

interface BookGroupFormProps {
    name: string;
    description?: string;
}
export default function BookGroupModal({ open, onClose, initialData, id }: IBookGroupModalProps) {
    const updateFn = BookGroupMutations.createOrUpdatBookGroup();
    const isUpdateOfGroup = id != undefined;
    const methods = useForm<BookGroupFormProps>({
        defaultValues: {
            name: initialData?.name,
            description: initialData?.description,
        },
    });
    const { register, errors, handleSubmit } = methods;
    function saveChanges(data: BookGroupFormProps) {
        if (id == null) {
            const bookGroupData: BookGroup = {
                ...data,
                createdDate: Timestamp.now(),
                lastChanged: Timestamp.now(),
            };
            updateFn.mutate({ data: bookGroupData });
        } else {
            updateFn.mutate({
                data: removeNullOrUndefinedKeys({
                    description: data.description,
                    lastChanged: Timestamp.now(),
                }),
                id,
            });
        }
        onClose();
    }
    return (
        <PzModal contentStyle={{ maxHeight: "50rem" }} isActive={open} onClose={onClose}>
            <React.Suspense fallback="">
                <form onSubmit={handleSubmit(saveChanges)}>
                    <Modal.Card.Body style={{ minWidth: "30rem", height: "100%", overflow: "hidden" }}>
                        <PzTitle is4>{id != undefined ? "Oppdater gruppe" : "Opprett ny gruppe"}</PzTitle>
                        <Content style={{ overflowY: "auto", maxHeight: "30rem", height: "max-content" }}>
                            <PzFormInputField
                                disabled={isUpdateOfGroup}
                                inputRef={register()}
                                name="name"
                                label="Gruppenavn"
                            />
                            <PzTextArea
                                maxLength={100}
                                textAreaRef={register()}
                                name="description"
                                label="Beskrivelse"
                            />
                        </Content>
                        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                            <PzButton isPrimary>Lagre</PzButton>
                            <PzButton isSecondary onClick={onClose} type="button" value="Avbryt">
                                Avbryt
                            </PzButton>
                        </div>
                    </Modal.Card.Body>
                </form>
            </React.Suspense>
        </PzModal>
    );
}
