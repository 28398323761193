export interface PzFirestoreBase {
    id?: string;
    path?: string;
    version?: number;
}

export type DataSource = "publizm" | "bokbasen";

export abstract class FirestoreVersionMapper<LatestType extends PzFirestoreBase, AllTypes extends PzFirestoreBase> {
    public abstract latestVersion: number;
    public abstract mapToLatest(object: AllTypes): LatestType;
    public addVersion(object: Partial<LatestType>): Partial<LatestType> {
        return {
            ...object,
            version: this.latestVersion,
        };
    }
}
