import "./PzSelectDropdown.css";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control, Label, Select } from "components/trunx";
import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";

import { SelectProps } from "./trunx/Select";

type OptionValue = any;

export interface OptionProps<T = any> {
    value: T;
    label: string;
    style?: CSSProperties;
    isDefault?: boolean;
    icon?: {
        icon: IconDefinition;
        color?: string;
    };
}

interface PzSelectDropdownProps extends SelectProps {
    title?: string;
    isSmall?: boolean;
    name?: string;
    options: OptionProps[];
    defaultOption?: OptionProps | null;
    onChanged?: (value: OptionValue) => void;
    selectRef?: (ref: HTMLSelectElement) => void;
}

export default function PzSelectDropdown(props: PzSelectDropdownProps): ReactElement {
    const { isSmall, title, name, options, onChanged, selectRef, ...selectProps } = props;
    const [selectedOption, setSelectedOption] = useState<OptionProps>(props.defaultOption ?? props.options[0]);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.selectRef && ref.current) {
            const selectElement = ref.current.querySelector("select") as HTMLSelectElement;
            props.selectRef(selectElement);
        }
    }, []);

    useEffect(() => {
        props.defaultOption && setSelectedOption(props.defaultOption);
    }, [props.defaultOption]);
    function renderTitle() {
        if (props.title) {
            return <Label isSize7={isSmall}>{props.title}</Label>;
        }
        return null;
    }

    function onSelect(value: string) {
        props.onChanged?.(value);
    }

    function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = event.target?.value;
        const updatedSelectedOption = getOptionByValue(value);
        setSelectedOption(updatedSelectedOption);
        onSelect(event.target?.value);
    }

    function getOptionByValue(value: OptionValue) {
        return props.options.find((option) => option.value == value)!;
    }

    const hasIcon: boolean = selectedOption !== undefined && selectedOption.icon !== undefined;
    return (
        <Control hasIconsLeft={hasIcon}>
            {renderTitle()}
            <div ref={ref}>
                <Select
                    isSmall={isSmall}
                    {...selectProps}
                    name={props.name}
                    onChange={handleChange}
                    className="pz-select"
                >
                    {props.options.map((option) => (
                        <PzOption
                            key={option.label}
                            option={option}
                            isSelected={selectedOption?.value == option.value}
                        />
                    ))}
                </Select>
            </div>
            {hasIcon && (
                <div className="icon is-left is-small">
                    <FontAwesomeIcon icon={selectedOption.icon!.icon} color={selectedOption.icon!.color} />
                </div>
            )}
        </Control>
    );
}

interface PzOptionProps {
    option: OptionProps;
    isSelected?: boolean;
}
function PzOption(props: PzOptionProps) {
    // console.log(props);
    return (
        <option
            style={props.option.style}
            value={props.option.value}
            selected={props.option.isDefault || props.isSelected}
        >
            {props.option.label}
        </option>
    );
}
