import { RenderWhen } from "components/auth/UserAuth";
import RenderLoadingIndicator from "components/RenderLoadingIndicator";
import { Column, Columns, Help } from "components/trunx";
import { useAssessment } from "contexts/AssessmentContext";
import { useAuth } from "contexts/AuthContext";
import { ReactElement, useEffect, useState } from "react";
import { UserRole, WrittenWorkState } from "shared";

import AssessmentButtonsPanel from "./AssessmentButtonsPanel";
import AssessmentEditor from "./AssessmentEditor";
import AssessmentHeader from "./AssessmentHeader";
import AssessmentJudgement from "./AssessmentJudgement";
import AssessmentMessageFormat from "./AssessmentMessageFormat";
import WrittenWorkInfo from "./WrittenWorkInfo";

export default function AssessmentContent(): ReactElement | null {
    const { uploadState, error, errorMsg, writtenWork, assessment } = useAssessment();

    const [readOnly, setReadOnly] = useState<boolean>(false);
    const { activeRole } = useAuth();

    useEffect(() => {
        const isAuthor = activeRole === UserRole.AUTHOR;
        const isEditorAndReadOnly =
            activeRole === UserRole.EDITOR &&
            writtenWork?.state &&
            ![WrittenWorkState.UNDER_ASSESSMENT, WrittenWorkState.ASSIGNED].includes(writtenWork?.state);

        const isAdminAndReadOnly =
            activeRole === UserRole.ADMIN &&
            writtenWork?.state &&
            [WrittenWorkState.ACCEPTED, WrittenWorkState.REFUSED].includes(writtenWork?.state);

        const readOnly = isAuthor || isEditorAndReadOnly || isAdminAndReadOnly;

        if (readOnly) {
            setReadOnly(readOnly);
        }
    }, [activeRole, writtenWork?.state]);

    return (
        <>
            <AssessmentHeader />

            <Columns isAlignItemsCenter isJustifyContentCenter isFlexDirectionColumn>
                <Column isFullMobile is10Desktop is6Widescreen isFullTablet>
                    <WrittenWorkInfo writtenWork={writtenWork} />
                    <RenderWhen userIsNot={UserRole.AUTHOR}>
                        <AssessmentEditor readOnly={readOnly} />
                    </RenderWhen>
                    <RenderWhen userIs={UserRole.AUTHOR}>
                        <AssessmentMessageFormat writtenWork={writtenWork} assessment={assessment} />
                    </RenderWhen>
                    <RenderWhen userIsNot={UserRole.AUTHOR}>
                        <AssessmentJudgement readOnly={readOnly} />
                    </RenderWhen>
                    {error && (
                        <Help isDanger style={{ fontSize: "18px", textAlign: "center" }}>
                            {errorMsg}
                        </Help>
                    )}
                    <AssessmentButtonsPanel eventId={writtenWork?.writtenWorkEventId} viewButton={!readOnly} />
                    <RenderWhen userIsNot={UserRole.AUTHOR}>
                        <Column hasTextCentered className={"is-vcentered"}>
                            <RenderLoadingIndicator state={uploadState} />
                        </Column>
                    </RenderWhen>
                </Column>
            </Columns>
        </>
    );
}
