import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

type PzRadioProps = {
    value: string;
    name: string;
    label: string;
    inputRef: (ref: HTMLInputElement | null) => void;
};
export default function PzRadio({ value, name, label, inputRef }: PzRadioProps) {
    const id = useRef(uuidv4());
    return (
        <div>
            <input type="radio" id={id.current} name={name} value={value} ref={inputRef} />
            <label style={{ marginLeft: "5px" }} htmlFor={id.current}>
                {label}
            </label>
        </div>
    );
}
