import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Column, Columns, Title } from "components/trunx";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactElement } from "react";
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Author, AuthorRole, AuthorSource, Language, languageDisplayValue } from "shared";
import { v4 as uuidv4 } from "uuid";

import { AppPath } from "../../../../../common/AppPath";
import CollapsibleExpandButton from "../../../../../components/dashboard/CollapsibleExpandButton";
import PzButton from "../../../../../components/form/PzButton";
import PzFormInputField from "../../../../../components/form/PzFormInputField";
import PzIconButton from "../../../../../components/form/PzIconButton";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import PageTemplate from "../../../../../components/pagetemplate/PageTemplate";
import PzTable from "../../../../../components/table/PzTable";
import { RowData } from "../../../../../components/table/TableBody";
import { ColumnData } from "../../../../../components/table/TableHead";
import { formatDate } from "../../../../../components/utils/DateUtils";
import { AuthorService } from "../../../../../services/firestore/BookService";
import { AuthorProfileDto } from "../../../../../services/firestore/types";
import { FirebaseUploadFile } from "../../../../../services/storage/types";
import AuthorProfileInfoCollapsible from "./AuthorProfileCollapsible";

export default function BookAuthorAdminPage() {
    return (
        <PageTemplate marginLessTop>
            <BookAuthorAdmin />
        </PageTemplate>
    );
}

export interface BookAuthorAdminFormStateProps {
    formValues: BookAuthorAdminFormProps;
    authorId: string;
    authorProfile: BookAuthorProfileFormProps;
    updatedAuthorProfile: BookAuthorProfileFormProps;
}

export interface BookAuthorAdminFormProps {
    source: AuthorSource;
    firstName: string;
    lastName: string;
    contactInfo?: string;
    roles?: AuthorRole[];
    profiles?: BookAuthorProfileFormProps[];
}

export interface BookAuthorProfileFormProps {
    source: AuthorSource;
    uniqueId?: string;
    documentId: string;
    displayName: string;
    name: string;
    lastChanged: Date;
    description: string;
    language: Language;
    profilePath?: string;
    roles?: AuthorRole[];
    profilePicture?: Partial<FirebaseUploadFile>;
}

export function BookAuthorAdmin(): ReactElement {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [initialFormValues, setInitialFormValues] = useState<Partial<BookAuthorAdminFormProps>>();
    const formProfiles = useRef<BookAuthorProfileFormProps[]>([]);
    useEffect(() => {
        loadInitialData();
    }, []);

    async function loadInitialData() {
        let formValues: Partial<BookAuthorAdminFormProps> = {};
        if (state?.formValues) {
            formValues = state.formValues;
        } else if (state?.authorId) {
            formValues = await loadData();
        }
        if (state?.updatedAuthorProfile) {
            formValues = updateProfilesFormValue(formValues);
        }
        formProfiles.current = formValues?.profiles ?? [];
        setInitialFormValues(formValues);
        setLoadingData(false);
    }

    function updateProfilesFormValue(formValues: Partial<BookAuthorAdminFormProps>) {
        const uniqueId = state.updatedAuthorProfile.uniqueId;
        let profiles = formValues.profiles ?? [];

        const profileExists = (profile) => profile.uniqueId === uniqueId;
        if (profiles.some(profileExists)) {
            profiles = profiles.map((profile) =>
                profile.uniqueId === uniqueId ? state.updatedAuthorProfile : profile
            );
        } else {
            profiles.push(state.updatedAuthorProfile);
        }
        formValues.profiles = profiles;
        return formValues;
    }

    async function loadData() {
        const authorId = state.authorId;
        const authorData = await new AuthorService().getAuthorById(authorId);
        const authorProfilesData = await new AuthorService().getAuthorProfilesById(authorId);
        const author = authorData?.author;

        return {
            source: author?.source,
            firstName: author?.firstName,
            lastName: author?.lastName,
            contactInfo: author?.contactInfo,
            roles: author?.roles,
            profiles: authorProfilesData.map((profile) => ({
                uniqueId: uuidv4(),
                source: profile.authorProfile.source,
                documentId: profile.documentId,
                displayName: profile.authorProfile.displayName,
                name: profile.authorProfile.name,
                lastChanged: profile.authorProfile.lastChanged?.toDate(),
                description: profile.authorProfile.description,
                language: profile.authorProfile.language,
                profilePath: profile.authorProfile.profilePicturePath,
                roles: profile.authorProfile.roles,
            })),
        };
    }

    async function onSubmit(data: BookAuthorAdminFormProps) {
        const authorData: Partial<Author> = {
            source: data.source ?? initialFormValues?.source ?? "publizm",
            firstName: data.firstName ?? initialFormValues?.firstName,
            lastName: data.lastName ?? initialFormValues?.lastName,
            contactInfo: data.contactInfo ?? initialFormValues?.contactInfo,
            roles: data.roles ?? initialFormValues?.roles,
        };
        const getProfile = (id: string) => initialFormValues?.profiles?.find((p) => p.documentId == id);
        const profiles: AuthorProfileDto[] =
            formProfiles.current?.map((profile) => {
                const initialProfile = getProfile(profile.documentId);
                return {
                    source: profile.source ?? initialProfile?.source ?? "publizm",
                    name: profile.name,
                    displayName: profile.displayName,
                    description: profile.description,
                    language: profile.language,
                    profilePicturePath: profile.profilePath,
                    profilePicture: profile.profilePicture,
                    documentId: profile.documentId,
                    roles: profile.roles ?? initialProfile?.roles,
                };
            }) ?? [];
        await new AuthorService().saveAuthor(authorData, profiles, state?.authorId);
        navigate(AppPath.ADMIN_AUTHORS, {});
    }
    if (loadingData) {
        return <LoadingIndicator />;
    }
    return <AuthorForm initialFormValues={initialFormValues} onSubmit={onSubmit} authorId={state?.authorId} />;
}

interface AuthorFormProps {
    initialFormValues?: Partial<BookAuthorAdminFormProps>;
    authorId?: string;
    onSubmit: (formValues: BookAuthorAdminFormProps) => void;
}
function AuthorForm({ initialFormValues, onSubmit, authorId }: AuthorFormProps) {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useNavigate();

    const methods = useForm<BookAuthorAdminFormProps>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: initialFormValues,
    });

    const { register, handleSubmit } = methods;
    useEffect(() => {
        register("source");
        register("profiles");
    }, [initialFormValues]);

    function toAuthorDashboard() {
        navigate(AppPath.ADMIN_AUTHORS);
    }
    return (
        <Columns isFlex isFlexDirectionRow p6>
            <Column is1Desktop is1Tablet is2Widescreen isHiddenMobile>
                <PzIconButton isSmall onClick={toAuthorDashboard} icon={faArrowLeft} />
            </Column>
            <Column isFlex isFlexDirectionColumn isAlignContentFlexStart isFlexGrow1 is8Desktop>
                <Column isPaddingLess pb4 isHiddenTablet isFlex isAlignContentFlexStart>
                    <PzIconButton isSmall onClick={toAuthorDashboard} icon={faArrowLeft} />
                </Column>
                <FormProvider {...methods}>
                    <form className="authoradmin">
                        <Title pb6>Forfatter</Title>
                        <Column is3>
                            <PzFormInputField type="text" label="Fornavn" name="firstName" inputRef={register()} />
                            <PzFormInputField type="text" label="Etternavn" name="lastName" inputRef={register()} />
                            <PzFormInputField
                                type="text"
                                label="Kontaktinfo"
                                name="contactInfo"
                                inputRef={register()}
                            />
                        </Column>
                        <AuthorProfilesTable authorId={authorId} />
                        <Column>
                            <PzButton
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={(data) => {
                                    setIsSubmitting(true);
                                    handleSubmit(onSubmit)(data);
                                }}
                                isPrimary
                            >
                                Oppdater
                            </PzButton>
                        </Column>
                    </form>
                </FormProvider>
            </Column>
        </Columns>
    );
}

interface AuthorProfilesTableProps {
    authorId?: string;
}
function AuthorProfilesTable({ authorId }: AuthorProfilesTableProps) {
    const navigate = useNavigate();
    const { fields: profiles } = useFieldArray({ name: "profiles" });
    const formState = useWatch<BookAuthorAdminFormProps>({});
    const editAuthorProfile = useMemo(
        () => (authorProfile?: BookAuthorProfileFormProps) => {
            navigate(AppPath.ADMIN_CREATE_AUTHOR_PROFILE, {
                state: {
                    formValues: { ...formState },
                    authorId: authorId,
                    authorProfile: authorProfile,
                },
            });
        },
        [formState]
    );
    const columns: ColumnData[] = [
        {
            label: "Forfatterprofil",
            sortByProperty: "authorProfile.name",
        },
        {
            label: "Sist oppdatert",
            sortByProperty: "authorProfileInfo.lastChanged",
        },
        {
            label: "Språk",
            sortByProperty: "authorProfileInfo.language",
        },
        {
            label: "",
        },
    ];

    function createRow(
        authorProfile: BookAuthorProfileFormProps,
        toggleCollapsible?: () => void,
        isOpenCollapsible?: boolean
    ): RowData {
        return {
            collapsibleComponent: (
                <AuthorProfileInfoCollapsible
                    onEditProfile={() => editAuthorProfile(authorProfile)}
                    description={authorProfile.description}
                    authorProfileId={authorProfile.documentId}
                />
            ),
            components: [
                {
                    content: (
                        <a
                            onClick={() => {
                                editAuthorProfile(authorProfile);
                            }}
                        >
                            {authorProfile.displayName}
                        </a>
                    ),
                    width: "15%",
                },
                {
                    content: formatDate(authorProfile.lastChanged ?? new Date()) ?? "-",
                    width: "15%",
                },

                {
                    content: languageDisplayValue(authorProfile.language),
                    width: "60%",
                },
                {
                    content: (
                        <CollapsibleExpandButton
                            isOpen={isOpenCollapsible}
                            onClick={(e) => {
                                e.preventDefault();
                                toggleCollapsible?.();
                            }}
                        />
                    ),
                    width: "10%",
                    className: "is-chevron-cell",
                },
            ],
        };
    }
    return (
        <Column is8>
            <Title pt6 hasTextWeightBold>
                Profiler
            </Title>
            <PzTable columns={columns} data={profiles as BookAuthorProfileFormProps[]} createRow={createRow}></PzTable>
            <Column isFlex isFlexDirectionRow isJustifyContentFlexStart pl0 ml0>
                <PzIconButton
                    icon={faPlus}
                    isSmall
                    iconPlacement="left"
                    onClick={(e) => {
                        e.preventDefault();
                        editAuthorProfile();
                    }}
                >
                    Ny profil
                </PzIconButton>
            </Column>
        </Column>
    );
}
