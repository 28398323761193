import "./AuthorProfileCatalogueCard.css";

import truncateText from "components/utils/TruncateText";
import DOMPurify from "dompurify";
import AuthorImage from "pages/customer/common/AuthorImage";
import { UrlUtils } from "shared";

import { AppPath } from "../../../../common/AppPath";

export interface AuthorProfileCatalogueCardProps {
    id: string;
    profileId: string;
    displayName: string;
    displayNameHighlighted: string;
    description: string;
    descriptionHighlighted: string;
    profileImagePath?: string;
    lastChanged?: Date;
}

export default function AuthorProfileCatalogueCard({
    id,
    displayName,
    displayNameHighlighted,
    descriptionHighlighted,
    profileImagePath,
    lastChanged,
}: AuthorProfileCatalogueCardProps) {
    function getDescription() {
        if (!descriptionHighlighted) return "";
        return truncateText(descriptionHighlighted, 200);
    }
    return (
        <a
            className="card author-profile-catalogue-card"
            href={UrlUtils.encodeAuthorUrl(id, displayName, AppPath.AUTHOR_PROFILE_DETAILS)}
        >
            <div className="author-profile-catalogue-card-image-container">
                <AuthorImage hasNoSpecifiedRatio={true} coverPath={profileImagePath} lastChanged={lastChanged} />
            </div>
            <div className="author-profile-catalogue-card-name highlight mt-2" style={{ textAlign: "center" }}>
                <h5 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayNameHighlighted ?? "") }} />
                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getDescription()) }} />
            </div>
        </a>
    );
}
