import { faArrowCircleRight, faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { AppPath } from "common/AppPath";
import { RenderWhen } from "components/auth/UserAuth";
import { Card, Column } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserRole, WrittenWorkState } from "shared";

import useKeyPress from "../../common/hooks/useKeyPress";
import { useAuth } from "../../contexts/AuthContext";
import { WrittenWorkData } from "../../services/firestore/types";
import { WrittenWorkService } from "../../services/firestore/WrittenWorkService";
import PzIconButton from "../form/PzIconButton";
import PzExpandableText from "../PzExpandableText";
import PzText from "../text/PzText";

interface WrittenWorksPanelCollapsibleProps {
    editorListComponent?: ReactElement;
    writtenWorkData: WrittenWorkData;
}

export default function WrittenWorksPanelCollapsible({
    editorListComponent,
    writtenWorkData,
}: WrittenWorksPanelCollapsibleProps) {
    const [assesmentExists, setAssesmentExists] = useState<boolean>(false);
    const { activeRole } = useAuth();
    const isEditor = activeRole === UserRole.EDITOR;
    const isAuthor = activeRole === UserRole.AUTHOR;
    const writtenWork = writtenWorkData.writtenWork;
    const ctrlPressed = useKeyPress("Control");

    useEffect(() => {
        new WrittenWorkService.Assessment(writtenWorkData.documentId).exists().then(setAssesmentExists);
    }, []);

    const assessmentButtonText = () => {
        if (isEditor) {
            return "Vurder";
        }
        return "Se vurdering";
    };

    function isAssessmentButtonEnabled() {
        const authorRenderConditions = [WrittenWorkState.REFUSED, WrittenWorkState.ACCEPTED];
        if (isAuthor) {
            return authorRenderConditions.includes(writtenWork.state);
        }
        return isEditor || assesmentExists;
    }

    function AssessmentButton() {
        if (!isAssessmentButtonEnabled()) {
            return (
                <PzIconButton disabled={true} icon={faArrowCircleRight}>
                    {assessmentButtonText()}
                </PzIconButton>
            );
        }
        if (ctrlPressed) {
            return (
                <span
                    onClick={() => {
                        window.open(AppPath.WORK_ASSESSMENT + `?id=${writtenWorkData.documentId}`);
                    }}
                >
                    <PzIconButton icon={faExternalLinkSquareAlt}>{assessmentButtonText()}</PzIconButton>
                </span>
            );
        } else {
            return (
                <Link
                    to={{
                        pathname: AppPath.WORK_ASSESSMENT,
                    }}
                    state={writtenWorkData}
                >
                    <PzIconButton icon={faArrowCircleRight}>{assessmentButtonText()}</PzIconButton>
                </Link>
            );
        }
    }

    function ContinueUploadButton(props) {
        const navigate = useNavigate();

        const uploadEvent =
            `${AppPath.SUBMIT_WORK}?event=${props.writtenWork.writtenWorkEventId}` ?? AppPath.SUBMIT_WORK;

        return (
            <PzIconButton onClick={() => navigate(uploadEvent)} icon={faArrowCircleRight}>
                Fortsett innlevering
            </PzIconButton>
        );
    }

    return (
        <>
            <Card
                style={{ height: "auto" }}
                p0
                m0
                isFlexTablet
                isFlexDesktop
                isFlexWidescreen
                isFlexDirectionRow
                isJustifyContentSpaceBetween
            >
                <Column isFlexGrow2>
                    <PzText
                        isSize7
                        isInline
                        isJustifyContentFlexStart
                        containerProps={{ m0: true, p0: true, ml3: true }}
                    >
                        <strong>Sjanger: </strong>
                        {writtenWork.tags?.join(", ")}
                    </PzText>
                    <PzExpandableText text={writtenWork.pitch} />
                </Column>
                <Column
                    style={{
                        display: "grid",
                        gridTemplateAreas: "'100px 100px'",
                        gridGap: "20px",
                        alignContent: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    {writtenWork.state === WrittenWorkState.DRAFT ? (
                        <RenderWhen userIs={UserRole.AUTHOR}>
                            <ContinueUploadButton writtenWork={writtenWork} />
                        </RenderWhen>
                    ) : (
                        <AssessmentButton />
                    )}
                </Column>
            </Card>
            {editorListComponent}
        </>
    );
}
