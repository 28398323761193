export function removeNullOrUndefinedKeys<T>(obj?: T, removeEmpty?: boolean): T {
    if (!obj) {
        return {} as T;
    }

    for (const propName in obj) {
        const prop = obj[propName];
        if (
            obj[propName] === null ||
            obj[propName] === undefined ||
            // @ts-ignore
            (typeof prop == "object" && Object.keys(prop).length === 0) ||
            (typeof prop == "string" && removeEmpty && isEmptyString(prop))
        ) {
            delete obj[propName];
        }

        if (typeof prop == "object") {
            removeNullOrUndefinedKeys(prop, removeEmpty);
        }
    }
    return obj;
}
export function isEmptyString(value?: string): boolean {
    return typeof value != "string" || (!value && value.trim().length === 0);
}
export function removeKeys<T>(obj: T, removeKeys: string[]): T {
    removeKeys.forEach((key) => {
        // @ts-ignore
        delete obj[key];
    });
    return obj;
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export const zeroPad = (num: number | string, places: number) => String(num).padStart(places, "0");

export function getNumberOrZero(value?: string | number) {
    if (value === undefined) {
        return 0;
    }
    if (typeof value === "number") {
        return isNaN(value) ? 0 : value;
    }
    if (typeof value === "string" && !isNaN(parseInt(value))) {
        return parseInt(value);
    }

    return 0;
}

export function filterUniqueByKey<T>(arr: T[], key: string): T[] {
    return arr.filter((value1, index, self) => index === self.findIndex((value2) => value2[key] == value1[key]));
}

export function objectsEqual(o1, o2) {
    if (!o1 || !o2) return false;
    return typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
              Object.keys(o1)
                  .sort()
                  .every((p) => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
}
export function objectsEqual2(o1: any, o2: any): boolean {
    if (o1 === o2) return true;

    if (typeof o1 !== "object" || o1 === null || typeof o2 !== "object" || o2 === null) {
        return false;
    }

    const keys1 = Object.keys(o1);
    const keys2 = Object.keys(o2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!keys2.includes(key)) return false;
        if (typeof o1[key] === "object" && typeof o2[key] === "object") {
            if (!objectsEqual2(o1[key], o2[key])) return false;
        } else if (o1[key] !== o2[key]) {
            return false;
        }
    }

    return true;
}
export function getIfNotempty(o?: string) {
    if (isEmptyString(o)) return undefined;
    return o;
}
export function isEmpty(o?: any) {
    if (!o || Object.keys(o).length == 0) return true;
    return false;
}
export function arraysEqual(a1, a2) {
    if (!a1 || !a2) return false;
    return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
}

export function arraysEqualSlow(a, b) {
    return JSON.stringify(a?.sort()) === JSON.stringify(b?.sort());
}
