import { Column } from "components/trunx";
import { BookType } from "shared";

import AlgoliaSearchProvider from "../../../components/algolia/AlgoliaSearchProvider";
import { isDevMode } from "../../../firebase";
import CatalogueCardDisplayer from "./CatalogueCardDisplayer";
import CatalogueCardDisplayerAlgolia from "./CatalogueCardDisplayerAlgolia";
import CatalougeFilterProvider from "./CatalogueFilterContext";

interface CataloguePageProps {
    type?: BookType;
}
export default function CataloguePage({ type }: CataloguePageProps) {
    return (
        <Column>
            <AlgoliaSearchProvider index={import.meta.env.VITE_ALGOLIA_INDEX_BOOKS!}>
                <CatalougeFilterProvider>
                    {isDevMode ? (
                        <CatalogueCardDisplayer defaultPageSize={48} type={type} />
                    ) : (
                        <CatalogueCardDisplayerAlgolia defaultPageSize={48} type={type} />
                    )}
                </CatalougeFilterProvider>
            </AlgoliaSearchProvider>
        </Column>
    );
}
