import "./BookProducts.css";

import { faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Column, Columns, Container, Label } from "components/trunx";
import React, { lazy, ReactElement, useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BookType } from "shared";

import { sortByFilename } from "../../../../../common/BookUtils";
import PzCheckbox from "../../../../../components/form/PzCheckbox";
import PzFormInputField from "../../../../../components/form/PzFormInputField";
import PzIconButton from "../../../../../components/form/PzIconButton";
import PzRadio from "../../../../../components/form/PzRadio";
import PzModal from "../../../../../components/modal/PzModal";
import PzPanel, { PzPanelOption } from "../../../../../components/PzPanel";
import PzText from "../../../../../components/text/PzText";
import { PzTitle } from "../../../../../components/text/PzTitle";
import PzFileUpload from "../../../../../components/uploadfile/PzFileUpload";
import { FileUtils } from "../../../../../components/utils/FileUtils";
import { BookService } from "../../../../../services/firestore/BookService";
import { FirebaseUploadFile } from "../../../../../services/storage/types";
import { BookAdminFormValues, parseAudioFile } from "./BookAdminTypes";
import BookCostForm from "./BookCostForm";
import BookCover from "./BookCover";
import LicenseeForm from "./LicenseeForm";
const ebookOptions: PzPanelOption[] = [
    {
        name: "Opplastning",
        content: <UploadEbook />,
    },
    {
        name: "Produktdetaljer",
        content: <EbookDetails />,
    },
    {
        name: "Pris",
        content: <BookPriceDetails name={"ebook.price"} />,
    },
    {
        name: "Rettighetshavere",
        content: <LicenseeForm name="ebook" />,
    },
    {
        name: "Kostnad",
        content: <BookCostForm name="ebook" />,
    },
];

const audioBookOptions: PzPanelOption[] = [
    {
        name: "Opplastning",
        content: <UploadAudioBook />,
    },
    {
        name: "Produktdetaljer",
        content: <AudioBookDetails />,
    },
    {
        name: "Pris",
        content: <BookPriceDetails name={"audiobook.price"} />,
    },
    {
        name: "Rettighetshavere",
        content: <LicenseeForm name="audiobook" />,
    },
    {
        name: "Kostnad",
        content: <BookCostForm name="audiobook" />,
    },
];

const bundleOptions: PzPanelOption[] = [
    {
        name: "Pris",
        content: <BookPriceDetails name={"bundlePrice"} />,
    },
];
export default function BookProducts(): ReactElement {
    return (
        <Container p0 m0>
            <Column m0>
                <PzTitle hasTextCentered>Produkter</PzTitle>
            </Column>
            <PzPanel title="E-bok" options={ebookOptions} />
            <PzPanel title="Lydbok" className="audiobook" options={audioBookOptions} />
            <PzPanel title="Bundle" options={bundleOptions} />
        </Container>
    );
}

interface BookPriceDetailsProps {
    name: string;
}
function BookPriceDetails({ name }: BookPriceDetailsProps) {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Column is5>
            <PzFormInputField
                label="Pris (NOK)"
                name={name}
                type="number"
                formError={errors[name]?.price}
                inputRef={register()}
            />
        </Column>
    );
}

function EbookDetails(): ReactElement {
    const { register, errors } = useFormContext<BookAdminFormValues>();

    return (
        <Columns>
            <Column is5>
                <PzFormInputField
                    label="ISBN nummer"
                    name="ebook.isbn"
                    type="number"
                    formError={errors.ebook?.isbn}
                    inputRef={register()}
                />
                <PzFormInputField
                    label="Oversetter"
                    name="ebook.translator"
                    type="text"
                    formError={errors.ebook?.translator}
                    inputRef={register()}
                />
            </Column>
        </Columns>
    );
}

function UploadEbook() {
    return (
        <Column className="upload">
            <BookCover formPath="ebook.cover" />
            <EbookUpload
                acceptExtensions=".mobi,.epub"
                label="Utdrag"
                name="ebook.sample"
                pagesName="ebook.samplePages"
            />
            <EbookUpload acceptExtensions=".epub" label="Fullversjon" name="ebook.file" pagesName="ebook.pages" />
            <EbookUpload acceptExtensions=".mobi" label="Kindleversjon" name="ebook.kindle" />
        </Column>
    );
}
const Ebook = lazy(() => import("../../../../../components/ebook/Ebook"));
interface EbookUploadProps {
    name: string;
    pagesName?: string;
    label: string;
    acceptExtensions?: string;
}
function EbookUpload({ name, pagesName, label, acceptExtensions }: EbookUploadProps) {
    const { register, setValue, errors } = useFormContext<BookAdminFormValues>();
    const ebook = useWatch<Partial<FirebaseUploadFile>>({ name: name });

    useEffect(() => {
        register({ name: name });
    }, []);

    return (
        <>
            <PzTitle pt5 pb0 mb0 isSize6>
                {label}
            </PzTitle>
            <Column p0 m0 pt3>
                {ebook?.name && (
                    <EbookFileDetails
                        onRemove={() => {
                            pagesName && setValue(pagesName, null);
                            setValue(name, null);
                        }}
                        file={ebook}
                    />
                )}
            </Column>
            {!ebook?.name && (
                <PzFileUpload
                    acceptExtensions={acceptExtensions}
                    ml0
                    pl0
                    pt5
                    dropZone
                    onFileAdded={(file) => setValue(name, file)}
                    label={label}
                >
                    Velg fil
                </PzFileUpload>
            )}
            {pagesName && (
                <Column is2 isHidden={!ebook?.name} pl0 ml0>
                    <PzFormInputField
                        label="Antall sider"
                        name={pagesName}
                        type="number"
                        formError={errors.ebook?.[pagesName]}
                        inputRef={register()}
                    />
                </Column>
            )}
        </>
    );
}
function UploadAudioBook() {
    const { setValue } = useFormContext<BookAdminFormValues>();
    const { fields: files, append } = useFieldArray({ name: "audiobook.files" });
    const [loadingFile, setLoadingFile] = useState<boolean>(false);

    return (
        <Column className="upload">
            <BookCover formPath="audiobook.cover" />
            <AudioBookType />
            <UploadAudioBookSample />
            <PzTitle pt3 pb0 mb0 isSize6>
                Lydbok
            </PzTitle>
            <Column p0 m0 pt0 mt5>
                {files
                    .map((file, index) => ({
                        file: file,
                        component: (
                            <AudioBookChapterDetails
                                key={file.id}
                                onRemove={() => setValue(`audiobook.files[${index}].deleted`, true)}
                                file={file.file as FirebaseUploadFile}
                                durationSeconds={file.durationSeconds as number}
                                name={`audiobook.files[${index}]`}
                            />
                        ),
                    }))
                    .sort((a, b) => sortByFilename(a.file?.file.name, b.file?.file.name))
                    .map((res) => res.component)}
            </Column>

            <PzFileUpload
                ml0
                pl0
                pt5
                multiple
                dropZone
                loading={loadingFile}
                accept="audio"
                onFileAdded={(files) => {
                    setLoadingFile(true);
                    parseAudioFile(files, (file) => {
                        append(file);
                        setLoadingFile(false);
                    });
                }}
                label={`Lydbok`}
            >
                Velg fil
            </PzFileUpload>
        </Column>
    );
}
function AudioBookType() {
    const { register } = useFormContext<BookAdminFormValues>();
    return (
        <Column>
            <Label>Type</Label>
            <Column isFlex isFlexDirectionRow style={{ gap: "15px" }}>
                <PzRadio label="Lydbok" name="audiobook.type" value={BookType.AUDIO} inputRef={register()} />
                <br />
                <PzRadio label="Podcast" name="audiobook.type" value={BookType.PODCAST} inputRef={register()} />
            </Column>
        </Column>
    );
}
function AudioBookType2() {
    const { register } = useFormContext<BookAdminFormValues>();
    return (
        <Column>
            <Label>Type</Label>
            <Column isFlex isFlexDirectionRow style={{ gap: "15px" }}>
                <PzCheckbox name="audiobook.contentType.audiobook" value="Lydbok" inputRef={register()}></PzCheckbox>
                <PzCheckbox
                    name="audiobook.contentType.radiodrama"
                    value="Hørespill"
                    inputRef={register()}
                ></PzCheckbox>
            </Column>
        </Column>
    );
}
function UploadAudioBookSample() {
    const [loadingFile, setLoadingFile] = useState<boolean>(false);
    const { setValue, register } = useFormContext<BookAdminFormValues>();
    const audioBookSample = useWatch<Partial<FirebaseUploadFile>>({ name: "audiobook.sample.file" });
    const audioBookSampleDuration = useWatch<number>({ name: "audiobook.sample.durationSeconds" });
    useEffect(() => {
        register("audiobook.sample.file");
        register("audiobook.sample.durationSeconds");
    }, []);
    return (
        <>
            <PzTitle pt5 pb0 mb0 isSize6>
                Utdrag
            </PzTitle>
            <Column p0 m0 pt3>
                {audioBookSample && audioBookSampleDuration && (
                    <AudioBookChapterDetails
                        onRemove={() => setValue(`audiobook.sample.file`, null)}
                        file={audioBookSample}
                        durationSeconds={audioBookSampleDuration}
                        name={"audiobook.sample"}
                    />
                )}
            </Column>
            {!(audioBookSample && audioBookSampleDuration) && (
                <PzFileUpload
                    ml0
                    pl0
                    pt5
                    dropZone
                    loading={loadingFile}
                    accept="audio"
                    onFileAdded={(file) => {
                        setLoadingFile(true);
                        parseAudioFile(file, (sample) => {
                            setValue(`audiobook.sample.file`, sample.file);
                            setValue(`audiobook.sample.durationSeconds`, sample.durationSeconds);
                            setLoadingFile(false);
                        });
                    }}
                    label={`Lydbok`}
                >
                    Velg fil
                </PzFileUpload>
            )}
        </>
    );
}
function AudioBookDetails() {
    const { register, errors } = useFormContext<BookAdminFormValues>();

    return (
        <Columns>
            <Column is5>
                <PzFormInputField
                    label="ISBN nummer"
                    name="audiobook.isbn"
                    type="number"
                    formError={errors.audiobook?.isbn}
                    inputRef={register()}
                />
                <PzFormInputField
                    label="Innleser"
                    name="audiobook.narrator"
                    type="text"
                    formError={errors.audiobook?.narrator}
                    inputRef={register()}
                />
                <PzFormInputField
                    label="Produsent"
                    name="audiobook.producer"
                    type="text"
                    formError={errors.audiobook?.producer}
                    inputRef={register()}
                />
                <PzFormInputField
                    label="Oversetter"
                    name="audiobook.translator"
                    type="text"
                    formError={errors.audiobook?.translator}
                    inputRef={register()}
                />
                <PzFormInputField
                    label="Lyddesigner"
                    name="audiobook.sounddesigner"
                    type="text"
                    formError={errors.audiobook?.sounddesigner}
                    inputRef={register()}
                />
            </Column>
        </Columns>
    );
}

interface EbookFileDetailsProps {
    file: Partial<FirebaseUploadFile>;
    onRemove: () => void;
}
function EbookFileDetails({ file, onRemove }: EbookFileDetailsProps) {
    const [bookUrl, setBookUrl] = useState<string>();
    const [previewBook, setPreviewBook] = useState<boolean>(false);
    function openBook() {
        getBookUrl().then(FileUtils.openFileUrl);
    }

    async function getBookUrl() {
        if (bookUrl) {
            return bookUrl;
        } else if (file.filePath) {
            return new BookService().adminLoadBookUrl(file.filePath).then((url) => {
                setBookUrl(url);
                return url;
            });
        } else if (file.data) {
            const fileUrl = FileUtils.dataToFileUrl(file.data, file.type!);
            setBookUrl(fileUrl);
            return fileUrl;
        }
    }
    return (
        <Column isFlex isFlexDirectionRow className="item" p0 is5>
            <PzText hasTextWeightBold>
                <a onClick={openBook}>{file?.name}</a>
            </PzText>
            <PzModal
                contentStyle={{ width: "90vw", height: "90vh", zIndex: 9999999999999 }}
                isActive={previewBook && bookUrl != undefined}
                onClose={() => setPreviewBook(false)}
            >
                {previewBook && bookUrl && <Ebook isSample ebookUrl={bookUrl} title={file?.name ?? "-"} />}
            </PzModal>
            <PzIconButton
                onClick={(e) => {
                    e.preventDefault();
                    setPreviewBook(true);
                    getBookUrl();
                }}
                icon={faExternalLinkAlt}
                iconPlacement="left"
                isSmall
            ></PzIconButton>
            <PzIconButton
                onClick={(e) => {
                    e.preventDefault();
                    onRemove();
                }}
                icon={faTimes}
                iconPlacement="left"
                isSmall
            >
                Fjern
            </PzIconButton>
        </Column>
    );
}

interface AudioBookChapterDetailsProps {
    name: string;
    durationSeconds: number;
    file: Partial<FirebaseUploadFile>;
    onRemove: () => void;
}
function AudioBookChapterDetails({ name, file, durationSeconds, onRemove }: AudioBookChapterDetailsProps) {
    const { register, setValue } = useFormContext<BookAdminFormValues>();
    const defaultTitle = useWatch<string>({ name: `${name}.title` });
    const isDeleted = useWatch<boolean>({ name: `${name}.deleted` });
    const [audioBookUrl, setAudioBookUrl] = useState<string>();
    useEffect(() => {
        register(`${name}.deleted`);
        register(`${name}.file`);
        register(`${name}.durationSeconds`);
        setValue(`${name}.file`, file);
        setValue(`${name}.durationSeconds`, durationSeconds);
    }, []);

    function formatSeconds(seconds?: number) {
        if (!seconds) {
            return;
        }
        return new Date(seconds * 1000).toISOString().substr(11, 8);
    }

    function openAudioBook() {
        if (audioBookUrl) {
            FileUtils.openFileUrl(audioBookUrl);
        } else if (file.filePath) {
            new BookService().adminLoadBookUrl(file.filePath).then((url) => {
                setAudioBookUrl(url);
                FileUtils.openFileUrl(url);
            });
        } else if (file.data) {
            FileUtils.openFile(file);
        }
    }
    if (isDeleted === true || !file) {
        return null;
    }
    return (
        <Column isFlex isFlexDirectionRow className="item" p0>
            <PzFormInputField
                p0
                m0
                defaultValue={defaultTitle}
                controlProps={{ p0: true, m0: true }}
                name={`${name}.title`}
                type="text"
                style={{ width: "20vw", maxWidth: "23rem" }}
                placeholder="Kapitteltittel"
                inputRef={register()}
            />
            <PzText hasTextLeft ml6 hasTextWeightBold>
                <a onClick={openAudioBook}>{file?.name}</a>
            </PzText>
            <PzText hasTextCentered hasTextWeightBold>
                {formatSeconds(durationSeconds)}
            </PzText>
            <PzIconButton
                onClick={(e) => {
                    e.preventDefault();
                    onRemove();
                }}
                icon={faTimes}
                iconPlacement="left"
                isSmall
            >
                Fjern
            </PzIconButton>
        </Column>
    );
}
