import { Column, Columns } from "components/trunx";
import { useFormContext } from "react-hook-form";

import UpdateUserInfo from "../../../components/auth/UpdateUserInfo";
import PzFormInputField from "../../../components/form/PzFormInputField";

interface SignupFormProps {
    validators?: Record<string, (value: string) => Promise<string | boolean>>;
    telephoneNumberRequired: boolean;
    passwordRequired?: boolean;
    defaultTelephoneNumber?: string;
}

export default function SignupUserInfoForm({
    validators,
    telephoneNumberRequired,
    defaultTelephoneNumber,
}: SignupFormProps) {
    const { register, errors, watch } = useFormContext();

    return (
        <>
            <UpdateUserInfo
                telephoneNumberRequired={telephoneNumberRequired}
                validators={validators}
                registerKindleEmail={false}
            />
            <Columns>
                <Column>
                    <PzFormInputField
                        label="Passord"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        placeholder="Passord"
                        formError={errors.password}
                        inputRef={register({
                            required: "Du må oppgi passord",
                            minLength: {
                                value: 8,
                                message: "Passord må ha minst 8 tegn",
                            },
                        })}
                    />
                </Column>
                <Column>
                    <PzFormInputField
                        label="Gjenta passord"
                        name="passwordConfirm"
                        type="password"
                        autoComplete="new-password"
                        placeholder="Gjenta passord"
                        formError={errors.passwordConfirm}
                        inputRef={register({
                            required: "Du må gjenta passordet",
                            validate: (value) => value === watch("password") || "Passordene må være like",
                        })}
                    />
                </Column>
            </Columns>
        </>
    );
}
