import { PropsWithChildren, ReactElement } from "react";

interface PzLabeledTextProps {
    label: string;
}

export default function PzDatListItem(props: PropsWithChildren<PzLabeledTextProps>): ReactElement {
    const { children, label } = props;
    return (
        <li style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            <p style={{ fontWeight: "bold" }}>{label}:</p>
            <div style={{ fontWeight: 400 }}>{children}</div>
        </li>
    );
}
