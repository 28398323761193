import { useMemo, useState } from "react";
import { Configure, useInstantSearch, usePagination, useSearchBox } from "react-instantsearch";
import { useSearchParams } from "react-router-dom";

import { getYearOrDateFromValue } from "../utils/DateUtils";
import { debounce } from "../utils/ObjectUtils";
import PzTable, { PzTableProps } from "./PzTable";

type PriceFilter = {
    type: "originalPrice" | "currentPrice";
    direction: "<" | ">";
    value: number;
};

type DateFilter = {
    type: "publisertDato" | "sistOppdatertDato";
    direction: "<" | ">";
    value: Date;
};
const searchQueryKey = "search";
export default function PzAlgoliaTable<T>(props: PzTableProps<T>) {
    const [priceFilters, setPriceFilters] = useState<PriceFilter[]>([]);
    const [dateFilters, setDatefilters] = useState<DateFilter[]>([]);
    const { status } = useInstantSearch();
    const { refine: refineSearch } = useSearchBox();
    const { nbPages, refine: refinePagination, nbHits } = usePagination();
    const [hitsPerPage, setHitsPerPage] = useState(10);
    const [queryParams, setQueryParams] = useSearchParams();

    const debouncedSetSearchPattern = useMemo(
        () =>
            debounce((searchValue) => {
                setQueryParams((_) => {
                    const prev = new URLSearchParams(window.location.search);
                    if (searchValue.length == 0) prev.delete(searchQueryKey);
                    else prev.set(searchQueryKey, searchValue);
                    return prev;
                });
                refineSearch(parseSearchValue(searchValue));
            }, 350),
        []
    );

    function parseSearchValue(searchValue: string) {
        const splittedSearch = searchValue.split(" ");
        const priceFilters: PriceFilter[] = [];
        const dateFilters: DateFilter[] = [];
        let searchFilter = "";
        splittedSearch.forEach((search) => {
            if (search.startsWith("pris") || search.startsWith("originalpris")) {
                parsePriceFilters(search, priceFilters);
            } else if (search.startsWith("publisert") || search.startsWith("oppdatert")) {
                parseDateFilters(search, dateFilters);
            } else {
                searchFilter += search + " ";
            }
            setPriceFilters(priceFilters);
            setDatefilters(dateFilters);
        });
        return searchFilter.trim();
    }

    function parseDateFilters(search: string, dateFilters: DateFilter[]) {
        const date = getYearOrDateFromValue(search);
        if (search.startsWith("publisert") && date) {
            dateFilters.push({
                type: "publisertDato",
                direction: search.includes(">") ? ">" : "<",
                value: date,
            });
        } else if (date) {
            dateFilters.push({
                type: "sistOppdatertDato",
                direction: search.includes(">") ? ">" : "<",
                value: date,
            });
        }
    }
    function parsePriceFilters(search: string, priceFilters: PriceFilter[]) {
        const numericString = search.match(/-?\d+\.?\d*/);
        if (search.startsWith("pris") && numericString) {
            priceFilters.push({
                type: "currentPrice",
                direction: search.includes(">") ? ">" : "<",
                value: Number(numericString),
            });
        } else if (numericString) {
            priceFilters.push({
                type: "originalPrice",
                direction: search.includes(">") ? ">" : "<",
                value: Number(numericString),
            });
        }
    }

    function getNumericFilters() {
        const priceFilterValues = priceFilters.map((priceFilter) => {
            const type = priceFilter.type;
            const priceType = type == "currentPrice" ? "prices.value" : "prices.originalPrice";
            return `${priceType}${priceFilter.direction}${priceFilter.value}`;
        });

        const dateFilterValues = dateFilters.map((dateFilter) => {
            const type = dateFilter.type;
            const date = dateFilter.value;
            const dateType = type == "publisertDato" ? "dateOfFirstPublication" : "lastmodified.value";
            return `${dateType}${dateFilter.direction}${date.getTime()}`;
        });

        return [...dateFilterValues, ...priceFilterValues];
    }

    return (
        <>
            <Configure hitsPerPage={hitsPerPage} numericFilters={getNumericFilters()} />
            <PzTable
                {...props}
                loading={status == "loading"}
                enableExternalDataUpdate
                search={{
                    defaultSearchValue: queryParams.get(searchQueryKey) || "",
                    onSearchChange: (val) => {
                        debouncedSetSearchPattern(val);
                    },
                }}
                pagination={{
                    onInit: setHitsPerPage,
                    totalPages: nbPages,
                    onPageSizeChange: (pagesize) => setHitsPerPage(pagesize),
                    onPageChange: (pagenumber) => refinePagination(pagenumber - 1),
                    totalElements: nbHits,
                }}
            />
        </>
    );
}
