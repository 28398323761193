import DashboardHorizontalMenu from "components/dashboard/Menu/DashboardHorizontalMenu";
import { AuthorAdminDashboardMenu } from "components/dashboard/Menu/DashboardUserMenu";
import PageTemplate from "components/pagetemplate/PageTemplate";
import { Column, Columns, Container } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { Configure, useInstantSearch } from "react-instantsearch";
import { Author, AuthorSource } from "shared";

import AlgoliaSearchProvider from "../../../../../components/algolia/AlgoliaSearchProvider";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import { DashboardProvider, useDashboard } from "../../../../../contexts/DashboardContext";
import { IAuthorAlgolia } from "../../../../../services/algolia/types";
import useAuthorsApi from "../../../../../services/firestore/hooks/useAuthorsApi";
import AuthorTable, { AuthorTableData } from "./AuthorTable";

export default function AuthorAdminDashboard(): ReactElement {
    return (
        <>
            <PageTemplate marginLessTop>
                <AlgoliaSearchProvider index={import.meta.env.VITE_ALGOLIA_INDEX_AUTHORS!}>
                    <DashboardProvider>
                        <Container>
                            {import.meta.env.DEV ? <AuthorAdminTable /> : <AuthorAdminTableAlgolia />}
                        </Container>
                    </DashboardProvider>
                </AlgoliaSearchProvider>
            </PageTemplate>
        </>
    );
}

function AuthorAdminTable() {
    const { selectedMenu } = useDashboard();
    const getSource = () => (selectedMenu == AuthorAdminDashboardMenu.ADMIN_AUTHORS_BOKBASEN ? "bokbasen" : "publizm");
    const [source, setSource] = useState<AuthorSource>(getSource());
    const [authorDataList, setAuthorDataList] = useState<AuthorTableData[]>([]);
    const { data, isLoading } = useAuthorsApi().authorsBySource(source);
    useEffect(() => {
        setSource(getSource());
    }, [selectedMenu]);
    function renderTable() {
        return <AuthorTable key={selectedMenu} authorTableData={authorDataList} source={getSource()} />;
    }

    function renderContent() {
        if (isLoading) {
            return <LoadingIndicator />;
        }

        switch (selectedMenu) {
            case AuthorAdminDashboardMenu.ADMIN_AUTHORS:
                return renderTable();
            case AuthorAdminDashboardMenu.ADMIN_AUTHORS_BOKBASEN:
                return renderTable();
            default:
                return renderTable();
        }
    }

    function mapResultsToTableData(authors?: Author[]): AuthorTableData[] {
        return (
            authors?.map((author) => ({
                fullName: author.fullName,
                firstName: author.firstName,
                lastName: author.lastName,
                bornInCountry: author.bornInCountry,
                bornInYear: author.bornInYear,
                lastChanged: author.lastChanged?.toDate(),
                roles: author.roles ?? [],
                source: author.source,
                contactInfo: author.contactInfo,
                id: author.id ?? "",
            })) ?? []
        );
    }

    useEffect(() => {
        setAuthorDataList(mapResultsToTableData(data));
    }, [data]);

    return (
        <Container>
            <DashboardHorizontalMenu />
            <Columns pt5>
                <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                    {renderContent()}
                </Column>
            </Columns>
        </Container>
    );
}

function AuthorAdminTableAlgolia() {
    const { selectedMenu } = useDashboard();
    const { results, status } = useInstantSearch();
    const getSource = () => (selectedMenu == AuthorAdminDashboardMenu.ADMIN_AUTHORS_BOKBASEN ? "bokbasen" : "publizm");
    const [authorDataList, setAuthorDataList] = useState<AuthorTableData[]>([]);
    const [loading, setIsLoading] = useState(true); // Prevent infinite loop on algolia

    useEffect(() => {
        if (status != "loading") {
            setIsLoading(false);
        }
    }, [status]);
    useEffect(() => {
        setAuthorDataList(mapResultsToTableData(results.hits));
    }, [results]);

    function renderTable() {
        return <AuthorTable key={selectedMenu} authorTableData={authorDataList} algolia source={getSource()} />;
    }

    function renderContent() {
        if (loading) {
            return <LoadingIndicator />;
        }

        switch (selectedMenu) {
            case AuthorAdminDashboardMenu.ADMIN_AUTHORS:
                return renderTable();
            case AuthorAdminDashboardMenu.ADMIN_AUTHORS_BOKBASEN:
                return renderTable();
            default:
                return renderTable();
        }
    }

    function mapResultsToTableData(authors?: IAuthorAlgolia[]): AuthorTableData[] {
        return (
            authors?.map((author) => ({
                fullName: author.fullName,
                firstName: author.firstName,
                lastName: author.lastName,
                bornInCountry: author.bornInCountry,
                lastChanged: author.lastChanged,
                roles: author.roles ?? [],
                source: author.source,
                contactInfo: author.contactInfo,
                id: author.objectID ?? "",
            })) ?? []
        );
    }

    function createAlgoliaFilterQuery() {
        const source = selectedMenu == AuthorAdminDashboardMenu.ADMIN_AUTHORS_BOKBASEN ? "bokbasen" : "publizm";
        const filter = `source: ${source}`;

        return filter;
    }

    return (
        <>
            <Configure filters={createAlgoliaFilterQuery()} />
            <DashboardHorizontalMenu />
            <Columns pt5>
                <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                    {renderContent()}
                </Column>
            </Columns>
        </>
    );
}
