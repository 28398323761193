import PzButton from "components/form/PzButton";
import { Column, Columns, Container, Content, Control, Field } from "components/trunx";
import { useAuth } from "contexts/AuthContext";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import PzFormInputField from "../../components/form/PzFormInputField";

interface UpdatePassword {
    password: string;
    passwordConfirm: string;
}

export default function UpdatePassword(): JSX.Element {
    const { register, errors, watch, handleSubmit } = useForm<UpdatePassword>({
        mode: "onBlur",
        defaultValues: {
            password: "",
            passwordConfirm: "",
        },
    });

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { updatePassword } = useAuth();
    const [updatePasswordSuccessful, setUpdatePasswordSuccessful] = useState(false);

    const onSubmit = (updatePasswordData) => updateAuthPassword(updatePasswordData.password);

    async function updateAuthPassword(password) {
        setError("");
        setLoading(true);
        updatePassword(password)
            .then(() => {
                setError("");
                setUpdatePasswordSuccessful(true);
            })
            .catch(() => {
                setError("Oppdater feilet. Prøv igjen");
                setUpdatePasswordSuccessful(false);
            })
            .finally(() => setLoading(false));
    }

    return (
        <Container mt5>
            <Columns className={"is-vcentered align-content-center"}>
                <Column id={"updatepassword"} isFlexGrow1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Columns>
                            <Column>
                                <PzFormInputField
                                    label="Passord"
                                    name="password"
                                    type="password"
                                    placeholder="Passord"
                                    formError={errors.password}
                                    inputRef={register({
                                        minLength: {
                                            value: 8,
                                            message: "Passord må ha minst 8 tegn",
                                        },
                                    })}
                                />
                            </Column>
                            <Column>
                                <PzFormInputField
                                    label="Gjenta passord"
                                    name="passwordConfirm"
                                    type="password"
                                    placeholder="Gjenta passord"
                                    formError={errors.passwordConfirm}
                                    inputRef={register({
                                        validate: (value) => value === watch("password") || "Passordene må være like",
                                    })}
                                />
                            </Column>
                        </Columns>
                        <Field isGrouped className="mt-5">
                            <Control>
                                <PzButton
                                    isPrimary
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={loading}
                                    isLoading={loading}
                                >
                                    Oppdater passord
                                </PzButton>
                            </Control>
                        </Field>
                    </form>
                    {updatePasswordSuccessful && (
                        <Content hasTextSuccess>Din oppdatering av passord var vellykket.</Content>
                    )}
                </Column>
            </Columns>
        </Container>
    );
}
