import { Column, Container, Help, Level, Modal } from "components/trunx";
import React, { ReactElement, useState } from "react";

import { UserService } from "../../services/firestore/UserService";
import PzButton from "../form/PzButton";
import PzModal from "../modal/PzModal";

interface DeleteUserButtonProps {
    userUid: string;
    userName: string;
    onSubmit: () => void;
}
export default function DeleteUserButton({ userUid, userName, onSubmit }: DeleteUserButtonProps): ReactElement {
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState<boolean>(false);
    const [isWaitingForDeleteUser, setIsWaitingForDeleteUser] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const onClose = () => {
        setErrorMessage(undefined);
        setDeleteUserModalOpen(false);
    };
    const onOpen = () => setDeleteUserModalOpen(true);
    const onDeleteUser = () => {
        setIsWaitingForDeleteUser(true);
        return new UserService()
            .deleteUser(userUid)
            .then(() => {
                onSubmit();
                onClose();
            })
            .catch(() => {
                setErrorMessage("Det skjedde en feil ved sletting av bruker");
            })
            .finally(() => {
                setIsWaitingForDeleteUser(false);
            });
    };
    return (
        <>
            <PzModal isActive={deleteUserModalOpen} onClose={onClose}>
                <Modal.Card.Head>
                    <Level>
                        <Level.Left>
                            <Level.Item>
                                <Modal.Card.Title>Slett bruker</Modal.Card.Title>
                            </Level.Item>
                        </Level.Left>
                    </Level>
                </Modal.Card.Head>
                <Modal.Card.Body>
                    <Container>
                        <Column>
                            <div>
                                Du er i ferd med å slette bruker med navn <em>{userName}</em>.{" "}
                            </div>
                            <div>Denne handlingen vil slette bruker og all tilhørende data.</div>
                            <br />
                            <strong>NB! Det er ikke mulig å gjenopprette slettet bruker.</strong>
                        </Column>
                        {errorMessage && (
                            <Column>
                                <Help isDanger>{errorMessage}</Help>
                            </Column>
                        )}
                        <Column>
                            <PzButton isDanger onClick={onDeleteUser} isLoading={isWaitingForDeleteUser}>
                                Slett bruker
                            </PzButton>
                            <PzButton isNormal onClick={onClose}>
                                Avbryt
                            </PzButton>
                        </Column>
                    </Container>
                </Modal.Card.Body>
            </PzModal>
            <PzButton isDanger onClick={onOpen}>
                Slett bruker
            </PzButton>
        </>
    );
}
