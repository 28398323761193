import "../SignUpPanel.css";

import PublizmLogo from "components/PublizmLogo";
import { Button, Column, Columns, Control, Field } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AgreementService } from "services/firestore/AgreementService";
import { AgreementType, CreateUserRequest, UserRole } from "shared";

import { PzTitle } from "../../../../components/text/PzTitle";
import { removeKeys, removeNullOrUndefinedKeys } from "../../../../components/utils/ObjectUtils";
import { FirebaseAuthErrorCode, useAuth } from "../../../../contexts/AuthContext";
import { AgreementData } from "../../../../services/firestore/types";
import AgreementCheckbox from "../AgreementCheckbox";
import SignupUserInfoForm from "../SignupUserInfoForm";

export interface SignUpCustomerProps extends CreateUserRequest {
    passwordConfirm: string;
    acceptUserAgreements: boolean;
    acceptConfidentiality: boolean;
}

export default function SignUpCustomerPanel(): ReactElement {
    const methods = useForm<SignUpCustomerProps>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: {
            firstname: "",
            surname: "",
            email: "",
            telephoneNumber: "",
            password: "",
            passwordConfirm: "",
            acceptUserAgreements: false,
            acceptConfidentiality: false,
        },
    });

    const navigate = useNavigate();
    const { signup } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { handleSubmit } = methods;
    const [latesUserAgreements, setLatestUserAgreements] = useState<AgreementData[]>([]);

    useEffect(() => {
        new AgreementService().getLatestRoleAgreements(UserRole.CUSTOMER).then(setLatestUserAgreements);
    }, []);

    const onSubmit = (data) => handleSubmitSignUp(data);

    async function handleSubmitSignUp(data) {
        setError("");
        const persistData: CreateUserRequest = {
            ...data,
            createUserType: UserRole.CUSTOMER,
        };

        const signupDataWithRemovedKeys: CreateUserRequest = removeKeys(persistData, [
            "passwordConfirm",
            "acceptAgreements",
            "acceptConfidentiality",
        ]);

        const signupData: CreateUserRequest = removeNullOrUndefinedKeys(signupDataWithRemovedKeys) as CreateUserRequest;
        signupData.role = UserRole.CUSTOMER;
        setLoading(true);

        await signup(signupData)
            .then(() => navigate("/"))
            .catch((err) => {
                if (err?.code === FirebaseAuthErrorCode.EMAIL_EXISTS) {
                    setError("Epost er allerede i bruk");
                } else {
                    setError("Det skjedde en feil. Vennligst prøv på nytt");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Columns className={"is-vcentered align-content-center signup-form"}>
            <Column isFlex isJustifyContentCenter className={"is-hidden-tablet-only is-hidden-mobile"}>
                <PublizmLogo isLink={false} width={125} height={169} />
            </Column>
            <Column id={"signup"} isFlexGrow1 m0 p2>
                <Column mb0 pb0>
                    <PzTitle>Registrering</PzTitle>
                    {error && <div className="notification is-danger"> {error} </div>}
                </Column>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Column>
                            <SignupUserInfoForm telephoneNumberRequired={false} />
                            <AgreementCheckbox
                                agreementType={AgreementType.USER_TERMS}
                                role={UserRole.CUSTOMER}
                                name="acceptContentAgreements"
                                requiredMessage="Du må akseptere brukervilkårene"
                                agreementDescription="Publizms brukervilkår"
                            />
                            <div className={"mt-3"}>
                                <AgreementCheckbox
                                    agreementType={AgreementType.PRIVACY}
                                    role={UserRole.CUSTOMER}
                                    name="acceptUserAgreements"
                                    requiredMessage="Du må akseptere vilkårene for personvern"
                                    agreementDescription="vilkårene for personvern"
                                />
                            </div>
                            <Field isGrouped className="mt-5">
                                <Control>
                                    <Button
                                        isPrimary
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={loading}
                                        isLoading={loading}
                                    >
                                        Registrer
                                    </Button>
                                </Control>
                            </Field>
                        </Column>
                    </form>
                </FormProvider>
            </Column>
        </Columns>
    );
}
