export type FirebaseDataType = ArrayBuffer | Blob | Uint8Array;
export interface FirebaseUploadFile {
    data: FirebaseDataType;
    name: string;
    type: string;
    metadata?: Record<string, string>;
    filePath?: string;
    fileUrl?: string;
}
export type FirebaseStorageMetadata = { [key: string]: string } | {};
/**
 * Total number of subfiles must be even number (1,2,4,6...)
 */
export enum FirebaseStorageFolder {
    AUTHOR_FILES = "author/files",
    BOOKS = "books",
    AUTHORS = "authors",
}

export enum FirebaseStorageErrorTypes {
    UNAUTHORIZED = "storage/unauthorized",
    UNAUTHENTICATED = "storage/unauthenticated",
    CANCELED = "storage/canceled",
}

export type FilePath = string;

//For storage
export interface FirebaseFileMetadata {
    id: string;
    filePath: string;
    name: string;
    uploadDate: Date;
    type: string;
    size: number;
    fileUrl?: string;
}
