import {
    collectionGroup,
    getDocs,
    limit,
    orderBy,
    query,
    QueryFieldFilterConstraint,
    Timestamp,
    where,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { BookSource, FirestoreCollection, Transaction } from "shared";

import { firestore } from "../../../firebase";
import { mapQueryResult, QueryReturnType } from "../queries/queriesCommon";

export default function useTransactionApi() {
    return {
        getTransactions: (source?: BookSource, fromDate?: Timestamp): QueryReturnType<Transaction[]> => {
            return useQuery({
                queryKey: ["transactions", source ?? "all", "date", fromDate?.toMillis() ?? "start"],
                queryFn: () => {
                    const contsraints: QueryFieldFilterConstraint[] = [];
                    if (source) contsraints.push(where("booksource", "==", source));
                    return getDocs(
                        query(
                            collectionGroup(firestore, FirestoreCollection.TRANSACTIONS),
                            ...contsraints,
                            orderBy("timestamp", "desc"),
                            limit(100)
                        )
                    );
                },
                select: mapQueryResult<Transaction>(),
                suspense: false,
                keepPreviousData: true,
            });
        },
    };
}
