import { Column } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";

import { SearchField } from "../search/SearchField";
import { filterBySearchValue } from "../utils/SearchUtils";

export interface PzTableCustomSearch {
    defaultSearchValue?: string;
    onSearchChange: (value: string) => void;
}
interface TableSearchProps<T> {
    originalData?: T[];
    searchData?: (data: T) => string;
    onFilteredDataChange: (filteredData: T[]) => void;
    customSearch?: PzTableCustomSearch;
}
export default function TableSearch<T>(props: TableSearchProps<T>): ReactElement | null {
    const [searchPattern, setSearchPattern] = useState<string>(props.customSearch?.defaultSearchValue || "");

    useEffect(() => {
        if (props.customSearch) {
            return;
        }
        if (!props.searchData || !props.originalData) {
            return;
        }
        props.onFilteredDataChange(
            props.originalData.filter((data: T) => {
                const searchData = props.searchData!(data);
                return filterBySearchValue(searchPattern, searchData);
            })
        );
    }, [searchPattern, props.originalData]);

    useEffect(() => {
        if (props.customSearch) {
            props.customSearch.onSearchChange(searchPattern ?? "");
            return;
        }
    }, [searchPattern]);

    if (!props.searchData && !props.customSearch) {
        return null;
    }
    return (
        <Column pb0 mb0 isFlex isJustifyContentFlexEnd>
            <SearchField defaultValue={props.customSearch?.defaultSearchValue} onChange={setSearchPattern} />
        </Column>
    );
}
