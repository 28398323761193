import { Timestamp } from "firebase/firestore";

import { OrderType } from "../../services/firestore/types";

export function sortByDate(
    aDate?: Timestamp | Date,
    bDate?: Timestamp | Date,
    sortOrder: OrderType = OrderType.DESC
): number {
    if (!bDate) {
        return 1;
    }
    if (!aDate) {
        return -1;
    }
    if (aDate instanceof Timestamp && bDate instanceof Timestamp) {
        return sortOrder === OrderType.ASC ? aDate.toMillis() - bDate.toMillis() : bDate.toMillis() - aDate.toMillis();
    }
    if (aDate instanceof Date && bDate instanceof Date) {
        return sortOrder === OrderType.ASC
            ? aDate.getMilliseconds() - bDate.getMilliseconds()
            : bDate.getMilliseconds() - aDate.getMilliseconds();
    }

    return 1;
}
