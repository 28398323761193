import { ReactElement, useEffect, useState } from "react";
import { BookStatus } from "shared";

import PzSelectDropdown, { OptionProps } from "../../../../../components/PzSelectDropdown";
import { BookService } from "../../../../../services/firestore/BookService";

interface StateDropdownProps {
    status?: BookStatus;
    bookId: string;
}

export const bookStateOptions: OptionProps<BookStatus>[] = [
    {
        value: BookStatus.HIDDEN,
        label: "Skjult",
    },
    {
        value: BookStatus.PUBLISHED,
        label: "Publisert",
    },
];

export default function BookStateDropdown(props: StateDropdownProps): ReactElement {
    const [selectedOption, setSelectedOption] = useState<OptionProps<BookStatus> | null>(null);

    useEffect(() => {
        setSelectedOption(bookStateOptions.find((b) => b.value == props.status) ?? null);
    }, [props.status]);

    function onOptionSelected(selected: BookStatus) {
        if (!selected) {
            return;
        }

        new BookService().updateBookVariant({ status: selected }, props.bookId);
    }

    return <PzSelectDropdown defaultOption={selectedOption} options={bookStateOptions} onChanged={onOptionSelected} />;
}
