import { AppPath } from "common/AppPath";
import { Column } from "components/trunx";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import PzButton from "../../components/form/PzButton";
import { FormSteps, useUpload } from "../../contexts/UploadContext";

export default function UploadPageButtons(): ReactElement {
    const { currentStep, prev, next, finish } = useUpload();
    const navigate = useNavigate();
    const getSecondaryProps = () => {
        if (currentStep === 1) {
            return {
                label: "Avbryt",
                onClick: () => navigate(AppPath.ROOT),
            };
        }
        return {
            label: "Tilbake",
            onClick: prev,
        };
    };

    const getPrimaryButtonProps = () => {
        if (currentStep === FormSteps.PITCH) {
            return {
                label: "Ferdig",
                onClick: finish,
            };
        }
        return {
            label: "Neste",
            onClick: next,
        };
    };

    return (
        <Column isMarginLess pb3 pt3 isFlex isJustifyContentSpaceAround className="whiteBanner">
            <Column isFlex isJustifyContentSpaceAround isTwoFifths>
                {currentStep === FormSteps.FINISHED ? (
                    <PzButton isPrimary onClick={() => navigate(AppPath.DASHBOARD)}>
                        Gå til min oversikt
                    </PzButton>
                ) : (
                    <>
                        <PzButton isSecondary {...getSecondaryProps()} />
                        <PzButton isPrimary {...getPrimaryButtonProps()} />
                    </>
                )}
            </Column>
        </Column>
    );
}
