import { Title, TitleProps } from "components/trunx";
import { PropsWithChildren } from "react";

export function PzTitle(props: PropsWithChildren<TitleProps>) {
    const { children, ...otherProps } = props;
    return (
        <Title {...otherProps} is3>
            {children}
        </Title>
    );
}
