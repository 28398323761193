import { Timestamp } from "firebase-admin/firestore";

import { Agreement, UserAgreement } from "./AgreementTypes";
import { CustomerBook } from "./CustomerBookTypes";
import { FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";
export enum UserRole {
    AUTHOR = "AUTHOR",
    EDITOR = "EDITOR",
    ADMIN = "ADMIN",
    CUSTOMER = "CUSTOMER",
}

export function createNewUserAgreement(
    agreement: Agreement,
    agreementId: string,
    // This is needed to be able to use this on firebase functions
    approvedDate: Timestamp
): UserAgreement {
    return {
        agreementType: agreement.agreementType,
        documentId: agreementId,
        approvedDate: approvedDate,
    };
}
export function getActiveRole(userRoles?: UserRole[]): UserRole {
    if (!userRoles || userRoles.length === 0) {
        return UserRole.CUSTOMER;
    }

    if (userRoles.includes(UserRole.ADMIN)) {
        return UserRole.ADMIN;
    } else if (userRoles.includes(UserRole.EDITOR)) {
        return UserRole.EDITOR;
    } else if (userRoles.includes(UserRole.AUTHOR)) {
        return UserRole.AUTHOR;
    } else if (userRoles.includes(UserRole.CUSTOMER)) {
        return UserRole.CUSTOMER;
    }

    return UserRole.CUSTOMER;
}

export const userRoleDisplayValue = (role?: UserRole): string => {
    switch (role) {
        case UserRole.ADMIN:
            return "Administrator";
        case UserRole.AUTHOR:
            return "Forfatter";
        case UserRole.EDITOR:
            return "Redaktør";
        case UserRole.CUSTOMER:
            return "Kunde";
        default:
            return "";
    }
};

export const userRoleInternalName = (role?: string): UserRole | undefined => {
    switch (role?.toLowerCase()) {
        case "administrator":
            return UserRole.ADMIN;
        case "redaktør":
            return UserRole.EDITOR;
        case "forfatter":
            return UserRole.AUTHOR;
        case "kunde":
            return UserRole.CUSTOMER;
        default:
            return undefined;
    }
};

export interface UserClaims {
    roles: UserRole[];
}

export interface UpdateEmail {
    email: string;
}

export interface CreateUserVippsRequest extends UserInfo {
    createUserType: UserRole;
    sub: string;
    role?: UserRole;
}

export interface CreateUserRequest extends UserInfo {
    password?: string;
    createUserType: UserRole;
    role?: UserRole;
}

export interface DeleteUserRequest {
    userUid: string;
}

export interface UserInfoMetadata {
    name: string;
    email: string;
    telephoneNumber?: string;
}

export type LoginProviderType = "vipps" | "email";
export interface LoginProvider {
    type: LoginProviderType;
    providerId: string;
}
interface UserInfoBase extends UserInfoMetadata, PzFirestoreBase {
    firstname: string;
    surname: string;
    fullname?: string;
    bokskyaId?: string;
    loginProvider?: LoginProvider[];
    providerIds?: string[];
    isBokskyaAccountActive?: boolean;
    companyName?: string;
    companyId?: number;
    accountnumber?: string;
    tags?: string[];
    photoPath?: string;
    assignments?: number;
    agreements?: UserAgreement[];
    roles?: UserRole[];
    createdDate?: Timestamp;
    lastSignInTime?: Timestamp;
    books?: CustomerBook[];
    emailKindle?: string;
}

export type UserInfo = UserInfoBase;
export const UserInfoLatestVersion = 1;

type AllUserInfo = UserInfoBase;

export class UserInfoVersionMapper extends FirestoreVersionMapper<UserInfo, UserInfoBase> {
    public latestVersion = UserInfoLatestVersion;
    public mapToLatest(userInfo: AllUserInfo): UserInfo {
        if (userInfo.version === UserInfoLatestVersion) {
            return userInfo;
        }

        switch (userInfo.version) {
            case undefined:
                return {
                    ...userInfo,
                    version: UserInfoLatestVersion,
                };
            default:
                throw new Error(`Mapper for userInfo version ${userInfo["version"]} not implemented`);
        }
    }
}
