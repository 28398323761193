import "./PzButton.css";

import { Button, ButtonProps } from "components/trunx";

export interface PzButtonProps extends ButtonProps {
    isSecondary?: boolean;
    label?: string;
}

export default function PzButton(props: PzButtonProps) {
    const { label, isSecondary, ...buttonProps } = props;
    const propsClassName = props.className ? props.className : "";
    return (
        <Button {...buttonProps} className={`${isSecondary ? "is-secondary" : ""} ${propsClassName} pz-button`}>
            {props.children ?? label}
        </Button>
    );
}
