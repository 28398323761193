import { Timestamp } from "firebase-admin/firestore";

import { Book, BookSource, BookType, Language } from ".";
import { FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";

export type CustomerBook = CustomerBookBase;
export const CustomerBookLatestVersion = 1;

type AllCustomerBook = CustomerBookBase;

/* This interface should only be used for user with Role: CUSTOMER */
export interface CustomerBookBase extends PzFirestoreBase {
    bookId: string;
    bookType: BookType;
    favorite?: boolean;
    purchased?: boolean;
    title: string;
    authorName: string;
    author: {
        profileId: string;
        name: string;
        description: string;
        profilePicturePath: string;
    };
    pages?: number;
    summary: string;
    type: BookType;
    language: Language;
    coverPath?: string;
    tags: string[];
    publisher: string;
    updatedDate?: Timestamp;
    source?: BookSource;
}

export class CustomerBookVersionMapper extends FirestoreVersionMapper<CustomerBook, AllCustomerBook> {
    public latestVersion = CustomerBookLatestVersion;
    public mapToLatest(book: AllCustomerBook): CustomerBook {
        if (book.version === CustomerBookLatestVersion) {
            return book;
        }

        switch (book.version) {
            case undefined:
                return {
                    ...book,
                    version: CustomerBookLatestVersion,
                };
            default:
                throw new Error(`Mapper for customer book version ${book["version"]} not implemented`);
        }
    }
}

export function createInitialCustomerBookData(bookId: string, book: Book, type: BookType): CustomerBook {
    const coverPath = type == BookType.EBOOK ? book.ebook?.coverPath : book.audiobook?.coverPath;
    return {
        bookId: bookId,
        bookType: type,
        title: book.title,
        authorName: "",
        author: {
            ...book.author,
        },
        type: type,
        summary: book.summary ?? book.shortSummary,
        coverPath: coverPath ?? book.coverPath ?? null,
        tags: book.tags,
        language: book.language,
        publisher: book.publisher,
    };
}
