import "./PWAPrompt.css";

import React, { useEffect, useState } from "react";

import { HomeScreenIcon, ShareIcon } from "./ShareIcon";

const copyTitle = "Installer App";
const copyBody =
    "Denne siden har app-funksjonalitet. Legg til din på din hjemskjerm for å bruke i offline-modus. Du må gjøre følgende i Safari:";
const copyAddHomeButtonLabel = "2) Trykk på 'Legg til på Hjemskjerm'";
const copyShareButtonLabel = "1) Trykk på 'Del' knappen";
const PWAPrompt = ({ delay, copyClosePrompt, permanentlyHideOnDismiss, promptData, maxVisits, onClose }) => {
    const [isVisible, setVisibility] = useState(!delay);

    useEffect(() => {
        if (delay) {
            setTimeout(() => {
                // Prevent keyboard appearing over the prompt if a text input has autofocus set
                if (document.activeElement) {
                    //@ts-ignore
                    document.activeElement.blur();
                }

                setVisibility(true);
            }, delay);
        }
    }, []);

    useEffect(() => {
        if (isVisible) {
            document.body.classList.add("noScroll");
        }
    }, [isVisible]);

    const isiOS13AndUp = /OS (13|14)/.test(window.navigator.userAgent);
    const visibilityClass = isVisible ? "visible" : "hidden";
    const iOSClass = isiOS13AndUp ? "modern" : "legacy";

    const dismissPrompt = (evt) => {
        document.body.classList.remove("noScroll");
        setVisibility(false);

        if (permanentlyHideOnDismiss) {
            localStorage.setItem(
                "iosPwaPrompt",
                JSON.stringify({
                    ...promptData,
                    visits: maxVisits,
                })
            );
        }

        if (typeof onClose === "function") {
            onClose(evt);
        }
    };

    const onTransitionOut = (evt) => {
        if (!isVisible) {
            evt.currentTarget.style.display = "none";
        }
    };

    return (
        <>
            <div
                className={`pwaPromptOverlay ${visibilityClass} ${iOSClass} iOSPWA-overlay`}
                aria-label="Close"
                role="button"
                onClick={dismissPrompt}
                onTransitionEnd={onTransitionOut}
            />
            <div
                className={`pwaPrompt ${visibilityClass} ${iOSClass} iOSPWA-container`}
                aria-describedby="pwa-prompt-description"
                aria-labelledby="pwa-prompt-title"
                role="dialog"
                onTransitionEnd={onTransitionOut}
            >
                <div className={`pwaPromptHeader iOSPWA-header`}>
                    <p id="pwa-prompt-title" className={`pwaPromptTitle`}>
                        {copyTitle}
                    </p>
                    <button className={`pwaPromptCancel iOSPWA-cancel`} onClick={dismissPrompt}>
                        {copyClosePrompt}
                    </button>
                </div>
                <div className={`pwaPromptBody iOSPWA-body`}>
                    <div className={`pwaPromptDescription iOSPWA-description`}>
                        <p
                            id="pwa-prompt-description"
                            className={`pwaPromptCopy pwaPromptTitle iOSPWA-description-copy`}
                        >
                            {copyBody}
                        </p>
                    </div>
                </div>
                <div className={`pwaPromptInstruction iOSPWA-steps`}>
                    <div className={`pwaPromptInstructionStep iOSPWA-step1`}>
                        <ShareIcon className={`pwaPromptShareIcon iOSPWA-step1-icon`} />
                        <p className={`pwaPromptCopy bold iOSPWA-step1-copy`}>{copyShareButtonLabel}</p>
                    </div>
                    <div className={`pwaPromptInstructionStep iOSPWA-step2`}>
                        <HomeScreenIcon className={`pwaPromptHomeIcon iOSPWA-step2-icon`} modern={isiOS13AndUp} />
                        <p className={`pwaPromptCopy bold iOSPWA-step2-copy`}>{copyAddHomeButtonLabel}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PWAPrompt;
