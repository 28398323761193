import { Container } from "components/trunx";

import authorAvatar from "../../../assets/absurd_face.png";
import { getStorageAuthorProfilepictureUrl } from "../../../services/firestore/hooks/useBookApi";

interface BookCoverImageProps {
    coverPath?: string;
    lastChanged?: Date;
    isSmall?: boolean;
    hasNoSpecifiedRatio?: boolean;
}
export default function AuthorImage({ coverPath, isSmall, hasNoSpecifiedRatio, lastChanged }: BookCoverImageProps) {
    const coverUrl = coverPath ? getStorageAuthorProfilepictureUrl(coverPath, lastChanged) : null;

    return (
        <Container
            className="author-image"
            isFlex
            isAlignItemsCenter
            style={
                hasNoSpecifiedRatio
                    ? {}
                    : { minHeight: isSmall ? "200px" : "280px", minWidth: isSmall ? "100%" : "280px" }
            }
        >
            {coverUrl ? (
                <img src={coverUrl} width={"100%"} className="p-5" alt="placeholder image" />
            ) : (
                <img
                    src={authorAvatar}
                    width={"100%"}
                    className="p-5"
                    alt="placeholder image"
                    style={{ background: "var(--almond-100)" }}
                />
            )}
        </Container>
    );
}
