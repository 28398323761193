import "./PzPaginate.css";

import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column, Container, Level, Select } from "components/trunx";
import React, { useEffect, useState } from "react";

import PzText from "../text/PzText";

export interface PzCustomPagination {
    onInit: (pageSize: number) => void;
    onPageChange: (pagenumber: number) => void;
    onPageSizeChange: (pagesize: number) => void;
    totalPages: number;
    totalElements: number;
}
interface PzPaginateProps<T> {
    data: T[];
    viewedData: T[];
    onPaginationChange: (paginatedData: T[]) => void;
    pageOptions?: number[];
    defaultPagination?: number;
    customPagination?: PzCustomPagination;
}

export default function PzPaginate<T>(props: PzPaginateProps<T>) {
    const { pageOptions = [5, 10, 15, 20, 100], defaultPagination = 20, customPagination } = props;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [numberOfRowsPerPage, setNumberOfRowsPerPage] = useState<number>(defaultPagination);
    function onPaginationChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const value = Number(event.target.value);
        setNumberOfRowsPerPage(value);
        setCurrentPage(1);
    }

    function getMaxNumberOfPages() {
        return props.customPagination?.totalPages ?? Math.ceil(props.viewedData.length / numberOfRowsPerPage);
    }

    function prevPage() {
        const numberOfPages = getMaxNumberOfPages();
        setCurrentPage((prevVal) => Math.max(1, Math.min(prevVal - 1, numberOfPages)));
    }

    function nextPage() {
        const numberOfPages = getMaxNumberOfPages();
        setCurrentPage((prevVal) => Math.min(numberOfPages, prevVal + 1));
    }

    useEffect(() => {
        customPagination?.onPageChange(currentPage);
    }, [currentPage]);

    useEffect(() => {
        customPagination?.onPageSizeChange(numberOfRowsPerPage);
    }, [numberOfRowsPerPage]);

    useEffect(() => {
        customPagination?.onInit(numberOfRowsPerPage);
    }, []);

    useEffect(() => {
        const numberOfPages = getMaxNumberOfPages();
        currentPage > numberOfPages && prevPage();
    }, [props.viewedData]);

    useEffect(() => {
        const dataLength = props.viewedData.length;

        props.onPaginationChange(
            props.viewedData.slice(
                Math.min(dataLength, numberOfRowsPerPage * (currentPage - 1)),
                Math.min(dataLength, numberOfRowsPerPage * currentPage)
            )
        );
    }, [currentPage, numberOfRowsPerPage, props.viewedData]);

    const totalElements = props.customPagination?.totalElements ?? props.data.length;
    const totalViewedElements = props.customPagination
        ? props.customPagination?.totalElements
        : props.viewedData.length;
    const viewedDataStartIndex = Math.min(totalViewedElements, numberOfRowsPerPage * (currentPage - 1) + 1);
    const viewedDataEndIndex = Math.min(totalViewedElements, viewedDataStartIndex - 1 + numberOfRowsPerPage);
    const filteredTotalInfo =
        totalElements !== totalViewedElements ? `(filtret fra totalt ${totalElements} elementer)` : "";

    if (totalViewedElements === 0) {
        return null;
    }
    return (
        <Container>
            <Level isFlex isPulledRight className="pz-paginate">
                <Level.Right isFlex style={{ width: "100%" }}>
                    <Container isFlex isFlexDirectionRow pr0 ml0>
                        <PzText
                            style={{ width: "120px", paddingTop: "0.4rem" }}
                            containerProps={{ mr2: true, pr0: true }}
                        >
                            Rader per side:
                        </PzText>
                        <Select p0 mr0 pr0 onChange={onPaginationChange} defaultValue={defaultPagination}>
                            {pageOptions.map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </Select>
                    </Container>
                    <PzText
                        pl0
                        ml0
                        containerProps={{ ml0: true, pl0: true }}
                    >{`${viewedDataStartIndex} - ${viewedDataEndIndex} av ${totalViewedElements} ${filteredTotalInfo}`}</PzText>
                    <Container isFlex style={{ gap: "10px" }} pl0 ml0 ml2>
                        <Column
                            isClickable
                            onClick={prevPage}
                            className={`${currentPage === 1 ? "disabled" : ""} paginate_button`}
                            pl0
                            ml0
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Column>
                        <Column
                            isClickable
                            onClick={nextPage}
                            className={`${currentPage === getMaxNumberOfPages() ? "disabled" : ""} paginate_button`}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Column>
                    </Container>
                </Level.Right>
            </Level>
        </Container>
    );
}
