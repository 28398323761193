import "./useradminform.css";

import ForgotPassword, { ForgotPasswordProps } from "components/auth/ForgotPassword";
import PageTemplate from "components/pagetemplate/PageTemplate";
import PzText from "components/text/PzText";
import { PzTitle } from "components/text/PzTitle";
import { Column, Columns, Container } from "components/trunx";
import { useState } from "react";
import { Link } from "react-router-dom";

import { AppPath } from "../../../common/AppPath";

export default function ForgotPasswordPage() {
    const [viewForgotPassword, setViewForgotPassword] = useState<boolean>(true);
    return (
        <PageTemplate>
            <Container>
                <Columns isAlignItemsCenter isJustifyContentCenter>
                    <Column isNarrow className="useradmin-form">
                        {viewForgotPassword ? (
                            <>
                                <PzTitle>Glemt passord</PzTitle>
                                <ForgotPassword
                                    changeViewComponent={(viewState: boolean) => setViewForgotPassword(viewState)}
                                />
                                <Column hasTextCentered>
                                    Trenger du en konto? <Link to={AppPath.SIGNUP}>Registrer deg</Link>
                                </Column>
                            </>
                        ) : (
                            <RenderForgotPasswordFeedback
                                changeViewComponent={(viewState: boolean) => setViewForgotPassword(viewState)}
                            />
                        )}
                    </Column>
                </Columns>
            </Container>
        </PageTemplate>
    );
}

function RenderForgotPasswordFeedback({ changeViewComponent }: ForgotPasswordProps) {
    return (
        <Column style={{ maxWidth: "600px" }}>
            <PzText>
                <p>Instruksjoner er nå sendt til deg!</p>
                <p>
                    Vi har sendt deg instruksjoner på e-post om hvordan du får deg et nytt passord. Den bør være å finne
                    i din mailboks snart.
                </p>
                <p>Har du ikke fått e-post?</p>
                <p>
                    Undersøk søppelpost-mappen din og forsikre deg om at du ikke er registrert med en annen
                    e-postadresse enn den du fylte inn i feltet. Hvis du tror du har skrevet feil adresse,
                    <a onClick={() => changeViewComponent && changeViewComponent(true)}> gå tilbake</a>
                </p>
            </PzText>
        </Column>
    );
}
