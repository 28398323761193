import * as Sentry from "@sentry/react";

const sentryEnabled: boolean = import.meta.env.VITE_SENTRYDSN != null;

const Logger = {
    info: function (arg: string): void {
        if (sentryEnabled) {
            Sentry.captureMessage(arg);
        }
        console.log(arg);
    },
    error: function (arg: string, err?: Error): void {
        if (sentryEnabled) {
            Sentry.captureException(err ? { ...err, message: arg } : new Error(arg));
        }
        console.error(arg, err);
    },
};

export default Logger;
