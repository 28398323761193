import { StoryblokComponent } from "@storyblok/react";
import { Container } from "components/trunx";
import React from "react";
import { ReactElement } from "react";

import PageTemplate from "../../components/pagetemplate/PageTemplate";
import StoryblokWrapper from "../../components/storyblok/StoryblokWrapper";
import { PageItem } from "./types";

interface StoryblokComponentsWrapperProps {
    slug: string;
}
export default function StoryblokPageItemWrapper({ slug }: StoryblokComponentsWrapperProps): ReactElement {
    return (
        <PageTemplate className="sbpage" renderSection={true} marginLessTop={true}>
            <Container className="sbcomponents">
                <StoryblokWrapper slug={slug}>
                    {(val: PageItem) => {
                        return (
                            <>
                                {val.content.body.map((b, index) => (
                                    <StoryblokComponent blok={b} key={b._uid} />
                                ))}
                            </>
                        );
                    }}
                </StoryblokWrapper>
            </Container>
        </PageTemplate>
    );
}
