import PageTemplate from "components/pagetemplate/PageTemplate";
import { useAuth } from "contexts/AuthContext";
import { UserRole } from "shared";

import AuthorEditProfile from "./AuthorEditProfile";
import CustomerEditProfile from "./CustomerEditProfile";
import EditorEditProfile from "./EditorEditProfile";

export default function EditProfilePage() {
    const { userInfo, activeRole } = useAuth();

    function getEditProfilePage() {
        switch (activeRole) {
            case UserRole.AUTHOR:
                return <AuthorEditProfile userInfo={userInfo} />;
            case UserRole.EDITOR:
                return <EditorEditProfile userInfo={userInfo} />;
            case UserRole.CUSTOMER:
                return <CustomerEditProfile userInfo={userInfo} />;
        }
    }

    return <PageTemplate>{getEditProfilePage()}</PageTemplate>;
}
