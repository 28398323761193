import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import React, { ReactElement } from "react";

import PzIconButton from "../form/PzIconButton";
import { SortOrder } from "./PzTable";
import PzTooltip from "./PzTooltip";
export interface ColumnData
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement> {
    label: string | ReactElement;
    tooltip?: ReactElement;
    sortByProperty?: string;
    customComparator?: (a: any, b: any) => number;
}

interface TableHeadProps {
    columns: ColumnData[];
    onSortClick: (colIndex: number) => void;
    sortOrder: SortOrder;
    sortColumnIndex?: number;
}

export default function TableHead(props: TableHeadProps): ReactElement {
    return (
        <thead>
            <tr>
                {props.columns.map((column, index) => (
                    <Column
                        {...column}
                        label={column.label}
                        onSort={
                            column.sortByProperty || column.customComparator
                                ? () => props.onSortClick(index)
                                : undefined
                        }
                        sortDirection={props.sortColumnIndex === index ? props.sortOrder : undefined}
                        key={"col_" + index}
                    />
                ))}
            </tr>
        </thead>
    );
}

interface ColumnProps extends ColumnData {
    sortDirection?: SortOrder;
    label: string | ReactElement;
    onSort?: () => void;
}

function Column(props: ColumnProps): ReactElement {
    const { sortDirection, customComparator, sortByProperty, label, tooltip, onSort, ...colProps } = props;
    const sortable: boolean = sortByProperty || customComparator ? true : false;

    function sortButton() {
        if (!sortable) {
            return null;
        }

        if (!sortDirection) {
            return (
                <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
                    <PzIconButton style={{ marginBottom: "-15px" }} size="xs" icon={faSortUp} isSmall />
                    <PzIconButton size="xs" icon={faSortDown} isSmall />
                </div>
            );
        }

        return <PzIconButton size="xs" icon={sortDirection === "asc" ? faSortDown : faSortUp} isSmall />;
    }

    return (
        <th {...colProps} onClick={props.onSort} className={sortable ? "sortable" : undefined}>
            <div className="header-content">
                {tooltip && <PzTooltip content={tooltip} />}
                <div className="header-label">{label}</div>
                {sortButton()}
            </div>
        </th>
    );
}
