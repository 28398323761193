import { httpsCallable } from "firebase/functions";
import { useRef, useState } from "react";

import PzButton from "../../../components/form/PzButton";
import { functions, functionsEuropeNorth } from "../../../firebase";
import SelectYearDropdown from "./SelectYearDropdown";
export default function RoyaltyReportButton() {
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [loading, setLoading] = useState<boolean>(false);
    const exportReport = useRef(httpsCallable(functionsEuropeNorth, "exportRoyaltiesReport"));

    async function exportAndDonwloadReport() {
        setLoading(true);
        const result = await exportReport.current({ year: selectedYear });
        const csvContent = "data:text/csv;charset=utf-8," + result.data;
        const link = document.createElement("a");
        link.download = `royalty_rapport_${selectedYear}.csv`;
        link.href = csvContent;
        link.click();
        setLoading(false);
    }
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", gap: "5px", width: "100%" }}>
            <SelectYearDropdown onChange={setSelectedYear} />
            <PzButton onClick={exportAndDonwloadReport} isLoading={loading}>
                Last ned rapport
            </PzButton>
        </div>
    );
}
