import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PageTemplate from "components/pagetemplate/PageTemplate";
import { Column, Columns, Title } from "components/trunx";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Licensee } from "shared";

import { AppPath } from "../../../../../common/AppPath";
import PzButton from "../../../../../components/form/PzButton";
import PzFormInputField from "../../../../../components/form/PzFormInputField";
import PzIconButton from "../../../../../components/form/PzIconButton";
import { validateKontonummerMod11 } from "../../../../../components/utils/AccountNumberUtils";
import { LicenseeService } from "../../../../../services/firestore/LicenseeService";
import UserSelect from "./UserSelect";

export default function LicenseeEditorPage() {
    return (
        <PageTemplate marginLessTop>
            <LicenseeForm />
        </PageTemplate>
    );
}

export interface LicsenseeFormProps {
    name: string;
    email?: string;
    accountnumber?: string;
    orgnumber?: string;
    connectedUserId?: string;
}

function LicenseeForm() {
    const { state } = useLocation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useNavigate();

    const methods = useForm<LicsenseeFormProps>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: {
            name: state?.licensee.name,
            email: state?.licensee.email,
            accountnumber: state?.licensee.accountnumber,
            orgnumber: state?.licensee.orgnumber,
            connectedUserId: state?.licensee.connectedToUser,
        },
    });

    const { register, handleSubmit, errors } = methods;

    function toLicenseeDashboard() {
        navigate(AppPath.ADMIN_LICENSEE_DASHBOARD);
    }

    async function onSubmit(data: LicsenseeFormProps) {
        try {
            setIsSubmitting(true);
            const licenseeData: Partial<Licensee> = {
                name: data.name,
                email: data.email,
                accountnumber: data.accountnumber,
                orgnumber: data.orgnumber,
                connectedToUser: data.connectedUserId,
                //TODO: Add phone number
            };
            await new LicenseeService().saveOrUpdate(licenseeData, state?.documentId);
            navigate(AppPath.ADMIN_LICENSEE_DASHBOARD, {});
        } finally {
            setIsSubmitting(false);
        }
    }

    function validateAccountNumber(accountNumber: number) {
        if (!accountNumber) {
            return true;
        }
        if (!validateKontonummerMod11(String(accountNumber))) {
            return "Ugyldig kontonummer";
        }
        return true;
    }

    return (
        <Columns isFlex isFlexDirectionRow p6>
            <Column is1Desktop is1Tablet is2Widescreen isHiddenMobile>
                <PzIconButton isSmall onClick={toLicenseeDashboard} icon={faArrowLeft} />
            </Column>
            <Column isFlex isFlexDirectionColumn isAlignContentFlexStart isFlexGrow1 is8Desktop>
                <Column isPaddingLess pb4 isHiddenTablet isFlex isAlignContentFlexStart>
                    <PzIconButton isSmall onClick={toLicenseeDashboard} icon={faArrowLeft} />
                </Column>
                <FormProvider {...methods}>
                    <form className="authoradmin" onSubmit={handleSubmit(onSubmit)}>
                        <Title pb6>Rettighetshaver</Title>
                        <Column is3>
                            <PzFormInputField type="text" label="Navn" name="name" inputRef={register()} />
                            <PzFormInputField type="text" label="Kontaktinfo" name="email" inputRef={register()} />
                            <PzFormInputField
                                type="number"
                                label="Organisasjonsnummer"
                                name="orgnumber"
                                inputRef={register()}
                            />
                            <PzFormInputField
                                label="Kontonummer"
                                name="accountnumber"
                                type="number"
                                formError={errors.accountnumber}
                                inputRef={register({
                                    valueAsNumber: true,
                                    validate: validateAccountNumber,
                                })}
                            />{" "}
                            <UserSelect />
                        </Column>
                        <Column>
                            <PzButton isLoading={isSubmitting} disabled={isSubmitting} isPrimary>
                                Oppdater
                            </PzButton>
                        </Column>
                    </form>
                </FormProvider>
            </Column>
        </Columns>
    );
}
