import "../SignUpPanel.css";

import PublizmLogo from "components/PublizmLogo";
import { Button, Column, Columns, Control, Field } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AgreementType, CreateUserRequest, UserRole } from "shared";

import { AppPath } from "../../../../common/AppPath";
import { PzTitle } from "../../../../components/text/PzTitle";
import { removeKeys } from "../../../../components/utils/ObjectUtils";
import { FirebaseAuthErrorCode, useAuth } from "../../../../contexts/AuthContext";
import { AgreementService } from "../../../../services/firestore/AgreementService";
import { AgreementData } from "../../../../services/firestore/types";
import AgreementCheckbox from "../AgreementCheckbox";
import SignupUserInfoForm from "../SignupUserInfoForm";

interface SignUpAuthorProps extends CreateUserRequest {
    passwordConfirm: string;
    acceptContentAgreements: boolean;
    acceptUserAgreements: boolean;
}

export default function SignUpAuthorPanel(): ReactElement {
    const methods = useForm<SignUpAuthorProps>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: {
            firstname: "",
            surname: "",
            email: "",
            telephoneNumber: "",
            password: "",
            passwordConfirm: "",
            acceptContentAgreements: false,
            acceptUserAgreements: false,
        },
    });

    const { handleSubmit } = methods;

    const navigate = useNavigate();
    const { signup } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [latesUserAgreements, setLatestUserAgreements] = useState<AgreementData[]>([]);

    const onSubmit = (data) => handleSubmitSignUp(data);

    useEffect(() => {
        new AgreementService().getLatestRoleAgreements(UserRole.AUTHOR).then(setLatestUserAgreements);
    }, []);

    async function handleSubmitSignUp(data) {
        setError("");
        setLoading(true);

        const registerAuthorData: CreateUserRequest = {
            ...removeKeys(data, ["passwordConfirm", "acceptUserAgreements", "acceptContentAgreements"]),
            createUserType: UserRole.AUTHOR,
        };
        await signup(registerAuthorData)
            .then(() => navigate(AppPath.ROOT))
            .catch((err) => {
                if (err?.details?.code == FirebaseAuthErrorCode.EMAIL_EXISTS) {
                    setError("Epost er allerede i bruk");
                } else {
                    setError("Det skjedde en feil. Vennligst prøv på nytt");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Columns className={"is-vcentered align-content-center"} m0 p0>
            <Column isFlex isJustifyContentCenter className={"is-hidden-tablet-only is-hidden-mobile"}>
                <PublizmLogo isLink={false} width={125} height={169} />
            </Column>
            <Column id={"signup"} isFlexGrow1 pt0>
                <PzTitle>Registrering forfatter</PzTitle>
                {error && <div className="notification is-danger"> {error} </div>}
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <SignupUserInfoForm telephoneNumberRequired={false} />
                        <AgreementCheckbox
                            agreementType={AgreementType.USER_TERMS}
                            role={UserRole.AUTHOR}
                            name="acceptContentAgreements"
                            requiredMessage="Du må akseptere brukervilkårene"
                            agreementDescription="Publizms brukervilkår"
                        />
                        <div className={"mt-3"}>
                            <AgreementCheckbox
                                agreementType={AgreementType.PRIVACY}
                                role={UserRole.AUTHOR}
                                name="acceptUserAgreements"
                                requiredMessage="Du må akseptere vilkårene for personvern"
                                agreementDescription="vilkårene for personvern"
                            />
                        </div>
                        <Field isGrouped className="mt-5">
                            <Control>
                                <Button
                                    isPrimary
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={loading}
                                    isLoading={loading}
                                >
                                    Registrer
                                </Button>
                            </Control>
                        </Field>
                    </form>
                </FormProvider>
            </Column>
        </Columns>
    );
}
