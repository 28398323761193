import PageTemplate from "components/pagetemplate/PageTemplate";
import { Container } from "components/trunx";
import React, { ReactElement } from "react";

import SignUpCustomerPanel from "./SignUpCustomerPanel";

export default function SignUpCustomerPage(): ReactElement {
    return (
        <PageTemplate>
            <Container p3>
                <SignUpCustomerPanel />
            </Container>
        </PageTemplate>
    );
}
