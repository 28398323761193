import "./pzDivider.css";

interface Props {
    color?: Color;
    fullWidth?: boolean;
}

type Color = "primary" | "secondary";

export default function PzDivider({ color = "primary", fullWidth = false }: Props) {
    return fullWidth ? (
        <div className="pzDivider right">
            <div className="firstblockFull" />
            <div className="secondblockFull" />
            <div className="thirdblockFull" />
            <div className="fourthblockFull" />
        </div>
    ) : (
        <div className="pzDivider">
            <div className={`firstblock ${color === "secondary" ? "firstblockSecondary" : ""}`} />
            <div className={`secondblock ${color === "secondary" ? "secondblockSecondary" : ""}`} />
        </div>
    );
}
