export enum AppPath {
    DASHBOARD_OLD = "/dashboard-old",
    DASHBOARD = "/dashboard",
    USERADMIN = "/users",
    LOGIN = "/login",
    LOGIN_VIPPS = "/login/vipps",
    JOIN_US = "/join-us",
    UPDATE_PROFILE = "/update-profile",
    FORGOT_PASSWORD = "/forgot-password",
    WORK_ASSESSMENT = "/assessment",
    UPDATE_AGREEMENT = "/update-agreement",
    MANAGE_AGREEMENTS = "/agreements",
    OPEN_FILE = "/openfile",
    BOOK_MANAGEMENT = "/book-management",
    BOOK_DETAILS = "/book-details",
    ROOT = "/",
    HOME = "/landing",
    BILLIGKROKEN = "/billigkroken",
    ARTICLES = "/news",
    ABOUT = "/about",
    AUTHORS = "/authors",
    DELIVER_SCRIPT = "/", // TODO
    CATALOG_ADMIN = "/catalog-admin",
    BOOK_GROUP_ADMIN = "/book-group-admin",
    SUBMIT_WORK = "/submit-work",
    SUBMIT_WORK_INFO = "/submit-work-info",
    UPLOAD_SUCCESSFUL = "/upload-success",
    SIGNUP_VIPPS = "/signup-author/vipps",
    SIGNUP = "/signup-author",
    SIGNUP_EDITOR = "/signup-editor",
    SIGNUP_CUSTOMER = "/signup-customer",
    EBOOK = "/ebook",
    BOOK = "/book",
    BOOK_PREVIEW = "/bookpreview",
    AUDIOBOOK = "/audiobook",
    AUDIOBOOK_SAMPLE = "/audiobook-sample",
    QR_CODE_LANDING = "/qr",
    BOOKCASE = "/bookcase",
    CATALOGUE = "/catalogue",
    CATALOGUE_ALL_BOOKS = "/catalogue/all-books",
    AUTHOR_PROFILE_DETAILS = "/author-profile-details",
    ADMIN_CREATE_AUTHOR = "/admin-create-author",
    PURCHASE_BOOK = "/purchase",
    ADMIN_CREATE_AUTHOR_PROFILE = "/admin-create-author-profile",
    ADMIN_AUTHORS = "/admin-authors",
    ADMIN_LICENSEE = "/admin-licensee",
    ADMIN_EDIT_LICENSEE = "/admin-edit-licensee",
    ADMIN_TRANSACTIONS = "/admin-transactions",
    ADMIN_LICENSEE_DASHBOARD = "/admin-list-licensee",
    ROYALTY_DASHBOARD = "/royalties",
    SMOC_AI = "/smoc",
    SMOC_BASE_URL = "https://go.smoc.ai/publizm",
}
