import { onAuthStateChanged, User } from "firebase/auth";
import { useRef } from "react";
import { useQuery, useQueryClient } from "react-query";

import { auth } from "../firebase";
import { AnalyticsApi } from "../services/firestore/queries/queriesCommon";

const useAuthState = () => {
    const AUTH_STATE_QUERYKEY = "authState";
    const authStateSubscription = useRef<ReturnType<typeof onAuthStateChanged>>();
    const eventCount = useRef(0);
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: AUTH_STATE_QUERYKEY,
        queryFn: ({ signal }): Promise<User | null> => {
            signal?.addEventListener("abort", () => {
                console.log("Abort auth state subscription");
                authStateSubscription.current?.();
            });
            return new Promise((resolve, reject) => {
                authStateSubscription.current = onAuthStateChanged(
                    auth,
                    (user) => {
                        AnalyticsApi.saveUserId(user?.uid);
                        console.log("User logged in", user, "eventCount", eventCount.current);
                        if (eventCount.current == 0) {
                            resolve(user);
                        } else {
                            queryClient.setQueryData(AUTH_STATE_QUERYKEY, user);
                        }
                        eventCount.current += 1;
                    },
                    (error) => {
                        console.log("AUTH error", error);
                        if (eventCount.current == 0) {
                            reject(error);
                        } else {
                            queryClient.resetQueries(AUTH_STATE_QUERYKEY);
                        }
                    }
                );
            });
        },
    });
};

export { useAuthState };
