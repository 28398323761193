import "./PurchaseButton.css";

import { faBook, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureMessage } from "@sentry/react";
import { AppPath } from "common/AppPath";
import { hasBookTypeAnyFiles, isPriceDiscounted } from "common/BookUtils";
import { Feature } from "components/feature/FeatureToggle";
import PzButton from "components/form/PzButton";
import PaymentDisabledModal from "components/modal/PaymentDisabled";
import TransactionErrorModal from "components/modal/TransactionErrorModal";
import { useAuth } from "contexts/AuthContext";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { BookPriceType, BookType, UrlUtils, UserRole } from "shared";

import { BookQueries } from "../../../../services/firestore/queries/book/bookQueries";
import { AnalyticsApi } from "../../../../services/firestore/queries/queriesCommon";
import DownloadAudiobook from "../audiobook/DownloadAudiobook";
import { BookVersionDetails, getPriceByType, useBookDetails } from "../BookDetailsContext";
import DownloadEbook from "../ebook/DowndloadEbook";
import SendToKindle from "../ebook/SendToKindle";
import PzCheckbox from "../../../../components/form/PzCheckbox";
import VippsPaymentButtonWithLegal from "../../../../components/transaction/VippsPaymentButtonWithLegal";
import VippsService from "../../../../services/VippsService";
import { UserService } from "../../../../services/firestore/UserService";
import PzModal from "../../../../components/modal/PzModal";
import { Box, Card, Level, Modal } from "../../../../components/trunx";
import { VersionDetails } from "./VersionDetails";
import PzPanel from "../../../../components/PzPanel";

export const EbookIcon = () => <FontAwesomeIcon icon={faBook} />;
export const AudioBookIcon = () => <FontAwesomeIcon icon={faHeadphones} />;

interface PurchaseButtonProps {
    type: BookType;
    hasAccess: boolean;
}

export default function PurchaseButton({ hasAccess, type }: PropsWithChildren<PurchaseButtonProps>) {
    const { hasRole, currentUser, userInfo } = useAuth();
    const navigate = useNavigate();
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [showPaymentInfo, setShowPaymentInfo] = useState<boolean>(false);
    const { bookId, audioBookId, ebookId, book, versionDetails, bundlePrice } = useBookDetails();
    const bookPriceType = type === BookType.EBOOK ? BookPriceType.EBOOK : BookPriceType.AUDIOBOOK;
    const price = useMemo(() => getPriceByType(book, bookPriceType), []);
    const [initPurchase, setInitPurchase] = useState<PurchaseBookProps>()
    const isBookAvailabeForPurchase = BookQueries.isBookAvailabeForPurchase(bookId, type);

    function renderErrorModal() {
        const onClose = () => setDisplayError(false);
        captureMessage("Payment failed");
        return <TransactionErrorModal onClose={onClose} />;
    }

    function renderPaymentDisabledModal() {
        const onClose = () => setShowPaymentInfo(false);
        return <PaymentDisabledModal onClose={onClose} />;
    }

    function onPurchaseButtonClicked() {
        if (Feature.PAYMENT_ACTIVE.isActive() || hasRole(UserRole.ADMIN)) {
            AnalyticsApi.logEvent("purchase_button_clicked");
            AnalyticsApi.logPurchaseButtonClicked(price?.value, bookId, book.title, type);
            const hasEbookFiles = hasBookTypeAnyFiles(BookType.EBOOK, book);
            const hasAudioBookFiles = hasBookTypeAnyFiles(BookType.AUDIO, book);
            setInitPurchase({
                price: price?.value,
                bookId,
                bundlePrice,
                type,
                versionDetails,
                allTypesPurchaseable: hasEbookFiles && hasAudioBookFiles,
            } as PurchaseBookProps
            );
        } else {
            setShowPaymentInfo(true);
        }
    }

    function openBook() {
        if (type === BookType.AUDIO || type === BookType.PODCAST) {
            navigate(`${AppPath.AUDIOBOOK}?id=${audioBookId}`, { state: { bookId: audioBookId, title: book?.title } });
        } else if (type === BookType.EBOOK) {
            navigate(AppPath.EBOOK, { state: { bookId: ebookId, title: book?.title } });
        }
    }

    function renderPrice() {
        const buyLabel = `Kjøp ${type == BookType.AUDIO ? "lydbok" : "ebok"}`;
        if (price && isPriceDiscounted(price)) {
            return (
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <p style={{ fontVariantNumeric: "tabular-nums" }}>
                        {buyLabel} <s>{price.originalPrice}</s> ,-
                    </p>
                    <p style={{ fontVariantNumeric: "tabular-nums" }}>
                        <strong>{price.value} ,-</strong>
                    </p>
                </div>
            );
        }
        return (
            <div>
                <p>
                    {buyLabel} {price?.value},-
                </p>
            </div>
        );
    }

    function renderOpenButtonLabel() {
        if (hasAccess) {
            if (type == BookType.PODCAST)
                return (
                    <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
                        <FontAwesomeIcon icon={faHeadphones} />
                        <div>Lytt</div>
                    </div>
                );
            else return `Åpne ${type === BookType.EBOOK ? "e-bok" : "lydbok"}`;
        }
        return renderPrice();
    }
    if (!hasBookTypeAnyFiles(type, book) || (!hasAccess && !isBookAvailabeForPurchase.data)) {
        return null;
    }
    return (
        <div>
            <PzButton
                onClick={hasAccess ? openBook : onPurchaseButtonClicked}
                className={
                    hasAccess
                        ? "pz-purchase-button pz-purchase-button-own"
                        : "pz-purchase-button pz-purchase-button-dont-own"
                }
            >
                {renderOpenButtonLabel()}
            </PzButton>
            {initPurchase && <PurchaseDetails {...initPurchase} onClose={() => setInitPurchase(undefined)} />}
            {hasAccess && type === BookType.EBOOK && (
                <>
                    <div className="kindle is-center">
                        <SendToKindle bookId={ebookId!} userId={currentUser?.uid} emailKindle={userInfo?.emailKindle} />
                    </div>
                    <div className="epub-download is-center">
                        <DownloadEbook bookId={ebookId!} />
                    </div>
                </>
            )}
            {hasAccess && type === BookType.AUDIO && (
                <>
                    <div className="epub-download is-center">
                        <DownloadAudiobook bookId={audioBookId!} />
                    </div>
                </>
            )}
            {displayError && renderErrorModal()}
            {showPaymentInfo && renderPaymentDisabledModal()}
        </div>
    );
}
interface PurchaseBookProps {
    bookId: string;
    price: number;
    bundlePrice: number;
    type: BookType;
    allTypesPurchaseable: boolean;
    onClose: () => void
    versionDetails: BookVersionDetails[];
}

function PurchaseDetails({
    price,
    type,
    versionDetails,
    bookId,
    bundlePrice,
    onClose,
    allTypesPurchaseable,
}: PurchaseBookProps) {
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [bundleSelected, setBundleSelected] = useState<boolean>(false);
    const [allowedCreatingUser, setAllowedCreatingUser] = useState<boolean>(false);
    const [hasPurchasedOtherType, setHasPurchasedOtherType] = useState<boolean>(false);
    const { currentUser } = useAuth();
    const closePurchaseModal = () => {
        AnalyticsApi.logEvent("purchase_cancelled_from_purchase_page");
        const details = versionDetails ?? [];
        const title = details.length > 0 ? details[0].title : "";
        AnalyticsApi.logCheckoutCanceled(
            price,
            bookId,
            title,
            type
        );
        onClose();

    };

    useEffect(() => {
        if (currentUser != null) {
            new UserService.Transaction().getAllByBookId(bookId).then((res) => {
                setHasPurchasedOtherType(res.length > 0);
            });
        }

    }, []);

    function initiateBookPayment() {
        const localBookId = bookId;
        const localBookType = bundleSelected ? BookType.BUNDLE : type;
        const vippsService = new VippsService();
        return vippsService.initiateBookPayment(localBookId, localBookType, price, versionDetails[0].title);
    }
    function toggleBundleSelection() {
        setBundleSelected((val) => !val);
    }
    function renderErrorModal() {
        return <TransactionErrorModal onClose={() => setDisplayError(false)} />;
    }
    const bundleDiff = bundlePrice - price;
    const otherBookFormat = type == BookType.EBOOK ? "lydbok" : "e-bok";
    const showBundleOption = bundlePrice && allTypesPurchaseable && !hasPurchasedOtherType;
    return <PzModal isActive onClose={closePurchaseModal}>
        <Modal.Card.Head p0 m0 pb2 hasBackgroundWhite>
            <Modal.Card.Title p0 m0>Bekreft kjøp</Modal.Card.Title>
        </Modal.Card.Head>
        <Modal.Card.Body pl0 pr0 mr0 ml0 >
            <div className="is-center" style={{ flexDirection: "column" }}>
                <VersionDetails type={type} versionDetails={versionDetails} isBundleSelected={bundleSelected} />
                <div className="purchase-details">
                    {showBundleOption && (
                        <div className="is-center" onClick={toggleBundleSelection}>
                            <PzCheckbox name="" checked={bundleSelected} />
                            <p className="m-4">{`Få med ${otherBookFormat} for bare ${bundleDiff}kr`}</p>
                        </div>
                    )}
                    <p className="is-size-3 is-center">{bundleSelected ? bundlePrice : price},-</p>
                    <VippsPaymentButtonWithLegal
                        initiatePayment={() => initiateBookPayment()}
                        productPurchaseMetadata={{
                            price,
                            bookId,
                            bundlePrice,
                            type,
                            allTypesPurchaseable,
                            versionDetails,
                        }}
                        setError={setDisplayError}
                    />
                    {currentUser == null && <Card style={{ marginTop: "10px" }} >
                        <div  >
                            <p>Du er ikke logget inn.</p>
                            <p>Etter kjøp er fullført vil du automatisk bli logget inn. </p>
                            <p>Hvis du ikke har bruker fra før vil det bli automatisk opprettet basert på informasjon fra Vipps</p>
                        </div>

                    </Card>}
                    {displayError && renderErrorModal()}
                    <PzButton onClick={onClose} className="is-center mt-4">
                        Avbryt
                    </PzButton>
                </div>
            </div>
        </Modal.Card.Body>
    </PzModal>
}
