import { Container, ContainerProps, Content, ContentProps } from "components/trunx";

export interface PzTextProps extends ContentProps {
    containerProps?: ContainerProps;
    isTextCentered?: boolean;
}

function PzText(props: PzTextProps) {
    const { containerProps, isTextCentered, ...otherProps } = props;
    return (
        <Container {...containerProps} isMarginLess className="pzTextContainer">
            <Content {...otherProps} className={`pzText ${props.className ? props.className : ""}`} />
        </Container>
    );
}

export default PzText;
