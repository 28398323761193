import { faMinus, faPenSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Book, BookGroup } from "shared";

import PzButton from "../../../../../components/form/PzButton";
import PzIconButton from "../../../../../components/form/PzIconButton";
import PzModal from "../../../../../components/modal/PzModal";
import PzTag from "../../../../../components/PzTag";
import { PzTitle } from "../../../../../components/text/PzTitle";
import { Modal } from "../../../../../components/trunx";
import { BookGroupQueries } from "../../../../../services/firestore/queries/book/bookgroupQueries";
import { BookMutations } from "../../../../../services/firestore/queries/book/bookMutations";

interface IBookGroupButtonProps {
    bookId: string;
    book: Book;
}

export default function BookGroupButton({ bookId, book }: IBookGroupButtonProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <React.Suspense fallback={""}>
            <PzIconButton
                buttonProps={{ style: { padding: "0" } }}
                isSmall
                onClick={() => setIsModalOpen(true)}
                icon={faPenSquare}
            ></PzIconButton>
            {isModalOpen && <UpdateBookGroupModal id={bookId} book={book} onClose={() => setIsModalOpen(false)} />}
        </React.Suspense>
    );
}

interface UpdateBookGroupModalProps {
    book: Book;
    id: string;
    onClose: () => void;
}
function UpdateBookGroupModal({ onClose, id, book }: UpdateBookGroupModalProps) {
    const { data, isLoading } = BookGroupQueries.allBookGroups();
    const [groups, setGroups] = useState(book.groups ?? []);
    const updateBookFn = BookMutations.updateBook();

    useEffect(() => {
        updateBookFn.isSuccess && onClose();
    }, [updateBookFn.isSuccess]);
    function lagre() {
        updateBookFn.mutate({
            data: {
                groups,
            },
            id,
        });
    }

    function addGroup(groupName: string) {
        setGroups((groups) => [...groups, groupName]);
    }

    function removeGroup(groupName: string) {
        setGroups((groups) => [...groups.filter((g) => g !== groupName)]);
    }

    if (isLoading) {
        return null;
    }

    const groupsNotAdded = data?.filter((group) => !groups.some((addedGroup) => addedGroup == group.name)) ?? [];
    const addedGroups = data?.filter((group) => groups.some((addedGroup) => addedGroup == group.name)) ?? [];
    return (
        <PzModal contentStyle={{ maxHeight: "50rem" }} isActive onClose={onClose}>
            <Modal.Card.Body style={{ minWidth: "30rem", height: "100%", overflow: "hidden" }}>
                <PzTitle is4>Oppdater grupper</PzTitle>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <BookGroupTags title="Eksisterende" groups={addedGroups} onClick={removeGroup} isAddedToBook />
                    <BookGroupTags title="Legg til" groups={groupsNotAdded} onClick={addGroup} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", marginTop: "20px" }}>
                    <PzButton isLoading={updateBookFn.isLoading} onClick={lagre} isPrimary>
                        Lagre
                    </PzButton>
                    <PzButton isSecondary onClick={onClose} type="button" value="Avbryt">
                        Avbryt
                    </PzButton>
                </div>
            </Modal.Card.Body>
        </PzModal>
    );
}

interface IBookGroupTagsProps {
    title: string;
    groups: BookGroup[];
    onClick: (groupName: string) => void;
    isAddedToBook?: boolean;
}
function BookGroupTags({ title, groups, onClick, isAddedToBook }: IBookGroupTagsProps) {
    return (
        <div>
            <PzTitle is6 pb0 mb2>
                {title}
            </PzTitle>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {groups.length > 0 ? (
                    groups.map((group) => (
                        <PzTag variant={isAddedToBook ? "normal" : "tertiary"} onClick={() => onClick(group.name)}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "5px",
                                    alignItems: "center",
                                }}
                            >
                                {group.name}
                                <FontAwesomeIcon icon={isAddedToBook ? faMinus : faPlus} color="black" />
                            </div>
                        </PzTag>
                    ))
                ) : (
                    <div>{isAddedToBook ? "Ingen grupper knyttet til boken" : "Ingen flere grupper å legge til"}</div>
                )}
            </div>
        </div>
    );
}
