import { Content, Level, Modal } from "components/trunx";
import { ReactElement } from "react";

import PzButton from "../form/PzButton";
import PzModal from "./PzModal";

interface TextModalProps {
    onClose: () => void;
    isActive: boolean;
    title: string;
    content: ReactElement;
    maxWidth?: number;
    maxHeight?: number;
}

export default function ContestInfoModal({
    onClose,
    isActive,
    title,
    content,
    maxWidth = 35,
    maxHeight = 50,
}: TextModalProps): ReactElement {
    function renderModal() {
        return (
            <>
                <Modal.Card.Head>
                    <Level>
                        <Level.Left>
                            <Level.Item>
                                <Modal.Card.Title isFlexShrink1>{title}</Modal.Card.Title>
                            </Level.Item>
                        </Level.Left>
                    </Level>
                </Modal.Card.Head>

                <Modal.Card.Body style={{ maxWidth: `${maxWidth}rem`, height: "100%", overflow: "hidden" }}>
                    <Content style={{ overflowY: "auto", maxHeight: "30rem", height: "max-content" }}>
                        {content}
                    </Content>
                </Modal.Card.Body>

                <Modal.Card.Foot>
                    <PzButton isPrimary onClick={onClose}>
                        Lukk
                    </PzButton>
                </Modal.Card.Foot>
            </>
        );
    }

    if (!isActive) {
        return <></>;
    }

    return (
        <PzModal contentStyle={{ maxHeight: `${maxHeight}rem` }} isActive={isActive} onClose={onClose}>
            {renderModal()}
        </PzModal>
    );
}
