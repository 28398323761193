import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "components/LoadingIndicator";
import { Label } from "components/trunx";

interface LoadingProps {
    state: string;
}

export default function RenderLoadingIndicator({ state }: LoadingProps) {
    if (state === "STALE") {
        return null;
    }

    switch (state) {
        case "UPLOADING":
            return (
                <Label>
                    <LoadingIndicator style={{ marginRight: "8px", verticalAlign: "middle", display: "inline" }} />
                    Lagrer
                </Label>
            );
        case "UPLOADED":
            return (
                <Label>
                    <FontAwesomeIcon icon={faCheck} color="green" style={{ marginRight: "5px" }} />
                    Lagret
                </Label>
            );
        case "ERROR":
            return (
                <Label>
                    <FontAwesomeIcon icon={faTimes} color="red" style={{ marginRight: "5px" }} />
                    Det skjedde en feil ved opplasting av data
                </Label>
            );
        default:
            return null;
    }
}
