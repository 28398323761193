import "react-toastify/dist/ReactToastify.css";

import AuthorProfileDetailsPage from "pages/customer/authorprofiledetails/AuthorProfileDetailsPage";
import BookcasePage from "pages/customer/bookcase/BookcasePage";
import AdministrationDashboard from "pages/dashboard/admin/book/bookadmin/AdministrationDashboard";
import LicenseeDashboard from "pages/dashboard/admin/book/licenseeadmin/LicenseeDashboard";
import LicenseeEditorPage from "pages/dashboard/admin/book/licenseeadmin/LicenseeEditorPage";
import EditProfilePage from "pages/myprofile/EditProfilePage";
import SmocDialogPage from "pages/smoc/SmocDialogPage";
import { PageNotFoundPage } from "pages/staticpages/PageNotFound";
import ForgotPasswordPage from "pages/useradmin/forgottenpassword/ForgotPasswordPage";
import SignUpCustomerPage from "pages/useradmin/signup/customer/SignUpCustomerPage";
import WorkAssessmentPage from "pages/workassessment/WorkAssessmentPage";
import React, { PropsWithChildren, ReactElement } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { UserRole } from "shared";
import { useRegisterSW } from "virtual:pwa-register/react";

import { AppPath } from "../common/AppPath";
import { AuthProvider, useAuth } from "../contexts/AuthContext";
import BookDetailsPage from "../pages/customer/bookdetails/BookDetailsPage";
import PurchaseBookPage from "../pages/customer/bookdetails/purchase/PurchasePage";
import CustomerPage from "../pages/customer/CustomerPage";
import AuthorAdminDashboard from "../pages/dashboard/admin/book/authoradmin/AuthorAdminDashboard";
import BookAuthorAdminPage from "../pages/dashboard/admin/book/authoradmin/BookAuthorAdminPage";
import BookAuthorProfilePage from "../pages/dashboard/admin/book/authoradmin/BookAuthorProfilePage";
import BookAdminPage from "../pages/dashboard/admin/book/bookadmin/BookAdminPage";
import BookGroupAdminDashboard from "../pages/dashboard/admin/book/bookadmin/bookgroup/BookGroupAdminDashboard";
import AdministrationTransactionDashboard from "../pages/dashboard/admin/book/transactionadmin/AdministrationTransactionDashboard";
import RoyaltyDashboardPage from "../pages/dashboard/royalty/RoyaltyDasboardPage";
import UserDashboard from "../pages/dashboard/UserDashboard";
import MainpageStoryblok from "../pages/mainpage/MainpageStoryblok";
import ArticlePage from "../pages/storyblok/ArticlePage";
import ArticlesPage from "../pages/storyblok/ArticlesPage";
import StoryblokPage, { CreateStoryblokPage } from "../pages/storyblok/StoryblokPage";
import UploadPage from "../pages/uploadfile/UploadPage";
import UploadSuccesfullPage from "../pages/uploadfile/UploadSuccessfullPage";
import LoginPage from "../pages/useradmin/login/LoginPage";
import SignUpAuthorPage from "../pages/useradmin/signup/author/SignUpAuthorPage";
import SignUpEditorPage from "../pages/useradmin/signup/editor/SignUpEditorPage";
import SigninWithVippsPage from "../pages/useradmin/signup/vipps/SigninWithVippsPage";
import ErrorBoundary from "./ErrorBoundary";
import LoadingIndicator from "./LoadingIndicator";
import ScrollToTop from "./pagetemplate/ScrollToTop";
import PWAPrompt from "./pwaprompt/PWAPrompt";
const AudiobookPage = React.lazy(() => import("pages/audiobook/AudiobookPage"));
const AudioBookSamplePage = React.lazy(() => import("pages/audiobook/AudioBookSamplePage"));
const EbookPage = React.lazy(() => import("pages/ebook/EbookPage"));

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
            useErrorBoundary: false,
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
            staleTime: 2000,
            retry: 0,
        },
    },
});
function App() {
    useRegisterSW({
        immediate: true,
        onOfflineReady() {
            toast("📱 Siden er klar for offline bruk", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        },
    });
    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <ToastContainer />
                    <ScrollToTop />
                    <PWAPrompt />
                    <React.Suspense fallback={<LoadingIndicator size="XL" />}>
                        <AuthProvider>
                            <Routes>
                                <Route path={AppPath.ARTICLES} element={<ArticlesPage />} />
                                <Route path={`${AppPath.ARTICLES}/:id`} element={<ArticlePage />} />
                                <Route path={AppPath.BOOKCASE} element={<BookcasePage />} />
                                <Route path={AppPath.SIGNUP} element={<SignUpAuthorPage />} />
                                <Route path={AppPath.LOGIN_VIPPS} element={<SigninWithVippsPage />} />
                                <Route path={AppPath.SIGNUP_VIPPS} element={<SigninWithVippsPage />} />
                                <Route path={`${AppPath.BOOK_DETAILS}/:bookUrl`} element={<BookDetailsPage />} />
                                <Route path={`${AppPath.CATALOGUE}/:tab`} element={<CustomerPage />} />
                                <Route path={AppPath.CATALOGUE} Component={CustomerPage} />
                                <Route path={AppPath.LOGIN} element={<LoginPage />} />
                                <Route path={AppPath.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
                                <Route path={AppPath.SUBMIT_WORK_INFO} element={CreateStoryblokPage("send-manus")} />
                                <Route path={AppPath.ROOT} Component={CustomerPage} />
                                <Route
                                    path={AppPath.HOME}
                                    element={<MainpageStoryblok /> /*() => CreateStoryblokPage("forside")*/}
                                />
                                <Route path={AppPath.ABOUT} element={CreateStoryblokPage("about")} />
                                <Route path={AppPath.JOIN_US} element={CreateStoryblokPage("join-us")} />
                                <Route path={AppPath.QR_CODE_LANDING} element={<Navigate to={AppPath.ROOT} />} />
                                <Route path={AppPath.AUDIOBOOK} element={<AudiobookPage />} />
                                <Route path={AppPath.AUDIOBOOK_SAMPLE} element={<AudioBookSamplePage />} />
                                <Route path={AppPath.EBOOK} element={<EbookPage />} />
                                <Route path={AppPath.PURCHASE_BOOK} element={<PurchaseBookPage />} />
                                <Route path={AppPath.SIGNUP_EDITOR} element={<SignUpEditorPage />} />
                                <Route path={AppPath.SIGNUP_CUSTOMER} element={<SignUpCustomerPage />} />
                                <Route path={AppPath.SMOC_AI} element={<SmocDialogPage />} />
                                <Route path="/:storyblokId" Component={StoryblokPage} />

                                <Route
                                    path={`${AppPath.AUTHOR_PROFILE_DETAILS}/:authorProfileName`}
                                    element={<AuthorProfileDetailsPage />}
                                />
                                <Route
                                    path={AppPath.DASHBOARD}
                                    element={
                                        <RedirectRoute
                                            redirectMap={{
                                                [UserRole.ADMIN]: UserDashboard,
                                                [UserRole.AUTHOR]: UserDashboard,
                                                [UserRole.EDITOR]: UserDashboard,
                                                [UserRole.CUSTOMER]: CustomerPage,
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path={AppPath.UPDATE_PROFILE}
                                    element={
                                        <RequireAuth>
                                            <EditProfilePage />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.SUBMIT_WORK}
                                    element={
                                        <RequireAuth>
                                            <UploadPage />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path={AppPath.WORK_ASSESSMENT}
                                    element={
                                        <RequireAuth requireRoles={[UserRole.EDITOR, UserRole.ADMIN]}>
                                            <WorkAssessmentPage />
                                        </RequireAuth>
                                    }
                                />

                                <Route
                                    path={AppPath.BOOK_MANAGEMENT}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <BookAdminPage />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.CATALOG_ADMIN}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <AdministrationDashboard />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.BOOK_GROUP_ADMIN}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <BookGroupAdminDashboard />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.ADMIN_LICENSEE_DASHBOARD}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <LicenseeDashboard />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.ROYALTY_DASHBOARD}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <RoyaltyDashboardPage />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.ADMIN_EDIT_LICENSEE}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <LicenseeEditorPage />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.ADMIN_TRANSACTIONS}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <AdministrationTransactionDashboard />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.UPLOAD_SUCCESSFUL}
                                    element={
                                        <RequireAuth>
                                            <UploadSuccesfullPage />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.USERADMIN}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <UserDashboard />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.MANAGE_AGREEMENTS}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <UserDashboard /> {/**TODO: Er dette riktig? */}
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.ADMIN_AUTHORS}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <AuthorAdminDashboard />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.ADMIN_CREATE_AUTHOR}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <BookAuthorAdminPage />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path={AppPath.ADMIN_CREATE_AUTHOR_PROFILE}
                                    element={
                                        <RequireAuth requireRole={UserRole.ADMIN}>
                                            <BookAuthorProfilePage />
                                        </RequireAuth>
                                    }
                                />
                                <Route path="*" Component={PageNotFoundPage} />
                            </Routes>
                        </AuthProvider>
                        <Toaster
                            position="bottom-center"
                            reverseOrder={false}
                            gutter={8}
                            containerClassName=""
                            containerStyle={{}}
                            toastOptions={{
                                // Define default options
                                className: "",
                                duration: 5000,
                                style: {
                                    background: "#363636",
                                    color: "#fff",
                                },
                            }}
                        />
                    </React.Suspense>
                </Router>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

interface IRequireAuth {
    requireRole?: UserRole;
    requireRoles?: UserRole[];
}
function RequireAuth({ children, requireRole, requireRoles }: PropsWithChildren<IRequireAuth>): ReactElement {
    const rolesToCheck = (requireRoles ?? [requireRole]).filter((role) => role != null || role != undefined);
    const { currentUser, activeRole } = useAuth();
    const isAuthenticated = currentUser != null;
    const hasRole = rolesToCheck.length == 0 || rolesToCheck.some((role) => activeRole && UserRole[activeRole] == role);
    if (isAuthenticated && hasRole) {
        return <>{children}</>
    } else if (!isAuthenticated) {
        return <Navigate
            to={AppPath.LOGIN}
            state={{
                from: {
                    pathname: window.location.pathname,
                    search: window.location.search,
                },
            }}
        />
    }
    //TODO: Fiks at denne går til root
    return <Navigate
        to={AppPath.LOGIN}
        state={{
            from: {
                pathname: window.location.pathname,
                search: window.location.search,
            },
        }}
    />
}

interface IRedirectRoute {
    redirectMap: { [key: string]: React.ComponentType<any> };
}
function RedirectRoute({ redirectMap }: IRedirectRoute): ReactElement {
    const { currentUser, activeRole } = useAuth();
    const isAuthenticated = currentUser != null;
    const userRole = activeRole;
    if (!isAuthenticated || !userRole) {
        return (
            <Navigate
                to={AppPath.LOGIN}
                state={{
                    from: {
                        pathname: window.location.pathname,
                        search: window.location.search,
                    },
                }}
            />
        );
    }
    const role = UserRole[userRole];
    const Component = redirectMap[role] ?? PageNotFoundPage;
    return <Component />;
}

export default App;
