import { useStoryblokApi } from "@storyblok/react";
import { useEffect, useRef, useState } from "react";
import { ReactElement } from "react";
import { useQuery } from "react-query";

import { PageNotFound } from "../../pages/staticpages/PageNotFound";
import LoadingIndicator from "../LoadingIndicator";
import { storyblokEnv } from "./StoryblokComponents";
const { StoryblokBridge, location } = window;

interface StoryblokWrapperProps<T> {
    slug: string;
    children: (val: T) => ReactElement;
}

export default function StoryblokWrapper<T>({ slug, children }: StoryblokWrapperProps<T>): ReactElement {
    const storyblokApi = useStoryblokApi();
    const storyblokInstance = useRef(new StoryblokBridge());
    const [version, setVersion] = useState<"draft" | "published">(storyblokEnv);
    const queryKey = ["storyblok", slug, version];

    const {
        data: story,
        isLoading,
        isFetching,
        isError,
        refetch,
    } = useQuery({
        queryKey,
        queryFn: () => storyblokApi.get(`cdn/stories/${slug}`, { version }),
        select(data) {
            return data?.data?.story;
        },
        suspense: false,
        enabled: !!slug,
    });

    useEffect(() => {
        storyblokInstance.current.pingEditor(() => {
            if (storyblokInstance.current.isInEditor()) {
                setVersion("draft");
            }
        });
        storyblokInstance.current.on(["published", "change"], () => {
            refetch();
        });
    }, []);
    if (isLoading || isFetching) {
        return <LoadingIndicator />;
    }
    if (isError && window.navigator.onLine) {
        return <PageNotFound />;
    }

    if (isError && !window.navigator.onLine) {
        return <div></div>;
    }

    return <>{children(story as T)}</>;
}
