import PageTemplate from "components/pagetemplate/PageTemplate";
import { Container } from "components/trunx";
import React, { ReactElement } from "react";

import SignUpEditorPanel from "./SignUpEditorPanel";

export default function SignUpEditorPage(): ReactElement {
    return (
        <PageTemplate>
            <Container p3>
                <SignUpEditorPanel />
            </Container>
        </PageTemplate>
    );
}
