import { Column } from "components/trunx";

import AlgoliaSearchProvider from "../../../../components/algolia/AlgoliaSearchProvider";
import { isDevMode } from "../../../../firebase";
import CatalougeFilterProvider from "../CatalogueFilterContext";
import AuthorProfileCatalogueDisplayer from "./AuthorProfileCatalogueDisplayer";
import AuthorProfileCatalogueDisplayerAlgolia from "./AuthorProfileCatalogueDisplayerAlgolia";

export default function CataloguePage() {
    return (
        <AlgoliaSearchProvider index={import.meta.env.VITE_ALGOLIA_INDEX_AUTHOR_PROFILES!}>
            <CatalougeFilterProvider>
                <Column>
                    {isDevMode ? <AuthorProfileCatalogueDisplayer /> : <AuthorProfileCatalogueDisplayerAlgolia />}
                </Column>
            </CatalougeFilterProvider>
        </AlgoliaSearchProvider>
    );
}
