import AgreementManagementPage from "pages/agreement/AgreeementManagementPage";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";

import { AppPath } from "../../../common/AppPath";
import { PageNotFoundPage } from "../../staticpages/PageNotFound";
import AdminDashboard from "./AdminDashboard";
import UserAdminDashboard from "./useradmin/UserAdminDashboard";
export default function AdminDashboardContainer(): ReactElement {
    const location = useLocation();

    switch (location.pathname) {
        case AppPath.MANAGE_AGREEMENTS:
            return <AgreementManagementPage />;
        case AppPath.DASHBOARD:
            return <AdminDashboard />;
        case AppPath.USERADMIN:
            return <UserAdminDashboard />;
        default:
            return <PageNotFoundPage description="Dashboardet du leter etter, finnes ikke." />;
    }
}
