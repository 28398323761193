import algoliasearch, { SearchClient } from "algoliasearch/lite";
import React from "react";
import { PropsWithChildren, useRef } from "react";
import { InstantSearch } from "react-instantsearch";
import { ReactElement } from "react";
interface AlgoliaSearchFieldProps {
    index: string;
}

interface PzAlgoliaContextProps {
    index: string;
    searchClient: SearchClient;
}
const PzAlgoliaContext = React.createContext<PzAlgoliaContextProps>({} as PzAlgoliaContextProps);

export function useSearchClient(): PzAlgoliaContextProps {
    return React.useContext(PzAlgoliaContext);
}
export default function AlgoliaSearchProvider({
    index,
    children,
}: PropsWithChildren<AlgoliaSearchFieldProps>): ReactElement {
    const searchClient = useRef(
        algoliasearch(import.meta.env.VITE_ALGOLIA_APP_ID!, import.meta.env.VITE_ALGOLIA_SEARCH_KEY!)
    );

    return (
        <PzAlgoliaContext.Provider value={{ searchClient: searchClient.current, index: index }}>
            <InstantSearch stalledSearchDelay={350} searchClient={searchClient.current} indexName={index}>
                {children}
            </InstantSearch>
        </PzAlgoliaContext.Provider>
    );
}
