import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppPath } from "common/AppPath";
import PzButton from "components/form/PzButton";
import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { LicenseeData } from "../../../../../services/firestore/types";

interface TableProps {
    licenseeDataList: LicenseeData[];
}
export default function LicenseeAdministrationTable({ licenseeDataList }: TableProps): ReactElement {
    const navigate = useNavigate();

    function editLicensee(licenseeData: LicenseeData) {
        navigate(AppPath.ADMIN_EDIT_LICENSEE, { state: licenseeData });
    }

    const columns: ColumnData[] = [
        {
            label: "Navn",
            sortByProperty: "licensee.name",
        },
        {
            label: "Telefonnummer",
            sortByProperty: "licensee.phone",
        },
        {
            label: "Epost-adresse",
            sortByProperty: "licensee.email",
        },
        {
            label: "Balanse",
            sortByProperty: "paymentInfo.totalBalance",
        },
        {
            label: "Antall solgt",
            sortByProperty: "saleInfo.numberOfSales",
        },
        {
            label: "Detaljer",
        },
    ];

    function createRow(licensee: LicenseeData): RowData {
        const unpaidBalance = licensee.paymentInfo?.unpaidBalance;
        return {
            components: [
                {
                    content: licensee.licensee.name ?? ".",
                    width: "25%",
                },
                {
                    content: licensee.licensee.phone ?? "-",
                    width: "15%",
                },
                {
                    content: licensee.licensee.email ?? "-",
                    width: "25%",
                },
                {
                    //TODO: Remove conditional chaining. Added as a quick-fix to phantom documents in emulator
                    content: unpaidBalance ? unpaidBalance.toFixed(2) + ",-" : "-",
                    width: "15%",
                },
                {
                    content: licensee?.saleInfo?.numberOfSales?.toString() ?? "",
                    width: "10%",
                },
                {
                    content: <PzButton onClick={() => editLicensee(licensee)}>Detaljer</PzButton>,
                    width: "10%",
                },
            ],
        };
    }

    function getSearchData(data: LicenseeData): string {
        const licensee = data.licensee;
        return licensee.name + licensee.email;
    }

    return (
        <>
            <PzTable
                createRow={createRow}
                searchData={getSearchData}
                data={licenseeDataList}
                columns={columns}
                rowKeyGenerator={(data) => data.documentId}
                tableAddOn={<NewLicensee />}
                defaultSortIndex={1}
                enablePagination
            />
        </>
    );
}

function NewLicensee() {
    const navigate = useNavigate();
    return (
        <PzButton
            isPrimary
            pr6
            pl6
            onClick={() => {
                navigate(AppPath.ADMIN_EDIT_LICENSEE);
            }}
        >
            <span>
                <span style={{ marginRight: "10px" }}>
                    <strong>Ny rettighetshaver</strong>
                </span>
                <FontAwesomeIcon icon={faPlus} color="black" />
            </span>
        </PzButton>
    );
}
