import React, { ReactElement, useEffect, useState } from "react";

import { FirebaseStorage } from "../../services/storage/StorageService";
import PzButton, { PzButtonProps } from "../form/PzButton";
import { FileUtils } from "../utils/FileUtils";

interface OpenFileProps {
    filePath?: string;
    label?: string;
    isButton?: boolean;
    isLink?: boolean;
}

interface OpenFileLinkProps extends OpenFileProps {
    isLink: true;
    isButton?: never;
}

interface OpenFileButtonProps extends OpenFileProps, PzButtonProps {
    isButton: true;
    isLink?: never;
}

type AllProps = OpenFileLinkProps | (OpenFileButtonProps & PzButtonProps);

export default function OpenFileLink({ filePath, isButton, label = "Åpne", ...buttonProps }: AllProps): ReactElement {
    const [fileUrl, setFileUrl] = useState<string>();

    useEffect(() => {
        if (filePath && !fileUrl) {
            new FirebaseStorage().getFileUrl(filePath).then(setFileUrl);
        }
    }, [filePath]);

    if (!filePath) {
        return <></>;
    }

    if (!isButton) {
        return (
            <a href="#" onClick={() => FileUtils.openFileUrl(fileUrl)}>
                {label}
            </a>
        );
    }

    return (
        <PzButton {...buttonProps} onClick={() => FileUtils.openFileUrl(fileUrl)}>
            {label}
        </PzButton>
    );
}
