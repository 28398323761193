import { Column, Container, Help, Textarea } from "components/trunx";
import { useUpload } from "contexts/UploadContext";
import React, { ReactElement } from "react";

import PzText from "../../components/text/PzText";

export default function UploadPitchPage(): ReactElement {
    const { updateWrittenWorkFormData, writtenWorkFormData, errors } = useUpload();

    function textCheck(newText: string) {
        updateWrittenWorkFormData({ pitch: newText });
    }

    return (
        <Column isNarrow>
            <PzText>
                <p>
                    Du er i heisen med en redaktør, og får 20 sekunder på å beskrive kjernen i ditt prosjekt. Skriv hva
                    du sier, maks 500 tegn.
                </p>
            </PzText>
            <Column m3>
                <Textarea
                    onBlur={(event) => textCheck(event.target.value)}
                    defaultValue={writtenWorkFormData.pitch}
                    isDanger={errors?.pitch !== undefined}
                    placeholder="Maks 500 tegn"
                />
                <Container pb5 mb4>
                    <Help isDanger style={{ fontSize: "16px" }}>
                        {errors?.pitch}
                    </Help>
                </Container>
            </Column>
        </Column>
    );
}
