import {
    AudioBookContentType,
    AudioBookFormat,
    Book,
    BookPrice,
    BookPriceType,
    BookType,
    ContributorRoleCode,
    Currency,
    EbookFormat,
    getNumberOrZero,
    Language,
} from "shared";

import { notEmpty } from "../../../../../components/utils/ObjectUtils";
import { BookFileAudioDto, BookFileDto, BookFileEbookDto } from "../../../../../services/firestore/types";
import { BookEconomicsLicensee } from "./../../../../../../shared/lib/types/BookTypes.d";
import {
    BookEconomics,
    BookLicenseePublizm,
    PUBLIZM_AUDIOBOOK_COVER_FILENAME,
    PUBLIZM_EBOOK_COVER_FILENAME,
} from "./../../../../../../shared/types/BookTypes";
import { BookAdminFormValues, LicenceeCostFormValues } from "./BookAdminTypes";

export class BookAdminMapper {
    static getPriceByType(book: Book, type: BookPriceType): number | undefined {
        return book?.prices?.find((price) => price.type === type)?.value;
    }
    static mapToFormValues(book?: Book, economics?: BookEconomics): Partial<BookAdminFormValues> {
        if (!book) {
            return {};
        }
        const audioBookSample = book.audiobook?.sample;
        const audioBookLicensees = book.audiobook?.licensees;
        const eBookLicensees = book.ebook?.licensees;
        const ebookProductionCost = economics?.products.find((p) => p.type === BookType.EBOOK)?.productionCost;
        const audioBookProductionCost = economics?.products.find((p) => p.type === BookType.AUDIO)?.productionCost;
        return {
            source: book.source,
            mainBook: book.mainBook,
            ebook: {
                productionCost: getNumberOrZero(ebookProductionCost),
                isbn: book.ebook?.isbn ?? 0,
                translator: book.ebook?.translator ?? "",
                sample: {
                    name: book.ebook?.sample?.name,
                    filePath: book.ebook?.sample?.filePath,
                },
                file: {
                    name: book?.ebook?.name,
                    filePath: book.ebook?.filePath,
                },
                kindle: {
                    name: book?.ebook?.kindle?.name,
                    filePath: book.ebook?.kindle?.filePath,
                },
                licenseeCosts: economics?.licensees
                    ?.filter((licensee) => licensee.bookType === BookType.EBOOK)
                    ?.map((licensee) => ({
                        licenseeId: licensee.licenseeId,
                        advance: licensee.advancePaid ?? 0,
                        currentAdvance: licensee.advancePaid ?? 0,
                        deletable: false,
                    })),
                licensees:
                    eBookLicensees && eBookLicensees?.length > 0
                        ? eBookLicensees
                              ?.filter((l) => l.licenseeId !== undefined)
                              ?.map((licensee) => ({
                                  licenseeId: licensee.licenseeId,
                                  name: licensee.name,
                                  percentage: getNumberOrZero(licensee.percentage),
                                  role: licensee.role,
                              }))
                        : [],
                pages: book.ebook?.pages,
                samplePages: book.ebook?.samplePages,
                price: this.getPriceByType(book, BookPriceType.EBOOK),
                cover: {
                    filePath: book.ebook?.coverPath,
                },
            },
            audiobook: {
                type: book.audiobook?.type ?? BookType.AUDIO,
                productionCost: getNumberOrZero(audioBookProductionCost),
                licenseeCosts: economics?.licensees
                    ?.filter(
                        (licensee) => licensee.bookType === BookType.AUDIO || licensee.bookType === BookType.PODCAST
                    )
                    ?.map((licensee) => ({
                        licenseeId: licensee.licenseeId,
                        advance: licensee.advancePaid ?? 0,
                        currentAdvance: licensee.advancePaid ?? 0,
                        deletable: false,
                    })),
                licensees:
                    audioBookLicensees && audioBookLicensees?.length > 0
                        ? audioBookLicensees
                              ?.filter((l) => l.licenseeId !== undefined)
                              ?.map((licensee) => ({
                                  licenseeId: licensee.licenseeId,
                                  name: licensee.name,
                                  percentage: getNumberOrZero(licensee.percentage),
                                  role: licensee.role,
                              }))
                        : [],
                cover: {
                    filePath: book.audiobook?.coverPath,
                },
                price: this.getPriceByType(book, BookPriceType.AUDIOBOOK),
                isbn: book.audiobook?.isbn ?? 0,
                contentType: {
                    audiobook: book.audiobook?.contentType?.some((c) => c === AudioBookContentType.AUDIOBOOK) ?? false,
                    radiodrama:
                        book.audiobook?.contentType?.some((c) => c === AudioBookContentType.RADIODRAMA) ?? false,
                },
                narrator: book.audiobook?.narrator ?? "",
                producer: book.audiobook?.producer ?? "",
                translator: book.audiobook?.translator ?? "",
                sounddesigner: book.audiobook?.sounddesigner ?? "",
                sample: {
                    title: audioBookSample?.title,
                    durationSeconds: audioBookSample?.durationSeconds,
                    file: {
                        filePath: audioBookSample?.filePath,
                        name: audioBookSample?.fileName,
                    },
                },
                files:
                    book?.audiobook?.files?.map((file) => ({
                        title: file.title,
                        durationSeconds: file.durationSeconds,
                        file: {
                            filePath: file.filePath,
                            name: file.fileName,
                        },
                    })) ?? [],
            },
            summary: book?.summary,
            reviews: book?.reviews,
            bookReview: book?.bookReviews?.map((val) => ({
                rating: val.rating ? parseInt(val.rating) : 1,
                content: val.content,
            }))?.[0],
            reviewsReaders: book?.reviewsReaders,
            tags: book?.tags,
            title: book?.title,
            state: book.status,
            author: {
                id: book.author.id,
                profileId: book.author.profileId,
                name: book.author.name,
                profilePicturePath: book.author.profilePicturePath ?? "",
                description: book.author.description ?? "",
                role: ContributorRoleCode[ContributorRoleCode.ByAuthor],
            },
            bundlePrice: this.getPriceByType(book, BookPriceType.BUNDLE),
            language: book?.language,
            publishedYear: book?.publishedYear,
            publisherLocation: book?.publisherLocation,
            publisher: book?.publisher,
        };
    }

    static getBookEconomicsLicenseeAdvancePaid(
        licenseeId: string,
        bookType: BookType,
        bookEconomics?: BookEconomics
    ): number | undefined {
        return bookEconomics?.licensees?.find((l) => l.licenseeId === licenseeId && l.bookType === bookType)
            ?.advancePaid;
    }

    static mapFormValuesToAudioBook(data: BookAdminFormValues): BookFileAudioDto {
        const files =
            data.audiobook.files
                ?.filter((file) => !file.deleted)
                .map((audiobook) => ({
                    title: audiobook.title,
                    fileName: audiobook.file?.name,
                    durationSeconds: audiobook.durationSeconds,
                    data: audiobook.file?.data,
                })) ?? [];
        const sample = data.audiobook.sample;
        if (sample) {
            files.push({
                title: sample.title,
                fileName: sample.file?.name,
                durationSeconds: sample.durationSeconds,
                data: sample.file?.data,
                // @ts-ignore
                sample: true,
            });
        }

        const contentType: AudioBookContentType[] = [];
        data.audiobook?.contentType?.audiobook && contentType.push(AudioBookContentType.AUDIOBOOK);
        data.audiobook?.contentType?.radiodrama && contentType.push(AudioBookContentType.RADIODRAMA);
        const licensees: BookLicenseePublizm[] =
            data.audiobook.licensees
                ?.filter((l) => l.licenseeId !== undefined && !l.removed)
                .map((licensee) => ({
                    source: "publizm",
                    licenseeId: licensee.licenseeId,
                    name: licensee.name,
                    percentage: licensee.percentage,
                    role: licensee.role,
                })) ?? [];
        return {
            // @ts-ignore
            data: files,
            cover: data.audiobook.cover && {
                data: data.audiobook.cover.data!,
                name: PUBLIZM_AUDIOBOOK_COVER_FILENAME,
                type: data.audiobook.cover.type!,
            },
            bookFile: {
                licenseeIds: Array.from(new Set(licensees.map((licensee) => licensee.licenseeId))),
                licensees: licensees,
                type: data.audiobook.type == BookType.PODCAST ? BookType.PODCAST : BookType.AUDIO,
                format: AudioBookFormat.MP3,
                name: "Audiobook",
                contentType: contentType,
                isbn: data.audiobook.isbn,
                translator: data.audiobook.translator,
                sounddesigner: data.audiobook.sounddesigner,
                narrator: data.audiobook.narrator,
                producer: data.audiobook.producer,
                coverPath: data.audiobook?.cover?.filePath ?? "",
            },
        };
    }

    static mapToBookEconomicsLicensee(
        type: BookType,
        licenseeFormValues?: LicenceeCostFormValues[]
    ): BookEconomicsLicensee[] {
        return (
            licenseeFormValues
                ?.filter((l) => l.licenseeId !== undefined && !l.removed)
                ?.map((l) => ({
                    licenseeId: l.licenseeId,
                    bookType: type,
                    advancePaid: getNumberOrZero(l.advance),
                    earnings: 0, // Ignored when updated
                    advanceSettled: 0, // Ignored when updated
                })) ?? []
        );
    }
    static mapToBookPrice(type: BookPriceType, price?: number): BookPrice | undefined {
        if (price) {
            const priceNumeric = parseInt(price.toString());
            return {
                value: priceNumeric,
                type: type,
                originalPrice: priceNumeric,
                currency: Currency.NOK,
            };
        }
    }

    static mapFormValuesToEbook(data: BookAdminFormValues): BookFileEbookDto {
        const licensees: BookLicenseePublizm[] =
            data.ebook.licensees
                ?.filter((l) => l.licenseeId !== undefined && !l.removed)
                .map((licensee) => ({
                    source: "publizm",
                    licenseeId: licensee.licenseeId,
                    name: licensee.name,
                    percentage: licensee.percentage,
                    role: licensee.role,
                })) ?? [];
        return {
            data: data.ebook?.file?.data,
            kindle: data.ebook.kindle?.name
                ? {
                      data: data.ebook.kindle?.data,
                      name: data.ebook.kindle.name!,
                  }
                : undefined,
            sample: data.ebook.sample?.name
                ? {
                      data: data.ebook.sample?.data,
                      name: data.ebook.sample.name!,
                  }
                : undefined,
            bookFile: {
                licenseeIds: Array.from(new Set(licensees.map((licensee) => licensee.licenseeId))),
                licensees: licensees,
                type: BookType.EBOOK,
                format: EbookFormat.EPUB,
                name: data.ebook.file?.name,
                pages: data.ebook.pages ?? -1,
                samplePages: data.ebook.samplePages ?? -1,
                isbn: data.ebook.isbn,
                translator: data.ebook.translator,
                coverPath: data.ebook?.cover?.filePath ?? "",
            },
            cover: data.ebook.cover && {
                data: data.ebook.cover.data!,
                name: PUBLIZM_EBOOK_COVER_FILENAME,
                type: data.ebook.cover.type!,
            },
        };
    }

    static mapFormValuesToBook(formValues: BookAdminFormValues): {
        book: Partial<Book>;
        files: BookFileDto[];
        economics: BookEconomics;
    } {
        const prices = [
            BookAdminMapper.mapToBookPrice(BookPriceType.EBOOK, formValues.ebook.price),
            BookAdminMapper.mapToBookPrice(BookPriceType.AUDIOBOOK, formValues.audiobook.price),
            BookAdminMapper.mapToBookPrice(BookPriceType.BUNDLE, formValues.bundlePrice),
        ].filter(notEmpty);

        const economicsLicensees = [
            ...BookAdminMapper.mapToBookEconomicsLicensee(BookType.EBOOK, formValues.ebook.licenseeCosts),
            ...BookAdminMapper.mapToBookEconomicsLicensee(BookType.AUDIO, formValues.audiobook.licenseeCosts),
        ];

        const economics: BookEconomics = {
            products: [
                {
                    type: BookType.EBOOK,
                    productionCost: getNumberOrZero(formValues.ebook.productionCost),
                    earnings: 0, // Ignored when saving
                },
                {
                    type: BookType.AUDIO,
                    productionCost: getNumberOrZero(formValues.audiobook.productionCost),
                    earnings: 0, // Ignored when saving
                },
            ],
            totalEarnings: 0, // Ignored when saving,
            licensees: economicsLicensees,
            totalSales: 0,
            licenseeIds: Array.from(new Set(economicsLicensees?.map((l) => l.licenseeId) ?? [])),
        };
        const book: Partial<Book> = {
            source: formValues.source ?? "publizm",
            bookType: formValues?.audiobook?.type == BookType.PODCAST ? BookType.PODCAST : BookType.BUNDLE, // TODO: Skal default være bundle? Det vil si at både lydbok/ebok er i samme book objekt,
            mainBook: formValues.mainBook == undefined ? true : formValues.mainBook,
            title: formValues.title,
            summary: formValues.summary,
            reviews: formValues.reviews,
            bookReviews: formValues.bookReview.content
                ? [
                      {
                          content: formValues.bookReview.content,
                          rating: formValues.bookReview.rating.toString(10),
                          ratingType: "Terningkast",
                          ratingLimit: "6",
                          author: "Publizm",
                          source: "Publizm",
                      },
                  ]
                : [],
            reviewsReaders: formValues.reviewsReaders,
            tags: formValues.tags,
            author: {
                id: formValues.author?.id ?? "",
                profileId: formValues.author?.profileId ?? "",
                name: formValues.author?.name ?? "",
                description: formValues.author?.description ?? "",
                profilePicturePath: formValues.author?.profilePicturePath ?? "",
                role: ContributorRoleCode[ContributorRoleCode.ByAuthor],
            },
            language: formValues.language as Language,
            publisher: formValues.publisher,
            publishedYear: formValues.publishedYear,
            publisherLocation: formValues.publisherLocation,
            status: formValues.state,
            prices: prices,
        };

        const files: BookFileDto[] = [];
        formValues.ebook && files.push(BookAdminMapper.mapFormValuesToEbook(formValues));
        formValues.audiobook && files.push(BookAdminMapper.mapFormValuesToAudioBook(formValues));

        return {
            book,
            files,
            economics,
        };
    }
}
