import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import PzText from "components/text/PzText";
import { Column, Columns, Content, Label } from "components/trunx";
import { useNavigate } from "react-router";

import { AppPath } from "../../../../../common/AppPath";
import PzIconButton from "../../../../../components/form/PzIconButton";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import useBookApi from "../../../../../services/firestore/hooks/useBookApi";

export interface AuthorProfileCollapsibleProps {
    description: string;
    authorProfileId: string;
    onEditProfile?: () => void;
}

export default function AuthorProfileInfoCollapsible({
    description,
    authorProfileId,
    onEditProfile,
}: AuthorProfileCollapsibleProps) {
    return (
        <Columns isFlex isFlexDirectionRow p4>
            <Column isFlex isFlexDirectionRow isFlexGrow5 p5>
                <Column isFlex isFlexGrow2 isFlexDirectionColumn>
                    <Label>Beskrivelse:</Label>
                    <PzText p0 m0 containerProps={{ m0: true, p0: true }}>
                        {description}
                    </PzText>
                </Column>
                <AuthorTitles authorProfileId={authorProfileId} />
            </Column>
            <Column isFlex isJustifyContentFlexEnd isFlexDirectionColumn>
                <PzIconButton icon={faArrowRight} onClick={onEditProfile}>
                    Rediger Profil
                </PzIconButton>
            </Column>
        </Columns>
    );
}

interface AuthorTitlesProps {
    authorProfileId: string;
}
function AuthorTitles({ authorProfileId }: AuthorTitlesProps) {
    const navigate = useNavigate();
    const books = useBookApi().getAllByAuthorProfileId(authorProfileId);
    if (books.isLoading) {
        return <LoadingIndicator />;
    }
    return (
        <Column isFlex isFlexDirectionColumn>
            <Label pl5 ml4>
                Titler:
            </Label>
            <Content isFlex isFlexDirectionColumn>
                {books.data!.map((book) => {
                    return (
                        <Column isFlex>
                            <PzText pr0 mr0 pt1 isAlignItemsSelfStart>
                                {book.title}
                            </PzText>
                            <PzIconButton
                                buttonProps={{ pl0: true, ml0: true }}
                                isSmall
                                icon={faArrowRight}
                                onClick={() =>
                                    book.source == "publizm"
                                        ? navigate(AppPath.BOOK_MANAGEMENT + "?id=" + book.id)
                                        : navigate(AppPath.BOOK_DETAILS + "?id=" + book.id)
                                }
                            />
                        </Column>
                    );
                })}
            </Content>
        </Column>
    );
}
