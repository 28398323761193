import { ReactElement, useState } from "react";
import { UserMetadata, UserRole } from "shared";

import { useAuth } from "../../contexts/AuthContext";
import { UserService } from "../../services/firestore/UserService";
import UserInfoModal, { UserModalData } from "../modal/UserInfoModal";

interface ShowUserInfoModalProps {
    userMetadata?: UserMetadata;
}

export default function ShowUserInfoModalLink({ userMetadata }: ShowUserInfoModalProps): ReactElement {
    const [showUserInfo, setShowUserInfo] = useState<UserModalData>();
    const { activeRole } = useAuth();
    const isCurrentUserAdmin = activeRole === UserRole.ADMIN;
    const openUserInfoModal = () => {
        if (userMetadata && isCurrentUserAdmin) {
            new UserService().getByUserId(userMetadata.userId).then((userInfo) => {
                const userModalData: UserModalData = {
                    name: userInfo?.name ?? "",
                    role: userInfo?.activeRole,
                    tags: userInfo?.userInfo.tags,
                    telephoneNumber: userInfo?.userInfo.telephoneNumber,
                    email: userInfo?.userInfo.email ?? "",
                };
                setShowUserInfo(userModalData);
            });
        } else {
            setShowUserInfo(userMetadata);
        }
    };
    const closeUserInfoModal = () => setShowUserInfo(undefined);
    const userName = userMetadata?.name ?? "-";
    const hasContactInfo = isCurrentUserAdmin || userMetadata?.email || userMetadata?.telephoneNumber;
    return (
        <>
            {userName ? (
                <>
                    <UserInfoModal user={showUserInfo} onClose={closeUserInfoModal} />
                    {hasContactInfo ? <a onClick={() => openUserInfoModal()}>{userName}</a> : <>{userName}</>}
                </>
            ) : (
                "-"
            )}
        </>
    );
}
