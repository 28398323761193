import { UPLOAD_STATE } from "common/types";
import { AgreementDashboardMenu, DashboardMenuType } from "components/dashboard/Menu/DashboardUserMenu";
import PzButton from "components/form/PzButton";
import FeedbackModal from "components/modal/FeedbackModal";
import PzSelectDropdown, { OptionProps } from "components/PzSelectDropdown";
import RenderLoadingIndicator from "components/RenderLoadingIndicator";
import { Column, Columns, Content, Textarea, Title } from "components/trunx";
import { Timestamp } from "firebase/firestore";
import { ReactElement, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { AgreementService } from "services/firestore/AgreementService";
import logger from "services/logging/LoggerService";
import { Agreement, agreementDisplayValue, AgreementType, UserRole, userRoleDisplayValue } from "shared";

import AgreementContent from "../../components/pagetemplate/AgreementContent";

const animatedComponents = makeAnimated();

interface AgreementCreateProps {
    updateSelectedMenu?: (selectedMenu: string | DashboardMenuType) => void;
    updateAgreementData?: () => void;
    initialAgreement?: Agreement;
}

export default function AgreementUpdatePage({
    updateSelectedMenu,
    updateAgreementData,
    initialAgreement,
}: AgreementCreateProps): ReactElement {
    const [uploadState, setUploadState] = useState<UPLOAD_STATE>("STALE");
    const [error, setError] = useState("");
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const openFeedbackModal = () => setShowFeedbackModal(true);
    const closeFeedbackModal = () => setShowFeedbackModal(false);
    const initalValues = {
        agreementContent: "",
        agreementType: AgreementType.PRIVACY,
        roles: [],
        date: Timestamp.now(),
    };
    const [agreement, setAgreement] = useState<Agreement>(
        initialAgreement ? Object.assign({}, initialAgreement, { date: Timestamp.now() }) : initalValues
    );

    agreementTypesValues.forEach((opt) => {
        opt.isDefault = opt.value === agreement.agreementType;
    });

    function updateData(data: Partial<Agreement>): void {
        setAgreement((prevState) => ({
            ...prevState,
            ...data,
        }));
    }

    function updateRoles(roles) {
        const choosenRoles: UserRole[] = [];
        roles.forEach((element) => {
            choosenRoles.push(element.value);
        });
        updateData({ roles: choosenRoles });
    }

    function convertAgreementRoles(agreementRoles: UserRole[]) {
        return agreementRoles.map((role) => userRolesValues.find((userRole) => userRole.value === role));
    }

    async function uploadNewAgreement(agreement: Agreement): Promise<void> {
        setUploadState("UPLOADING");
        setError("");
        await new AgreementService()
            .createNewAgreement(agreement)
            .then(async () => {
                setUploadState("UPLOADED");
                updateAgreementData && (await updateAgreementData());
                updateSelectedMenu && updateSelectedMenu(AgreementDashboardMenu.NEWEST);
            })
            .catch((error) => {
                setError("Det skjedde en feil. Prøv igjen");
                logger.error(error);
            });
    }

    function setAgreementType(e: string) {
        agreementTypesValues.forEach((type) => {
            if (e === type.value) {
                updateData({ agreementType: type.value });
            }
        });
    }

    return (
        <Column>
            <Title mb5 style={{ fontWeight: 500, fontSize: "54px" }}>
                Opprett avtale{" "}
            </Title>
            <Column isPaddingLess pb3>
                <p>
                    Avtalene skrives i formatet <a href="https://www.markdownguide.org/basic-syntax/">markdown</a>.
                </p>
                <p>
                    Du kan skrive i markdown direkte på feltet nedenfor, eller bruke redigeringsverktøy som{" "}
                    <a href="https://dillinger.io/">dillinger.io</a> eller{" "}
                    <a href="https://stackedit.io/app#">stackedit.io</a>.
                </p>
            </Column>

            {renderSubTitle("Velg avtaletype")}
            <PzSelectDropdown options={agreementTypesValues} onChanged={(e: string) => setAgreementType(e)} />

            {renderSubTitle("Velg roller knyttet til avtale")}
            <Select
                options={userRolesValues}
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={convertAgreementRoles(agreement.roles)}
                isMulti
                onChange={(e) => updateRoles(e)}
            />

            <Columns pb0 mb0>
                <Column>
                    {renderSubTitle("Legg inn avtaleinnhold (markdown)")}
                    <Textarea
                        mt3
                        mb4
                        rows={10}
                        defaultValue={agreement.agreementContent}
                        onChange={(e) => updateData({ agreementContent: e.target.value })}
                    />
                </Column>
                <Column>
                    {renderSubTitle("Forhåndsvisning")}
                    <Content
                        hasBackgroundWhite
                        pt0
                        pr4
                        pl4
                        style={{ height: "calc(100% - 73px)", border: "1px solid black" }}
                    >
                        <AgreementContent content={agreement.agreementContent ?? ""} />
                    </Content>
                </Column>
            </Columns>
            <Column hasTextCentered pb3 className={"is-vcentered"}>
                <Columns hasTextCentered pt2 pb3 style={{ margin: "0 auto", maxWidth: "400px" }}>
                    <Column hasTextCentered>
                        <PzButton isPrimary onClick={openFeedbackModal}>
                            Opprett avtale
                        </PzButton>
                    </Column>
                </Columns>
                <RenderLoadingIndicator state={uploadState} />
            </Column>

            <FeedbackModal
                content="Er du sikker på at du vil opprette avtalen? Husk å fylle inn alle felt."
                isActive={showFeedbackModal}
                onAcceptText="Opprett avtale"
                onClose={closeFeedbackModal}
                onAccept={() => {
                    uploadNewAgreement(agreement);
                    setShowFeedbackModal(false);
                }}
            />
        </Column>
    );
}

const agreementTypesValues: OptionProps[] = Object.keys(AgreementType).map((key) => ({
    value: AgreementType[key],
    label: agreementDisplayValue(AgreementType[key]),
}));

const userRolesValues = [getRoleValue(UserRole.AUTHOR), getRoleValue(UserRole.EDITOR), getRoleValue(UserRole.CUSTOMER)];

function getRoleValue(role: UserRole): { label: string; value: UserRole } {
    return { value: role, label: userRoleDisplayValue(role) };
}

function renderSubTitle(title: string) {
    return (
        <Title is6 style={{ fontWeight: 600 }} mb3 mt5>
            {title}
        </Title>
    );
}
