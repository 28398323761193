import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Card, Column, Label } from "components/trunx";
import { formatDate } from "components/utils/DateUtils";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import PzIconButton from "../../components/form/PzIconButton";
import SbEditableContainer from "../../components/storyblok/SbEditableContainer";
import PzText from "../../components/text/PzText";
import { PzTitle } from "../../components/text/PzTitle";
import ArticleMetadata from "./ArticleMetadata";
import SocialMediaButtons from "./SocialMediaButtons";
import { ArticleItem } from "./types";

interface ArticleCardProps {
    item: ArticleItem;
    isSmall?: boolean;
}
export default function ArticleCard({ item, isSmall }: ArticleCardProps): ReactElement {
    const articleUrl = `${window.location.origin}/news/${item.slug}`;
    if (isSmall) {
        return <SmallArticleCard item={item} articleUrl={articleUrl} />;
    } else {
        return <DefaultArticleCard item={item} articleUrl={articleUrl} />;
    }
}

interface ArticleCardItemProps {
    item: ArticleItem;
    articleUrl: string;
}

function SmallArticleCard({ item, articleUrl }: ArticleCardItemProps) {
    const navigate = useNavigate();
    function gotoArticle() {
        navigate("/news/" + item.slug);
    }

    return (
        <article className="column is-9">
            <Card
                className="smallArticle column is-9"
                isClickable
                style={{ borderRadius: "8px", height: "100%", width: "100%", flexShrink: 0 }}
                isFlex
                isJustifyContentFlexStart
                isAlignItemsFlexStart
                onClick={gotoArticle}
            >
                <Card.Image
                    isFlexGrow5
                    src={item.content.image?.filename}
                    style={{ borderRadius: "8px", maxWidth: "120px" }}
                />
                <Card.Content
                    style={{ width: "100%" }}
                    className="ml-5"
                    isFlex
                    isJustifyContentSpaceBetween
                    isFlexDirectionColumn
                    isFlexGrow0
                    p2
                >
                    <PzText pb1 m0 containerProps={{ m0: true, p0: true }}>
                        Oppdatert {formatDate(new Date(item.published_at ?? item.created_at))}
                        {item.content.publisher && <span className="publisher"> - {item.content.publisher}</span>}
                    </PzText>
                    <h4 className="mb-1 text-2xl">{item.content.title}</h4>
                    <p>{item.content.preview}</p>

                    <div className="detailsLink">
                        <a href={`/news/${item.slug}`}>Les mer</a>
                    </div>
                </Card.Content>
            </Card>
        </article>
    );
}

function DefaultArticleCard({ item, articleUrl }: ArticleCardItemProps) {
    return (
        <>
            {/** @ts-ignore */}
            <SbEditableContainer content={item}>
                <a href={articleUrl}>
                    <Column pt2 pb2 m0>
                        <Card style={{ borderRadius: "10px" }}>
                            <Card.Image
                                src={item.content.image.filename}
                                style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
                            />
                            <Label p2 hasTextGrey>
                                {item.content.imagecaption}
                            </Label>
                            <Card.Content pl6 pr6 mr4 ml4 pb0 mb0>
                                <PzTitle hasTextCentered isSize1 hasTextWeightBold>
                                    {item.content.title}
                                </PzTitle>

                                <PzText isSize4 p0 m0 containerProps={{ m0: true, p0: true }}>
                                    {item.content.preview}
                                </PzText>

                                <Column pt6 mt6 pb0 mb0>
                                    <ArticleMetadata item={item} />
                                    <Column p0 m0 isFlex isFlexDirectionRow isJustifyContentSpaceBetween>
                                        <PzIconButton buttonProps={{ p0: true, m0: true }} icon={faArrowRight} isSmall>
                                            <PzText
                                                mt1
                                                mb1
                                                containerProps={{ m0: true, p0: true, pr4: true }}
                                                isSize4
                                                hasTextWeightBold
                                            >
                                                <a>Les mer</a>
                                            </PzText>
                                        </PzIconButton>
                                        <SocialMediaButtons id={item.id} />
                                    </Column>
                                </Column>
                            </Card.Content>
                        </Card>
                    </Column>
                </a>
            </SbEditableContainer>
        </>
    );
}
