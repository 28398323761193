import PageTemplate from "components/pagetemplate/PageTemplate";
import { Container } from "components/trunx";
import SignUpAuthorPanel from "pages/useradmin/signup/author/SignUpAuthorPanel";

export default function SignUpAuthorPage() {
    return (
        <PageTemplate>
            <Container m0 p0>
                <SignUpAuthorPanel />
            </Container>
        </PageTemplate>
    );
}
