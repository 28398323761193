import { Box, Modal } from "components/trunx";
import React, { CSSProperties, HTMLAttributes, PropsWithChildren, ReactElement } from "react";
import { createPortal } from "react-dom";

export interface PzModalProps extends HTMLAttributes<HTMLDivElement> {
    isActive: boolean;
    onClose?: () => void;
    contentStyle?: CSSProperties;
}

export default function PzModal(props: PropsWithChildren<PzModalProps>): ReactElement {
    const { isActive, onClose, children, contentStyle } = props;

    const renderModal = () => {
        return (
            <Modal isActive={isActive} style={{ position: "fixed", zIndex: 100000000000 }} >
                <Modal.Background onClick={onClose} />
                <Modal.Content style={{ minHeight: "200px", minWidth: "200px", width: "fit-content", ...contentStyle }}>
                    <Box>{children}</Box>
                </Modal.Content>
            </Modal>
        );
    };
    return <>{createPortal(renderModal(), document.body)}</>;
}
