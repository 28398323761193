import { Timestamp } from "firebase/firestore";
export function formatDate(dateValue?: unknown): string | undefined {
    if (!dateValue) {
        return;
    }
    if (typeof dateValue == "number") {
        return new Date(dateValue).toLocaleDateString("nb-NO", { year: "numeric", month: "2-digit", day: "2-digit" });
    }
    const firestoreDate = dateValue as Timestamp;
    let date = dateValue as Date;
    if (firestoreDate["toDate"]) {
        date = firestoreDate.toDate();
    } else if (firestoreDate.seconds && firestoreDate.nanoseconds) {
        date = new Timestamp(firestoreDate.seconds, firestoreDate.nanoseconds).toDate();
    }
    return date.toLocaleDateString("nb-NO", { year: "numeric", month: "2-digit", day: "2-digit" });
}

export function getYearOrDateFromValue(value: string): Date | undefined {
    const regex = /[<>=](\d{2}\.\d{2}\.\d{4})/;
    const dateMatch = regex.exec(value);
    const hasDate = dateMatch && dateMatch.length > 1;
    if (hasDate) {
        const dateString = dateMatch![1];
        const [day, month, year] = dateString.split(".");
        //@ts-ignore
        return new Date(year, month - 1, day);
    }
    const regexYear = /[<>=](\d{4})/;
    const yearMatch = regexYear.exec(value);
    if (yearMatch && yearMatch.length > 1) {
        return new Date(yearMatch![1]);
    }
}
