import { Button, Content, Control, Field } from "components/trunx";
import { removeKeys, removeNullOrUndefinedKeys } from "components/utils/ObjectUtils";
import { FirebaseAuthErrorCode, useAuth } from "contexts/AuthContext";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UserService } from "services/firestore/UserService";
import { UserInfo } from "shared";

import UpdateUserInfoForm from "../../components/auth/UpdateUserInfo";

export interface UpdateUserInfoProps {
    userInfo?: UserInfo;
}

export default function UpdateUserInfo({ userInfo }: UpdateUserInfoProps) {
    const [succsessfulUpdateUserInfo, setSuccsessfulUpdateUserInfo] = useState<boolean>(false);

    const methods = useForm<UserInfo>({
        mode: "onBlur",
        defaultValues: {
            firstname: userInfo?.firstname,
            surname: userInfo?.surname,
            email: userInfo?.email,
            telephoneNumber: userInfo?.telephoneNumber,
            emailKindle: userInfo?.emailKindle,
        },
    });

    const { handleSubmit } = methods;
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { currentUser, updateEmail } = useAuth();

    const onSubmit = (userInfo) => handleSubmitUserInfo(userInfo);

    async function handleSubmitUserInfo(userInfo) {
        setError("");
        setLoading(true);
        await updateEmail(userInfo.email)
            .then(() => {
                updateUserInfo(userInfo);
            })
            .catch((err) => {
                if (
                    err.code === FirebaseAuthErrorCode.EMAIL_EXISTS ||
                    err.code === FirebaseAuthErrorCode.EMAIL_IN_USE
                ) {
                    setError("Eposten er allerede i bruk");
                } else {
                    setError("Det skjedde en feil. Vennligst forsøk å logge deg av og på for så å prøve på nytt.");
                }
                setSuccsessfulUpdateUserInfo(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function updateUserInfo(userInfo) {
        const uploadUserInfo = removeNullOrUndefinedKeys(removeKeys(userInfo, ["passwordConfirm", "password"]));

        currentUser &&
            new UserService()
                .updateUserInfo(uploadUserInfo, currentUser?.uid)
                .then(() => {
                    setError("");
                    setSuccsessfulUpdateUserInfo(true);
                })
                .catch(() => {
                    setError("Oppdatering feilet, prøv igjen");
                    setSuccsessfulUpdateUserInfo(false);
                })
                .finally(() => {
                    setLoading(false);
                });
    }

    return (
        <>
            {error && <div className="notification is-danger"> {error} </div>}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <UpdateUserInfoForm
                        telephoneNumberRequired={false}
                        defaultTelephoneNumber={userInfo?.telephoneNumber}
                        registerKindleEmail
                    />
                    <Field isGrouped className="mt-5">
                        <Control>
                            <Button isPrimary onClick={handleSubmit(onSubmit)} disabled={loading} isLoading={loading}>
                                Oppdater profil
                            </Button>
                            {succsessfulUpdateUserInfo && (
                                <Content hasTextSuccess>Din oppdatering av brukerprofil var vellykket.</Content>
                            )}
                        </Control>
                    </Field>
                </form>
            </FormProvider>
        </>
    );
}
