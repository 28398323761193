import { Container } from "components/trunx";
import React from "react";

import PageTemplate from "../../../components/pagetemplate/PageTemplate";
import LoginPagePanel from "./LoginPanel";

export default function LoginPage() {
    return (
        <PageTemplate>
            <Container p5>
                <LoginPagePanel />
            </Container>
        </PageTemplate>
    );
}
