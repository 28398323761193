import "./Mainpage.css";

import PzButton from "components/form/PzButton";
import PzSection from "components/layout/PzSection";
import PzDivider from "components/pz/PzDivider";
import FeaturedBooksSlideshow from "pages/storyblok/FeaturedBooksSlideshow";
import { useNavigate } from "react-router-dom";

import PageTemplate from "../../components/pagetemplate/PageTemplate";
import { sbButtonLinkToToPath } from "../../components/storyblok/StoryblokComponents";
import StoryblokWrapper from "../../components/storyblok/StoryblokWrapper";
import { IMainPage } from "../storyblok/types";

export default function MainpageStoryblok() {
    const navigate = useNavigate();

    return (
        <PageTemplate className="mainpage" renderSection={false} marginLessTop>
            <div>
                <StoryblokWrapper slug={`landing`}>
                    {(content: IMainPage) => {
                        return (
                            <>
                                <PzSection style={{ paddingBottom: "2rem" }}>
                                    <div className="mainpage-content">
                                        <div style={{ maxWidth: "800px" }}>
                                            <h1 className="mainpage-title mb-4 text-6xl">{content.content.tittel}</h1>
                                            <p
                                                className="mainpage-ingress text-2xl"
                                                style={{ paddingRight: 8, marginBottom: 16 }}
                                            >
                                                {content.content.paragraf1}
                                            </p>
                                            <PzButton
                                                className="text-xl is-fullwidth-mobile"
                                                style={{
                                                    backgroundColor: "var(--persimon)",
                                                    color: "white",
                                                    marginBottom: 16,
                                                }}
                                                onClick={() =>
                                                    navigate(sbButtonLinkToToPath(content.content.knapp[0].linkTo))
                                                }
                                            >
                                                {content.content.knapp[0].text}
                                            </PzButton>
                                            <p className="text-lg">{content.content.paragraf2}</p>
                                        </div>
                                        <div className="mainpage-visual-container">
                                            <img
                                                src={content.content.bilde.filename}
                                                id="mainpage-visual"
                                                alt="artistic illustration of a hand with a book booquet"
                                            />
                                        </div>
                                    </div>
                                </PzSection>
                                <PzSection style={{ backgroundColor: "var(--almond-200)", paddingTop: "1rem" }}>
                                    <div className="mainpage-content">
                                        <div style={{ width: "100%" }}>
                                            <div className="mainpage-center-on-tablet">
                                                <h2 style={{ marginBottom: 0 }}>{content.content.aktueltTittel}</h2>
                                                <PzDivider />
                                            </div>
                                            <FeaturedBooksSlideshow />
                                        </div>
                                    </div>
                                </PzSection>
                                <PzSection>
                                    <div className="mainpage-content">
                                        <div style={{ width: "100%" }}>
                                            <div className="mainpage-center-on-tablet mb-4">
                                                <h2 style={{ marginBottom: 0 }}>{content.content.publizerTittel}</h2>
                                                <PzDivider color="secondary" />
                                            </div>
                                            <div className="mainpage-philosophy">
                                                <div>
                                                    <p className="mb-3 text-lg">{content.content.publizerParagraf}</p>

                                                    <PzButton
                                                        className="text-lg mt-2 is-fullwidth-mobile"
                                                        style={{ backgroundColor: "var(--ocean)", color: "white" }}
                                                        onClick={() =>
                                                            navigate(
                                                                sbButtonLinkToToPath(
                                                                    content.content.publizerKnapp[0].linkTo
                                                                )
                                                            )
                                                        }
                                                    >
                                                        {content.content.publizerKnapp[0].text}
                                                    </PzButton>
                                                </div>
                                                <img
                                                    src={content.content.publizerBilde.filename}
                                                    alt="artistic illustration of lightbulbs in a network tree"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </PzSection>
                            </>
                        );
                    }}
                </StoryblokWrapper>
            </div>
        </PageTemplate>
    );
}
