import "./PzTable.css";

import { Column, Columns, Content, Section } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";

import LoadingIndicator from "../LoadingIndicator";
import PzText from "../text/PzText";
import PzPaginate, { PzCustomPagination } from "./PzPaginate";
import TableBody, { createRowType } from "./TableBody";
import TableHead, { ColumnData } from "./TableHead";
import TableSearch, { PzTableCustomSearch } from "./TableSearch";

function getValue<T>(data: T, key: string): any {
    const splitByDot = key.split(".");
    let value = data;
    splitByDot.forEach((key) => {
        if (value === undefined) {
            return;
        }
        //@ts-ignore
        value = value[key];
    });
    return value;
}

function descendingComparator<T>(a: T, b: T, orderBy: string) {
    const A_FIRST = 1;
    const B_FIRST = -1;
    const SAME = 0;

    const aValue = getValue(a, orderBy);
    const bValue = getValue(b, orderBy);

    // Handle null values
    if (!aValue && !bValue) {
        return SAME;
    } else if (!bValue) {
        return A_FIRST;
    } else if (!aValue) {
        return B_FIRST;
    }

    if (typeof bValue === "string") {
        return bValue.localeCompare(aValue);
    }

    if (bValue < aValue) {
        return A_FIRST;
    } else if (bValue > aValue) {
        return B_FIRST;
    } else {
        return SAME;
    }
}

export type SortOrder = "asc" | "desc";

function getComparator(order: SortOrder, columnData: ColumnData): (a: any, b: any) => number {
    if (columnData.customComparator) {
        return order === "desc"
            ? (a, b) => columnData.customComparator!(a, b)
            : (a, b) => -columnData.customComparator!(a, b);
    }
    if (columnData.sortByProperty) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, columnData.sortByProperty!)
            : (a, b) => -descendingComparator(a, b, columnData.sortByProperty!);
    }

    return (a, b) => 0;
}

function sortRows<T>(array: T[], comparator?: (a: T, b: T) => number) {
    if (!comparator) {
        return array;
    }
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export interface PzTableProps<T> {
    columns: ColumnData[];
    createRow: createRowType<T>;
    // Used to identify row after sorting and data change
    // Using rowIndex wont work if data changes
    rowKeyGenerator?: (data: T) => string;
    data?: T[];
    searchData?: (data: T) => string;
    pagination?: PzCustomPagination;
    search?: PzTableCustomSearch;
    id?: string;
    isNarrow?: boolean;
    isFullwidth?: boolean;
    isHoverable?: boolean;
    isBordered?: boolean;
    isStriped?: boolean;
    isShadowless?: boolean;
    tableAddOn?: ReactElement;
    defaultSortIndex?: number;
    loading?: boolean;
    enablePagination?: boolean;
    defaultPagination?: number;
    enableExternalDataUpdate?: boolean; // Used to support changing data from parent component
}

export default function PzTable<T>(props: PzTableProps<T>): ReactElement {
    const { enablePagination = true } = props;
    const { enableExternalDataUpdate = false } = props;
    const [order, setOrder] = useState<SortOrder>("asc");
    const [orderByIndex, setOrderByIndex] = useState<number>();
    const [viewedData, setViewedData] = useState<T[] | undefined>(props.data);
    const [filteredData, setFilteredData] = useState<T[]>(props.data ?? []);
    const [filteredAndSortedData, setFilteredAndSortedData] = useState<T[]>(props.data ?? []);

    useEffect(() => {
        if (props.defaultSortIndex) {
            setOrderByIndex(props.defaultSortIndex);
        }
    }, []);

    function onSortClick(colIndex: number) {
        let newOrderByIndex: number | undefined = colIndex;
        let newOrder: SortOrder = "asc";
        if (colIndex === orderByIndex) {
            newOrderByIndex = order === "desc" ? undefined : colIndex;
            newOrder = order === "asc" ? "desc" : "asc";
        }

        setOrderByIndex(newOrderByIndex);
        setOrder(newOrder);
    }

    useEffect(() => {
        enableExternalDataUpdate && setViewedData(props.data);
    }, [props.data]);

    useEffect(() => {
        const sortedData = sortRows(
            filteredData,
            orderByIndex !== undefined ? getComparator(order, props.columns[orderByIndex]) : undefined
        );
        setFilteredAndSortedData(sortedData);
    }, [order, orderByIndex, filteredData]);

    function getTableClassName() {
        const classNames = ["table"];
        props.isNarrow && classNames.push("is-narrow");
        props.isFullwidth && classNames.push("is-fullwidth");
        props.isHoverable && classNames.push("is-hoverable");
        props.isStriped && classNames.push("is-striped");
        props.isBordered && classNames.push("is-bordered");
        props.isShadowless && classNames.push("is-shadowless");
        return classNames.join(" ");
    }

    return (
        <>
            <Columns isInlineFlexMobile>
                {props.tableAddOn && (
                    <Column pb0 mb0 isFlex isJustifyContentFlexStart>
                        {props.tableAddOn}
                    </Column>
                )}
                <TableSearch
                    originalData={props.data}
                    onFilteredDataChange={setFilteredData}
                    searchData={props.searchData}
                    customSearch={props.search}
                />
            </Columns>

            <div className="b-table">
                <div className="table-wrapper has-mobile-cards">
                    <table className={getTableClassName()} id={props.id} style={{ width: "100%" }}>
                        <TableHead
                            columns={props.columns}
                            onSortClick={onSortClick}
                            sortOrder={order}
                            sortColumnIndex={orderByIndex}
                        />
                        {viewedData === undefined || props.loading ? (
                            <tbody>
                                <tr className="is-empty">
                                    <td colSpan={9999999}>
                                        <Section>
                                            <Content hasTextCentered hasTextGrey>
                                                <p>
                                                    <PzText>Laster...</PzText>
                                                    <LoadingIndicator size="L" />
                                                </p>
                                            </Content>
                                        </Section>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <TableBody
                                columns={props.columns}
                                createRow={props.createRow}
                                rowKeyGenerator={props.rowKeyGenerator}
                                data={viewedData}
                            />
                        )}
                    </table>
                </div>
                {props.data !== undefined && enablePagination && (
                    <PzPaginate
                        viewedData={filteredAndSortedData}
                        data={props.data}
                        onPaginationChange={setViewedData}
                        defaultPagination={props.defaultPagination}
                        customPagination={props.pagination}
                    />
                )}
            </div>
        </>
    );
}
