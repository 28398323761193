import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PzIconButton from "components/form/PzIconButton";
import LoadingIndicator from "components/LoadingIndicator";
import PzModal from "components/modal/PzModal";
import { Modal } from "components/trunx";
import { useState } from "react";

import { BookQueries } from "../../../../services/firestore/queries/book/bookQueries";
import { AnalyticsApi } from "../../../../services/firestore/queries/queriesCommon";

interface DownloadAudiobookProps {
    bookId: string;
}

export default function DownloadAudiobook({ bookId }: DownloadAudiobookProps) {
    const [showModal, setShowModal] = useState<boolean>(false);

    const { data: audiobook, isLoading } = BookQueries.loadAudiobook(bookId, true, showModal);

    const downloadLink = audiobook?.[0]?.src;

    const closeModal = () => {
        setShowModal(false);
    };

    function renderBody() {
        return (
            <div className="epub-download">
                <p>Last ned lydbok ved å trykke på lenken</p>
                <br />
                <a
                    href={downloadLink}
                    download
                    onClick={() => {
                        AnalyticsApi.logEvent("download_audiobook", { bookId });
                    }}
                >
                    <FontAwesomeIcon icon={faArrowCircleDown} />
                    <span>Last ned</span>
                </a>
            </div>
        );
    }
    return (
        <span>
            <PzIconButton iconPlacement="left" isSmall icon={faArrowCircleDown} onClick={() => setShowModal(true)}>
                <span style={{ textDecoration: "underline" }}>Last ned lydfil</span>
            </PzIconButton>
            <PzModal isActive={showModal} onClose={closeModal}>
                <Modal.Card.Body style={{ justifyContent: "center" }}>
                    {isLoading ? (
                        <div>
                            <LoadingIndicator />
                            <p>Forbereder nedlasting</p>
                        </div>
                    ) : (
                        renderBody()
                    )}
                </Modal.Card.Body>
            </PzModal>
        </span>
    );
}
