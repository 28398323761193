import { collectionGroup, getDocs, Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
    AudioBookFile,
    Author,
    AuthorProfile,
    AuthorProfileVersionMapper,
    AuthorVersionMapper,
    Book,
    BookEconomics,
    BookEconomicsLicensee,
    BookEconomicsVersionMapper,
    BookFileAudioBook,
    BookFileEbook,
    BookType,
    BookVersionMapper,
    Email,
    FilePath,
    FirestoreCollection,
    getEbookFilename,
    getIfNotempty,
    removeNullOrUndefinedKeys,
    UrlUtils,
} from "shared";

import { getFileUUIDFromFilePath } from "../../components/utils/FirestoreFilePathUtils";
import { firestore, functions, functionsEuropeNorth } from "../../firebase";
import { FirebaseStorageFolder, FirebaseUploadFile } from "../storage/types";
import { FirebaseStorage } from "./../storage/StorageService";
import { FirestoreService, Query } from "./FirestoreService";
import { getStorageAuthorProfilepictureUrl } from "./hooks/useBookApi";
import {
    AuthorData,
    AuthorProfileData,
    AuthorProfileDto,
    BookData,
    BookEconomicsData,
    BookFileAudioDto,
    BookFileDto,
    BookFileEbookDto,
    DocumentData,
    EbookFile,
    isBookFileAudioBook,
    isBookFileEbook,
} from "./types";

export class BookService extends FirestoreService<Book> {
    ebookSample = httpsCallable<{ bookId: string }, string>(functionsEuropeNorth, "epubSampleLink");
    createBookLinkFn = httpsCallable<
        { bookId: string; path: string | null; type: string; downloadable?: boolean },
        string
    >(functionsEuropeNorth, "createBookLink");
    sendEbookToKindleFn = httpsCallable(functionsEuropeNorth, "deliverEmailAttachment");
    private storageService = new FirebaseStorage().withPath(FirebaseStorageFolder.BOOKS);

    constructor() {
        super(FirestoreCollection.BOOK, new BookVersionMapper());
    }

    protected mapBookData(includeEconomics: boolean, docDataList: DocumentData<Book>[]): Promise<BookData[]> {
        if (includeEconomics) {
            return Promise.all(
                docDataList
                    .map((data) => {
                        return new BookService.BookEconomicsService(data.id)
                            .get()
                            .then((economics) => this.mapBookVariantDataWithEconomics(data, economics));
                    })
                    .filter((data) => data !== null)
            );
        } else {
            return Promise.resolve(docDataList.map(this.mapBookVariantData).filter((data) => data !== null));
        }
    }

    async getPaginatedStartAt(
        orderByField: string,
        startAfter: any,
        limit: number,
        queries: Query[]
    ): Promise<BookData[]> {
        return this.paginatedQueryStartAt(orderByField, startAfter, limit, queries).then((docDataList) =>
            docDataList.map(this.mapBookVariantData).filter((data) => data !== null)
        );
    }

    async getPaginatedStartAfter(
        orderByField: string,
        startAfter: any,
        limit: number,
        queries: Query[]
    ): Promise<BookData[]> {
        return this.paginatedQueryStartAfter(orderByField, startAfter, limit, queries).then((docDataList) =>
            docDataList.map(this.mapBookVariantData).filter((data) => data !== null)
        );
    }

    async getPaginatedEndBefore(
        orderByField: string,
        endBefore: any,
        limit: number,
        queries: Query[]
    ): Promise<BookData[]> {
        return this.paginatedQueryEndBefore(orderByField, endBefore, limit, queries).then((docDataList) =>
            docDataList.map(this.mapBookVariantData).filter((data) => data !== null)
        );
    }

    async getById(id: string, includeEconomics?: boolean, fromCache?: boolean): Promise<BookData | null> {
        let bookPromise;
        if (fromCache) {
            bookPromise = this.getDocumentFromCache(id);
        } else {
            bookPromise = this.getDocument(id);
        }
        const result = await bookPromise.then((data) => {
            if (data === null) {
                return null;
            }
            if (includeEconomics) {
                return new BookService.BookEconomicsService(id)
                    .get()
                    .then((economics) => this.mapBookVariantDataWithEconomics(data, economics));
            }

            return this.mapBookVariantData(data);
        });

        if (result == null && fromCache) {
            return this.getById(id, false);
        }

        return result;
    }

    async getFilteredBooks(queries: Query[]): Promise<BookData[]> {
        return this.multiQuery(queries).then((docDataList) => {
            return docDataList.map(this.mapBookVariantData).filter((data) => data != null);
        });
    }

    updateBookVariant(data: Partial<Book>, documentId: string): Promise<FilePath> {
        return this.update(data, documentId);
    }

    async loadCoverUrl(coverPath: string): Promise<string> {
        return new FirebaseStorage().getFileUrl(coverPath);
    }

    async loadEbookSample(bookId: string): Promise<EbookFile | undefined> {
        const bookVariant = await this.getDocument(bookId);
        const ebook = bookVariant?.data.ebook ?? bookVariant?.data.bookFile;
        if (!ebook || ebook.type !== BookType.EBOOK) {
            return;
        }

        const ebookUrl = (await this.ebookSample({ bookId }))?.data;
        return {
            url: ebookUrl,
            title: ebook.name!,
            pages: ebook.pages,
        };
    }
    async loadEbook(bookVariantId: string): Promise<EbookFile | undefined> {
        const bookVariant = await this.getDocument(bookVariantId);
        const ebook = bookVariant?.data.ebook ?? bookVariant?.data.bookFile;
        if (!ebook || ebook.type !== BookType.EBOOK) {
            return;
        }

        if (bookVariant?.data.source == "bokbasen") {
            const ebookUrl = await this.loadBookUrl(bookVariantId, "", BookType.EBOOK);
            return {
                url: ebookUrl,
                title: ebook.name!,
                pages: ebook.pages,
            };
        }

        const ebookUrl = await this.loadBookUrl(bookVariantId, ebook.filePath, BookType.EBOOK);
        return {
            url: ebookUrl,
            title: ebook.name!,
            pages: ebook.pages,
        };
    }

    async loadKindleBook(bookVariantId: string): Promise<EbookFile | undefined> {
        const bookVariant = await this.getDocument(bookVariantId);
        const book = bookVariant?.data;
        if (book?.source == "bokbasen") {
            const ebookUrl = await this.loadBookUrl(bookVariantId, null, BookType.EBOOK);
            const title = book?.mainTitle?.replace(/ /g, "_") ?? `ebok_${book.bokbasenId}`;
            return {
                url: ebookUrl,
                title: `${title}.epub`,
                pages: bookVariant?.data.ebook?.pages ?? 0,
            };
        }

        const ebook = bookVariant?.data.ebook?.kindle;
        if (!ebook) {
            return;
        }

        const ebookUrl = await this.loadBookUrl(bookVariantId, ebook.filePath, BookType.EBOOK);
        return {
            url: ebookUrl,
            title: ebook.name!,
            pages: bookVariant?.data.ebook?.pages ?? 0,
        };
    }

    // Creates url accesible by users with ADMIN role only
    async adminLoadBookUrl(filePath: string): Promise<string> {
        return new FirebaseStorage().getFileUrl(filePath);
    }

    async loadBookUrl(
        bookId: string,
        filePath: string | null,
        type: BookType,
        downloadable?: boolean
    ): Promise<string> {
        const data = await this.createBookLinkFn({ bookId, path: filePath, type, downloadable });
        if (filePath == null && UrlUtils.isValidHttpUrl(data.data)) {
            return data.data;
        }
        const tempFilePath = data.data;
        return new FirebaseStorage().getFileUrl(tempFilePath);
    }

    async addBook(
        data: Partial<Book>,
        files?: BookFileDto[],
        economics?: BookEconomics,
        bookId?: string
    ): Promise<FilePath> {
        if (!bookId) {
            data.createdDate = Timestamp.now();
        }
        data.updatedDate = Timestamp.now();

        const bookVariantDocumentId = await this.update(removeNullOrUndefinedKeys(data), bookId);
        if (files) {
            for (const fileKey in files) {
                const file = files[fileKey];
                await this.saveFile(bookVariantDocumentId, file);
            }
        }
        if (economics) {
            await new BookService.BookEconomicsService(bookVariantDocumentId).saveOrUpdate(economics);
        }
        const book = await this.getById(bookVariantDocumentId);
        const coverPath = getIfNotempty(book?.book?.ebook?.coverPath) ?? book?.book?.audiobook?.coverPath;
        await this.update({ coverPath }, bookVariantDocumentId);
        return bookVariantDocumentId;
    }

    async saveFile(bookVariantDocumentId: string, file: BookFileDto): Promise<void> {
        if (isBookFileEbook(file)) {
            await this.saveEbook(bookVariantDocumentId, file);
        } else if (isBookFileAudioBook(file)) {
            await this.saveAudioBook(bookVariantDocumentId, file);
        }
    }

    async saveCover(
        documentId: string,
        coverKey: string,
        coverFile: FirebaseUploadFile,
        oldCoverPath?: string
    ): Promise<void> {
        const storagePathId = documentId;
        const path = await this.storageService
            .withPath(storagePathId)
            .updateFile(coverFile, `assets/${coverFile.name}`, { cache: true, cacheOptions: { maxAge: 604800 } });
        await new FirebaseStorage().deleteFile(oldCoverPath);
        await this.update({ [coverKey]: path }, documentId);
    }

    private async saveAudioBook(bookVariantDocumentId: string, audioBook: BookFileAudioDto) {
        const storagePathId = bookVariantDocumentId;
        const book = await this.getById(bookVariantDocumentId, true);
        const existingFiles = book?.book.audiobook?.files ?? [];
        const uploadFiles: AudioBookFile[] = [];
        const type = audioBook.bookFile.format.toLocaleLowerCase();
        for (const fileKey in audioBook.data) {
            const file = audioBook.data[fileKey];
            const existingFile = existingFiles.find((existing) => existing.fileName === file.fileName);
            let path = existingFile?.filePath;
            if (file.data) {
                const firebaseFile: FirebaseUploadFile = {
                    data: file.data,
                    name: file.fileName,
                    type: type === "mp3" ? "audio/mpeg" : type,
                };
                const storageFileName = file.sample ? "audiobookSample.mp3" : file.fileName;
                const filePath =
                    getFileUUIDFromFilePath(existingFile?.filePath) ??
                    this.createBookVariantId(`audiobook/${storageFileName}`);
                if (file.sample) {
                    path = await this.storageService
                        .withPath(storagePathId)
                        .updateFile(firebaseFile, `/sample/${storageFileName}`);
                } else {
                    path = await this.storageService
                        .withPath(storagePathId)
                        .updateFile(firebaseFile, filePath, undefined, {
                            podcast: audioBook.bookFile.type == BookType.PODCAST,
                        });
                }
            } else if (path != undefined) {
                await this.storageService.updateMetadata(path?.split("books/")[1], {
                    podcast: audioBook.bookFile.type == BookType.PODCAST,
                });
            }
            const mappedFile = removeNullOrUndefinedKeys({
                ...existingFile,
                title: file.title ?? existingFile?.title,
                filePath: path,
                durationSeconds: file.durationSeconds ?? existingFile?.durationSeconds,
                fileName: file.fileName ?? existingFile?.fileName,
            });
            if (file.sample) {
                audioBook.bookFile.sample = mappedFile;
            } else {
                uploadFiles.push(mappedFile);
            }
        }
        const deletedFiles = existingFiles.filter(
            (existing) => !uploadFiles.some((upload) => upload.fileName === existing.fileName)
        );
        await Promise.all(
            deletedFiles.map(async (deleteFile) => {
                await this.storageService
                    .withPath(storagePathId)
                    .deleteFile(getFileUUIDFromFilePath(deleteFile?.filePath));
            })
        );
        audioBook.bookFile.files = uploadFiles;
        await this.updateBookVariant({ audiobook: audioBook.bookFile as BookFileAudioBook }, bookVariantDocumentId);
        if (audioBook.cover?.data) {
            await this.saveCover(
                bookVariantDocumentId,
                "audiobook.coverPath",
                audioBook.cover,
                book?.book.audiobook?.coverPath
            );
        }
    }

    private async saveEbook(bookVariantDocumentId: string, ebook: BookFileEbookDto): Promise<void> {
        const storagePathId = bookVariantDocumentId;
        const book = await this.getById(bookVariantDocumentId, true);
        const existingEbook = book?.book.ebook;
        ebook.bookFile.filePath = existingEbook?.filePath;
        if (ebook.data) {
            const firebaseFile: FirebaseUploadFile = {
                data: ebook.data!,
                name: ebook.bookFile.name!,
                type: this.getEbookTypeFromName(ebook.bookFile.name!),
            };
            const filePath = getFileUUIDFromFilePath(ebook.bookFile.filePath) ?? this.createBookVariantId("ebook");
            const path = await this.storageService.withPath(storagePathId).updateFile(firebaseFile, filePath);
            ebook.bookFile.filePath = path;
        }

        if (!ebook.bookFile.name) {
            await this.storageService
                .withPath(storagePathId)
                .deleteFile(getFileUUIDFromFilePath(existingEbook?.filePath));
            ebook.bookFile.filePath = undefined;
        }

        await this.addOrRemoveEbookFile("sample", ebook, storagePathId, existingEbook);
        await this.addOrRemoveEbookFile("kindle", ebook, storagePathId, existingEbook);

        await this.updateBookVariant(
            { ebook: removeNullOrUndefinedKeys(ebook.bookFile) as BookFileEbook },
            bookVariantDocumentId
        );
        if (ebook.cover?.data) {
            await this.saveCover(bookVariantDocumentId, "ebook.coverPath", ebook.cover, book?.book.ebook?.coverPath);
        }
    }

    private getEbookTypeFromName(bookName: string) {
        const isEpub = bookName.includes(".epub");
        return isEpub ? "application/epub+zip" : "application/x-mobipocket-ebook";
    }
    private async addOrRemoveEbookFile(
        name: string,
        ebook: BookFileEbookDto,
        storagePathId: string,
        existingEbook?: BookFileEbook
    ) {
        if (ebook[name] && ebook[name].data) {
            const bookName = ebook[name]?.name as string;
            const firebaseFile: FirebaseUploadFile = {
                data: ebook[name]?.data,
                name: ebook[name]?.name,
                type: this.getEbookTypeFromName(bookName),
            };
            const filePath = getFileUUIDFromFilePath(ebook[name].filePath) ?? this.createBookVariantId("ebook_" + name);
            const path = await this.storageService.withPath(storagePathId).updateFile(firebaseFile, filePath);
            ebook.bookFile[name] = {
                name: ebook[name]?.name,
                filePath: path,
            };
        } else {
            ebook.bookFile[name] = existingEbook?.[name];
        }

        if (ebook?.[name]?.name == undefined && existingEbook?.[name]) {
            ebook.bookFile[name] = undefined;
            this.storageService
                .withPath(storagePathId)
                .deleteFile(getFileUUIDFromFilePath(existingEbook[name]?.filePath));
        }
    }
    private createBookVariantId(name: string): string {
        return name;
    }

    mapBookVariantDataWithEconomics(docData: DocumentData<Book>, economics?: BookEconomics): BookData {
        return {
            book: docData.data,
            economics: economics,
            documentId: docData.id,
        };
    }

    mapBookVariantData(docData: DocumentData<Book>): BookData {
        return {
            book: docData.data,
            documentId: docData.id,
        };
    }

    // TODO: move this to cloud function
    async sendEbookToKindle(recipientEmail: string, bookId: string) {
        const ebook = await this.loadKindleBook(bookId);
        if (ebook) {
            const { url, title } = ebook;
            // Postfix epub books with ".txt" to trigger automatic conversion
            const fileName = getEbookFilename(title);
            const email: Email = {
                from: "mail@publizm.no",
                to: [recipientEmail],
                message: {
                    subject: "Ebook " + title,
                    content: "Ebook " + title,
                    attachments: [
                        {
                            filename: fileName,
                            path: url,
                        },
                    ],
                },
            };
            return await this.sendEbookToKindleFn(email);
        }
    }

    static BookEconomicsService = class extends FirestoreService<BookEconomics> {
        constructor(bookId: string) {
            super(
                `${FirestoreCollection.BOOK}/${bookId}/${FirestoreCollection.BOOK_ECONOMICS}`,
                new BookEconomicsVersionMapper()
            );
        }

        exists(): Promise<boolean> {
            return this.getDocument(FirestoreCollection.BOOK_ECONOMICS).then((data) => data?.data !== null);
        }

        get(): Promise<BookEconomics> {
            return this.getDocument(FirestoreCollection.BOOK_ECONOMICS).then((data) => data?.data as BookEconomics);
        }

        getAll(): Promise<BookEconomicsData[]> {
            return getDocs(collectionGroup(firestore, FirestoreCollection.LICENSEE_SALE_INFO)).then((result) => {
                return result.docs.map((data) => ({
                    //@ts-ignore
                    bookId: data.ref.parent.parent.id,
                    economics: data.data() as BookEconomics,
                }));
            });
        }

        async saveOrUpdate(updatedEconomics: BookEconomics) {
            const existingEconomics = await this.get();
            if (existingEconomics) {
                const newLicensees = updatedEconomics.licensees.filter(
                    (updated) =>
                        !existingEconomics?.licensees?.some((existing) => this.isBookLicenseeEqual(updated, existing))
                );
                const updatedLicensees = existingEconomics?.licensees?.map((existingLicensee) => ({
                    ...existingLicensee,
                    advancePaid: Math.max(
                        existingLicensee.advancePaid ?? 0,
                        this.getLicensee(updatedEconomics, existingLicensee.bookType, existingLicensee.licenseeId)
                            ?.advancePaid ?? 0
                    ),
                }));

                // Only update advancePaid
                existingEconomics.licensees = [...(newLicensees ?? []), ...(updatedLicensees ?? [])];
                // Only update productionCost
                existingEconomics.products = existingEconomics.products.map((product) => ({
                    ...product,
                    productionCost:
                        this.getProduct(updatedEconomics, product.type)?.productionCost ?? product.productionCost,
                }));
                await this.update(existingEconomics, FirestoreCollection.BOOK_ECONOMICS);
            } else {
                await this.save(updatedEconomics, FirestoreCollection.BOOK_ECONOMICS);
            }
        }

        private isBookLicenseeEqual(a: BookEconomicsLicensee, b: BookEconomicsLicensee) {
            return a.licenseeId === b.licenseeId && a.bookType === b.bookType;
        }
        private getLicensee(economics: BookEconomics, bookType: BookType, licenseeId: string) {
            return economics.licensees.find(
                (licensee) => licensee.bookType === bookType && licensee.licenseeId == licenseeId
            );
        }
        private getProduct(economics: BookEconomics, bookType: BookType) {
            return economics.products.find((updatedProduct) => updatedProduct.type === bookType);
        }
    };
}

export class AuthorService extends FirestoreService<Author> {
    constructor() {
        super(FirestoreCollection.AUTHOR, new AuthorVersionMapper());
    }

    getAuthorProfileByName(authorProfileName: string): Promise<AuthorProfileData | null> {
        return new AuthorService.AuthorProfile().getByDisplayName(authorProfileName);
    }
    getAuthorProfileById(authorId: string, authorProfileId: string): Promise<AuthorProfileData | null> {
        return new AuthorService.AuthorProfile().getById(authorProfileId, authorId);
    }

    getAllAuthorProfiles(): Promise<AuthorProfileData[]> {
        return new AuthorService.AuthorProfile().getAll();
    }

    getAllSubscribed(source: "publizm" | "bokbasen", onUpdate: (authorData: AuthorData[]) => void): () => void {
        return this.getAllSnapshot(async (authorData) => {
            const authors = await Promise.all(
                authorData.map(async (authorData) => ({
                    author: authorData.data,
                    documentId: authorData.id,
                    amountOfProfiles: await new AuthorService.AuthorProfile().amount(authorData.id),
                }))
            );
            onUpdate(authors);
        }, source);
    }

    getAll(source?: "publizm" | "bokbasen"): Promise<AuthorData[]> {
        let query;
        if (source) {
            query = this.query("source", "==", source);
        } else {
            query = this.all();
        }
        return query.then((data) => {
            if (!data) {
                return [];
            }
            return Promise.all(
                data.map(async (authorData) => ({
                    author: authorData.data,
                    documentId: authorData.id,
                    amountOfProfiles: await new AuthorService.AuthorProfile().amount(authorData.id),
                }))
            );
        });
    }

    async getAuthorById(documentId: string): Promise<AuthorData | null> {
        return this.getDocument(documentId).then(async (docData) => {
            if (!docData) {
                return null;
            }
            return {
                author: docData.data,
                documentId: docData.id,
                amountOfProfiles: await new AuthorService.AuthorProfile().amount(documentId),
            };
        });
    }

    getAuthorProfilesById(documentId: string): Promise<AuthorProfileData[]> {
        return new AuthorService.AuthorProfile().getAllByAuthorId(documentId);
    }

    async saveAuthor(authorData: Partial<Author>, profiles: AuthorProfileDto[], documentId?: string) {
        const authorId = await this.updateAuthor(authorData, documentId);
        for (const key in profiles) {
            const profile = profiles[key];
            await this.saveAuthorProfile(profile, authorId);
        }
    }

    async saveAuthorProfile(profileDto: AuthorProfileDto, authorId: string) {
        const authorProfileService = new AuthorService.AuthorProfile();
        await authorProfileService.saveAuthorProfile(profileDto, authorId, profileDto.documentId);
    }
    updateAuthor(data: Partial<Author>, documentId?: string): Promise<string> {
        return this.update({ ...removeNullOrUndefinedKeys(data), lastChanged: Timestamp.now() }, documentId);
    }

    mapAuthorData(docData: DocumentData<Author>): AuthorData {
        return {
            author: docData.data,
            documentId: docData.id,
            amountOfProfiles: 0,
        };
    }

    // Subcollection
    static AuthorProfile = class extends FirestoreService<AuthorProfile> {
        private storageService = new FirebaseStorage().withPath(FirebaseStorageFolder.AUTHORS);
        constructor() {
            super(FirestoreCollection.AUTHOR_PROFILE, new AuthorProfileVersionMapper());
        }

        async getByDisplayName(displayName: string) {
            return this.query("displayName", "==", displayName).then(async (docData) => {
                if (docData.length == 0) {
                    return null;
                }
                return {
                    authorProfile: docData[0].data,
                    authorId: docData[0].data.authorId,
                    documentId: docData[0].id,
                };
            });
        }
        async getById(documentId: string, authorId: string): Promise<AuthorProfileData | null> {
            return this.getDocument(documentId).then(async (docData) => {
                if (!docData) {
                    return null;
                }
                return {
                    authorProfile: docData.data,
                    authorId: authorId,
                    documentId: docData.id,
                };
            });
        }
        amount(authorId: string): Promise<number> {
            return this.getAllByAuthorId(authorId).then((res) => res.length);
        }
        exists(authorId: string): Promise<boolean> {
            return this.getAllByAuthorId(authorId).then((data) => data.length > 0);
        }

        getAllByAuthorId(authorId: string): Promise<AuthorProfileData[]> {
            return this.query("authorId", "==", authorId).then(async (docData) => {
                if (docData.length == 0) {
                    return [];
                }
                return docData.map((profile) => ({
                    authorProfile: profile.data,
                    authorId: authorId,
                    documentId: profile.id,
                }));
            });
        }

        getAll(): Promise<AuthorProfileData[]> {
            return this.all().then(async (docData) => {
                if (docData.length == 0) {
                    return [];
                }
                return docData.map((data) => ({
                    authorProfile: data.data,
                    authorId: data.data.authorId,
                    documentId: data.id,
                }));
            });
        }

        async getPaginatedStartAt(
            orderByField: string,
            startAt: any,
            limit: number,
            queries: Query[]
        ): Promise<AuthorProfileData[]> {
            return this.paginatedQueryStartAt(orderByField, startAt, limit, queries).then(async (docData) => {
                if (docData.length == 0) {
                    return [];
                }
                return docData.map((data) => ({
                    authorProfile: data.data,
                    authorId: data.data.authorId,
                    documentId: data.id,
                }));
            });
        }

        async getPaginatedStartAfter(
            orderByField: string,
            startAfter: any,
            limit: number,
            queries: Query[]
        ): Promise<AuthorProfileData[]> {
            return this.paginatedQueryStartAfter(orderByField, startAfter, limit, queries).then(async (docData) => {
                if (docData.length == 0) {
                    return [];
                }
                return docData.map((data) => ({
                    authorProfile: data.data,
                    authorId: data.data.authorId,
                    documentId: data.id,
                }));
            });
        }

        async getPaginatedEndBefore(
            orderByField: string,
            endBefore: any,
            limit: number,
            queries: Query[]
        ): Promise<AuthorProfileData[]> {
            return this.paginatedQueryEndBefore(orderByField, endBefore, limit, queries).then((docData) => {
                if (docData.length == 0) {
                    return [];
                }
                return docData.map((data) => ({
                    authorProfile: data.data,
                    authorId: data.data.authorId,
                    documentId: data.id,
                }));
            });
        }

        async loadProfileUrl(profilePath: string, date?: Date): Promise<string> {
            return getStorageAuthorProfilepictureUrl(profilePath, date);
        }

        async saveAuthorProfile(
            profileDto: AuthorProfileDto,
            authorId: string,
            authorProfileId?: string
        ): Promise<void> {
            let profilePath = profileDto.profilePath;
            const profile: AuthorProfile = {
                source: profileDto.source,
                authorId: authorId,
                displayName: profileDto.displayName,
                name: profileDto.name,
                description: profileDto.description,
                lastChanged: Timestamp.now(),
                profilePicturePath: profilePath,
                language: profileDto.language,
            };
            const existing = authorProfileId ? await this.getById(authorProfileId, authorId) : undefined;
            const profileId = await this.updateAuthorProfile(profile, authorProfileId);
            if (profileDto.profilePicture && profileDto.profilePicture.data) {
                const firebaseFile: FirebaseUploadFile = {
                    data: profileDto.profilePicture.data,
                    name: "profileimage",
                    type: profileDto.profilePicture.type ?? "jpeg",
                };

                const existingProfilePath = existing?.authorProfile.profilePicturePath;
                profilePath = await this.storageService
                    .withPath(getFileUUIDFromFilePath(authorId))
                    .updateFile(firebaseFile, "profile", { cache: true });
                await new FirebaseStorage().deleteFile(existingProfilePath);
                this.updateAuthorProfile({ profilePicturePath: profilePath }, getFileUUIDFromFilePath(profileId));
            }
        }
        updateAuthorProfile(data: Partial<AuthorProfile>, documentId?: string): Promise<FilePath> {
            if (!documentId) {
                return this.update(data);
            }
            return this.update(removeNullOrUndefinedKeys(data), documentId);
        }
    };
}
