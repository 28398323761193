import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { configureScope, SentryTransactionId } from "./SentryUtils";

export function SentryInit(): void {
    const sentryDSN = import.meta.env.VITE_SENTRYDSN;

    if (sentryDSN != null) {
        Sentry.init({
            dsn: sentryDSN,
            attachStacktrace: true,
            autoSessionTracking: true,
            environment: import.meta.env.VITE_FIREBASE_projectId,
            release: import.meta.env.VITE_VERSION,
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(navigator),
                }),
                new CaptureConsole({
                    levels: ["error"],
                }),
            ],
            tracesSampleRate: 1,
        });

        SentryTransactionId();
        configureScope("origin", "publizm-webapp");
    }
}
