import {
    Agreement,
    Assessment,
    Author,
    AuthorProfile,
    Book,
    BookEconomics,
    BookFileAudioBook,
    BookFileEbook,
    Catalogue,
    CustomerBook,
    isAudioBook as isAudioBookOrPodcast,
    isEbook,
    Language,
    Licensee,
    LicenseePaymentInfo,
    Optional,
    Royalty,
    RoyaltyReport,
    Transaction,
    UserInfo,
    UserRole,
    WrittenWork,
    WrittenWorkEvent,
} from "shared";
import { BookGroup } from "shared/lib/types/BookTypes";

import { AuthorSource } from "./../../../shared/types/BookTypes";
import { LicenseeSaleInfo } from "./../../../shared/types/LicenseeTypes";
import { FirebaseDataType, FirebaseUploadFile } from "./../storage/types";

export interface DocumentData<T> {
    data: T;
    id: string;
}

export enum FirebaseStorageErrorTypes {
    UNAUTHORIZED = "storage/unauthorized",
    UNAUTHENTICATED = "storage/unauthenticated",
    CANCELED = "storage/canceled",
}

export interface UserInfoData {
    userInfo: UserInfo;
    name: string;
    id: string;
    roles?: UserRole[];
    activeRole?: UserRole;
}

export interface WrittenWorkData {
    writtenWork: WrittenWork;
    documentId: string;
    writtenWorkEvent?: WrittenWorkEvent;
}

export interface AssessmentData {
    assessment: Assessment;
    documentId: string;
}

export interface AgreementData {
    agreement: Agreement;
    documentId: string;
}

export interface BookGroupData {
    bookGroup: BookGroup;
    documentId: string;
}

export interface BookData {
    book: Book;
    economics?: BookEconomics;
    relatedBooks?: BookData[];
    documentId: string;
}

export interface BookEconomicsData {
    economics: BookEconomics;
    bookId: string;
}

export interface LicenseePaymentInfoData {
    paymentInfo: LicenseePaymentInfo;
    licenseeId: string;
}

export interface LicenseeSaleInfoData {
    saleInfo: LicenseeSaleInfo;
    licenseeId: string;
}

export interface CatalogueData {
    catalogue: Catalogue;
    documentId: string;
}

export interface BookFileEbookDto {
    bookFile: Optional<BookFileEbook, "filePath">;
    data?: FirebaseDataType;
    sample?: {
        data?: FirebaseDataType;
        name: string;
        filePath?: string;
    };
    kindle?: {
        data?: FirebaseDataType;
        name: string;
        filePath?: string;
    };
    cover?: FirebaseUploadFile;
}
export interface BookFileAudioDto {
    bookFile: Optional<BookFileAudioBook, "files">;
    cover?: FirebaseUploadFile;
    data: {
        title: string;
        fileName: string;
        order: number;
        durationSeconds: number;
        data: FirebaseDataType;
        sample?: boolean;
    }[];
}

export interface AuthorProfileDto {
    source: AuthorSource;
    profilePicture?: Partial<FirebaseUploadFile>;
    profilePath?: string;
    displayName: string;
    name: string;
    description: string;
    language: Language;
    documentId?: string;
}

export interface EbookFile {
    url: string;
    title: string;
    pages: number;
}

export interface AudioBookChapter {
    src: string;
    title: string;
    index: number;
    durationSeconds?: number;
}
export type BookFileDto = BookFileEbookDto | BookFileAudioDto;

export const isBookFileEbook = (file: BookFileDto): file is BookFileEbookDto => isEbook(file.bookFile);
export const isBookFileAudioBook = (file: BookFileDto): file is BookFileAudioDto => isAudioBookOrPodcast(file.bookFile);

export interface AuthorData {
    author: Author;
    amountOfProfiles: number;
    documentId: string;
}

export enum RoyaltyReportState {
    GENERATED,
    NOT_GENERATED,
}

export interface RoyaltyReportData {
    report: RoyaltyReport;
    royalties: RoyaltyData[];
}

export interface RoyaltyData {
    royalty: Royalty;
    documentId: string;
}

export interface LicenseeData {
    licensee: Licensee;
    paymentInfo?: LicenseePaymentInfo;
    saleInfo?: LicenseeSaleInfo;
    documentId: string;
}

export interface AuthorProfileData {
    authorProfile: AuthorProfile;
    authorId?: string;
    documentId: string;
}

export interface CustomerBookData {
    customerBook: CustomerBook;
    documentId: string;
}

export interface TransactionData {
    transaction: Transaction;
    documentId: string;
}

export enum OrderType {
    ASC = "asc",
    DESC = "desc",
}
