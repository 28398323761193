import "./PzMenuTabs.css";

import { Li, Tabs, Ul } from "components/trunx";
interface PzMenuTabsProps {
    options: string[];
    selectedOption: string;
    onOptionSelected: (option: string) => void;
    vertical?: boolean;
}
export default function PzMenuTabs<T>({ options, onOptionSelected, selectedOption, vertical }: PzMenuTabsProps) {
    return (
        <Tabs pb0 mb0 className="pzmenu_tabs">
            <Ul style={vertical ? { flexDirection: "column", alignItems: "center" } : {}}>
                {options.map((option) => (
                    <Li isActive={selectedOption === option} onClick={() => onOptionSelected(option)}>
                        <a>{option}</a>
                    </Li>
                ))}
            </Ul>
        </Tabs>
    );
}
