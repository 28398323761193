// @ts-nocheck

// Polyfill to support various browsers (ex IE, Safari, Firefox etc)

// File.arrayBuffer polyfill. Older safari browser doesn't support arrayBuffer (version < 14)
// This function checks if arrayBuffer exists on File and Blob and if not adds custom arrayBuffer implementation.
(function () {
    File.prototype.arrayBuffer = File.prototype.arrayBuffer || customArrayBuffer;
    Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || customArrayBuffer;
    function customArrayBuffer() {
        // this: File or Blob
        return new Promise((resolve) => {
            const fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result);
            };
            fr.readAsArrayBuffer(this);
        });
    }
})();

export {};
