import { createContext, useContext } from "react";
interface RoyaltyDashboardContextProps {
    viewMonth: number;
    viewUser?: string;
    generateReport: (month: number, year: number, userId?: string) => void;
    loadReport: (month: number, year: number, userId?: string) => void;
}

export const useRoyaltyContext = () => useContext(RoyaltyDashboardContext);
export const RoyaltyDashboardContext = createContext<RoyaltyDashboardContextProps>({} as RoyaltyDashboardContextProps);
