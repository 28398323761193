import { faFeather } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, Modal } from "components/trunx";

import PzButton from "../form/PzButton";
import PzModal from "../modal/PzModal";
import { PzTitle } from "../text/PzTitle";

interface TransactionErrorModalProps {
    onClose: () => void;
}

export default function PaymentDisabledModal({ onClose }: TransactionErrorModalProps): JSX.Element {
    function renderIcon() {
        return <FontAwesomeIcon icon={faFeather} />;
    }

    return (
        <PzModal isActive>
            <Modal.Card.Head>
                <PzTitle style={{ marginBottom: "0" }}>{renderIcon()} Vedlikehold!</PzTitle>
            </Modal.Card.Head>
            <Modal.Card.Body style={{ maxWidth: "35rem" }}>
                <Content>
                    Publizm er i vedlikeholdsmodus og betalingsløsningen er derfor deaktivert for øyeblikket. Sjekk om
                    en liten stund så er vi nok klare!
                </Content>
            </Modal.Card.Body>
            <Modal.Card.Foot>
                <PzButton isPrimary onClick={() => onClose()}>
                    Lukk
                </PzButton>
            </Modal.Card.Foot>
        </PzModal>
    );
}
