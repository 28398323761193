import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, Section } from "components/trunx";
import React, { ReactElement, useState } from "react";
import { UnmountClosed } from "react-collapse";

import { ColumnData } from "./TableHead";
//@ts-ignore //TODO: Fixme
interface RowComponentType
    extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {
    content: ReactElement | string;
    className?: string;
}

export type createRowType<T> = (data: T, toggleCollapsible?: () => void, isCollapsed?: boolean) => RowData;
export interface RowData
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {
    onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
    components: RowComponentType[];
    collapsibleComponent?: ReactElement;
}

interface TableBodyProps<T> {
    createRow: createRowType<T>;
    data: T[];
    columns: ColumnData[];
    rowKeyGenerator?: (data: T) => string;
}
export default function TableBody<T>(props: TableBodyProps<T>): ReactElement {
    const [openCollasibleRowKey, setOpenCollapsibleRowKey] = useState<string>();
    function toggleCollapsible(rowKey: string) {
        return () =>
            isCollapsibleRowWithIndexOpen(rowKey)
                ? setOpenCollapsibleRowKey(undefined)
                : setOpenCollapsibleRowKey(rowKey);
    }

    function isCollapsibleRowWithIndexOpen(rowKey: string) {
        return rowKey === openCollasibleRowKey;
    }
    if (props.data.length === 0) {
        return (
            <tbody style={{ textAlign: "center" }}>
                <tr className="is-empty">
                    <td colSpan={9999999}>
                        <Section>
                            <Content hasTextCentered hasTextGrey>
                                <p>
                                    <span className="icon is-large">
                                        <FontAwesomeIcon size="3x" icon={faSadTear} />
                                    </span>
                                </p>
                                <p>Ingen data å vise</p>
                            </Content>
                        </Section>
                    </td>
                </tr>
            </tbody>
        );
    }

    function getRowKey(data: T): string {
        //@ts-ignore
        return props.rowKeyGenerator?.(data) ?? Object.values(data).join("");
    }

    return (
        <tbody>
            {props.data.map((data) => {
                const rowKey = getRowKey(data);
                return (
                    <TableRow
                        columns={props.columns}
                        key={rowKey}
                        isOpenCollapsible={isCollapsibleRowWithIndexOpen(rowKey)}
                        row={props.createRow(data, toggleCollapsible(rowKey), isCollapsibleRowWithIndexOpen(rowKey))}
                    />
                );
            })}
        </tbody>
    );
}

interface TableRowProps {
    row: RowData;
    columns: ColumnData[];
    isOpenCollapsible: boolean;
}
function TableRow({ columns, row, isOpenCollapsible }: TableRowProps): ReactElement {
    const { components, collapsibleComponent, ...tableProps } = row;
    return (
        <>
            <tr {...tableProps}>
                {components.map((component, index) => {
                    const { content, ...tdProps } = component;
                    return (
                        <td data-label={columns[index].label} key={"row_" + index} {...tdProps}>
                            {content}
                        </td>
                    );
                })}
            </tr>
            <CollapsibleRow colspan={components.length} open={isOpenCollapsible} component={collapsibleComponent} />
        </>
    );
}

interface CollapsibleRowProps {
    open: boolean;
    component?: ReactElement;
    colspan: number;
}

function CollapsibleRow(props: CollapsibleRowProps): ReactElement | null {
    if (!props.component) {
        return null;
    }
    return (
        <tr className="detail">
            <td colSpan={props.colspan} style={{ padding: 0, width: "100%" }}>
                <div>
                    <UnmountClosed isOpened={props.open}>{props.component}</UnmountClosed>
                </div>
            </td>
        </tr>
    );
}
