import { useAuth } from "contexts/AuthContext";
import { ReactElement, useEffect, useState } from "react";
import { WrittenWorkState } from "shared";

import { EditorDashboardMenu } from "../../../components/dashboard/Menu/DashboardUserMenu";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useDashboard } from "../../../contexts/DashboardContext";
import { WrittenWorkData } from "../../../services/firestore/types";
import { WrittenWorkService } from "../../../services/firestore/WrittenWorkService";
import EditorDashboardTable from "./EditorDashboardTable";

export default function EditorDashboard(): ReactElement {
    const { selectedMenu } = useDashboard();
    const [writtenWorkDataList, setWrittenWorkDataList] = useState<WrittenWorkData[]>([]);
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        loadInitialData();
    }, []);

    async function loadInitialData() {
        if (currentUser) {
            await new WrittenWorkService()
                .getEditorData(currentUser.uid)
                .then(setWrittenWorkDataList)
                .catch(console.log);
        }
        setLoading(false);
    }

    function getWrittenWorksDataListByType(state: WrittenWorkState[]) {
        return writtenWorkDataList.filter((data) => state.includes(data.writtenWork.state));
    }

    function renderContent() {
        if (loading || !selectedMenu) {
            return <LoadingIndicator />;
        }

        function renderTable(writtenWorkDataList: WrittenWorkData[]) {
            return (
                <EditorDashboardTable
                    key={selectedMenu}
                    writtenWorkDataList={writtenWorkDataList}
                    updateWrittenWorks={loadInitialData}
                />
            );
        }

        switch (selectedMenu) {
            case EditorDashboardMenu.ALL:
                return renderTable(writtenWorkDataList);
            case EditorDashboardMenu.NEW:
                return renderTable(getWrittenWorksDataListByType([WrittenWorkState.ASSIGNED]));
            case EditorDashboardMenu.UNDER_ASSESSMENT:
                return renderTable(getWrittenWorksDataListByType([WrittenWorkState.UNDER_ASSESSMENT]));
            case EditorDashboardMenu.ASSESSED:
                return renderTable(getWrittenWorksDataListByType([WrittenWorkState.ASSESSED]));
            case EditorDashboardMenu.ARCHIVE:
                return renderTable(
                    getWrittenWorksDataListByType([WrittenWorkState.REFUSED, WrittenWorkState.ACCEPTED])
                );
            default:
                return renderTable(writtenWorkDataList);
        }
    }

    return renderContent();
}
