import React from "react";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";

import StoryblokPageItemWrapper from "./StoryblokPageItemWrapper";

export default function StoryblokPage(): ReactElement | null {
    const { storyblokId } = useParams<{ storyblokId: string }>();

    return CreateStoryblokPage(storyblokId || "");
}
export const CreateStoryblokPage = (id: string): ReactElement => <StoryblokPageItemWrapper slug={id} />;
