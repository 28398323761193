import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import { getWrittenWorkStateDisplayValue, UserRole, WrittenWorkEvent, WrittenWorkState } from "shared";

import { useAuth } from "../../contexts/AuthContext";

export interface StateOptionType {
    label: ReactElement;
    value: WrittenWorkState;
}

export enum STATE_COLOR {
    NEEDS_ACTION = "blue",
    WAITING_FOR_RESPONS = "green",
    DONE_REFUSED = "red",
    DONE_ACCEPTED = "grey",
}

export function renderLabel(labelValue: string, color: string, showColor: boolean): ReactElement {
    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
            {showColor && <FontAwesomeIcon size="xs" icon={faCircle} color={color} />}
            {labelValue}
        </div>
    );
}

export function findLabelValue(writtenWorkState: WrittenWorkState, writtenWorkEvent?: WrittenWorkEvent): string {
    return useAuth().userHasActiveRole(UserRole.AUTHOR)
        ? getAuthorLabel(writtenWorkState, writtenWorkEvent)
        : getWrittenWorkStateDisplayValue(writtenWorkState);
}

function getAuthorLabel(writtenWorkState: WrittenWorkState, writtenWorkEvent?: WrittenWorkEvent) {
    return writtenWorkEvent?.authorLabelMap?.get(writtenWorkState) || getWrittenWorkStateDisplayValue(writtenWorkState);
}

export function getStateColor(state: WrittenWorkState): STATE_COLOR {
    switch (state) {
        // Wating states
        case WrittenWorkState.DRAFT:
        case WrittenWorkState.ASSIGNED:
        case WrittenWorkState.UNDER_ASSESSMENT:
            return STATE_COLOR.WAITING_FOR_RESPONS;

        // States that requre admin action
        case WrittenWorkState.DELIVERED:
        case WrittenWorkState.ASSESSED:
            return STATE_COLOR.NEEDS_ACTION;

        // End states
        case WrittenWorkState.ACCEPTED:
            return STATE_COLOR.DONE_ACCEPTED;
        case WrittenWorkState.REFUSED:
            return STATE_COLOR.DONE_REFUSED;

        // Safety net
        default:
            console.error(`Missing color mapping for WrittenWorkState '${state}'`);
            return STATE_COLOR.NEEDS_ACTION;
    }
}
