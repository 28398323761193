import { collectionGroup, getDocs, query, Timestamp, where } from "firebase/firestore";
import {
    FirestoreCollection,
    Licensee,
    LicenseePaymentInfo,
    LicenseePaymentInfoVersionMapper,
    LicenseeVersionMapper,
    removeNullOrUndefinedKeys,
} from "shared";

import { isEmpty } from "../../components/utils/ObjectUtils";
import { firestore } from "../../firebase";
import { getLicenseeSaleInfoCollectionName, LicenseeSaleInfo } from "./../../../shared/types/LicenseeTypes";
import { DataSource } from "./../../../shared/types/PzFirestoreBaseTypes";
import { FirestoreService } from "./FirestoreService";
import { DocumentData, LicenseeData, LicenseePaymentInfoData, LicenseeSaleInfoData } from "./types";

export class LicenseeService extends FirestoreService<Licensee> {
    constructor() {
        super(FirestoreCollection.LICENSEE, new LicenseeVersionMapper());
    }

    async getAll(source?: DataSource): Promise<LicenseeData[]> {
        const query = source ? this.query("source", "==", source) : this.all();
        const result: LicenseeData[] = await query.then((data) => {
            if (!data) {
                return [];
            }
            return Promise.all(
                data.map(async (licenseeData) => ({
                    licensee: licenseeData.data,
                    documentId: licenseeData.id,
                }))
            );
        });

        return result;
    }

    getAllSubscribed(source: "publizm" | "bokbasen", onUpdate: (licenseeData: LicenseeData[]) => void): () => void {
        return this.getAllSnapshot(async (docDataList) => {
            onUpdate(await this.mapLicenseeData(docDataList));
        }, source);
    }

    protected async mapLicenseeData(data: DocumentData<Licensee>[]): Promise<LicenseeData[]> {
        if (!data) {
            return [];
        }
        const result = await Promise.all(
            data.map(async (licenseeData) => ({
                licensee: licenseeData.data,
                documentId: licenseeData.id,
            }))
        );

        // if (withBalance) {
        //     return Promise.all(
        //         result.map(async (licenseeData) => {
        //             const paymentInfo = await new LicenseeService.LicenseePaymentInfoService(
        //                 licenseeData.documentId
        //             ).get();
        //             return {
        //                 ...licenseeData,
        //                 paymentInfo: paymentInfo,
        //             };
        //         })
        //     );
        // }

        return result;
    }
    async saveOrUpdate(licensee: Partial<Licensee>, documentId?: string) {
        if (isEmpty(licensee.connectedToUser) && documentId) {
            this.removeUserConnection(documentId);
        }
        return this.update({ ...removeNullOrUndefinedKeys(licensee), lastChanged: Timestamp.now() }, documentId);
    }

    async connectToUser(licenseeId: string, userId: string) {
        this.saveOrUpdate({ connectedToUser: userId }, licenseeId);
    }

    async removeUserConnection(licenseeId: string) {
        this.deleteField("connectedToUser", licenseeId);
    }

    static LicenseePaymentInfoService = class extends FirestoreService<LicenseePaymentInfo> {
        constructor(licenseeId: string) {
            super(
                `${FirestoreCollection.LICENSEE}/${licenseeId}/${FirestoreCollection.LICENSEE_PAYMENT_INFO}`,
                new LicenseePaymentInfoVersionMapper()
            );
        }

        exists(): Promise<boolean> {
            return this.getDocument(FirestoreCollection.LICENSEE_PAYMENT_INFO).then((data) => data?.data !== null);
        }

        get(): Promise<LicenseePaymentInfo> {
            return this.getDocument(FirestoreCollection.LICENSEE_PAYMENT_INFO).then(
                (data) => data?.data as LicenseePaymentInfo
            );
        }

        getAllPaymentInfo(): Promise<LicenseePaymentInfoData[]> {
            return getDocs(collectionGroup(firestore, FirestoreCollection.LICENSEE_PAYMENT_INFO)).then((result) => {
                return result.docs.map((data) => ({
                    //@ts-ignore
                    licenseeId: data.ref.parent.parent.id,
                    paymentInfo: data.data() as LicenseePaymentInfo,
                }));
            });
        }

        getAllSaleInfo(source: DataSource, year: number): Promise<LicenseeSaleInfoData[]> {
            return getDocs(
                query(collectionGroup(firestore, getLicenseeSaleInfoCollectionName(source)), where("year", "==", year))
            ).then((result) => {
                return result.docs.map((data) => ({
                    //@ts-ignore
                    licenseeId: data.ref.parent.parent.id,
                    saleInfo: data.data() as LicenseeSaleInfo,
                }));
            });
        }
    };
}
