import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";

import Logger from "../services/logging/LoggerService";
import PzText from "./text/PzText";
import { PzTitle } from "./text/PzTitle";
import { Container } from "./trunx/Container";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error): State {
        console.log(error);
        return { hasError: true };
    }

    static componentDidCatch(error, errorInfo): void {
        Logger.error(`${error} ${errorInfo}`);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return <ErrorMessage />;
        }

        return <>{this.props.children}</>;
    }
}

function ErrorMessage() {
    function renderIcon() {
        return <FontAwesomeIcon icon={faExclamationCircle} />;
    }
    return (
        <Container hasTextCentered style={{ paddingTop: "10rem" }}>
            <PzTitle>{renderIcon()} Det har oppstått en feil</PzTitle>
            <PzText pb3>
                Vi beklager så mye. Dette er vår feil, ikke din. Våre beste folk er satt på saken og vil rette feilen så
                snart som mulig.
            </PzText>
            <PzText pb3>I mellomtiden kan du forsøke igjen. Kanskje dette bare er en midlertidig ustabilitet.</PzText>
        </Container>
    );
}
