import PzSelectDropdown, { OptionProps } from "../../../components/PzSelectDropdown";

interface SelectYearDropdownProps {
    onChange: (year: number) => void;
}
export default function SelectYearDropdown({ onChange }: SelectYearDropdownProps) {
    function getYearOptions(): OptionProps<number>[] {
        return ["2022", "2023", "2024"].map((yearNumber) => {
            return {
                label: yearNumber,
                value: parseInt(yearNumber),
                isDefault: new Date().getFullYear().toString() == yearNumber,
            };
        });
    }

    return (
        <PzSelectDropdown style={{ width: "150px" }} title="Velg år" options={getYearOptions()} onChanged={onChange} />
    );
}
