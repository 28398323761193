import { AppPath } from "common/AppPath";
import PageTemplate from "components/pagetemplate/PageTemplate";
import { AssessmentProvider } from "contexts/AssessmentContext";
import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WrittenWorkData } from "services/firestore/types";

import { WrittenWorkService } from "../../services/firestore/WrittenWorkService";
import AssessmentContent from "./AssessmentContent";

interface WrittenWorkDataState {
    state?: WrittenWorkData;
}

export default function WorkAssessmentPage(): ReactElement {
    const [params, _] = useSearchParams();
    const documentId = params.get("id");

    if (documentId) {
        return WorkAssessmentPageNeedsLoading(documentId);
    } else {
        return WorkAssessmentPagePreLoaded({});
    }
}

function WorkAssessmentPagePreLoaded({ state }: WrittenWorkDataState): ReactElement {
    const writtenWorkData: WrittenWorkData = state ?? useLocation().state;

    const navigate = useNavigate();

    useEffect(() => {
        if (!writtenWorkData) {
            navigate(AppPath.DASHBOARD);
        }
    }, [writtenWorkData]);

    return (
        <PageTemplate marginLessTop>
            <AssessmentProvider writtenWorkData={writtenWorkData}>
                <AssessmentContent />
            </AssessmentProvider>
        </PageTemplate>
    );
}

function WorkAssessmentPageNeedsLoading(documentId: string): ReactElement {
    const [writtenWorkData, setWrittenWorkData] = useState<WrittenWorkData>();

    useEffect(() => {
        new WrittenWorkService().getWrittenWorkDataByDocId(documentId).then((writtenWorkData) => {
            if (writtenWorkData) {
                setWrittenWorkData(writtenWorkData);
            }
        });
    }, []);
    if (writtenWorkData) {
        return <WorkAssessmentPagePreLoaded state={writtenWorkData} />;
    } else {
        return <div>Loading...</div>;
    }
}
