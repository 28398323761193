import { Checkbox, CheckboxProps, Label } from "components/trunx";
import { useEffect, useRef } from "react";
import { ReactElement } from "react";

interface PzCheckboxProps extends CheckboxProps {
    name: string;
    value?: string;
    label?: string;
    inputRef?: (ref: HTMLInputElement) => void;
    defaultChecked?: boolean;
}
export default function PzCheckbox({
    name,
    defaultChecked,
    value,
    inputRef,
    label,
    ...checkboxProps
}: PzCheckboxProps): ReactElement {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const inputElement = ref.current?.querySelector("input");
        if (inputElement && inputRef) {
            inputRef(inputElement);
        }
    }, []);
    return (
        <div ref={ref}>
            {label && <Label>{label}</Label>}
            <Checkbox defaultChecked={defaultChecked} name={name} value={value} {...checkboxProps}>
                {value}
            </Checkbox>
        </div>
    );
}
