import React, { PropsWithChildren, useContext, useEffect, useState } from "react";

import LoadingIndicator from "../../../../../components/LoadingIndicator";
import { LicenseeService } from "../../../../../services/firestore/LicenseeService";
import { LicenseeData } from "../../../../../services/firestore/types";

interface BookAdminContextProps {
    licensees: LicenseeData[];
}
const BookAdminContext = React.createContext<BookAdminContextProps>({} as BookAdminContextProps);

export function useBookAdminContext(): BookAdminContextProps {
    return useContext(BookAdminContext);
}

export function BookAdminProvider({ children, bookId }: PropsWithChildren<{ bookId?: string }>) {
    const [loading, setLoading] = useState<boolean>(true);
    const [licensees, setLicensees] = useState<LicenseeData[]>([]);
    useEffect(() => {
        loadInitialData();
    }, []);

    async function loadInitialData() {
        await new LicenseeService().getAll().then(setLicensees);
        setLoading(false);
    }

    return (
        <BookAdminContext.Provider value={{ licensees: licensees }}>
            {loading ? <LoadingIndicator /> : children}
        </BookAdminContext.Provider>
    );
}
