import "./PzQuote.css";

import { Content, ContentProps } from "components/trunx";

interface PzQuoteProps extends ContentProps {
    isPrimary?: boolean;
    isSecondary?: boolean;
}

function PzQuote(props: PzQuoteProps) {
    const { isPrimary = false, isSecondary = false, className, ...otherProps } = props;
    const style = isSecondary ? "secondary" : "primary";

    return <Content hasTextCentered {...otherProps} className={`pzQuote ${className ? className : ""} ${style}`} />;
}

export default PzQuote;
