import "./PzSwiper.css";
import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

import { A11y, Autoplay, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperOptions } from "swiper/types/swiper-options";

interface IPzSwiperProps {
    slides: JSX.Element[];
    pagination?: boolean;
    slidesPerView?: number;
    breakpoints?: {
        [width: number]: SwiperOptions;
        [ratio: string]: SwiperOptions;
    };
    autoplay?: boolean | { delay?: number; threshold?: boolean };
}
export default function PzSwiper({ slides, pagination, autoplay, breakpoints, slidesPerView }: IPzSwiperProps) {
    function getAutoplayProps() {
        if (!autoplay) return;
        const delay = typeof autoplay == "object" ? autoplay.delay : 2000;

        return { delay: delay, disableOnInteraction: false, pauseOnMouseEnter: true, waitForTransition: false };
    }
    return (
        <Swiper
            className="pz_swiper"
            style={{ width: "100%" }}
            grabCursor
            loop
            breakpoints={breakpoints}
            modules={[Navigation, Pagination, A11y, Mousewheel, Autoplay]}
            slidesPerView={slidesPerView}
            mousewheel={{ releaseOnEdges: false, thresholdTime: 200, thresholdDelta: 100 }}
            pagination={
                pagination
                    ? { clickable: true, type: "bullets", dynamicBullets: true, dynamicMainBullets: 5 }
                    : undefined
            }
            autoplay={getAutoplayProps()}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={`slide_${index}`}>{slide}</SwiperSlide>
            ))}
        </Swiper>
    );
}
