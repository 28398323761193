import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapsibleExpandButton from "components/dashboard/CollapsibleExpandButton";
import PzButton from "components/form/PzButton";
import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import { ReactElement } from "react";
import { useNavigate } from "react-router";
import { BookGroupNames, BookPriceType, BookStatus, BookType } from "shared";

import { AppPath } from "../../../../../common/AppPath";
import PzAlgoliaTable from "../../../../../components/table/PzAlgoliaTable";
import PzTable from "../../../../../components/table/PzTable";
import { formatDate } from "../../../../../components/utils/DateUtils";
import AktueltCheckbox from "./AktueltCheckbox";
import { BookCollapsible } from "./BookCollapsible";
import BookEarningNumbers from "./BookEarningNumbers";
import BookSaleNumbers from "./BookSaleNumbers";
import { BookTableData } from "./BookTableTypes";
import ShowBookTypeIcons from "./ShowBookTypeIcons";

interface TableProps {
    bookDataList: BookTableData[];
    algolia?: boolean;
}
export default function BookAdministrationTable({ bookDataList, algolia = true }: TableProps): ReactElement {
    const columns: ColumnData[] = [
        {
            label: "Tittel",
            sortByProperty: !algolia ? "title" : undefined,
        },
        {
            label: "Endret",
            sortByProperty: !algolia ? "updatedDate" : undefined,
        },
        {
            label: "Status",
            sortByProperty: !algolia ? "status" : undefined,
        },
        {
            label: "Aktuelt",
            customComparator: !algolia
                ? (a: BookTableData, b: BookTableData) => (a.groups?.includes(BookGroupNames.AKTUELT) ? 1 : -1)
                : undefined,
        },
        {
            label: "Forfatter",
            sortByProperty: !algolia ? "author" : undefined,
        },
        {
            label: "Inntjening",
        },
        {
            label: "Antall solgt",
        },
        {
            label: "Formater",
        },
        {
            label: "",
        },
    ];

    function createRow(book: BookTableData, toggleCollapsible?: () => void, isOpenCollapsible?: boolean): RowData {
        // const totalEarnings = book.economics?.totalEarnings;
        return {
            collapsibleComponent: <BookCollapsible id={book.id} />,
            components: [
                {
                    content: book.title ?? ".",
                    width: "40%",
                },
                {
                    content: formatDate(book.updatedDate) ?? "-",
                    width: "10%",
                },
                {
                    content: book.status === BookStatus.PUBLISHED ? "Publisert" : "Utkast",
                    width: "6%",
                },
                {
                    content: <AktueltCheckbox groups={book.groups ?? []} bookId={book.id} />,
                    width: "5%",
                },
                {
                    content: book.author ?? "-",
                    width: "25%",
                },
                {
                    content: algolia ? <BookEarningNumbers bookId={book.id} /> : <div>--</div>,
                    width: "8%",
                },
                {
                    content: algolia ? <BookSaleNumbers bookId={book.id} /> : <div>--</div>,
                },
                {
                    content: (
                        <ShowBookTypeIcons
                            showAudio={book.prices?.some((p) => p.type == BookPriceType.AUDIOBOOK)}
                            showEbook={book.prices?.some((p) => p.type == BookPriceType.EBOOK)}
                            showPodcast={book.bookType == BookType.PODCAST}
                            showKindle={false}
                        />
                    ),
                    width: "8%",
                },

                {
                    content: <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />,
                    width: "1%",
                    className: "is-chevron-cell",
                },
            ],
        };
    }

    function getSearchData(data: BookTableData): string {
        const book = data;
        const status = book.status === BookStatus.PUBLISHED ? "Publisert" : "Utkast";
        return (
            book.title +
            status +
            formatDate(book.updatedDate) +
            book.groups?.join(",") +
            book.price +
            book.author +
            book.collectionNames?.join(",") +
            book.groups?.join(",")
        );
    }

    if (!algolia) {
        return (
            <PzTable
                createRow={createRow}
                searchData={getSearchData}
                data={bookDataList}
                columns={columns}
                enableExternalDataUpdate
                rowKeyGenerator={(data) => data.id}
                tableAddOn={<NewBook />}
                defaultSortIndex={1}
            />
        );
    }
    return (
        <PzAlgoliaTable
            createRow={createRow}
            searchData={getSearchData}
            data={bookDataList}
            columns={columns}
            rowKeyGenerator={(data) => data.id}
            tableAddOn={<NewBook />}
            defaultSortIndex={1}
        />
    );
}

function NewBook() {
    const navigate = useNavigate();
    return (
        <PzButton isPrimary pr6 pl6 onClick={() => navigate(AppPath.BOOK_MANAGEMENT)}>
            <span>
                <span style={{ marginRight: "10px" }}>
                    <strong>Ny bok</strong>
                </span>
                <FontAwesomeIcon icon={faPlus} color="black" />
            </span>
        </PzButton>
    );
}
