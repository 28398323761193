import "./ArticlePage.css";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Column, Container } from "components/trunx";
import { formatDate } from "components/utils/DateUtils";
import { useNavigate, useParams } from "react-router-dom";

import { AppPath } from "../../common/AppPath";
import PzIconButton from "../../components/form/PzIconButton";
import PageTemplate from "../../components/pagetemplate/PageTemplate";
import { RichTextRenderer } from "../../components/storyblok/StoryblokComponents";
import StoryblokWrapper from "../../components/storyblok/StoryblokWrapper";
import { ArticleItem } from "./types";

const borderRadius = "20px";
export default function ArticlePage() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    function backLink() {
        return (
            <Column is8 m0 p0 className="backLink">
                <PzIconButton
                    isSmall
                    iconPlacement="left"
                    icon={faArrowLeft}
                    onClick={() => navigate(AppPath.ARTICLES)}
                >
                    Tilbake til aktuelt
                </PzIconButton>
            </Column>
        );
    }

    return (
        <PageTemplate className="fullArticle" marginLessTop>
            <Container isFlex isJustifyContentCenter isAlignItemsCenter isFlexDirectionColumn>
                <Column is9 is12Mobile isCentered p0 m0 hasBackgroundWhite style={{ borderRadius: borderRadius }}>
                    {backLink()}
                    <StoryblokWrapper slug={`news/${id}`}>
                        {(article: ArticleItem) => (
                            <article>
                                <img
                                    style={{
                                        width: "100%",
                                        borderRadius: 8,
                                        maxHeight: 540,
                                        objectFit: "cover",
                                        objectPosition: "center",
                                    }}
                                    className="pb-0 m-0"
                                    src={article.content.image?.filename}
                                    alt="Article image"
                                />
                                <p className="text-sm mt-1 mb-1 text-gray-600">{article.content.imagecaption}</p>

                                <h2>{article.content.title}</h2>

                                <p className="mb-1 mt-1 text-gray-600">
                                    Oppdatert {formatDate(new Date(article.published_at ?? article.created_at))}
                                    {article.content.publisher && (
                                        <span className="publisher"> - {article.content.publisher}</span>
                                    )}
                                </p>

                                <p className="text-xl font-medium mb-4 mt-4">{article.content.preview}</p>
                                {/** @ts-ignore */}
                                <RichTextRenderer blok={article.content} className="article-text" />
                            </article>
                        )}
                    </StoryblokWrapper>
                    {backLink()}
                </Column>
            </Container>
        </PageTemplate>
    );
}
