export enum EnvironmentType {
    LOCAL = "publizm-local",
    DEVELOPMENT = "publizm-dev",
    TEST = "publizm-test",
    PROD = "publizm-web",
}

/**
 * Provides helper functions to determine the runtime environment
 */
export class Environment {
    /**
     * @returns `true` if executed in a cloud function (server side), otherwise `false`
     */
    public static isServer(env: any): boolean {
        return this.gcpProject(env) !== undefined;
    }

    /**
     * @returns `true` if executed in the client (browser), otherwise `false`
     */
    public static isClient(env: any): boolean {
        return this.reactProject(env) !== undefined;
    }

    /**
     * @returns `true` if executed in the emulator, otherwise `false`
     */
    public static isEmulator(env: any): boolean {
        return env?.FUNCTIONS_EMULATOR === "true";
    }

    /**
     * @returns `true` if executed in the local developer environment (IDE/emulator), otherwise `false`
     */
    public static isLocal(env: any): boolean {
        return this.getEnvironment(env) === EnvironmentType.LOCAL;
    }

    /**
     * @returns `true` if executed in the `publizm-dev` Firebase environment, otherwise `false`
     */
    public static isDevelopment(env: any): boolean {
        return !this.isEmulator(env) && this.getEnvironment(env) === EnvironmentType.DEVELOPMENT;
    }

    /**
     * @returns `true` if executed in the `publizm-test` Firebase environment, otherwise `false`
     */
    public static isTest(env: any): boolean {
        return this.getEnvironment(env) === EnvironmentType.TEST;
    }

    /**
     * @returns `true` if executed in the `publizm-web` Firebase environment, otherwise `false`
     */
    public static isProduction(env: any): boolean {
        return this.getEnvironment(env) === EnvironmentType.PROD;
    }

    /**
     * @returns the EnvironmentType representing the current rutime environment
     */
    public static getEnvironment(env: any): EnvironmentType {
        const environmentId = this.isServer(env) ? this.gcpProject(env) : this.reactProject(env);
        return this.resolveEnvironment(environmentId);
    }

    public static toString(verbose = true, env: any): string {
        if (verbose) {
            return (
                "Environment:" +
                JSON.stringify({
                    gcpProject: this.gcpProject(env),
                    reactProject: this.reactProject(env),
                    isServer: this.isServer(env),
                    isEmulator: this.isEmulator(env),
                    isClient: this.isClient(env),
                    isLocal: this.isLocal(env),
                    isDev: this.isDevelopment(env),
                    isTest: this.isTest(env),
                    isProd: this.isProduction(env),
                }) +
                "\nProcess.env: " +
                JSON.stringify(process.env)
            );
        } else {
            return JSON.stringify({
                Environment: this.getEnvironment(env),
                Location: this.isServer(env) ? "Server" : "Browser",
            });
        }
    }

    private static resolveEnvironment(envId?: string) {
        switch (envId) {
            case EnvironmentType.PROD:
                return EnvironmentType.PROD;
            case EnvironmentType.TEST:
                return EnvironmentType.TEST;
            case EnvironmentType.DEVELOPMENT:
                return EnvironmentType.DEVELOPMENT;
            default:
                return EnvironmentType.LOCAL;
        }
    }

    /**
     * @returns the Google Cloud Platform (server side) project id
     */
    private static gcpProject(env: any): string | undefined {
        if (this.isEmulator(env)) {
            // Needed because projectId in emulator is publizm-dev, same as for EnvironmentType.DEVELOPMENT
            return EnvironmentType.LOCAL;
        } else {
            return env?.GCP_PROJECT || env?.GCLOUD_PROJECT;
        }
    }

    /**
     * @returns the (client side) project id
     */
    private static reactProject(env: any): string | undefined {
        if (env?.VITE_FIREBASE_localEnv) {
            return EnvironmentType.LOCAL;
        } else {
            return env?.VITE_FIREBASE_projectId;
        }
    }
}
