import { Timestamp } from "firebase-admin/firestore";
import { Book } from "shared";
import { v4 as uuidv4 } from "uuid";

import { FirestoreCollection } from "./FirestoreTypes";
import { DataSource, FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";
export type Licensee = LicenseeBokbasen | LicenseePublizm;
export const LicenseeLatestVersion = 1;

type AllLicensee = LicenseeBokbasen | LicenseePublizm;

export class LicenseeVersionMapper extends FirestoreVersionMapper<Licensee, AllLicensee> {
    public latestVersion = LicenseeLatestVersion;
    public mapToLatest(licensee: AllLicensee): Licensee {
        if (licensee.version === LicenseeLatestVersion) {
            return licensee;
        }

        switch (licensee.version) {
            case undefined:
                return {
                    ...licensee,
                    version: LicenseeLatestVersion,
                };
            default:
                throw new Error(`Mapper for licensee version ${licensee["version"]} not implemented`);
        }
    }
}
export interface LicenseeBokbasen extends LicenseeBase {
    source: "bokbasen";
    bokbasenId: string;
    glnId: string;
    website?: string;
    discount: number;
}
export interface LicenseePublizm extends LicenseeBase {
    source: "publizm";
    phone: string;
}

export function getLicenseeId(book: Book): string {
    if (book.source == "bokbasen") {
        const publisher = book.publishingDetails?.publisher;
        return publisher ? "bokbasen_" + publisher?.bokbasenId : "bokbasen_" + uuidv4();
    }
    return uuidv4();
}
export interface LicenseeBase extends PzFirestoreBase {
    source: DataSource;
    name: string;
    licenseeId: string;
    phone?: string;
    email?: string;
    accountnumber?: string;
    orgnumber?: string;
    connectedToUser?: string;
    lastChanged?: Timestamp;
}

export interface LicenseeSaleInfo extends PzFirestoreBase {
    income: number;
    numberOfSales: number;
    year: number;
    lastChanged?: Timestamp;
    created?: Timestamp;
}

export function getLicenseeSaleInfoCollectionName(source: DataSource): string {
    if (source == "publizm") return FirestoreCollection.LICENSEE_SALE_INFO;
    return `${FirestoreCollection.LICENSEE_SALE_INFO}_${source}`;
}

export interface LicenseePaymentInfoBase extends PzFirestoreBase {
    totalBalance: number;
    unpaidBalance: number;
    lastPayment?: Timestamp;
    lastChanged?: Timestamp;
}

export type LicenseePaymentInfo = LicenseePaymentInfoBase;
export const LicenseePaymentInfoLatestVersion = 1;

type AllLicenseePaymentInfo = LicenseePaymentInfo;

export class LicenseePaymentInfoVersionMapper extends FirestoreVersionMapper<
    LicenseePaymentInfo,
    AllLicenseePaymentInfo
> {
    public latestVersion = LicenseePaymentInfoLatestVersion;
    public mapToLatest(licenseePaymentInfo: AllLicenseePaymentInfo): LicenseePaymentInfo {
        if (licenseePaymentInfo.version === LicenseePaymentInfoLatestVersion) {
            return licenseePaymentInfo;
        }

        switch (licenseePaymentInfo.version) {
            case undefined:
                return {
                    ...licenseePaymentInfo,
                    version: LicenseePaymentInfoLatestVersion,
                };
            default:
                throw new Error(
                    `Mapper for LicenseePalicenseePaymentInfoymentInfo version ${licenseePaymentInfo["version"]} not implemented`
                );
        }
    }
}
