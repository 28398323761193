import "./StepIndicator.css";

import { ReactElement, useEffect, useState } from "react";

interface StepIndicatorProps {
    activeStep: number;
    steps: StepProps[];
    onChange: (step: number) => void;
}

export default function StepIndicator(props: StepIndicatorProps) {
    const [activeStep, setActiveStep] = useState<number>(props.activeStep);

    useEffect(() => {
        setActiveStep(props.activeStep);
    }, [props.activeStep]);

    function onStepClick(index: number) {
        setActiveStep(index);
        props.onChange(index);
    }

    return (
        <ul className="steps step-indicator has-content-centered is-horizontal">
            {props.steps.map((step, index) => (
                <Step key={index} {...step} onClick={() => onStepClick(index)} active={activeStep === index} />
            ))}
        </ul>
    );
}

interface StepProps {
    label?: string;
    active?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    stepMarker?: ReactElement | string;
}

function Step({ label, active, onClick, stepMarker, disabled }: StepProps) {
    function onStepClick() {
        !disabled && onClick?.();
    }

    return (
        <li
            className={`steps-segment ${disabled ? "disabled" : ""} ${active ? "is-active" : ""}`}
            onClick={onStepClick}
        >
            <a href="#" className={`steps-marker`}>
                <span className="icon">{stepMarker}</span>
            </a>
            <div className="steps-content">{label && (disabled ? <p>{label}</p> : <a href="#">{label}</a>)}</div>
        </li>
    );
}
