import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import { ReactElement, useState } from "react";
import { BookGroup } from "shared";

import PzButton from "../../../../../../components/form/PzButton";
import PzModal from "../../../../../../components/modal/PzModal";
import { PzTitle } from "../../../../../../components/text/PzTitle";
import { Content, Modal } from "../../../../../../components/trunx";
import { formatDate } from "../../../../../../components/utils/DateUtils";
import { BookGroupMutations } from "../../../../../../services/firestore/queries/book/bookMutations";
import BookGroupModal from "./BookGroupModal";

interface TableProps {
    bookGroupList: BookGroup[];
}
export default function BookGroupTable({ bookGroupList }: TableProps): ReactElement {
    const columns: ColumnData[] = [
        {
            label: "Gruppenavn",
            sortByProperty: "name",
        },
        {
            label: "Beskrivelse",
            sortByProperty: "description",
        },
        {
            label: "Opprett dato",
            sortByProperty: "createdDate",
        },
        {
            label: "",
        },
    ];

    function createRow(bookGroup: BookGroup): RowData {
        return {
            components: [
                {
                    content: bookGroup.name ?? "",
                    width: "30%",
                },
                {
                    content: bookGroup.description ?? "",
                    width: "20%",
                },
                {
                    content: formatDate(bookGroup.createdDate) ?? "-",
                    width: "50%",
                },
                {
                    content: (
                        <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                            <UpdateOrCreateGroup id={bookGroup.id} bookGroup={bookGroup} />
                            <DeleteGroupButton id={bookGroup.id!} bookGroup={bookGroup} />
                        </div>
                    ),
                    width: "10%",
                },
            ],
        };
    }

    function getSearchData(bookGroup: BookGroup): string {
        return bookGroup.description ?? "" + bookGroup.name;
    }

    return (
        <>
            <PzTable
                createRow={createRow}
                searchData={getSearchData}
                data={bookGroupList}
                columns={columns}
                tableAddOn={<UpdateOrCreateGroup />}
                rowKeyGenerator={(data) => data.id!}
                defaultSortIndex={1}
            />
        </>
    );
}

interface INewGroupProps {
    id?: string;
    bookGroup?: BookGroup;
}
function UpdateOrCreateGroup({ id, bookGroup }: INewGroupProps) {
    const [openModal, setOpenModal] = useState(false);
    const isNew = id == undefined;
    return (
        <>
            <PzButton isPrimary={isNew} pr6={isNew} pl6={isNew} onClick={() => setOpenModal(true)}>
                <span>
                    <span style={isNew ? { marginRight: "10px" } : {}}>
                        <strong>{!isNew ? "Oppdater" : "Ny gruppe"}</strong>
                    </span>
                    {isNew && <FontAwesomeIcon icon={faPlus} color="black" />}
                </span>
            </PzButton>
            {openModal && <BookGroupModal open onClose={() => setOpenModal(false)} initialData={bookGroup} id={id} />}
        </>
    );
}

interface IDeleteGroupProps {
    id: string;
    bookGroup: BookGroup;
}
function DeleteGroupButton({ id, bookGroup }: IDeleteGroupProps) {
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <PzButton isDanger onClick={() => setOpenModal(true)}>
                Slett
            </PzButton>
            {openModal && <DeleteGroupModal onClose={() => setOpenModal(false)} bookGroup={bookGroup} id={id} />}
        </>
    );
}

interface IDeleteGroupModalProps {
    bookGroup: BookGroup;
    id: string;
    onClose: () => void;
}
function DeleteGroupModal({ onClose, id, bookGroup }: IDeleteGroupModalProps) {
    const deleteGroupFn = BookGroupMutations.deleteGroup();
    function onDelete() {
        deleteGroupFn.mutate({ id, groupName: bookGroup.name });
        onClose();
    }
    return (
        <PzModal contentStyle={{ maxHeight: "50rem" }} isActive onClose={onClose}>
            <Modal.Card.Body style={{ minWidth: "30rem", height: "100%", overflow: "hidden" }}>
                <PzTitle is4>Slett gruppe</PzTitle>
                <Content style={{ overflowY: "auto", maxHeight: "30rem", height: "max-content" }}>
                    Er du sikker på at du vil slette gruppen med navn <strong>{bookGroup.name}</strong>?
                </Content>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <PzButton onClick={onDelete} isPrimary>
                        Slett
                    </PzButton>
                    <PzButton isSecondary onClick={onClose} type="button" value="Avbryt">
                        Avbryt
                    </PzButton>
                </div>
            </Modal.Card.Body>
        </PzModal>
    );
}
