import classnames from "classnames";
import React from "react";

import { bulmaClassName, trunxPropsToClassnamesObject } from "./classNames";
import { ErrorBoundaryProps } from "./ErrorBoundary";
import {
    extractModifiersProps,
    HelpersProps,
    MainColorsProps,
    modifierPropsToClassnamesObject,
    SizeProps,
} from "./modifiers";

export interface SelectProps
    extends React.SelectHTMLAttributes<HTMLSelectElement>,
        ErrorBoundaryProps,
        HelpersProps,
        MainColorsProps,
        SizeProps {
    isFocused?: boolean;
    isHovered?: boolean;
    isLoading?: boolean;
    isMultiple?: boolean;
}

export class Select extends React.Component<SelectProps> {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = { hasError: false };

    render(): React.ReactNode {
        const [
            modifiersProps,
            { children, className, fallbackUI, isFocused, isHovered, isLoading, isMultiple, ...props },
        ] = extractModifiersProps(this.props);

        if (this.state.hasError) return fallbackUI;

        return (
            <div
                className={classnames(
                    bulmaClassName.select,
                    className,
                    modifierPropsToClassnamesObject(modifiersProps),
                    trunxPropsToClassnamesObject({
                        isFocused,
                        isHovered,
                        isLoading,
                        isMultiple,
                    })
                )}
            >
                <select {...props} multiple={isMultiple}>
                    {children}
                </select>
            </div>
        );
    }
}
