import React from "react";

import { bulmaClassName } from "./classNames";
import { ErrorBoundaryProps } from "./ErrorBoundary";
import { HelpersProps } from "./modifiers";
import { renderElement } from "./renderElement";

export interface ColumnsProps extends React.HTMLAttributes<HTMLDivElement>, ErrorBoundaryProps, HelpersProps {
    isDesktop?: boolean;
    isGapless?: boolean;
    isMobile?: boolean;
    isMultiline?: boolean;
}

export class Columns extends React.Component<ColumnsProps> {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = { hasError: false };

    render(): React.ReactNode {
        const { fallbackUI, isDesktop, isGapless, isMobile, isMultiline, ...props } = this.props;

        if (this.state.hasError) return fallbackUI;

        return renderElement("div", props, bulmaClassName.columns, {
            isDesktop,
            isGapless,
            isMobile,
            isMultiline,
        });
    }
}
