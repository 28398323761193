import "./BookMetaDataSection.css";

import { hasBookTypeAnyFiles } from "common/BookUtils";
import { CSSProperties, PropsWithChildren, useEffect, useState } from "react";
import { AuthorRole, Book, BookAuthor, BookType, languageDisplayValue, UrlUtils } from "shared";

import { AppPath } from "../../../../common/AppPath";
import { CatalogueFilterKeys } from "../../catalogue/CatalogueFilterContext";
import { BookMetadataMapper } from "./BookMetadataMapper";

export default function BookMetaDataSection(props: { book: Book }) {
    const { book } = props;

    function shouldRenderEbookColumn(): boolean {
        if (!hasBookTypeAnyFiles(BookType.EBOOK, book)) {
            return false;
        }
        return true;
    }

    function shouldRenderAudiobookColumn(): boolean {
        return hasBookTypeAnyFiles(BookType.AUDIO, book);
    }

    function shouldRenderPodcastColumn(): boolean {
        return hasBookTypeAnyFiles(BookType.PODCAST, book) && book.bookType == BookType.PODCAST;
    }
    return (
        <div className="meta-data-section-container">
            {/* {renderTags()} */}
            {shouldRenderEbookColumn() && <EbookColumn book={book} />}
            {shouldRenderAudiobookColumn() && <AudioBookColumn book={book} />}
            {shouldRenderPodcastColumn() && <PodcastColumn book={book} />}
        </div>
    );
}

interface EBookColumnProps {
    book: Book;
}

function EbookColumn({ book }: EBookColumnProps) {
    const [ebook, setEbook] = useState<Book>();

    useEffect(() => {
        BookMetadataMapper.getBookWithType(book, BookType.EBOOK).then(setEbook);
    }, []);

    if (!ebook) {
        return null;
    }

    const collectionQueryParams = BookMetadataMapper.getCollections(ebook)
        .map((c) => `${CatalogueFilterKeys.COLLECTION}=${c}`)
        .join("&");
    const collectionUrl = `${AppPath.CATALOGUE_ALL_BOOKS}?${collectionQueryParams}`;
    const translatorAuthor =
        ebook.source == "bokbasen" ? ebook.authors?.find((a) => a.role == AuthorRole.TranslatedBy) : null;
    return (
        <MetadataTable title="Ebok" titleStyle={{ marginLeft: "-0.4rem" }}>
            <>
                {ebook.publishedYear && (
                    <MetadataRow label="Utgitt">
                        <p>{ebook.publishedYear}</p>
                    </MetadataRow>
                )}
                {ebook.language && (
                    <MetadataRow label="Språk">
                        <p>{languageDisplayValue(ebook.language)}</p>
                    </MetadataRow>
                )}
                {ebook.ebook?.translator && <MetadataRow label="Oversetter">{ebook.ebook?.translator}</MetadataRow>}
                {translatorAuthor && (
                    <MetadataRow label="Oversetter">
                        <AuthorName authorName={translatorAuthor.name} bookAuthor={translatorAuthor} />
                    </MetadataRow>
                )}
                {ebook.source == "bokbasen" && ebook.isbn && <MetadataRow label="ISBN">{ebook.isbn}</MetadataRow>}
                {BookMetadataMapper.getFileFormat(ebook) && (
                    <MetadataRow label="Format">{BookMetadataMapper.getFileFormat(ebook)}</MetadataRow>
                )}
                {ebook.source == "bokbasen" && ebook.originalTitle && (
                    <MetadataRow label="Format">{BookMetadataMapper.getFileFormat(ebook)}</MetadataRow>
                )}
                {ebook.source == "bokbasen" && ebook.originalTitle && (
                    <MetadataRow label="Original tittel">
                        <p>{ebook.originalTitle}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.getEBookPages(ebook) && (
                    <MetadataRow label="Sider">
                        <p>{BookMetadataMapper.getEBookPages(ebook)}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.getBookTechincalProtection(ebook) && (
                    <MetadataRow label="Beskyttelse">
                        <p>{BookMetadataMapper.getBookTechincalProtection(ebook)}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.hasCollection(ebook) && (
                    <MetadataRow label="Serie">
                        <a href={collectionUrl}>{BookMetadataMapper.renderCollection(ebook)}</a>
                    </MetadataRow>
                )}
            </>
        </MetadataTable>
    );
}

interface PodcastColumnProps {
    book: Book;
}
function PodcastColumn({ book }: PodcastColumnProps) {
    const [podcast, setPodcast] = useState<Book>();

    useEffect(() => {
        BookMetadataMapper.getBookWithType(book, BookType.PODCAST).then(setPodcast);
    }, []);

    if (!podcast) {
        return null;
    }
    const collectionQueryParams = BookMetadataMapper.getCollections(podcast)
        .map((c) => `${CatalogueFilterKeys.COLLECTION}=${c}`)
        .join("&");
    const collectionUrl = `${AppPath.CATALOGUE_ALL_BOOKS}?${collectionQueryParams}`;
    const narratorAuthor =
        podcast.source == "bokbasen" ? podcast.authors?.find((a) => a.role == AuthorRole.ReadBy) : null;
    const translatorAuthor =
        podcast.source == "bokbasen" ? podcast.authors?.find((a) => a.role == AuthorRole.TranslatedBy) : null;

    return (
        <MetadataTable title="Podcast" titleStyle={{ marginLeft: "-0.7rem" }}>
            <>
                {podcast.publishedYear && (
                    <MetadataRow label="Utgitt">
                        <p>{podcast.publishedYear}</p>
                    </MetadataRow>
                )}
                {podcast.language && (
                    <MetadataRow label="Språk">
                        <p>{languageDisplayValue(podcast.language)}</p>
                    </MetadataRow>
                )}
                {podcast.audiobook?.translator && (
                    <MetadataRow label="Oversetter">
                        <AuthorName
                            authorName={podcast.audiobook?.translator ?? podcast.bookFile?.translator}
                            bookAuthor={translatorAuthor}
                        />
                    </MetadataRow>
                )}
                <MetadataRow label="Lengde">
                    <p>{BookMetadataMapper.getAudiobookDuration(podcast)}</p>
                </MetadataRow>
                {podcast.audiobook?.narrator && (
                    <MetadataRow label="Innleser">
                        <p>{podcast.audiobook?.narrator}</p>
                    </MetadataRow>
                )}
                {/* Bokbasen */}
                {podcast.source == "bokbasen" && podcast.originalTitle && (
                    <MetadataRow label="Original tittel">
                        <p>{podcast.originalTitle}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.isAudioBook(podcast.bookFile) && podcast.bookFile?.producer && (
                    <MetadataRow label="Produsent">
                        <p>{podcast.bookFile?.producer}</p>
                    </MetadataRow>
                )}
                {podcast.audiobook?.producer && (
                    <MetadataRow label="Produsent">
                        <p>{podcast.audiobook?.producer}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.hasCollection(podcast) && (
                    <MetadataRow label="Serie">
                        <a href={collectionUrl}>{BookMetadataMapper.renderCollection(podcast)}</a>
                    </MetadataRow>
                )}
            </>
        </MetadataTable>
    );
}

interface AudioBookColumnProps {
    book: Book;
}
function AudioBookColumn({ book }: AudioBookColumnProps) {
    const [audioBook, setAudioBook] = useState<Book>();

    useEffect(() => {
        BookMetadataMapper.getBookWithType(book, BookType.AUDIO).then(setAudioBook);
    }, []);

    if (!audioBook) {
        return null;
    }
    const collectionQueryParams = BookMetadataMapper.getCollections(audioBook)
        .map((c) => `${CatalogueFilterKeys.COLLECTION}=${c}`)
        .join("&");
    const collectionUrl = `${AppPath.CATALOGUE_ALL_BOOKS}?${collectionQueryParams}`;
    const narratorAuthor =
        audioBook.source == "bokbasen" ? audioBook.authors?.find((a) => a.role == AuthorRole.ReadBy) : null;
    const translatorAuthor =
        audioBook.source == "bokbasen" ? audioBook.authors?.find((a) => a.role == AuthorRole.TranslatedBy) : null;

    return (
        <MetadataTable title="Lydbok" titleStyle={{ marginLeft: "-0.7rem" }}>
            <>
                {audioBook.publishedYear && (
                    <MetadataRow label="Utgitt">
                        <p>{audioBook.publishedYear}</p>
                    </MetadataRow>
                )}
                {audioBook.language && (
                    <MetadataRow label="Språk">
                        <p>{languageDisplayValue(audioBook.language)}</p>
                    </MetadataRow>
                )}
                {audioBook.audiobook?.translator && (
                    <MetadataRow label="Oversetter">
                        <AuthorName
                            authorName={audioBook.audiobook?.translator ?? audioBook.bookFile?.translator}
                            bookAuthor={translatorAuthor}
                        />
                    </MetadataRow>
                )}
                <MetadataRow label="Lengde">
                    <p>{BookMetadataMapper.getAudiobookDuration(audioBook)}</p>
                </MetadataRow>
                {audioBook.audiobook?.narrator && (
                    <MetadataRow label="Innleser">
                        <p>{audioBook.audiobook?.narrator}</p>
                    </MetadataRow>
                )}
                {/* Bokbasen */}
                {audioBook.audience && book.audience !== "Voksen" && (
                    <MetadataRow label="Aldersgruppe">
                        <p>{audioBook.audience}</p>
                    </MetadataRow>
                )}
                {audioBook.source == "bokbasen" && audioBook.isbn && (
                    <MetadataRow label="ISBN">
                        <p>{audioBook.isbn}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.getFileFormat(audioBook) && (
                    <MetadataRow label="Format">
                        <p>{BookMetadataMapper.getFileFormat(audioBook)}</p>
                    </MetadataRow>
                )}
                {audioBook.source == "bokbasen" && audioBook.originalTitle && (
                    <MetadataRow label="Original tittel">
                        <p>{audioBook.originalTitle}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.getBookTechincalProtection(audioBook) && (
                    <MetadataRow label="Beskyttelse">
                        <p>{BookMetadataMapper.getBookTechincalProtection(audioBook)}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.isAudioBook(audioBook.bookFile) && audioBook.bookFile?.sounddesigner && (
                    <MetadataRow label="Lyddesigner">
                        <p>{audioBook.bookFile?.sounddesigner}</p>
                    </MetadataRow>
                )}
                {audioBook.audiobook?.sounddesigner && (
                    <MetadataRow label="Lyddesigner">
                        <p>{audioBook.audiobook?.sounddesigner}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.isAudioBook(audioBook.bookFile) && audioBook.bookFile?.narrator && (
                    <MetadataRow label="Innleser">
                        <AuthorName
                            authorName={audioBook.audiobook?.narrator ?? audioBook.bookFile?.narrator}
                            bookAuthor={narratorAuthor}
                        />
                    </MetadataRow>
                )}
                {BookMetadataMapper.isAudioBook(audioBook.bookFile) && audioBook.bookFile?.producer && (
                    <MetadataRow label="Produsent">
                        <p>{audioBook.bookFile?.producer}</p>
                    </MetadataRow>
                )}
                {audioBook.audiobook?.producer && (
                    <MetadataRow label="Produsent">
                        <p>{audioBook.audiobook?.producer}</p>
                    </MetadataRow>
                )}
                {BookMetadataMapper.hasCollection(audioBook) && (
                    <MetadataRow label="Serie">
                        <a href={collectionUrl}>{BookMetadataMapper.renderCollection(audioBook)}</a>
                    </MetadataRow>
                )}
            </>
        </MetadataTable>
    );
}

function AuthorName({ authorName, bookAuthor }: { authorName: string; bookAuthor?: BookAuthor | null }) {
    if (bookAuthor) {
        return (
            <a href={UrlUtils.encodeAuthorForAuthor(bookAuthor, AppPath.AUTHOR_PROFILE_DETAILS)}>{bookAuthor?.name}</a>
        );
    }
    return <p>{authorName}</p>;
}

function MetadataRow({ label, children }: PropsWithChildren<{ label: string }>) {
    return (
        <tr className="metadata-row">
            <td className="tlabel" style={{ textAlign: "right" }}>
                {label}
            </td>
            <td>
                <p className="line"></p>
            </td>
            <td className="value">{children}</td>
        </tr>
    );
}

function MetadataTable({
    children,
    title,
    titleStyle,
}: PropsWithChildren<{ title: string; titleStyle?: CSSProperties }>) {
    return (
        <table className="meta-data-table">
            <thead>
                <th style={{ width: "6rem" }}></th>
                <th style={{ width: "2rem" }}></th>
                <th></th>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td colSpan={2} className="font-bold mb-1" style={{ textAlign: "left" }}>
                        <p style={titleStyle}>{title}</p>
                    </td>
                </tr>
                {children}
            </tbody>
        </table>
    );
}
