import { ReactElement, useEffect, useState } from "react";
import { BookGroupNames } from "shared";

import PzCheckbox from "../../../../../components/form/PzCheckbox";
import { BookMutations } from "../../../../../services/firestore/queries/book/bookMutations";

interface AktueltCheckboxProps {
    groups: string[];
    bookId: string;
}

export default function AktueltCheckbox(props: AktueltCheckboxProps): ReactElement | null {
    const [isChecked, setIsChecked] = useState(false);

    const removeBookGroupFn = BookMutations.removeBookGroup();
    const addBookGroupFn = BookMutations.addBookGroup();

    useEffect(() => {
        setIsChecked(props.groups.includes(BookGroupNames.AKTUELT));
    }, [props.groups]);

    function onChange() {
        if (props.bookId) {
            if (!isChecked) {
                addBookGroupFn.mutate({ groupName: BookGroupNames.AKTUELT, id: props.bookId });
            } else {
                removeBookGroupFn.mutate({ groupName: BookGroupNames.AKTUELT, id: props.bookId });
            }
        }

        setIsChecked(!isChecked);
    }

    return <PzCheckbox checked={isChecked} mt1 name="featured" onClick={onChange}></PzCheckbox>;
}
