import "./BookDescription.css";

import { Column, Container, Label } from "components/trunx";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ReactElement } from "react";
import { BOOK_TAGS } from "shared";

import PzCheckbox from "../../../../../components/form/PzCheckbox";
import PzTextArea from "../../../../../components/form/PzTextArea";
import PzPanel, { PzPanelOption } from "../../../../../components/PzPanel";
import PzSelectDropdown from "../../../../../components/PzSelectDropdown";
import { PzTitle } from "../../../../../components/text/PzTitle";
import { BookAdminFormValues } from "./BookAdminTypes";
const contentOptions: PzPanelOption[] = [
    {
        name: "Sammendrag",
        content: <BookSummary />,
    },
    {
        name: "Emneknagger",
        content: <BookTags />,
    },
];

const reviewsOptions: PzPanelOption[] = [
    {
        name: "Anmeldelse",
        content: <BookReview />,
    },
    {
        name: "Omtaler fra redaksjon",
        content: <ReviewsFromEditors />,
    },
    {
        name: "Omtaler fra publikum",
        content: <ReviewsFromReaders />,
    },
];

export default function BookDescription(): ReactElement {
    return (
        <Container p0 m0>
            <Column m0>
                <PzTitle hasTextCentered>Innholdsbeskrivelse</PzTitle>
            </Column>
            <PzPanel options={contentOptions} />
            <PzPanel options={reviewsOptions} />
        </Container>
    );
}

function BookSummary() {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Column>
            <Label>Sammendrag</Label>
            <PzTextArea name="summary" textAreaRef={register()} error={errors.summary?.message} />
        </Column>
    );
}

function BookTags() {
    return (
        <Column>
            <Label pl4 hasTextGrey>
                Emneknagger
            </Label>
            <Column className="booktags" p0 m0>
                {BOOK_TAGS.map((tag, index) => (
                    <BookTagButton key={index} tag={tag} />
                ))}
            </Column>
        </Column>
    );
}

interface BookTagButtonProps {
    tag: string;
}
function BookTagButton({ tag }: BookTagButtonProps) {
    const { register, getValues } = useFormContext<BookAdminFormValues>();
    const [isSelected, setIsSelected] = useState<boolean>(false);
    useEffect(() => {
        const tags: string[] = getValues("tags") ?? [];
        setIsSelected(tags && tags?.includes(tag));
    }, []);
    return <PzCheckbox m2 p2 defaultChecked={isSelected} name="tags" value={tag} inputRef={register()} />;
}

function ReviewsFromEditors() {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Column>
            <Label>Omtaler</Label>
            <PzTextArea name="reviews" textAreaRef={register()} error={errors.reviews?.message} />
        </Column>
    );
}

function BookReview() {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Column>
            <Label>Anmeldelse</Label>
            <PzTextArea name="bookReview.content" textAreaRef={register()} error={errors.reviews?.message} />
            <div style={{ width: "100px" }}>
                <PzSelectDropdown
                    name="bookReview.rating"
                    ml0
                    pl0
                    selectRef={register()}
                    title="Terningkast"
                    options={[...Array(6).keys()]
                        .map((value) => value + 1)
                        .map((value) => ({ value: value, label: value.toString() }))}
                ></PzSelectDropdown>
            </div>
        </Column>
    );
}

function ReviewsFromReaders() {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Column>
            <Label>Omtaler</Label>
            <PzTextArea name="reviewsReaders" textAreaRef={register()} error={errors.reviewsReaders?.message} />
        </Column>
    );
}
