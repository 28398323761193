import { Timestamp } from "firebase-admin/firestore";

import { BookLicenseeBase, BookSource, BookType } from "./BookTypes";
import { Currency } from "./CommonTypes";
import { FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";

export type Transaction = TransactionBase;
export const TransactionLatestVersion = 1;

type AllTransactions = TransactionBase;

export interface TransactionBookLicensee extends BookLicenseeBase {
    earnings: number;
    totalEarnings: number;
}

export interface BookTransactionDetails {
    booksource: BookSource;
    bookType: BookType;
    price: number;
    salePrice: number;
    bookId?: string;
    orderid?: string;
    fulfilmentid?: string;
}

export interface VippsTransactionDetails {
    reference: string;
    state: "CAPTURED" | "REFUNDED" | "AUTHORIZED" | "CANCELLED";
}
/* This interface should only be used for user with Role: CUSTOMER */
export interface TransactionBase extends PzFirestoreBase {
    skipProcessing?: boolean; // Brukes ved debugging for å unngå prosessering av transaksjon
    booksource: BookSource;
    bookId: string;
    includesBookIds?: string[];
    includesBooks?: BookTransactionDetails[];
    vippsDetails?: VippsTransactionDetails;
    fulfilmentid?: string;
    bookType: BookType;
    timestamp: Timestamp;
    price: number;
    priceInØre: number;
    originalPrice?: number;
    currency: Currency;
    licensees?: TransactionBookLicensee[];
    licenseeIds?: string[];
    isDuplicate?: boolean;
    isCopyFromUser?: string;
}

export class TransactionVersionMapper extends FirestoreVersionMapper<Transaction, AllTransactions> {
    public latestVersion = TransactionLatestVersion;
    public mapToLatest(transaction: AllTransactions): Transaction {
        if (transaction.version === TransactionLatestVersion) {
            return transaction;
        }

        switch (transaction.version) {
            case undefined:
                return {
                    ...transaction,
                    version: TransactionLatestVersion,
                };
            default:
                throw new Error(`Mapper for customer trnsaction version ${transaction["version"]} not implemented`);
        }
    }
}
