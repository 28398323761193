import "./DropZone.css";

import { Column, Columns } from "components/trunx";
import React, { useRef, useState } from "react";

import UploadFileIcon from "../../assets/svg/UploadFileIcon";
import { FirebaseUploadFile } from "../../services/storage/types";
import { FileUtils } from "../utils/FileUtils";
import FileTagView from "./FileTagView";
interface DropZoneProps {
    onFileAdded: (file: FirebaseUploadFile) => void;
    onDeleteFile: (file: FirebaseUploadFile) => void;
    files: FirebaseUploadFile[];
}
// @Deprecated DO NOT USE
export function DropZone({ onFileAdded, files, onDeleteFile }: DropZoneProps) {
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    function dragover_handler(ev: React.DragEvent<any>) {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = "copy";
        setIsMouseOver(true);
    }

    function dragexit_handler(ev: React.DragEvent<any>) {
        setIsMouseOver(false);
    }

    async function drop_handler(ev: React.DragEvent<any>) {
        ev.preventDefault();
        // Get the id of the target and add the moved element to the target's DOM
        if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
            const dataTransferFile = ev.dataTransfer.files[0];
            const file = await FileUtils.mapUploadedFile(dataTransferFile);
            onFileAdded(file);
        }
        setIsMouseOver(false);
        ev.dataTransfer.clearData();
    }

    function handleAddFileButtonClick() {
        inputRef?.current?.click();
    }

    async function onFileUploaded(ev: React.ChangeEvent<any>) {
        ev.preventDefault();
        const fileUploaded = ev.target.files[0];
        const file = await FileUtils.mapUploadedFile(fileUploaded);
        onFileAdded(file);
    }

    return (
        <>
            <Columns
                ml1
                mr1
                className={`dropzone ${isMouseOver ? "dropzone-hover" : ""}`}
                hasBackgroundLight
                onDrop={drop_handler}
                onDragLeave={dragexit_handler}
                onDragOver={dragover_handler}
            >
                <Column className="dropzone-content" isAlignSelfCenter pb6>
                    <div className="upload-file-icon">
                        <UploadFileIcon />
                    </div>
                    <a className="link mr-2" onClick={handleAddFileButtonClick}>
                        Velg en fil
                    </a>
                    eller slipp filen din her
                </Column>
            </Columns>
            <input ref={inputRef} id="uploadInput" type="file" style={{ display: "none" }} onChange={onFileUploaded} />
            <FileTagView files={files} onDeleteFile={onDeleteFile} />
        </>
    );
}
