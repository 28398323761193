import "./Articles.css";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useStoryblokApi } from "@storyblok/react";
import { Column, Columns } from "components/trunx";
import { useEffect, useState } from "react";

import PzIconButton from "../../components/form/PzIconButton";
import LoadingIndicator from "../../components/LoadingIndicator";
import { storyblokEnv } from "../../components/storyblok/StoryblokComponents";
import PzText from "../../components/text/PzText";
import ArticleCard from "./ArticleCard";
import { ArticleItem } from "./types";
import NewsletterModal from "../../components/NewsletterModal";
const pageSize = 10;
export default function Articles() {
    const [page, setPage] = useState<number>(1);
    const [totalArticles, setTotalArticles] = useState<number>(1);
    const [stories, setStories] = useState<ArticleItem[]>([]);
    useEffect(() => {
        useStoryblokApi()
            .getStories({
                version: storyblokEnv,
                sort_by: "first_published_at:desc:string",
                per_page: pageSize,
                page: page,
                filter_query: {
                    component: { in: "article" },
                },
            })
            .then((res) => {
                // @ts-ignore
                setStories(res.data.stories as ArticleItem[]);
                setTotalArticles(res.total);
            });
    }, [page]);
    // @ts-ignore
    if (stories.length === 0) {
        return <LoadingIndicator />;
    }
    const totalPages = Math.max(1, Math.round(totalArticles / pageSize + 0.4));
    return (
        <Columns isFlexDirectionColumn>
            <Columns className="articles">
                {/** @ts-ignore */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", textWrap: "nowrap", alignItems: "center" }}>
                    <div style={{ marginTop: "7px" }}>
                        <NewsletterModal />
                    </div>
                </div>
                {stories.map((item) => (
                    <ArticleCard item={item} isSmall key={item.id} />
                ))}
            </Columns>

            <ArticlesPaging
                currentPage={page}
                totalPages={totalPages}
                openNextPage={() => setPage((prev) => Math.min(totalPages, prev + 1))}
                openPrevPage={() => setPage((prev) => Math.max(1, prev - 1))}
            />
        </Columns>
    );
}

interface ArticlesPagingProps {
    currentPage: number;
    totalPages: number;
    openPrevPage: () => void;
    openNextPage: () => void;
}
function ArticlesPaging({ currentPage, totalPages, openPrevPage, openNextPage }: ArticlesPagingProps) {
    return (
        <Column isFlex isFlexDirectionRow isAlignSelfCenter className="is-9">
            <PzIconButton icon={faArrowLeft} isSmall className="articles-paging-button" onClick={openPrevPage} />
            <PzText hasTextCentered>
                Side {currentPage} av {totalPages}
            </PzText>
            <PzIconButton icon={faArrowRight} isSmall className="articles-paging-button" onClick={openNextPage} />
        </Column>
    );
}
