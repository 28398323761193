import "./PzPanel.css";

import { Column, Container } from "components/trunx";
import { ReactNode, useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import { ReactElement } from "react";

import { AnalyticsApi } from "../services/firestore/queries/queriesCommon";
import CollapsibleExpandButton from "./dashboard/CollapsibleExpandButton";
import PzMenuTabs from "./pagetemplate/menu/PzMenuTabs";
import { PzTitle } from "./text/PzTitle";

export interface PzPanelOption {
    name: string;
    content: ReactNode;
}

interface PzPanelProps {
    collapsible?: boolean;
    className?: string;
    title?: string;
    options: PzPanelOption[];
}

export default function PzPanel(props: PzPanelProps): ReactElement {
    const [selectedOption, setSelectedOption] = useState<string>(props.options[0].name);
    const [isOpenCollapsible, setOpenCollapsbile] = useState<boolean>(true);
    const menuOptions = useMemo(() => props.options.map((o) => o.name), props.options);
    const toggleCollapsible = (e) => {
        e.preventDefault();
        setOpenCollapsbile((prev) => !prev);
    };

    function onMenuOptionSelected(option: string) {
        AnalyticsApi.logPageView(option);
        setSelectedOption(option);
        setOpenCollapsbile(true);
    }
    return (
        <Container pb5 m0 className={props.className}>
            {props.title && (
                <PzTitle hasTextCentered is4 hasTextWeightBold>
                    {props.title}
                </PzTitle>
            )}

            <Container className="pz-panel" style={{ minHeight: !isOpenCollapsible ? "20px" : "30vh" }}>
                <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />
                <PzMenuTabs
                    options={menuOptions}
                    selectedOption={selectedOption}
                    onOptionSelected={onMenuOptionSelected}
                />
                <Collapse isOpened={isOpenCollapsible}>
                    <Column p4>
                        <Container>
                            {props.options.map((option) => {
                                return <Column isHidden={selectedOption !== option.name}>{option.content}</Column>;
                            })}
                        </Container>
                    </Column>
                </Collapse>
            </Container>
        </Container>
    );
}
