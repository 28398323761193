import "./DashboardHorizontalMenu.css";

import { Container, Li, Tabs, Ul } from "components/trunx";
import { useAuth } from "contexts/AuthContext";
import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { AppPath } from "../../../common/AppPath";
import { useDashboard } from "../../../contexts/DashboardContext";
import dashboardUserMenu from "./DashboardUserMenu";
export default function DashboardMenu(): ReactElement {
    const location = useLocation();
    const { activeRole } = useAuth();
    const { selectedMenu, updateSelectedMenu } = useDashboard();
    const menuItems = dashboardUserMenu(activeRole, location.pathname as AppPath);

    useEffect(() => {
        updateSelectedMenu(selectedMenu && menuItems.includes(selectedMenu) ? selectedMenu : menuItems[0]);
    }, []);

    return (
        <Container>
            <Tabs pb0 mb0>
                <Ul>
                    {menuItems
                        .filter((item) =>
                            item.toLowerCase().includes("test") && import.meta.env.NODE_ENV !== "development"
                                ? false
                                : true
                        )
                        .map((item) => (
                            <DashboardMenuItem key={item} name={item} isActive={selectedMenu === item} />
                        ))}
                </Ul>
            </Tabs>
        </Container>
    );
}

function DashboardMenuItem({ name, isActive }: { name: string; isActive: boolean }) {
    const { updateSelectedMenu } = useDashboard();
    return (
        <Li onClick={() => updateSelectedMenu(name)} isActive={isActive}>
            <a>{name}</a>
        </Li>
    );
}
