import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";

interface UseOnClickOutsideProps<T extends HTMLElement> {
    ref: MutableRefObject<T | null>;
    isComponentVisible: boolean;
    setIsComponentVisible: Dispatch<SetStateAction<boolean>>;
}
export default function useOnClickOutside<T extends HTMLElement>(initialIsVisible = false): UseOnClickOutsideProps<T> {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef<T>(null);

    const handleClickOutside = (event) => {
        if (ref.current !== null && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}
