import "./TagFilePage.css";

import { Column, Help } from "components/trunx";
import React, { ReactElement } from "react";

import TagFile from "../../components/tagfile/TagFile";
import PzText from "../../components/text/PzText";
import { useUpload } from "../../contexts/UploadContext";

export default function TagFilePage(): ReactElement | null {
    const { errors, updateWrittenWorkFormData, writtenWorkFormData } = useUpload();

    function isTagSelected(tagName: string) {
        return writtenWorkFormData.tags?.includes(tagName) ?? false;
    }

    function removeTag(tagName: string) {
        return writtenWorkFormData.tags ? [...writtenWorkFormData.tags].filter((tag) => tag !== tagName) : [];
    }

    function addTag(tagName: string) {
        return writtenWorkFormData.tags ? [...writtenWorkFormData.tags, tagName] : [];
    }

    function onTagClicked(tagName: string) {
        const updatedTags = isTagSelected(tagName) ? removeTag(tagName) : addTag(tagName);
        updateWrittenWorkFormData({ tags: updatedTags });
    }

    return (
        <Column>
            <TagInfoText />
            <>
                <TagFile onTagClicked={onTagClicked} isTagSelected={isTagSelected} />
                <Help isDanger style={{ textAlign: "center", fontSize: "1rem" }}>
                    {errors?.tags}
                </Help>
            </>
        </Column>
    );
}

function TagInfoText() {
    return (
        <Column isMarginLess>
            <PzText>
                <p>
                    I dag har vi en rekke sjangere, med sine krav til form og innhold. Hvordan vil du beskrive din
                    historie?
                </p>

                <p>Tagg den her. Du kan tagge flere.</p>
            </PzText>
        </Column>
    );
}
