import VippsPaymentButton from "assets/svg/VippsPaymentButton";
import { AppPath } from "common/AppPath";
import { PurchaseBookProps } from "pages/customer/bookdetails/purchase/PurchasePage";
import { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import Logger from "../../services/logging/LoggerService";

export type ProductPurchaseMetadata = PurchaseBookProps;

export interface VippsInitiatePaymentButtonProps {
    productPurchaseMetadata: ProductPurchaseMetadata;
    initiatePayment: () => Promise<void>;
    setError?: (hasError: boolean) => void;
}

export default function VippsInitiatePaymentButton({
    initiatePayment,
    productPurchaseMetadata,
    setError,
}: VippsInitiatePaymentButtonProps): JSX.Element {
    const { currentUser } = useAuth();
    const [redirect, setRedirect] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(true);
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        setRedirect(searchParams.get("code") != null);
    }, []);

    async function authenticateAndInitiatePayment() {
        // if (!currentUser) {
        //     setRedirect(true);
        //     return;
        // }
        if (!active) return;

        setActive(false);
        await initiatePayment()
            .catch((error) => {
                if (typeof setError !== "undefined") setError(true);
                Logger.error(error);
            })
            .finally(() => setActive(true));
    }

    function renderRedirect() {
        return (
            <Navigate
                to={{
                    pathname: AppPath.LOGIN,
                }}
                state={{
                    from: {
                        pathname: window.location.pathname,
                        search: window.location.search,
                    },
                    productPurchaseMetadata: productPurchaseMetadata,
                }}
            />
        );
    }

    function renderButton() {
        return (
            <div
                id="vipps-payment-button"
                className={`is-center ${active ? "" : "disabled"} `}
                onClick={() => authenticateAndInitiatePayment()}
            >
                <VippsPaymentButton />
            </div>
        );
    }

    return <>{redirect ? renderRedirect() : renderButton()}</>;
}
