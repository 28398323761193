import { AudioBookFile, Book, BookPrice, BookType } from "shared/types";

export function getOrderByFileName(filename: string) {
    const filenameNoType = filename?.split(".")[0];
    if (!filenameNoType?.match(/\d+/)) {
        return -1;
    }
    const filenameSplit = filenameNoType?.split("_");
    const index = filenameSplit.find((str) => str.match(/\d+/));
    return index ? parseInt(index, 10) : -1;
}

export function sortAudioBookByFilename(audioBookA: AudioBookFile, audioBookB: AudioBookFile) {
    return getOrderByFileName(audioBookA.fileName) > getOrderByFileName(audioBookB.fileName) ? 1 : -1;
}

export function isPriceDiscounted(price: BookPrice) {
    return (
        price &&
        price.originalPrice &&
        price.value &&
        price.originalPrice !== price.value &&
        price.originalPrice > price.value
    );
}

export function sortByFilename(filenameA: string, filenameB: string) {
    return getOrderByFileName(filenameA) > getOrderByFileName(filenameB) ? 1 : -1;
}

export function hasBookTypeAnyFiles(type: BookType, book: Book): boolean {
    if (book.source == "bokbasen") {
        const hasContentOfBookType = book.bookType == type && book.inventoryId != null;
        const hasRelatedBookOfType = book.relatedBooks?.find((b) => b.type == type && b.inventoryId) != null;
        return hasRelatedBookOfType || hasContentOfBookType;
    }
    if (
        (type === BookType.AUDIO && book.bookType != BookType.PODCAST) ||
        (type == BookType.PODCAST && book.bookType == BookType.PODCAST)
    ) {
        return book?.audiobook?.files != null && book.audiobook.files.length > 0;
    }
    return book?.ebook?.filePath != undefined;
}
