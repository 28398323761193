export function filterBySearchValue(searchPattern?: string, searchData?: string): any {
    if (!searchPattern || !searchData) {
        return true;
    }
    return searchData.toLowerCase().match(sanitizeSearchPattern(searchPattern).toLowerCase()) !== null;
}

function sanitizeSearchPattern(searchPattern: string): string {
    return searchPattern
        .replaceAll("?", "\\?")
        .replaceAll("+", "\\+")
        .replaceAll("(", "\\(")
        .replaceAll(")", "\\)")
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("\\", "\\\\");
}
