import "./AgreementContent.css";

import { ReactElement } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
interface AgreementContentProps {
    content: string;
}
export default function AgreementContent({ content }: AgreementContentProps): ReactElement {
    return (
        <ReactMarkdown
            className="agreement-content"
            // @ts-ignore
            rehypePlugins={[rehypeRaw]}
            children={content}
        />
    );
}
