import * as EmailValidator from "email-validator";
import { collection, doc, setDoc } from "firebase/firestore";
import { FirestoreCollection, MailchimSubscribeStates } from "shared";

import { Email } from "../../shared";
import { firestore } from "../firebase";
import { MailchimpSubscribe } from "./../../shared/types/EmailTypes";

export function validateEmailAddress(email) {
    return EmailValidator.validate(email);
}

export function validateKindleEmailAddress(email) {
    const parts = email.split("@");
    if (parts && parts[1] && parts[1] !== "kindle.com") return false;
    return EmailValidator.validate(email);
}

export async function sendEmail(email: Email) {
    validateEmail(email);
    return setDoc(doc(collection(firestore, FirestoreCollection.EMAIL)), email);
}

export async function subscribeToNewsletter(subscribeEmail: MailchimpSubscribe) {
    if (!validateEmailAddress(subscribeEmail.email)) throwEmailError("Must define a valid email address.");

    return setDoc(doc(collection(firestore, FirestoreCollection.NEWSLETTER_SUBSCRIBE)), {
        ...subscribeEmail,
        state: MailchimSubscribeStates.PENDING,
    });
}

function validateEmail(email: Email): Error | void {
    // Validate given email addresses
    if (email.to.length === 0) throwEmailError("Must define at least one email recipient.");
    if (!validateEmailAddress(email.from)) throwEmailError("Must define a valid from-address.");
    if (email.to.find((addr) => !validateEmailAddress(addr))) throwEmailError("All email addresses must be valid.");
    if (email.cc?.find((addr) => !validateEmailAddress(addr))) throwEmailError("All email addresses must be valid.");
    if (email.bcc?.find((addr) => !validateEmailAddress(addr))) throwEmailError("All email addresses must be valid.");

    // Validate subject
    if (!email.message.subject) throwEmailError("Subject can not be empty.");

    // Validate content
    if (!email.message.content) throwEmailError("Content can not be empty.");
}

function throwEmailError(desc: string) {
    throw new Error(desc);
}
