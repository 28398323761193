import "./PzPopover.css";

import { HTMLAttributes, PropsWithChildren } from "react";

export enum Align {
    right = "align-right",
    left = "align-left",
    center = "align-center",
}

export interface PzPopoverProps extends HTMLAttributes<HTMLDivElement> {
    showPopover: boolean;
    align?: Align;
}

/**
 * Floating box-component.
 */
export default function PzPopover(props: PropsWithChildren<PzPopoverProps>) {
    const { showPopover, children, align = Align.left, className = "", ...otherProps } = props;
    if (showPopover) {
        return (
            <div
                className={`box pz-popover ${align} ${className}`.trim()}
                {...otherProps}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {children}
            </div>
        );
    } else {
        return <></>;
    }
}
