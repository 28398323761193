import "./BookDetails.css";

import { faFacebookSquare, faSquareXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowLeft, faEnvelopeSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VippsLogo from "assets/svg/VippsLogo";
import { AppPath } from "common/AppPath";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { EmailShareButton, FacebookShareButton, FacebookShareCount, TwitterShareButton } from "react-share";
import { BookAuthor, BookReview, BookType, getIfNotempty, UrlUtils } from "shared";

import PzButton from "../../../components/form/PzButton";
import PzTag from "../../../components/PzTag";
import PzText from "../../../components/text/PzText";
import { PzTitle } from "../../../components/text/PzTitle";
import { bookToCoverpath, getCoverpath } from "../../../services/firestore/hooks/useBookApi";
import { CatalogueFilterKeys } from "../catalogue/CatalogueFilterContext";
import BookCoverImage from "../common/CoverImage";
import BlaIBokaButton from "./audiobook/BlaIBokaButton";
import { PlaySampleButton } from "./audiobook/PlaySampleButton";
import { useBookDetails } from "./BookDetailsContext";
import BookMetaDataSection from "./meta/BookMetaDataSection";
import PurchaseButton from "./purchase/PurchaseButton";

interface BookPurchaseDetailsProps {
    onClose?: () => void;
}

export default function BookDetails({ onClose }: BookPurchaseDetailsProps): ReactElement {
    return (
        <div id="book-details">
            <ExitButton onClick={onClose} />
            <a className="back-to-books" href={AppPath.CATALOGUE_ALL_BOOKS}>
                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "1rem" }} />
                Tilbake til bøker
            </a>
            <BookProductDetails />
        </div>
    );
}

function BookReviews() {
    const { book } = useBookDetails();

    function renderRating(review: BookReview) {
        if (!review.rating) return null;
        if (review.ratingType == "Terningkast") {
            return (
                <PzText pt2 p0 m0 containerProps={{ isFlexGrow0: true, p0: true, m0: true }}>
                    <div style={{ transform: "scale(2)", marginLeft: "5px" }}>
                        {/** @ts-ignore*/}
                        {review.rating == 4 ? "⚃" : review.rating == 5 ? "⚄" : "⚅"}
                    </div>
                </PzText>
            );
        }
        return (
            <PzText pt2 p0 m0 containerProps={{ isFlexGrow0: true, p0: true, m0: true }}>
                {review.ratingType} <i>{review.rating}</i> av <i>{review.ratingLimit}</i>
            </PzText>
        );
    }
    return (
        <>
            {book.bookReviews && (
                <div className="book-reviews" style={{ marginTop: "10px" }}>
                    <PzTitle isSize3 hasTextWeightBold>
                        {book.bookReviews.length == 1 ? "Anmeldelse" : "Anmeldelser"}
                    </PzTitle>
                    <div className="reviews-container">
                        {book.bookReviews.map((review) => (
                            <div className="book-review" style={{ marginTop: "10px" }}>
                                <PzText p0 m0 containerProps={{ p0: true, m0: true }}>
                                    <i>{review.content}</i>
                                </PzText>
                                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                                    {review.author && (
                                        <PzText pt2 p0 m0 containerProps={{ isFlexGrow0: true, p0: true, m0: true }}>
                                            {review.author}{" "}
                                            {review.source && review.source != "Publizm" ? " - " + review.source : ""}
                                        </PzText>
                                    )}
                                    {review.rating && renderRating(review)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {book.reviews && (
                <div className="book-other-details" style={{ marginTop: "10px" }}>
                    <PzTitle hasTextWeightBold isSize3>
                        Omtale fra redaksjonen
                    </PzTitle>
                    <PzText p0 m0 containerProps={{ p0: true, m0: true }}>
                        {book.reviews}
                    </PzText>
                </div>
            )}
        </>
    );
}

function ShareOnSocialmediaButtons({ book }) {
    function getCurrentUrl() {
        return window.location.href;
    }
    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "5px", marginTop: "20px" }}>
            <div>
                <FacebookShareButton url={getCurrentUrl()} title={"Del på facebook"} hashtag={"Publizm"}>
                    <FontAwesomeIcon icon={faFacebookSquare} size="2x" style={{ color: "#4267B2" }} />
                </FacebookShareButton>
                {/**@ts-ignore */}
                <FacebookShareCount url={getCurrentUrl()}>{(count: number) => <span>{count}</span>}</FacebookShareCount>
            </div>
            <TwitterShareButton url={getCurrentUrl()} title={book.title} hashtags={["Publizm"]}>
                {/* <TwitterIcon size={32} /> */}
                <FontAwesomeIcon icon={faSquareXTwitter} size="2x" />
            </TwitterShareButton>
            {/* <FacebookMessengerShareButton url={getCurrentUrl()} appId="550257460031081" title="Del på messenger">
                <FacebookMessengerIcon size={32} />
            </FacebookMessengerShareButton> */}
            <EmailShareButton url={getCurrentUrl()} subject={book.title} title="Del med e-post">
                <FontAwesomeIcon icon={faEnvelopeSquare} size="2x" />
            </EmailShareButton>
        </div>
    );
}
function BookProductDetails() {
    const navigate = useNavigate();
    const { book, hasAccess } = useBookDetails();
    const summary = book.summary ?? book.shortSummary;

    const renderPurchaseButtons = () => {
        const { hasEbookAccess, hasAudiobookAccess, hasBundleAccess } = hasAccess;
        return (
            <div>
                {book.bookType == BookType.PODCAST && (
                    <PurchaseButton hasAccess={book.bookType == BookType.PODCAST} type={BookType.PODCAST} />
                )}
                <PurchaseButton hasAccess={hasAudiobookAccess || hasBundleAccess} type={BookType.AUDIO} />
                <br />
                <PurchaseButton hasAccess={hasEbookAccess || hasBundleAccess} type={BookType.EBOOK} />
                {book.bookType != BookType.PODCAST && <VippsSection />}
            </div>
        );
    };


    const coverUrl = getCoverpath({
        bookId: book.id!,
        bookType: book.bookType,
        source: book.source,
        size: "medium",
        updatedDate: book.updatedDate.toDate(),
        coverPath: bookToCoverpath(book),
    });
    const subjects = book.allSubjects ?? book.subjects ?? book.tags ?? [];
    return (
        <div>
            <BookCoverImage coverPath={coverUrl} bookReviews={book.bookReviews} />
            <SampleButtons />
            {renderPurchaseButtons()}
            <TitleAndAuthor title={book.title} author={book?.author} />
            <p className="is-center" style={{ margin: "10px 0px", flexWrap: "wrap" }}>
                {subjects.map((subject) => (
                    <PzTag
                        size="medium"
                        onClick={() =>
                            navigate(`${AppPath.CATALOGUE_ALL_BOOKS}?${CatalogueFilterKeys.SUBJECT_KEY}=${subject}`)
                        }
                    >
                        {subject}
                    </PzTag>
                ))}
            </p>
            <p className="is-center mb-4 summary">{summary}</p>
            <BookReviews />
            <BookMetaDataSection book={book} />
            <ShareOnSocialmediaButtons book={book} />
        </div>
    );
}

function SampleButtons() {
    const { relatedBooks, bookId } = useBookDetails();
    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "0.2rem", justifyContent: "center", margin: "16px" }}>
            {relatedBooks.map((b) => {
                return <PlaySampleButton book={b} bookId={b.source == "bokbasen" ? b.bokbasenReferanse : bookId} />;
            })}
            <BlaIBokaButton book={relatedBooks.find((b) => b.source == "bokbasen" && b.bookType == BookType.EBOOK)} />
        </div>
    );
}
interface FavoritesButtonProps {
    onClick: () => void;
    selected?: boolean;
}

function FavoritesButton({ onClick, selected }: FavoritesButtonProps) {
    return (
        <PzButton
            isRounded
            isOutlined
            style={{ border: "2px solid black !important", width: "200px" }}
            onClick={onClick}
            isFlex
            isJustifyContentCenter
            m2
        >
            <div style={{ marginRight: "15px" }}>
                <FavouriteIcon selected={selected == true} />
            </div>
            {selected == true ? "Fjern fra bokhylle" : "Legg i min bokhylle"}
        </PzButton>
    );
}

function VippsSection() {
    return (
        <div className="vipps-logo">
            {VippsLogo({ width: "min(200px, 50%)" })}
            <p style={{ textAlign: "center" }}>Våre ebøker kan lese på nettbrett, Kindle og i din nettleser.</p>
        </div>
    );
}

export interface BookDescriptionTab {
    name: BookDetailsMenuLabel;
    content: ReactElement | string;
}

enum BookDetailsMenuLabel {
    SUMMARY = "Sammendrag",
    AUTHOR_INFO = "Om forfatteren",
}

function TitleAndAuthor({ title, author }: { title: string; author: BookAuthor }) {
    const authorName = author?.name;
    return (
        <div className="book-details-title-and-author-container">
            <h3 className="is-center">{title}</h3>
            <a
                className="ignore-hover is-center"
                href={UrlUtils.encodeAuthorForAuthor(author, AppPath.AUTHOR_PROFILE_DETAILS)}
            >
                {authorName}
            </a>
        </div>
    );
}

interface ExitButtonProps {
    onClick?: () => void;
}

function ExitButton({ onClick }: ExitButtonProps) {
    if (!onClick) {
        return null;
    }
    return (
        <div onClick={onClick}>
            <a style={{ color: "rgb(54, 54, 54)" }}>
                <FontAwesomeIcon icon={faTimes} style={{ fontSize: "1.5rem" }} />
            </a>
        </div>
    );
}

function FavouriteIcon({ selected }: { selected: boolean }) {
    return (
        <FontAwesomeIcon color={selected ? "#cfcf00" : "black"} size="lg" icon={[selected ? "fas" : "far", "heart"]} />
    );
}
