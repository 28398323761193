import * as admin from "firebase-admin";

import { BookSource, BookType } from "./BookTypes";
import { Currency } from "./CommonTypes";
import { FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";

export interface VippsPaymentBase extends PzFirestoreBase {
    initiationTime: admin.firestore.Timestamp;
    settledTime?: admin.firestore.Timestamp;
    /** @deprecated Use paymentState*/
    state?: PaymentStates;
    paymentState: VippsPaymentState;
    orderId: string;
    userId?: string;
    anonymous?: boolean;
    vippsUserSub?: string;
    productInfo?: VippsProductInfo;
    transactionInfo?: TransactionInfo;
    userState: VippsPaymentUserState;
    errorInfo?: TransactionErrorInfo;
}

export enum VippsPaymentUserState {
    CREATED = "CREATED",
    INITIATED_LOGGED_INN = "INITIATED_LOGGED_INN",
    INITIATED_ANONYMOUS = "INITIATED_ANONYMOUS",
    ERROR_CREATING_USER = "ERROR_CREATING_USER",
}

export type VippsPayment = VippsPaymentBase;

export const VippsPaymentLatestVersion = 2;

export enum CallbackStatuses {
    RESERVED = "RESERVED", // Payment reserved, by user accepting the payment in Vipps
    SALE = "SALE", // Payment captured with direct capture, by merchant (after RESERVED)
    RESERVE_FAILED = "RESERVE_FAILED", // Reserve failed because of insufficient funds, invalid card, or similar
    SALE_FAILED = "SALE_FAILED", // Direct failed because of insufficient funds, incalid card, or similar
    CANCELLED = "CANCELLED", // Payment canceled, by user in Vipps
    REJECTED = "REJECTED", // User did not act on payment (timeout, etc)
}
/**
 * State
 * State of the Payment.
 * One of:
 * - CREATED : User has not yet acted upon the payment
 * - ABORTED : User has aborted the payment before authorization
 * - EXPIRED: User did not act on the payment within the payment expiration time
 * - AUTHORIZED : User has approved the payment
 * - TERMINATED : Merchant has terminated the payment via the cancelPayment endpoint
 * - CAPTURE : Payment captured by merchant (after AUTHORIZED)
 */
export enum VippsPaymentState {
    CREATED = "CREATED",
    ABORTED = "ABORTED",
    EXPIRED = "EXPIRED",
    AUTHORIZED = "AUTHORIZED",
    CAPTURE = "CAPTURE",
    CAPTURED = "CAPTURED",
    TERMINATED = "TERMINATED",
    REFUNDED = "REFUNDED",
    REJECTED = "REJECTED",
}

export enum PaymentStates {
    INITIATE = "INITIATE", // Payment initiated, by merchant
    RESERVE = "RESERVE", // Payment reserved, by user accepting the payment in Vipps
    CAPTURE = "CAPTURE", // Payment captured, bu merchant (after RESERVE)
    REFUND = "REFUND", // Payment refunded, by merchant (after CAPTURE)
    CANCEL = "CANCEL", // Payment canceled, by user in Vipps
    SALE = "SALE", // Payment captured with direct capture, by merchant
    VOID = "VOID", // Payment canceled, by merchant
}

export interface TransactionInfo {
    amount: number; // Price of the item(s) bought
    amountInØre?: number; // Price of the item(s) bought
    transactionText: string;
    transactionId?: string;
    capturedAmount?: number; // Amount which has been captured from the customer
    refundedAmount?: number; // Amount which has been refunded to the customer
    userInfo?: VippsPaymentUserInfo; // Information from Vipps about the paying user
}

export interface GetPaymentDetailsResponse {
    orderId: string;
    transactionSummary: {
        capturedAmount: number;
        remainingAmountToCapture: number;
        refundedAmount: number;
        remainingAmountToRefund: number;
        bandIdentificationNumber: number;
    };
    transactionLogHistory: {
        amount: number;
        transactionText: string;
        transactionId: string;
        timeStamp: string;
        operation: PaymentStates;
        operationSuccess: boolean;
        requestId?: string;
    }[];
    sub?: string;
}

export interface VippsCallbackBody {
    merchantSerialNumber: number;
    orderId: string;
    transactionInfo: {
        amount: number;
        status: CallbackStatuses;
        timeStamp: string;
        transactionId: string;
    };
    errorInfo?: TransactionErrorInfo;
}

interface TransactionErrorInfo {
    errorMessage: string;
    errorGroup: string;
    errorCode: string;
}

export interface VippsPaymentUserInfo {
    givenName: string;
    familyName: string;
    name: string;
    email: string;
    phoneNumber: string;
    emailVerified: boolean;
}

export interface VippsLoginUserInfo {
    given_name: string;
    family_name: string;
    name: string;
    email: string;
    phone_number: string;
    birthdate?: string;
    sub: string; // Unique user id used when creating user from vipps login
    sid: string;
    adress?: VippsAdress;
    other_addresses?: VippsAdress[];
}
export interface LoginWithVippsRequest {
    code?: string | null;
    vippsOrderId?: string;
    redirectUrl?: string;
}
export interface LoginWithVippsResponse {
    redirectUrl?: string;
    userInfo?: VippsLoginUserInfo;
    customToken?: string;
    existingUser?: boolean;
}

export interface VippsAdress {
    address_type: string;
    country?: string;
    formatted?: string;
    postal_code?: string;
    region?: string;
    street_address?: string;
}

export interface VippsBookInfo {
    bookId: string;
    bookType: BookType;
    originalPrice?: number;
    price: number;
    currency: Currency;
    source: BookSource;
}

export interface InitiatePaymentResponse {
    orderId: string;
    userPerformPaymentUrl: string;
}

export interface PaymentDetailsWrapper {
    paymentDetails?: GetPaymentDetailsResponse;
    status?: number;
    statusText?: string;
}

export class VippsPaymentVersionMapper extends FirestoreVersionMapper<VippsPaymentBase, VippsPayment> {
    public latestVersion = VippsPaymentLatestVersion;

    public mapToLatest(vippsPayment: VippsPaymentBase): VippsPaymentBase {
        if (vippsPayment.version === this.latestVersion) {
            return vippsPayment;
        }

        switch (vippsPayment.version) {
            case undefined:
                return {
                    ...vippsPayment,
                    version: this.latestVersion,
                };
            default:
                throw new Error(`Mapper for vipps payment version ${vippsPayment["version"]} not implemented`);
        }
    }
}

export type VippsProductInfo = VippsBookInfo;
export type GeneralPaymentInfoUpdate = Pick<VippsPayment, "state" | "transactionInfo"> & Partial<VippsPayment>;

// --- Internal service api args

export interface InitiateBookPaymentRequest {
    bookId: string;
    bookType: BookType;
}

export interface RefundBookPaymentRequest {
    userId: string;
    transactionId: string;
}

export interface OrderRequest {
    orderId: string;
}
