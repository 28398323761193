import ReactPixel, {
    AddPaymentInfo,
    AddToCart,
    AddToWishlist,
    AdvancedMatching,
    CompleteRegistration,
    DynamicAdAddToCart,
    DynamicAdPurchase,
    DynamicAdViewContent,
    InitiateCheckout,
    Lead,
    Options,
    Purchase,
    Search,
    StartTrial,
    Subscribe,
    ViewContent,
} from "react-facebook-pixel";

type EventData =
    | AddPaymentInfo
    | DynamicAdAddToCart
    | AddToCart
    | AddToWishlist
    | CompleteRegistration
    | InitiateCheckout
    | Lead
    | DynamicAdPurchase
    | Purchase
    | Search
    | StartTrial
    | Subscribe
    | DynamicAdViewContent
    | ViewContent;

export default class PixelService {
    initialized: boolean;

    constructor() {
        this.initialized = false;
    }

    /**
     * Function that initializes Facebook Pixel tracking on current site.
     * @param pixelId ID which connects the application to the Facebook Pixel integration.
     * @param advancedMatching (optional) object containing parameters used to collect
     * more information about user being tracked.
     * @param options object determining whether default info such as button clicks will be
     * sent.
     */
    initializePageTracking(options?: Options, advancedMatching?: Partial<AdvancedMatching>): void {
        ReactPixel.init(
            import.meta.env.REACT_FACEBOOK_PIXELID ?? "",
            advancedMatching && this.prepareAdvancedMatching(advancedMatching),
            options
        );
        this.initialized = true;
        //this.revokeTrackingConsent(); // To be GDPR compliant: uncomment and grant consent upon user agreement.
    }

    /**
     * Grants the application the right to track the user. Pixel will automatically send
     * events to Facebook if Pixel is initialized after granting the consent.
     */
    grantTrackingConsent(): void {
        if (this.initialized) ReactPixel.grantConsent();
    }

    /**
     * Revokes the right to track the user. Pixel will automatically stop sending events
     * to Facebook after revoking the consent.
     */
    revokeTrackingConsent(): void {
        if (this.initialized) ReactPixel.revokeConsent();
    }

    /**
     * For information about Meta Pixel events, consult
     * https://www.facebook.com/business/help/402791146561655?id=1205376682832142.
     * @param eventName
     * @param parameters
     */
    trackEvent(eventName: string, parameters: EventData) {
        ReactPixel.track(eventName, parameters);
    }

    private prepareAdvancedMatching(advancedMatching: Partial<AdvancedMatching>): AdvancedMatching {
        const advancedMatchingStandardized: AdvancedMatching = {
            em: "",
            fn: "",
            ln: "",
            ph: "",
            ge: "",
            db: "",
            ct: "",
            st: "",
            zp: "",
            country: "",
            ...advancedMatching,
        };

        return advancedMatchingStandardized;
    }
}
