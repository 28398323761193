import "./TermsAndConditions.css";

import { Card, Column } from "components/trunx";
import { useAuth } from "contexts/AuthContext";
import { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";
import { AgreementService } from "services/firestore/AgreementService";
import { AgreementData, UserInfoData } from "services/firestore/types";
import LoggerService from "services/logging/LoggerService";
import { agreementDisplayValue } from "shared";

import PzButton from "../form/PzButton";
import PzFormInputField from "../form/PzFormInputField";
import PzText from "../text/PzText";
import AgreementContent from "./AgreementContent";

export default function TermsAndConditions({ children }: PropsWithChildren<unknown>): ReactElement {
    const { currentUser, userInfo, updateUserInfoData, activeRole } = useAuth();
    const [notApprovedAgreementData, setNotApprovedAgreementData] = useState<AgreementData[]>([]);
    const [viewedAgreementIndex, setViewedAgreementIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const viewedAgreementData = useMemo(getViewedAgreementData, [notApprovedAgreementData, viewedAgreementIndex]);

    useEffect(() => {
        setNotApprovedAgreementData([]);
        setViewedAgreementIndex(0);
        if (currentUser && userInfo) {
            setLoading(true);
            checkAgreements();
        }
    }, [userInfo]);

    useEffect(() => {
        const hasApprovedAllAgreements = !viewedAgreementData && viewedAgreementIndex > 0;
        if (hasApprovedAllAgreements) {
            updateUserInfoData();
        }
    }, [viewedAgreementData, viewedAgreementIndex]);

    function getViewedAgreementData() {
        return notApprovedAgreementData[viewedAgreementIndex];
    }

    function checkAgreements() {
        getLatestNotApprovedUserAgreements()
            .then(setNotApprovedAgreementData)
            .finally(() => setLoading(false));
    }

    function updateUserAgreement(userInfo: Partial<UserInfoData>, activeData: AgreementData): void {
        new AgreementService()
            .updateUserAcceptAgreement(userInfo, activeData)
            .then(() => {
                setViewedAgreementIndex((prevState) => ++prevState);
            })
            .catch((error) => LoggerService.error(error));
    }

    async function getLatestNotApprovedUserAgreements(): Promise<AgreementData[]> {
        if (userInfo) {
            return await new AgreementService().getUserNewAgreements(userInfo, activeRole);
        }
        return [];
    }

    // if (loading) {
    //     return <LoadingIndicator size="L" style={{ height: "20rem" }} />;
    // }

    if (!viewedAgreementData || !userInfo) {
        return <>{children}</>;
    }

    const viewedAgreementNumber = viewedAgreementIndex + 1;
    const numberOfAgreementsToApproval = notApprovedAgreementData.length;
    const agreementTypeDisplayValue = `${agreementDisplayValue(viewedAgreementData.agreement.agreementType)}`;
    const agreementNumberDisplayValue =
        numberOfAgreementsToApproval > 1 ? `- avtale ${viewedAgreementNumber} av ${numberOfAgreementsToApproval}` : "";
    return (
        <Column pt6 className="terms-and-conditions" isFlex isFlexDirectionColumn>
            <Column is8 isAlignContentCenter isCentered isAlignSelfCenter>
                <Column hasTextLeft pb5>
                    <PzText hasTextLeft containerProps={{ m0: true, p0: true }} hasTextWeightBold>
                        Du må godkjenne nye avtalevilkår for å fortsette å bruke kontoen din
                    </PzText>
                </Column>
                <Card isAlignContentCenter>
                    <Card.Header>
                        <PzText
                            hasTextWeightBold
                            containerProps={{ m0: true, p4: true }}
                        >{`${agreementTypeDisplayValue} ${agreementNumberDisplayValue}`}</PzText>
                    </Card.Header>
                    <Card.Content p6>
                        <AgreementContent content={viewedAgreementData?.agreement.agreementContent ?? ""} />
                    </Card.Content>
                    <Card.Footer
                        style={{
                            position: "sticky",
                            bottom: "0",
                            top: "0",
                            backgroundColor: "white",
                            zIndex: 100001,
                        }}
                    >
                        <AgreementApproveButton onApprove={() => updateUserAgreement(userInfo, viewedAgreementData)} />
                    </Card.Footer>
                </Card>
            </Column>
        </Column>
    );
}

interface AgreementApproveButtonProps {
    onApprove: () => void;
}
function AgreementApproveButton({ onApprove }: AgreementApproveButtonProps) {
    const [hasApprovedAgreement, setHasApprovedAgreement] = useState<boolean>(false);
    const toggleHasApprovedAgreements = () => setHasApprovedAgreement((prevState) => !prevState);

    useEffect(() => {
        setHasApprovedAgreement(false);
    }, [onApprove]);

    return (
        <Column>
            <PzFormInputField
                name="accept"
                type="checkbox"
                inputRef={null}
                checked={hasApprovedAgreement}
                onChange={toggleHasApprovedAgreements}
            >
                <label htmlFor="accept" className="checkbox ml-3" onClick={toggleHasApprovedAgreements}>
                    Jeg godtar vilkårene
                </label>
            </PzFormInputField>
            <PzButton isPrimary disabled={!hasApprovedAgreement} onClick={onApprove}>
                Lagre
            </PzButton>
        </Column>
    );
}
