import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Column, Columns } from "components/trunx";
import { useEffect, useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { bookLicenseeDisplayValue, BookLicenseeRole } from "shared";

import PzFormInputField from "../../../../../components/form/PzFormInputField";
import PzIconButton from "../../../../../components/form/PzIconButton";
import PzSelect2 from "../../../../../components/PzSelect2";
import PzText from "../../../../../components/text/PzText";
import { BookAdminFormValues, LicenceeFormValues } from "./BookAdminTypes";
import LicenseeSelector from "./LicenseeSelector";

interface LicenseeFormProps {
    name: "ebook" | "audiobook";
}
export default function LicenseeForm({ name }: LicenseeFormProps) {
    const { fields: licensees, append, remove } = useFieldArray({ name: `${name}.licensees` });
    const selectedLicensees: LicenceeFormValues[] | undefined = useWatch({ name: `${name}.licensees` });

    const isTotalPercentageOver100 = getTotalPercentage() > 100;

    function getTotalPercentage() {
        return (
            selectedLicensees
                // @ts-ignore
                ?.map((val) => (val?.percentage ? parseInt(val.percentage, 10) : 0))
                .reduce((prev, val) => prev + val, 0) ?? 0
        );
    }

    function validatePercentageInput(percentage: number) {
        if (percentage < 0) {
            return "Kan minimum være 0%";
        } else if (percentage > 100) {
            return "Kan maks være 100%";
        } else if (isTotalPercentageOver100) {
            return false;
        }
        return true;
    }

    function renderErrorMessage() {
        const hasAllePercentageInputsNumber = selectedLicensees?.every((licensee) => {
            return licensee.percentage !== undefined && licensee.percentage?.toString() !== "";
        });
        if (!selectedLicensees || selectedLicensees.length == 0 || !hasAllePercentageInputsNumber) {
            return null;
        }
        const errorMessage = isTotalPercentageOver100 && `Fordeling overstiger 100%`;

        return (
            <Column p0 m0 style={{ color: "red" }}>
                {errorMessage}
            </Column>
        );
    }
    function renderTotalPercentage() {
        if (!selectedLicensees || selectedLicensees.length == 0) {
            return null;
        }
        const totalPercentage = getTotalPercentage();
        return (
            <Column pl0 ml0>
                <PzText containerProps={{ ml0: true, pl0: true }}>
                    Sum fordeling: <strong>{totalPercentage}%</strong>
                </PzText>
                <PzText pt2 containerProps={{ ml0: true, pl0: true }}>
                    Publizm får: <strong>{100 - totalPercentage}%</strong>
                </PzText>
            </Column>
        );
    }
    return (
        <Columns isFlex isFlexDirectionColumn>
            <Column pl5 style={{ minHeight: "30px" }}>
                {renderErrorMessage()}
            </Column>
            {licensees.map((licensee, index) => {
                return (
                    <LicenseeRow
                        validatePercentageInput={validatePercentageInput}
                        licensee={licensee as LicenceeFormValues}
                        name={name}
                        index={index}
                    />
                );
            })}
            <Column pl5>{renderTotalPercentage()}</Column>

            <Column>
                <PzIconButton
                    onClick={(e) => {
                        e.preventDefault();
                        append({ percentage: 0, licenseeId: null, name: null, role: null });
                    }}
                    icon={faPlus}
                    iconPlacement="left"
                    isSmall
                >
                    Legg til
                </PzIconButton>
            </Column>
        </Columns>
    );
}

interface LicenseeRowProps {
    licensee: LicenceeFormValues;
    name: string;
    index: number;
    validatePercentageInput: (percentage: number) => any;
}
function LicenseeRow({ licensee, name, index, validatePercentageInput }: LicenseeRowProps) {
    const { errors } = useFormContext<BookAdminFormValues>();
    const { register } = useFormContext<BookAdminFormValues>();
    const isRemoved = useWatch<boolean>({ name: `${name}.licensees[${index}].removed` });
    if (isRemoved == true) {
        return null;
    }

    const errorPercentage = errors?.[name]?.licensees?.[index]?.percentage;
    return (
        <Column is5 key={licensee["id"]} isFlex isFlexDirectionRow>
            <LicenseeSelector name={`${name}.licensees`} index={index} />
            <LicenseeRoleSelector name={name} index={index} />
            <Column p0 m0 is4>
                <PzFormInputField
                    label="Prosent"
                    mt5
                    name={`${name}.licensees[${index}].percentage`}
                    defaultValue={licensee.percentage}
                    type="number"
                    formError={errorPercentage}
                    inputRef={register({
                        validate: validatePercentageInput,
                        required: "Du må oppgi fordeling",
                    })}
                />
            </Column>
            <LicenseeRemoveButton name={name} index={index} />
        </Column>
    );
}

interface LicenseeRemoveButtonProps {
    name: string;
    index: number;
}
function LicenseeRemoveButton({ name, index }: LicenseeRemoveButtonProps) {
    const { register, errors, setValue } = useFormContext<BookAdminFormValues>();

    useEffect(() => {
        register(`${name}.licensees[${index}].removed`);
    }, []);
    return (
        <Column pt5>
            <PzIconButton
                onClick={(e) => {
                    e.preventDefault();
                    setValue(`${name}.licensees[${index}].removed`, true);
                }}
                icon={faTimes}
                iconPlacement="left"
                isSmall
            >
                Fjern
            </PzIconButton>
        </Column>
    );
}

interface LicenseeRoleSelectorProps {
    name: string;
    index: number;
}

export interface LicenseeRoleOption {
    label: string;
    value: string;
}
export function LicenseeRoleSelector({ name, index }: LicenseeRoleSelectorProps) {
    const { register, setValue } = useFormContext<BookAdminFormValues>();
    const licenseeRole = useWatch({ name: `${name}.licensees[${index}].role` });
    const licenseeRoleOptions: LicenseeRoleOption[] = useMemo(
        () =>
            Object.keys(BookLicenseeRole)
                .filter((x) => isNaN(parseInt(x)))
                .map((key: string) => ({
                    value: BookLicenseeRole[BookLicenseeRole[key]],
                    label: bookLicenseeDisplayValue(BookLicenseeRole[key]),
                })),
        []
    );
    const initialValue = licenseeRoleOptions.find((licensee) => licensee.value === licenseeRole) ?? null;
    useEffect(() => {
        register(`${name}.licensees[${index}].role`);
        setValue(`${name}.licensees[${index}].role`, licenseeRole);
    }, []);

    function onChanged(selected: LicenseeRoleOption | null) {
        if (selected == null) {
            return;
        }
        setValue(`${name}.licensees[${index}].role`, selected.value);
    }
    return (
        <Column is8 pt0 mt0>
            <PzSelect2
                title="Rolle"
                onChanged={onChanged}
                options={licenseeRoleOptions}
                selectedOption={initialValue}
                searchable
            ></PzSelect2>
        </Column>
    );
}
