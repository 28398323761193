import { FirebaseUploadFile } from "../../services/storage/types";

export type FileData = ArrayBuffer | Blob | Uint8Array;

export class FileUtils {
    static async mapUploadedFile(uploadedFile: File): Promise<FirebaseUploadFile> {
        const data = await uploadedFile.arrayBuffer();
        return {
            data,
            name: uploadedFile.name,
            type: uploadedFile.type,
        };
    }

    static openFile(file: Partial<FirebaseUploadFile>): void {
        if (file.fileUrl) {
            this.openFileUrl(file.fileUrl);
        } else if (file.data && file.type) {
            this.openLocalFile(file.data, file.type);
        }
    }

    static dataToFileUrl(data: FileData, type: string): string {
        const fileBlob = new Blob([data], { type: type });
        return URL.createObjectURL(fileBlob);
    }

    private static openLocalFile(data: FileData, fileType: string, openInNewWindow = true): void {
        const fileURL = this.dataToFileUrl(data, fileType);
        this.openFileUrl(fileURL, openInNewWindow);
    }

    static async openFileUrl(fileURL?: string, openInNewWindow = true): Promise<void> {
        if (!fileURL) {
            return;
        }
        const result = window.open(fileURL, openInNewWindow ? "_blank" : "_self");
        if (result === null) {
            alert("Kunne ikke åpne fil. Vennligst gi tillatelse til å åpne popup vindu");
        }
    }
}
