import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "components/trunx";
import React, { ReactElement } from "react";

import { SocialMedia } from "../../common/SocialMedia";

interface SocialMediaButtonsProps {
    id: string;
}
export default function SocialMediaButtons({ id }: SocialMediaButtonsProps): ReactElement {
    function openNewTab(url: SocialMedia): void {
        const newWindow = window.open(url, "_blank", "noopener, noreferrer");
        newWindow ? (newWindow.opener = null) : null;
    }
    return (
        <Column hasTextBlack isFlex isFlexDirectionRow isJustifyContentFlexEnd>
            <Column isFlex isFlexDirectionRow isFlexGrow0>
                <Column isClickable isNarrowMobile>
                    <a
                        style={{ color: "#4a4a4a" }}
                        onClick={() => openNewTab(SocialMedia.INSTAGRAM)}
                        className="social-media-icon"
                    >
                        <FontAwesomeIcon size="2x" icon={faInstagram} />
                    </a>
                </Column>
                <Column isClickable isNarrowMobile>
                    <a
                        style={{ color: "#4a4a4a" }}
                        onClick={() => openNewTab(SocialMedia.FACEBOOK)}
                        className="social-media-icon"
                    >
                        <FontAwesomeIcon size="2x" icon={faFacebook} />
                    </a>
                </Column>
                <Column isClickable isNarrowMobile>
                    <a
                        style={{ color: "#4a4a4a" }}
                        onClick={() => openNewTab(SocialMedia.YOUTUBE)}
                        className="social-media-icon"
                    >
                        <FontAwesomeIcon size="2x" icon={faYoutube} />
                    </a>
                </Column>
            </Column>
        </Column>
    );
}
