import { Card } from "components/trunx";
import React, { PropsWithChildren, ReactElement, useState } from "react";
import { Collapse } from "react-collapse";

import CollapsibleExpandButton from "./dashboard/CollapsibleExpandButton";
import PzText from "./text/PzText";

interface PzCollapsibleProps {
    title: string;
}
export default function PzCollapsible({ title, children }: PropsWithChildren<PzCollapsibleProps>): ReactElement {
    const [isOpenCollapsible, setOpenCollasbile] = useState<boolean>(false);
    const toggleCollapsible = () => setOpenCollasbile((prevState) => !prevState);
    return (
        <Card>
            <Card.Header isFlex isFlexDirectionRow isJustifyContentSpaceBetween>
                <PzText isSize6 hasTextWeightBold containerProps={{ pl3: true, p0: true, m0: true }}>
                    {title}
                </PzText>
                <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />
            </Card.Header>
            <Card.Content p0>
                <Collapse isOpened={isOpenCollapsible}>{children}</Collapse>
            </Card.Content>
        </Card>
    );
}
