import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";

import { FirebaseUploadFile } from "../../services/storage/types";
import { FileUtils } from "../utils/FileUtils";
import FileTagView from "./FileTagView";
interface SingleFileUploadProps {
    onFileAdded: (file: FirebaseUploadFile) => void;
    onDeleteFile: (file: FirebaseUploadFile) => void;
    files: FirebaseUploadFile[];
}

export default function SingleFileUpload({ onFileAdded, files, onDeleteFile }: SingleFileUploadProps): ReactElement {
    async function onFileUploaded(ev: React.ChangeEvent<any>) {
        ev.preventDefault();
        const fileUploaded: File | undefined = ev.target.files[0];
        if (fileUploaded) {
            const file = await FileUtils.mapUploadedFile(fileUploaded);
            onFileAdded(file);
        }
    }

    return (
        <div className={`file has-name is-normal`} style={{ width: "max-content" }}>
            <label className="file-label">
                <input className="file-input" type="file" name="resume" onChange={onFileUploaded} />
                <span className="file-cta">
                    <FontAwesomeIcon icon={faUpload} />
                    <span className="file-label">Velg fil</span>
                </span>
            </label>
            <FileTagView files={files} onDeleteFile={onDeleteFile} />
        </div>
    );
}
