import { RenderWhen } from "components/auth/UserAuth";
import { Container, Title } from "components/trunx";
import { ReactElement } from "react";
import { UserRole, WrittenWork } from "shared";

import OpenFileLink from "../../components/dashboard/OpenFileLink";
import ShowUserInfoModalLink from "../../components/dashboard/ShowUserInfoModalLink";
import { formatDate } from "../../components/utils/DateUtils";

interface WrittenWorkInfoProps {
    writtenWork?: WrittenWork;
}

export default function WrittenWorkInfo({ writtenWork }: WrittenWorkInfoProps): ReactElement {
    return (
        <Container mb6 pb4>
            <Title pb2 is4 style={{ fontWeight: 600 }}>
                Informasjon om verket
            </Title>
            {renderTitleAndElement("Tittel", writtenWork?.title)}
            {renderTitleAndElement("Sjanger", writtenWork?.tags.join(", "))}
            {writtenWork?.uploadDate && renderTitleAndElement("Dato levert", formatDate(writtenWork.uploadDate) ?? "-")}
            <RenderWhen userIs={UserRole.ADMIN}>
                {renderTitleAndElement(
                    "Redaktør",
                    <ShowUserInfoModalLink userMetadata={writtenWork?.assignedToEditor} />
                )}
            </RenderWhen>
            <RenderWhen userIs={UserRole.ADMIN}>
                {renderTitleAndElement(
                    "Forfatter",
                    <ShowUserInfoModalLink
                        userMetadata={{
                            userId: writtenWork?.authorId ?? "-",
                            name: writtenWork?.authorName ?? "-",
                            email: "",
                        }}
                    />
                )}
            </RenderWhen>
            <OpenFileLink isButton isPrimary mt3 filePath={writtenWork?.filePath} label="Åpne verk" />
        </Container>
    );
}

function renderTitleAndElement(label?: string, element?: ReactElement | string) {
    if (!label || !element) {
        return <></>;
    }
    return (
        <div>
            <Title style={{ fontWeight: 600 }} is6 isInlineBlock mr2 mb3>
                {label}:
            </Title>
            {element}
        </div>
    );
}
