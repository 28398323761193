import { faBookOpen, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "components/trunx";
import { BookType } from "shared";

import PzText from "../../../components/text/PzText";
import { PzTitle } from "../../../components/text/PzTitle";
import { getCoverpath } from "../../../services/firestore/hooks/useBookApi";
import BookCoverImage from "../common/BookCoverImage";
import { BookVersionDetails } from "./BookDetailsContext";

export interface VersionCardProp {
    details: BookVersionDetails[];
    onClick?: () => void;
    showPrice?: boolean;
}

// eslint-disable-next-line
const NO_ACTION = () => { };

export default function VersionCard({ details, onClick = NO_ACTION, showPrice = true }: VersionCardProp) {
    function renderPlus() {
        return (
            <PzTitle isAlignItemsCenter pl2 pr2 isFlex isAlignContentCenter style={{ margin: "0 auto" }}>
                +
            </PzTitle>
        );
    }

    const detail = details[0];
    return (
        <Card
            onClick={onClick}
            isClickable={onClick != NO_ACTION}
            className="book"
            isFlex
            isFlexDirectionColumn
            style={{
                backgroundColor: "#f9f7f2",
                border: "1px solid #c8c8c8",
            }}
        >
            <Card.Content p0 isFlex isJustifyContentCenter isFlexDirectionColumn>
                <div className="is-center">
                    <BookCoverImage
                        coverPath={getCoverpath({
                            source: detail.source!,
                            bookId: detail.bookId!,
                            bookType: detail.format!,
                            size: "small",
                            updatedDate: detail.updatedDate,
                            coverPath: detail.coverPath,
                        })}
                        isSmall
                    />
                </div>
                <TitleAndAuthor title={details[0].title} authorName={details[0].author} />
            </Card.Content>
            <Card.Footer isFlex isJustifyContentCenter p0 style={{ borderTop: "0" }}>
                <div className="columns is-size-4 is-size-5-mobile is-flex">
                    {details.map((detail, index) => {
                        const icon = detail.format == BookType.AUDIO ? HeadphoneIcon() : BookIcon();
                        return (
                            <>
                                <div className="column">{icon}</div>
                                {details.length > 1 && index + 1 != details.length ? renderPlus() : ""}
                            </>
                        );
                    })}
                    {showPrice && details.length == 1 && details[0].price && (
                        <div className="column mr-6">{details[0].price},-</div>
                    )}
                </div>
            </Card.Footer>
        </Card>
    );
}

function HeadphoneIcon() {
    return <FontAwesomeIcon icon={faHeadphones} />;
}

function BookIcon() {
    return <FontAwesomeIcon icon={faBookOpen} />;
}

export function TitleAndAuthor({
    title,
    authorName,
    textColor,
}: {
    title?: string;
    authorName?: string;
    textColor?: string;
}) {
    return (
        <div className="container">
            <PzText
                pt1
                isSize6
                m0
                p0
                hasTextCentered
                style={{
                    color: `${textColor !== undefined ? textColor : "var(--persimon)"}`,
                    textDecoration: "underline",
                }}
            >
                {authorName}
            </PzText>
            <PzText isSize6 mb0 hasTextCentered style={{ ...(textColor && { color: textColor }) }}>
                {title}
            </PzText>
        </div>
    );
}
