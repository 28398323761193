import "./BookDetails.css";

import { Column, Columns, Label } from "components/trunx";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Configure, useInstantSearch, useSearchBox } from "react-instantsearch";
import { ReactElement } from "react";
import { Options } from "react-select";
import { BookAuthor, Language, languageDisplayValue } from "shared";

import AlgoliaSearchProvider from "../../../../../components/algolia/AlgoliaSearchProvider";
import PzFormInputField from "../../../../../components/form/PzFormInputField";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import PzSelect2 from "../../../../../components/PzSelect2";
import PzSelectDropdown from "../../../../../components/PzSelectDropdown";
import { PzTitle } from "../../../../../components/text/PzTitle";
import { IAuthorProfilesAlgolia } from "../../../../../services/algolia/types";
import useAuthorsApi from "../../../../../services/firestore/hooks/useAuthorsApi";
import { BookAdminFormValues, bookStateOptions, languageOptions } from "./BookAdminTypes";
import BookDescription from "./BookDescription";
import BookProducts from "./BookProducts";
export default function BookDetails() {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Columns isFlex isFlexDirectionColumn>
            <Column isFlex isFlexDirectionRow>
                <Column isFlex isFlexDirectionRow>
                    <BookInfo />
                    <Column p4 ml5 pb5 is5>
                        <Column ml0 isAlignSelfFlexEnd>
                            <PzSelectDropdown
                                name="state"
                                ml0
                                pl0
                                selectRef={register()}
                                title="Sett status"
                                options={bookStateOptions}
                            ></PzSelectDropdown>
                        </Column>
                        <BookAuthorProfiles />
                    </Column>
                </Column>
            </Column>
            <BookDescription />
            <BookProducts />
        </Columns>
    );
}

function BookPublisherDetails() {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Column pt5 p0 m0>
            <Column isFlex isFlexDirectionColumn p0 m0>
                <PzTitle isSize4 hasTextWeightBold>
                    Detaljer
                </PzTitle>
                <PzFormInputField
                    label="Utgivelsesår"
                    name="publishedYear"
                    type="text"
                    formError={errors.publishedYear}
                    inputRef={register()}
                />
                <PzFormInputField
                    label="Utgiver"
                    name="publisher"
                    type="text"
                    formError={errors.publisher}
                    inputRef={register()}
                />
                <PzFormInputField
                    label="Utgivelsessted"
                    name="publisherLocation"
                    type="text"
                    formError={errors.publisherLocation}
                    inputRef={register()}
                />
            </Column>
        </Column>
    );
}

export interface AuthorOptionType {
    label: ReactElement;
    authorId?: string;
    profileId?: string;
    value: IAuthorProfilesAlgolia;
}

function BookAuthorProfiles() {
    return (
        <AlgoliaSearchProvider index={import.meta.env.VITE_ALGOLIA_INDEX_AUTHOR_PROFILES!}>
            <React.Suspense fallback={<LoadingIndicator size="S" />}>
                <BookAuthorProfilesSelect />
            </React.Suspense>
        </AlgoliaSearchProvider>
    );
}
function BookAuthorProfilesSelect() {
    const { register, setValue } = useFormContext<BookAdminFormValues>();
    const { results, status } = useInstantSearch();
    const { refine: refineSearch } = useSearchBox();
    const [authorProfileOptions, setAuthorProfileOptions] = useState<Options<AuthorOptionType>>([]);
    const [initialValue, setInitialValue] = useState<AuthorOptionType | null>(null);
    const selectedAuthor = useWatch({ name: "author" }) as BookAuthor;
    const selectedAuthorProfile = useAuthorsApi().authorProfileById(
        selectedAuthor?.id,
        selectedAuthor?.profileId,
        true
    );
    const loadingSearchResults = useRef(false);
    const searchResults = useRef<Options<AuthorOptionType>>([]);
    useEffect(() => {
        register("author.id");
        register("author.profileId");
        register("author.name");
        register("author.profilePicturePath");
        register("author.description");
        if (selectedAuthorProfile) {
            setInitialValue({
                label: renderLabel(selectedAuthorProfile.language, selectedAuthorProfile.displayName),
                authorId: selectedAuthor.id,
                profileId: selectedAuthor.profileId,
                value: {
                    ...selectedAuthorProfile,
                    _highlightResult: {},
                    lastChanged: undefined,
                    objectID: selectedAuthorProfile.id!,
                },
            });
        }
    }, []);

    useEffect(() => {
        const options = loadAuthorProfileOptions();
        setAuthorProfileOptions(options);
        if (status == "idle") {
            searchResults.current = options;
            loadingSearchResults.current = false;
        }
    }, [results, status]);

    function renderLabel(language: Language, displayName: string) {
        return (
            <div>
                <Label style={{ fontSize: "10px" }} hasTextGrey>
                    {languageDisplayValue(language)}
                </Label>
                {displayName}{" "}
            </div>
        );
    }
    function loadAuthorProfileOptions() {
        return results.hits.map((profile: IAuthorProfilesAlgolia) => ({
            label: renderLabel(profile.language, profile.displayName),
            profileId: profile.objectID,
            authorId: profile.authorId,
            value: profile,
        }));
    }

    function onChanged(selected: AuthorOptionType | null) {
        if (selected == null) {
            return;
        }
        setValue("author.id", selected.authorId);
        setValue("author.profileId", selected.profileId);
        setValue("author.name", selected.value.displayName);
        setValue("author.profilePicturePath", selected.value.profilePicturePath);
        setValue("author.description", selected.value.description);
    }

    function loadOptions(searchInput: string) {
        refineSearch(searchInput);
        loadingSearchResults.current = true;
        searchResults.current = [];
        return waitForResultsLoaded();
    }
    function waitForResultsLoaded() {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (loadingSearchResults.current) {
                    resolve(waitForResultsLoaded());
                } else {
                    resolve(searchResults.current);
                }
            }, 100);
        });
    }
    return (
        <Column>
            <Configure hitsPerPage={100} />
            <PzSelect2
                title="Forfatter"
                controlStyle={{ padding: "4px 0" }}
                onChanged={onChanged}
                loadOptions={loadOptions}
                options={authorProfileOptions}
                selectedOption={initialValue}
                searchable
            ></PzSelect2>
        </Column>
    );
}

function BookInfo() {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Column is5>
            <PzFormInputField
                label="Tittel"
                name="title"
                type="text"
                formError={errors.title}
                inputRef={register({
                    required: "Du må oppgi tittel",
                })}
            />
            <PzSelectDropdown
                name="language"
                selectRef={register()}
                title="Språk"
                options={languageOptions}
            ></PzSelectDropdown>
            <BookPublisherDetails />
        </Column>
    );
}
