import { Container, Section } from "components/trunx";
import {
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateEmail as authUpdateEmail,
    updatePassword as authUpdatePassword,
    User,
    UserCredential,
    signInWithCustomToken,
} from "firebase/auth";
import { HttpsCallable, httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { SentrySetUserLoggedInAndRole } from "services/logging/SentryUtils";
import { CreateUserRequest, getActiveRole, LoginWithVippsRequest, LoginWithVippsResponse, UserInfo, UserRole } from "shared";

import LoadingIndicator from "../components/LoadingIndicator";
import { removeNullOrUndefinedKeys } from "../components/utils/ObjectUtils";
import { auth, functions, functionsEuropeNorth } from "../firebase";
import useUserApi, { USER_API_KEYS } from "../services/firestore/hooks/useUserApi";
import { useAuthState } from "./useAuthState";
import { AnalyticsApi } from "../services/firestore/queries/queriesCommon";

export type FirebaseUserCredential = UserCredential;
export type FirebaseUser = User;
export type FirebaseAuthError = {
    code: FirebaseAuthErrorCode;
};

export enum FirebaseAuthErrorCode {
    WRONG_PASSWORD = "auth/wrong-password",
    USER_NOT_FOUND = "auth/user-not-found",
    EMAIL_IN_USE = "already-in-use",
    EMAIL_EXISTS = "auth/email-already-exists",
}

export type doLoginType = (email: string, password: string) => Promise<FirebaseUserCredential>;
export type doRegisterType = (user: CreateUserRequest) => Promise<void>;
export type doLogoutType = () => Promise<void>;
export interface SignupAnonymoyslyProps {
    email: string;
    firstname?: string;
    surname?: string;
}
interface AuthContextProps {
    activeRole?: UserRole;
    currentUser: User | null;
    userInfo?: UserInfo;
    login: doLoginType;
    signup: doRegisterType;
    logout: doLogoutType;
    hasRole: (role: UserRole) => boolean;
    resetPassword: (email: string) => Promise<void>;
    updateEmail: (email: string) => Promise<FirebaseUserCredential | void>;
    updatePassword: (password: string) => Promise<void>;
    setActiveRole: (requestedUserRole: UserRole) => Promise<void>;
    updateUserInfoData: () => Promise<void>;
    userHasActiveRole: (role: UserRole) => boolean | null;
    loginWithOrderId: (orderId: string) => Promise<string | undefined>;
}

const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

export function useAuth(): AuthContextProps {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const { data: currentUser } = useAuthState();
    const createUserRef = useRef<HttpsCallable>(httpsCallable(functionsEuropeNorth, "createUser"));
    const info = useUserApi().getUser(currentUser?.uid);
    const { data: userInfo } = info;
    const [activeRole, setActiveRole] = useState<UserRole | undefined>(undefined);
    const queryClient = useQueryClient();
    const loginWithVippsFn = httpsCallable<LoginWithVippsRequest, LoginWithVippsResponse>(
        functionsEuropeNorth,
        "loginWithVipps"
    );
    useEffect(() => {
        if (currentUser == null && userInfo != null) {
            queryClient.invalidateQueries(USER_API_KEYS.getUser(userInfo.id));
        }
    }, [currentUser]);
    useEffect(() => {
        if (userInfo?.roles) {
            const userActiveRole = getActiveRoleFromSessionStorage() || getActiveRole(userInfo.roles);
            setActiveRole((prevstate) => prevstate ?? userActiveRole);
        } else {
            setActiveRole(undefined);
        }
    }, [userInfo]);

    function signupUser<T extends CreateUserRequest>(formData: T): Promise<void> {
        return createUserRef
            .current(removeNullOrUndefinedKeys(formData))
            .then(() => {
                login(formData.email, formData.password!);
            })
            .catch((err) => {
                throw err;
            });
    }

    async function loginWithOrderId(orderId: string): Promise<string | undefined> {
        const userInfoResponse = await loginWithVippsFn({ vippsOrderId: orderId });
        const data = userInfoResponse.data
        if (!data.customToken) return;
        const userDetails = await signInWithCustomToken(auth, data.customToken).then((data) => {
            AnalyticsApi.logEvent("user_logged_in_with_vipps");
            return data
        });
        console.log("Logged in with vipps", userDetails);
        return userDetails?.user?.uid;
    }
    function login(email: string, password: string): Promise<FirebaseUserCredential> {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logout(): Promise<void> {
        clearSessionStorage();
        return signOut(auth);
    }

    function resetPassword(email: string): Promise<void> {
        return sendPasswordResetEmail(auth, email);
    }

    function updateEmail(email: string): Promise<FirebaseUserCredential | void> {
        if (!currentUser) {
            return Promise.resolve();
        }
        return authUpdateEmail(currentUser, email);
    }

    function updatePassword(password: string): Promise<void> {
        if (!currentUser) {
            return Promise.resolve();
        }
        return authUpdatePassword(currentUser, password);
    }

    function getActiveRoleFromSessionStorage(): UserRole | undefined {
        if (currentUser) {
            const sessStor = sessionStorage.getItem(currentUser.uid);
            const activeRole = sessStor ? JSON.parse(sessStor).activeRole : undefined;
            return activeRole;
        }
        return undefined;
    }

    function clearSessionStorage() {
        sessionStorage.clear();
    }

    function updateActiveRole(requestedUserRole: UserRole): Promise<void> {
        if (currentUser && userInfo?.roles?.includes(requestedUserRole)) {
            setActiveRole(requestedUserRole);
            sessionStorage.setItem(currentUser.uid, JSON.stringify({ activeRole: requestedUserRole }));
            SentrySetUserLoggedInAndRole(userInfo, currentUser, requestedUserRole);
        }
        return Promise.resolve();
    }

    function userHasActiveRole(role: UserRole): boolean {
        return currentUser != null && activeRole === role;
    }

    function updateUserInfoData(): Promise<void> {
        if (currentUser) {
            queryClient.refetchQueries(USER_API_KEYS.getUser(currentUser?.uid));
        }
        return Promise.resolve();
    }

    function hasRole(role: UserRole) {
        return currentUser !== null && activeRole === role;
    }

    const value = {
        currentUser: currentUser ?? null,
        userInfo: userInfo ?? undefined,
        activeRole,
        login,
        hasRole,
        signup: signupUser,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        setActiveRole: updateActiveRole,
        updateUserInfoData,
        userHasActiveRole,
        loginWithOrderId,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function LoadingAuthIndicator() {
    return (
        <Section className="hero is-fullheight">
            <div className="hero-body">
                <Container>
                    <LoadingIndicator size="L" />
                </Container>
            </div>
        </Section>
    );
}
