import React from "react";

import { bulmaClassName } from "./classNames";
import { ErrorBoundaryProps } from "./ErrorBoundary";
import { HelpersProps, MainColorsProps } from "./modifiers";
import { renderElement } from "./renderElement";

export interface NotificationProps
    extends React.HTMLAttributes<HTMLDivElement>,
        ErrorBoundaryProps,
        HelpersProps,
        MainColorsProps {}

export class Notification extends React.Component<NotificationProps> {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = { hasError: false };

    render(): React.ReactNode {
        const { fallbackUI, ...props } = this.props;

        if (this.state.hasError) return fallbackUI;

        return renderElement("div", props, bulmaClassName.navbar);
    }
}
