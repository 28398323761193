import { Timestamp } from "firebase-admin/firestore";

import { BookLicenseeRole, BookType } from "./BookTypes";
import { FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";

export interface CreateRoyaltyReportRequest {
    userId: string;
    month: number;
    year: number;
}

export type RoyaltyReport = RoyaltyReportBase;
export const RoyaltyReportLatestVersion = 1;

type AllReportRoyalty = RoyaltyReportBase;

export class RoyaltyReportVersionMapper extends FirestoreVersionMapper<RoyaltyReport, AllReportRoyalty> {
    public latestVersion = RoyaltyReportLatestVersion;
    public mapToLatest(royaltyReport: AllReportRoyalty): RoyaltyReport {
        if (royaltyReport.version === RoyaltyReportLatestVersion) {
            return royaltyReport;
        }

        switch (royaltyReport.version) {
            case undefined:
                return {
                    ...royaltyReport,
                    version: RoyaltyLatestVersion,
                };
            default:
                throw new Error(`Mapper for royaltyReport version ${royaltyReport["version"]} not implemented`);
        }
    }
}
export interface RoyaltyReportBase extends PzFirestoreBase {
    licenseeId: string;
    userId: string;
    numberOfSales: number;
    totalRoyalty?: number;
    month: number;
    year: number;
}

export type Royalty = RoyaltyBase;
export const RoyaltyLatestVersion = 1;

type AllRoyalty = RoyaltyBase;

export class RoyaltyVersionMapper extends FirestoreVersionMapper<Royalty, AllRoyalty> {
    public latestVersion = RoyaltyLatestVersion;
    public mapToLatest(royalty: AllRoyalty): Royalty {
        if (royalty.version === RoyaltyLatestVersion) {
            return royalty;
        }

        switch (royalty.version) {
            case undefined:
                return {
                    ...royalty,
                    version: RoyaltyLatestVersion,
                };
            default:
                throw new Error(`Mapper for royalty version ${royalty["version"]} not implemented`);
        }
    }
}
export interface RoyaltyBase extends PzFirestoreBase {
    bookId: string;
    licenseeId: string;
    userId: string;
    title: string;
    bookType: BookType;
    role: BookLicenseeRole;
    roles: BookLicenseeRole[];
    royaltyPercentage?: number;
    numberOfSales?: number;
    sales: BookSaleInfo[];
    royalty?: number;
    month: number;
    year: number;
}

export interface BookSaleInfo {
    purchaseDate: Timestamp;
    price: number;
    royaltyPercentage: number;
}
