import "./PzFileUpload.css";

import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Column, ColumnProps, Columns, Label } from "components/trunx";
import React, { PropsWithChildren, ReactElement, useRef, useState } from "react";

import { FirebaseUploadFile } from "../../services/storage/types";
import PzIconButton from "../form/PzIconButton";
import PzText from "../text/PzText";
import { FileUtils } from "../utils/FileUtils";
interface FileUploadCommonProps extends ColumnProps {
    onFileAdded: (file: FirebaseUploadFile) => void;
    accept?: "image" | "audio" | "all";
    acceptExtensions?: string;
    isLink?: boolean;
    showFileInfo?: boolean;
    loading?: boolean;
    label?: string;
    file?: FirebaseUploadFile;
    dropZone?: boolean;
    multiple?: boolean;
}

interface FileUploadLinkProps extends FileUploadCommonProps {
    isLink?: true;
    showFileInfo?: never;
    label?: never;
    loading?: never;
    file?: never;
}

interface FileUploadButtonProps extends FileUploadCommonProps {
    isLink?: false | undefined;
    showFileInfo?: boolean;
    label?: string;
    file?: FirebaseUploadFile;
    loading?: boolean;
}
type FileUploadProps = FileUploadLinkProps | FileUploadButtonProps;

export default function PzFileUpload({
    isLink,
    showFileInfo,
    children,
    label,
    onFileAdded,
    acceptExtensions,
    accept,
    multiple,
    dropZone,
    file,
    loading,
    ...columnProps
}: PropsWithChildren<FileUploadProps>): ReactElement {
    const inputRef = useRef<HTMLInputElement>(null);
    async function onFileUploaded(ev: React.ChangeEvent<any>) {
        ev.preventDefault();
        const files = ev.target.files;
        for (let index = 0; index < files.length; ++index) {
            const dataTransferFile = files[index];
            if (dataTransferFile) {
                const file = await FileUtils.mapUploadedFile(dataTransferFile);
                onFileAdded(file);
            }
        }
        ev.target.value = null;
    }

    function acceptFileType() {
        if (acceptExtensions) {
            return acceptExtensions;
        }
        switch (accept) {
            case "image":
                return "image/*";
            case "audio":
                return "audio/*";
            default:
                return "";
        }
    }

    function renderButtonLink() {
        if (isLink) {
            return (
                <PzText className="file-label">
                    <a>{children}</a>
                </PzText>
            );
        }
        const button = (
            <div
                style={{ margin: 0, padding: 0 }}
                onClick={(e) => {
                    inputRef.current?.click();
                    e.preventDefault();
                }}
            >
                <PzIconButton buttonProps={{ isLoading: loading, isAlignContentFlexEnd: true }} icon={faUpload}>
                    {children}
                </PzIconButton>
            </div>
        );
        if (showFileInfo) {
            return (
                <Column>
                    <Label pb0 mb0>
                        {label}
                    </Label>
                    <div className="fileinfo-container">
                        <div className="fileinfo">{file?.name}</div>
                        {button}
                    </div>
                </Column>
            );
        }
        return <>{button}</>;
    }

    return (
        <Column {...columnProps} className="pzfileupload" isFlex isFlexDirectionRow>
            {dropZone && <DropZone onFileAdded={onFileAdded} />}
            <label className="file-label" style={{ width: "max-content", alignSelf: dropZone ? "flex-end" : "auto" }}>
                <input
                    ref={inputRef}
                    accept={acceptFileType()}
                    className="file-input"
                    type="file"
                    name="resume"
                    multiple={multiple}
                    onChange={onFileUploaded}
                />
                {renderButtonLink()}
            </label>
        </Column>
    );
}

interface DropZoneProps {
    onFileAdded: (file: FirebaseUploadFile) => void;
}
function DropZone({ onFileAdded }: DropZoneProps) {
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

    function dragover_handler(ev: React.DragEvent<any>) {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = "copy";
        setIsMouseOver(true);
    }

    function dragexit_handler(ev: React.DragEvent<any>) {
        setIsMouseOver(false);
    }

    async function drop_handler(ev: React.DragEvent<any>) {
        ev.preventDefault();
        // Get the id of the target and add the moved element to the target's DOM
        if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
            const files = ev.dataTransfer.files;
            for (let index = 0; index < files.length; ++index) {
                const dataTransferFile = files[index];
                const file = await FileUtils.mapUploadedFile(dataTransferFile);
                onFileAdded(file);
            }
        }
        setIsMouseOver(false);
        ev.dataTransfer.clearData();
    }

    return (
        <Columns
            ml1
            mr1
            mb0
            className={`dropzone ${isMouseOver ? "dropzone-hover" : ""}`}
            hasBackgroundLight
            onDrop={drop_handler}
            onDragLeave={dragexit_handler}
            onDragOver={dragover_handler}
        >
            <Column className="dropzone-content" isAlignSelfCenter>
                Last opp filer ved å dra hit
            </Column>
        </Columns>
    );
}
