import React from "react";
import { useFormContext } from "react-hook-form";

import * as emailService from "../../services/EmailService";
import PzFormInputField from "../form/PzFormInputField";

interface UpdateFormProps {
    validators?: Record<string, (value: string) => Promise<string | boolean>>;
    telephoneNumberRequired?: boolean;
    defaultTelephoneNumber?: string;
    registerKindleEmail?: boolean;
}

export default function UpdateUserInfoForm({
    validators,
    telephoneNumberRequired,
    registerKindleEmail = false,
    defaultTelephoneNumber,
}: UpdateFormProps) {
    const { register, errors, watch } = useFormContext();

    async function validateEmail(email: string) {
        if (!emailService.validateEmailAddress(email)) {
            return "Ugyldig epost-adresse";
        }

        if (validators?.["email"]) {
            return validators["email"](email);
        }

        return true;
    }

    return (
        <>
            <div className="columns">
                <div className="column">
                    <PzFormInputField
                        fieldProps={{
                            className: "is-flex-grow-1",
                        }}
                        label="Fornavn"
                        name="firstname"
                        placeholder="Fornavn"
                        autoComplete="given-name"
                        formError={errors.firstname}
                        inputRef={register({ required: "Du må oppgi fornavn" })}
                    />
                </div>
                <div className="column">
                    <PzFormInputField
                        label="Etternavn"
                        name="surname"
                        autoComplete="family-name"
                        placeholder="Etternavn"
                        formError={errors.surname}
                        inputRef={register({ required: "Du må oppgi etternavn" })}
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <PzFormInputField
                        label="Epost"
                        name="email"
                        autoComplete="email"
                        placeholder="Epost-adresse"
                        formError={errors.email}
                        inputRef={register({
                            required: "Du må oppgi epostadresse",
                            validate: validateEmail,
                        })}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    {/* <PzPhoneInput
                        defaultValue={defaultTelephoneNumber || ""}
                        label="Telefonnr"
                        name="telephoneNumber"
                        required={telephoneNumberRequired ?? false}
                    /> */}
                    <PzFormInputField
                        label="Telefonnr"
                        name="telephoneNumber"
                        autoComplete="tel-country-code"
                        placeholder="Telefonnr"
                        inputRef={register({
                            required: "Du må oppgi telefonnummer",
                        })}
                    />
                </div>
            </div>
            {registerKindleEmail && (
                <div className="columns">
                    <div className="column">
                        <PzFormInputField
                            label="Kindle-epost"
                            name="emailKindle"
                            autoComplete="email"
                            placeholder="Kindle-epost"
                            formError={errors.emailKindle}
                            inputRef={register({
                                validate: validateEmail,
                            })}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
