import { BookType } from "shared";

import { BookVersionDetails } from "../BookDetailsContext";
import VersionCard from "../VersionCard";

interface VersionDetailsProps {
    type: BookType;
    isBundleSelected: boolean;
    versionDetails: BookVersionDetails[];
}

export function VersionDetails({ isBundleSelected, type, versionDetails }: VersionDetailsProps) {
    const showVersionDetails = versionDetails
        .sort((detail) => (detail.format === type ? -1 : 1))
        .filter((detail) => isBundleSelected || detail.format === type);
    return (
        <div className="container">
            <div className="column is-two-quarters">
                <div className="columns is-justify-content-space-evenly is-flex-widescreen-only is-hidden-touch is-desktop">
                    <VersionCard details={showVersionDetails} showPrice={false} />
                </div>
                <div className="columns is-align-items-center is-flex-direction-column is-flex is-hidden-desktop">
                    <VersionCard details={showVersionDetails} showPrice={false} />
                </div>
            </div>
        </div>
    );
}
