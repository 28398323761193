import React from "react";

import { bulmaClassName } from "./classNames";
import { ErrorBoundaryProps } from "./ErrorBoundary";
import { HelpersProps } from "./modifiers";
import { renderElement } from "./renderElement";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement>, ErrorBoundaryProps, HelpersProps {
    isFluid?: boolean;
    isFullhd?: boolean;
    isWidescreen?: boolean;
}

export class Container extends React.Component<ContainerProps> {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = { hasError: false };

    render(): React.ReactNode {
        const { fallbackUI, isFluid, isFullhd, isWidescreen, ...props } = this.props;

        if (this.state.hasError) return fallbackUI;

        return renderElement("div", props, bulmaClassName.container, {
            isFluid,
            isFullhd,
            isWidescreen,
        });
    }
}
