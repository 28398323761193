import { Column, Columns } from "components/trunx";
import React from "react";
import { ReactElement } from "react";

import PzText from "../../components/text/PzText";
import { formatDate } from "../../components/utils/DateUtils";
import { ArticleItem } from "./types";

interface ArticleMetadataProps {
    item: ArticleItem;
}
export default function ArticleMetadata({ item }: ArticleMetadataProps): ReactElement {
    return (
        <Columns isFlex pt4 mt4 isFlexWrapWrap>
            <Column isFlex isFlexDirectionRow p0 m0>
                <PzText pl1 p0 pb4 m0 containerProps={{ p0: true, m0: true }} hasTextGrey>
                    Oppdatert {formatDate(new Date(item.published_at ?? item.created_at))}
                    {item.content.publisher && <span className="publisher"> - {item.content.publisher}</span>}
                </PzText>
            </Column>
        </Columns>
    );
}
