import "./TagFile.css";

import { Column } from "components/trunx";

import TagFileButton from "./TagFileButton";

export const tagsList = [
    "Bekjennelse",
    "Fantasy",
    "Komedie",
    "Oppvekst",
    "Cli-fi",
    "Tragedie",
    "Faksjon",
    "Eventyr",
    "Sci-fi",
    "Skitten realisme",
    "Krim",
    "Ungdom",
    "Romantikk",
    "Spenning",
    "Dystopi",
    "Technothriller",
    "Erotikk",
    "Psykologisk drama",
    "Utopi",
    "Grøsser",
    "Feelgood",
    "Lyrikk",
    "Mysterier",
    "Familie",
    "Brev",
    "Historie",
    "Politikk",
    "Dagbok",
    "Sakprosa",
    "Barn",
];

interface TagFileProps {
    onTagClicked: (tagName: string) => void;
    isTagSelected: (tagName: string) => boolean;
}
export default function TagFile({ onTagClicked, isTagSelected }: TagFileProps) {
    return (
        <Column isFlex isFlexDirectionRow isJustifyContentCenter isFlexWrapWrap>
            {tagsList.map((tag, index) => (
                <TagFileButton
                    key={tag + index}
                    tagName={tag}
                    onClick={() => onTagClicked(tag)}
                    selected={isTagSelected(tag)}
                />
            ))}
        </Column>
    );
}
