import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { BookGroupNames, BookPriceType, BookStatus, BookType, UrlUtils } from "shared";

import { AppPath } from "../../../../../common/AppPath";
import CollapsibleExpandButton from "../../../../../components/dashboard/CollapsibleExpandButton";
import PzButton from "../../../../../components/form/PzButton";
import PzAlgoliaTable from "../../../../../components/table/PzAlgoliaTable";
import PzTable from "../../../../../components/table/PzTable";
import { formatDate } from "../../../../../components/utils/DateUtils";
import AktueltCheckbox from "./AktueltCheckbox";
import { BookBokbasenCollapsible } from "./BookBokbasenCollapsible";
import BookEarningNumbers from "./BookEarningNumbers";
import BookSaleNumbers from "./BookSaleNumbers";
import BookStateDropdown from "./BookStateDropdown";
import { BookTableData } from "./BookTableTypes";
import ShowBookTypeIcons from "./ShowBookTypeIcons";
import PzInfoMessage from "../../../../../components/PzInfoMessage";

interface TableProps {
    bookDataList: BookTableData[];
    algolia?: boolean;
}
export default function BokbasenBookAdministrationTable({ bookDataList, algolia = true }: TableProps): ReactElement {
    const navigate = useNavigate();

    const columns: ColumnData[] = [
        {
            label: "Tittel",
            sortByProperty: !algolia ? "title" : undefined,
        },
        {
            label: "Først publisert",
            sortByProperty: !algolia ? "dateOfFirstPublication" : undefined,
        },
        {
            label: "Status",
            sortByProperty: !algolia ? "status" : undefined,
        },
        {
            label: "Aktuelt",
            customComparator: !algolia
                ? (a: BookTableData, b: BookTableData) => (a.groups?.includes(BookGroupNames.AKTUELT) ? 1 : -1)
                : undefined,
        },
        {
            label: "Forfatter",
            sortByProperty: !algolia ? "author" : undefined,
        },
        {
            label: "Inntjening",
        },
        {
            label: "Antall solgt",
        },
        {
            label: "Alle formater",
        },
        {
            label: "Åpne",
        },
        {
            label: "",
        },
    ];

    function createRow(bookData: BookTableData, toggleCollapsible?: () => void, isOpenCollapsible?: boolean): RowData {
        const previewPath = UrlUtils.encodeBookUrl(
            bookData.id,
            bookData.title,
            bookData.author ?? "",
            AppPath.BOOK_DETAILS,
            true
        );
        return {
            collapsibleComponent: <BookBokbasenCollapsible id={bookData.id} />,
            components: [
                {
                    content: bookData.title ?? ".",
                    width: "35%",
                },
                {
                    content: formatDate(bookData.dateOfFirstPublication) ?? "-",
                    width: "15%",
                },
                {
                    content: <BookStateDropdown status={bookData.status} bookId={bookData.id} />,
                    width: "30%",
                },
                {
                    content: <AktueltCheckbox groups={bookData.groups ?? []} bookId={bookData.id} />,
                    width: "5%",
                },
                {
                    content: bookData.author ?? "-",
                    width: "20%",
                },
                {
                    content: algolia ? <BookEarningNumbers bookId={bookData.id} /> : <div>--</div>,
                },
                {
                    content: algolia ? <BookSaleNumbers bookId={bookData.id} /> : <div>--</div>,
                },
                {
                    content: (
                        <ShowBookTypeIcons
                            showAudio={bookData.prices?.some((p) => p.type == BookPriceType.AUDIOBOOK)}
                            showEbook={bookData.prices?.some((p) => p.type == BookPriceType.EBOOK)}
                            showPodcast={bookData.bookType == BookType.PODCAST}
                            showKindle={false}
                        />
                    ),
                    width: "15%",
                },
                {
                    content: <PzButton onClick={() => navigate(previewPath)}>Åpne</PzButton>,
                    width: "15%",
                },
                {
                    content: <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />,
                    width: "1%",
                    className: "is-chevron-cell",
                },
            ],
        };
    }

    function getSearchData(book: BookTableData): string {
        const status = book.status === BookStatus.PUBLISHED ? "Publisert" : "Utkast";
        return (
            book.title +
            status +
            formatDate(book.dateOfFirstPublication) +
            book.subjects?.join("") +
            book.groups?.join(",") +
            book.price +
            book.author +
            book.collectionNames?.join(",")
        );
    }

    if (!algolia) {
        return (
            <PzTable
                createRow={createRow}
                searchData={getSearchData}
                data={bookDataList}
                columns={columns}
                rowKeyGenerator={(data) => data.id}
                defaultSortIndex={1}
            />
        );
    }
    return (
        <>
            <PzInfoMessage message="NB: Bokbasen bøker er utfaset og vil ikke vises på katalogen" />
            <PzAlgoliaTable
                createRow={createRow}
                searchData={getSearchData}
                data={bookDataList}
                columns={columns}
                rowKeyGenerator={(data) => data.id}
                defaultSortIndex={1}
            />
        </>
    );
}
