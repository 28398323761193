import "./BokbasenCollapsible.css";

import { faPenSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Column } from "components/trunx";
import { formatDate } from "components/utils/DateUtils";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BookBokbasen, BookPriceType, BookType, Currency, getNumberOrZero, mapToRelatedBook } from "shared";

import PzButton from "../../../../../components/form/PzButton";
import PzFormInputField from "../../../../../components/form/PzFormInputField";
import PzIconButton from "../../../../../components/form/PzIconButton";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import PzDataList from "../../../../../components/PzDataList";
import PzDatListItem from "../../../../../components/PzDataListItem";
import { PzTitle } from "../../../../../components/text/PzTitle";
import { BookService } from "../../../../../services/firestore/BookService";
import useBookApi from "../../../../../services/firestore/hooks/useBookApi";
import { getPriceValueByType } from "../../../../customer/bookdetails/BookDetailsContext";
import BookGroupButton from "./BookGroupButton";

interface BookCollapsibleProps {
    id: string;
}
export function BookBokbasenCollapsible(props: BookCollapsibleProps) {
    return (
        <React.Suspense fallback={<LoadingIndicator />}>
            <BookBokbasenCollapsibleContent {...props} />
        </React.Suspense>
    );
}

function BookBokbasenCollapsibleContent(props: BookCollapsibleProps) {
    const { id } = props;
    const { data, isLoading } = useBookApi().bookById(id, true);

    async function deleteBundlePrice() {
        const prices = (book.prices ?? []).filter((p) => p.type != BookPriceType.BUNDLE);
        new BookService().updateBookVariant({ prices }, id);
    }
    async function updatePrice(price: number, bookType: BookType, relatedBookId?: string): Promise<any> {
        const priceAsNumber = getNumberOrZero(price);
        if (book.bookType == bookType && book.bokbasenReferanse == relatedBookId) {
            const bookPrice = book.price;
            bookPrice.value = priceAsNumber;
            return new BookService().updateBookVariant({ price: bookPrice }, id);
        } else if (bookType == BookType.BUNDLE) {
            let prices = (book.prices ?? []).filter((p) => p.type != BookPriceType.BUNDLE);
            prices = [
                ...prices,
                { type: BookPriceType.BUNDLE, value: priceAsNumber, originalPrice: 0, currency: Currency.NOK },
            ];
            return new BookService().updateBookVariant({ prices }, id);
        } else {
            if (relatedBookId) {
                const relatedBook = await new BookService().getById(relatedBookId);
                const bookPrice = relatedBook!.book.price;
                bookPrice.value = priceAsNumber;
                return new BookService().updateBookVariant({ price: bookPrice }, relatedBookId);
            }
        }
    }

    if (isLoading || !data) {
        return null;
    }

    const book = data as BookBokbasen;
    const bundlePrice = getPriceValueByType(book, BookPriceType.BUNDLE);

    const allBooks = [...(book.relatedBooks ?? []), mapToRelatedBook(book, id)];
    return (
        <Card style={{ height: "auto" }} p0 m0>
            <div style={{ width: "max-content", display: "flex", justifyContent: "space-between", gap: "5rem" }}>
                <Column pl2 ml2>
                    <PzTitle isSize5>Metadata</PzTitle>
                    <Column
                        isFlexGrow0
                        m0
                        p0
                        isSize7
                        isJustifyContentSpaceBetween
                        style={{ maxWidth: "800px", width: "max-content", gap: "30px" }}
                    >
                        <PzDataList rows={6}>
                            <PzDatListItem label={"Bokbasen referanse"}>{book.bokbasenReferanse}</PzDatListItem>
                            <PzDatListItem label={"Opprettet dato"}>{formatDate(book.createdDate)}</PzDatListItem>
                            <PzDatListItem label={"Publisert første gang"}>{book.publishedYear}</PzDatListItem>
                            <PzDatListItem label={"Utgiver"}>{book.publisher}</PzDatListItem>
                            <PzDatListItem label={"Versjon"}>{book.version}</PzDatListItem>
                            <PzDatListItem label={"Serie"}>{book.collectionNames?.join(",")}</PzDatListItem>
                            <PzDatListItem label={"Grupper"}>
                                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                                    <div>{book.groups?.join(", ")}</div>
                                    <BookGroupButton book={book} bookId={id} />
                                </div>
                            </PzDatListItem>
                            <PzDatListItem label={"Sjanger"}>{book.allSubjects?.join(", ")}</PzDatListItem>
                        </PzDataList>
                    </Column>
                </Column>
                <Column>
                    <PzTitle pt0 mt0 isSize5 pb0 mb0>
                        Varianter
                    </PzTitle>
                    <Column
                        isFlexGrow0
                        pt2
                        m0
                        p0
                        isSize7
                        isJustifyContentSpaceBetween
                        isFlex
                        isFlexDirectionRow
                        style={{ width: "max-content", gap: "30px" }}
                    >
                        {allBooks.map((b) => (
                            <div>
                                <PzTitle mb1 pb1 isSize6 hasTextWeightBold>
                                    {b.type == BookType.AUDIO ? "Lydbok" : "Ebok"}
                                </PzTitle>
                                <PzDataList rows={2}>
                                    <PzDatListItem label={"ISBN"}>{b.isbn}</PzDatListItem>
                                    <PzDatListItem label={"Original pris"}>{b.originalPrice},-</PzDatListItem>
                                    <EditableText
                                        label="Pris"
                                        editableValue={b.price}
                                        editable
                                        onSave={(value) => {
                                            return updatePrice(value, b.type, b.bookId);
                                        }}
                                    >
                                        {b.price},-
                                    </EditableText>
                                </PzDataList>
                            </div>
                        ))}
                    </Column>
                    {allBooks.length > 1 && (
                        <div style={{ marginTop: "20px", fontSize: "0.75em" }}>
                            <PzDataList rows={1}>
                                <EditableText
                                    label="Bundle pris (NOK)"
                                    editableValue={bundlePrice}
                                    editable
                                    onSave={(value) => {
                                        if (value) {
                                            return updatePrice(value, BookType.BUNDLE);
                                        }
                                        return Promise.resolve();
                                    }}
                                >
                                    {bundlePrice ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "5px",
                                                height: 0,
                                                marginTop: "-1px",
                                            }}
                                        >
                                            {bundlePrice} ,-
                                            <PzIconButton
                                                buttonProps={{ style: { padding: "0", marginTop: "20px" } }}
                                                isSmall
                                                icon={faTrash}
                                                onClick={() => deleteBundlePrice()}
                                            />
                                        </div>
                                    ) : (
                                        "Ikke satt"
                                    )}
                                </EditableText>
                            </PzDataList>
                        </div>
                    )}
                </Column>
            </div>
        </Card>
    );
}

interface EditableTextProps<T> {
    editableValue: T;
    editable: boolean;
    label: string;
    onSave: (updatedValue: T) => Promise<void>;
}

interface EditableFormProps<T> {
    value?: T;
}
function EditableText<T>(props: PropsWithChildren<EditableTextProps<T>>) {
    const { label, onSave, children } = props;
    const [isEditMode, setIsEditMode] = useState(false);
    useEffect(() => {
        setIsEditMode(false);
    }, [props.editableValue]);
    if (!isEditMode) {
        return (
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <PzDatListItem label={label}>{children}</PzDatListItem>
                {props.editable && (
                    <PzIconButton
                        buttonProps={{ style: { padding: "0" } }}
                        isSmall
                        icon={faPenSquare}
                        onClick={() => setIsEditMode(true)}
                    />
                )}
            </div>
        );
    }

    return (
        <EditTextInput
            {...props}
            onSave={(value) => {
                return onSave(value);
            }}
        />
    );
}

function EditTextInput<T>({ label, editableValue, onSave: onChange }: EditableTextProps<T>) {
    const [isSaving, setIsSaving] = useState(false);
    const methods = useForm<EditableFormProps<T>>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: {
            //@ts-ignore
            value: editableValue,
        },
    });

    const { register, handleSubmit, errors, setValue } = methods;
    async function onSubmit(data: EditableFormProps<T>) {
        setIsSaving(true);
        // @ts-ignore
        setValue("value", data.value ?? undefined);
        onChange(data.value ?? editableValue);
    }
    return (
        <div>
            <FormProvider {...methods}>
                <form className="editable_text" onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                        <PzFormInputField
                            style={{ width: "130px" }}
                            label={label}
                            name="value"
                            type="number"
                            placeholder=""
                            isSmall
                            inputRef={register()}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                                alignSelf: "flex-end",
                                marginBottom: "13px",
                            }}
                        >
                            <PzButton isSmall isPrimary isLoading={isSaving}>
                                Lagre
                            </PzButton>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}
