import "./GroupedBooksSlideshow.css";

import React, { useRef } from "react";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import { authorDisplayValue, Book, getBookPrices } from "shared";

import { AppPath } from "../../common/AppPath";
import PzBookCard from "../../components/pz/PzBookCard";
import PzItemsSwiper from "../../components/swiper/PzItemsSwiper";
import { PzTitle } from "../../components/text/PzTitle";
import { getCoverpath } from "../../services/firestore/hooks/useBookApi";
import { BookQueries } from "../../services/firestore/queries/book/bookQueries";
import { CatalogueFilterKeys } from "../customer/catalogue/CatalogueFilterContext";
interface IGroupedBooksSlideshowProps {
    groupName: string;
    title: string;
    description?: string;
    showSeeAllButton?: boolean;
}

function LoadingFallback() {
    return (
        <ContentLoader
            width={"100%"}
            height={250}
            viewBox="0 0 100% 250"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="9" rx="1" ry="2" width="200" height="350" />
            <rect x="18%" y="9" rx="1" ry="2" width="200" height="350" />
            <rect x="36%" y="9" rx="1" ry="2" width="200" height="350" />
            <rect x="54%" y="9" rx="1" ry="2" width="200" height="350" />
            <rect x="72%" y="9" rx="1" ry="2" width="200" height="350" />
            <rect x="90%" y="9" rx="1" ry="2" width="200" height="350" />
        </ContentLoader>
    );
}

export default function GroupedBooksSlideshow(props: IGroupedBooksSlideshowProps) {
    const { title, groupName, description, showSeeAllButton = true } = props;
    const navigate = useNavigate();
    return (
        <div className="grouped_books">
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                <PzTitle className="sbtitle" pb0 mb0 isSize3>
                    {title}
                </PzTitle>
                {showSeeAllButton && (
                    <a
                        className="link"
                        onClick={() =>
                            navigate(`${AppPath.CATALOGUE_ALL_BOOKS}?${CatalogueFilterKeys.GROUP}=${groupName}`)
                        }
                    >
                        Se alle
                    </a>
                )}
            </div>
            {description && <p style={{ maxWidth: "1000px", padding: "5px 3px" }}>{description}</p>}
            <React.Suspense fallback={<LoadingFallback />}>
                <GroupedBooksSlideshowContainer {...props} />
            </React.Suspense>
        </div>
    );
}

function GroupedBooksSlideshowContainer({ groupName }: IGroupedBooksSlideshowProps) {
    const { data: books } = BookQueries.booksByGroupname(groupName);

    return (
        <PzItemsSwiper
            slides={books?.map((book) => (
                <GroupedBook book={book} key={book.id} />
            ))}
        />
    );
}

interface GroupedBookProps {
    book: Book;
}

function GroupedBook({ book }: GroupedBookProps) {
    const ref = useRef<HTMLDivElement>(null);
    function getBookVersionDetails() {
        return {
            coverPath: getCoverpath({
                bookId: book.id!,
                source: book.source,
                updatedDate: book.updatedDate?.toDate(),
                size: "small",
                bookType: book.bookType,
                coverPath: book.ebook?.coverPath ?? book?.coverPath,
            }),
            title: book.title,
            titleHighlighted: book.title,
            author: authorDisplayValue(book.author),
            authorHighlighted: authorDisplayValue(book.author),
            collectionNames: book.collectionNames,
            collectionNamesHighlighted: book.collectionNames?.join(","),
            prices: getBookPrices(book),
            bookReviews: book.bookReviews,
            id: book.id,
        };
    }

    const details = getBookVersionDetails();
    return (
        <div ref={ref} className="grouped-books-slideshow" style={{ paddingBottom: "20px" }}>
            <PzBookCard {...details} size="small" />
        </div>
    );
}
