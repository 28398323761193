import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { ColumnData } from "components/table/TableHead";
import { ReactElement, useEffect, useState } from "react";
import { bookLicenseeDisplayValue, bookTypeDisplayValue, Royalty } from "shared";

import { RoyaltyData } from "../../../services/firestore/types";
import RoyaltyFilter from "./RoyaltyFilter";

interface TableProps {
    data?: RoyaltyData[];
}

const SUM_ROYALTY_TITLE = "Sum royalties";
export default function RoyaltyTable({ data }: TableProps): ReactElement {
    const [tableData, setTableData] = useState<RoyaltyData[] | undefined>(data);

    useEffect(() => {
        if (!data) {
            setTableData(undefined);
            return;
        }
        const totalRoyalty = data.reduce((p, c) => p + (c.royalty.royalty ?? 0), 0);
        const updatedTableData = [...data];
        if (data.length > 0) {
            updatedTableData.push({
                royalty: {
                    title: SUM_ROYALTY_TITLE,
                    royalty: totalRoyalty,
                } as Royalty,
                documentId: "",
            } as RoyaltyData);
        }
        setTableData(updatedTableData);
    }, [data]);

    const columns: ColumnData[] = [
        {
            label: "Tittel",
            sortByProperty: "royalty.title",
        },
        {
            label: "Type",
            sortByProperty: "royalty.bookType",
        },
        {
            label: "Rettighet",
            sortByProperty: "royalty.role",
        },
        {
            label: "Antall salg",
            sortByProperty: "royalty.numberOfSales",
        },
        {
            label: "Royalties (NOK)",
            sortByProperty: "royalty.royalty",
        },
    ];

    function createRow(royaltyData: RoyaltyData): RowData {
        const royalty = royaltyData.royalty;

        const titleComponent =
            royalty.title === SUM_ROYALTY_TITLE ? (
                <div style={{ fontWeight: "bold" }}>{royalty.title}</div>
            ) : (
                royalty.title
            );
        return {
            components: [
                {
                    content: titleComponent,
                    width: "25%",
                },
                {
                    content: bookTypeDisplayValue(royalty.bookType) ?? "-",
                    width: "15%",
                },
                {
                    content: bookLicenseeDisplayValue(royalty.role) ?? "-",
                    width: "25%",
                },
                {
                    //TODO: Remove conditional chaining. Added as a quick-fix to phantom documents in emulator
                    content: royalty.numberOfSales?.toLocaleString() ?? "-",
                    width: "15%",
                },
                {
                    content: royalty.royalty ? royalty.royalty.toFixed(2) + ",-" : royalty.royalty == 0 ? "0,-" : "-",
                    width: "10%",
                },
            ],
        };
    }

    function getSearchData(data: RoyaltyData): string {
        const royalty = data.royalty;
        return royalty.title + bookTypeDisplayValue(royalty.bookType) + bookLicenseeDisplayValue(royalty.role);
    }

    return (
        <>
            <PzTable
                createRow={createRow}
                searchData={getSearchData}
                data={tableData}
                loading={tableData == undefined}
                columns={columns}
                rowKeyGenerator={(data) => data.documentId}
                tableAddOn={<RoyaltyFilter />}
                defaultSortIndex={1}
                enableExternalDataUpdate
                defaultPagination={10000}
            />
        </>
    );
}
