import { Column, Subtitle } from "components/trunx";
import React, { ReactElement, useState } from "react";

import PzButton from "../../../../components/form/PzButton";
import TagFile from "../../../../components/tagfile/TagFile";

interface InteresseProfilSelectProps {
    isUpdate?: boolean;
    onSubmit: (tags: string[]) => void;
    onCancel: () => void;
    initialTags: string[];
}

export function InteresseProfilSelect({
    isUpdate,
    onSubmit,
    onCancel,
    initialTags,
}: InteresseProfilSelectProps): ReactElement {
    const [tags, setTags] = useState<string[]>(initialTags ?? []);

    function isTagSelected(tagName: string) {
        return tags.includes(tagName);
    }

    function removeTag(tagName: string) {
        return [...tags].filter((tag) => tag !== tagName);
    }

    function addTag(tagName: string) {
        return [...tags, tagName];
    }

    function onTagClicked(tagName: string) {
        const updatedTags = isTagSelected(tagName) ? removeTag(tagName) : addTag(tagName);
        setTags(updatedTags);
    }

    function renderTitle() {
        if (isUpdate) {
            return <Subtitle>Oppdater interesseprofil</Subtitle>;
        }
        return (
            <>
                <Subtitle>Lag en interesseprofil</Subtitle>
                <Subtitle is6>
                    Lag en liste over sjangere du er interessert i, for å få bedre matcher på dine oppdrag.
                </Subtitle>
            </>
        );
    }

    return (
        <Column>
            {renderTitle()}
            <TagFile onTagClicked={onTagClicked} isTagSelected={isTagSelected} />
            <Column isFlex isJustifyContentSpaceEvenly isNarrow>
                <PzButton
                    onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                    }}
                >
                    Avbryt
                </PzButton>
                <PzButton
                    onClick={(e) => {
                        e.preventDefault();
                        onSubmit(tags);
                    }}
                    isPrimary
                >
                    Lagre
                </PzButton>
            </Column>
        </Column>
    );
}
