import "./FeaturedBooksSlideshow.css";

import PzBookCard from "components/pz/PzBookCard";
import { Column, Columns, Container } from "components/trunx";
import { PlaySampleButton } from "pages/customer/bookdetails/audiobook/PlaySampleButton";
import React from "react";
import { Book, BookPriceType, BookType } from "shared";

import LoadingIndicator from "../../components/LoadingIndicator";
import PzSlideshow from "../../components/swiper/PzSlideshow";
import PzText from "../../components/text/PzText";
import useBookApi from "../../services/firestore/hooks/useBookApi";
import { getPriceValueByType } from "../customer/bookdetails/BookDetailsContext";
import { TitleAndAuthor } from "../customer/bookdetails/VersionCard";

export default function FeaturedBooksSlideshow() {
    return (
        <React.Suspense fallback={<LoadingIndicator />}>
            <FeaturedBooksSlideshowContainer />
        </React.Suspense>
    );
}
function FeaturedBooksSlideshowContainer() {
    const booksFeatured = useBookApi().getAllFeatured();
    return (
        <PzSlideshow
            autoplayDelay={10000000}
            slides={booksFeatured.data?.map((elem) => (
                <FeaturedBook book={elem} key={elem.id} />
            ))}
        />
    );
}

interface FeaturedBookProps {
    book: Book;
}

function FeaturedBook({ book }: FeaturedBookProps) {
    function getBookVersionDetails() {
        return {
            coverPath: book.ebook?.coverPath ?? book?.coverPath,
            title: book?.title,
            id: book.id,
            author: book.author.name,
            format: BookType.EBOOK,
            price: getPriceValueByType(book, BookPriceType.EBOOK),
        };
    }

    function hasSample() {
        return book.audiobook?.sample?.filePath || book.bookFile?.sample?.filePath;
    }

    const details = [getBookVersionDetails()];
    const bookDetail = details[0];
    return (
        <Container className="featuredBook" style={{ height: "min-content" }}>
            <Columns p2 isFlexTablet isFlexDirectionRow>
                <Column isFlexGrow1 p0 m0 isFlex isFlexDirectionColumn isAlignItemsCenter>
                    <PzBookCard
                        title={bookDetail.title}
                        author={bookDetail.author}
                        id={bookDetail.id}
                        coverPath={bookDetail.coverPath}
                        bookReviews={book.bookReviews}
                        prices={[]}
                        size="medium"
                    />
                    <TitleAndAuthor title={details[0].title} authorName={details[0].author} />
                    <Column isHiddenTablet isFlex isJustifyContentCenter>
                        <PlaySampleButton book={book} bookId={book.id!} isFeatured />
                    </Column>
                </Column>
                <Column pl5 pr5 p0 m0 isFlexGrow2 isHiddenMobile>
                    <PzText isSize5 hasTextWeightBold mb0 pl0 ml0 pr0 mr0 mt3 style={{ flexGrow: 0 }}>
                        {book.title}
                    </PzText>
                    <PzText className="shortSummary">{book.summary ?? book.shortSummary}</PzText>

                    <div className="buttonContainer">
                        <PlaySampleButton book={book} bookId={book.id!} isFeatured />
                    </div>
                </Column>
            </Columns>
        </Container>
    );
}
