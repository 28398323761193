//@ts-nocheck
/* eslint-disable */
import "./PzSelect2.css";
import "./PzSelectDropdown.css";

import { Label } from "components/trunx";
import { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";
import Select, { OptionsType, components } from "react-select";
import AsyncSelect from 'react-select/async';
interface PzSelect2Props<T> {
    title?: string;
    // @ts-ignore
    options?: OptionsType<T>;
    loadOptions?: (searchValue) => Promise<OptionsType<T>>;
    onInputChange?: (searchValue: string) => void;
    onChanged?: (value: T | null) => void;
    selectedOption: T | null;
    controlStyle?: CSSProperties;
    menuStyle?: CSSProperties;
    style?: CSSProperties;
    placeholder?: string;
    searchable?: boolean;
    name?: string;
    selectRef?: (ref: HTMLInputElement) => void;
}

export default function PzSelect2<T>(props: PzSelect2Props<T>): ReactElement {
    const divRef = useRef<HTMLDivElement>(null);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            ...props.controlStyle,
        }),
        menu: (provided) => ({
            ...provided,
            ...props.menuStyle,
        }),
    };
    const [selectedOption, setSelectedOption] = useState<T | null>(props.selectedOption);

    useEffect(() => {
        const selectElement = divRef.current?.querySelector(`input[type="hidden"]`);
        if (selectElement && props.selectRef) {
            selectElement.setAttribute("name", props?.name ?? "");
            //@ts-ignore
            props.selectRef(selectElement);
        }
    }, []);
    useEffect(() => {
        setSelectedOption(props.selectedOption);
    }, [props.selectedOption]);

    function renderTitle() {
        if (props.title) {
            return <Label>{props.title}</Label>;
        }
        return null;
    }

    function onSelect(value: T | null) {
        props.onChanged?.(value);
    }

    function handleChange(option: T | null) {
        setSelectedOption(option);
        onSelect(option);
    }

    const CustomOption = ({ children, ...props }) => {
        const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
        const newProps = Object.assign(props, { innerProps: rest });
        return (
            //@ts-ignore
            <components.Option {...newProps} className={`select_option  ${newProps.isSelected ? "selected" : ""}`}>
                {children}
            </components.Option>
        );
    };
    const loadOptions = (
        inputValue: string,
        callback: (options: ColourOption[]) => void
    ) => {
        props.loadOptions(inputValue).then(callback);
    };
    function renderSelect() {
        if (props.loadOptions) {
            return <AsyncSelect
                name={props.name}
                styles={customStyles}
                value={selectedOption}
                onChange={handleChange}
                components={{ Option: CustomOption }}
                defaultOptions={props.options}
                defaultValue={props.selectedOption}
                loadOptions={loadOptions}
                loadingMessage={() => "Laster..."}
                escapeClearsValue={false}
                isSearchable={props.searchable === true}
                placeholder={props.placeholder ?? "Velg"}
            />
        }
        return <Select
            name={props.name}
            styles={customStyles}
            value={selectedOption}
            onChange={handleChange}
            onInputChange={props.onInputChange}
            components={{ Option: CustomOption }}
            options={props.options}
            isSearchable={props.searchable === true}
            placeholder={props.placeholder ?? "Velg"}
        ></Select>
    }
    return (
        <div ref={divRef} style={props.style}>
            {renderTitle()}
            {renderSelect()}
        </div>
    );
}
