import { SearchResponse } from "@algolia/client-search";
import DashboardHorizontalMenu from "components/dashboard/Menu/DashboardHorizontalMenu";
import { AdministrationDashboardMenu } from "components/dashboard/Menu/DashboardUserMenu";
import PageTemplate from "components/pagetemplate/PageTemplate";
import { Column, Columns, Container } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { Configure, useInstantSearch } from "react-instantsearch";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Book } from "shared";

import AlgoliaSearchProvider, { useSearchClient } from "../../../../../components/algolia/AlgoliaSearchProvider";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import PzSelectDropdown, { OptionProps } from "../../../../../components/PzSelectDropdown";
import { DashboardProvider, useDashboard } from "../../../../../contexts/DashboardContext";
import { isDevMode } from "../../../../../firebase";
import { BooksIndex, CollectionDetailsHighlighResult, HighlightResult } from "../../../../../services/algolia/types";
import { BookGroupQueries } from "../../../../../services/firestore/queries/book/bookgroupQueries";
import { BookQueries } from "../../../../../services/firestore/queries/book/bookQueries";
import BokbasenBookAdministrationTable from "./BokbasenBookAdministrationTable";
import BookAdministrationTable from "./BookAdministrationTable";
import { BookTableData } from "./BookTableTypes";

export default function AdminDashboard(): ReactElement {
    return (
        <>
            <PageTemplate marginLessTop>
                <AlgoliaSearchProvider index={import.meta.env.VITE_ALGOLIA_INDEX_BOOKS!}>
                    <DashboardProvider>
                        <Container>{isDevMode ? <DashboardContent /> : <DashboardContentAlgolia />}</Container>
                    </DashboardProvider>
                </AlgoliaSearchProvider>
            </PageTemplate>
        </>
    );
}

function DashboardContent() {
    const { selectedMenu } = useDashboard();
    const getSource = () => (selectedMenu == AdministrationDashboardMenu.BOKBASEN ? "bokbasen" : "publizm");
    const [tableDataList, setTableDataList] = useState<BookTableData[]>([]);
    const { data, isLoading } = BookQueries.allBooksBySource(getSource());

    function renderTable() {
        return <BookAdministrationTable key={"publizm"} bookDataList={tableDataList} algolia={false} />;
    }

    function renderTableBokbasen() {
        return <BokbasenBookAdministrationTable key={"bokbasen"} bookDataList={tableDataList} algolia={false} />;
    }

    function renderContent() {
        if (isLoading) {
            return <LoadingIndicator />;
        }

        switch (selectedMenu) {
            case AdministrationDashboardMenu.PUBLIZM:
                return renderTable();
            case AdministrationDashboardMenu.BOKBASEN:
                return renderTableBokbasen();
            default:
                return renderTable();
        }
    }

    function mapBookDataToTableData(books?: Book[]): BookTableData[] {
        return (
            books
                ?.filter((book) => book.mainBook || book.source == "publizm")
                .map((book) => ({
                    coverPath: book.coverPath,
                    title: book.title,
                    bookType: book.bookType,
                    author: book.author?.name,
                    updatedDate: book.updatedDate?.toDate(),
                    dateOfFirstPublication:
                        book.source == "bokbasen"
                            ? book.dateOfFirstPublication?.toMillis()
                            : book.updatedDate?.toMillis(),
                    titleHighlighted: book.title,
                    authorHighlighted: book.author?.name,
                    collectionNames: book.collectionNames,
                    collectionNamesHighlighted: book.collectionNames?.join(","),
                    status: book.status,
                    prices: book.prices,
                    subjects: book.allSubjects,
                    groups: book.groups,
                    id: book.id ?? "",
                })) ?? []
        );
    }

    useEffect(() => {
        setTableDataList(mapBookDataToTableData(data));
    }, [data]);

    return (
        <>
            <DashboardHorizontalMenu />

            <Columns pt5>
                <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                    {renderContent()}
                </Column>
            </Columns>
        </>
    );
}

type AlgoliaFilters = "groups" | "publisher";
function DashboardContentAlgolia() {
    const { selectedMenu } = useDashboard();
    const { results, status } = useInstantSearch();
    const [filters, setFilters] = useState<Map<AlgoliaFilters, string | undefined>>(new Map());

    const [tableDataList, setTableDataList] = useState<BookTableData[]>([]);

    const [loading, setIsLoading] = useState(true);

    const getSource = () => (selectedMenu == AdministrationDashboardMenu.BOKBASEN ? "bokbasen" : "publizm");

    useEffect(() => {
        if (status != "loading") {
            setIsLoading(false);
        }
    }, [status]);

    function renderTable() {
        return <BookAdministrationTable key={"publizm"} bookDataList={tableDataList} />;
    }

    function renderTableBokbasen() {
        return <BokbasenBookAdministrationTable key={"bokbasen"} bookDataList={tableDataList} />;
    }

    function renderContent() {
        if (loading) {
            return <LoadingIndicator />;
        }

        switch (selectedMenu) {
            case AdministrationDashboardMenu.PUBLIZM:
                return renderTable();
            case AdministrationDashboardMenu.BOKBASEN:
                return renderTableBokbasen();
            default:
                return renderTable();
        }
    }

    function mapBookDataToTableData(books: BooksIndex[]): BookTableData[] {
        return (
            books?.map((book) => ({
                coverPath: book["ebook.coverPath"] ?? book.coverPath,
                title: book["title"],
                author: book["author.name"],
                bookType: book.bookType,
                updatedDate: book.updatedDate,
                dateOfFirstPublication: book.dateOfFirstPublication,
                titleHighlighted: (book._highlightResult["title"] as HighlightResult)?.value,
                authorHighlighted: (book._highlightResult["author.name"] as HighlightResult)?.value,
                collectionNames: book.collectionNames,
                collectionNamesHighlighted: (
                    book._highlightResult["collectionDetails"] as CollectionDetailsHighlighResult[]
                )
                    ?.map((v) => v.title as HighlightResult)
                    ?.map((c) => c?.value)
                    ?.join(","),
                status: book.status,
                prices: book.prices,
                subjects: book.allSubjects,
                groups: book.groups,
                id: book.objectID,
            })) ?? []
        );
    }

    useEffect(() => {
        setTableDataList(mapBookDataToTableData(results.hits));
    }, [results]);

    function createAlgoliaFilterQuery() {
        const source = getSource();
        let filter = `source: ${source}`;

        if (source == "bokbasen") {
            filter += ` AND mainBook: true`;
        }
        const groupFilter = filters.get("groups");
        if (groupFilter) {
            filter += ` AND groups:"${groupFilter}"`;
        }

        const publisherFilter = filters.get("publisher");
        if (publisherFilter) {
            filter += ` AND publisher:"${publisherFilter}"`;
        }
        return filter;
    }

    function updateFilterValue(filter: AlgoliaFilters) {
        return (value: string | undefined) =>
            setFilters((prevFilters) => {
                const newFilters = new Map(prevFilters);
                return newFilters.set(filter, value);
            });
    }
    return (
        <>
            <Configure filters={createAlgoliaFilterQuery()} />
            <DashboardHorizontalMenu />
            <Columns pt5>
                <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                            marginBottom: "10px",
                            justifyContent: "flex-end",
                        }}
                    >
                        <PublisherFilterButton updatePublisherFilter={updateFilterValue("publisher")} />
                        <GroupFilterButton updateGroupFilter={updateFilterValue("groups")} />
                    </div>
                    {renderContent()}
                </Column>
            </Columns>
        </>
    );
}

interface IPublisherFilterButtonProps {
    updatePublisherFilter: (filter: string | undefined) => void;
}
function PublisherFilterButton({ updatePublisherFilter }: IPublisherFilterButtonProps) {
    const publisherQueryKey = "publisher";
    const searchClient = useSearchClient();
    const [queryParams, setQueryParams] = useSearchParams();

    const publishers = useQuery({
        queryKey: "algolia_publishers",
        queryFn: () => {
            return searchClient.searchClient
                .search([
                    {
                        indexName: import.meta.env.VITE_ALGOLIA_INDEX_BOOKS!,
                        params: {
                            facets: ["publisher"],
                            hitsPerPage: 0,
                        },
                    },
                ])
                .then((data) => {
                    if (
                        !data ||
                        !data.results ||
                        data.results.length == 0 ||
                        !(data.results[0] as SearchResponse).facets
                    )
                        return [{ label: "", value: "" }];
                    const publishers = (data.results[0] as SearchResponse).facets!["publisher"];
                    const publisherNames = Object.keys(publishers);
                    return [
                        { label: "Alle", value: "" },
                        ...publisherNames.map((name) => ({ label: `${name} (${publishers[name]})`, value: name })),
                    ];
                });
        },
    });

    useEffect(() => {
        updatePublisherFilter(queryParams.get(publisherQueryKey) ?? undefined);
    }, []);

    function _updatePublisherFilter(value: string | undefined) {
        updatePublisherFilter(value == "" ? undefined : value);
        setQueryParams((prev) => {
            if (value) {
                prev.set(publisherQueryKey, value);
            } else {
                prev.delete(publisherQueryKey);
            }
            return prev;
        });
    }

    return (
        <PzSelectDropdown
            isSmall
            title="Forlagfilter"
            options={publishers.data ?? []}
            defaultValue={queryParams.get(publisherQueryKey) ?? ""}
            onChanged={(value) => _updatePublisherFilter(value == "" ? undefined : value)}
        />
    );
}

interface IGroupFilterButtonProps {
    updateGroupFilter: (filter: string | undefined) => void;
}
function GroupFilterButton({ updateGroupFilter }: IGroupFilterButtonProps) {
    const groupQueryKey = "group";
    const groups = BookGroupQueries.allBookGroups();
    const [queryParams, setQueryParams] = useSearchParams();

    const ingenOption = { label: "Alle", value: "" };
    function groupsToOptions(): OptionProps[] {
        const groupOptions =
            groups.data?.map((group) => ({
                label: group.name,
                value: group.name,
            })) ?? [];
        return [ingenOption, ...groupOptions];
    }

    useEffect(() => {
        updateGroupFilter(queryParams.get(groupQueryKey) ?? undefined);
    }, []);

    function _updateGroupFilter(value: string | undefined) {
        updateGroupFilter(value == "" ? undefined : value);
        setQueryParams((prev) => {
            if (value) {
                prev.set(groupQueryKey, value);
            } else {
                prev.delete(groupQueryKey);
            }
            return prev;
        });
    }

    return (
        <PzSelectDropdown
            isSmall
            title="Gruppefilter"
            options={groupsToOptions()}
            onChanged={(value) => _updateGroupFilter(value == "" ? undefined : value)}
        />
    );
}
