import { findLabelValue, getStateColor, renderLabel, StateOptionType } from "components/dashboard/DashboardState";
import { ReactElement } from "react";
import { WrittenWorkData } from "services/firestore/types";
import { UserRole, WrittenWorkState } from "shared";

import { useAuth } from "../../contexts/AuthContext";

interface WrittenWorkStateProps {
    writtenWorkData: WrittenWorkData;
}

export default function DashboardWrittenWorkState({ writtenWorkData }: WrittenWorkStateProps): ReactElement {
    function findState(writtenWorkState: WrittenWorkState): StateOptionType {
        return {
            value: writtenWorkState,
            label: renderLabel(
                findLabelValue(writtenWorkState, writtenWorkData.writtenWorkEvent),
                getStateColor(writtenWorkState),
                false
            ),
        };
    }

    function getStateAuthor() {
        const state = writtenWorkData.writtenWork.state;
        // Hide two internal states from authors
        switch (state) {
            case WrittenWorkState.ASSIGNED:
                return findState(WrittenWorkState.DELIVERED);
            case WrittenWorkState.ASSESSED:
                return findState(WrittenWorkState.UNDER_ASSESSMENT);
            default:
                return findState(state);
        }
    }

    const stateToShow = useAuth().userHasActiveRole(UserRole.AUTHOR)
        ? getStateAuthor()
        : findState(writtenWorkData.writtenWork.state);

    return <div>{stateToShow?.label}</div>;
}
