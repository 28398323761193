import { Column, Columns, Help } from "components/trunx";
import UploadFile from "components/uploadfile/UploadFile";
import React, { ReactElement, useEffect } from "react";

import PzFormInputField from "../../components/form/PzFormInputField";
import { isEmpty } from "../../components/utils/ObjectUtils";
import { useAuth } from "../../contexts/AuthContext";
import { useUpload } from "../../contexts/UploadContext";

export default function UploadFilePage(): ReactElement {
    const { userInfo } = useAuth();
    const { writtenWorkFormData, updateWrittenWorkFormData, file, updateFile, errors, maxNumberOfFiles } = useUpload();

    useEffect(() => {
        if (isEmpty(writtenWorkFormData.authorName) && userInfo?.name) {
            updateWrittenWorkFormData({ authorName: userInfo.name });
        }
    }, [userInfo, writtenWorkFormData.authorName]);

    return (
        <Columns isJustifyContentCenter isFlexDirectionColumn>
            <Column is6 isAlignSelfCenter>
                <Column>
                    <PzFormInputField
                        fieldProps={{
                            className: "is-flex-grow-1",
                        }}
                        label="Tittel på verket"
                        name="title"
                        defaultValue={writtenWorkFormData.title}
                        formError={{ message: errors?.title }}
                        onBlur={(e) => updateWrittenWorkFormData({ title: e.target.value })}
                        inputRef={null}
                    />
                </Column>
                <Column mb3 pb3>
                    <PzFormInputField
                        fieldProps={{
                            className: "is-flex-grow-1",
                        }}
                        label="Forfatter/Pseudonym"
                        name="authorName"
                        placeholder={userInfo?.name}
                        defaultValue={writtenWorkFormData.authorName}
                        formError={{ message: errors?.authorName }}
                        onBlur={(e) => updateWrittenWorkFormData({ authorName: e.target.value ?? userInfo?.name })}
                        inputRef={null}
                    />
                </Column>
                <Column>
                    <UploadFile
                        type="SINGLE_FILE"
                        initialFileList={file ? [file] : []}
                        onFileListUpdated={(files) => updateFile(files[0])}
                        maxNumberOfFiles={maxNumberOfFiles}
                    />
                    <Help isDanger>{errors?.filePath}</Help>
                </Column>
            </Column>
        </Columns>
    );
}
