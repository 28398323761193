import * as admin from "firebase-admin";
import * as Mail from "nodemailer/lib/mailer";

export interface MailchimpSubscribe {
    email: string;
    state?: MailchimSubscribeStates;
    error?: string;
    tags?: MailchimpTags[];
}

export enum MailchimSubscribeStates {
    PENDING = "PENDING",
    ERROR = "ERROR",
}

export enum MailchimpTags {
    NEWSLETTER = "nyhetsmail",
    AUTHOR = "forfatter",
    EDITOR = "redaktør",
}

export interface Email {
    from: string;
    to: string[];
    cc?: string[];
    bcc?: string[];
    message: {
        subject: string;
        content: string;
        attachments?: Mail.Attachment[];
    };
}

export interface EmailDelivery {
    startTime: admin.firestore.FieldValue;
    state: EmailState;
    attempts: number;
    endTime?: admin.firestore.FieldValue;
    info?: {
        messageId: string;
        accepted: string[];
        rejected: string[];
        pending: string[];
        response: string;
    };
    error?: string;
}

export enum EmailState {
    PENDING = "PENDING", // obsolete
    PROCESSING = "PROCESSING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}
