import "./BookUpload.css";

import { Column, Container } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { BookType } from "shared";

import { AppPath } from "../../../../../common/AppPath";
import PzButton from "../../../../../components/form/PzButton";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import { PzTitle } from "../../../../../components/text/PzTitle";
import { BookService } from "../../../../../services/firestore/BookService";
import { BookAdminProvider } from "./BookAdminContext";
import { BookAdminMapper } from "./BookAdminMapper";
import { BookAdminFormValues } from "./BookAdminTypes";
import BookDetails from "./BookDetails";
interface BookAdminProps {
    bookId?: string;
}
export default function BookAdmin({ bookId }: BookAdminProps): ReactElement {
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [initialFormValues, setInitialFormValues] = useState<Partial<BookAdminFormValues>>();
    useEffect(() => {
        loadInitialData();
    }, []);

    async function loadInitialData() {
        if (bookId) {
            const bookData = await new BookService().getById(bookId, true);
            const book = bookData?.book;
            const bookFormValues: Partial<BookAdminFormValues> = BookAdminMapper.mapToFormValues(
                book,
                bookData?.economics
            );
            setInitialFormValues(bookFormValues);
        } else {
            setInitialFormValues({
                //@ts-ignore
                audiobook: { type: BookType.AUDIO },
            });
        }
        setLoadingData(false);
    }

    if (loadingData) {
        return <LoadingIndicator />;
    }
    return (
        <BookAdminProvider bookId={bookId}>
            <BookAdminForm initialFormValues={initialFormValues} bookId={bookId} />
        </BookAdminProvider>
    );
}

interface BookAdminFormProps {
    initialFormValues?: Partial<BookAdminFormValues>;
    bookId?: string;
}
function BookAdminForm({ initialFormValues, bookId }: BookAdminFormProps): ReactElement {
    const navigate = useNavigate();
    const methods = useForm<BookAdminFormValues>({
        mode: "onSubmit",
        reValidateMode: "onBlur",
        defaultValues: initialFormValues,
    });
    const [uploading, setUploading] = useState<boolean>(false);

    const { handleSubmit } = methods;
    const onSubmit = async (data: BookAdminFormValues) => {
        setUploading(true);
        try {
            const { book, files, economics } = BookAdminMapper.mapFormValuesToBook(data);
            await new BookService().addBook(book, files, economics, bookId);
        } catch (e) {
            console.error("Error uploading book", e);
            throw e;
        }

        setUploading(false);
        navigate(AppPath.CATALOG_ADMIN);
    };
    return (
        <Container>
            <PzTitle>Publiser tittel</PzTitle>
            <FormProvider {...methods}>
                <form className="bookupload">
                    <div>
                        <PzButton
                            isPulledRight
                            isPrimary
                            onClick={handleSubmit(onSubmit)}
                            disabled={uploading}
                            isLoading={uploading}
                        >
                            Oppdater
                        </PzButton>
                    </div>

                    <BookDetails />
                    <Column isJustifyContentCenter isFlex>
                        <PzButton isPrimary onClick={handleSubmit(onSubmit)} disabled={uploading} isLoading={uploading}>
                            Oppdater
                        </PzButton>
                    </Column>
                </form>
            </FormProvider>
        </Container>
    );
}
