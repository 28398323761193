import "../../common/Card.css";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import PzButton from "components/form/PzButton";
import PzIconButton from "components/form/PzIconButton";
import PzInputField from "components/form/PzInputField";
import LoadingIndicator from "components/LoadingIndicator";
import PzModal from "components/modal/PzModal";
import PzInfoMessage from "components/PzInfoMessage";
import { Help, Modal } from "components/trunx";
import { useState } from "react";
import { validateKindleEmailAddress } from "services/EmailService";
import { BookService } from "services/firestore/BookService";
import { UserService } from "services/firestore/UserService";

interface SendToKindleProps {
    bookId: string;
    userId?: string;
    emailKindle?: string;
}

enum SendToKindleState {
    INPUT,
    SUCCESS,
    ERROR,
}

export default function SendToKindle({ bookId, userId, emailKindle }: SendToKindleProps) {
    const [showKindleModal, setShowKindleModal] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [status, setStatus] = useState(SendToKindleState.INPUT);
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setShowKindleModal(false);
        setStatus(SendToKindleState.INPUT);
    };

    const sendToKindle = () => {
        if (!emailKindle && !validateKindleEmailAddress(email)) {
            setError("Du må oppgi en gyldig kindle epost-adresse");
            return;
        }
        setLoading(true);
        !emailKindle && saveKindleEmail(userId);
        sendEbookToKindle();
    };

    function sendEbookToKindle() {
        new BookService().sendEbookToKindle(emailKindle || email, bookId).then(
            () => {
                setStatus(SendToKindleState.SUCCESS);
                setLoading(false);
            },
            () => {
                setStatus(SendToKindleState.ERROR);
                setLoading(false);
            }
        );
    }

    function saveKindleEmail(userId?: string) {
        if (userId) {
            new UserService()
                // @ts-ignore
                .updateUserInfo({ emailKindle: email }, userId)
                .catch(console.log);
        }
    }
    function renderBody() {
        const info = (
            <>
                <p>
                    <strong>For å kunne sende ebok til din kindle</strong> må du ha godkjent at publizm kan sende deg
                    ebøker:
                </p>

                <a
                    href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GX9XLEVV8G4DB28H"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Gå inn på amazon kindle og følg instruksene
                </a>
                <p>
                    Legg til email adressen <strong>@publizm.no</strong> under "Approved Personal Document Email List"
                </p>
            </>
        );

        const sendToKindleForm = (
            <div>
                <div className="mb-4">{info}</div>
                {emailKindle ? (
                    <div className="mb-4">
                        <p>
                            Ebok sendes til <strong>{emailKindle}</strong>
                        </p>
                        <a href="/update-profile">Rediger kindle-epost</a>
                    </div>
                ) : (
                    <div className="mb-4">
                        <PzInputField
                            mb4
                            placeholder="johnsmith@kindle.com"
                            name="kindleEmail"
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={() => setError("")}
                        />
                    </div>
                )}

                {error && <Help isDanger>{error}</Help>}
                <PzButton isPrimary onClick={sendToKindle}>
                    Send til kindle
                </PzButton>
                <PzButton isNormal onClick={() => closeModal()}>
                    Avbryt
                </PzButton>
            </div>
        );

        const showStatus = (
            <div style={{ maxWidth: "500px" }}>
                <PzInfoMessage icon={"smile"} message={"Eboken er sendt til " + email} />
                <p>
                    Det kan ta opp til 10 minutter før eboken blir tilgjengelig på Kindle. Første gang du sender en
                    ebook til Kindle vil du motta en epost fra Amazon der du må bekrefte Publizm som godkjent avsender.
                </p>

                <PzButton isPrimary onClick={() => closeModal()}>
                    Lukk
                </PzButton>
            </div>
        );
        const showSendError = (
            <div>
                <PzInfoMessage icon={"frown"} message={"Sending av ebok til " + email + " feilet."} />
                <PzButton isPrimary onClick={() => closeModal()}>
                    Lukk
                </PzButton>
            </div>
        );

        switch (status) {
            case SendToKindleState.INPUT:
                return sendToKindleForm;
            case SendToKindleState.SUCCESS:
                return showStatus;
            case SendToKindleState.ERROR:
                return showSendError;
        }
    }

    return (
        <span>
            <PzIconButton iconPlacement="left" isSmall icon={faArrowRight} onClick={() => setShowKindleModal(true)}>
                <span style={{ textDecoration: "underline" }}>Send til Kindle</span>
            </PzIconButton>
            <PzModal isActive={showKindleModal} onClose={() => setShowKindleModal(false)}>
                <Modal.Card.Head>
                    <Modal.Card.Title> Send til Kindle</Modal.Card.Title>
                </Modal.Card.Head>
                <Modal.Card.Body>{loading ? <LoadingIndicator /> : renderBody()}</Modal.Card.Body>
            </PzModal>
        </span>
    );
}
