import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import IconText from "components/text/IconText";
import { Column } from "components/trunx";
import { ReactElement } from "react";
import { UserInfoMetadata, UserRole, userRoleDisplayValue } from "shared";

import DashboardUserIcon from "../dashboard/DashboardUserIcon";
import LoadingIndicator from "../LoadingIndicator";
import PzText from "../text/PzText";
import PzModal from "./PzModal";

export interface UserModalData extends UserInfoMetadata {
    role?: UserRole;
    tags?: string[];
}
interface EditorInfoModalProps {
    user?: UserModalData;
    onClose: () => void;
}

export default function UserInfoModal({ user, onClose }: EditorInfoModalProps): ReactElement | null {
    if (!user) {
        return null;
    }
    return (
        <PzModal isActive onClose={onClose}>
            <div style={{ width: "350px" }}>
                {!user ? (
                    <LoadingIndicator />
                ) : (
                    <Column>
                        <UserInfo user={user} />
                        <ContactInfo user={user} />
                        <Interests user={user} />
                    </Column>
                )}
            </div>
        </PzModal>
    );
}

interface UserProps {
    user: UserModalData;
}

function UserInfo({ user }: UserProps) {
    return (
        <Column isFlex isAlignItemsCenter isFlexDirectionColumn pb0>
            <DashboardUserIcon />
            <Column pb2>
                <strong>{user.name}</strong>
            </Column>
            <Column p0>{userRoleDisplayValue(user.role)}</Column>
        </Column>
    );
}

export function ContactInfo({ user }: UserProps): ReactElement {
    return (
        <Column>
            <PzText isSize6 p0 m0 containerProps={{ m0: true, p0: true }}>
                <strong>Kontakt</strong>
            </PzText>
            <IconText icon={faEnvelope}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
            </IconText>
            <IconText icon={faPhone}>{user.telephoneNumber}</IconText>
        </Column>
    );
}

function Interests({ user }: UserProps) {
    if (!user.tags) {
        return null;
    }
    return (
        <Column>
            <PzText isSize6 p0 m0 containerProps={{ m0: true, p0: true, pb2: true }}>
                <strong>Interesser</strong>
            </PzText>
            {user.tags?.join(", ")}
        </Column>
    );
}
