import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Card, Column, Columns } from "components/trunx";
import React, { ReactElement, useEffect, useState } from "react";

import DashboardUserIcon from "../../../components/dashboard/DashboardUserIcon";
import ShowUserInfoModalLink from "../../../components/dashboard/ShowUserInfoModalLink";
import PzIconButton from "../../../components/form/PzIconButton";
import PzText from "../../../components/text/PzText";
import { UserInfoData, WrittenWorkData } from "../../../services/firestore/types";
import SortEditor from "./SortEditor";

interface EditorListProps {
    editors: UserInfoData[];
    writtenWorkData: WrittenWorkData;
    onAssign: (writtenWorkData: WrittenWorkData, editor: UserInfoData) => void;
}

export default function EditorList(props: EditorListProps): ReactElement {
    const [editors, setEditors] = useState<UserInfoData[]>(props.editors);

    useEffect(() => {
        setEditors(props.editors.sort(SortEditor.sort(props.writtenWorkData)));
    }, [props.editors]);

    return (
        <Columns m0 p0 isFlex isFlexDirectionColumn style={{ maxHeight: "100%" }}>
            <Column m0 p0 style={{ height: "100%" }}>
                {editors.map((editor, index) => (
                    <EditorItem
                        key={editor.userInfo.companyId + String(index)}
                        editor={editor}
                        onAssign={() => {
                            props.onAssign(props.writtenWorkData, editor);
                        }}
                    />
                ))}
            </Column>
        </Columns>
    );
}

interface EditorItemProps {
    editor: UserInfoData;
    onAssign: () => void;
}

function EditorItem({ editor, onAssign }: EditorItemProps) {
    return (
        <>
            <Card style={{ height: "auto" }} p4 isFlexTablet isFlexDesktop isFlexWidescreen isFlexDirectionRow>
                <Column isFlex isFlexDirectionRow isJustifyContentSpaceAround>
                    <DashboardUserIcon />
                    <Column isAlignContentFlexStart>
                        <ShowUserInfoModalLink
                            userMetadata={{ userId: editor.id, name: editor.name, email: editor.userInfo.email }}
                        />
                        <PzText isSize7 p0 m0 containerProps={{ m0: true, p0: true }}>
                            {editor.userInfo.tags?.join(", ")}
                        </PzText>
                    </Column>
                </Column>
                <Column isFlex isJustifyContentFlexEnd>
                    <Column isFlex isFlexDirectionRow>
                        <PzText
                            isSize7
                            isAlignSelfCenter
                            containerProps={{ isFlex: true, isJustifyContentFlexEnd: true, m0: true, p2: true }}
                        >
                            <Column isFlex isFlexDirectionRow>
                                <div>
                                    <strong>{editor.userInfo.assignments ?? 0}</strong>
                                </div>
                            </Column>
                        </PzText>
                    </Column>
                    <PzIconButton onClick={onAssign} icon={faUserPlus} isSmall iconPlacement="top">
                        Angi
                    </PzIconButton>
                </Column>
            </Card>
        </>
    );
}
