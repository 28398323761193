import { captureMessage } from "@sentry/react";
import PzButton from "components/form/PzButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { BookType, UrlUtils } from "shared";

import { AppPath } from "../../../../common/AppPath";
import PzCheckbox from "../../../../components/form/PzCheckbox";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import TransactionErrorModal from "../../../../components/modal/TransactionErrorModal";
import PageTemplate from "../../../../components/pagetemplate/PageTemplate";
import VippsPaymentButtonWithLegal from "../../../../components/transaction/VippsPaymentButtonWithLegal";
import { AnalyticsApi } from "../../../../services/firestore/queries/queriesCommon";
import { UserService } from "../../../../services/firestore/UserService";
import VippsService from "../../../../services/VippsService";
import { BookVersionDetails } from "../BookDetailsContext";
import { VersionDetails } from "./VersionDetails";

export interface PurchaseBookProps {
    bookId: string;
    price: number;
    bundlePrice: number;
    type: BookType;
    allTypesPurchaseable: boolean;
    versionDetails: BookVersionDetails[];
}
export default function PurchaseBookPage() {
    return (
        <PageTemplate>
            <PurchaseBookPageContent />
        </PageTemplate>
    );
}
function PurchaseBookPageContent() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [purchaseBookDetails, setPurchaseBookDetails] = useState<PurchaseBookProps>();
    const [isStateEmpty, setIsStateEmpty] = useState<boolean>(false);

    const goBack = () => {
        // navigate(AppPath.PURCHASE_BOOK, { replace: true });
        AnalyticsApi.logEvent("purchase_cancelled_from_purchase_page");
        const details = purchaseBookDetails?.versionDetails ?? [];
        const title = details.length > 0 ? details[0].title : "";
        AnalyticsApi.logCheckoutCanceled(
            purchaseBookDetails?.price,
            purchaseBookDetails?.bookId,
            title,
            purchaseBookDetails?.type
        );
        if (purchaseBookDetails) {
            navigate(UrlUtils.encodeBookUrl(purchaseBookDetails?.bookId, "", "", AppPath.BOOK_DETAILS, true), {
                replace: true,
            });
        } else {
            navigate(-1);
        }
    };
    useEffect(() => {
        const curr_state = state ?? JSON.parse(window.localStorage.getItem("purchase_info") ?? "null");
        setIsStateEmpty(curr_state == undefined);
        setPurchaseBookDetails(curr_state);
        window.localStorage.setItem("purchase_info", JSON.stringify(curr_state));
    }, []);

    if (isStateEmpty) {
        return <div>Denne siden må åpnes gjennom katalogen</div>;
    }
    if (!purchaseBookDetails) {
        return <LoadingIndicator />;
    }

    return <PurchaseBookContainer onClose={goBack} {...purchaseBookDetails} />;
}
interface PurchaseModalProps extends PurchaseBookProps {
    onClose: () => void;
}
function PurchaseBookContainer({
    onClose,
    price,
    type,
    versionDetails,
    bookId,
    bundlePrice,
    allTypesPurchaseable,
}: PurchaseModalProps): JSX.Element {
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [bundleSelected, setBundleSelected] = useState<boolean>(false);
    const [hasPurchasedOtherType, setHasPurchasedOtherType] = useState<boolean>(false);

    useEffect(() => {
        new UserService.Transaction().getAllByBookId(bookId).then((res) => {
            setHasPurchasedOtherType(res.length > 0);
        });
    }, []);
    function vippsPaymentFn(): { initiateBookPayment: () => Promise<void> } {
        const localBookId = bookId;
        const localBookType = bundleSelected ? BookType.BUNDLE : type;
        const vippsService = new VippsService();

        function initiateBookPayment() {
            console.log("HERER")
            return vippsService.initiateBookPayment(localBookId, localBookType, price, versionDetails[0].title);
        }

        return {
            initiateBookPayment,
        };
    }

    function toggleBundleSelection() {
        setBundleSelected((val) => !val);
    }

    function renderErrorModal() {
        captureMessage("Payment failed");
        return <TransactionErrorModal onClose={() => setDisplayError(false)} />;
    }
    const bundleDiff = bundlePrice - price;
    const otherBookFormat = type == BookType.EBOOK ? "lydbok" : "e-bok";
    const showBundleOption = bundlePrice && allTypesPurchaseable && !hasPurchasedOtherType;

    return (
        <div className="is-center" style={{ flexDirection: "column" }}>
            <div className="is-center mb-4" style={{ display: "flex" }}>
                <h3>Bekreft kjøp</h3>
            </div>
            <div>
                <VersionDetails type={type} versionDetails={versionDetails} isBundleSelected={bundleSelected} />
                <div className="purchase-details">
                    {showBundleOption && (
                        <div className="is-center" onClick={toggleBundleSelection}>
                            <PzCheckbox name="" checked={bundleSelected} />
                            <p className="m-4">{`Få med ${otherBookFormat} for bare ${bundleDiff}kr`}</p>
                        </div>
                    )}
                    <p className="is-size-3 is-center">{bundleSelected ? bundlePrice : price},-</p>
                    <VippsPaymentButtonWithLegal
                        initiatePayment={vippsPaymentFn().initiateBookPayment}
                        productPurchaseMetadata={{
                            price,
                            bookId,
                            bundlePrice,
                            type,
                            allTypesPurchaseable,
                            versionDetails,
                        }}
                        setError={setDisplayError}
                    />
                    {displayError && renderErrorModal()}
                    <PzButton onClick={onClose} className="is-center mt-4">
                        Avbryt
                    </PzButton>
                </div>
            </div>
        </div>
    );
}
