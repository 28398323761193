import { Column, Container } from "components/trunx";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { PzTitle } from "../../../../../components/text/PzTitle";
import PzFileUpload from "../../../../../components/uploadfile/PzFileUpload";
import { FileUtils } from "../../../../../components/utils/FileUtils";
import { BookService } from "../../../../../services/firestore/BookService";
import { FirebaseUploadFile } from "../../../../../services/storage/types";
import { BookAdminFormValues } from "./BookAdminTypes";

interface BookCoverProps {
    formPath?: string;
}
export default function BookCover({ formPath = "cover" }: BookCoverProps) {
    const { register, setValue } = useFormContext<BookAdminFormValues>();
    const coverFile = useWatch<FirebaseUploadFile>({ name: formPath });
    const [coverPath, setCoverPath] = useState<string>();
    useEffect(() => {
        register({ name: formPath });
    }, []);

    useEffect(() => {
        loadCoverPath();
    }, [coverFile]);

    function addFile(file: FirebaseUploadFile) {
        setValue(formPath, file);
    }

    function loadCoverPath() {
        if (coverFile && coverFile.data) {
            const coverPath = FileUtils.dataToFileUrl(coverFile.data, coverFile.type);
            setCoverPath(coverPath);
            return;
        }
        if (coverFile && coverFile.filePath) {
            new BookService().loadCoverUrl(coverFile.filePath).then(setCoverPath);
        }
    }

    return (
        <Column pl0 ml0 isFlex isFlexDirectionRow>
            <Container isFlex isFlexDirectionColumn>
                <Column p0 m0>
                    <PzTitle isSize6 pb0 mb2 hasTextWeightBold>
                        Forsidebilde
                    </PzTitle>
                    <div className="coverimage">
                        {coverPath && (
                            <figure className="image">
                                <img src={coverPath} alt="Cover image" />
                            </figure>
                        )}
                    </div>
                    <PzFileUpload accept="image" p0 m0 pt4 onFileAdded={addFile}>
                        Last opp
                    </PzFileUpload>
                </Column>
            </Container>
        </Column>
    );
}
