import "./AuthorProfileCatalogueDisplayer.css";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "components/LoadingIndicator";
import { PzTitle } from "components/text/PzTitle";
import { Column, Content, Input } from "components/trunx";
import { debounce } from "components/utils/ObjectUtils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AuthorService } from "services/firestore/BookService";
import { Query } from "services/firestore/FirestoreService";
import { AuthorProfileData } from "services/firestore/types";

import useUrlQueries from "../../../../common/hooks/useQuery";
import { getQueryUrl, updateWindowQueryParameterByQueries } from "../../../../components/utils/UrlUtils";
import Paginate from "../Paginate";
import AuthorProfileCatalogueCard, { AuthorProfileCatalogueCardProps } from "./AuthorProfileCatalogueCard";
const CURSOR_AFTER_KEY = "cursor";
const SEARCH_PATTERN_KEY = "search";
const FILTER_KEY = "filter";

const getDefaultQuery: () => Query[] = () => [{ fieldPath: "roles", opStr: "array-contains", value: "ByAuthor" }];
export default function AuthorProfileCardDisplayer() {
    const [displayAuthorProfiles, setDisplayAuthorProfiles] = useState<AuthorProfileCatalogueCardProps[]>([]);
    const [fetchedAuthorProfiles, setFetchedAuthorProfiles] = useState<AuthorProfileData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const query = useUrlQueries();
    const hasLoadedInitialAuthors = useRef(false);

    const [searchPattern, setSearchPattern] = useState<string>(query.get(SEARCH_PATTERN_KEY) ?? "");
    const debouncedSetSearchPattern = useMemo(
        () =>
            debounce((e) => {
                setSearchPattern(e.target.value.toLowerCase().trim());
            }, 350),
        []
    );

    //Pagination related
    const [cursorFirst, setCursorFirst] = useState<any>(query.get(CURSOR_AFTER_KEY) ?? "");
    const [cursorLast, setCursorLast] = useState<any>();
    const [orderByField, setOrderByField] = useState<string>("displayName");
    const [pageSize, setPageSize] = useState<number>(12);

    useEffect(() => {
        hasLoadedInitialAuthors.current && loadAuthorProfilesStartAfter(orderByField, null, pageSize); //uses a string of 1 to "reset" cursorLast to start. setCursorLast does not work as it is async
    }, [pageSize, searchPattern]);

    useEffect(() => {
        loadAuthorProfilesStartAt(orderByField, cursorFirst, pageSize).finally(
            () => (hasLoadedInitialAuthors.current = true)
        );
    }, []);

    //Display fetched authorProfiles
    useEffect(() => {
        setDisplayAuthorProfiles(mapAuthorProfileBaseToAuthorProfileCard(fetchedAuthorProfiles));
    }, [fetchedAuthorProfiles]);

    useEffect(() => {
        updateWindowQueryParameterByQueries({
            [CURSOR_AFTER_KEY]: cursorFirst,
            [SEARCH_PATTERN_KEY]: searchPattern,
        });
    }, [cursorFirst, searchPattern]);

    async function loadAuthorProfilesStartAt(orderByField: string, startAt: any, limit: number) {
        setLoading(true);
        setDisplayAuthorProfiles([]);
        const searchFieldQueries: Query[] = getDefaultQuery();

        if (searchPattern !== "") {
            searchFieldQueries.push({
                fieldPath: "searchField",
                opStr: "array-contains-any",
                value: searchPattern.split(" "),
            });
        }
        await new AuthorService.AuthorProfile()
            .getPaginatedStartAt(orderByField, startAt, limit, searchFieldQueries)
            .then((profiles) => {
                if (profiles.length != 0) {
                    setFetchedAuthorProfiles(profiles);
                    setCursorFirst(profiles[0].authorProfile.displayName);
                    setCursorLast(profiles[profiles.length - 1].authorProfile.displayName);
                } else {
                    setCursorLast("1");
                    setCursorFirst("z");
                    setFetchedAuthorProfiles([]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }

    async function loadAuthorProfilesStartAfter(orderByField: string, startAfter: any, limit: number) {
        setLoading(true);
        setDisplayAuthorProfiles([]);
        const searchFieldQueries: Query[] = getDefaultQuery();

        if (searchPattern !== "") {
            searchFieldQueries.push({
                fieldPath: "searchField",
                opStr: "array-contains-any",
                value: searchPattern.split(" "),
            });
        }
        await new AuthorService.AuthorProfile()
            .getPaginatedStartAfter(orderByField, startAfter, limit, searchFieldQueries)
            .then((profiles) => {
                if (profiles.length != 0) {
                    setFetchedAuthorProfiles(profiles);
                    setCursorFirst(profiles[0].authorProfile.displayName);
                    setCursorLast(profiles[profiles.length - 1].authorProfile.displayName);
                } else {
                    setCursorLast("1");
                    setCursorFirst("z");
                    setFetchedAuthorProfiles([]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }

    async function loadAuthorProfilesEndBefore(orderByField: string, endBefore: any, limit: number) {
        setLoading(true);
        setDisplayAuthorProfiles([]);
        const searchFieldQueries: Query[] = getDefaultQuery();

        if (searchPattern !== "") {
            searchFieldQueries.push({
                fieldPath: "searchField",
                opStr: "array-contains-any",
                value: searchPattern.split(" "),
            });
        }
        await new AuthorService.AuthorProfile()
            .getPaginatedEndBefore(orderByField, endBefore, limit, searchFieldQueries)
            .then((profiles) => {
                if (profiles.length != 0) {
                    setFetchedAuthorProfiles(profiles);
                    setCursorFirst(profiles[0].authorProfile.displayName);
                    setCursorLast(profiles[profiles.length - 1].authorProfile.displayName);
                } else {
                    setCursorLast("1");
                    setCursorFirst("z");
                    setFetchedAuthorProfiles([]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }

    function getNextLink() {
        return getQueryUrl({
            [CURSOR_AFTER_KEY]: cursorLast,
            [SEARCH_PATTERN_KEY]: searchPattern,
        });
    }

    function getPrevLink() {
        return getQueryUrl({
            [CURSOR_AFTER_KEY]: cursorFirst,
            [SEARCH_PATTERN_KEY]: searchPattern,
        });
    }

    return (
        <div className="catalogue-card-displayer author-card-displayer">
            <Column
                isFlex
                isFlexDirectionColumn
                isAlignItemsFlexStart
                className="catalogue-filter"
                style={{ flexBasis: "auto" }}
            >
                <PzTitle ml4 mb0 p0>
                    Søk
                </PzTitle>
                <SearchFieldMemoized onChange={debouncedSetSearchPattern} />
            </Column>

            {loading && <LoadingIndicator size="XL" />}

            <div className="cards-container author-profile-cards-container">
                {displayAuthorProfiles.length === 0 && !loading ? <Content>Ingen treff på ditt søk</Content> : null}
                {displayAuthorProfiles.map((authorProfile) => {
                    return <AuthorProfileCatalogueCard {...authorProfile} />;
                })}
            </div>
            <Column isFlex isFlexDirectionColumn isAlignSelfFlexStart>
                <Paginate
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    nextLink={getNextLink()}
                    prevLink={getPrevLink()}
                    onPrev={() => loadAuthorProfilesEndBefore(orderByField, cursorFirst, pageSize)}
                    onNext={() => loadAuthorProfilesStartAfter(orderByField, cursorLast, pageSize)}
                />
            </Column>
        </div>
    );
}

//Utility method for mapping from AuthorProfileData to AuthorProfileCataloguecardProps
function mapAuthorProfileBaseToAuthorProfileCard(
    authorProfiles: AuthorProfileData[]
): AuthorProfileCatalogueCardProps[] {
    const convertedAuthorProfiles: AuthorProfileCatalogueCardProps[] = [];
    authorProfiles?.forEach((authorProfileData) => {
        convertedAuthorProfiles.push({
            profileImagePath: authorProfileData.authorProfile.profilePicturePath as string,
            displayName: authorProfileData.authorProfile.displayName,
            displayNameHighlighted: authorProfileData.authorProfile.displayName,
            profileId: authorProfileData.documentId,
            description: authorProfileData.authorProfile.description,
            descriptionHighlighted: authorProfileData.authorProfile.description,
            id: authorProfileData.authorId as string,
        });
    });
    return convertedAuthorProfiles;
}

interface SearchFieldProps {
    onChange: (e: any) => void;
}
const SearchFieldMemoized = React.memo(SearchField);

function SearchField({ onChange }: SearchFieldProps) {
    return (
        <div style={{ position: "relative", marginRight: "10px", margin: "8px", minWidth: "300px" }}>
            <Input ml2 isRounded onChange={(e) => onChange(e)} style={{ paddingLeft: "36px" }} />
            <FontAwesomeIcon icon={faSearch} style={{ position: "absolute", zIndex: 10, top: "12px", right: "15px" }} />
        </div>
    );
}
