import { httpsCallable } from "firebase/functions";
import { RoyaltyData } from "services/firestore/types";
import { FirestoreCollection, Royalty, RoyaltyReport, RoyaltyReportVersionMapper, RoyaltyVersionMapper } from "shared";

import { functions, functionsEuropeNorth } from "../../firebase";
import { FirestoreService } from "./FirestoreService";

export class RoyaltyService extends FirestoreService<RoyaltyReport> {
    generateNewRoyaltyReportFn = httpsCallable(functionsEuropeNorth, "createRoyaltiesReport");
    constructor() {
        super(FirestoreCollection.ROYALTY_REPORT, new RoyaltyReportVersionMapper());
    }

    async getRoyaltyiesForUserByMonthAndYear(
        month: number | string,
        year?: number,
        generateNewReport?: boolean,
        userId?: string
    ): Promise<RoyaltyData[]> {
        const monthAsNumber = typeof month == "string" ? parseInt(month) : month;
        const yearAsNumber = typeof year == "string" ? parseInt(year) : year;
        const _userId = userId ?? this.getUserId();
        if (generateNewReport) {
            await this.generateNewRoyaltyReportFn({
                month: monthAsNumber,
                year: yearAsNumber,
                userId: _userId,
            });
        }

        const result = await this.multiQuery([
            {
                fieldPath: "month",
                opStr: "==",
                value: monthAsNumber,
            },
            {
                fieldPath: "year",
                opStr: "==",
                value: yearAsNumber,
            },
            {
                fieldPath: "userId",
                opStr: "==",
                value: _userId,
            },
        ]);

        if (result.length == 0 && !generateNewReport) {
            return this.getRoyaltyiesForUserByMonthAndYear(month, year, true, userId);
        }

        if (result.length == 0) {
            return [];
        }
        return new RoyaltyService.Royalties(result[0].id).getRoyaltyiesForUserByMonthAndYear(
            monthAsNumber,
            yearAsNumber,
            _userId
        );
    }

    static Royalties = class extends FirestoreService<Royalty> {
        constructor(royaltyReportId?: string) {
            super(
                `${FirestoreCollection.ROYALTY_REPORT}/${royaltyReportId}/${FirestoreCollection.ROYALTIES}`,
                new RoyaltyVersionMapper()
            );
        }

        async getRoyaltyiesForUserByMonthAndYear(
            month: number,
            year?: number,
            userId?: string
        ): Promise<RoyaltyData[]> {
            const result = await this.multiQuery([
                {
                    fieldPath: "month",
                    opStr: "==",
                    value: month,
                },
                {
                    fieldPath: "year",
                    opStr: "==",
                    value: year,
                },
                {
                    fieldPath: "userId",
                    opStr: "==",
                    value: userId ?? this.getUserId(),
                },
            ]);

            return result.map((royalty) => ({
                documentId: royalty.id,
                royalty: royalty.data as Royalty,
            }));
        }
    };
}
