import { Timestamp } from "firebase-admin/firestore";

import { FirestoreVersionMapper, PzFirestoreBase } from "./PzFirestoreBaseTypes";
import { UserInfoMetadata } from "./UserTypes";

export enum WrittenWorkState {
    DRAFT = "draft",
    DELIVERED = "delivered",
    ASSIGNED = "assigned",
    UNDER_ASSESSMENT = "under_assessment",
    ASSESSED = "assessed",
    ACCEPTED = "accepted",
    REFUSED = "refused",
}

const writtenWorkStateDisplayValue = new Map<WrittenWorkState, string>([
    [WrittenWorkState.DRAFT, "Ikke levert"],
    [WrittenWorkState.DELIVERED, "Levert"],
    [WrittenWorkState.ASSIGNED, "Tildelt"],
    [WrittenWorkState.UNDER_ASSESSMENT, "Vurderes"],
    [WrittenWorkState.ASSESSED, "Vurdert"],
    [WrittenWorkState.ACCEPTED, "Akseptert"],
    [WrittenWorkState.REFUSED, "Ikke antatt"],
]);

export const getWrittenWorkEventDisplayValue = (writtenWorkEventId?: string): string => {
    const event = writtenWorkEventId ? WrittenWorkEvent.get(writtenWorkEventId) : undefined;
    return event ? event.name : "-";
};

export const getWrittenWorkStateDisplayValue = (writtenWorkState: WrittenWorkState): string =>
    writtenWorkStateDisplayValue.get(writtenWorkState) ?? "Ukjent";

export interface UserMetadata extends UserInfoMetadata {
    userId: string;
}

export type FilePath = string;
export type UserId = string;

export type WrittenWork = WrittenWorkBase;

const WrittenWorkLatestVersion = 1;
type AllWrittenWork = WrittenWorkBase; // | WrittenWorkV2 --> Union type;

export class WrittenWorkEvent {
    private static readonly all = new Map<string, WrittenWorkEvent>();
    static readonly K2021 = new WrittenWorkEvent(
        "K2021",
        "Novellekonkurranse 2021",
        new Date("2021-07-15"),
        new Date("2022-01-01"),
        new Map([
            [WrittenWorkState.ACCEPTED, "Ferdig"],
            [WrittenWorkState.REFUSED, "Ferdig"],
        ]),
        {
            introduction:
                "Kjære ${authorName}, \n\nTusen takk for at vi fikk gleden av å lese din novelle. Under ser du vår vurdering.",
            endingAccepted:
                "Det kom nærmere 550 bidrag til «Årets Novelle 2021». Flere av novellene holder et skyhøyt nivå, det ble knallhard konkurranse. Vi kommer til å publisere rundt 30 noveller, langt flere enn vi våget å håpe på.\n\nI din novelle ser vi en skriveglede vi håper du tar vare på. Bli med inn i vår litterære verden: Et sted for å skape, foredle og nyte. Vi satser tungt på lydbøker og andre digitale formater. Vi oppfordrer deg til å lytte, lære og leke videre. Publizm ønsker å bidra til utviklingen av din kompetanse på dette feltet.\n\nFølg oss på publizm.no og sosiale medier. Vi holder kontakten!\n\nMed vennlig hilsen\nPublizm v/ Sidsel Dalen",
            endingRefused:
                "Det kom nærmere 550 bidrag til «Årets Novelle 2021». Flere av novellene holder et skyhøyt nivå, det ble knallhard konkurranse. Vi kommer til å publisere rundt 30 noveller, langt flere enn vi våget å håpe på.\n\nI din novelle ser vi en skriveglede vi håper du tar vare på. Bli med inn i vår litterære verden: Et sted for å skape, foredle og nyte. Vi satser tungt på lydbøker og andre digitale formater. Vi oppfordrer deg til å lytte, lære og leke videre. Publizm ønsker å bidra til utviklingen av din kompetanse på dette feltet.\n\nFølg oss på publizm.no og sosiale medier. Vi holder kontakten!\n\nMed vennlig hilsen\nPublizm v/ Sidsel Dalen",
        }
    );

    private constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly activeFrom: Date,
        public readonly activeTo: Date,
        public readonly authorLabelMap?: Map<WrittenWorkState, string>,
        public readonly assessmentFeedback?: AssessmentFeedback
    ) {
        WrittenWorkEvent.all.set(id, this);
    }

    static get(id: string): WrittenWorkEvent | undefined {
        return WrittenWorkEvent.all.get(id);
    }

    static exists(id: string): boolean {
        return WrittenWorkEvent.all.has(id);
    }

    isActive(): boolean {
        return this.activeFrom.getTime() <= Date.now() && Date.now() <= this.activeTo.getTime();
    }

    toString(): string {
        return this.id;
    }

    feedbackIntroduction(authorName: string): string | undefined {
        return this.assessmentFeedback?.introduction?.replace("${authorName}", authorName);
    }
    feedbackEnding(judgement: boolean): string | undefined {
        return judgement ? this.assessmentFeedback?.endingAccepted : this.assessmentFeedback?.endingRefused;
    }
}

/*
#### Example ######
interface WrittenWorkV2 extends Omit<WrittenWorkBase, "version" | "authorId"> {
    version?: typeof WrittenWorkLatestVersion;
    authorId2: string;
} 
#############
*/

interface WrittenWorkBase extends PzFirestoreBase {
    version?: typeof WrittenWorkLatestVersion;
    authorId: string;
    assignedToEditor?: UserMetadata;
    title?: string;
    filePath?: FilePath;
    authorName?: string;
    pitch?: string;
    tags: string[];
    state: WrittenWorkState;
    writtenWorkEventId?: string;
    uploadDate: Timestamp;
    assessmentMetadata?: AssessmentMetadata;
}

// ##### ASSESSMENT #######
export type Assessment = AssessmentBase;
export const AssessmentLatestVersion = 1;

type AllAssessment = AssessmentBase;

export interface AssessmentMetadata {
    lastOpened?: Timestamp;
    openedQuantity?: number;
    judgement?: boolean;
}

export interface AssessmentFeedback {
    introduction: string;
    endingAccepted: string;
    endingRefused: string;
}

interface AssessmentBase extends AssessmentMetadata, PzFirestoreBase {
    version?: typeof AssessmentLatestVersion;
    genre?: string;
    theme?: string;
    motive?: string;
    characters?: string;
    structure?: string;
    language?: string;
    conclusion?: string;
    judgement?: boolean;
    introduction?: string;
    ending?: string;
}

export class WrittenWorkVersionMapper extends FirestoreVersionMapper<WrittenWork, AllWrittenWork> {
    public latestVersion = WrittenWorkLatestVersion;
    public mapToLatest(writtenWork: AllWrittenWork): WrittenWork {
        if (writtenWork.version === WrittenWorkLatestVersion) {
            return writtenWork;
        }

        switch (writtenWork.version) {
            case undefined:
                return {
                    ...writtenWork,
                    version: WrittenWorkLatestVersion,
                };
            default:
                throw new Error(`Mapper for writtenwork version ${writtenWork["version"]} not implemented`);
        }
    }
}

export class AssessmentVersionMapper extends FirestoreVersionMapper<Assessment, AllAssessment> {
    public latestVersion = AssessmentLatestVersion;
    public mapToLatest(assesment: AllAssessment): Assessment {
        if (assesment.version === AssessmentLatestVersion) {
            return assesment;
        }

        switch (assesment.version) {
            case undefined:
                return {
                    ...assesment,
                    version: AssessmentLatestVersion,
                };
            default:
                throw new Error(`Mapper for assesment version ${assesment["version"]} not implemented`);
        }
    }
}
