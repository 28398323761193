import { ReactElement } from "react";
import { AgreementType, UserRole } from "shared";

import { AgreementData } from "../../../../services/firestore/types";
import AgreementCheckbox from "../AgreementCheckbox";

interface AcceptanceCriteriaProps {
    agreements: AgreementData[];
}
export function AcceptanceCriteria({ agreements }: AcceptanceCriteriaProps): ReactElement {
    return (
        <>
            <AgreementCheckbox
                agreementType={AgreementType.CONFIDENTIALITY}
                role={UserRole.EDITOR}
                name="acceptConfidentiality"
                requiredMessage="Du må akseptere vilkårene for taushetsplikt"
                agreementDescription="vilkårene for Publizms taushetsplikt"
            />
            <AgreementCheckbox
                agreementType={AgreementType.USER_TERMS}
                role={UserRole.EDITOR}
                name="acceptAgreements"
                requiredMessage="Du må akseptere brukervilkårene"
                agreementDescription="Publizms brukervilkår"
            />
        </>
    );
}
