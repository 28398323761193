import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Column, Columns, Label } from "components/trunx";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import PzFormInputField from "../../../../../components/form/PzFormInputField";
import PzIconButton from "../../../../../components/form/PzIconButton";
import { BookAdminFormValues, LicenceeCostFormValues } from "./BookAdminTypes";
import LicenseeSelector from "./LicenseeSelector";

type BookTypeName = "ebook" | "audiobook";
interface BookCostFormProps {
    name: BookTypeName;
}
export default function BookCostForm({ name }: BookCostFormProps) {
    return (
        <Columns isFlex isFlexDirectionColumn>
            <BookCost name={name} />
            <Column m0 pt4>
                <Label pl0 isSize5>
                    Rettighetshaver forskudd:
                </Label>
                <BookLicenseeAdvancePayments name={name} />
            </Column>
        </Columns>
    );
}

interface BookLicenseeAdvancePaymentsProps {
    name: BookTypeName;
}
function BookLicenseeAdvancePayments({ name }: BookLicenseeAdvancePaymentsProps) {
    const { fields: licensees, append, remove } = useFieldArray({ name: `${name}.licenseeCosts` });
    return (
        <Columns isFlex isFlexDirectionColumn pl0 ml0>
            {licensees.map((licensee, index) => {
                return <LicenseeRow licensee={licensee as LicenceeCostFormValues} name={name} index={index} />;
            })}
            <Column pl0 ml0>
                <PzIconButton
                    onClick={(e) => {
                        e.preventDefault();
                        append({ percentage: 0, licenseeId: null, name: null, role: null });
                    }}
                    icon={faPlus}
                    iconPlacement="left"
                    isSmall
                >
                    Legg til
                </PzIconButton>
            </Column>
        </Columns>
    );
}
interface LicenseeRowProps {
    licensee: LicenceeCostFormValues;
    name: string;
    index: number;
}
function LicenseeRow({ licensee, name, index }: LicenseeRowProps) {
    const { errors } = useFormContext<BookAdminFormValues>();
    const { register } = useFormContext<BookAdminFormValues>();
    const isRemoved = useWatch<boolean>({ name: `${name}.licenseeCosts[${index}].removed` });
    const deletable = useWatch<boolean>({ name: `${name}.licenseeCosts[${index}].deletable` });
    if (isRemoved == true) {
        return null;
    }

    const errorAdvance = errors?.[name]?.licenseeCosts?.[index]?.advance;
    return (
        <Column pl0 ml0 is5 key={licensee["id"]} isFlex isFlexDirectionRow>
            <LicenseeSelector noDuplicates name={`${name}.licenseeCosts`} index={index} />
            <Column p0 m0 is4>
                <PzFormInputField
                    label="Forskudd"
                    mt5
                    name={`${name}.licenseeCosts[${index}].advance`}
                    defaultValue={licensee.advance}
                    type="number"
                    formError={errorAdvance}
                    inputRef={register({
                        validate: (advance: number) =>
                            advance < licensee.currentAdvance
                                ? `Du kan ikke endre forskudd til lavere enn ${licensee.currentAdvance}`
                                : true,
                    })}
                />
            </Column>
            {deletable !== false && <LicenseeRemoveButton name={name} index={index} />}
        </Column>
    );
}

interface BookCostProps {
    name: string;
}
function BookCost({ name }: BookCostProps) {
    const { register, errors } = useFormContext<BookAdminFormValues>();
    return (
        <Column is5>
            <PzFormInputField
                label="Produksjonskostnad (NOK)"
                name={`${name}.productionCost`}
                type="number"
                formError={errors[name]?.productionCost}
                inputRef={register()}
            />
        </Column>
    );
}

interface LicenseeRemoveButtonProps {
    name: string;
    index: number;
}
function LicenseeRemoveButton({ name, index }: LicenseeRemoveButtonProps) {
    const { register, errors, setValue } = useFormContext<BookAdminFormValues>();

    useEffect(() => {
        register(`${name}.licenseeCosts[${index}].removed`);
    }, []);
    return (
        <Column pt5>
            <PzIconButton
                onClick={(e) => {
                    e.preventDefault();
                    setValue(`${name}.licenseeCosts[${index}].removed`, true);
                }}
                icon={faTimes}
                iconPlacement="left"
                isSmall
            >
                Fjern
            </PzIconButton>
        </Column>
    );
}
