import { collection, getDocs, query, where } from "firebase/firestore";
import { FirestoreCollection } from "shared";

import { firestore } from "../firebase";

export default class SignupService {
    async isInvitedAsEditor(email: string) {
        const result = await getDocs(
            query(collection(firestore, FirestoreCollection.INVITES), where("email", "==", email))
        );
        return !result.empty;
    }
}
