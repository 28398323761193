import { collection, getDocs, query, where } from "firebase/firestore";
import { useQuery, UseQueryResult } from "react-query";
import { Author, AuthorProfile, AuthorSource, FirestoreCollection, UrlUtils } from "shared";

import { firestore } from "../../../firebase";
import { mapFirstQueryResult, mapQueryResult } from "../queries/queriesCommon";

interface UseAuthorsApiProps {
    authorsBySource: (source: AuthorSource) => UseQueryResult<Author[]>;
    authorProfilesById: (id: string) => UseQueryResult<AuthorProfile[]>;
    authorProfileById: (authorId: string, id: string, suspense?: boolean) => AuthorProfile | undefined;
    authorProfileByUrl: (url: string) => UseQueryResult<AuthorProfile | null>;
}

const AuthorKeys = {
    authors: "authors",
    authorsBySource: (source: AuthorSource) => [AuthorKeys.authors, "source", source],
    authorProfilesById: (id: string) => [AuthorKeys.authors, "profiles", id],
    authorProfileById: (authorId: string, id: string) => [AuthorKeys.authors, "profiles", authorId, id],
    authorProfileByUrl: (url: string) => [...AuthorKeys.authors, "authorProfileByUrl", url] as const,
    auhtorByDisplayName: (displayName: string) => [...AuthorKeys.authors, "auhtorByDisplayName", displayName] as const,
};
export default function useAuthorsApi(): UseAuthorsApiProps {
    function authorProfileByProfileId(id: string): UseQueryResult<AuthorProfile | null> {
        return useQuery({
            queryKey: AuthorKeys.authorProfilesById(id),
            queryFn: () =>
                getDocs(query(collection(firestore, FirestoreCollection.AUTHOR_PROFILE), where("authorId", "==", id))),
            select: mapFirstQueryResult<AuthorProfile>(),

            keepPreviousData: true,
            suspense: false,
        });
    }
    function authorProfileByUrl(url: string): UseQueryResult<AuthorProfile | null> {
        const urlAuthorId = UrlUtils.getAuthorRefFromUrl(url);
        if (urlAuthorId) {
            return authorProfileByProfileId(urlAuthorId);
        }
        const displayName = UrlUtils.decodeAuthorUrlLegacy(url!);
        return profileByDisplayName(displayName);
    }
    function profileByDisplayName(displayName: string) {
        return useQuery({
            queryKey: AuthorKeys.auhtorByDisplayName(displayName),
            queryFn: () =>
                getDocs(
                    query(
                        collection(firestore, FirestoreCollection.AUTHOR_PROFILE),
                        where("displayName", "==", displayName),
                        where("language", "==", "NB")
                    )
                ),
            select: mapFirstQueryResult<AuthorProfile>(),
            suspense: false,
        });
    }
    function authorsBySource(source: AuthorSource): UseQueryResult<Author[]> {
        return useQuery({
            queryKey: AuthorKeys.authorsBySource(source),
            queryFn: () =>
                getDocs(query(collection(firestore, FirestoreCollection.AUTHOR), where("source", "==", source))),
            select: mapQueryResult<Author>(),

            keepPreviousData: true,
        });
    }
    function authorProfileById(authorId: string, profileId: string, suspense?: boolean): AuthorProfile | undefined {
        const profiles = authorProfilesById(authorId, suspense);
        return profiles.data?.find((p) => p.id === profileId);
    }
    function authorProfilesById(id: string, suspense?: boolean): UseQueryResult<AuthorProfile[]> {
        return useQuery({
            queryKey: AuthorKeys.authorProfilesById(id),
            queryFn: () =>
                getDocs(query(collection(firestore, FirestoreCollection.AUTHOR_PROFILE), where("authorId", "==", id))),
            select: mapQueryResult<AuthorProfile>(),

            keepPreviousData: true,
            enabled: id != undefined,
            suspense: suspense ?? false,
        });
    }
    return {
        authorProfilesById,
        authorsBySource,
        authorProfileById,
        authorProfileByUrl,
    };
}
