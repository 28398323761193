import PzSwiper from "./PzSwiper";

interface IPzItemsSwiperProps {
    slides?: JSX.Element[];
    autoplayDelay?: number;
}
export default function PzSlideshow({ slides, autoplayDelay }: IPzItemsSwiperProps) {
    return (
        <PzSwiper autoplay={autoplayDelay ? { delay: autoplayDelay } : true} slides={slides ?? []} slidesPerView={1} />
    );
}
