import { Container, Field, Help, Label, Textarea } from "components/trunx";
import React, { useEffect, useRef } from "react";
import { ReactElement } from "react";

interface PzTextAreaProps {
    name: string;
    label?: string;
    maxLength?: number;
    defaultValue?: string;
    error?: string;
    textAreaRef: (ref: HTMLTextAreaElement) => void;
    onChange?: (value: string) => void;
}
export default function PzTextArea({
    name,
    defaultValue,
    maxLength,
    label,
    textAreaRef,
    error,
    onChange,
}: PzTextAreaProps): ReactElement {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (textAreaRef && ref.current) {
            const textAreaElement = ref.current.querySelector("textarea") as HTMLTextAreaElement;
            textAreaRef(textAreaElement);
        }
    }, []);

    function onTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        onChange?.(e.target.value);
    }
    return (
        <div ref={ref}>
            {label && (
                <Field.Label isNormal>
                    <Label hasTextLeft>{label}</Label>
                </Field.Label>
            )}
            <Textarea
                maxLength={maxLength}
                name={name}
                isDanger={error !== undefined}
                value={defaultValue}
                onChange={onTextChange}
            />
            {error && (
                <Container pb5 mb4>
                    <Help isDanger style={{ fontSize: "16px" }}>
                        {error}
                    </Help>
                </Container>
            )}
        </div>
    );
}
