import { WrittenWorkEvent } from "shared";

export function getWrittenWorkEventFromUrl(url: string): WrittenWorkEvent | undefined {
    if (!url) return;

    const eventParam = new URLSearchParams(url).get("event");

    if (!eventParam) throw new Error(`URL inneholder ikke event ${url}`);

    const writtenWorkEvent = WrittenWorkEvent.get(eventParam);

    if (!WrittenWorkEvent.exists(eventParam)) {
        throw new Error(`Eventen "${eventParam}" eksisterer ikke.`);
    } else if (writtenWorkEvent && !writtenWorkEvent.isActive()) {
        throw new Error(`Eventen "${eventParam}" er ikke aktiv.`);
    }

    return writtenWorkEvent;
}

export function updateWindowQueryParameterByQueries(queries: Record<string, string | null>, replace?: boolean): void {
    let url = getQueryUrl(queries);
    url = url.endsWith("?") ? url.substring(0, url.length - 1) : url;
    if (url != getWindowFullUrl()) {
        replace ? window.history.replaceState(null, "", url) : window.history.pushState(null, "", url);
    }
}

export function getWindowFullUrl() {
    return window.location.host + window.location.pathname + window.location.search;
}

export function updateWindowQueryParameter(key: string, value?: string | null): void {
    const searchParams = new URLSearchParams(window.location.search);
    value ? searchParams.set(key, value) : searchParams.delete(key);
    const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
    window.history.pushState(null, "", newRelativePathQuery);
}

export function getQueryUrl(queries: Record<string, string | null | undefined>) {
    const searchParams = new URLSearchParams(window.location.search);
    Object.keys(queries).forEach((key) => {
        const value = queries[key];
        value ? searchParams.set(key, value) : searchParams.delete(key);
    });
    return window.location.pathname + "?" + searchParams.toString();
}
