import { DocumentData, DocumentReference, getDocs, Query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
    BookType,
    FirestoreCollection,
    InitiateBookPaymentRequest,
    InitiatePaymentResponse,
    PaymentDetailsWrapper,
    RefundBookPaymentRequest,
} from "shared";

import { functions, functionsEurope } from "../firebase";
import { AnalyticsApi, FirestoreApi } from "./firestore/queries/queriesCommon";

export default class VippsService {
    /**
     * Initiate a Vipps transaction. Redirects the user to a Vipps GUI.
     * @param bookId ID of Firestore document containing information about the book that is being purchased
     * @param bookType of enum BookPriceType representing which version of work to be purchased
     * @throws Error if something went wrong in the API call and it returns a status code like 4xx or 5xx
     */
    async initiateBookPayment(bookId: string, bookType: BookType, price: number, title?: string): Promise<void> {
        const initiatePaymentFn = httpsCallable(functionsEurope, "initVippsPayment", {
            timeout: 1000 * 60 * 10, // 10 minutes
        });

        const bookPaymentRequest: InitiateBookPaymentRequest = {
            bookId: bookId,
            bookType: bookType,
        };
        AnalyticsApi.logEvent("vipps_payment_initated");
        AnalyticsApi.logBeginCheckout(price, bookId, title ?? "ukjent tittel", bookType);
        return initiatePaymentFn(bookPaymentRequest)
            .then((response) => {
                const { userPerformPaymentUrl } = response.data as InitiatePaymentResponse;
                window.location.href = userPerformPaymentUrl;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    async cancelPayment(userId: string, transactionId: string): Promise<void> {
        const refundPaymentFn = httpsCallable(functionsEurope, "refundVippsPayment", {
            timeout: 1000 * 60 * 10, // 10 minutes
        });

        const refundVippsPayment: RefundBookPaymentRequest = {
            userId,
            transactionId,
        };
        AnalyticsApi.logEvent("vipps_payment_cancelled");
        return refundPaymentFn(refundVippsPayment)
            .then((response) => {
                console.log("Payment cancelled", response);
            })
            .catch((err) => {
                throw new Error(err);
            });
    }
    /**
     * Gets the details of a Vipps transaction.
     * @param orderId ID of transaction which has been initiated.
     * @throws Error if something went wrong in the API call and it returns a status code like 4xx or 5xx
     */
    async getPaymentDetails(orderId: string): Promise<PaymentDetailsWrapper> {
        const getPaymentDetailsFn = httpsCallable(functions, "getPaymentDetails");

        const res = (await this.executeFunctionAndHandleResult(getPaymentDetailsFn, orderId))
            .data as PaymentDetailsWrapper;

        return res;
    }

    /**
     * Updates the Firestore document corresponding to the order ID and perform correct
     * actions with regard to the current status of the transaction (e.g. capture
     * payment if transaction has status "RESERVED").
     * @param orderId ID of transaction which has been initiated.
     * @throws Error if something went wrong in the API call and it returns a status code like 4xx or 5xx
     */
    refreshTransaction(orderId: string): void {
        const refreshTransactionFn = httpsCallable(functions, "refreshTransaction");

        this.executeFunctionAndHandleResult(refreshTransactionFn, orderId);
    }

    /**
     * Cancels an initiated or reserved transaction. Updates the Firestore document correspondingly.
     * @param orderId ID of transaction which has been initiated.
     * @throws Error if something went wrong in the API call and it returns a status code like 4xx or 5xx
     */
    async cancelPayment2(orderId: string): Promise<void> {
        const cancelPaymentFn = httpsCallable(functions, "cancelPayment");

        await this.executeFunctionAndHandleResult(cancelPaymentFn, orderId);
    }

    /**
     * Refunds a captured transaction. Updates the Firestore document correspondingly.
     * @param orderId ID of transaction which has been initiated.
     * @throws Error if something went wrong in the API call and it returns a status code like 4xx or 5xx
     */
    async refundPayment(orderId: string): Promise<void> {
        const refundPaymentFn = httpsCallable(functions, "refundPayment");

        await this.executeFunctionAndHandleResult(refundPaymentFn, orderId);
    }

    async getVippsPaymentInfoDocumentRefByOrderIdAndUserId(
        orderId: string,
        userId: string
    ): Promise<DocumentReference> {
        const query = await getDocs(
            FirestoreApi.getQueryFor(
                FirestoreCollection.PAYMENT,
                where("orderId", "==", orderId),
                where("userId", "==", userId)
            )
        );

        if (!query.empty) {
            const snapshot = query.docs[0];
            return snapshot.ref;
        } else {
            throw new Error(`Could not find payment-document with orderId '${orderId}' and userId ${userId}.`);
        }
    }

    getVippsPaymentInfoDocumentRefByOrderIdAndUserIdQuery(orderId: string, userId: string): Query<DocumentData> {
        return FirestoreApi.getQueryFor(
            FirestoreCollection.PAYMENT,
            where("orderId", "==", orderId),
            where("userId", "==", userId)
        );
    }

    //@ts-ignore
    private async executeFunctionAndHandleResult(fn: ({ orderId: string }) => Promise<void | any>, orderId: string) {
        return await fn({ orderId })
            .then((response) => {
                if (!response) {
                    throw new Error("Data from API call not present");
                }
                return response;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }
}
