import { DefaultRestService } from "./DefaultRestService";

export interface BrregCompanyResponse {
    navn: string;
}

export const BrregService = {
    getCompanyById: (orgnr: string): Promise<BrregCompanyResponse | undefined> =>
        DefaultRestService.get(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`).then((response) => {
            if (response.status === 200) {
                return response.data as BrregCompanyResponse;
            }
        }),
};
