import React from "react";
import { BookPrice } from "shared";

import PzBookCard from "../../../components/pz/PzBookCard";
import { Content } from "../../../components/trunx";
import { BookVersionDetails } from "../bookdetails/BookDetailsContext";

export interface CatalogueCardProps extends BookVersionDetails {
    prices: BookPrice[];
}
interface IBookCardGridProps {
    loading: boolean;
    bookCardDetails: CatalogueCardProps[];
}

export default function BookCardGrid({ bookCardDetails, loading }: IBookCardGridProps) {
    return (
        <div className="cards-container catalogue-cards-container">
            {bookCardDetails.length === 0 && !loading ? <Content>Ingen treff på ditt søk</Content> : null}
            {bookCardDetails.map((card) => (
                <PzBookCard {...card} key={card.id} />
            ))}
        </div>
    );
}
