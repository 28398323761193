import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { ReactElement } from "react";

import IconText from "../../../../components/text/IconText";

interface UserAgreementLabelProps {
    hasApprovedLatestAgreements: boolean;
}
export default function UserAgreementLabel({ hasApprovedLatestAgreements }: UserAgreementLabelProps): ReactElement {
    if (hasApprovedLatestAgreements) {
        return (
            <IconText icon={faCheck} color="green">
                {"godkjent nyeste"}
            </IconText>
        );
    }

    return (
        <IconText icon={faTimes} color="red">
            {"mangler"}
        </IconText>
    );
}
