import "./StoryblokComponents.css";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
    apiPlugin,
    ISbRichtext,
    RichTextSchema,
    StoryblokComponent,
    StoryblokComponentType,
    storyblokInit,
} from "@storyblok/react";
import cloneDeep from "clone-deep";
import { Column, Columns, Container, Section } from "components/trunx";
import { PropsWithChildren, useState } from "react";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Environment, UserRole } from "shared";
import {
    MARK_BOLD,
    MARK_STYLED,
    NODE_HEADING,
    NODE_PARAGRAPH,
    NODE_QUOTE,
    render,
} from "storyblok-rich-text-react-renderer";

import { AppPath } from "../../common/AppPath";
import { AuthStatus, RenderWhen } from "../../components/auth/UserAuth";
import PzButton from "../../components/form/PzButton";
import TextModal from "../../components/modal/TextModal";
import NewsLetterInput from "../../components/pagetemplate/NewsLetterInput";
import PublizmLogo from "../../components/PublizmLogo";
import PzCarousel from "../../components/PzCarousel";
import PzQuote from "../../components/text/PzQuote";
import PzText from "../../components/text/PzText";
import { PzTitle } from "../../components/text/PzTitle";
import CatalogueCardDisplayer from "../../pages/customer/catalogue/CatalogueCardDisplayer";
import ArticleCard from "../../pages/storyblok/ArticleCard";
import FeaturedBooksSlideshow from "../../pages/storyblok/FeaturedBooksSlideshow";
import GroupedBooksSlideshow from "../../pages/storyblok/GroupedBooksSlideshow";
import { ArticleItems } from "../../pages/storyblok/types";
import PzIconButton from "../form/PzIconButton";
import PzFaq from "../PzFaq";
import PzSlideshow from "../swiper/PzSlideshow";
import { isEmpty } from "../utils/ObjectUtils";
import SbEditableContainer, { CustomSbBlokData, StoryblokBlokProps } from "./SbEditableContainer";
import StoryblokWrapper from "./StoryblokWrapper";

const sbRichTextSchema: typeof RichTextSchema = cloneDeep(RichTextSchema); // you can make a copy of the default RichTextSchema
const storyBlokToken = "qDKtf8eQUgJ4s6r0QlGZtQtt";
type Alignment = "centered" | "right" | "left";
export const isDev =
    Environment.isLocal(import.meta.env) ||
    Environment.isDevelopment(import.meta.env) ||
    import.meta.env.MODE == "development";
export const storyblokEnv = isDev ? "draft" : "published";
storyblokInit({
    accessToken: storyBlokToken,
    bridge: true,
    use: [apiPlugin],
    apiOptions: {
        cache: { type: "memory" },
    },
    components: {
        title: SbTitle,
        image: SbImage,
        videolink: VideoLink,
        button: SbButton,
        empty_block: EmptyBlock,
        body: RichTextRenderer,
        quote: SbQuote,
        carousel: SbCarousel,
        faq: SbFaq,
        column: SbColumn,
        infomodal: SbInfomodal,
        authenticated: SbAuthetincatedUser,
        newsletter_subscription: SbNewsLetterSubscribtion,
        slideshow: SbSlideshow,
        latest_article: SbLatestArticle,
        featured_books: SbFeaturedBooks,
        grouped_books: SbGroupedBooks,
        container: SbContainer,
        catalog: SbCatalog,
    },
    richText: {
        schema: sbRichTextSchema,
        resolver: (component, content) => {
            switch (component) {
                case "videolink":
                    return <VideoLink blok={content} />;
                case "image":
                    return <SbImage blok={content} />;
                case "button":
                    return <SbButton blok={content} />;
                case "infomodal":
                    return <SbInfomodal blok={content} />;
                case "authenticated":
                    return <SbAuthetincatedUser blok={content} />;
                case "slideshow":
                    return <SbSlideshow blok={content} />;
                case "grouped_books":
                    return <SbGroupedBooks blok={content} />;
                default:
                    return "Resolver not defined";
            }
        },
    },
});

function renderRichText2(content: any) {
    return render(content, {
        nodeResolvers: {
            [NODE_HEADING]: (children, { level }) => {
                return (
                    <PzTitle
                        mt3
                        mb3
                        isSize1={level === 1}
                        isSize2={level === 2}
                        isSize3={level === 3}
                        isSize4={level === 4}
                    >
                        {children}
                    </PzTitle>
                );
            },
            [NODE_QUOTE]: (children) => <PzText hasTextGrey>{children}</PzText>,
            [NODE_PARAGRAPH]: (children) => {
                if (children == null) return <p style={{ height: "1em", marginBottom: 0 }}> </p>;
                return <p>{children}</p>;
            },
        },
        defaultStringResolver: (str) => <PzText hasTextCentered>{str}</PzText>,
        markResolvers: {
            [MARK_STYLED]: (children, attr) => {
                return (
                    <>
                        {children && (
                            <PzText className={`${attr.class}`}>
                                {/* @ts-ignore */}
                                {children?.type !== "br" ? children : <div className="mt-auto" />}
                            </PzText>
                        )}
                    </>
                );
            },
            [MARK_BOLD]: (children) => <div style={{ fontWeight: "bold" }}>{children}</div>,
        },
        blokResolvers: {
            // @ts-ignore
            ["videolink"]: (blok) => <VideoLink blok={blok} />,
            // @ts-ignore
            ["image"]: (blok) => <SbImage blok={blok} />,
            // @ts-ignore
            ["button"]: (blok) => <SbButton blok={blok} />,
            // @ts-ignore
            ["infomodal"]: (blok) => <SbInfomodal blok={blok} />,
            // @ts-ignore
            ["authenticated"]: (blok) => <SbAuthetincatedUser blok={blok} />,
            // @ts-ignore
            ["slideshow"]: (blok) => <SbSlideshow blok={blok} />,
            // @ts-ignore
            ["grouped_books"]: (blok) => <SbGroupedBooks blok={blok} />,
        },
    });
}

interface SbCatalogProps {
    content: StoryblokComponentType<any>[];
}

function SbCatalog({ blok }: StoryblokBlokProps<SbCatalogProps>) {
    return (
        <SbEditableContainer blok={blok}>
            <CatalogueCardDisplayer defaultPageSize={12} />
        </SbEditableContainer>
    );
}

interface SbContainerProps {
    content: StoryblokComponentType<any>[];
    title: string;
}

function SbContainer({ blok }: StoryblokBlokProps<SbContainerProps>) {
    return (
        <SbEditableContainer blok={blok}>
            <ContentContainer title={blok.title}>
                {blok.content.map((content, index) => (
                    <Column key={index}>
                        <StoryblokComponent blok={content} key={`sb_${index}`} />
                    </Column>
                ))}
            </ContentContainer>
        </SbEditableContainer>
    );
}

function SbGroupedBooks({ blok }: StoryblokBlokProps<{ groupName: string; title: string; description: string }>) {
    return (
        <SbEditableContainer blok={blok}>
            <GroupedBooksSlideshow groupName={blok.groupName} title={blok.title} description={blok.description} />
        </SbEditableContainer>
    );
}

function SbFeaturedBooks(props: StoryblokBlokProps<{ title: string }>) {
    return (
        <SbEditableContainer {...props}>
            <ContentContainer title={props.blok.title}>
                <FeaturedBooksSlideshow />
            </ContentContainer>
        </SbEditableContainer>
    );
}

function SbLatestArticle({ blok }: StoryblokBlokProps<{ title: string }>) {
    const navigate = useNavigate();
    const title = isEmpty(blok.title as string) ? "Aktuelt" : (blok.title as string);
    return (
        <SbEditableContainer blok={blok}>
            <ContentContainer title={title}>
                <StoryblokWrapper slug="article">
                    {(val: ArticleItems) => {
                        return <ArticleCard item={val.items[0]} isSmall />;
                    }}
                </StoryblokWrapper>
                <PzIconButton
                    iconPlacement="left"
                    isSmall
                    icon={faArrowRight}
                    onClick={() => navigate(AppPath.ARTICLES)}
                >
                    <div style={{ textDecoration: "underline" }}>se alle artikler i aktuelt</div>
                </PzIconButton>
            </ContentContainer>
        </SbEditableContainer>
    );
}

interface SbColumnProps {
    content: StoryblokComponentType<any>[];
    alignment: Alignment[];
}

function SbColumn({ blok }: StoryblokBlokProps<SbColumnProps>) {
    return (
        <SbEditableContainer blok={blok}>
            <Columns isInlineMobile isAlignItemsSpaceAround isFlex style={{ width: "max-content", margin: "0 auto" }}>
                {blok.content.map((content, index) => (
                    <Column
                        key={index}
                        pr5
                        isAlignSelfCenter={blok.alignment?.includes("centered")}
                        hasTextCenteredMobile
                    >
                        <StoryblokComponent blok={content} key={`sb_${index}`} />
                    </Column>
                ))}
            </Columns>
        </SbEditableContainer>
    );
}

interface AuthetincatedUserProps {
    user_role: string;
    content: any[];
}

function SbAuthetincatedUser({ blok }: StoryblokBlokProps<AuthetincatedUserProps>) {
    let userRole = AuthStatus[blok.user_role as keyof typeof AuthStatus];
    userRole = userRole ?? UserRole[blok.user_role as keyof typeof UserRole];
    return (
        <RenderWhen userIs={userRole}>
            {blok.content.map((content, index) => (
                <StoryblokComponent blok={content} key={`sb_${index}`} />
            ))}
        </RenderWhen>
    );
}

interface InfomodalProps {
    linktext: string;
    modaltitle: string;
    text: string;
    modalcontent: any;
    textAlignment: "centered" | "left" | "right";
}

function SbInfomodal({ blok }: StoryblokBlokProps<InfomodalProps>) {
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const openInfoModal = () => setShowInfoModal(true);
    const closeInfoModal = () => setShowInfoModal(false);

    return (
        <SbEditableContainer blok={blok}>
            <TextModal
                onClose={closeInfoModal}
                isActive={showInfoModal}
                title={blok.modaltitle}
                content={<>{renderRichText2(blok.modalcontent)}</>}
                maxHeight={75}
                maxWidth={50}
            />
            <PzText
                hasTextRight={blok.textAlignment === "right"}
                hasTextLeft={blok.textAlignment === "left"}
                hasTextCentered={blok.textAlignment == "centered"}
                p0
                m0
                containerProps={{ m0: true, p0: true }}
                hasTextWeightBold
            >
                {blok.text} <a onClick={openInfoModal}>{blok.linktext}</a>
            </PzText>
        </SbEditableContainer>
    );
}

interface SBImageProps {
    image: {
        filename: string;
        name?: string;
        title?: string;
        metadata?: Record<string, string>;
    };
    title: string;
    size: "small" | "medium" | "large" | "xlarge";
    width: "small" | "medium" | "large" | "xlarge";
    alignment: "right" | "left" | "centered";
}

function SbImage({ blok }: StoryblokBlokProps<SBImageProps>): ReactElement {
    function getWidth() {
        switch (blok.width) {
            case "small":
                return "300px";
            case "medium":
                return "500px";
            case "large":
                return "700px";
            case "xlarge":
                return "1000px";
            default:
                return undefined;
        }
    }

    let colProps = {};
    if (blok.alignment) {
        colProps = {
            isFlex: true,
            isFlexDirectionRow: true,
            isJustifyContentFlexEnd: blok.alignment === "right",
            isJustifyContentFlexStart: blok.alignment === "left",
            isJustifyContentCenter: blok.alignment === "centered",
        };
    }

    return (
        <SbEditableContainer blok={blok}>
            <Column isInlineMobile {...colProps}>
                {blok.title && (
                    <PzText>
                        <p style={{ textAlign: "center" }}>{blok.title}</p>
                    </PzText>
                )}
                <figure
                    className="image is-center"
                    style={{ display: "flex", flexDirection: "column", maxWidth: getWidth() }}
                >
                    <img style={{ maxWidth: getWidth() }} src={blok.image.filename} alt={blok.title} />
                    <caption style={{ textAlign: "left", maxWidth: getWidth() }}>{blok.image.name}</caption>
                </figure>
            </Column>
        </SbEditableContainer>
    );
}

type SbButtonLinkTo = "SHORT_STORY" | "REGISTER" | "REGISTER_CUSTOMER" | "LOGIN" | "CATALOGUE";

interface SbButtonProps {
    text: string;
    linkToPath: string;
    smocLink?: string;
    linkTo: SbButtonLinkTo;
    alignment: Alignment;
}

export function sbButtonLinkToToPath(linkTo: SbButtonLinkTo) {
    switch (linkTo) {
        case "CATALOGUE":
            return `${AppPath.CATALOGUE}`;
        case "SHORT_STORY":
            return `${AppPath.SUBMIT_WORK}`;
        case "REGISTER":
            return `${AppPath.SIGNUP}`;
        case "REGISTER_CUSTOMER":
            return `${AppPath.SIGNUP_CUSTOMER}`;
        case "LOGIN":
            return `${AppPath.LOGIN}`;
        default:
            return `${AppPath.ROOT}`;
    }
}

function SbButton({ blok }: StoryblokBlokProps<SbButtonProps>): ReactElement {
    const navigate = useNavigate();

    const linkTo = () => {
        if (blok.smocLink) {
            // Example smocLink = https://go.smoc.ai/publizm/kuben_copy_1/survey1_copy_1
            const pattern = /\/publizm\/([^/]+)\/([^/]+)/.exec(blok.smocLink);
            if (pattern && pattern.length > 2) {
                const name = pattern[1];
                const key = pattern[2];
                return `/smoc?smoc_domain_name=${name}&smoc_key=${key}`;
            }
        }
        if (blok.linkToPath) return blok.linkToPath;
        return sbButtonLinkToToPath(blok.linkTo);
    };
    const alignment = blok.alignment;
    const alignemntProps = {
        isJustifyContentCenter: alignment === "centered",
        isJustifyContentFlexStart: alignment === "left",
        isJustifyContentFlexEnd: alignment === "right",
    };
    console.log(linkTo());
    return (
        <SbEditableContainer blok={blok}>
            <Column isFlex {...alignemntProps} isMarginLess>
                <PzButton isNormal isSize4 isPrimary onClick={() => navigate(linkTo())}>
                    {blok.text}
                </PzButton>
            </Column>
        </SbEditableContainer>
    );
}

interface TitleProps {
    title: string;
    size: "small" | "medium" | "large";
    alignment: "centered" | "left" | "right";
    component: string;
}

export function SbTitle({ blok }: StoryblokBlokProps<TitleProps>): ReactElement {
    const alignment = blok.alignment;
    const alignemntProps = {
        hasTextCentered: alignment === "centered",
        hasTextLeft: alignment === "left",
        hasTextRight: alignment === "right",
    };

    const size = blok.size;
    const sizeProps = {
        isSize1: size === "large",
        isSize2: size === "medium",
        isSize3: size === "small",
    };
    return (
        <SbEditableContainer blok={blok}>
            <PzTitle p0 m0 {...alignemntProps} {...sizeProps} className="responsive-title sbtitle">
                {blok.title}
            </PzTitle>
        </SbEditableContainer>
    );
}

interface VideoLinkProps {
    link: {
        cached_url: string;
    };
    video_link: string;
    alignment: Alignment;
    size: "small" | "medium" | "large" | "xlarge";
}

export function VideoLink({ blok }: StoryblokBlokProps<VideoLinkProps>): ReactElement {
    const alignment = blok.alignment;
    const alignemntProps = {
        isJustifyContentCenter: alignment == undefined || alignment === "centered",
        isJustifyContentFlexStart: alignment === "left",
        isJustifyContentFlexEnd: alignment === "right",
    };

    function getWidth() {
        return getHeight() * 1.777777777777778;
    }

    function getHeight() {
        switch (blok.size) {
            case "small":
                return 315;
            case "medium":
                return 500;
            case "large":
                return 700;
            case "xlarge":
                return 800;
            default:
                return 315;
        }
    }

    function getYoutubeVideoId(videoLink: string) {
        if (videoLink.includes("youtu.be")) {
            return videoLink.replace("https://youtu.be/", "");
        } else if (videoLink.includes("youtube.com/watch")) {
            return videoLink.replace("https://www.youtube.com/watch?v=", "");
        }

        return videoLink;
    }

    function getVimeoVideoId(videoLink: string) {
        if (videoLink.includes("https://vimeo.com")) {
            return videoLink.replace("https://vimeo.com/", "");
        } else if (videoLink.includes("https://player.vimeo.com/video/")) {
            return videoLink.replace("https://player.vimeo.com/video/", "").replace(/\?.*/, "");
        }

        return videoLink;
    }

    function renderIframe() {
        const videoLink = blok.video_link ?? blok.link?.cached_url;
        if (videoLink?.includes("vimeo")) {
            return (
                <iframe
                    width={getWidth() + "px"}
                    height={getHeight() + "px"}
                    title="vimeo-player"
                    src={`https://player.vimeo.com/video/${getVimeoVideoId(videoLink)}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            );
        }

        return (
            <iframe
                width={getWidth() + "px"}
                height={getHeight() + "px"}
                src={`https://www.youtube.com/embed/${getYoutubeVideoId(videoLink)}?autoplay=0`}
                title=""
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
        );
    }
    return (
        <SbEditableContainer blok={blok}>
            <Column isFlex {...alignemntProps} isMarginLess>
                {renderIframe()}
            </Column>
        </SbEditableContainer>
    );
}

interface SbSlideshowProps {
    images: SBImageProps[];
}

export function SbSlideshow({ blok }: StoryblokBlokProps<SbSlideshowProps>): ReactElement {
    const images =
        blok.images?.map((image, index) => (
            <figure className="image is-center">
                <img
                    key={index}
                    src={image.image.filename}
                    style={{ height: "400px", width: "auto", objectFit: "cover", borderRadius: 8 }}
                />
            </figure>
        )) ?? [];

    const columns = blok.columns as CustomSbBlokData[];
    const components =
        columns?.map((blok, index) => (
            <Column key={index}>
                <StoryblokComponent blok={blok} key={`sb_${index}`} />
            </Column>
        )) ?? [];
    const slides = [...images, ...components];
    return (
        <SbEditableContainer blok={blok}>
            <Container>
                <PzSlideshow slides={slides} />
            </Container>
        </SbEditableContainer>
    );
}

interface SBQuoteProps {
    text: ISbRichtext;
}

export function SbQuote({ blok }: StoryblokBlokProps<SBQuoteProps>): ReactElement {
    return (
        <SbEditableContainer blok={blok}>
            <Section>
                <Column isFlex isJustifyContentCenter>
                    <PublizmLogo isLink={false} width={75} height={101} />
                </Column>
                <PzQuote isPrimary isSize4>
                    <p>{renderRichText2(blok.text)}</p>
                </PzQuote>
            </Section>
        </SbEditableContainer>
    );
}

interface SbFaqProps {
    item: SbFaqItem[];
}

interface SbFaqItem {
    question: string;
    answer: string;
}

export function SbFaq({ blok }: StoryblokBlokProps<SbFaqProps>): ReactElement {
    return (
        <SbEditableContainer blok={blok}>
            <Column mt4>
                <PzFaq items={blok.item} />
            </Column>
        </SbEditableContainer>
    );
}
export function EmptyBlock({ blok }: StoryblokBlokProps<unknown>): ReactElement {
    // const richTextContainer = () => <div dangerouslySetInnerHTML={{ __html: renderRichText(blok!.content) }} />;
    return (
        <SbEditableContainer blok={blok!}>
            <div style={{ height: "30px" }} />
        </SbEditableContainer>
    );
}
export function RichTextRenderer({ blok }: StoryblokBlokProps<{ content: ISbRichtext }>): ReactElement {
    // const richTextContainer = () => <div dangerouslySetInnerHTML={{ __html: renderRichText(blok!.content) }} />;
    return (
        <SbEditableContainer blok={blok!}>
            <PzText className={blok.className as string} containerProps={{ mb4: true, mt4: true }}>
                {renderRichText2(blok!.content)}
            </PzText>
        </SbEditableContainer>
    );
}

interface SbCarouselProps {
    item: SbCarouselItem[];
}

interface SbCarouselItem {
    content: any[];
    image: {
        filename: string;
    };
}

function SbCarousel({ blok }: StoryblokBlokProps<SbCarouselProps>) {
    const items = blok.item
        .filter((item) => item.content.length > 0)
        .map((item) => (
            <Column
                className="carousel_item"
                style={{ margin: "0 auto" }}
                is3Tablet
                is5Mobile
                p0
                m0
                isFlex
                isFlexDirectionColumn
                isAlignContentCenter
                isAlignItemsCenter
                isJustifyContentCenter
            >
                <Column isFlexGrow0 isFlex isJustifyContentStart isAlignContentStart>
                    <figure className="image is-center pb-0 mb-0">
                        <img className="" src={item.image.filename} style={{ maxHeight: "300px", maxWidth: "300px" }} />
                    </figure>
                </Column>
                <Column isFlexGrow1 is12 isJustifyContentStart isAlignContentStart>
                    {renderRichText2(item.content[0].content)}
                </Column>
            </Column>
        ));
    return (
        <SbEditableContainer blok={blok}>
            <Container m0 mb4 p0 isAlignContentFlexStart className="sbcarousel">
                <Column isHiddenMobile p0 m0 isFlex isJustifyContentCenter isFlexWrapNowrap>
                    {items.map((item) => (
                        <>{item}</>
                    ))}
                </Column>
                <Column isHiddenTablet>
                    <PzCarousel items={items} />
                </Column>
            </Container>
        </SbEditableContainer>
    );
}

function SbNewsLetterSubscribtion({ blok }: StoryblokBlokProps<unknown>) {
    return (
        <SbEditableContainer blok={blok}>
            <Container pt5 pb0>
                <PzTitle hasTextCentered style={{ fontWeight: 500 }}>
                    Meld deg på, få siste nytt
                </PzTitle>
                <Column pb0 mb0>
                    <NewsLetterInput />
                </Column>
            </Container>
        </SbEditableContainer>
    );
}

interface ContentContainerProps {
    title?: string;
    backgroundShadow?: boolean;
}

function ContentContainer({ title, children, backgroundShadow = true }: PropsWithChildren<ContentContainerProps>) {
    return (
        <Container className="sbcontent">
            {title && (
                <PzTitle className="sbtitle" pb0 mb0 isSize2>
                    {title}
                </PzTitle>
            )}
            <Column
                mt0
                style={backgroundShadow ? { backgroundColor: "white", boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px" } : {}}
            >
                {children}
            </Column>
        </Container>
    );
}
