import DashboardUserIcon from "components/dashboard/DashboardUserIcon";
import { Column, Columns, Content, Heading } from "components/trunx";
import { ReactElement } from "react";
import { UserRole } from "shared";

import useOnClickOutside from "../hooks/useOnClickOutside";
import { UserPopoverMenu } from "./UserPopoverMenu";

export interface UserLogoProps {
    name: string;
    role?: UserRole;
}

export default function UserLogo(props: UserLogoProps): ReactElement {
    const {
        ref,
        isComponentVisible: showUserMenu,
        setIsComponentVisible: setShowUserMenu,
    } = useOnClickOutside<HTMLDivElement>(false);

    const { name, role } = props;
    function toggleShowUserMenu() {
        setShowUserMenu((prevState) => !prevState);
    }
    return (
        <Content p0 m0>
            <Columns isJustifyContentFlexEnd isFlex p0 m0 className="is-vcentered" isMobile>
                <Column m0 p0 isHiddenMobile isHiddenTouch>
                    <Heading className="noBreak">{name}</Heading>
                </Column>
                <Column isJustifyContentFlexEnd isFlexGrow0 isClickable onClick={toggleShowUserMenu}>
                    <div ref={ref}>
                        <DashboardUserIcon />
                        <UserPopoverMenu showUserMenu={showUserMenu} />
                    </div>
                </Column>
            </Columns>
        </Content>
    );
}
