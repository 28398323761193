import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
    CACHE_SIZE_UNLIMITED,
    connectFirestoreEmulator,
    initializeFirestore,
    persistentLocalCache,
    persistentSingleTabManager,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

export const NODE_DEV = import.meta.env.VITE_DEV_MODE == "true" || import.meta.env.DEV;
export const isDevMode = NODE_DEV && import.meta.env.VITE_FIREBASE_DISABLE_EMULATOR != "true";

export const firebaseApp = initializeApp({
    apiKey: import.meta.env.VITE_FIREBASE_apiKey,
    authDomain: import.meta.env.VITE_FIREBASE_authDomain,
    databaseURL: import.meta.env.VITE_FIREBASE_databaseURL,
    projectId: import.meta.env.VITE_FIREBASE_projectId,
    storageBucket: import.meta.env.VITE_FIREBASE_storageBucket,
    messagingSenderId: import.meta.env.VITE_FIREBASE_messagingSenderId,
    appId: import.meta.env.VITE_FIREBASE_appId,
    measurementId: import.meta.env.VITE_FIREBASE_measurementId,
});

// if (import.meta.env.VITE_FIREBASE_appcheck_client_key) {
//     const appCheck = firebase.appCheck();
//     appCheck.activate(import.meta.env.VITE_FIREBASE_appcheck_client_key, true);
// }
export let analytics: Analytics | null = null;
if (import.meta.env.VITE_FIREBASE_measurementId) {
    analytics = getAnalytics(firebaseApp);
}

export const firestore = initializeFirestore(firebaseApp, {
    ignoreUndefinedProperties: true,
    localCache: persistentLocalCache({
        tabManager: persistentSingleTabManager({ forceOwnership: true }),
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    }),
});
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const functionsEurope = getFunctions(firebaseApp, "europe-west3");
export const functionsEuropeNorth = getFunctions(firebaseApp, "europe-north1");
export const storage = getStorage(firebaseApp);

if (isDevMode) {
    //@ts-ignore
    window.global ||= window;
    const emulator = import.meta.env.VITE_FIREBASE_EMULATOR_HOST || "emulator";
    console.log("Using emulator with host", emulator);

    connectAuthEmulator(auth, `http://${emulator}:9099`);
    connectFirestoreEmulator(firestore, `${emulator}`, 8080);
    connectFunctionsEmulator(functions, `${emulator}`, 7090);
    connectFunctionsEmulator(functionsEuropeNorth, `${emulator}`, 7090);
    connectFunctionsEmulator(functionsEurope, `${emulator}`, 7090);
    connectStorageEmulator(storage, `${emulator}`, 7080);
}

console.log("Running Publizm version " + import.meta.env.VITE_VERSION);
if (isDevMode) {
    console.log("Firebase running in development mode with emulator");
} else if (import.meta.env.DEV) {
    console.log("Firebase running in development mode without emulator");
} else {
    console.log("Firebase running in production mode");
}

export default firebaseApp;
