import PzTable from "components/table/PzTable";
import { RowData } from "components/table/TableBody";
import { Column } from "components/trunx";
import React, { ReactElement, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";

import { AppPath } from "../../../common/AppPath";
import CollapsibleExpandButton from "../../../components/dashboard/CollapsibleExpandButton";
import DashboardWrittenWorkState from "../../../components/dashboard/DashboardWrittenWorkState";
import OpenFileLink from "../../../components/dashboard/OpenFileLink";
import WrittenWorksPanelCollapsible from "../../../components/dashboard/WrittenWorkCollapsbile";
import PzButton from "../../../components/form/PzButton";
import { ColumnData } from "../../../components/table/TableHead";
import { formatDate } from "../../../components/utils/DateUtils";
import { WrittenWorkData } from "../../../services/firestore/types";

interface WrittenWorksTableProps {
    writtenWorkDataList: WrittenWorkData[];
    updateWrittenWorks: () => void;
}
export default function AuthorDashboardTable({ writtenWorkDataList }: WrittenWorksTableProps): ReactElement {
    const [renderWrittenWorkDataList, setRenderWrittenWorkDataList] = useState<WrittenWorkData[]>(writtenWorkDataList);
    const navigate = useNavigate();

    useEffect(() => {
        setRenderWrittenWorkDataList(writtenWorkDataList);
    }, [writtenWorkDataList]);

    const columns: ColumnData[] = [
        {
            label: "Tittel",
            sortByProperty: "writtenWork.title",
        },
        {
            label: "Dato levert",
            sortByProperty: "writtenWork.uploadDate",
        },
        {
            label: "Status",
            sortByProperty: "writtenWork.state",
        },
        {
            label: "Fillenke",
        },
        {
            label: "",
        },
    ];

    function createRow(
        writtenWorkData: WrittenWorkData,
        toggleCollapsible?: () => void,
        isOpenCollapsible?: boolean
    ): RowData {
        return {
            collapsibleComponent: <WrittenWorksPanelCollapsible writtenWorkData={writtenWorkData} />,
            components: [
                {
                    content: writtenWorkData.writtenWork.title ?? "",
                    width: "34%",
                },
                {
                    content: formatDate(writtenWorkData.writtenWork.uploadDate) ?? "-",
                    width: "10%",
                },
                {
                    content: <DashboardWrittenWorkState writtenWorkData={writtenWorkData} />,
                    width: "16%",
                },
                {
                    content: <OpenFileLink isLink filePath={writtenWorkData.writtenWork.filePath} />,
                    width: "5%",
                },
                {
                    content: <CollapsibleExpandButton isOpen={isOpenCollapsible} onClick={toggleCollapsible} />,
                    width: "1%",
                },
            ],
        };
    }

    return (
        <>
            <Column isFlex isJustifyContentEnd>
                <PzButton isPrimary pr6 pl6 onClick={() => navigate(AppPath.SUBMIT_WORK)}>
                    Send manus
                </PzButton>
            </Column>
            <PzTable
                createRow={createRow}
                data={renderWrittenWorkDataList}
                columns={columns}
                rowKeyGenerator={(data) => data.documentId}
                defaultSortIndex={1}
            />
        </>
    );
}
