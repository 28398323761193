import { ReactElement, useEffect, useState } from "react";
import { WrittenWorkState } from "shared";

import { AdminDashboardMenu } from "../../../components/dashboard/Menu/DashboardUserMenu";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useDashboard } from "../../../contexts/DashboardContext";
import { UserInfoData, WrittenWorkData } from "../../../services/firestore/types";
import { UserService } from "../../../services/firestore/UserService";
import { WrittenWorkService } from "../../../services/firestore/WrittenWorkService";
import AdminDashboardTable from "./AdminDashboardTable";
export default function AdminDashboard(): ReactElement {
    const { selectedMenu } = useDashboard();
    const [loading, setLoading] = useState<boolean>(true);
    const [editors, setEditors] = useState<UserInfoData[]>([]);
    const [writtenWorkDataList, setWrittenWorkDataList] = useState<WrittenWorkData[]>([]);
    const [archivedWrittenWorkDatalist, setArchivedWrittenWorkDatalist] = useState<WrittenWorkData[]>([]);

    function getWrittenWorksDataListByType(state: WrittenWorkState[] = []) {
        return writtenWorkDataList.filter((data) =>
            state.length === 0 ? true : state.includes(data.writtenWork.state)
        );
    }

    function renderContent() {
        if (loading || !selectedMenu) {
            return <LoadingIndicator />;
        }

        const viewedWrittenWorkDataListe = getWrittenWorksDataListByType();

        function renderTable(writtenWorkDataList: WrittenWorkData[]) {
            return (
                <>
                    <AdminDashboardTable
                        key={selectedMenu}
                        writtenWorkDataList={writtenWorkDataList}
                        editors={editors}
                        updateWrittenWorks={loadInitialData}
                    />
                </>
            );
        }

        switch (selectedMenu) {
            case AdminDashboardMenu.ALL:
                return renderTable(viewedWrittenWorkDataListe);
            case AdminDashboardMenu.DELIVERED:
                return renderTable(getWrittenWorksDataListByType([WrittenWorkState.DELIVERED]));
            case AdminDashboardMenu.UNDER_ASSESSMENT:
                return renderTable(
                    getWrittenWorksDataListByType([WrittenWorkState.UNDER_ASSESSMENT, WrittenWorkState.ASSIGNED])
                );
            case AdminDashboardMenu.ASSESSED: {
                return renderTable(getWrittenWorksDataListByType([WrittenWorkState.ASSESSED]));
            }
            case AdminDashboardMenu.ARCHIVE:
                loadArchivedData();
                return renderTable(archivedWrittenWorkDatalist);
            default:
                return renderTable(viewedWrittenWorkDataListe);
        }
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    async function loadInitialData() {
        await new UserService().getAllEditors().then(setEditors);
        await new WrittenWorkService().getAllDataNotArchived().then(setWrittenWorkDataList);
        setLoading(false);
    }

    async function loadArchivedData() {
        await new WrittenWorkService().getAllDataArchived().then(setArchivedWrittenWorkDatalist);
    }

    return renderContent();
}
