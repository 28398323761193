import {
    Assessment,
    AssessmentVersionMapper,
    FilePath,
    FirestoreCollection,
    removeNullOrUndefinedKeys,
    WrittenWork,
    WrittenWorkEvent,
    WrittenWorkState,
    WrittenWorkVersionMapper,
} from "shared";

import { auth } from "../../firebase";
import { FirestoreService } from "./FirestoreService";
import { AssessmentData, DocumentData, UserInfoData, WrittenWorkData } from "./types";
import { UserService } from "./UserService";

const statesNotAssignedToEditor = [WrittenWorkState.DRAFT, WrittenWorkState.DELIVERED];

export class WrittenWorkService extends FirestoreService<WrittenWork> {
    constructor() {
        super(FirestoreCollection.WRITTEN_WORK, new WrittenWorkVersionMapper());
    }

    getUserId(): string {
        const firebaseUser = auth.currentUser;
        if (firebaseUser) {
            return firebaseUser.uid;
        }
        return "";
    }

    getWrittenWorkDataByDocId(documentId: string): Promise<WrittenWorkData | null> {
        return this.getDocument(documentId).then((data) => {
            if (data) {
                return this.mapWrittenWorkData(data);
            } else {
                return null;
            }
        });
    }

    getFirstLoggedinUserDataWithState(state: WrittenWorkState): Promise<WrittenWorkData | null> {
        return this.multiQuery([
            { fieldPath: "state", opStr: "==", value: state },
            { fieldPath: "authorId", opStr: "==", value: this.getUserId() },
        ]).then((data) => {
            if (data.length === 0) {
                return null;
            }
            return this.mapWrittenWorkData(data[0]);
        });
    }

    getAllLoggedinUserDataWithState(state: WrittenWorkState): Promise<WrittenWorkData[]> {
        return this.multiQuery([
            { fieldPath: "state", opStr: "==", value: state },
            { fieldPath: "authorId", opStr: "==", value: this.getUserId() },
        ]).then((docDataList) => docDataList.map(this.mapWrittenWorkData));
    }

    getAllLoggedInUserData(): Promise<WrittenWorkData[]> {
        return this.query("authorId", "==", this.getUserId()).then((docDataList) =>
            docDataList.map(this.mapWrittenWorkData)
        );
    }

    getAllDataWithState(state: WrittenWorkState): Promise<WrittenWorkData[]> {
        return this.query("state", "==", state).then((docDataList) => docDataList.map(this.mapWrittenWorkData));
    }

    getAllDataNotArchived(): Promise<WrittenWorkData[]> {
        return this.query("state", "not-in", [
            WrittenWorkState.ACCEPTED,
            WrittenWorkState.REFUSED,
        ]).then((docDataList) => docDataList.map(this.mapWrittenWorkData));
    }

    getAllDataArchived(): Promise<WrittenWorkData[]> {
        return this.query("state", "in", [WrittenWorkState.ACCEPTED, WrittenWorkState.REFUSED]).then((docDataList) =>
            docDataList.map(this.mapWrittenWorkData)
        );
    }

    getEditorData(editorUID: string): Promise<WrittenWorkData[]> {
        return this.query("assignedToEditor.userId", "==", editorUID).then((docDataList) =>
            docDataList.map(this.mapWrittenWorkData)
        );
    }

    getAllData(): Promise<WrittenWorkData[]> {
        return this.all().then((docDataList) => docDataList.map(this.mapWrittenWorkData));
    }

    updateWrittenWork(data: Partial<WrittenWork>, documentId?: string): Promise<FilePath> {
        return this.update(removeNullOrUndefinedKeys(data), documentId);
    }

    async changeWrittenWorkState(writtenWorkData: WrittenWorkData, toState: WrittenWorkState): Promise<void> {
        const fromState = writtenWorkData.writtenWork.state;
        const editorId = writtenWorkData.writtenWork.assignedToEditor?.userId;
        const updatedWrittenWork: Partial<WrittenWork> = {
            state: toState,
        };
        if (!WrittenWorkHelper.isAllowedStateChange(writtenWorkData.writtenWork, toState)) {
            throw new Error(`Not allowed to change state from ${writtenWorkData.writtenWork.state} to ${toState}`);
        }

        if (editorId && WrittenWorkHelper.isStateChangeFromAssignedToNotAssigned(fromState, toState)) {
            this.deleteField("assignedToEditor", writtenWorkData.documentId);
            await new UserService().decrementEditorAssigments(editorId);
        }

        await new WrittenWorkService().updateWrittenWork(updatedWrittenWork, writtenWorkData.documentId);
    }

    async assignWrittenWork(
        documentId: string,
        editor: UserInfoData,
        state: WrittenWorkState = WrittenWorkState.ASSIGNED
    ): Promise<void> {
        const updatedWrittenWork: Partial<WrittenWork> = {
            assignedToEditor: {
                userId: editor.id,
                email: editor.userInfo.email,
                name: editor.name,
                telephoneNumber: editor.userInfo.telephoneNumber,
            },
            state,
        };
        await new WrittenWorkService().updateWrittenWork(updatedWrittenWork, documentId);
        await new UserService().incrementEditorAssigments(editor.id);
    }

    mapWrittenWorkData(docData: DocumentData<WrittenWork>): WrittenWorkData {
        const writtenWorkEvent = WrittenWorkEvent.get(docData.data.writtenWorkEventId || "");
        return {
            writtenWork: docData.data,
            documentId: docData.id,
            ...(writtenWorkEvent && { writtenWorkEvent: writtenWorkEvent }),
        };
    }

    // Subcollection
    static Assessment = class extends FirestoreService<Assessment> {
        constructor(writtenWorkId: string) {
            super(`${FirestoreCollection.WRITTEN_WORK}/${writtenWorkId}/assessment`, new AssessmentVersionMapper());
        }

        exists(): Promise<boolean> {
            return this.all().then((data) => data.length > 0);
        }

        getFirst(): Promise<AssessmentData | null> {
            return this.all().then((data) => {
                if (data.length === 0) {
                    return null;
                }
                return {
                    assessment: data[0].data,
                    documentId: data[0].id,
                };
            });
        }

        updateAssessment(data: Partial<Assessment>, documentId?: string): Promise<FilePath> {
            if (!documentId) {
                return this.update(data);
            }
            return this.update(removeNullOrUndefinedKeys(data), documentId);
        }

        incrementOpenedQuantity(documentId: string): Promise<FilePath> {
            return this.increment("openedQuantity", documentId);
        }
    };
}

export class WrittenWorkHelper {
    static isAllowedStateChange(writtenWork: WrittenWork, toState: WrittenWorkState): boolean {
        const fromState = writtenWork.state;
        if (
            statesNotAssignedToEditor.includes(fromState) &&
            ![...statesNotAssignedToEditor, WrittenWorkState.REFUSED].includes(toState)
        ) {
            return false;
        }

        if (
            ![...statesNotAssignedToEditor, WrittenWorkState.REFUSED].includes(toState) &&
            !writtenWork.assignedToEditor
        ) {
            return false;
        }
        return true;
    }

    static isStateChangeFromAssignedToNotAssigned(fromState: WrittenWorkState, toState: WrittenWorkState): boolean {
        return !statesNotAssignedToEditor.includes(fromState) && statesNotAssignedToEditor.includes(toState);
    }
}
