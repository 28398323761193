import React, { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import PzButton from "../../../../../components/form/PzButton";
import PzSelect2 from "../../../../../components/PzSelect2";
import { OptionProps } from "../../../../../components/PzSelectDropdown";
import { UserService } from "../../../../../services/firestore/UserService";
import { LicsenseeFormProps } from "./LicenseeEditorPage";

const defaultValue = { label: "Velg en bruker", value: null, isDefault: true };
export default function UserSelect() {
    const [userOptions, setUserOptions] = useState<OptionProps<string | null>[]>([]);
    const { register, setValue, control } = useFormContext<LicsenseeFormProps>();
    const connectedUserId = useWatch({ name: "connectedUserId" });
    useEffect(() => {
        register("connectedUserId");
        new UserService().getAllUsers().then((users) => {
            let options: OptionProps<string | null>[] = [
                defaultValue,
                ...users.map((user) => ({
                    label: user.name,
                    value: user.id,
                })),
            ];

            options = options.map((option) => {
                if (option.value === connectedUserId) option.isDefault = true;
                return option;
            });
            setUserOptions(options);
        });
    }, []);

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                gap: "10px",
                justifyContent: "space-between",
            }}
        >
            <Controller
                control={control}
                name="connectedUserId"
                render={({ onChange, value, name, ref }) => (
                    <PzSelect2
                        title="Knytt til bruker"
                        name={name}
                        style={{ width: "100%" }}
                        searchable
                        options={userOptions}
                        //@ts-ignore
                        onChanged={(option) => {
                            onChange(option?.value);
                            console.log(option);
                        }}
                        selectedOption={userOptions.find((v) => v.value == value)}
                        selectRef={(r) => (ref.current = r)}
                    ></PzSelect2>
                )}
            ></Controller>
            <PzButton type="button" value="Slett" isDanger onClick={() => setValue("connectedUserId", null)}>
                Slett
            </PzButton>
        </div>
    );
}
