import { SbBlokKeyDataTypes, storyblokEditable } from "@storyblok/react";
import { PropsWithChildren } from "react";
import { ISbComponentType } from "storyblok-js-client/dist/types/interfaces";
export interface StoryblokBlokProps<T> {
    blok: CustomSbBlokData & T;
}

export interface CustomSbBlokData extends ISbComponentType<string> {
    [index: string]: SbBlokKeyDataTypes;
}

export default function SbEditableContainer<T>({ blok, children }: PropsWithChildren<StoryblokBlokProps<T>>) {
    return (
        <div {...storyblokEditable(blok)} key={blok._uid as string} className="sbcontent">
            {children}
        </div>
    );
}
