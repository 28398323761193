import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import { UserInfo, UserRole } from "shared";

export function SentryTransactionId(): string {
    const transactionId = Math.random().toString(36).substr(2, 9);
    configureScope("transaction_id", transactionId);
    return transactionId;
}

export function SentrySetUserLoggedInAndRole(
    userInfo: Partial<UserInfo> | undefined | null,
    user?: User | null,
    activeRole?: UserRole | undefined
): void {
    Sentry.setTag("user_logged_in", user != null);
    Sentry.setTag("user_roles", userInfo?.roles?.toString());
    Sentry.setTag("user_active_role", activeRole);

    if (user?.email != null) {
        Sentry.setUser({
            email: user.email,
            roles: userInfo?.roles?.toString(),
            activeRole: activeRole,
            name: userInfo?.name,
            user_id: userInfo?.id,
            creationTime: user?.metadata?.creationTime,
            lastSignInTime: user?.metadata?.lastSignInTime,
        });
    } else {
        Sentry.setUser({ name: "unauthenticated" });
    }
}

export function configureScope(id: string, value: any): void {
    Sentry.configureScope((scope) => {
        scope.setTag(id, value);
    });
}

export function captureMessage(msg: string): void {
    Sentry.captureMessage(msg);
}
