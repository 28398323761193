import "./TerningkastIcon.css";

import { BookReview } from "shared";

import terningkast5 from "../../assets/Terningkast 5.png";
import terningkast6 from "../../assets/Terningkast 6.png";
type TerningkastIkonProps = {
    bookReviews?: BookReview[];
    top?: string;
    right?: string;
};
export default function TerningkastIkon({ bookReviews, top, right }: TerningkastIkonProps) {
    const bookReviewsWithTerningkast = bookReviews?.filter((review) => review.ratingType == "Terningkast");
    if (bookReviewsWithTerningkast && bookReviewsWithTerningkast.length > 0) {
        const review = bookReviewsWithTerningkast.reduce((prev, current) =>
            // @ts-ignore
            prev.rating > current.rating ? prev : current
        );
        const rating = parseInt(review.rating ?? "0");
        if (rating >= 5) {
            return (
                <div
                    className="terningkast"
                    style={{
                        top,
                        right,
                        backgroundImage: `url('${rating == 5 ? terningkast5 : terningkast6}')`,
                    }}
                ></div>
            );
        }
    }
    return null;
}
