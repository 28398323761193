import { Column, Columns } from "components/trunx";
import React, { ReactElement, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BrregService } from "services/external/BrregService";

import PzFormInputField from "../../../../components/form/PzFormInputField";
import { validateKontonummerMod11 } from "../../../../components/utils/AccountNumberUtils";
import { SignUpProps } from "./SignUpEditorPanel";

export function SignupEditorCompanyInfo(): ReactElement {
    const { register, errors, setError, setValue, clearErrors } = useFormContext<SignUpProps>();
    const [isOrgLoading, setIsOrgLoading] = useState<boolean>(false);

    async function onChangeCompanyId(event: React.ChangeEvent<HTMLInputElement>) {
        setValue("companyName", undefined);
        clearErrors("companyId");
        const companyId = event.target.value;

        if (!companyId) {
            return;
        }

        setIsOrgLoading(true);
        const company = await BrregService.getCompanyById(companyId);
        setIsOrgLoading(false);

        if (company?.navn) {
            setValue("companyName", company.navn);
            return true;
        }

        setError("companyId", { message: `Fant ingen organsisasjon med orgnr ${companyId}` });
    }

    function validateCompanyId(companyId: number) {
        if (!companyId) {
            return true;
        }

        if (errors.companyId) {
            return false;
        }
    }

    function validateAccountNumber(accountNumber: number) {
        if (!accountNumber) {
            return true;
        }
        if (!validateKontonummerMod11(String(accountNumber))) {
            return "Ugyldig kontonummer";
        }
        return true;
    }

    return (
        <>
            <Columns>
                <Column>
                    <PzFormInputField
                        label="Organisasjonsnummer"
                        name="companyId"
                        type="number"
                        controlProps={{ isLoading: isOrgLoading }}
                        formError={errors.companyId}
                        onBlur={onChangeCompanyId}
                        inputRef={register({ valueAsNumber: true, validate: validateCompanyId })}
                    />
                </Column>
                <Column>
                    <PzFormInputField
                        fieldProps={{
                            className: "is-flex-grow-1",
                        }}
                        style={{ textOverflow: "ellipsis" }}
                        disabled
                        placeholder="Fyll ut organisasjonsnummer"
                        label="Selskap"
                        name="companyName"
                        formError={errors.companyName}
                        inputRef={register}
                    />
                </Column>
            </Columns>
            <Columns>
                <Column is6>
                    <PzFormInputField
                        label="Kontonummer"
                        name="accountnumber"
                        type="number"
                        formError={errors.accountnumber}
                        inputRef={register({
                            valueAsNumber: true,
                            validate: validateAccountNumber,
                        })}
                    />
                </Column>
            </Columns>
        </>
    );
}
