import "./PzSection.css";

import React, { PropsWithChildren } from "react";

interface PzSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    isFullWidth?: boolean;
    isFullHeightTablet?: boolean;
}

export default function PzSection(props: PropsWithChildren<PzSectionProps>) {
    const { isFullWidth = true, isFullHeightTablet = false, children, ...otherProps } = props;
    return (
        <section
            className={`pz-section ${isFullWidth && "pz-section-full-width"} ${
                isFullHeightTablet && "full-height-tablet"
            }`}
            {...otherProps}
        >
            {children}
        </section>
    );
}
