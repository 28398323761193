import { Column } from "components/trunx";
import { ReactElement, useState } from "react";

interface PzExpandableTextProps {
    text?: string;
}
export default function PzExpandableText({ text }: PzExpandableTextProps): ReactElement {
    const [expanded, setExpanded] = useState<boolean>(false);
    const maxTextLength = 200;
    const expandable = text && text.length > maxTextLength;
    function getText() {
        if (!text) {
            return null;
        }

        if (expanded || !expandable) {
            return text;
        }

        return text.substring(0, maxTextLength) + "...";
    }
    return (
        <Column p0>
            <div style={{ maxWidth: "40em" }}>
                <>
                    {getText()}
                    {expandable && (
                        <a style={{ marginLeft: "5px" }} onClick={() => setExpanded(!expanded)}>
                            {expanded ? "Les mindre" : "Les mer"}
                        </a>
                    )}
                </>
            </div>
        </Column>
    );
}
