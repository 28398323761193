import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import {
    addDoc,
    collection,
    doc,
    DocumentData,
    DocumentReference,
    DocumentSnapshot,
    FirestoreError,
    getDoc,
    getDocFromCache,
    getDocs,
    getDocsFromCache,
    limit,
    onSnapshot,
    orderBy,
    Query,
    query,
    QueryConstraint,
    QuerySnapshot,
    QueryStartAtConstraint,
    setDoc,
    updateDoc,
    WhereFilterOp,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { BookType, FirestoreCollection } from "shared";

import { analytics, firestore } from "../../../firebase";

export type QueryReturnType<T> = UseQueryResult<T>;
export interface WhereQuery {
    fieldPath: string;
    opStr: WhereFilterOp;
    value: any;
}

export const FirestoreApi = {
    add: async (firestoreCollection: FirestoreCollection, data: any, documentId: string) => {
        return await setDoc(doc(FirestoreApi.getCollectionRef(firestoreCollection), documentId), data);
    },
    mutate: async (firestoreCollection: FirestoreCollection, data: any, documentId?: string) => {
        if (documentId) {
            const ref = doc(firestore, firestoreCollection, documentId);
            return await updateDoc(ref, data);
        }
        return await addDoc(FirestoreApi.getCollectionRef(firestoreCollection), data);
    },

    getSingleDocRef: (firestoreCollection: FirestoreCollection, documentId?: string) => {
        if (documentId) {
            return doc(FirestoreApi.getCollectionRef(firestoreCollection), documentId);
        }
        return doc(FirestoreApi.getCollectionRef(firestoreCollection), firestoreCollection);
    },
    getCollectionRef: (firestoreCollection: FirestoreCollection) => {
        return collection(firestore, firestoreCollection);
    },

    getQueryFor: (firestoreCollection: FirestoreCollection, ...queries: QueryConstraint[]) => {
        return query(FirestoreApi.getCollectionRef(firestoreCollection), ...queries);
    },

    getDocsForQuery: (query: Query<DocumentData>, fromCache?: boolean) => {
        if (fromCache) {
            return getDocsFromCache(query);
        }
        return getDocs(query);
    },

    getSingleDoc: async (firestoreCollection: FirestoreCollection, documentId: string, fromCache?: boolean) => {
        if (fromCache) {
            return getDocFromCache(FirestoreApi.getSingleDocRef(firestoreCollection, documentId));
        }
        return getDoc(FirestoreApi.getSingleDocRef(firestoreCollection, documentId));
    },
    orderByLimitQuery: (
        ref: Query,
        orderByValue: string,
        limitBy: number,
        ...constraints: QueryStartAtConstraint[]
    ) => {
        return query(ref, orderBy(orderByValue), limit(limitBy), ...constraints);
    },

    orderByLimitQueryDesc: (
        ref: Query,
        orderByValue: string,
        limitBy: number,
        ...constraints: QueryStartAtConstraint[]
    ) => {
        return query(ref, orderBy(orderByValue, "desc"), limit(limitBy), ...constraints);
    },
};

export const AnalyticsApi = {
    logEvent: (
        metricName: string,
        params?: {
            [key: string]: any;
        }
    ): void => {
        console.debug("logEvent", metricName, params);
        analytics && logEvent(analytics, metricName, params);
    },
    logPageView: (page_title: string): void => {
        console.debug("logPageView", page_title);
        analytics && logEvent(analytics, "page_view", { page_title: page_title });
    },
    logSearch: (search_term: string): void => {
        console.debug("logSearch", search_term);
        analytics &&
            logEvent(analytics, "search", {
                search_term,
            });
    },
    logViewItem: (item_id: string, title: string, bookType: BookType): void => {
        console.debug("logViewItem", item_id, title, bookType);
        analytics &&
            logEvent(analytics, "view_item", {
                title,
                item_id,
                items: [{ item_id, item_name: title, item_category: bookType }],
            });
    },
    logBeginCheckout: (value: number, itemId: string, title: string, bookType: BookType): void => {
        console.debug("logBeginCheckout", value, itemId, title, bookType);
        analytics &&
            logEvent(analytics, "begin_checkout" as string, {
                currency: "NOK",
                value,
                items: [{ item_id: itemId, price: value, item_name: title, item_category: bookType }],
            });
    },
    logPurchaseButtonClicked: (value?: number | string, itemId?: string, title?: string, bookType?: BookType): void => {
        console.debug("logPurchaseButtonClicked", value, itemId, title, bookType);
        const priceAsNumber = value ? (typeof value === "string" ? parseInt(value) : value) : 0;
        analytics &&
            logEvent(analytics, "add_to_cart" as string, {
                currency: "NOK",
                value: priceAsNumber,
                items: [{ item_id: itemId, price: priceAsNumber, item_name: title, item_category: bookType }],
            });
    },
    logCheckoutCanceled: (value?: number | string, itemId?: string, title?: string, bookType?: BookType): void => {
        console.debug("cancelCheckout", value, itemId, title, bookType);
        const priceAsNumber = value ? (typeof value === "string" ? parseInt(value) : value) : 0;
        analytics &&
            logEvent(analytics, "remove_from_cart" as string, {
                currency: "NOK",
                value: priceAsNumber,
                items: [{ item_id: itemId, price: priceAsNumber, item_name: title, item_category: bookType }],
            });
    },
    logPurchase: (
        value: number,
        itemId?: string,
        title?: string,
        transaction_id?: string,
        bookType?: BookType
    ): void => {
        console.debug("logPurchase", value, itemId, title, transaction_id, bookType);
        analytics &&
            logEvent(analytics, "purchase" as string, {
                currency: "NOK",
                value,
                content_type: bookType,
                transaction_id,
                items: [{ item_id: itemId, price: value, item_name: title, item_category: bookType }],
            });
    },
    logScreenViewEvent: (screenName: string): void => {
        console.debug("logScreenViewEvent", screenName);
        AnalyticsApi.logEvent("screen_view", {
            firebase_screen: screenName,
        });
    },
    saveUserId: (userId?: string): void => {
        if (analytics && userId) {
            console.debug("setUserId", "setUserProperties", userId);
            setUserId(analytics, userId, { global: true });
            setUserProperties(analytics, { userId: userId });
        }
    },
};
export function useFirestoreDocument2<T = DocumentData, R = DocumentSnapshot<T>>(
    keyName: QueryKey,
    docRef: DocumentReference<T>,
    subscribe?: boolean,
    useQueryOptions?: Omit<UseQueryOptions<DocumentSnapshot<T>, FirestoreError, R>, "queryFn">
): UseQueryResult {
    const query = useQuery(
        keyName,
        async () => {
            const docSnap = await getDoc(docRef);
            return docSnap;
        },
        useQueryOptions
    );

    useEffect(() => {
        if (subscribe) {
            const unsub = onSnapshot(docRef, { includeMetadataChanges: true }, (doc) => {
                query.refetch();
            });

            return () => unsub();
        }
    }, [subscribe]);
    return query;
}

export function mapDocResult<T>() {
    return (data: DocumentSnapshot<DocumentData>): T | null => {
        if (data.exists()) {
            return {
                ...(data.data() as T),
                id: data.id,
                path: data.ref.path,
            };
        }
        return null;
    };
}
export function mapQueryResult<T>() {
    return React.useCallback((data: QuerySnapshot<DocumentData>): T[] => {
        if (data.empty) {
            return [];
        }
        return data.docs.map((doc) => ({
            ...(doc.data() as T),
            id: doc.id,
            path: doc.ref.path,
        }));
    }, []);
}

export function mapFirstQueryResult<T>() {
    return React.useCallback((data: QuerySnapshot<DocumentData>): T | null => {
        if (data.empty) {
            return null;
        }
        return data.docs.map((doc) => ({
            ...(doc.data() as T),
            id: doc.id,
            path: doc.ref.path,
        }))[0];
    }, []);
}
