import PageTemplate from "components/pagetemplate/PageTemplate";
import { Container } from "components/trunx";

import Articles from "./Articles";

export default function ArticlesPage() {
    return (
        <PageTemplate>
            <Container>
                <Articles />
            </Container>
        </PageTemplate>
    );
}
