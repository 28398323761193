import PzButton from "components/form/PzButton";
import PzExpandableText from "components/PzExpandableText";
import { Card, Column } from "components/trunx";
import { formatDate } from "components/utils/DateUtils";
import { useNavigate } from "react-router";
import { UrlUtils } from "shared";

import { AppPath } from "../../../../../common/AppPath";
import useKeyPress from "../../../../../common/hooks/useKeyPress";
import PzDataList from "../../../../../components/PzDataList";
import PzDatListItem from "../../../../../components/PzDataListItem";
import { PzTitle } from "../../../../../components/text/PzTitle";
import useBookApi from "../../../../../services/firestore/hooks/useBookApi";
import BookGroupButton from "./BookGroupButton";

interface BookCollapsibleProps {
    id: string;
}

export function BookCollapsible({ id }: BookCollapsibleProps) {
    const navigate = useNavigate();
    const ctrlPressed = useKeyPress("Control");
    const { data: book, isLoading } = useBookApi().bookById(id, true);

    function open(url: string): void {
        if (ctrlPressed) {
            window.open(url);
        } else {
            navigate(url);
        }
    }

    if (!book) {
        return null;
    }

    const previewPath = UrlUtils.encodeBookUrlForBook(book, AppPath.BOOK_DETAILS, true);
    const editPath = `${AppPath.BOOK_MANAGEMENT}?id=${id}`;
    return (
        <Card
            style={{ height: "auto" }}
            p0
            m0
            isFlexTablet
            isFlexDesktop
            isFlexWidescreen
            isFlexDirectionRow
            isJustifyContentSpaceBetween
        >
            <Column pl2 ml2>
                <PzTitle isSize5>Metadata</PzTitle>
                <Column
                    isFlexGrow0
                    m0
                    p0
                    isSize7
                    isJustifyContentSpaceBetween
                    style={{ width: "max-content", gap: "30px" }}
                >
                    <PzDataList rows={4}>
                        <PzDatListItem label={"Sjanger"}>{book.tags?.join(", ")}</PzDatListItem>
                        <PzDatListItem label={"Opprettet dato"}>{formatDate(book.createdDate)}</PzDatListItem>
                        <PzDatListItem label={"Publisert første gang"}>{book.publishedYear}</PzDatListItem>
                        <PzDatListItem label={"Utgiver"}>{book.publisher}</PzDatListItem>
                        <PzDatListItem label={"Sammendrag"}>
                            <PzExpandableText text={book.summary} />
                        </PzDatListItem>
                        <PzDatListItem label={"Grupper"}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                                <div>{book.groups?.join(", ")}</div>
                                <BookGroupButton book={book} bookId={id} />
                            </div>
                        </PzDatListItem>
                    </PzDataList>
                </Column>
            </Column>
            <Column>
                <Column isFlex isJustifyContentCenter style={{ gap: "10px" }}>
                    <PzButton onClick={() => open(previewPath)}>Forhåndsvis tittel</PzButton>
                    <PzButton onClick={() => open(editPath)} isPrimary style={{ width: "200px" }}>
                        Rediger tittel
                    </PzButton>
                </Column>
            </Column>
        </Card>
    );
}
