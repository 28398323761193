import React, { ReactElement } from "react";

import { FirebaseUploadFile } from "../../services/storage/types";
import { FileUtils } from "../utils/FileUtils";
interface FileListViewProps {
    onDeleteFile: (file: FirebaseUploadFile) => void;
    files: FirebaseUploadFile[];
}
export default function FileTagView({ files, onDeleteFile }: FileListViewProps): ReactElement {
    const deleteFile = (file: FirebaseUploadFile) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onDeleteFile(file);
    };

    return (
        <div>
            {files.map((file) => (
                <div key={file.name} style={{ display: "flex", flexDirection: "row" }}>
                    <div className="file-name" style={{ width: "100%", paddingRight: "30px" }}>
                        <span className="file-tag" key={file.name} onClick={() => FileUtils.openFile(file)}>
                            <a>{file.name}</a>
                        </span>
                    </div>
                    <button
                        style={{ alignSelf: "center", right: "25px" }}
                        className="delete is-small"
                        onClick={deleteFile(file)}
                    ></button>
                </div>
            ))}
            {files.length === 0 && <span className="file-name">Ingen fil valgt</span>}
        </div>
    );
}
