import { AgreementDashboardMenu } from "components/dashboard/Menu/DashboardUserMenu";
import LoadingIndicator from "components/LoadingIndicator";
import { useDashboard } from "contexts/DashboardContext";
import { ReactElement, useEffect, useState } from "react";
import { AgreementHelpers, AgreementService } from "services/firestore/AgreementService";
import { AgreementData } from "services/firestore/types";
import { AgreementType } from "shared";

import AgreementTable from "./AgreeementTable";
import AgreementUpdatePage from "./AgreementCreate";

export default function AgreementManagementPage(): ReactElement {
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [currentAgreementData, setCurrentAgreementData] = useState<AgreementData | null>();
    const { selectedMenu, updateSelectedMenu } = useDashboard();

    const [agreementDataList, setAgreementDataList] = useState<AgreementData[]>([]);

    useEffect(() => {
        setCurrentAgreementData(null);
    }, [selectedMenu]);

    function getAgreementDataListByType(agreementType: AgreementType) {
        return agreementDataList.filter((data) => agreementType.includes(data.agreement.agreementType));
    }

    function getLatestAgreementsAllRoles(): AgreementData[] {
        const roleAndAgreements = AgreementHelpers.getAllNewestAgreements(agreementDataList);
        const latestAgreements: AgreementData[] = [];

        roleAndAgreements.forEach(function (agreementDataList) {
            agreementDataList.forEach((agreementData) => {
                if (!latestAgreements.includes(agreementData)) {
                    return latestAgreements.push(agreementData);
                }
            });
        });

        return latestAgreements;
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    async function loadInitialData() {
        setLoading(true);
        await new AgreementService().getAllAgreements().then(setAgreementDataList).catch(setError);
        setLoading(false);
    }

    function renderContent() {
        if (loading) {
            return <LoadingIndicator />;
        }

        function renderTable(agreementDataList: AgreementData[]) {
            return (
                <AgreementTable
                    key="AgreementTable"
                    agreementDataList={agreementDataList}
                    updateAgreementData={loadInitialData}
                    setCurrentAgreementData={setCurrentAgreementData}
                    updateSelectedMenu={updateSelectedMenu}
                />
            );
        }

        switch (selectedMenu) {
            case AgreementDashboardMenu.ALL:
                return renderTable(agreementDataList);
            case AgreementDashboardMenu.NEWEST:
                return renderTable(getLatestAgreementsAllRoles());
            case AgreementDashboardMenu.USER_TERMS:
                return renderTable(getAgreementDataListByType(AgreementType.USER_TERMS));
            case AgreementDashboardMenu.PRIVACY:
                return renderTable(getAgreementDataListByType(AgreementType.PRIVACY));
            case AgreementDashboardMenu.CONFIDENTIALITY:
                return renderTable(getAgreementDataListByType(AgreementType.CONFIDENTIALITY));
            case AgreementDashboardMenu.NEW_AGREEMENT:
                return (
                    <AgreementUpdatePage
                        updateSelectedMenu={updateSelectedMenu}
                        updateAgreementData={loadInitialData}
                        initialAgreement={currentAgreementData?.agreement}
                    />
                );
            default:
                return renderTable(agreementDataList);
        }
    }

    return renderContent();
}
