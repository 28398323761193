import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column, Tag } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { Options } from "react-select";
import { UserRole, userRoleDisplayValue } from "shared";

import PzIconButton from "../../../../components/form/PzIconButton";
import { useAuth } from "../../../../contexts/AuthContext";
import { UserService } from "../../../../services/firestore/UserService";
import { UserTableData } from "./UserAdminDashboardTable";

export interface UserRoleOptionType {
    label: string;
    value: UserRole;
}

interface UserRoleOverviewProps {
    userTableData: UserTableData;
    onUserUpdated: () => void;
}
export default function UserRoleOverview({ userTableData, onUserUpdated }: UserRoleOverviewProps): ReactElement {
    const { userInfo: loggedInUser } = useAuth();
    const [isAddRoleMode, setIsAddRoleMode] = useState<boolean>(false);
    const roleOptions: Options<UserRoleOptionType> = Object.values(UserRole)
        .filter((role) => !userTableData.roles?.includes(role))
        .map((role) => ({
            label: userRoleDisplayValue(role),
            value: role,
        }));

    async function removeUserRole(removeRole: UserRole) {
        const userRoles = userTableData.roles ?? [];
        await new UserService().updateUserInfo(
            { roles: userRoles.filter((role) => removeRole !== role) },
            userTableData.userId
        );
        onUserUpdated();
    }

    async function addUserRole(addRole?: UserRole) {
        if (!addRole) {
            return;
        }
        const userRoles = userTableData.roles ?? [];
        userRoles.push(addRole);
        await new UserService().updateUserInfo({ roles: userRoles }, userTableData.userId);
        onUserUpdated();
    }

    function allowedToDeleteRole(userInfoData: UserTableData, role: UserRole) {
        const hasMoreThanOneRole = userInfoData.roles && userInfoData.roles.length > 1;
        const isLoggedInUser = loggedInUser && userInfoData.userId === loggedInUser.id;
        return (!isLoggedInUser && hasMoreThanOneRole) || (isLoggedInUser && role !== userInfoData.activeRole);
    }

    useEffect(() => {
        roleOptions.length === 0 && setIsAddRoleMode(false);
    }, [roleOptions]);

    const hasRoleOptions = roleOptions.length > 0;

    return (
        <Column>
            <strong>Roller</strong>
            <Column isFlex isFlexDirectionRow isFlexWrapWrap style={{ gap: "5px" }}>
                {userTableData.roles.sort().map((role) => {
                    return (
                        <Tag isNormal isMedium>
                            {userRoleDisplayValue(role)}
                            {allowedToDeleteRole(userTableData, role) && (
                                <button className="delete is-small" onClick={() => removeUserRole(role)}></button>
                            )}
                        </Tag>
                    );
                })}
            </Column>
            {!isAddRoleMode && hasRoleOptions && (
                <PzIconButton icon={faPlus} isSmall iconPlacement="left" onClick={() => setIsAddRoleMode(true)}>
                    Legg til rolle
                </PzIconButton>
            )}
            {isAddRoleMode && (
                <Column p0 m0>
                    <Column isFlex isFlexDirectionRow isFlexWrapWrap style={{ gap: "5px" }}>
                        {roleOptions.map((roleOption) => (
                            <Tag isLink isMedium isClickable onClick={() => addUserRole(roleOption?.value)}>
                                <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
                                {userRoleDisplayValue(roleOption.value)}
                            </Tag>
                        ))}
                    </Column>
                    <PzIconButton icon={faTimes} isSmall iconPlacement="left" onClick={() => setIsAddRoleMode(false)}>
                        Avbryt
                    </PzIconButton>
                </Column>
            )}
        </Column>
    );
}
