import { Control, ControlProps, Field, Label } from "components/trunx";
import { FieldValues } from "react-hook-form";

import PzInputField, { PzInputFieldProps } from "./PzInputField";

interface PzFormInputFieldProps extends Omit<PzInputFieldProps, "error"> {
    formError?: FieldValues;
    fieldProps?: typeof Field.prototype.props;
    controlProps?: ControlProps;
}

function PzFormInputField({
    formError,
    fieldProps,
    controlProps,
    label,
    labelProps,
    ...inputFieldProps
}: PzFormInputFieldProps) {
    return (
        <>
            <Field {...fieldProps}>
                {label && (
                    <Field.Label isNormal>
                        <Label {...labelProps} hasTextLeft={labelProps?.hasTextLeft ?? true}>
                            {label}
                        </Label>
                    </Field.Label>
                )}
                <Control {...controlProps}>
                    <PzInputField {...inputFieldProps} error={formError?.message} />
                </Control>
            </Field>
        </>
    );
}

export default PzFormInputField;
