export enum Currency {
    NOK = "NOK",
}

export enum Language {
    EN = "EN",
    NB = "NB",
    NN = "NN",
    DN = "DN",
}

export function languageDisplayValue(language: Language) {
    switch (language) {
        case Language.NB:
            return "Norsk (Bokmål)";
        case Language.EN:
            return "Engelsk";
        case Language.NN:
            return "Norsk (Nynorsk)";
        default:
            return language;
    }
}
export function dateToYYYYMMDDstring(date: Date | string): string {
    try {
        const datetimestring =
            date instanceof Date ? date.toISOString() : typeof date == "number" ? new Date(date).toISOString() : date;
        const datetimstringsplit = datetimestring.split("T");
        const datestring = datetimstringsplit[0]?.replace(/-/g, "");
        const timestring = datetimstringsplit[1]?.split(".")[0]?.replace(/:/g, "");
        return datestring + timestring;
    } catch (e) {
        console.error(e);
        return "99990101";
    }
}
