import React, { ReactNode } from "react";
import { Environment } from "shared";

export interface FeatureType {
    isActive: () => boolean;
}

export class Feature implements FeatureType {
    public static PAYMENT_ACTIVE = new Feature(() => true);
    public static OFF: Feature = new Feature(() => false);
    public static ON: Feature = new Feature(() => true);
    public static NOT_PRODUCTION: Feature = new Feature(() => !Environment.isProduction(import.meta.env));
    public static UNDER_DEVELOPMENT: Feature = Feature.NOT_PRODUCTION;
    public static RELEASE1_0: Feature = Feature.NOT_PRODUCTION;
    public static ALLOW_PURCHASE: Feature = new Feature(() => false);

    constructor(public isActive: () => boolean) { }
}

type FeatureToggleProps = { feature: FeatureType; children: ReactNode };
type OnOffProp = { isActive?: boolean; children: ReactNode };

export const FeatureToggle = (props: FeatureToggleProps) => {
    let simple = false;
    let complex = false;

    React.Children.forEach(props.children, (child) => {
        // @ts-ignore
        if (child.type && (child.type == On || child.type == Off)) {
            complex = true;
        } else {
            simple = true;
        }
    });

    if (simple && complex) {
        throw Error("Illegal <FeatureToggle>. Cannot have <On><Off> in combination with other elements");
    }
    const featureIsActive = props.feature.isActive && props.feature.isActive();

    if (simple) {
        return <>{featureIsActive && props.children}</>;
    } else {
        const toggledChildren = React.Children.map(props.children, (child) => {
            // @ts-ignore
            return React.cloneElement(child, { isActive: featureIsActive });
        });
        return <>{toggledChildren}</>;
    }
};

export const On = (props: OnOffProp) => <>{props.isActive === true && props.children}</>;
export const Off = (props: OnOffProp) => <>{props.isActive === false && props.children}</>;
