import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import DashboardUserIcon from "components/dashboard/DashboardUserIcon";
import PzSelectDropdown from "components/PzSelectDropdown";
import IconText from "components/text/IconText";
import { Column } from "components/trunx";
import { Link, useNavigate } from "react-router-dom";
import { UserInfoMetadata, UserRole, userRoleDisplayValue, userRoleInternalName } from "shared";

import { AppPath } from "../../common/AppPath";
import { useAuth } from "../../contexts/AuthContext";
import PzButton from "../form/PzButton";
import PzPopover, { Align } from "../popover/PzPopover";

export function UserPopoverMenu(props: { showUserMenu: boolean }) {
    const auth = useAuth();
    const navigate = useNavigate();

    const options = auth.userInfo?.roles?.map(userRoleDisplayValue);

    function logout() {
        auth.logout().then(() => {
            navigate(AppPath.ROOT);
        });
    }

    function changeRole(clickedRole: string) {
        const userRole: UserRole | undefined = userRoleInternalName(clickedRole);
        const parsedRole: UserRole | undefined = Object.values(UserRole).find((role) => {
            return role === userRole;
        });
        navigate(AppPath.DASHBOARD);
        parsedRole && auth.setActiveRole(parsedRole).then(() => navigate(AppPath.DASHBOARD));
    }

    function renderDropdown() {
        if (options !== undefined && options?.length > 1) {
            return (
                <Column hasTextCentered>
                    <PzSelectDropdown
                        hasTextCentered
                        options={options.map((option) => {
                            return {
                                value: option,
                                label: option,
                                isDefault: userRoleInternalName(option) === auth?.activeRole,
                            };
                        })}
                        onChanged={changeRole}
                    />
                </Column>
            );
        }
    }

    const userContactInfo = auth?.userInfo;
    const userInfo = auth.userInfo;
    const activeRole = auth.activeRole;

    return (
        <PzPopover showPopover={props.showUserMenu} align={Align.right}>
            {userInfo && <RenderUserInfo name={userInfo?.name} activeRole={activeRole} />}
            {renderDropdown()}
            {userContactInfo && (
                <UserContactInfo
                    email={userContactInfo?.email ?? ""}
                    telephoneNumber={userContactInfo.telephoneNumber}
                />
            )}
            <Column hasTextCentered>
                <AdministrateProfileButton />
            </Column>
            <Column hasTextCentered>
                <PzButton mt0 isPrimary onClick={logout}>
                    Logg ut
                </PzButton>
            </Column>
        </PzPopover>
    );
}

interface UserPopoverData extends UserInfoMetadata {
    activeRole?: UserRole;
}

function UserContactInfo({ email, telephoneNumber }: Pick<UserPopoverData, "email" | "telephoneNumber">) {
    return (
        <Column pt0>
            <IconText icon={faEnvelope}>
                <a href={`mailto:${email}`}>{email}</a>
            </IconText>
            {telephoneNumber && <IconText icon={faPhone}>{telephoneNumber}</IconText>}
        </Column>
    );
}

function RenderUserInfo({ name, activeRole }: Partial<UserPopoverData>) {
    return (
        <Column isFlex isAlignItemsCenter isFlexDirectionColumn pb0 mb2>
            <DashboardUserIcon />
            <Column pb2>{<strong>{name}</strong>}</Column>
            <Column p0>{userRoleDisplayValue(activeRole)}</Column>
        </Column>
    );
}

function AdministrateProfileButton() {
    return (
        <>
            <Link to="/update-profile">
                <PzButton style={{ fontWeight: 600 }} isRounded>
                    Administrer konto
                </PzButton>
            </Link>
        </>
    );
}
