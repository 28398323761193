import { Timestamp } from "firebase/firestore";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import {
    Book,
    BookType,
    createInitialCustomerBookData,
    Currency,
    CustomerBook,
    CustomerBookVersionMapper,
    FirestoreCollection,
    getActiveRole,
    getBookIdByType,
    removeNullOrUndefinedKeys,
    Transaction,
    TransactionVersionMapper,
    UserId,
    UserInfo,
    UserInfoVersionMapper,
    UserRole,
} from "shared";

import { auth, functions } from "../../firebase";
import { BookAccessDetails } from "../../pages/customer/bookdetails/BookDetailsContext";
import { FilePath } from "../storage/types";
import { FirestoreService } from "./FirestoreService";
import { CustomerBookData, DocumentData, TransactionData, UserInfoData } from "./types";

export class UserService extends FirestoreService<UserInfo> {
    deleteUserFn = httpsCallable(functions, "deleteUser");
    constructor() {
        super(FirestoreCollection.USERS, new UserInfoVersionMapper());
    }

    getAllUsers(): Promise<UserInfoData[]> {
        return this.all().then((userDataList) => {
            if (userDataList) {
                return userDataList.map(this.mapUserInfoToUserInfoData);
            }
            return [];
        });
    }
    getByUserId(userId: UserId): Promise<UserInfoData | undefined> {
        return this.getDocument(userId).then((userData) => {
            if (userData) {
                return this.mapUserInfoToUserInfoData(userData);
            }
        });
    }

    getAllEditors(): Promise<UserInfoData[]> {
        return this.query("roles", "array-contains", UserRole.EDITOR).then((userDataList) => {
            if (userDataList) {
                return userDataList.map(this.mapUserInfoToUserInfoData);
            }
            return [];
        });
    }

    getUserMetadata(uid: string): Promise<UserInfoData | null> {
        return this.getDocument(uid).then((data) => {
            if (data) {
                return this.mapUserInfoToUserInfoData(data);
            }
            return null;
        });
    }

    updateUserInfo(userInfo: Partial<UserInfo>, uid: string): Promise<FilePath> {
        return this.update(userInfo, uid);
    }

    incrementEditorAssigments(editorDocumentId: string): Promise<FilePath> {
        return this.increment("assignments", editorDocumentId);
    }

    decrementEditorAssigments(editorDocumentId: string): Promise<FilePath> {
        return this.increment("assignments", editorDocumentId, -1);
    }

    deleteUser(userUid: string): Promise<void | HttpsCallableResult> {
        return this.deleteUserFn({ userUid }).catch((e) => {
            console.error("Bruker har ikke tilgang til å slette bruker");
            throw e;
        });
    }

    private mapUserInfoToUserInfoData(firestoreUserInfo: DocumentData<UserInfo>): UserInfoData {
        const userInfo = firestoreUserInfo.data;
        return {
            userInfo: userInfo,
            activeRole: getActiveRole(userInfo.roles),
            roles: userInfo.roles,
            name: userInfo.firstname + " " + userInfo.surname,
            id: firestoreUserInfo.id,
        };
    }

    // Subcollection
    static Transaction = class extends FirestoreService<Transaction> {
        constructor(userId?: string) {
            const user = auth.currentUser;
            super(`${FirestoreCollection.USERS}/${userId ?? user?.uid}/transactions`, new TransactionVersionMapper());
        }

        async hasPurchasedBook(bookId: string, bookType?: BookType) {
            if (bookType) {
                return this.hasPurchasedBookByType(bookId, bookType);
            }

            const bookByType = await this.multiQuery([
                {
                    fieldPath: "bookId",
                    opStr: "==",
                    value: bookId,
                },
            ]);

            return bookByType.length > 0;
        }

        async hasPurchasedBookByType(bookId: string, bookType?: BookType) {
            const bookByType = await this.getPurchasedBookByType(bookId, bookType);
            const bookByBundle = await this.getBundleAccess(bookId);

            return bookByBundle.length > 0 || bookByType.length > 0;
        }

        async getPurchasedBookByType(bookId: string, bookType?: BookType) {
            return this.multiQuery([
                {
                    fieldPath: "bookId",
                    opStr: "==",
                    value: bookId,
                },
                {
                    fieldPath: "bookType",
                    opStr: "==",
                    value: bookType,
                },
            ]);
        }
        async getPurchasedBookById(bookId: string) {
            return this.multiQuery([
                {
                    fieldPath: "includesBookIds",
                    opStr: "array-contains",
                    value: bookId,
                },
            ]);
        }
        async getBundleAccess(bookId: string) {
            return this.multiQuery([
                {
                    fieldPath: "bookId",
                    opStr: "==",
                    value: bookId,
                },
                {
                    fieldPath: "bookType",
                    opStr: "==",
                    value: BookType.BUNDLE,
                },
            ]);
        }

        async getBookAccess(book: Book, bookId: string): Promise<BookAccessDetails> {
            const ebookId = getBookIdByType(book, bookId, BookType.EBOOK) ?? "";
            const audioBookId = getBookIdByType(book, bookId, BookType.AUDIO) ?? "";
            const ebookPromise = this.getPurchasedBookByType(ebookId, BookType.EBOOK);
            const audiobookPromise = this.getPurchasedBookByType(audioBookId, BookType.AUDIO);
            const bundlePromise = this.getBundleAccess(bookId);
            const [ebookAccess, audiobookAccess, bundleAccess] = await Promise.all([
                ebookPromise,
                audiobookPromise,
                bundlePromise,
            ]);

            const hasEbookAccess = ebookAccess.length > 0;
            const hasAudiobookAccess = audiobookAccess.length > 0;
            return {
                hasEbookAccess,
                hasAudiobookAccess,
                hasBundleAccess: bundleAccess.length > 0 || (hasEbookAccess && hasAudiobookAccess),
            };
        }

        getAll(): Promise<TransactionData[]> {
            return this.all().then((docDataList) =>
                docDataList.map(this.mapTransactionData).filter((data) => data !== null)
            );
        }

        getAllByBookId(bookId: string): Promise<TransactionData[]> {
            return this.query("bookId", "==", bookId).then((data) => {
                if (!data || data.length == 0) {
                    return [];
                }

                return data.map(this.mapTransactionData);
            });
        }

        async addTransaction(bookId: string, bookType: BookType, price: number): Promise<TransactionData> {
            const existing = await this.multiQuery([
                { fieldPath: "bookId", opStr: "==", value: bookId },
                { fieldPath: "bookType", opStr: "==", value: bookType },
            ]);
            if (existing.length > 0) {
                return this.mapTransactionData(existing[0]);
            }
            const transaction: Transaction = {
                booksource: "publizm",
                bookId,
                bookType,
                price,
                priceInØre: price * 100,
                currency: Currency.NOK,
                timestamp: Timestamp.now(),
            };

            const documentId = await this.update(removeNullOrUndefinedKeys(transaction));
            return {
                transaction,
                documentId,
            };
        }

        mapTransactionData(docData: DocumentData<Transaction>): TransactionData {
            return {
                transaction: docData.data,
                documentId: docData.id,
            };
        }
    };

    // Subcollection
    static CustomerBook = class extends FirestoreService<CustomerBook> {
        constructor(userId?: string) {
            const user = auth.currentUser;
            super(`${FirestoreCollection.USERS}/${userId ?? user?.uid}/customerbooks`, new CustomerBookVersionMapper());
        }

        exists(): Promise<boolean> {
            return this.all().then((data) => data.length > 0);
        }

        getAll(): Promise<CustomerBookData[]> {
            return this.all().then((docDataList) =>
                docDataList.map(this.mapBookVariantData).filter((data) => data !== null)
            );
        }

        getFirst(): Promise<CustomerBookData | null> {
            return this.all().then((data) => {
                if (data.length === 0) {
                    return null;
                }
                return {
                    customerBook: data[0].data,
                    documentId: data[0].id,
                };
            });
        }

        getByBookId(bookId: string): Promise<CustomerBookData | null> {
            return this.query("bookId", "==", bookId).then((data) => {
                if (!data || data.length == 0) {
                    return null;
                }

                return {
                    customerBook: data[0].data,
                    documentId: data[0].id,
                };
            });
        }

        getByBookIdAndType(bookId: string, type: BookType): Promise<CustomerBookData | null> {
            return this.multiQuery([
                { fieldPath: "bookId", opStr: "==", value: bookId },
                { fieldPath: "bookType", opStr: "==", value: type },
            ]).then((data) => {
                if (!data || data.length == 0) {
                    return null;
                }

                return {
                    customerBook: data[0].data,
                    documentId: data[0].id,
                };
            });
        }
        async toogleBookAsFavorite(bookId: string, book: Book, type: BookType) {
            const existing: CustomerBookData | null = await this.getByBookIdAndType(bookId, type);
            if (existing != null) {
                existing.customerBook.favorite = !existing.customerBook.favorite;
                await this.updateCustomerBook(existing.customerBook, existing.documentId);
            } else {
                const customerBook: CustomerBook = createInitialCustomerBookData(bookId, book, type);
                customerBook.favorite = true;
                await this.updateCustomerBook(customerBook);
            }
        }

        updateCustomerBook(data: Partial<CustomerBook>, documentId?: string): Promise<FilePath> {
            if (!documentId) {
                return this.update(data);
            }

            // If favorite is set to false and book is not purchased.
            if (!data.purchased && data.favorite === false) {
                this.deleteDoc(documentId);
                return Promise.resolve("");
            }

            return this.update(removeNullOrUndefinedKeys(data), documentId);
        }

        mapBookVariantData(docData: DocumentData<CustomerBook>): CustomerBookData {
            return {
                customerBook: docData.data,
                documentId: docData.id,
            };
        }
    };
}
