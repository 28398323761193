import DashboardHorizontalMenu from "components/dashboard/Menu/DashboardHorizontalMenu";
import { AdministrationDashboardMenu } from "components/dashboard/Menu/DashboardUserMenu";
import PageTemplate from "components/pagetemplate/PageTemplate";
import { Column, Columns, Container } from "components/trunx";
import { ReactElement, useEffect, useState } from "react";
import { Transaction } from "shared";

import LoadingIndicator from "../../../../../components/LoadingIndicator";
import { DashboardProvider, useDashboard } from "../../../../../contexts/DashboardContext";
import useTransactionApi from "../../../../../services/firestore/hooks/useTransactionApi";
import TransactionsTable, { TransactionsTableData } from "./TransactionsTable";

export default function AdministrationTransactionDashboard(): ReactElement {
    return (
        <>
            <PageTemplate marginLessTop>
                <DashboardProvider>
                    <Container>
                        <DashboardContent />
                    </Container>
                </DashboardProvider>
            </PageTemplate>
        </>
    );
}

function DashboardContent() {
    const { selectedMenu } = useDashboard();
    const getSource = () => (selectedMenu == AdministrationDashboardMenu.BOKBASEN ? "bokbasen" : "publizm");
    const [source, setSource] = useState(getSource());
    const [tableDataList, setTableDataList] = useState<TransactionsTableData[]>([]);
    const { data, isLoading } = useTransactionApi().getTransactions(source == "bokbasen" ? source : undefined);

    useEffect(() => {
        setSource(getSource());
    }, [selectedMenu]);

    function renderTable() {
        return <TransactionsTable key={"publizm"} data={tableDataList} />;
    }

    function renderTableBokbasen() {
        return <TransactionsTable key={"bokbasen"} data={tableDataList} />;
    }

    function renderContent() {
        if (isLoading || tableDataList.length == 0) {
            return <LoadingIndicator />;
        }

        switch (selectedMenu) {
            case AdministrationDashboardMenu.PUBLIZM:
                return renderTable();
            case AdministrationDashboardMenu.BOKBASEN:
                return renderTableBokbasen();
            default:
                return renderTable();
        }
    }

    function mapBookDataToTableData(transactions?: Transaction[]): TransactionsTableData[] {
        return (
            transactions
                ?.filter((transaction) =>
                    source == "publizm"
                        ? transaction.booksource == "publizm" || transaction.booksource == undefined
                        : true
                )
                .map((transaction) => {
                    const userId = transaction!.path!.split("/")[1];
                    return {
                        id: transaction.id ?? "",
                        bookId: transaction.bookId,
                        userId,
                        bookType: transaction.bookType,
                        source: transaction.booksource ?? "publizm",
                        isDuplicate: transaction.isDuplicate,
                        transactionStatus: transaction.vippsDetails?.state,
                        vippsReference: transaction.vippsDetails?.reference,
                        originalPrice: transaction.originalPrice,
                        price: transaction.price,
                        timestamp: transaction.timestamp?.toDate().toLocaleString("no-NO"),
                    };
                }) ?? []
        );
    }

    useEffect(() => {
        setTableDataList(mapBookDataToTableData(data));
    }, [data]);

    return (
        <>
            <DashboardHorizontalMenu />

            <Columns pt5>
                <Column isFlex isFlexDirectionColumn isJustifyContentFlexStart>
                    {renderContent()}
                </Column>
            </Columns>
        </>
    );
}
