import { Column, Field, Help } from "components/trunx";
import React, { useState } from "react";

import { useAuth } from "../../contexts/AuthContext";
import PzButton from "../form/PzButton";
import PzFormInputField from "../form/PzFormInputField";

export interface ForgotPasswordProps {
    changeViewComponent?: (viewState: boolean) => void;
}

export default function ForgotPassword({ changeViewComponent }: ForgotPasswordProps) {
    const { resetPassword } = useAuth();
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    async function handleSubmit(e) {
        e.preventDefault();
        setMessage("");
        setError("");
        setLoading(true);
        changeViewComponent && changeViewComponent(true);
        inputRef.current &&
            resetPassword(inputRef.current.value)
                .then(() => {
                    setMessage("Se innboksen din for videre instrukser");
                    changeViewComponent && changeViewComponent(false);
                })
                .catch(() => {
                    {
                        setError("Du må oppgi en gyldig e-post adresse.");
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <PzFormInputField label="Epost-adresse" name="email" placeholder="Epost-adresse" inputRef={inputRef} />
                {error && <Help isDanger>{error}</Help>}
                {message && <Help isSuccess>{message}</Help>}
                <Field isGrouped className="mt-5">
                    <Column isFull pr0 pl0>
                        <PzButton isLoading={loading} disabled={loading} isPrimary type="submit" isFullwidth>
                            Send inn
                        </PzButton>
                    </Column>
                </Field>
            </form>
        </>
    );
}
