import "./PzIconButton.css";

import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { PropsWithChildren, ReactElement } from "react";

import PzText from "../text/PzText";
import PzButton, { PzButtonProps } from "./PzButton";
interface PzIconButtonProps extends FontAwesomeIconProps {
    disabled?: boolean;
    iconPlacement?: "top" | "left" | "right" | "bottom";
    onClick?: (e) => void;
    isSmall?: boolean;
    isPrimary?: boolean;
    buttonProps?: PzButtonProps;
}
export default function PzIconButton(props: PropsWithChildren<PzIconButtonProps>): ReactElement {
    const {
        onClick,
        iconPlacement,
        children = null,
        isSmall = false,
        className,
        buttonProps,
        ...fontAwesomeProps
    } = props;

    function renderIcon() {
        return <FontAwesomeIcon {...fontAwesomeProps} />;
    }

    function renderText() {
        if (!children) {
            return null;
        }
        return (
            <PzText m0 p0 containerProps={{ m0: true, p0: true }} isSize6 isAlignContentCenter>
                {children}
            </PzText>
        );
    }

    function renderContent() {
        if (iconPlacement === "left" || iconPlacement === "top") {
            return (
                <>
                    {renderIcon()}
                    {renderText()}
                </>
            );
        }

        return (
            <>
                {renderText()}
                {renderIcon()}
            </>
        );
    }
    return (
        <PzButton
            isPrimary={props.isPrimary}
            disabled={props.disabled}
            className={`pz-icon-button ${isSmall ? "small" : "regular"} ${iconPlacement} ${className}`}
            onClick={onClick}
            {...buttonProps}
        >
            {renderContent()}
        </PzButton>
    );
}
