import { UserRole } from "shared";

import { AppPath } from "../../../common/AppPath";
import { Feature } from "./../../feature/FeatureToggle";

export class MenuItem {
    label: string;
    target: AppPath | AppPath[] | MenuItem[] | string;
    _enabled: Feature;

    constructor(label: string, path: AppPath | AppPath[] | MenuItem[] | string, enabled = Feature.ON) {
        this.label = label;
        this.target = path;
        this._enabled = enabled;
    }

    get isMultiMenu(): boolean {
        return this.target instanceof Array && this.target[0] instanceof MenuItem;
    }

    get location(): AppPath | string {
        if (typeof this.target === "string") {
            return this.target;
        } else if (!this.isMultiMenu) {
            return this.target[0] as AppPath;
        }
        return AppPath.ROOT;
    }

    get isCurrentLocation(): boolean {
        if (typeof this.target === "string") {
            return window.location.pathname === this.target;
        } else if (this.target instanceof Array) {
            return this.target.some((path) => window.location.pathname === path);
        } else {
            return false;
        }
    }
    get enabled(): boolean {
        return this._enabled.isActive();
    }
}

const item = (label: string, target: AppPath | AppPath[] | MenuItem[] | string, enabled = Feature.ON) =>
    new MenuItem(label, target, enabled);

/** Define menues for each user role */
const authorMenu = [
    // item("Landing", AppPath.HOME),
    item("Oversikt", AppPath.DASHBOARD),
    item("Lytt & Les", [AppPath.CATALOGUE, AppPath.ROOT]),
    item("Billigkroken", AppPath.BILLIGKROKEN),
    item("Magazinet", AppPath.ARTICLES),
    item("Send manus", `${AppPath.SUBMIT_WORK_INFO}`),
    item("Om oss", AppPath.ABOUT),
];

const editorMenu = [
    // item("Landing", AppPath.HOME),
    item("Oversikt", AppPath.DASHBOARD),
    item("Lytt & Les", [AppPath.CATALOGUE, AppPath.ROOT]),
    item("Billigkroken", AppPath.BILLIGKROKEN),
    item("Magazinet", AppPath.ARTICLES),
    item("Send manus", `${AppPath.SUBMIT_WORK_INFO}`),
    item("Om oss", AppPath.ABOUT),
];

const adminMenu = [
    // item("Landing", AppPath.HOME),
    item("Lytt & Les", [AppPath.CATALOGUE, AppPath.ROOT]),
    item("Billigkroken", AppPath.BILLIGKROKEN),
    item("Magazinet", AppPath.ARTICLES),
    item("Send manus", `${AppPath.SUBMIT_WORK_INFO}`),
    item("Om oss", AppPath.ABOUT),
    item("Oversikt", [
        item("Verk", AppPath.DASHBOARD),
        item("Brukeradmin", AppPath.USERADMIN),
        item("Avtaler", AppPath.MANAGE_AGREEMENTS),
    ]),
    item("Adminstrasjon", [
        item("Katalog", AppPath.CATALOG_ADMIN),
        item("Transaksjoner", AppPath.ADMIN_TRANSACTIONS),
        item("Bok grupper", AppPath.BOOK_GROUP_ADMIN),
        item("Forfatterprofiler", AppPath.ADMIN_AUTHORS),
        item("Rettighetshavere", AppPath.ADMIN_LICENSEE_DASHBOARD),
        item("Royalty", AppPath.ROYALTY_DASHBOARD),
    ]),
];

const customerMenu = [
    // item("Landing", AppPath.HOME),
    item("Lytt & Les", AppPath.DASHBOARD),
    item("Billigkroken", AppPath.BILLIGKROKEN),
    item("Magazinet", AppPath.ARTICLES),
    item("Om oss", AppPath.ABOUT),
];

const defaultMenu = [
    // item("Landing", AppPath.HOME),
    item("Lytt & Les", [AppPath.CATALOGUE, AppPath.ROOT]),
    item("Billigkroken", AppPath.BILLIGKROKEN),
    item("Magazinet", AppPath.ARTICLES),
    item("Send manus", `${AppPath.SUBMIT_WORK_INFO}`),
    item("Om oss", AppPath.ABOUT),
];

const userMenu = (role?: UserRole) => {
    switch (role) {
        case UserRole.AUTHOR:
            return authorMenu;
        case UserRole.EDITOR:
            return editorMenu;
        case UserRole.ADMIN:
            return adminMenu;
        case UserRole.CUSTOMER:
            return customerMenu;
        default:
            return defaultMenu;
    }
};

export default userMenu;
